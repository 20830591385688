import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { CustomsService } from '../../services/customs.service';
import { AllLocationFilterComponent } from '../customs-location-filter/all-location-filter.component';
declare var $: any;
@Component({
  selector: 'app-customs-custom-filters',
  templateUrl: './customs-custom-filters.component.html',
  styleUrls: ['./customs-custom-filters.component.scss'],
})
export class CustomsCustomFiltersComponent implements OnInit, OnChanges {
  isCustomFilterSecHide: boolean;
  isCustomFilterSecShow: boolean;
  isFilterHide: boolean;
  isFilterShow: boolean;
  isSearchBoxHide: boolean;
  isSearchBoxShow: boolean;
  isSortShow: boolean;
  isSortHide: boolean;
  isShareShow: boolean;
  isShareHide: boolean;

  constructor(
    private accountSessionData: CurrentSessionDataService,
    private customsService: CustomsService,
    private dataSharingService: DataSharingService,
    private appInsightsService: AppInsightsService
  ) {
    this.accountID = this.currentAccDetails.accountId;
    this.orginEmail = this.currentUserDetails.emailAddress;
    this.isGroup = this.currentAccDetails.isGroup;
    this.dataSharingService.isGuidedTourCustomFilterCompHide.subscribe(
      (value) => {
        this.isCustomFilterSecHide = value;
      }
    );
    this.dataSharingService.isGuidedTourCustomFilterCompShow.subscribe(
      (value) => {
        this.isCustomFilterSecShow = value;
      }
    );
    this.dataSharingService.isGuidedTourCustomFilterHide.subscribe((value) => {
      this.isFilterHide = value;
    });
    this.dataSharingService.isGuidedTourCustomFilterShow.subscribe((value) => {
      this.isFilterShow = value;
    });
    this.dataSharingService.isGuidedTourShareShipmentHide.subscribe((value) => {
      this.isShareHide = value;
    });
    this.dataSharingService.isGuidedTourShareShipmentShow.subscribe((value) => {
      this.isShareShow = value;
    });
    this.dataSharingService.isGuidedTourSortHide.subscribe((value) => {
      this.isSortHide = value;
    });
    this.dataSharingService.isGuidedTourSortShow.subscribe((value) => {
      this.isSortShow = value;
    });
    this.dataSharingService.isGuidedTourSearchTextBoxHide.subscribe((value) => {
      this.isSearchBoxHide = value;
    });
    this.dataSharingService.isGuidedTourSearchTextBoxShow.subscribe((value) => {
      this.isSearchBoxShow = value;
    });
  }

  ngOnChanges(): void {
    if (this.paramCheck) {
      this.highlightSelectedFilter();
    }
    this.initialRecentLocation();
    this.defaultSortSelectedColumn =
      this.sortOptions[this.defaultSortSelectedValue - 1]?.name;
  }

  scrHeight: any;
  scrWidth: any;
  @ViewChild(AllLocationFilterComponent)
  allLocation!: AllLocationFilterComponent;
  isMobileViewParent = false;
  mobileStatusFilter: any[] = [];
  mobileExceptionFilter: any = [];
  mobileTransportFilter: any = [];
  mobileWatchListFilter: any = [];
  mobileCustomsTypeFilter: any = [];
  mobilePGAExistsFilter: any = [];
  mobileFilterContainer: boolean = false;
  selectedMainFilter: any[] = [];
  mobileDateRangeFilter: any = [];
  statusFilterValue: boolean = false;
  exceptionFilterValue: boolean = false;
  transportFilterValue: boolean = false;
  favoritesFilterValue: boolean = false;
  pgaExistsFilterValue: boolean = false;
  customsTypeFilterValue = false;
  public checkWatchlist: boolean;
  public checkPgaExists: boolean;
  customsSearchText = '';
  customsSharePopup = false;
  offsetLoadingPort: any = 0;
  offsetDischargePort: any = 0;
  offsetPortofClearance: any = 0;
  offsetShipmentOrigin: any = 0;
  offsetShipmentDestination: any = 0;
  allLocationList: any[] = [];
  dischargePortList: any[] = [];
  portofClearanceList: any[] = [];
  LoadingPortList: any[] = [];
  shipmentOriginList: any[] = [];
  shipmentDestinationList: any[] = [];
  mobileAllLocationFilter: any = [];
  accountID: any;
  orginEmail: any;
  isGroup: any;
  dateFilterValue: boolean = false;
  currentAccDetails = this.accountSessionData.getCurrentAccountData();
  currentUserDetails = this.accountSessionData.getCurrentUserData();
  fetchMobileRecords = 30;
  mobileLocationFilterReselect: any = [];
  mobileLoadingFilterReselect: any = [];
  mobileDestinationFilterReselect: any = [];
  mobileDischargeFilterReselect = [];
  mobileOriginFilterReselect = [];
  mobilePocFilterReselect = [];
  @Input() selectedDateRangeRender: any;
  @Output() clearAllFilterValue = new EventEmitter<any>();
  @Input() paramCheck: any;
  @Output() selectedMainFilterArray = new EventEmitter<any>();
  @Output() selectedFilterArray = new EventEmitter<any>();
  @Output() selectedUrlFilters = new EventEmitter<any>();
  @Output() selectedDefualtUrlFilters = new EventEmitter<any>();
  @Output() onCustomsSearchHandler = new EventEmitter<any>();
  @Output() clearSearchFilter = new EventEmitter<any>();
  @Output() onSortSelectedHandler = new EventEmitter<any>();
  @Output() selectedDateRange = new EventEmitter<any>();
  @Output() locFilter = new EventEmitter<any>();
  @Output() downloadExcel = new EventEmitter<any>();
  @Output() onUserFilterSaved = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<any>();
  @Output() userFilterFetch = new EventEmitter<any>();
  @Input() defaultSortSelectedValue: number = 1;
  @Input() defaultSortDirection: string = 'asc';
  defaultSortSelectedColumn: string = '';
  defaultCustomsSortCustomsDashboard = 'Loading Dep.';
  defaultCustomsSortCustomsDashboardWatchlist = 'Loading Dep.';
  @Input() resultCount: any;
  @Input() urlShare = '';
  @Input() isDashboard: any;
  @Input() isCustomsDashboardWatchlist: any;
  @Input() isCustomsDashboard: any;
  clickEvent = new Subject();
  clickSortOption = new Subject();
  watchlistFilter = 'Watchlist';
  PGAExistsFilter = 'PGA Exists';
  resentAllLocationList: any = [];
  isProgressLocation: boolean = false;
  offsetImporterOfRecord: number = 0;
  importerOfRecordList: any[] = [];
  recentListImporterOfRecord: any = [];
  mobileImporterOfRecordFilter: any = [];
  offsetShipper: number = 0;
  shipperList: any[] = [];
  recentListShipper: any = [];
  mobileShipperFilter: any = [];
  offsetConsignee: number = 0;
  consigneeList: any[] = [];
  recentListConsignee: any = [];
  mobileConsigneeFilter: any = [];
  offsetSupplier: number = 0;
  supplierList: any[] = [];
  recentListSupplier: any = [];
  resentListLoadingPort: any = [];
  resentListDischargePort: any = [];
  resentListPortOfClearance: any = [];
  resentListShipmentOrigin: any = [];
  resentListShipmentDestination: any = [];
  allLocationReselectedList: any[] = [];
  mobileSupplierFilter: any = [];
  offsetImporter: number = 0;
  importerList: any[] = [];
  recentListImporter: any = [];
  mobileImporterFilter: any = [];
  copyText: any = '';
  locationFilterValue: boolean = false;
  @Input() tempFilterDiff: any;
  @Input() isfilterEdit: any;
  tempSortDiff: any;
  isShowEditPopup = false;
  createUserFilterPopUp: boolean = false;
  myFilterName: any = '';
  myEditFilterName: any = '';
  myFilterError: boolean = false;
  myFilterNameEmpty: boolean = false;
  isShowSaveAsBtn: boolean = false;
  isShowSaveBtn: boolean = false;
  mobileSelectedFilter: any = '';
  tempUndoArray: any;
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Input() saveFilterJson: any;
  @Input() savedFilters: any;
  @Input() guidedTourFilter: boolean;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768 ? true : false;
    this.clearMobileFilters();
  }
  filterItemset = [
    { filt: 'Date Range' },
    { filt: 'Location' },
    { filt: 'Supplier' },
    { filt: 'Consignee' },
    { filt: 'Importer' },
    { filt: 'Importer of Record' },
    { filt: 'Shipper' },
    { filt: 'Status' },
    { filt: 'Transport Type' },
    { filt: 'Exception Type' },
    { filt: 'Customs Type' },
    { filt: 'Watchlist' },
    { filt: 'PGA Exists' },
  ];
  filterItem: any[] = [
    { filt: 'Date Range', val: 5, col_name: '', checked: false },
    { filt: 'Location', val: 4, col_name: 'allLocation', checked: false },
    { filt: 'Supplier', val: 11, col_name: 'supplier', checked: false },
    { filt: 'Consignee', val: 3, col_name: 'consignee', checked: false },
    { filt: 'Importer', val: 12, col_name: 'importer', checked: false },
    {
      filt: 'Importer of Record',
      val: 10,
      col_name: 'importer_of_record',
      checked: false,
    },
    { filt: 'Shipper', val: 2, col_name: 'shipper', checked: false },
    { filt: 'Status', val: 1, col_name: 'statuscustom', checked: false },
    { filt: 'Transport Type', val: 0, col_name: 'transport', checked: false },
    { filt: 'Exception Type', val: 6, col_name: 'exception', checked: false },
    { filt: 'Customs Type', val: 8, col_name: 'customstype', checked: false },
    { filt: 'Watchlist', val: 7, col_name: '', checked: false },
    { filt: 'PGA Exists', val: 9, col_name: '', checked: false },
  ];
  statusFilterItem: any[] = [
    { filt: 'Prepared', val: 1, checked: false },
    { filt: 'Filed', val: 2, checked: false },
    { filt: 'In Progress', val: 3, checked: false },
    { filt: 'Released', val: 4, checked: false },
    { filt: 'Archived', val: 5, checked: false },
  ];
  transportFilterItem: any[] = [
    { filt: 'Sea', val: 1, checked: false },
    { filt: 'Air', val: 2, checked: false },
    { filt: 'Road', val: 3, checked: false },
    { filt: 'Rail', val: 4, checked: false },
    { filt: 'Other', val: 5, checked: false },
  ];
  customsTypeFilterItem: any[] = [
    { filt: 'Import', val: 1, checked: false },
    { filt: 'Export', val: 2, checked: false },
    { filt: 'FTZ', val: 3, checked: false },
    { filt: 'Other', val: 4, checked: false },
  ];
  exceptionsFilterItem: any[] = [
    { filt: 'No Exceptions', val: 1, checked: false },
    { filt: 'On Hold', val: 2, checked: false },
    { filt: 'Delayed', val: 3, checked: false },
  ];
  sortOptions = [
    {
      id: 1,
      name: 'Loading Dep.',
      sort_column: 'loading_dep',
      sort_direction: 'asc',
    },
    {
      id: 2,
      name: 'Discharge Arr.',
      sort_column: 'discharge_arr',
      sort_direction: 'asc',
    },
    {
      id: 3,
      name: 'Entry Arr.',
      sort_column: 'entry_arr',
      sort_direction: 'asc',
    },
    {
      id: 4,
      name: 'Export Date',
      sort_column: 'export_date',
      sort_direction: 'asc',
    },
    {
      id: 5,
      name: 'Filed Date',
      sort_column: 'filed_date',
      sort_direction: 'asc',
    },
    {
      id: 6,
      name: 'Released Date',
      sort_column: 'released_date',
      sort_direction: 'asc',
    },
    {
      id: 7,
      name: 'Status',
      sort_column: 'status',
      sort_direction: 'asc',
    },
    {
      id: 8,
      name: 'Customs #',
      sort_column: 'custom',
      sort_direction: 'asc',
    },
  ];

  ngOnInit(): void {
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768;
    this.defaultSortSelectedColumn =
      this.sortOptions[this.defaultSortSelectedValue - 1]?.name;
    this.clickEvent.pipe(debounceTime(900)).subscribe((e) => {
      this.onSearchHandler(e);
    });
    this.clickSortOption.pipe(debounceTime(900)).subscribe((e: any) => {
      this.onSortHandler(e);
    });
    $(document).ready(function () {
      $('.restrict-close').on('click', function (e: any) {
        e.stopPropagation();
      });
    });
  }

  clearMobileFilters() {
    if (!this.isMobileViewParent) {
      this.mobileStatusFilter = [];
      this.mobileDateRangeFilter = [];
      this.mobileTransportFilter = [];
      // this.mobileOriginFilter = [];
      // this.mobileDestinationtFilter = [];
      this.mobileWatchListFilter = [];
      // this.mobileSelectedFilter = '';
      this.mobileExceptionFilter = [];
      this.mobileCustomsTypeFilter = [];
      this.mobilePGAExistsFilter = [];
    }
  }

  openMobileFilter() {
    this.mobileFilterContainer = true;
  }

  closeFilter() {
    this.mobileFilterContainer = false;
    // if (this.mobileLocationFilterReselect.type == 'shipper') {
    //   this.mobileShipperFilterReselect =
    //     this.mobileLocationFilterReselect.account;
    // }

    this.allLocationReselectedList = [
      { loading: this.mobileLoadingFilterReselect },
      { discharge: this.mobileDestinationFilterReselect },
      { portOfClearance: this.mobilePocFilterReselect },
      { origin: this.mobileOriginFilterReselect },
      { destination: this.mobileDestinationFilterReselect },
    ];
  }

  clearAllFilter() {
    this.filterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.statusFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.transportFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.exceptionsFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.customsTypeFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.selectedMainFilter = [];

    // this.mobileShipperFilterReselect = [];
    this.selectedDateRangeRender = [];
    this.checkWatchlist = false;
    this.checkPgaExists = false;
    //this.mobileSelectedFilter = '';
    this.clearAllFilterValue.emit('clearAll');
    this.mobileAllLocationFilter.filterArray = [];

    this.mobileLoadingFilterReselect = [];
    this.mobileDischargeFilterReselect = [];
    this.mobilePocFilterReselect = [];
    this.mobileOriginFilterReselect = [];
    this.mobileDestinationFilterReselect = [];

    this.allLocationReselectedList = [
      { loading: this.mobileLoadingFilterReselect },
      { discharge: this.mobileDischargeFilterReselect },
      { portOfClearance: this.mobilePocFilterReselect },
      { origin: this.mobileOriginFilterReselect },
      { destination: this.mobileDestinationFilterReselect },
    ];
  }

  highlightSelectedFilter() {
    let filterOptions = this.paramCheck.filter_options;

    this.setAllMobFilterFalse();
    if (filterOptions.length < 1) {
      this.setAllMobFilterFalse();
    } else {
      filterOptions.forEach((value: any) => {
        switch (value.column_name) {
          case 'statuscustom':
            this.statusFilterValue = true;
            break;
          case 'transport':
            this.transportFilterValue = true;
            break;
          case 'exception':
            this.exceptionFilterValue = true;
            break;
          case 'favorites':
            this.favoritesFilterValue = true;
            break;
          case 'pga':
            this.pgaExistsFilterValue = true;
            break;
          case 'customstype':
            this.customsTypeFilterValue = true;
            break;

          // case 'shipper':
          //   this.shipperFilterValue = true;
          //   break;
          case 'Loading Port':
          case 'Discharge Port':
          case 'Port of Clearance':
          case 'Shipment Origin':
          case 'Shipment Destination':
            this.locationFilterValue = true;
            break;
          case 'loadingdepature':
          case 'dischargearrival':
          case 'estimatedentry':
          case 'entryportarrival':
          case 'export':
          case 'filed':
          case 'released':
            this.dateFilterValue = true;
            break;
          default:
            //this.setAllMobFilterFalse()
            break;
        }
      });
    }
  }

  setAllMobFilterFalse() {
    this.statusFilterValue = false;
    this.transportFilterValue = false;
    this.exceptionFilterValue = false;
    this.favoritesFilterValue = false;
    this.pgaExistsFilterValue = false;
    this.customsTypeFilterValue = false;

    // this.shipperFilterValue = false;
    // this.locationFilterValue = false;
    this.dateFilterValue = false;
  }

  clearAllFilters() {
    this.filterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.statusFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.exceptionsFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.transportFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.customsTypeFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.LoadingPortList = [];
    this.offsetLoadingPort = 0;

    this.dischargePortList = [];
    this.offsetDischargePort = 0;
    this.portofClearanceList = [];
    this.offsetPortofClearance = [];
    this.shipmentOriginList = [];
    this.offsetShipmentOrigin = 0;
    this.shipmentDestinationList = [];
    this.offsetShipmentDestination = 0;

    this.selectedMainFilter = [];
    this.isShowSaveAsBtn = false;
    this.isShowSaveBtn = false;
    this.tempSortDiff = '';
    this.shipperList = [];
    this.offsetShipper = 0;

    this.supplierList = [];
    this.offsetSupplier = 0;
    this.shipperList = [];
    this.offsetShipper = 0;
    this.consigneeList = [];
    this.offsetConsignee = 0;
    this.importerList = [];
    this.offsetImporter = 0;
    this.importerOfRecordList = [];
    this.offsetImporterOfRecord = 0;
    //this.mobileShipperFilterReselect = [];

    this.allLocationReselectedList = [
      { loading: [] },
      { discharge: [] },
      { portOfClearance: [] },
      { origin: [] },
      { destination: [] },
    ];
  }

  filterIsOpen(
    event: any,
    status: any,
    filterValue: any,
    filterDisplayName: any,
    filterText: any,
    index: any
  ) {
    let isVisible = $(event).attr('aria-expanded');
    if (isVisible == 'true') {
      this.isFilterSelected(
        status,
        filterValue,
        filterDisplayName,
        filterText,
        index
      );
      if (filterText == 'location') {
        this.allLocation.allLocationDefaultCustomfilterM('Loading Port');
      }
    } else {
      let checker: any;
      switch (filterValue) {
        case 0:
          checker = Object.values(this.transportFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 1:
          checker = Object.values(this.statusFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 6:
          checker = Object.values(this.exceptionsFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 8:
          checker = Object.values(this.customsTypeFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        // case 2:
        //   this.shipperList = [];
        //   this.offsetShipper = 0;
        //   break;
      }
    }
  }

  removeMainFilter(isPresent: boolean, filterValue: any) {
    if (!isPresent) {
      let elementPos = this.selectedMainFilter
        .map(function (x: any) {
          return x.id;
        })
        .indexOf(filterValue);
      if (elementPos > -1) {
        this.selectedMainFilter.splice(elementPos, 1);
      }
    }
  }

  @ViewChild('dropdownBtn') dropdownBtn: ElementRef;
  @ViewChild('dropdownElementDiv') dropdownElementDiv: ElementRef;

  toggleFilterDropdown() {
    let classList = this.dropdownElementDiv?.nativeElement?.classList;
    if (JSON.stringify(classList)?.includes('show')) {
      this.dropdownBtn.nativeElement.click();
    }
  }

  isFilterSelected(
    status: any,
    filterValue: any,
    filterDisplayName: any,
    filterText: any,
    index: any
  ) {
    this.toggleFilterDropdown();
    if (status) {
      this.appInsightsService.logEvent(filterDisplayName + ' Filter Applied');
      switch (filterValue) {
        case 0:
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.transportFilterItem,
            true
          );
          break;
        case 1:
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.statusFilterItem,
            true
          );
          break;
        case 2:
          this.getShipperList('');
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.shipperList,
            true
          );
          break;
        case 3:
          this.getConsigneeList('');
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.consigneeList,
            true
          );
          break;

        case 4:
          $('#watchListId').click();
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          this.getLoadingPortList('');
          break;
        case 5:
          $('#watchListId').click();
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          break;
        case 6:
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.exceptionsFilterItem,
            true
          );
          break;
        case 7:
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          $('#watchListId').click();
          this.selectedFilterArray.emit({
            type: 'favorites',
            selectedValue: [
              { column_name: 'favorites', operater: '=', value: true },
            ],
            clearFrom: '',
          });
          setTimeout(() => {
            this.saveAsButtonVisibility();
          }, 300);
          break;
        case 8:
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.customsTypeFilterItem,
            true
          );
          break;
        case 9:
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          $('#watchListId').click();
          this.selectedFilterArray.emit({
            type: 'pga',
            selectedValue: [{ column_name: 'pga', operater: '=', value: true }],
            clearFrom: '',
          });
          setTimeout(() => {
            this.saveAsButtonVisibility();
          }, 300);
          break;
        case 10:
          this.getImporterOfRecordList('');
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.importerOfRecordList,
            true
          );
          break;
        case 11:
          this.getSupplierList('');
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.supplierList,
            true
          );
          break;
        case 12:
          this.getImporterList('');
          this.setCustomsMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.importerList,
            true
          );
          break;
      }
    } else {
      let setFilterText = '';
      if (filterValue === 7) {
        setFilterText = 'favorites';
      } else if (filterValue === 9) {
        setFilterText = 'pga';
      } else {
        setFilterText = filterText;
      }
      let filterCloseData = {
        type: setFilterText,
        index: index,
        id: filterValue,
        eventType: '',
      };
      this.closeCustomFilters(filterCloseData);
    }
    this.selectedMainFilterArray.emit(this.selectedMainFilter);
  }

  setCustomsMainFilterArray(
    id: any,
    filterDisplayName: any,
    type: any,
    index: any,
    filterArray: any,
    autoOpen: boolean
  ) {
    let currentFilterData = {
      id,
      filterDisplayName,
      type,
      index,
      filterArray,
      autoOpen,
    };
    if (this.checkFilterPresent(id) === -1) {
      this.selectedMainFilter.push(currentFilterData);
    }
    if (this.isMobileViewParent) {
      this.setMobileFilterData(id, currentFilterData);
    }
  }

  checkFilterPresent(filterValue: any) {
    return this.selectedMainFilter
      .map(function (x: any) {
        return x.id;
      })
      .indexOf(parseInt(filterValue));
  }

  setMobileFilterData(id: any, currentFilterData: any) {
    currentFilterData.autoOpen = false;
    switch (id) {
      case 0:
        this.mobileTransportFilter = currentFilterData;
        break;
      case 1:
        this.mobileStatusFilter = currentFilterData;
        break;
      // case 2:
      //   this.mobileShipperFilter = currentFilterData;
      //   if (typeof currentFilterData.filterDisplayName === 'object') {
      //     this.mobileShipperFilterReselect =
      //       currentFilterData.filterDisplayName;
      //   }
      //   break;
      // case 3:
      //   break;
      case 4:
        this.mobileAllLocationFilter = currentFilterData;
        break;
      case 5:
        this.mobileDateRangeFilter = currentFilterData;
        break;
      case 6:
        this.mobileExceptionFilter = currentFilterData;
        break;
      case 7:
        this.mobileWatchListFilter = currentFilterData;
        break;
      case 8:
        this.mobileCustomsTypeFilter = currentFilterData;
        break;
      case 9:
        this.mobilePGAExistsFilter = currentFilterData;
        break;
    }
  }

  closeCustomFilters(event: any) {
    if (event.id == '0') {
      this.transportFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    }
    if (event.id == '1') {
      this.statusFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    }
    if (event.id == '6') {
      this.exceptionsFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    }
    if (event.id == '8') {
      this.customsTypeFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '2') {
      this.shipperList = [];
      this.offsetShipper = 0;
    } else if (event.id == '3') {
      this.consigneeList = [];
      this.offsetConsignee = 0;
    } else if (event.id == '10') {
      this.importerOfRecordList = [];
      this.offsetImporterOfRecord = 0;
    } else if (event.id == '11') {
      this.supplierList = [];
      this.offsetSupplier = 0;
    } else if (event.id == '12') {
      this.importerList = [];
      this.offsetImporter = 0;
    } else if (event.id == '4') {
      this.allLocationList = [];
      this.offsetLoadingPort = 0;
      this.offsetDischargePort = 0;
      this.offsetPortofClearance = 0;
      this.offsetShipmentOrigin = 0;
      this.offsetShipmentDestination = 0;
    }
    if (event.closeFrom === 'defaultFilter') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: 'defaultFilter',
        account: [],
      });
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    } else if (event.closeFrom !== 'clearFilterOnly') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: '',
        account: [],
      });
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    }
    let elementPos = this.selectedMainFilter
      .map(function (x: any) {
        return x.index;
      })
      .indexOf(event.index);

    if (elementPos > -1) {
      this.selectedMainFilter.splice(elementPos, 1);
    }

    if (event.eventType == 'closeBtn') {
      this.filterItem.forEach((e: any) => {
        if (e.val == event.id) {
          //this.appInsightsService.logEvent(e.filt + ' Filter Removed');//TODO:
          e.checked = false;
        }
      });
    }
    this.selectedMainFilterArray.emit(this.selectedMainFilter);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  getSelectedFilterArray(event: any) {
    this.selectedFilterArray.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  offsetLocation(event: any) {
    // if (event.type == 'shipper') {
    //   this.offsetShipper = event.offset;
    //   this.getShipperList(event.value);
    // } else if (event.type == 'consignee') {
    //   this.offsetConsignee = event.offset;
    //   this.getConsigneeList(event.value);
    // } else
    if (event.type == 'Loading Port') {
      this.offsetLoadingPort = event.offset;
      this.getLoadingPortList(event.value);
    } else if (event.type == 'Discharge Port') {
      this.offsetDischargePort = event.offset;
      this.getDischargePortList(event.value);
    } else if (event.type == 'Port of Clearance') {
      this.offsetPortofClearance = event.offset;
      this.getPortofClearanceList(event.value);
    } else if (event.type == 'Shipment Origin') {
      this.offsetShipmentOrigin = event.offset;
      this.getShipmentOriginList(event.value);
    } else if (event.type == 'Shipment Destination') {
      this.offsetShipmentDestination = event.offset;
      this.getShipmentDestinationList(event.value);
    } else if (event.type == 'Importer of Record') {
      this.offsetImporterOfRecord = event.offset;
      this.getImporterOfRecordList(event.value);
    } else if (event.type == 'Importer') {
      this.offsetImporter = event.offset;
      this.getImporterList(event.value);
    } else if (event.type == 'Shipper') {
      this.offsetShipper = event.offset;
      this.getShipperList(event.value);
    } else if (event.type == 'Consignee') {
      this.offsetConsignee = event.offset;
      this.getConsigneeList(event.value);
    } else if (event.type == 'Supplier') {
      this.offsetSupplier = event.offset;
      this.getSupplierList(event.value);
    }
  }

  locationSelectedFilter(event: any) {
    this.mobileLocationFilterReselect = event;
    if (this.mobileLocationFilterReselect.type == 'Loading Port') {
      this.mobileLoadingFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Discharge Port') {
      this.mobileDischargeFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Port of Clearance') {
      this.mobilePocFilterReselect = this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Shipment Origin') {
      this.mobileOriginFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (
      this.mobileLocationFilterReselect.type == 'Shipment Destination'
    ) {
      this.mobileDestinationFilterReselect =
        this.mobileLocationFilterReselect.account;
    }

    this.locFilter.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  getLocationList(event: any) {
    if (event.type == 'Loading Port') {
      this.getLoadingPortList(event.value);
      this.offsetLoadingPort = 0;
    } else if (event.type == 'Discharge Port') {
      this.getDischargePortList(event.value);
      this.offsetDischargePort = 0;
    } else if (event.type == 'Port of Clearance') {
      this.getPortofClearanceList(event.value);
      this.offsetPortofClearance = 0;
    } else if (event.type == 'Shipment Origin') {
      this.getShipmentOriginList(event.value);
      this.offsetShipmentOrigin = 0;
    } else if (event.type == 'Shipment Destination') {
      this.getShipmentDestinationList(event.value);
      this.offsetShipmentDestination = 0;
    } else if (event.type == 'Importer of Record') {
      this.getImporterOfRecordList(event.value);
      this.offsetImporterOfRecord = 0;
    } else if (event.type == 'Importer') {
      this.getImporterList(event.value);
      this.offsetImporter = 0;
    } else if (event.type == 'Shipper') {
      this.getShipperList(event.value);
      this.offsetShipper = 0;
    } else if (event.type == 'Consignee') {
      this.getConsigneeList(event.value);
      this.offsetConsignee = 0;
    } else if (event.type == 'Supplier') {
      this.getSupplierList(event.value);
      this.offsetSupplier = 0;
    }
  }

  loadLocationFilter(value: any) {
    switch (value) {
      case 'Loading Port':
        this.allLocationList = [];
        this.offsetLoadingPort = 0;
        this.getLoadingPortList('');
        break;
      case 'Discharge Port':
        this.allLocationList = [];
        this.offsetDischargePort = 0;
        this.getDischargePortList('');
        break;
      case 'Port of Clearance':
        this.allLocationList = [];
        this.offsetPortofClearance = 0;
        this.getPortofClearanceList('');
        break;
      case 'Shipment Origin':
        this.allLocationList = [];
        this.offsetShipmentOrigin = 0;
        this.getShipmentOriginList('');
        break;
      case 'Shipment Destination':
        this.allLocationList = [];
        this.offsetShipmentDestination = 0;
        this.getShipmentDestinationList('');
        break;
      default:
        this.getLoadingPortList('');
        break;
    }
  }

  getDischargePortList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getDischargePortFilter(
        searchText,
        this.offsetDischargePort,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((discharge: any) => {
        if (this.offsetDischargePort >= 10) {
          this.isProgressLocation = false;
          this.dischargePortList = [
            ...this.dischargePortList,
            ...discharge.result,
          ];
          this.allLocationList = this.dischargePortList;
        } else {
          this.isProgressLocation = false;
          this.dischargePortList = discharge.result;
          this.allLocationList = this.dischargePortList;
        }
      });
  }

  getPortofClearanceList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getPortofClearanceFilter(
        searchText,
        this.offsetPortofClearance,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((clearance: any) => {
        if (this.offsetPortofClearance >= 10) {
          this.portofClearanceList = [
            ...this.portofClearanceList,
            ...clearance.result,
          ];
          this.allLocationList = this.portofClearanceList;
          this.isProgressLocation = false;
        } else {
          this.portofClearanceList = clearance.result;
          this.allLocationList = this.portofClearanceList;
          this.isProgressLocation = false;
        }
      });
  }

  getLoadingPortList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getLoadingPortFilter(
        searchText,
        this.offsetLoadingPort,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((loadingPort: any) => {
        if (this.offsetLoadingPort >= 10) {
          this.LoadingPortList = [
            ...this.LoadingPortList,
            ...loadingPort.result,
          ];
          this.allLocationList = this.LoadingPortList;
          this.isProgressLocation = false;
        } else {
          this.LoadingPortList = loadingPort.result;
          this.allLocationList = this.LoadingPortList;
          this.isProgressLocation = false;
        }
      });
  }

  getShipmentOriginList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getShipmentOriginFilter(
        searchText,
        this.offsetShipmentOrigin,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((shipmentOrigin: any) => {
        if (this.offsetShipmentOrigin >= 10) {
          this.shipmentOriginList = [
            ...this.shipmentOriginList,
            ...shipmentOrigin.result,
          ];
          this.allLocationList = this.shipmentOriginList;
          this.isProgressLocation = false;
        } else {
          this.shipmentOriginList = shipmentOrigin.result;
          this.allLocationList = this.shipmentOriginList;
          this.isProgressLocation = false;
        }
      });
  }

  getShipmentDestinationList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getShipmentDestinationFilter(
        searchText,
        this.offsetShipmentDestination,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((shipmentDesn: any) => {
        if (this.offsetShipmentDestination >= 10) {
          this.shipmentDestinationList = [
            ...this.shipmentDestinationList,
            ...shipmentDesn.result,
          ];
          this.allLocationList = this.shipmentDestinationList;
          this.isProgressLocation = false;
        } else {
          this.shipmentDestinationList = shipmentDesn.result;
          this.allLocationList = this.shipmentDestinationList;
          this.isProgressLocation = false;
        }
      });
  }

  clearFilterSearch(e: any) {
    // if (e.type == 'shipper') {
    //   this.shipperList = [];
    //   this.offsetShipper = 0;
    // } else if (e.type == 'consignee') {
    //   this.consigneeList = [];
    //   this.offsetConsignee = 0;
    // } else

    if (e.type == 'Loading Port') {
      this.allLocationList = [];
      this.offsetLoadingPort = 0;
    } else if (e.type == 'Discharge Port') {
      this.allLocationList = [];
      this.offsetDischargePort = 0;
    } else if (e.type == 'Port of Clearance') {
      this.allLocationList = [];
      this.offsetPortofClearance = 0;
    } else if (e.type == 'Shipment Origin') {
      this.allLocationList = [];
      this.offsetShipmentOrigin = 0;
    } else if (e.type == 'Shipment Destination') {
      this.allLocationList = [];
      this.offsetShipmentDestination = 0;
    } else if (e.type == 'Importer of Record') {
      this.importerOfRecordList = [];
      this.offsetImporterOfRecord = 0;
    } else if (e.type == 'Importer') {
      this.importerList = [];
      this.offsetImporter = 0;
    } else if (e.type == 'Shipper') {
      this.shipperList = [];
      this.offsetShipper = 0;
    } else if (e.type == 'Consignee') {
      this.consigneeList = [];
      this.offsetConsignee = 0;
    } else if (e.type == 'Supplier') {
      this.supplierList = [];
      this.offsetSupplier = 0;
    }
    this.getLocationList(e);
  }

  loadURLFilters(filterURl: any) {
    let filterObj = {
      status: filterURl.statusFilter,
      transport: filterURl.transportType,
      exception: filterURl.exceptionType,
      customs: filterURl.customsType,
      dateRange: filterURl.dateRange,
      shipper: filterURl.shipper,
      consignee: filterURl.consignee,
      supplier: filterURl.supplier,
      importer: filterURl.importer,
      importerOfRecord: filterURl.importerOfRecord,
      allLocation: filterURl.allLocation,
    };
    this.setShareFilters(filterURl, filterObj);
    if (filterURl.type != 'defaultFilter') {
      this.selectedUrlFilters.emit({
        mainfilter: filterURl.mainFilter,
        status: filterObj.status,
        exception: filterObj.exception,
        transport: filterObj.transport,
        customs: filterObj.customs,
        dateRange: filterObj.dateRange.selectedValue,
        watchFilter: filterURl.watchFilter,
        pgaFilter: filterURl.pgaFilter,
        shipmentId: filterURl.shipmentId,
        consignee: filterObj.consignee.selectedValue,
        shipper: filterObj.shipper.selectedValue,
        supplier: filterObj.supplier.selectedValue,
        importer: filterObj.importer.selectedValue,
        importerOfRecord: filterObj.importerOfRecord.selectedValue,
        shipperAccount: filterObj.shipper.selectedAccount,
        consigneeAccount: filterObj.consignee.selectedAccount,
        supplierAccount: filterObj.supplier.selectedAccount,
        importerAccount: filterObj.importer.selectedAccount,
        importerOfRecordAccount: filterObj.importerOfRecord.selectedAccount,
        loadingPort: filterObj.allLocation.selectedValue[0].loadingPortValue,
        dischargePort:
          filterObj.allLocation.selectedValue[1].dischargePortValue,
        portOfClearance:
          filterObj.allLocation.selectedValue[4].portOfClearanceValue,
        shipmentOrigin:
          filterObj.allLocation.selectedValue[2].shipmentOriginValue,
        shipmentDestination:
          filterObj.allLocation.selectedValue[3].shipmentDestinationValue,
        loadingPortAcc: filterObj.allLocation.selectedAccount[0].loadingPort,
        dischargePortAcc:
          filterObj.allLocation.selectedAccount[1].dischargePort,
        portOfClearanceAcc:
          filterObj.allLocation.selectedAccount[4].portOfClearance,
        shipmentOriginAcc:
          filterObj.allLocation.selectedAccount[2].shipmentOrigin,
        shipmentDestinationAcc:
          filterObj.allLocation.selectedAccount[3].shipmentDestination,
        customsId: filterURl.customsId,
      });
    } else if (filterURl.type === 'defaultFilter') {
      this.selectedDefualtUrlFilters.emit({
        mainfilter: filterURl.mainFilter,
      });
    }
  }

  setShareFilters(filterURl: any, filterObj: any) {
    filterURl?.mainFilter?.forEach((element: any) => {
      this.filterItem.find((ele) => {
        if (ele.val === element.id) {
          ele.checked = true;
        }
      });
      if (element.id == '0') {
        this.ApplyElementCase2(filterObj.transport, element);
      } else if (element.id == '1') {
        this.ApplyElementCase0(filterObj.status, element);
      } else if (element.id == '2') {
        this.getShipperList('');
        this.mapFilterData(
          element,
          '',
          filterObj.shipper.selectedAccount,
          this.shipperList
        );
      } else if (element.id == '3') {
        this.getConsigneeList('');
        this.mapFilterData(
          element,
          '',
          filterObj.consignee.selectedAccount,
          this.consigneeList
        );
      } else if (element.id == '11') {
        this.getSupplierList('');
        this.mapFilterData(
          element,
          '',
          filterObj.supplier.selectedAccount,
          this.supplierList
        );
      } else if (element.id == '12') {
        this.getImporterList('');
        this.mapFilterData(
          element,
          '',
          filterObj.importer.selectedAccount,
          this.importerList
        );
      } else if (element.id == '10') {
        this.getImporterOfRecordList('');
        this.mapFilterData(
          element,
          '',
          filterObj.importerOfRecord.selectedAccount,
          this.importerOfRecordList
        );
      } else if (element.id == '4') {
        this.mapFilterData(
          element,
          '',
          '',
          filterObj.allLocation.selectedAccount
        );
      } else if (element.id == '5') {
        this.mapFilterData(
          element,
          '',
          '',
          filterObj.dateRange.selectedDateRange
        );
      } else if (element.id == '6') {
        this.ApplyElementCase1(filterObj.exception, element);
      } else if (element.id == '8') {
        this.ApplyElementCase3(filterObj.customs, element);
      } else if (element.id == '7') {
        this.mapFilterData(element, 'Watchlist', '', filterURl.watchFilter);
      } else if (element.id == '9') {
        this.mapFilterData(element, 'pga', '', filterURl.pgaFilter);
      }
    });
  }

  ApplyElementCase0(status: any, element: any) {
    if (status) {
      let statusChecked = status[0]?.value?.split(',');
      statusChecked?.forEach((ele: any) => {
        let elementPos = this.statusFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        if (elementPos > -1) {
          this.statusFilterItem[elementPos].checked = true;
        } else {
          this.statusFilterItem[elementPos].checked = false;
        }
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.statusFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.statusFilterItem);
  }

  ApplyElementCase1(exception: any, element: any) {
    if (exception) {
      let exceptionChecked = exception[0]?.value?.split(',');
      exceptionChecked?.forEach((ele: any) => {
        let elementPos = this.exceptionsFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        elementPos > -1
          ? (this.exceptionsFilterItem[elementPos].checked = true)
          : (this.exceptionsFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.exceptionsFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.exceptionsFilterItem);
  }

  ApplyElementCase2(transport: any, element: any) {
    if (transport) {
      let transportChecked = transport[0]?.value?.split(',');
      transportChecked?.forEach((ele: any) => {
        let elementPos = this.transportFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        elementPos > -1
          ? (this.transportFilterItem[elementPos].checked = true)
          : (this.transportFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.transportFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.transportFilterItem);
  }

  ApplyElementCase3(customs: any, element: any) {
    if (customs) {
      let customsChecked = customs[0]?.value?.split(',');
      customsChecked?.forEach((ele: any) => {
        let elementPos = this.customsTypeFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        elementPos > -1
          ? (this.customsTypeFilterItem[elementPos].checked = true)
          : (this.customsTypeFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.customsTypeFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.customsTypeFilterItem);
  }

  trimDisplayStringCustomFilter(filterArray: any, filterDisplayName: any) {
    let displayText = '';
    filterArray
      .filter((t: any) => t.checked)
      .map((t: any) => (displayText += t.filt + ', '));
    if (displayText.trim().length > 5) {
      displayText = displayText.replace(/,\s*$/, '');
    }
    displayText = displayText?.trim();
    displayText = displayText.replace(/,\s*$/, '');
    if (displayText?.length > 5) {
      displayText =
        filterDisplayName + ' : ' + displayText?.substring(0, 5) + '...';
    } else {
      displayText = filterDisplayName + ' : ' + displayText;
    }
    return displayText;
  }

  mapFilterData(
    element: any,
    displayText: any,
    selectedAcc: any,
    filterArray: any
  ) {
    let mapData = {
      id: element.id,
      filterDisplayName: displayText ? displayText : element.filterDisplayName,
      selectedAccount: selectedAcc,
      type: element.type,
      index: element.index,
      filterArray: filterArray,
    };
    this.selectedMainFilter.push(mapData);
    this.setMobileFilterData(element.id, mapData);
  }

  onSearchHandler(event: any) {
    this.appInsightsService.logEvent('Customs Data Searched', {
      'Search Text': this.customsSearchText.trim(),
    });
    this.onCustomsSearchHandler.emit({
      searchText: this.customsSearchText.trim(),
      event,
    });
  }

  clearSearchText(event: any) {
    this.appInsightsService.logEvent('Customs Data Search Cleared');
    if (!event) {
      this.clearSearchFilter.emit(event);
      this.customsSearchText = '';
    }
  }

  onSortHandler(sortOption: any) {
    if (sortOption.id === this.defaultSortSelectedValue) {
      this.defaultSortDirection =
        this.defaultSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.defaultSortDirection = 'asc';
    }
    sortOption.sort_direction = this.defaultSortDirection;
    this.defaultSortSelectedColumn = sortOption.name;

    this.appInsightsService.logEvent('Customs Sort Applied', {
      'Sort Column': sortOption.name,
      'Sort Direction': sortOption.sort_direction,
    });
    this.onSortSelectedHandler.emit(sortOption);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  closeWatchFilter() {
    let event = {
      type: 'favorites',
      id: '7',
      eventType: 'closeBtn',
      index: 7,
      closeFrom: '',
    };

    this.closeCustomFilters(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  closePGAExistsFilter() {
    let event = {
      type: 'pga',
      id: '9',
      eventType: 'closeBtn',
      index: 9,
      closeFrom: '',
    };

    this.closeCustomFilters(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  watchlistSelected(event: any) {
    if (event.target.checked) {
      this.checkWatchlist = event.target.checked;
      this.isFilterSelected(true, 7, 'Watchlist', 'watchlist', 7);
    } else {
      this.checkWatchlist = event.target.checked;
      this.closeWatchFilter();
    }
  }

  pgaExistSelected(event: any) {
    if (event.target.checked) {
      this.checkPgaExists = event.target.checked;
      this.isFilterSelected(true, 9, 'PGA Exists', 'pga', 9);
    } else {
      this.checkPgaExists = event.target.checked;
      this.closePGAExistsFilter();
    }
  }

  getDateRange(event: any) {
    this.trackAIDateRangeFilter(event);
    this.selectedDateRange.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  excelExport(download: any) {
    this.appInsightsService.logEvent('Customs List Excel Data Downloaded');
    this.downloadExcel.emit(download);
  }

  closeSharePopUp() {
    this.customsSharePopup = false;
  }

  share(event: any) {
    this.appInsightsService.logEvent('Customs List Share Clicked');
    this.customsSharePopup = true;
    this.prepareUrlShare.emit({
      type: 'All',
      shipmentId: this.customsSearchText,
    });
  }

  getImporterOfRecordList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getImporterOfRecordFilter(
        searchText,
        this.offsetImporterOfRecord,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (locationL.result && locationL.result != null) {
          if (this.offsetImporterOfRecord >= 10) {
            this.isProgressLocation = false;
            this.importerOfRecordList = [
              ...this.importerOfRecordList,
              ...locationL.result,
            ];
          } else {
            this.isProgressLocation = false;
            this.importerOfRecordList = locationL.result;
          }
        } else {
          this.importerOfRecordList = [];
        }
      });
  }

  getImporterList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getImporterFilter(
        searchText,
        this.offsetImporter,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (locationL.result && locationL.result != null) {
          if (this.offsetImporter >= 10) {
            this.isProgressLocation = false;
            this.importerList = [...this.importerList, ...locationL.result];
          } else {
            this.isProgressLocation = false;
            this.importerList = locationL.result;
          }
        } else {
          this.importerList = [];
        }
      });
  }

  getShipperList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getShipperFilter(
        searchText,
        this.offsetShipper,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (locationL.result && locationL.result != null) {
          if (this.offsetShipper >= 10) {
            this.isProgressLocation = false;
            this.shipperList = [...this.shipperList, ...locationL.result];
          } else {
            this.isProgressLocation = false;
            this.shipperList = locationL.result;
          }
        } else {
          this.shipperList = [];
        }
      });
  }

  getSupplierList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getSupplierFilter(
        searchText,
        this.offsetSupplier,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (locationL.result && locationL.result != null) {
          if (this.offsetSupplier >= 10) {
            this.isProgressLocation = false;
            this.supplierList = [...this.supplierList, ...locationL.result];
          } else {
            this.isProgressLocation = false;
            this.supplierList = locationL.result;
          }
        } else {
          this.supplierList = [];
        }
      });
  }

  getConsigneeList(searchText: any) {
    this.isProgressLocation = true;
    this.customsService
      .getConsigneeFilter(
        searchText,
        this.offsetConsignee,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (locationL.result && locationL.result != null) {
          if (this.offsetConsignee >= 10) {
            this.isProgressLocation = false;
            this.consigneeList = [...this.consigneeList, ...locationL.result];
          } else {
            this.isProgressLocation = false;
            this.consigneeList = locationL.result;
          }
        } else {
          this.consigneeList = [];
        }
      });
  }

  currentShipperTypeFilter(id: number) {
    if (id === 10) {
      return this.importerOfRecordList;
    } else if (id === 2) {
      return this.shipperList;
    } else if (id === 3) {
      return this.consigneeList;
    } else if (id === 11) {
      return this.supplierList;
    } else if (id === 12) {
      return this.importerList;
    } else {
      return null;
    }
  }

  currentShippertTypeFilerRecent(id: number) {
    if (id === 10) {
      return this.recentListImporterOfRecord;
    } else if (id === 2) {
      return this.recentListShipper;
    } else if (id === 3) {
      return this.recentListConsignee;
    } else if (id === 11) {
      return this.recentListSupplier;
    } else if (id === 12) {
      return this.recentListImporter;
    } else {
      return null;
    }
  }

  getFilterOffset(id: number) {
    if (id === 10) {
      return this.offsetImporterOfRecord;
    } else if (id === 2) {
      return this.offsetShipper;
    } else if (id === 3) {
      return this.offsetConsignee;
    } else if (id === 11) {
      return this.offsetSupplier;
    } else if (id === 12) {
      return this.offsetImporter;
    } else {
      return null;
    }
  }

  initialRecentLocation() {
    setTimeout(() => {
      let recentConsignee = sessionStorage.getItem('customsRecentConsignee');
      let recentShipper = sessionStorage.getItem('customsRecentShipper');
      let recentImporter = sessionStorage.getItem('customsRecentImporter');
      let recentSupplier = sessionStorage.getItem('customsRecentSupplier');
      let recentLoading = sessionStorage.getItem('CustomsRecentLoadingPort');
      let recentDischarge = sessionStorage.getItem(
        'CustomsRecentDischargePort'
      );
      let recentPortOfClearance = sessionStorage.getItem(
        'CustomsRecentPortOfClearance'
      );
      let recentShipmentOrigin = sessionStorage.getItem(
        'CustomsRecentShipmentOrigin'
      );
      let recentShipmentDestination = sessionStorage.getItem(
        'CustomsRecentShipmentDestination'
      );
      let recentImporterOfRecord = sessionStorage.getItem(
        'customsRecentImporterOfRecord'
      );
      this.recentListImporterOfRecord =
        recentImporterOfRecord != null
          ? JSON.parse(recentImporterOfRecord)
          : [];
      this.recentListShipper =
        recentShipper != null ? JSON.parse(recentShipper) : [];
      this.recentListConsignee =
        recentConsignee != null ? JSON.parse(recentConsignee) : [];
      this.recentListSupplier =
        recentSupplier != null ? JSON.parse(recentSupplier) : [];
      this.recentListImporter =
        recentImporter != null ? JSON.parse(recentImporter) : [];
      this.resentListLoadingPort =
        recentLoading != null ? JSON.parse(recentLoading) : [];
      this.resentListDischargePort =
        recentDischarge != null ? JSON.parse(recentDischarge) : [];
      this.resentListPortOfClearance =
        recentPortOfClearance != null ? JSON.parse(recentPortOfClearance) : [];
      this.resentListShipmentOrigin =
        recentShipmentOrigin != null ? JSON.parse(recentShipmentOrigin) : [];
      this.resentListShipmentDestination =
        recentShipmentDestination != null
          ? JSON.parse(recentShipmentDestination)
          : [];
      setTimeout(() => {
        this.resentAllLocationList = [
          { recentLoadingList: this.resentListLoadingPort },
          { recentDischargeList: this.resentListDischargePort },
          { recentPortOfClearanceList: this.resentListPortOfClearance },
          { recentShipmentOriginList: this.resentListShipmentOrigin },
          { recentShipmentDestinationList: this.resentListShipmentDestination },
        ];
      }, 300);
    }, 1000);
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  saveMyFilter() {
    let filterName = this.myFilterName.trim();
    if (filterName) {
      this.mainFilterValueCheck();
      this.saveFilterJson.mainFilter.forEach((e: any) => {
        e.filterDisplayName = e.filterDisplayName.includes(':')
          ? e.filterDisplayName?.split(':')[0]
          : e.filterDisplayName;
      });

      this.isLoading.emit(true);
      this.customsService
        .saveUserFilter(
          this.currentUserDetails.emailAddress,
          filterName,
          this.saveFilterJson,
          this.currentAccDetails.accountId,
          this.currentAccDetails.isGroup
        )
        .subscribe((res: any) => {
          if (res.statusCode == 'OC200') {
            this.isShowSaveAsBtn = false;
            this.isShowSaveBtn = false;
            this.myFilterName = '';
            this.createUserFilterPopUp = false;
            this.tempSortDiff = '';
            this.isfilterEdit = '';
            this.myEditFilterName = '';
            this.tempFilterDiff = '';
            this.appInsightsService.logEvent('User Filter Saved', {
              'Filter Name': filterName,
            });
            this.onUserFilterSaved.emit({
              message: 'Filter has been saved successfully',
              type: 'save',
              filterName: filterName,
            });
            this.isLoading.emit(false);
          } else if (res.statusCode == 'OC401') {
            this.myFilterError = true;
            this.isLoading.emit(false);
            setTimeout(() => {
              this.myFilterError = false;
            }, 3000);
          }
        });
    } else {
      this.myFilterNameEmpty = true;
    }
  }

  mainFilterValueCheck() {
    if (this.saveFilterJson.mainFilter.length <= 0) {
      if (Object.keys(this.saveFilterJson.statusFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 1,
          filterDisplayName: 'Status',
          type: 'statuscustom',
          index: 1,
        });
      }
      if (Object.keys(this.saveFilterJson.transportType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 0,
          filterDisplayName: 'Transport Type',
          type: 'transport',
          index: 0,
        });
      }
      if (Object.keys(this.saveFilterJson.exceptionType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 6,
          filterDisplayName: 'Exception Type',
          type: 'exception',
          index: 6,
        });
      }
      if (Object.keys(this.saveFilterJson.customsType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 8,
          filterDisplayName: 'Customs Type',
          type: 'customstype',
          index: 8,
        });
      }
      if (this.saveFilterJson.shipper.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 2,
          filterDisplayName: 'Shipper',
          type: 'shipper',
          index: 2,
        });
      }
      if (this.saveFilterJson.consignee.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 3,
          filterDisplayName: 'Consignee',
          type: 'consignee',
          index: 3,
        });
      }
      if (this.saveFilterJson.supplier.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 11,
          filterDisplayName: 'Supplier',
          type: 'supplier',
          index: 11,
        });
      }
      if (this.saveFilterJson.importer.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 12,
          filterDisplayName: 'Importer',
          type: 'importer',
          index: 12,
        });
      }
      if (this.saveFilterJson.importerOfRecord.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 10,
          filterDisplayName: 'Importer Of Record',
          type: 'importer_of_record',
          index: 10,
        });
      }
      if (
        this.saveFilterJson.allLocation.selectedAccount[0].loadingPort.length >
          0 ||
        this.saveFilterJson.allLocation.selectedAccount[1].dischargePort
          .length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[2].shipmentOrigin
          .length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[3].shipmentDestination
          .length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[4].portOfClearance
          .length > 0
      ) {
        this.saveFilterJson.mainFilter.push({
          id: 4,
          filterDisplayName: 'Location',
          type: 'location',
          index: 4,
        });
      }
      if (this.saveFilterJson.dateRange.selectedDateRange.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 5,
          filterDisplayName: 'Date Range',
          type: '',
          index: 5,
        });
      }
      if (Object.keys(this.saveFilterJson.watchFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 7,
          filterDisplayName: 'Watchlist',
          type: '',
          index: 7,
        });
      }
      if (Object.keys(this.saveFilterJson.pgaFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 9,
          filterDisplayName: 'PGA Exists',
          type: '',
          index: 9,
        });
      }
    }
  }

  saveAsButtonVisibility() {
    this.myEditFilterName = '';
    this.myFilterName = '';
    if (!this.isMobileViewParent && this.paramCheck) {
      let filterValue = this.paramCheck.filter_options;
      let isEdit = this.isfilterEdit?.isEditTrue || false;
      if (!this.tempSortDiff) {
        this.tempSortDiff = this.paramCheck.sort_options;
      }
      setTimeout(() => {
        if (
          JSON.stringify(this.paramCheck) !=
            JSON.stringify(this.tempFilterDiff) &&
          filterValue.length > 0
        ) {
          this.isEditFn(isEdit);
        } else if (
          JSON.stringify(this.paramCheck) == JSON.stringify(this.tempFilterDiff)
        ) {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
        } else {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          this.clearAllFilterValue.emit('saveButton');
        }
      }, 100);
    }
  }

  isEditFn(isEdit: boolean) {
    this.isShowSaveAsBtn = true;
    if (isEdit) {
      this.isShowSaveBtn = true;
    } else {
      this.isShowSaveBtn = false;
    }
  }

  setUpdateFilterName() {
    this.isShowEditPopup = true;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
    this.myEditFilterName = this.isfilterEdit.savedValue.filterName;
  }

  updateUserFilter() {
    this.callPrepareShareUrl();
    setTimeout(() => {
      let filterJson: any = '';
      let msgText: any = '';
      if (this.myEditFilterName != '' && this.isShowEditPopup) {
        filterJson = this.isfilterEdit.savedValue.jsonFilter;
        msgText = this.myEditFilterName;
      } else if (this.myEditFilterName == '' && !this.isShowEditPopup) {
        msgText = this.isfilterEdit.savedValue.filterName;
        this.mainFilterValueCheck();
        this.saveFilterJson.mainFilter.forEach((e: any) => {
          e.filterDisplayName = e.filterDisplayName.includes(':')
            ? e.filterDisplayName?.split(':')[0]
            : e.filterDisplayName;
        });
        filterJson = JSON.stringify(this.saveFilterJson);
      }
      if (msgText) {
        this.isLoading.emit(true);
        this.customsService
          .updateUserFilter(
            this.isfilterEdit.savedValue,
            filterJson,
            msgText,
            this.currentAccDetails.accountId,
            this.currentAccDetails.isGroup
          )
          .subscribe((res: any) => {
            if (res.statusCode == 'OC200') {
              this.isShowSaveAsBtn = false;
              this.isShowSaveBtn = false;
              this.tempSortDiff = '';
              this.myEditFilterName = '';
              this.isShowEditPopup = false;
              this.isLoading.emit(false);
              this.onUserFilterSaved.emit({
                message: msgText + ' Saved filter Updated',
                type: 'update',
                filterName: msgText,
              });
              this.isfilterEdit = '';
              this.tempFilterDiff = '';
              this.tempSortDiff = '';
            } else if (res.statusCode == 'OC401') {
              this.myFilterError = true;
              this.isLoading.emit(false);
              setTimeout(() => {
                this.myFilterError = false;
              }, 3000);
            }
          });
      } else {
        this.myFilterNameEmpty = true;
      }
    }, 400);
  }

  callPrepareShareUrl() {
    setTimeout(() => {
      this.prepareUrlShare.emit({
        type: 'All',
        customsId: this.customsSearchText,
      });
    }, 100);
  }

  deleteUserFilter() {
    this.isLoading.emit(true);
    this.customsService
      .deleteUserFilter(
        this.currentUserDetails.emailAddress,
        this.isfilterEdit.savedValue.id
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          //  this.appInsightsService.logEvent('User Filter Deleted', {
          //    'Filter Name': filterName,
          //  });
          this.isShowEditPopup = false;
          this.tempSortDiff = '';
          this.isLoading.emit(false);
          this.onUserFilterSaved.emit({
            message:
              this.isfilterEdit.savedValue.filterName + ' Filter Deleted',
            type: 'delete',
            filterName: this.isfilterEdit.savedValue.filterName,
          });
          this.tempUndoArray = this.isfilterEdit.savedValue;
          this.clearAllFilters();
          this.clearAllFilterValue.emit('clearAll');
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          setTimeout(() => {
            this.tempUndoArray = '';
          }, 5000);
        }
      });
  }

  undoChanges() {
    this.isLoading.emit(true);
    this.customsService
      .saveUserFilter(
        this.currentUserDetails.emailAddress,
        this.tempUndoArray.filterName,
        this.tempUndoArray.jsonFilter,
        this.currentAccDetails.accountId,
        this.currentAccDetails.isGroup
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          this.isLoading.emit(false);
          this.onUserFilterSaved.emit({
            message: this.tempUndoArray.filterName + ' Filter Restored',
            type: 'save',
          });
          this.tempUndoArray = '';
        }
      });
  }

  selectedUserFilter(userFilterRow: any) {
    this.mobileSelectedFilter = userFilterRow.id;
    this.userFilterFetch.emit({ userFilterRow: userFilterRow, type: 'view' });
  }

  closeUserFilterPopUp() {
    this.createUserFilterPopUp = false;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
  }

  closeEditUserFilterPopUp() {
    this.isShowEditPopup = false;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
  }

  openSaveFilterPopUp() {
    this.createUserFilterPopUp = true;
    this.prepareUrlShare.emit({
      type: 'All',
      customsId: this.customsSearchText,
    });
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
  }

  trackAIDateRangeFilter(event: any) {
    let filterParams: any[] = [];
    event?.selectedDateRange.forEach((e: any) => {
      filterParams.push({
        'Filter Type': e.status,
        'From Date': e.fromDate,
        'To Date': e.toDate,
      });
    });
    if (filterParams.length > 0) {
      this.appInsightsService.logEvent('Customs Date Filter Applied', {
        Filters: JSON.stringify(filterParams),
      });
    }
  }
}
