import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  CommonUtils
} from 'src/app/common/utils';
@Pipe({
  name: 'findKeyByValue',
})
export class FindKeyByValuePipe implements PipeTransform {
  transform(value: any, type: any, columnProperty: any) {
    if(type?.trim()?.toLowerCase() === 'json element') {
      let objArray = columnProperty?.split('.');
      objArray?.splice(0, 1); // To remove shipemnts. from JSON and use remaining props
      let result = value;
      objArray?.forEach((e: any) => {
        if(e == "customs_declarations") { // hard coded on BA's request dur to bug - 106832
          if(result[e]?.length > 0) {
            result = result[e][0];
          }
        } else {
          result = result[e];
        }
      });
      return result;
    } else if(type?.trim()?.toLowerCase() === 'action field') {
      let result = '';
      value?.action_fields?.forEach((e: any) => {
        if(e.actionFieldName === columnProperty) {
          e.actionFieldValue?.forEach((f: any) => {
            result += f?.value + ' ';
          });
        }
      });
      return result;
    } else {
      let result = '';
      value?.dataFieldValues?.forEach((e: any) => {
        if(e?.displayValue?.trim() != "" && e?.displayValue != null && e?.dataFieldName == columnProperty) {
          result += e?.displayValue + ", "
        }
      })
      return CommonUtils.removeLastComma(result?.trim());
    }
  }
}