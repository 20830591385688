<nav class="navbar navbar-default navbar-fixed-top navbar-expand-md shadow-sm bg-white" *ngIf="loginDisplay"
  aria-label="loginDisplay">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" id="mobileMenu" data-bs-toggle="collapse" data-bs-target="#main_nav"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="">
        <img src="assets/img/mobile-menu-btn.png" [ngClass]="!isMobileSearch ? 'mobile-show' : 'mobile-hide'"
          class="mobile-toggle-open" alt="" />
        <img src="assets/img/mobile-Menu-Close-Btn.png" class="mobile-toggle-close" alt="" />
      </span>
    </button>
    <img src="assets/img/mobile-Menu-Close-Btn.png" *ngIf="isMobileSearch" class="Mobile-Menu-Btn mobileclose-menu"
      (click)="closeSearch()" alt="" />
    <a class="navbar-brand logoimg" href="javascript:void(0);">
      <img id="oiaLogo" (click)="logoClick()" src="assets/img/oia-logo.svg" class="OIA-Logo" alt="" />
    </a>

    <div class="collapse navbar-collapse" id="main_nav">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 menutextstyle">
        <li class="userfilter-right">
          <app-filter (isSessionCheck)="sessionCheck()"></app-filter>
        </li>
        <li class="nav-item largedevice" id="top-menu-dashboard">
          <a id="dashboardLink" class="nav-link cool-link" [ngClass]="isDashboardSelected ? 'active' : ''"
            aria-current="page" (click)="DashboardMenuSelected()">HOME</a>
        </li>
        <li class="nav-item largedeviceli" id="top-menu-shipment" *ngIf="showShipment">
          <a id="shipmentLink" class="nav-link cool-link" [ngClass]="isShipmentSelected ? 'active' : ''"
            (click)="ShipmentMenuSelected()">SHIPMENTS</a>
        </li>
        <li class="nav-item largedeviceli" id="top-menu-shipment" *ngIf="showBooking">
          <a id="bookingLink" class="nav-link cool-link" [ngClass]="isBookingsSelected ? 'active' : ''"
            (click)="BookingMenuSelected()">BOOKINGS</a>
        </li>
        <li class="nav-item largedeviceli" id="top-menu-shipment" *ngIf="showCustoms">
          <a id="customsLink" class="nav-link cool-link" [ngClass]="isCustomsSelected ? 'active' : ''"
            (click)="CustomsMenuSelected()">CUSTOMS</a>
        </li>
        <li class="nav-item largedeviceli" id="top-menu-shipment" *ngIf="showReport">
          <a id="reportLink" class="nav-link cool-link" [ngClass]="isReportSelected ? 'active' : ''"
            routerLink="/reports" title="Reports" (click)="reportMenuSelected()">
            REPORTS
          </a>
        </li>
      </ul>
    </div>
    <div class="user-info">
      <ul class="nav mb-2 mb-lg-0">
        <li class="nav-item">
          <div class="quicksearch-container">
            <div class="gs-search" #searchIcon>
              <input type="text" class="gs-input" (click)="quickSearch()"
                [ngClass]="{ 'gs-input-expand': searchExpand }" placeholder="Search" [(ngModel)]="shipmentSearchText"
                #quicksearchBtn autocomplete="off" (paste)="onPaste($event)"
                (keyup.enter)="recentSearchSave(shipmentSearchText)" (keyup)="globalSearchList($event);
                globalSearchInput.next([shipmentSearchText, $event])" name="shipmentSearchText"
                (keyup.backspace)="clearGlobalSearchList(shipmentSearchText)" #searchTextbox />
              <span class="gs-icon" (click)="onSearchExpand($event); recentSearchSave(shipmentSearchText)">
                <img id="search-close-global" src="assets/img/close_whitebg_remove.svg"
                  [ngClass]="shipmentSearchText.length >0 ? 'show-icon' : 'hide-icon'"
                  (click)="clearGlobalSearch(); $event.stopPropagation()" alt="search-close-global" />
                <img id="globalSearchIconDesktop" src="assets/img/search_icon.svg" alt="globalSearchIconDesktop" />
              </span>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/faqs" title="FAQ Page">
            <img src="assets/img/support.png" srcset="
                assets/img/support@2x.png 2x,
                assets/img/support@3x.png 3x
              " alt="FAQ Page" />
          </a>
        </li>
        <li class="nav-item">
          <div class="dropdown">
            <a class="nav-link d-block link-dark text-decoration-none" id="dropdownUser1" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img id="profileSec" [src]="profileImgUrl" alt="" />
            </a>
            <ul class="dropdown-menu profile-dropdown-box" clickOutside (clickOutside)="opened = !opened"
              aria-labelledby="dropdownUser1" id="profileListDropdown">
              <ng-container *ngIf="!checkUnsavedBroadcastMsg">
                <li class="profile-popup-header">
                  <div class="d-flex align-items-center">
                    <div class="profile-desk-margin">
                      <img class="profileimage" [src]="profileImgUrl" alt="" />
                    </div>
                    <div class="usernamepadding-desk">
                      <h4 style="overflow: hidden" class="username1 same-class">
                        {{ userName }}
                      </h4>
                      <p style="overflow: hidden" class="useremail1 same-class font-set">
                        {{ userEmail }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="hover-bg">
                  <a class="dropdown-item" *ngIf="isAdmin" routerLink="/admin" (click)="closeDropDown()">{{
                    "menu.admin-link-text" | translate
                    }}</a>
                </li>
                <li class="hover-bg">
                  <a class="dropdown-item" href="javascript: void(0);" routerLink="/profile"
                    (click)="closeDropDown()">Profile</a>
                </li>
                <li class="hover-bg" *ngIf="showNPLink">
                  <a class="dropdown-item" href="javascript: void(0);"
                    routerLink="profile/notificationpreference">Notification Preferences</a>
                </li>
                <li class="hover-bg">
                  <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">Log Out</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="search-mobile" *ngIf="isMobileSearch">
      <div style="position: relative">
        <input type="text" class="gs-mobile-input" [ngClass]="{ 'gs-input-expand': searchExpand }" placeholder="Search"
          [(ngModel)]="shipmentSearchText" #quicksearchBtn (touchstart)="quickSearch()" autocomplete="off"
          (keyup.enter)="autoSuggestShipment(shipmentSearchText)" (keyup)="
            globalSearchList($event); globalSearchInput.next([shipmentSearchText, $event])
          " name="shipmentSearchText" (keyup.backspace)="clearGlobalSearchList(shipmentSearchText)" />
        <span class="mobile-search-icon" (click)="onSearchExpand($event); recentSearchSave(shipmentSearchText)"><img
            src="assets/img/search-icon-sm.svg" alt="" /></span>
      </div>
    </div>
  </div>
  <div #quicksearch *ngIf="isQuickSearch">
    <app-quick-search (closeQuickSearchModal)="closeQuickSearchModal($event)">
    </app-quick-search>
  </div>

  <div #quicksearch *ngIf="isGlobalSearch">
    <app-global-search-list [totalShipmentCount]="totalShipmentCount" [totalBookingCount]="totalBookingCount"
      [totalCustomsCount]="totalCustomsCount" [bookingAutoSuggestedRecord]="bookingAutoSuggestedRecord"
      [customsAutoSuggestedRecord]="customsAutoSuggestedRecord" (clearSearchTextBox)="clearSearchTextBox($event)"
      (clearSearchResult)="clearSearchResult($event)" [shipmentAutoSuggestedRecord]="shipmentAutoSuggestedRecord"
      [searchedText]="shipmentSearchText" [shipmentRoute]="shipmentRoute" [bookingRoute]="bookingRoute"
      [customsRoute]="customsRoute">
    </app-global-search-list>
  </div>
</nav>

<nav class="navbar navbar-default navbar-fixed-top navbar-expand-md shadow-sm bg-white" *ngIf="notLoggedIn"
  aria-labelledby="notLoggedIn">
  <div class="container-fluid">
    <a class="navbar-brand logoimg" routerLink="/dashboard" href="javascript:void(0);">
      <img src="assets/img/oia-logo.svg" class="OIA-Logo" alt="" />
    </a>
  </div>
</nav>