import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AI_CustomProps,
  AI_PageName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';

@Component({
  selector: 'app-customs-global-search',
  templateUrl: './customs-global-search.component.html',
  styleUrls: ['./customs-global-search.component.scss'],
})
export class CustomsGlobalSearchComponent implements OnChanges {
  defaultCustomsSortSelectedColumn: string = 'booking_confirmed_actual';
  defaultCustomsSortDirection: string = 'desc';
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  customsMasterData: any[] = [];
  isProgressShow: boolean = false;
  defaultCustomsFilterSelectedValue: any = 0;
  @Input() customsAutoSuggestedRecord: any[] = [];
  @Input() totalCustomsCount: number = 0;
  @Input() searchedText: any = '';
  globalSearchPopup = false;
  @Output() viewAllEmit = new EventEmitter<any>();

  constructor(
    private currentSessionUserData: CurrentSessionDataService,
    public router: Router,
    private appInsightsService: AppInsightsService
  ) {}

  ngOnChanges(): void {
    if (this.searchedText.length > 0) {
      this.globalSearchPopup = true;
    } else {
      this.globalSearchPopup = false;
    }
  }

  resultPage() {
    let val = this.searchedText;
    if (val.length > 2) {
      this.trackAISearchClickEvent(val);
      this.viewAllEmit.emit('emit');
      let filterVal = JSON.stringify({ val: val, from: 'Customs' });
      this.router.navigate(['/shipments/view/search-result'], {
        queryParams: { filterParam: filterVal },
        skipLocationChange: true,
      });
    }
  }

  trackAISearchClickEvent(searchText: any) {
    this.appInsightsService.logEvent(AI_PageName.GlobalSearch, {
      [AI_CustomProps.ModuleName]: 'Custom Global Search',
      [AI_CustomProps.SearchText]: searchText,
    });
  }
}
