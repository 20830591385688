import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';

@Injectable()
export class BookingServies {
  currentAccountData: any;
  isParent: boolean;
  public stopRequest: Subject<void> = new Subject<void>();
  isActionFieldChanged: boolean;
  openActionTab: boolean = false;
  actionFieldExist: boolean = false;
  switchTabInitiated: boolean = false;
  public activeBookingDetailTab: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  isFullScreenTabEnabled: boolean = false;

  constructor(
    private http: HttpClient,
    public currentUserDataService: CurrentSessionDataService
  ) {
    this.currentAccountData =
      this.currentUserDataService.getCurrentAccountData();
    this.isParent = this.currentAccountData.isParent;
  }

  getAllBookings(
    accountId: string,
    emailId: any,
    bodyParam: any,
    isGroup: boolean
  ): Observable<any> {
    this.getCurrentSessionData();
    let url =
      environment.base_api_desktop_url +
      'Booking?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;

    return this.http
      .post<any[]>(url, bodyParam)
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getBookingDetails(
    accountId: string,
    emailId: any,
    bookingId: any,
    isGroup: boolean
  ): Observable<any> {
    this.getCurrentSessionData();
    let params = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: [
        {
          name: 'bookingNumber',
          value: bookingId,
        },
      ],
      sort_options: [
        {
          column_name: 'first_leg_departure_actual',
          direction: 'desc',
        },
      ],
      filter_options: [],
    };
    let url =
      environment.base_api_desktop_url +
      'Booking?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;

    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  downloadFile(filePath: any): Observable<any> {
    const headers: Object = {
      responseType: 'Blob',
    };

    let bodyParam = {
      parameters: [
        {
          name: filePath,
        },
      ],
    };
    let url = environment.base_api_desktop_url + 'Download/Attachment';

    return this.http
      .post<any[]>(url, bodyParam, headers)
      .pipe(catchError(this.handelError));
  }

  getOriginFilter(
    searchText: any,
    offset: any,
    value: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'source',
          value: value,
        },
      ],
      sort_options: [
        {
          column_name: 'rL_PortName', //changed RL_Code to rL_PortName recently as per BE Request
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
          'Booking/Unlococode?accountId=' +
          accountID +
          '&userEmail=' +
          userEmail +
          '&isGroup=' +
          isGroup +
          '&isParent=' +
          this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  prepareExcelDowload(
    accountId: string,
    emailId: any,
    bodyParam: any,
    isGroup: any
  ): Observable<any> {
    this.getCurrentSessionData();
    let url =
      environment.base_api_desktop_url +
      'Booking/ExcelExport?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;
    const headers: Object = {
      responseType: 'Blob',
    };
    return this.http
      .post<any[]>(url, bodyParam, headers)
      .pipe(catchError(this.handelError));
  }

  addRemoveWatchList(bookingId: any, id: any, state: any) {
    let params = {
      forwarder_reference: bookingId,
      favorite: [{ userId: id }],
    };
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'Booking/WatchList/' + state,
        params
      )
      .pipe(catchError(this.handelError));
  }

  updateBookings(params: any): Observable<any> {
    let url =
      environment.base_api_desktop_url + 'ShipmentImport?isBooking=true';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }
  /*
  skipTour(email: any, params: any): Observable<any> {
    let url =
      environment.base_api_desktop_url + 'user/guidedtour?userEmail=' + email;
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }
   */
  saveUserFilter(
    userEmail: any,
    filterName: any,
    jsonArray: any,
    accountId: any,
    isGroup: boolean
  ) {
    let params = {
      userEmail: userEmail,
      filterName: filterName,
      jsonFilter:
        typeof jsonArray == 'object' ? JSON.stringify(jsonArray) : jsonArray,
      accountId: !isGroup ? accountId : '',
      groupId: isGroup ? accountId : '',
      filterType: 'booking',
    };
    let url = environment.base_api_desktop_url + 'Shipment/filter';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  updateUserFilter(
    data: any,
    jsonArray: any,
    filterName: any,
    accountId: any,
    isGroup: boolean
  ) {
    let params = {
      id: data.id,
      userEmail: data.userEmail,
      filterName: filterName,
      jsonFilter: jsonArray,
      accountId: !isGroup ? accountId : '',
      groupId: isGroup ? accountId : '',
      filterType: 'booking',
    };
    let url = environment.base_api_desktop_url + 'Shipment/filter';
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }

  getUserFilter(userEmail: any, accountId: any, isGroup: boolean) {
    let accId = !isGroup ? accountId : '';
    let groupId = isGroup ? accountId : '';
    let url =
      environment.base_api_desktop_url +
      'Shipment/filter/usershipment?userEmail=' +
      userEmail +
      '&accountId=' +
      accId +
      '&filterType=booking' +
      '&groupId=' +
      groupId;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  deleteUserFilter(userEmail: any, id: any) {
    let url = environment.base_api_desktop_url + 'Shipment/filter';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id,
        userEmail: userEmail,
        partitionId: userEmail,
        filterType: 'booking',
      },
    };

    return this.http
      .delete<any[]>(url, options)
      .pipe(catchError(this.handelError));
  }

  publicShare(
    accountId: any,
    userEmail: any,
    params: any,
    isGroup: boolean,
    isParent: boolean
  ) {
    let url = '';
    if (userEmail) {
      url =
        environment.base_api_publicShare_url +
        'Booking/limited?accountId=' +
        accountId +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        isParent;
    } else {
      url =
        environment.base_api_publicShare_url +
        'Booking/limited?accountId=' +
        accountId +
        '&userEmail=""&isGroup=' +
        isGroup +
        '&isParent=' +
        isParent;
    }

    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  getShipperFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
      ],
      sort_options: [
        {
          column_name: 'shipper.name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
          'booking/shipper?accountId=' +
          accountID +
          '&userEmail=' +
          userEmail +
          '&isGroup=' +
          isGroup +
          '&isParent=' +
          this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getPickupFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    value: any,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
        {
          name: 'source',
          value: value,
        },
      ],
      sort_options: [
        {
          column_name: 'ship_from.name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
          'Booking/PickupDelivery?accountId=' +
          accountID +
          '&userEmail=' +
          userEmail +
          '&isGroup=' +
          isGroup +
          '&isParent=' +
          this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getFinalDeliveryFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    value: any,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
        {
          name: 'source',
          value: value,
        },
      ],
      sort_options: [
        {
          column_name: 'ship_to.name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
          'Booking/PickupDelivery?accountId=' +
          accountID +
          '&userEmail=' +
          userEmail +
          '&isGroup=' +
          isGroup +
          '&isParent=' +
          this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: any) {
    return throwError(
      {
        status: error.status,
        statusText: error.statusText,
      } || 'Server Error'
    );
  }

  saveShareFilter(
    userEmail: any,
    filterName: any,
    jsonArray: any,
    accountId: any
  ) {
    let params = {
      userEmail: userEmail,
      filterName: filterName,
      jsonFilter:
        typeof jsonArray == 'object' ? JSON.stringify(jsonArray) : jsonArray,
      accountId: accountId,
      filterType: 'booking',
    };
    let url = environment.base_api_desktop_url + 'SharedUrl';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  getShareFilter(guid: any) {
    let url = environment.base_api_desktop_url + 'SharedUrl?guid=' + guid;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  skipTour(email: any, params: any): Observable<any> {
    let url =
      environment.base_api_desktop_url + 'user/guidedtour?userEmail=' + email;
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }

  getLogsCount(shipmentId: any, email: any): Observable<any> {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
          'Booking/ChangeLogCountByCat?shipmentNumber=' +
          shipmentId +
          '&userEmail=' +
          email
      )
      .pipe(catchError(this.handelError));
  }

  shipmentPublicSearch(shimpmentNumber: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'shipmentNumber',
          value: shimpmentNumber,
        },
      ],
    };
    return this.http
      .post<any[]>(
        environment.base_api_publicShare_url + 'Shipment/public_Search',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getCurrentSessionData() {
    this.currentAccountData =
      this.currentUserDataService.getCurrentAccountData();
    this.isParent = this.currentAccountData.isParent
      ? this.currentAccountData.isParent
      : false;
  }
  // end of service code
}
