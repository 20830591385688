import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeText',
})
export class DateTimeTextPipe implements PipeTransform {
  monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  getMonthYearFormatted(dateSelected: any) {
    let date = new Date(dateSelected);
    return (
      this.monthNames[date.getMonth()] +
      ' ' +
      date.getDate() +
      ' ,' +
      date.getFullYear()
    );
  }

  getDate(value: any, hasBetween: boolean) {
    if (hasBetween) {
      let date = value?.split(',');
      return (
        this.getMonthYearFormatted(date[0]) +
        ' to ' +
        this.getMonthYearFormatted(date[1])
      );
    } else {
      return this.getMonthYearFormatted(value?.split(','));
    }
  }

  convertTimeTo12HrUTC(time: any) {
    // 18:00:00
    const timeString12hr = new Date(
      '1970-01-01T' + time + 'Z'
    ).toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });

    return timeString12hr;
  }

  transform(value: any, conditionCompare: string) {
    let text: any = value;
    let dateRangeAlonePatten: any = /^[A-Za-z\s]*$/;
    let dateAlonePattern: any = /^(\d{2}\/\d{2}\/\d{4})$/i;
    let dateBetweenPattern: any =
      /^(\d{2}\/\d{2}\/\d{4},){1,}(\d{2}\/\d{2}\/\d{4})$/i;
    let dateRangeAndTime: any = /^[A-Za-z0-9\s]*T\d{2}:\d{2}Z$/;
    let dateRangeAndTimeBetween: any =
      /^[A-Za-z0-9\s]*T\d{2}:\d{2}Z,T\d{2}:\d{2}Z$/;
    let timeAloneBetweenPattern = /^T\d{2}:\d{2}Z, T\d{2}:\d{2}Z$/;
    let timeAlonePattern: any = /^T\d{2}:\d{2}Z$/;
    let dateTimePattern: any = /^(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z)$/i;
    let dateTimeBetweenPattern: any =
      /^(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z,){1,}(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z)$/i;

    let dateRange: any = [
      'Today',
      'This Week',
      'Yesterday',
      'Last Week',
      'Last Month',
      'Last 3 Months',
      'Last 6 Months',
      'Tomorrow',
      'Next Week',
      'Next Month',
      'Next 3 Months',
      'Next 6 Months',
    ];

    if (dateRangeAlonePatten.test(value)) {
      /** if value is date range **/
      text = dateRange.filter((e: any) => value.includes(e))[0];
    } else if (conditionCompare?.trim()?.toLowerCase() == 'between') {
      if (dateBetweenPattern.test(value)) {
        text = this.getDate(value, true);
      } else if (timeAloneBetweenPattern.test(value)) {
        /** T00:02Z,T00:02Z **/
        let fromTime = value.split(',')[0];
        let toTime = value.split(',')[1].trim();
        text =
          this.convertTimeTo12HrUTC(
            fromTime.substring(1, fromTime.length - 1) + ':00'
          ) +
          ' to ' +
          this.convertTimeTo12HrUTC(
            toTime.substring(1, toTime.length - 1) + ':00'
          );
      } else if (dateRangeAndTimeBetween.test(value)) {
        /** TodayT23:58Z,T23:58Z **/
        let range = dateRange.filter((e: any) => value.includes(e))[0];
        let from = value.split(",")[0];
        let toTime = value.split(",")[1];
        // console.log(from)
        // console.log(toTime)
        text = range + " " + this.convertTimeTo12HrUTC(from.substring(range.length + 1, from.length - 1) + ":00")
          + " to " + this.convertTimeTo12HrUTC(toTime.substring(1, toTime.length - 1) + ":00");
      }

      else if (dateTimeBetweenPattern.test(value)) {
        let from = value.split(",")[0];
        let to = value.split(",")[1];
        let fromDate = from.split("T")[0];
        let toDate = to.split("T")[0];
        text = this.getMonthYearFormatted(fromDate) + " " + this.convertTimeTo12HrUTC(from.substring(fromDate.length + 1, from.length - 1) + ":00")
          + " to " + this.getMonthYearFormatted(toDate) + " " + this.convertTimeTo12HrUTC(to.substring(toDate.length + 1, to.length - 1) + ":00");
      }

      else {  // exception date-range,Tundefined:undefinedZ)$
        text = dateRange.filter((e: any) => value.includes(e))[0];
      }
    } else {
      if (dateAlonePattern.test(value)) {
        text = this.getDate(value, false);
      } else if (timeAlonePattern.test(value)) {
        /** T00:02Z **/
        text = this.convertTimeTo12HrUTC(
          value.substring(1, value.length - 1) + ':00'
        );
      } else if (dateRangeAndTime.test(value)) {
        let range = dateRange.filter((e: any) => value.includes(e))[0];
        text =
          range +
          ' ' +
          this.convertTimeTo12HrUTC(
            value.substring(range.length + 1, value.length - 1) + ':00'
          );
      } else if (dateTimePattern.test(value)) {
        let date = value.split('T')[0];
        text =
          this.getMonthYearFormatted(date) +
          ' ' +
          this.convertTimeTo12HrUTC(
            value.substring(date.length + 1, value.length - 1) + ':00'
          );
      } else {
        // exception date-range,Tundefined:undefinedZ)$
        text = dateRange.filter((e: any) => value.includes(e))[0];
      }
    }
    return text;
  }
}
