<div class="location-rap">
  <div class="rap-desktop">
    <div id="dateRangeButton" class="btn filter-selct-btn position-relative w100 custom-arow ml-1"
      style="padding-top: 6px" id="dropdownMenuButton" [ngClass]="{'borderSurrounding':accountDropdownToggle}"
      (click)="statusTextBoxHandler()">
      {{ selectedFilterText }}
      <span><img src="../../../../../assets/img/down_arrow-lg.svg" alt=""
          [ngClass]="{'rotate-arrow':accountDropdownToggle}" /></span>
      <span><img src="../../../../../assets/img/close-sm.png" class="close-sm" alt=""
          (click)="clearFilters(listData.index, listData.id)" /></span>
    </div>
  </div>
  <div *ngIf="accountDropdownToggle" class="rap-sec desktop" class="{{
      loadingPortFilterSelectedList.length > 0 ||
      dischargePortFilterSelectedList.length > 0 ||
      pocFilterSelectedList.length > 0 ||
      shipmentOriginFilterSelectedList.length > 0 ||
      shipmentDestinationFilterSelectedList.length > 0
        ? 'add-selection'
        : 'remove-selection'
    }}">
    <div (click)="CloseOut()" class="all-location-backdrop"></div>
    <div class="filterRange-wrap-bookings">
      <div class="flter-dateRange" id="dateRangeDropdown">
        <div class="date-range">
          <ul>
            <li [ngClass]="{ 'active-class': locationFilterValue == filter.status }"
              *ngFor="let filter of locationFilter" (click)="selectLocationDebounce.next(filter)">
              {{ filter.status }}
            </li>
          </ul>
        </div>
        <div class="nav-conainer add-border">
          <ng-container>
            <app-progressbar *ngIf="isProgress"></app-progressbar>
          </ng-container>
          <div class="accountStatusFilterDropdown">
            <ul class="statusList">
              <div class="search-area">
                <input type="text" class="form-control" style="height: 28px !important" name="search"
                  placeholder="Search for City" [(ngModel)]="searchText" (keyup)="getSearchLocationList(searchText)"
                  (keyup.backspace)="clearData(searchText)" (keyup.enter)="onSearchHandler(searchText)"
                  autocomplete="off" ngDefaultControl #searchTextbox />
                <span class="right-set-search">
                  <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-1"
                    *ngIf="searchText.length >0"
                    (click)="searchTextbox.value='';searchText = '';getSearchLocationList('');">
                  <img class="search-set" src="assets/img/Iconfeather-search.png" />
                </span>
              </div>
              <div class="scroll-sec" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                [scrollWindow]="false" (scrolled)="onScroll(searchText)">
                <ng-container>
                  <div *ngIf="
                       locationFilterValue == 'Loading Port'   &&
                        loadingPortRecentList.length > 0
                    ">
                    <h6 class="mb-1">Recent Locations</h6>
                    <div *ngFor="
                        let show of loadingPortRecentList;
                        let indexOfelement = index
                      " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                      <div class="con-set">
                        <span class="move-right data-up">
                          <span class="list-data">{{
                            show.portName | titlecase
                            }}, {{ show.countryName | titlecase
                            }}</span>
                        </span>
                        <span class="move-left">
                          <span class="list-data">
                            {{ show.unlocode}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                     locationFilterValue == 'Discharge Port'  &&
                      dischargePortRecentList.length > 0
                    ">
                    <h6 class="mb-1">Recent Locations</h6>
                    <div *ngFor="
                        let show of dischargePortRecentList;
                        let indexOfelement = index
                      " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                      <div class="con-set">
                        <span class="move-right data-up">
                          <span class="list-data">{{
                            show.portName | titlecase
                            }}, {{ show.countryName | titlecase
                            }}</span>
                        </span>
                        <span class="move-left">
                          <span class="list-data">
                            {{ show.unlocode}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                       locationFilterValue == 'Port of Clearance' &&
                      portOfClearanceRecentList.length > 0
                    ">
                    <h6 class="mb-1">Recent Locations</h6>
                    <div *ngFor="
                        let show of portOfClearanceRecentList;
                        let indexOfelement = index
                      " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                      <div class="con-set">
                        <span class="move-right data-up port-of-c">
                          <span
                            class="list-data">{{manipulateData(show.port_of_clearance_description)}}{{checkCountryCode(show)}}
                          </span>
                        </span>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                     locationFilterValue == 'Shipment Origin'   &&
                      shipmentOriginRecentList.length > 0
                    ">
                    <h6 class="mb-1">Recent Locations</h6>
                    <div *ngFor="
                        let show of shipmentOriginRecentList;
                        let indexOfelement = index
                      " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                      <div class="con-set">
                        <span class="move-right data-up">
                          <span class="list-data">{{
                            show.portName | titlecase
                            }}, {{ show.countryName | titlecase
                            }}</span>
                        </span>
                        <span class="move-left">
                          <span class="list-data">
                            {{ show.unlocode}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                     locationFilterValue == 'Shipment Destination'  &&
                      shipmentDestinationRecentList.length > 0
                    ">
                    <h6 class="mb-1">Recent Locations</h6>
                    <div *ngFor="
                        let show of shipmentDestinationRecentList;
                        let indexOfelement = index
                      " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                      <div class="con-set">
                        <span class="move-right data-up">
                          <span class="list-data">{{
                            show.portName | titlecase
                            }}, {{ show.countryName | titlecase
                            }}</span>
                        </span>
                        <span class="move-left">
                          <span class="list-data">
                            {{ show.unlocode}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container>
                  <h6 class="mb-1 mt-2">All Locations</h6>
                  <div>
                    <div class="status-text" *ngFor="
                        let show of allLocationList;
                        let indexOfelement = index
                      " (click)="locationFilterSelected(show, indexOfelement)">
                      <ng-container *ngIf="allLocationList?.length > 0">
                        <div class="con-set" *ngIf="
                            locationFilterValue == 'Loading Port' ||
                            locationFilterValue == 'Discharge Port' || locationFilterValue == 'Shipment Origin' || locationFilterValue == 'Shipment Destination' 
                          ">
                          <span class="move-right">
                            <span class="list-data">{{
                              show.portName | titlecase
                              }}, {{ show.countryName | titlecase
                              }}</span>
                          </span>
                          <span class="move-left">
                            <span class="list-data">
                              {{ show.unlocode}}
                            </span>
                          </span>
                        </div>
                        <div class="con-set" *ngIf="
                            locationFilterValue == 'Port of Clearance' 
                          ">
                          <span class="move-right port-of-c">
                            <span
                              class="list-data">{{manipulateData(show.port_of_clearance_description)}}{{checkCountryCode(show)}}

                            </span>
                          </span>
                        </div>
                      </ng-container>
                    </div>
                    <div class="font-w" *ngIf="allLocationList?.length <= 0 && !isProgress">
                      No Records Found
                    </div>
                  </div>
                </ng-container>
              </div>
            </ul>
          </div>
        </div>
        <div class="nav-conainer padding-set new-pad" *ngIf="
            loadingPortFilterSelectedList.length > 0 ||
            dischargePortFilterSelectedList.length > 0 ||
            pocFilterSelectedList.length > 0 ||
            shipmentOriginFilterSelectedList.length > 0 ||
            shipmentDestinationFilterSelectedList.length > 0
          ">
          <div class="accountStatusFilterDropdown">
            <ul class="statusList scroll-set-sec">
              <div *ngIf="loadingPortFilterSelectedList.length > 0" class="status-list below-desktop">
                <h6 class="mb-1 mt-3">Loading Port Filters</h6>
                <div class="con-set make-one" *ngFor="
                    let show of loadingPortFilterSelectedList;
                    let indexOfelement = index
                  ">
                  <span class="move-right">
                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt="" (click)="
                          childSelectedText(indexOfelement, show, 'Loading Port')
                        " />
                      <span class="set-one">{{show.portName | titlecase}}, {{ show.countryName | titlecase}}</span>
                    </span>
                  </span>
                  <span class="move-left">
                    <span class="list-data">
                      {{ show.unlocode}}
                    </span>
                  </span>
                </div>
              </div>
              <div *ngIf="dischargePortFilterSelectedList.length > 0" class="status-list">
                <h6 class="mb-1 mt-3">Discharge Port Filters</h6>
                <div class="con-set" *ngFor="
                    let show of dischargePortFilterSelectedList;
                    let indexOfelement = index
                  ">
                  <span class="move-right">
                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt="" (click)="
                          childSelectedText(indexOfelement, show, 'Discharge Port')
                        " />
                      <span class="set-one">{{show.portName | titlecase}}, {{ show.countryName | titlecase}}</span>
                    </span>
                  </span>

                  <span class="move-left">
                    <span class="list-data">
                      {{ show.unlocode}}
                    </span></span>
                </div>
              </div>
              <div *ngIf="pocFilterSelectedList.length > 0" class="status-list">
                <h6 class="mb-1 mt-3">Port of Clearance Filters</h6>
                <div class="con-set" *ngFor="
                    let show of pocFilterSelectedList;
                    let indexOfelement = index
                  ">
                  <span class="move-right">
                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt="" (click)="
                          childSelectedText(indexOfelement, show, 'Port of Clearance')
                        " />
                      <span
                        class="set-one">{{manipulateData(show.port_of_clearance_description)}}{{checkCountryCode(show)}}
                      </span>

                    </span>
                  </span>
                </div>
              </div>
              <div *ngIf="shipmentOriginFilterSelectedList.length > 0" class="status-list">
                <h6 class="mb-1 mt-3">Shipment Origin Filters</h6>
                <div class="con-set" *ngFor="
                    let show of shipmentOriginFilterSelectedList;
                    let indexOfelement = index
                  ">
                  <span class="move-right">
                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt="" (click)="
                          childSelectedText(
                            indexOfelement,
                            show,
                            'Shipment Origin'
                          )
                        " />
                      <span class="set-one">{{show.portName | titlecase}}, {{ show.countryName | titlecase}}</span>
                    </span>
                  </span>
                  <span class="move-left">
                    <span class="list-data">
                      {{ show.unlocode}}
                    </span>
                  </span>
                </div>
              </div>
              <div *ngIf="shipmentDestinationFilterSelectedList.length > 0" class="status-list">
                <h6 class="mb-1 mt-3">Shipment Destination Filters</h6>
                <div class="con-set" *ngFor="
                    let show of shipmentDestinationFilterSelectedList;
                    let indexOfelement = index
                  ">
                  <span class="move-right">
                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt="" (click)="
                          childSelectedText(
                            indexOfelement,
                            show,
                            'Shipment Destination'
                          )
                        " />
                      <span class="set-one">{{show.portName | titlecase}}, {{ show.countryName | titlecase}}</span>
                    </span>
                  </span>
                  <span class="move-left">
                    <span class="list-data">
                      {{ show.unlocode}}
                    </span>
                  </span>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>






<div class="rap-sec mobile">
  <div class="accordion-body origin-Location">
    <div class="p-2">
      <div class="pickup mt-3" *ngIf="loadingPortFilterSelectedList.length > 0">
        <h5>Loading Port Filters</h5>
        <div *ngFor="
            let show of loadingPortFilterSelectedList;
            let indexOfelement = index
          " class="status-list">
          <div class="ruler">
            <div class="d-flex align-items-center justify-content-between">
              <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" style="width: 15px" alt=""
                  (click)="childSelectedText(indexOfelement, show, 'Loading Port')" />
                {{show.portName | titlecase}},{{ show.countryName | titlecase}}
              </span>
              <span class="move-left">
                {{ show.unlocode}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="origin mt-3" *ngIf="dischargePortFilterSelectedList.length > 0">
        <h5>Discharge Port Filters</h5>
        <div *ngFor="
            let show of dischargePortFilterSelectedList;
            let indexOfelement = index
          " class="status-list">
          <div class="ruler">
            <div class="d-flex align-items-center justify-content-between">
              <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" style="width: 15px" alt=""
                  (click)="childSelectedText(indexOfelement, show, 'Discharge Port')" />
                {{show.portName | titlecase}},{{ show.countryName | titlecase}}
              </span>
              <span class="move-left"> <span class="list-data">
                  {{ show.unlocode}}
                </span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="origin mt-3" *ngIf="pocFilterSelectedList.length > 0">
        <h5>Port of Clearance Filters</h5>
        <div *ngFor="
            let show of pocFilterSelectedList;
            let indexOfelement = index
          " class="status-list">
          <div class="ruler">
            <div class="d-flex align-items-center justify-content-between">
              <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" style="width: 15px" alt=""
                  (click)="
                    childSelectedText(indexOfelement, show, 'Port of Clearance')
                  " />
                {{manipulateData(show.port_of_clearance_description)}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="pickup mt-3" *ngIf="shipmentOriginFilterSelectedList.length > 0">
        <h5>Shipment Origin Filters</h5>
        <div *ngFor="
            let show of shipmentOriginFilterSelectedList;
            let indexOfelement = index
          " class="status-list">
          <div class="ruler">
            <div class="d-flex align-items-center justify-content-between">
              <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" style="width: 15px" alt=""
                  (click)="childSelectedText(indexOfelement,show,'Shipment Origin')" />
                {{show.portName | titlecase}},{{ show.countryName | titlecase}}
              </span>
              <span class="move-left">
                <span class="list-data"> {{ show.unlocode}} </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="pickup mt-3" *ngIf="shipmentDestinationFilterSelectedList.length > 0">
        <h5>Shipment Destination Filters</h5>
        <div *ngFor="
            let show of shipmentDestinationFilterSelectedList;
            let indexOfelement = index
          " class="status-list">
          <div class="ruler">
            <div class="d-flex align-items-center justify-content-between">
              <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" style="width: 15px" alt=""
                  (click)="childSelectedText(indexOfelement,show,'Shipment Destination')" />
                {{show.portName | titlecase}},{{ show.countryName | titlecase}}
              </span>
              <span class="move-left">
                <span class="list-data">{{ show.unlocode}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-by mt-2">
        <h5>Location Filter By</h5>
        <div class="select-box mr-0">
          <div class="inbox-set">
            <input type="text" readonly class="form-control" (click)="mobileLocationTextBoxHandler()" name="search"
              autocomplete="off" [(ngModel)]="selectedMobileValue" ngDefaultControl />
            <div class="arrow-img-main">
              <img *ngIf="!locationToggleMobile" alt="CopyImage" src="assets/img/down_arrow-lg.svg" />
              <img *ngIf="locationToggleMobile" alt="CopyImage" src="assets/img/up_arrow_lg.svg" />
            </div>
          </div>
          <div class="accountStatusFilterDropdown up-mobile"
            [style.visibility]="locationToggleMobile ? 'visible' : 'hidden'">
            <ul class="statusList mobile-set-level">
              <li *ngFor="let filter of locationFilter" (click)="selectLocationDebounce.next(filter)">
                {{ filter.status }}
              </li>
            </ul>
          </div>
        </div>
        <div class="statusList p-0">
          <div class="search-area input-group mt-0 mb-3">
            <input type="text" class="form-control border-right-0 search-area" name="search" autocomplete="off"
              placeholder="Search for City" [(ngModel)]="searchText" (keyup)="getSearchLocationList(searchText)"
              (keyup.backspace)="clearData(searchText)" (keyup.enter)="onSearchHandler(searchText)" autocomplete="off"
              ngDefaultControl />
            <div class="input-group-append">
              <div class="search-bg input-group-text">
                <img src="../../../../../assets/img/search-sm.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="location-innerscroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          [scrollWindow]="false" (scrolled)="onScroll(searchText)">
          <!-- <ng-container>
            <div *ngIf="
                locationFilterValue == 'Pickup' && pickupRecentList.length > 0
              " class="pl-2">
              <h5 class="mb-1">Recent Locations</h5>
              <div *ngFor="
                  let show of pickupRecentList;
                  let indexOfelement = index
                " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                <div>
                  <span class="move-right">
                    {{ show.name | titlecase }}
                  </span>
                  <span class="move-left">
                    {{ show.city | titlecase }},
                    <ng-container *ngIf="
                        show.countryCode == 'US' || show.countryCode == 'CA'
                      ">
                      {{ show.state | titlecase }},</ng-container>
                    {{ show.countryName | titlecase }}
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="
                locationFilterValue == 'Origin' && orginRecentList.length > 0
              " class="pl-2">
              <h5 class="mb-1">Recent Locations</h5>
              <div *ngFor="let show of orginRecentList; let indexOfelement = index" class="status-text"
                (click)="locationFilterSelected(show, indexOfelement)">
                <div>
                  <span class="move-right">
                    {{ show.rL_PortName }}<ng-container *ngIf="show.rL_PortName">,
                    </ng-container>{{ show.country }}
                  </span>
                  <span class="move-left">
                    {{ show.rL_Code }}
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="
                locationFilterValue == 'Destination' &&
                destinationRecentList.length > 0
              " class="pl-2">
              <h5 class="mb-1">Recent Locations</h5>
              <div *ngFor="
                  let show of destinationRecentList;
                  let indexOfelement = index
                " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                <div>
                  <span class="move-right">
                    {{ show.rL_PortName }}<ng-container *ngIf="show.rL_PortName">,
                    </ng-container>{{ show.country }}
                  </span>
                  <span class="move-left">
                    {{ show.rL_Code }}
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="
                locationFilterValue == 'Final Delivery' &&
                finalDeliveryRecentList.length > 0
              " class="pl-2">
              <h5 class="mb-1">Recent Locations</h5>
              <div *ngFor="
                  let show of finalDeliveryRecentList;
                  let indexOfelement = index
                " class="status-text" (click)="locationFilterSelected(show, indexOfelement)">
                <div>
                  <span class="move-right">
                    {{ show.name | titlecase }}
                  </span>
                  <span class="move-left">
                    {{ show.city | titlecase }},
                    <ng-container *ngIf="
                        show.countryCode == 'US' || show.countryCode == 'CA'
                      ">
                      {{ show.state | titlecase }},</ng-container>
                    {{ show.countryName | titlecase }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container> -->
          <ng-container>
            <h5 class="mb-1 mt-3 pl-2">All Locations</h5>
            <div class="pl-2">
              <div class="status-text" *ngFor="let show of allLocationList; let indexOfelement = index"
                (click)="locationFilterSelected(show, indexOfelement)">
                <ng-container *ngIf="allLocationList?.length > 0">
                  <div
                    *ngIf="
                       locationFilterValue == 'Loading Port' || locationFilterValue == 'Discharge Port' || locationFilterValue == 'Shipment Origin' || locationFilterValue == 'Shipment Destination'">
                    <span class="move-right">
                      <span class="list-data">{{
                        show.portName | titlecase
                        }},{{ show.countryName | titlecase
                        }}</span>
                    </span>
                    <span class="move-left">
                      <span class="list-data">
                        {{ show.unlocode}}
                      </span>
                    </span>
                  </div>
                  <div *ngIf="
                     locationFilterValue == 'Port of Clearance'
                    ">
                    <span class="move-right">
                      <span class="list-data">{{manipulateData(show.port_of_clearance_description)}}</span>
                    </span>
                  </div>
                </ng-container>
              </div>
              <ng-container>
                <app-progressbar *ngIf="isProgress"></app-progressbar>
              </ng-container>
              <div class="font-w" *ngIf="allLocationList?.length <= 0 && !isProgress">
                No Records Found
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>