<!--Progress bar display start -->
<app-progressbar [hidden]="!isProgressShow" class="action-detail-field"></app-progressbar>
<!--Progress bar display end -->
<app-success-toast *ngIf="isShowMessage" [message]="showMessage" class="my-profile-toast"></app-success-toast>

<form [formGroup]="actionFieldForm">
   <div class="over-all-rap pr-2 pl-2" id="actionField-scroll-div">
      <div class="set-one mt-4" *ngIf="actionFieldForm.value?.sections?.length>0
         && actionFieldForm.value?.sections[0].actionFields?.length>0">
         <div formArrayName="sections">
            <div [formGroupName]="0">
               <div formArrayName="actionFields">
                  <div *ngFor="let e of actionField(0)?.controls; let index = index">
                     <!---dynamic field builder starts-->
                     <div [formGroupName]="index">
                        <!--textbox-->
                        <ng-template [ngIf]="actionFieldForm.value?.sections[0]
                         .actionFields[index].controlType.displayText==='Text Box'">
                           <div class="contact" formArrayName="actionFieldValue">
                              <div [formGroupName]="0">
                                 <label>{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldName}}</label>
                                 <div class="sub-set" clickOutside
                                 (clickOutside)="offAllClearIconFlagOnSpecificIndex(0, index)">
                                    <input type="text" formControlName="value" placeholder="{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].hintText}}" maxlength="{{actionFieldForm.value?.sections[0]
                                          .actionFields[index].maximumLength}}" minlength="{{actionFieldForm.value?.sections[0]
                                             .actionFields[index].minimumLength}}" [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                e.get('actionFieldValue.0.value')?.touched" [class.is-invalid-border-color]="e.get('actionFieldValue.0.value')?.errors?.minlength
                                || actionFieldForm.value?.sections[0]
                                .actionFields[index].numberValidation ||
                                actionFieldForm.value?.sections[0]
                                             .actionFields[index].decimalValidation"
                                       (keydown)="offValidation(0, index)" (click)="showOrHideClearIcon(0, index, true)"
                                       (keyup)="showOrHideClearIcon(0, index, true)">

                                    <span class="close-button" (click)="closeInfo(0, index)"
                                       [hidden]="actionFieldForm.value?.sections[0].actionFields[index].clearIcon == false"><img
                                          src="../../assets/img/close_whitebg_remove.svg" alt="" /></span>

                                    <span class="tool-tip"
                                       *ngIf="actionFieldForm.value?.sections[0]
                                 .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[0]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].toolTipText}}</span>

                                    <div class="is-invalid-color error">
                                       <small *ngIf="actionFieldForm.value?.sections[0]
                                          .actionFields[index].numberValidation">
                                          Please enter value in numbers</small>
                                       <small *ngIf="actionFieldForm.value?.sections[0]
                                             .actionFields[index].decimalValidation">
                                          Please enter value in decimals</small>
                                       <span
                                          *ngIf="actionFieldForm.value?.sections[0]
.actionFields[index].numberValidation || actionFieldForm.value?.sections[0]
.actionFields[index].decimalValidation && e.get('actionFieldValue.0.value')?.errors?.minlength">&nbsp;&nbsp;&nbsp;</span>
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.minlength">
                                          field required at least {{actionFieldForm.value?.sections[0]
                                          .actionFields[index].minimumLength}} characters.</small>
                                    </div>

                                    <div class="is-invalid error"
                                       *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                          Please enter the field</small>
                                    </div>


                                 </div>
                              </div>

                           </div>

                        </ng-template>
                        <!--textbox-->

                        <!--checkbox-->
                        <ng-template [ngIf]="actionFieldForm.value?.sections[0]
                        .actionFields[index].controlType.displayText==='Checkbox'">
                           <div class="contact m-0" formArrayName="actionFieldValue">
                              <div [formGroupName]="0">
                                 <label>{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldName}}</label>
                                 <div class="sub-set">
                                    <div class="checkbox-ctr">
                                       <input type="checkbox" [checked]="actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldValue[0].value=='true'" id="con1-0-{{index}}"
                                          class="con-base-element" (change)="addSelectedCheckBox($event, 0, index)" />
                                       <label for="con1-0-{{index}}" class="chck-box-lbl"></label>
                                    </div>
                                    <span class="tool-tip"
                                       *ngIf="actionFieldForm.value?.sections[0]
                                 .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[0]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].toolTipText}}</span>
                                 </div>
                              </div>

                           </div>

                        </ng-template>
                        <!--checkbox-->

                        <!--dropdown-->
                        <ng-template [ngIf]="actionFieldForm.value?.sections[0]
                         .actionFields[index].controlType.displayText==='Dropdown'">
                           <div class="contact" formArrayName="actionFieldValue">
                              <div [formGroupName]="0">
                                 <label>{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldName}}</label>
                                 <div class="sub-set" clickOutside
                                 (clickOutside)="offAllClearIconFlagOnSpecificIndex(0, index)">
                                    <div class="btn-group dropdown">
                                       <button type="button" id="dropdownMenuButton" [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                  e.get('actionFieldValue.0.value')?.touched" data-toggle="dropdown"
                                          aria-haspopup="true" aria-expanded="false"
                                          class="btn btn-secondary dropdown-toggle w100 custom-arow"
                                          (click)="showOrHideClearIcon(0, index, true)">
                                          {{actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value===''?'select
                                          value':actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value}}
                                       </button>

                                       <span class="close-button" (click)="closeInfo(0, index)"
                                          [hidden]="actionFieldForm.value?.sections[0].actionFields[index].clearIcon == false"><img
                                             src="../../assets/img/close_whitebg_remove.svg" alt="" /></span>

                                       <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                                          <ul class="custom-drop-menu">
                                             <li class="dropdown-item" *ngFor="let a of actionFieldForm.value?.sections[0]
                                        .actionFields[index].dropdownList"
                                                (click)="addSelectedDropDownValue(a, 0, index)">{{a.value}}</li>
                                          </ul>
                                       </div>
                                    </div>
                                    <div class="is-invalid error"
                                       *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                          Please enter the field</small>
                                    </div>
                                    <span class="tool-tip"
                                       *ngIf="actionFieldForm.value?.sections[0]
                                 .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[0]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].toolTipText}}</span>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                        <!--dropdown-->
                        <!--textarea-->
                        <ng-template [ngIf]="actionFieldForm.value?.sections[0]
                         .actionFields[index].controlType.displayText==='Text Area'">
                           <div class="contact" formArrayName="actionFieldValue">
                              <div [formGroupName]="0">
                                 <label style="vertical-align: top;">{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldName}}</label>
                                 <div class="sub-set" clickOutside
                                 (clickOutside)="offAllClearIconFlagOnSpecificIndex(0, index)">
                                    <textarea rows="4" (keyup)="ActionFieldFormChanges()" style="width:100%"
                                       formControlName="value" placeholder="{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].hintText}}" maxlength="{{actionFieldForm.value?.sections[0]
                                          .actionFields[index].maximumLength}}" minlength="{{actionFieldForm.value?.sections[0]
                                             .actionFields[index].minimumLength}}" [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                e.get('actionFieldValue.0.value')?.touched"
                                       [class.is-invalid-border-color]="e.get('actionFieldValue.0.value')?.errors?.minlength"
                                       (click)="showOrHideClearIcon(0, index, true)"
                                       (keyup)="showOrHideClearIcon(0, index, true)"></textarea>

                                    <span class="close-button" (click)="closeInfo(0, index)"
                                       [hidden]="actionFieldForm.value?.sections[0].actionFields[index].clearIcon == false"><img
                                          src="../../assets/img/close_whitebg_remove.svg" alt="" /></span>

                                    <div class="is-invalid error"
                                       *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                          Please enter the field</small>
                                    </div>
                                    <div class="is-invalid-color error">
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.minlength">
                                          field required at least {{actionFieldForm.value?.sections[0]
                                          .actionFields[index].minimumLength}} characters.</small>
                                    </div>
                                    <span class="tool-tip"
                                       *ngIf="actionFieldForm.value?.sections[0]
                                 .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[0]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].toolTipText}}</span>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                        <!--textarea-->
                        <!--date-->
                        <ng-template [ngIf]="actionFieldForm.value?.sections[0]
                         .actionFields[index].controlType.displayText==='Date'">
                           <div class="contact">
                              <div>
                                 <label>{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldName}}</label>
                                 <div class="sub-set">
                                    <div class="btn-group dropdown" clickOutside
                                       (clickOutside)="offToggleDate(0, index)">
                                       <button type="button" id="dropdownMenuButton" [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                  e.get('actionFieldValue.0.value')?.touched" (click)="toggleDateDropDown(0,index)"
                                          aria-haspopup="true" aria-expanded="false"
                                          class="btn btn-secondary dropdown-toggle w100 custom-arow" [ngClass]="{'toggle-on':actionFieldForm.value?.sections[0]
                                                                  .actionFields[index].dateDropdownToggleFlag}"><img
                                             src="../../../../../assets/img/Calendar.svg" alt=""
                                             (click)="showOrHideClearIcon(0, index, true)">
                                          {{actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value===''?'select
                                          date':actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value}}
                                       </button>

                                       <span class="close-button" (click)="closeInfo(0, index)"
                                          [hidden]="actionFieldForm.value?.sections[0].actionFields[index].clearIcon == false"><img
                                             src="../../assets/img/close_whitebg_remove.svg" alt="" /></span>

                                       <div aria-labelledby="dropdownMenuButton" class="dropdown-menu" [ngClass]="{'toggle-show':actionFieldForm.value?.sections[0]
                                    .actionFields[index].dateDropdownToggleFlag}" style="display:block" [hidden]="!actionFieldForm.value?.sections[0]
                                  .actionFields[index].dateDropdownToggleFlag">
                                          <ngb-datepicker class="date-time-designchange" [(ngModel)]="date" [startDate]="actionFieldForm.value?.sections[0]
                                              .actionFields[index].startDate" formControlName="dates"
                                             (click)="changeDate($event, date, 0, index)"></ngb-datepicker>
                                       </div>
                                    </div>
                                    <div class="is-invalid error"
                                       *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                          Please enter the field</small>
                                    </div>
                                    <span class="tool-tip"
                                       *ngIf="actionFieldForm.value?.sections[0]
                                 .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[0]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].toolTipText}}</span>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                        <!--date-->
                        <!--dateTime-->
                        <ng-template [ngIf]="actionFieldForm.value?.sections[0]
                         .actionFields[index].controlType.displayText==='Datetime'">
                           <div class="contact" id="dateTime-{{0}}-{{index}}">
                              <div>
                                 <label>{{actionFieldForm.value?.sections[0]
                                    .actionFields[index].actionFieldName}}</label>
                                 <div class="sub-set">
                                    <div class="btn-group dropdown" clickOutside
                                       (clickOutside)="offToggleDateTime(0, index)">
                                       <button type="button" id="dropdownMenuButton" aria-haspopup="true"
                                          aria-expanded="false" [class.is-invalid-border-color]="actionFieldForm.value?.sections[0]
                                       .actionFields[index].dateTimeValidation"
                                          (click)="toggleDateTimeDropDown(0,index)"
                                          class="btn btn-secondary dropdown-toggle w100 custom-arow" [ngClass]="{'toggle-on':actionFieldForm.value?.sections[0]
                                                                  .actionFields[index].dateTimeDropdownToggleFlag}"
                                          (click)="showOrHideClearIcon(0, index, true)"><img
                                             src="../../../../../assets/img/Calendar.svg" alt="">
                                          {{actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value===''?'select
                                          date/time':actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value}}
                                       </button>

                                       <span class="close-button" (click)="closeInfo(0, index)"
                                          [hidden]="actionFieldForm.value?.sections[0].actionFields[index].clearIcon == false"><img
                                             src="../../assets/img/close_whitebg_remove.svg" alt="" /></span>

                                       <div aria-labelledby="dropdownMenuButton" [hidden]="!actionFieldForm.value?.sections[0]
                                  .actionFields[index].dateTimeDropdownToggleFlag" [ngClass]="{'toggle-show':actionFieldForm.value?.sections[0]
                                  .actionFields[index].dateTimeDropdownToggleFlag}" class="dropdown-menu"
                                          style="display:block;">
                                          <ul class="custom-drop-menu">
                                             <li class="dropdown-item">
                                                <ngb-datepicker class="date-time-designchange" [(ngModel)]="date"
                                                   [startDate]="actionFieldForm.value?.sections[0]
                                              .actionFields[index].startDate" formControlName="dates" (click)="changeDateTime($event, date, time, 0, index,actionFieldForm.value?.sections[0]
                                                   .actionFields[index].actionFieldValue[0].value)"></ngb-datepicker>
                                                <ngb-timepicker class="date-time-designchange" [(ngModel)]="actionFieldForm.value?.sections[0]
                                                  .actionFields[index].times" formControlName="times" (click)="changeDateTime($event, date, actionFieldForm.value?.sections[0]
                                                   .actionFields[index].times, 0, index,
                                                   actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value)" (change)="changeDateTime($event, date, actionFieldForm.value?.sections[0]
                                                   .actionFields[index].times, 0, index,
                                                   actionFieldForm.value?.sections[0]
                                          .actionFields[index].actionFieldValue[0].value)"
                                                   [meridian]="true"></ngb-timepicker>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                    <div class="is-invalid error"
                                       *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                       <small *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                          Please enter the field</small>
                                    </div>
                                    <div class="is-invalid-color error" *ngIf="actionFieldForm.value?.sections[0]
                                    .actionFields[index].dateTimeValidation">
                                       <small>
                                          Please enter both date and time</small>
                                    </div>

                                    <span class="tool-tip"
                                       *ngIf="actionFieldForm.value?.sections[0]
                                 .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[0]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[0]
                                       .actionFields[index].toolTipText}}</span>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                        <!--dateTime-->
                     </div>
                     <!---dynamic field builder ends-->
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="set-two mt-4 mb-4">
         <div class="row" formArrayName="sections">
            <div class="col-12">
               <div class="row">
                  <div class="pr-2 mb-2" *ngFor="let s of sectionsField().controls; let sectionIndex = index" [ngClass]="{'col-12 full-view': actionFieldForm.value?.sections[sectionIndex].showFullWidth, 
                    'col-6 half-view' : !actionFieldForm.value?.sections[sectionIndex].showFullWidth }"
                     [hidden]="sectionIndex==0">
                     <div class="accordion" *ngIf="sectionIndex != 0">
                        <div class="accordion-item">
                           <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                              <button class="accordion-button" type="button" data-toggle="collapse"
                                 [attr.data-target]="'#panelsStayOpen-collapseOne-'+sectionIndex" aria-expanded="true"
                                 aria-controls="panelsStayOpen-collapseOne">
                                 {{actionFieldForm.value?.sections[sectionIndex].sectionName}}
                              </button>
                           </h2>
                           <div id="panelsStayOpen-collapseOne-{{sectionIndex}}"
                              class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                              <div class="accordion-body p-0">
                                 <div class="set-one" *ngIf="actionFieldForm.value?.sections?.length>0">
                                    <div>
                                       <div [formGroupName]="sectionIndex">
                                          <div formArrayName="actionFields">
                                             <div
                                                *ngFor="let e of actionField(sectionIndex)?.controls; let index = index">
                                                <!---dynamic field builder starts-->
                                                <div [formGroupName]="index">
                                                   <!--textbox-->
                                                   <ng-template [ngIf]="actionFieldForm.value?.sections[sectionIndex]
                                             .actionFields[index].controlType.displayText==='Text Box'">
                                                      <div class="contact" formArrayName="actionFieldValue">
                                                         <div [formGroupName]="0">
                                                            <label>{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index]?.actionFieldName}}</label>
                                                            <div class="sub-set" clickOutside
                                                            (clickOutside)="offAllClearIconFlagOnSpecificIndex(sectionIndex, index)">
                                                               <input type="text" formControlName="value" placeholder="{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].hintText}}"
                                                                  (keydown)="offValidation(sectionIndex, index)"
                                                                  maxlength="{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].maximumLength}}" minlength="{{actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].minimumLength}}"
                                                                  [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                                    e.get('actionFieldValue.0.value')?.touched"
                                                                  [class.is-invalid-border-color]="e.get('actionFieldValue.0.value')?.errors?.minlength
                                                    || actionFieldForm.value?.sections[sectionIndex]
                                                    .actionFields[index].numberValidation ||
                                                    actionFieldForm.value?.sections[sectionIndex]
                                             .actionFields[index].decimalValidation"
                                                                  (click)="showOrHideClearIcon(sectionIndex, index, true)"
                                                                  (keyup)="showOrHideClearIcon(sectionIndex, index, true)">

                                                               <span class="close-button"
                                                                  (click)="closeInfo(sectionIndex,index)"
                                                                  [hidden]="actionFieldForm.value?.sections[sectionIndex]
                                                                                                   .actionFields[index].clearIcon == false"><img
                                                                     src="../../assets/img/close_whitebg_remove.svg"
                                                                     alt="" /></span>

                                                               <div class="is-invalid-color error">
                                                                  <small *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].numberValidation">
                                                                     Please enter value in numbers</small>
                                                                  <small *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                                        .actionFields[index].decimalValidation">
                                                                     Please enter value in decimals</small>
                                                                  <span
                                                                     *ngIf="actionFieldForm.value?.sections[sectionIndex]
.actionFields[index].numberValidation || actionFieldForm.value?.sections[sectionIndex]
.actionFields[index].decimalValidation && e.get('actionFieldValue.0.value')?.errors?.minlength">&nbsp;&nbsp;&nbsp;</span>
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.minlength">
                                                                     field required at least
                                                                     {{actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].minimumLength}}
                                                                     characters.</small>
                                                               </div>
                                                               <div class="is-invalid error"
                                                                  *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                                                     Please enter the field</small>
                                                               </div>

                                                               <span class="tool-tip"
                                                                  *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].toolTipText}}</span>
                                                            </div>

                                                         </div>
                                                      </div>

                                                   </ng-template>
                                                   <!--textbox-->

                                                   <!--checkbox-->
                                                   <ng-template [ngIf]="actionFieldForm.value?.sections[sectionIndex]
                                            .actionFields[index].controlType.displayText==='Checkbox'">
                                                      <div class="contact m-0" formArrayName="actionFieldValue">
                                                         <div [formGroupName]="0">
                                                            <label>{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index]?.actionFieldName}}</label>
                                                            <div class="sub-set">
                                                               <div class="checkbox-ctr">
                                                                  <input type="checkbox" [checked]="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].actionFieldValue[0].value=='true'"
                                                                     id="con1-{{sectionIndex}}-{{index}}"
                                                                     class="con-base-element"
                                                                     (change)="addSelectedCheckBox($event, sectionIndex, index)" />
                                                                  <label for="con1-{{sectionIndex}}-{{index}}"
                                                                     class="chck-box-lbl"></label>
                                                               </div>
                                                               <span class="tool-tip"
                                                                  *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].toolTipText}}</span>
                                                            </div>
                                                         </div>

                                                      </div>

                                                   </ng-template>
                                                   <!--checkbox-->

                                                   <!--dropdown-->
                                                   <ng-template [ngIf]="actionFieldForm.value?.sections[sectionIndex]
                                             .actionFields[index].controlType.displayText==='Dropdown'">
                                                      <div class="contact" formArrayName="actionFieldValue">
                                                         <div [formGroupName]="0">
                                                            <label>{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index]?.actionFieldName}}</label>
                                                            <div class="sub-set" clickOutside
                                                            (clickOutside)="offAllClearIconFlagOnSpecificIndex(sectionIndex, index)">
                                                               <div class="btn-group dropdown">
                                                                  <button type="button" id="dropdownMenuButton"
                                                                     [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                                      e.get('actionFieldValue.0.value')?.touched"
                                                                     data-toggle="dropdown" aria-haspopup="true"
                                                                     aria-expanded="false"
                                                                     class="btn btn-secondary dropdown-toggle w100 custom-arow"
                                                                     (click)="showOrHideClearIcon(sectionIndex, index, true)">
                                                                     {{actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value===''?'select
                                                                     value':actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value}}
                                                                  </button>

                                                                  <span class="close-button"
                                                                     (click)="closeInfo(sectionIndex,index)"
                                                                     [hidden]="actionFieldForm.value?.sections[sectionIndex]
                                                                                                      .actionFields[index].clearIcon == false"><img
                                                                        src="../../assets/img/close_whitebg_remove.svg"
                                                                        alt="" /></span>

                                                                  <div aria-labelledby="dropdownMenuButton"
                                                                     class="dropdown-menu">
                                                                     <ul class="custom-drop-menu">
                                                                        <li class="dropdown-item" *ngFor="let a of actionFieldForm.value?.sections[sectionIndex]
                                                            .actionFields[index].dropdownList"
                                                                           (click)="addSelectedDropDownValue(a, sectionIndex, index)">
                                                                           {{a.value}}</li>
                                                                     </ul>
                                                                  </div>
                                                               </div>
                                                               <div class="is-invalid error"
                                                                  *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                                                     Please enter the field</small>
                                                               </div>
                                                               <span class="tool-tip"
                                                                  *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].toolTipText}}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </ng-template>
                                                   <!--dropdown-->
                                                   <!--textarea-->
                                                   <ng-template [ngIf]="actionFieldForm.value?.sections[sectionIndex]
                                             .actionFields[index].controlType.displayText==='Text Area'">
                                                      <div class="contact" formArrayName="actionFieldValue">
                                                         <div [formGroupName]="0">
                                                            <label
                                                               style="vertical-align: top;">{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index]?.actionFieldName}}</label>
                                                            <div class="sub-set" clickOutside
                                                            (clickOutside)="offAllClearIconFlagOnSpecificIndex(sectionIndex, index)">
                                                               <textarea rows="4" style="width:100%"
                                                                  formControlName="value" placeholder="{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].hintText}}"
                                                                  (keyup)="ActionFieldFormChanges()" maxlength="{{actionFieldForm.value?.sections[sectionIndex]
                                                                        .actionFields[index].maximumLength}}"
                                                                  minlength="{{actionFieldForm.value?.sections[sectionIndex]
                                                                           .actionFields[index].minimumLength}}"
                                                                  [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                                    e.get('actionFieldValue.0.value')?.touched"
                                                                  [class.is-invalid-border-color]="e.get('actionFieldValue.0.value')?.errors?.minlength"
                                                                  (click)="showOrHideClearIcon(sectionIndex, index, true)"
                                                                  (keyup)="showOrHideClearIcon(sectionIndex, index, true)"></textarea>

                                                               <span class="close-button"
                                                                  (click)="closeInfo(sectionIndex,index)"
                                                                  [hidden]="actionFieldForm.value?.sections[sectionIndex]
                                                                                                   .actionFields[index].clearIcon == false"><img
                                                                     src="../../assets/img/close_whitebg_remove.svg"
                                                                     alt="" /></span>

                                                               <div class="is-invalid error"
                                                                  *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                                                     Please enter the field</small>
                                                               </div>
                                                               <div class="is-invalid-color error">
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.minlength">
                                                                     field required at least
                                                                     {{actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].minimumLength}}
                                                                     characters.</small>
                                                               </div>
                                                               <span class="tool-tip"
                                                                  *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].toolTipText}}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </ng-template>
                                                   <!--textarea-->
                                                   <!--date-->
                                                   <ng-template [ngIf]="actionFieldForm.value?.sections[sectionIndex]
                                             .actionFields[index].controlType.displayText==='Date'">
                                                      <div class="contact">
                                                         <div>
                                                            <label>{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index]?.actionFieldName}}</label>
                                                            <div class="sub-set">
                                                               <div class="btn-group dropdown" clickOutside
                                                                  (clickOutside)="offToggleDate(sectionIndex, index)">
                                                                  <button type="button" id="dropdownMenuButton"
                                                                     [class.is-invalid-border]="e.get('actionFieldValue.0.value')?.invalid && 
                                                      e.get('actionFieldValue.0.value')?.touched"
                                                                     (click)="toggleDateDropDown(sectionIndex,index)"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     class="btn btn-secondary dropdown-toggle w100 custom-arow"
                                                                     [ngClass]="{'toggle-on':actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].dateDropdownToggleFlag}"
                                                                     (click)="showOrHideClearIcon(sectionIndex, index, true)"><img
                                                                        src="../../../../../assets/img/Calendar.svg"
                                                                        alt="">
                                                                     {{actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value===''?'select
                                                                     date':actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value}}

                                                                  </button>

                                                                  <span class="close-button"
                                                                     (click)="closeInfo(sectionIndex,index)"
                                                                     [hidden]="actionFieldForm.value?.sections[sectionIndex]
                                                                                                      .actionFields[index].clearIcon == false"><img
                                                                        src="../../assets/img/close_whitebg_remove.svg"
                                                                        alt="" /></span>

                                                                  <div aria-labelledby="dropdownMenuButton"
                                                                     class="dropdown-menu" style="display:block"
                                                                     [hidden]="!actionFieldForm.value?.sections[sectionIndex]
                                                      .actionFields[index].dateDropdownToggleFlag" [ngClass]="{'toggle-show':actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].dateDropdownToggleFlag}">
                                                                     <ngb-datepicker class="date-time-designchange"
                                                                        [(ngModel)]="date" [startDate]="actionFieldForm.value?.sections[sectionIndex]
                                              .actionFields[index].startDate" formControlName="dates"
                                                                        (click)="changeDate($event, date, sectionIndex, index)"></ngb-datepicker>
                                                                  </div>
                                                               </div>
                                                               <div class="is-invalid error"
                                                                  *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                                                     Please enter the field</small>
                                                               </div>
                                                               <span class="tool-tip"
                                                                  *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].toolTipText}}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </ng-template>
                                                   <!--date-->
                                                   <!--dateTime-->
                                                   <ng-template [ngIf]="actionFieldForm.value?.sections[sectionIndex]
                                             .actionFields[index].controlType.displayText==='Datetime'">
                                                      <div class="contact" id="dateTime-{{sectionIndex}}-{{index}}">
                                                         <div>
                                                            <label>{{actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index]?.actionFieldName}}</label>
                                                            <div class="sub-set">
                                                               <div class="btn-group dropdown" clickOutside
                                                                  (clickOutside)="offToggleDateTime(sectionIndex, index)">
                                                                  <button type="button" id="dropdownMenuButton"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     [class.is-invalid-border-color]="actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].dateTimeValidation"
                                                                     (click)="toggleDateTimeDropDown(sectionIndex,index)"
                                                                     class="btn btn-secondary dropdown-toggle w100 custom-arow"
                                                                     [ngClass]="{'toggle-on':actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].dateTimeDropdownToggleFlag}"
                                                                     (click)="showOrHideClearIcon(sectionIndex, index, true)"><img
                                                                        src="../../../../../assets/img/Calendar.svg"
                                                                        alt="">
                                                                     {{actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value===''?'select
                                                                     date and
                                                                     time':actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value}}

                                                                  </button>

                                                                  <span class="close-button"
                                                                     (click)="closeInfo(sectionIndex,index)"
                                                                     [hidden]="actionFieldForm.value?.sections[sectionIndex]
                                                                                                      .actionFields[index].clearIcon == false"><img
                                                                        src="../../assets/img/close_whitebg_remove.svg"
                                                                        alt="" /></span>

                                                                  <div aria-labelledby="dropdownMenuButton" [hidden]="!actionFieldForm.value?.sections[sectionIndex]
                                                      .actionFields[index].dateTimeDropdownToggleFlag" [ngClass]="{'toggle-show':actionFieldForm.value?.sections[sectionIndex]
                                                      .actionFields[index].dateTimeDropdownToggleFlag}"
                                                                     class="dropdown-menu" style="display:block;">
                                                                     <ngb-datepicker class="date-time-designchange"
                                                                        [(ngModel)]="date" [startDate]="actionFieldForm.value?.sections[sectionIndex]
                                              .actionFields[index].startDate" formControlName="dates"
                                                                        (click)="changeDateTime($event, date, time, sectionIndex, index,
                                                                        actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value)"></ngb-datepicker>
                                                                     <ngb-timepicker [(ngModel)]="actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].times"
                                                                        formControlName="times" (click)="changeDateTime($event, date, actionFieldForm.value?.sections[sectionIndex]
                                                                        .actionFields[index].times, sectionIndex, index,
                                                                        actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value)"
                                                                        (change)="changeDateTime($event, date, actionFieldForm.value?.sections[sectionIndex]
                                                                        .actionFields[index].times, sectionIndex, index,
                                                                        actionFieldForm.value?.sections[sectionIndex]
                                                                     .actionFields[index].actionFieldValue[0].value)"
                                                                        [meridian]="true"></ngb-timepicker>
                                                                  </div>
                                                               </div>
                                                               <div class="is-invalid error"
                                                                  *ngIf="e.get('actionFieldValue.0.value')?.invalid && e.get('actionFieldValue.0.value')?.touched">
                                                                  <small
                                                                     *ngIf="e.get('actionFieldValue.0.value')?.errors?.required">
                                                                     Please enter the field</small>
                                                               </div>
                                                               <div class="is-invalid-color error" *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                    .actionFields[index].dateTimeValidation">
                                                                  <small>
                                                                     Please enter both date and time</small>
                                                               </div>
                                                               <span class="tool-tip"
                                                                  *ngIf="actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText?.trim()!='' && actionFieldForm.value?.sections[sectionIndex]
                                                               .actionFields[index].toolTipText">{{actionFieldForm.value?.sections[sectionIndex]
                                                                  .actionFields[index].toolTipText}}</span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </ng-template>
                                                   <!--dateTime-->
                                                </div>
                                                <!---dynamic field builder ends-->
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="footer-btn-ctr" *ngIf="actionFieldForm.value?.sections?.length>0">
         <button class="field-btn cancel-field" [class.disabled]="disableSave" [disabled]="disableSave"
            (click)="closeActionField()">CANCEL</button>
         <button class="field-btn add-field" [class.disabled]="disableSave" [disabled]="disableSave"
            (click)="makeRequest()">SAVE</button>
      </div>
   </div>
</form>

<div class="no-data" *ngIf="actionFieldForm.value?.sections?.length==0">
   <p class="no-data-found mt-2 mb-4">No Action Field Added</p>
</div>



<!-- Action Field changes modal start -->
<div tabindex="-1" role="dialog" [hidden]="!showCancelModal" id="changes-action-popup" data-bs-backdrop="static"
   data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true" style="display: block">
   <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
      <div class="modal-content" style="border: none">
         <div class="removed-action-popup move-height popup-center">
            <div class="confirm-element-usage">
               <span class="alert-icon">
                  <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" />
               </span>
               <button type="button" class="close delete-close-icon" (click)="
            showCancelModal = !showCancelModal
            ">
                  <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon" />
               </button>
               <div class="body-section confirm-removal">
                  <h3>Changes Will Not Be Saved</h3>
                  <div class="discription">Are you sure you want to proceed?</div>
                  <div class="button-setup">
                     <button class="edit-permissons mr-2" (click)="
                showCancelModal = !showCancelModal
                ">
                        GO BACK
                     </button>
                     <button class="add-type" (click)="closeModal()">PROCEED</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Action Field Changes modal end -->