<div
  id="dateRangeButton"
  *ngIf="!isMobileView"
  class="btn filter-selct-btn position-relative w100 custom-arow"
  style="padding-top: 6px"
  [ngClass]="{ borderSurrounding: isDateRangeToggle }"
  (click)="openDateRange()"
  id="dropdownMenuButton"
>
  {{ dateRangeSelectedText }}
  <span
    ><img
      src="../../../../../assets/img/down_arrow-lg.svg"
      alt=""
      [ngClass]="{ 'rotate-arrow': isDateRangeToggle }"
  /></span>
  <span
    ><img
      src="../../../../../assets/img/close-sm.png"
      class="close-sm"
      alt=""
      (click)="clearFilters(filterType.type, filterType.index, filterType.id)"
  /></span>
</div>

<div class="filterRange-wrap" *ngIf="isDateRangeToggle">
  <div (click)="CloseOut()" class="all-date-backdrop"></div>
  <div class="flter-dateRange" id="dateRangeDropdown">
    <div class="date-range">
      <ul>
        <li
          [ngClass]="{
            'status-selction': dateRangeFilterType == filter || filter.checked
          }"
          *ngFor="let filter of shipmentFilterRange"
          (click)="selectActiveDateFilter(filter)"
        >
          {{ filter.status }}
        </li>
      </ul>
    </div>
    <div class="nav-conainer">
      <div class="btn-group dropdown filterDropdown">
        <button
          type="button"
          class="btn btn-range dropdown-toggle p-1 mx-2"
          data-toggle="dropdown"
        >
          {{ isSelectedRangeValue }}

          <span class="arrow-toggle fa fa-angle-down"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ul class="custom-drop-menu">
            <li
              class="dropdown-item"
              (click)="selectDateRange(dateRange)"
              *ngFor="let dateRange of filterDateRange"
            >
              {{ dateRange.range }}
              <span *ngIf="dateRange.val > 2 && dateRange.val <= 6"
                >{{ dateRange.uiFromDate }} -
                {{ dateRange.uiToday }}
              </span>
              <span *ngIf="dateRange.val > 0 && dateRange.val <= 2">
                {{ dateRange.uiToday }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <p style="color: red" *ngIf="!isValidDates">
          {{ dateRangeErrorMessage }}
        </p>
        <div class="d-flex align-items-center justify-content-center">
          <div class="pl-2">
            <input
              type="text"
              id="dateFrom"
              [(ngModel)]="dateFrom"
              (change)="ondateFromChange($event)"
              (keypress)="ondateFromChange($event)"
              placeholder="mm/dd/yyyy"
              maxlength="10"
              ngDefaultControl
            />
          </div>
          <div class="pl-2 d-flex align-items-center">
            <span class="fw-normal">to</span>
            <span class="px-2"
              ><input
                type="text"
                id="dateTo"
                [(ngModel)]="dateTo"
                (change)="ondateToChange($event)"
                (keypress)="ondateToChange($event)"
                placeholder="mm/dd/yyyy"
                maxlength="10"
                ngDefaultControl
            /></span>
          </div>
        </div>
        <ngb-datepicker
          #dp
          [(ngModel)]="model"
          (navigate)="date = $event.next"
          (dateSelect)="onDateSelection($event)"
          [displayMonths]="1"
          [dayTemplate]="t"
          outsideDays="hidden"
          ngDefaultControl
        >
        </ngb-datepicker>
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>

      <div class="text-right mt-0 mr-3 mb-2">
        <button
          class="btn btn-primary add-btn"
          (click)="onAddDateRangeFilter($event)"
          [disabled]="
            (!dateTo && !dateFrom) || dateRangeFilterType === undefined
          "
        >
          ADD
        </button>
      </div>
    </div>
    <div
      class="active-dateRange"
      *ngIf="selectedDateRangeFilters && selectedDateRangeFilters.length > 0"
    >
      <div class="rap-selected-date" *ngFor="let item of groupedSelectedArray">
        <h6>{{ item.type }}</h6>
        <div class="rap-selection-scroll">
          <span
            class="data"
            *ngFor="
              let selectedItem of item.selectedDates;
              let indexOfelement = index
            "
          >
            <span class="pr-1">
              <img
                alt="close-button"
                src="../../../../../assets/img/close-sm.png"
                class="filter-close"
                (click)="onRemoveSelectedDateRangeFilterHandler(selectedItem)"
              />
            </span>
            <ng-container
              >{{ getDisplayDateFormatted(selectedItem.fromDate) }} -
              {{ getDisplayDateFormatted(selectedItem.toDate) }}
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- mobile design start -->
<div class="accordion-body" *ngIf="isMobileView">
  <p style="color: red" *ngIf="!isValidDates">
    {{ dateRangeErrorMessage }}
  </p>
  <div
    *ngIf="selectedDateRangeFilters && selectedDateRangeFilters.length > 0"
    class="mb-2"
  >
    <div class="mt-2 active-date">Active Date Range Filters</div>
    <div
      *ngFor="let data of selectedDateRangeFilters; let i = index"
      class="d-flex mobile-ruler justify-content-between align-items-center"
    >
      <div class="d-flex pr-1 align-items-center">
        <span class="pr-1"
          ><img
            src="../../../../../assets/img/close-sm.png"
            class="filter-close"
            alt=""
            (click)="onRemoveSelectedDateRangeFilterHandler(data)"
        /></span>
        <span class="filter-data-mobile">{{ data.status }}</span>
      </div>
      <div class="data-status">
        {{ getDisplayDateFormatted(data.fromDate) }} -
        {{ getDisplayDateFormatted(data.toDate) }}
      </div>
    </div>
  </div>
  <div class="dropdown filterDropdown d-block mb-2">
    <label>Date Range By</label>
    <button
      type="button"
      class="btn btn-range w-100 text-left"
      (click)="mobileDataRangeBy()"
      data-toggle="dropdown"
    >
      {{ dateRangeByValue }}
      <img
        alt="arrow-down"
        *ngIf="dateRangeImg"
        class="arrow-icon"
        src="assets/img/down_arrow-lg.svg"
      />
      <img
        alt="arrow-down"
        *ngIf="!dateRangeImg"
        class="arrow-icon"
        src="assets/img/up_arrow_lg.svg"
      />
    </button>
    <div id="dateRange" class="dropdown-menu">
      <ul class="custom-drop-menu">
        <li
          class="dropdown-item"
          [ngClass]="{
            'status-selction': dateRangeFilterType == filter || filter.checked
          }"
          *ngFor="let filter of shipmentFilterRange"
          (click)="selectActiveDateFilter(filter)"
        >
          {{ filter.status }}
        </li>
      </ul>
    </div>
  </div>

  <div class="dropdown filterDropdown d-block mb-2">
    <label>Filter Dates</label>
    <button
      type="button"
      class="btn btn-range w-100 text-left"
      (click)="mobileTodayRange()"
      data-toggle="dropdown"
    >
      {{ isSelectedRangeValue }}
      <img
        alt="arrow-down"
        *ngIf="todayRangImg"
        class="arrow-icon"
        src="assets/img/down_arrow-lg.svg"
      />
      <img
        alt="arrow-down"
        *ngIf="!todayRangImg"
        class="arrow-icon"
        src="assets/img/up_arrow_lg.svg"
      />
    </button>
    <div id="filterDates" class="dropdown-menu">
      <ul class="custom-drop-menu">
        <li
          class="dropdown-item"
          (click)="selectDateRange(dateRange)"
          *ngFor="let dateRange of filterDateRange"
        >
          {{ dateRange.range }}
          <span *ngIf="dateRange.val > 2 && dateRange.val <= 6"
            >{{ dateRange.uiFromDate }} -
            {{ dateRange.uiToday }}
          </span>
          <span *ngIf="dateRange.val > 0 && dateRange.val <= 2">
            {{ dateRange.uiToday }}
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div (click)="openMobileDatepicker()">
    <div
      class="dropdown d-flex align-items-center justify-content-left date-fill"
    >
      <div class="set-full" data-toggle="dropdown">
        <input
          type="text"
          id="dateFrom"
          [(ngModel)]="dateFrom"
          (change)="ondateFromChange($event)"
          (keypress)="ondateFromChange($event)"
          placeholder="mm/dd/yyyy"
          maxlength="10"
          ngDefaultControl
        />
      </div>
      <span class="p-2">to</span>
      <div class="set-full" data-toggle="dropdown">
        <input
          type="text"
          id="dateTo"
          [(ngModel)]="dateTo"
          (change)="ondateToChange($event)"
          (keypress)="ondateToChange($event)"
          placeholder="mm/dd/yyyy"
          maxlength="10"
          ngDefaultControl
        />
      </div>

      <div class="dropdown-menu mobile-datepicker">
        <ngb-datepicker
          #dp
          [(ngModel)]="model"
          (navigate)="date = $event.next"
          (dateSelect)="onDateSelection($event)"
          [displayMonths]="1"
          [dayTemplate]="t"
          outsideDays="hidden"
          ngDefaultControl
        >
        </ngb-datepicker>
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="text-left mt-0 mr-3 mb-2">
    <button
      class="btn btn-primary add-btn mt-3"
      (click)="onAddDateRangeFilter($event)"
      [disabled]="(!dateTo && !dateFrom) || dateRangeFilterType === undefined"
    >
      ADD
    </button>
  </div>
</div>
