import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { BookingServies } from '../../services/booking.service';
import { AllLocationFilterComponent } from '../all-location-filter/all-location-filter.component';

@Component({
  selector: 'app-booking-custom-filters',
  templateUrl: './booking-custom-filters.component.html',
  styleUrls: ['./booking-custom-filters.component.scss'],
})
export class BookingCustomFiltersComponent implements OnInit, OnChanges {
  @Input() bookingSortOptions: any = [];
  @Input() sortOptions: any = [];
  @Input() isDashboard: any;
  @Input() isBookingsDashboardWatchlist: any;
  @Input() isBookingsDashboard: any;
  selectedMainFilter: any[] = [];
  watchlistFilter = 'Watchlist';
  mobileSelectedFilter: any = '';
  public checkWatchlist: boolean;
  @Input() resultCount: any;
  @Input() defaultBookingSortSelectedValue: number;
  @Input() defaultBookingSortDirection: string = 'asc';
  @Input() defaultSortSelectedValue: number = 1;
  @Input() defaultSortDirection: string = 'asc';
  @Output() selectedFilterArray = new EventEmitter<any>();
  @Output() selectedMainFilterArray = new EventEmitter<any>();
  @Output() selectedDateRange = new EventEmitter<any>();
  defaultBookingSortSelectedColumn = 'Booked Date';
  defaultBookingSortBookingsDashboard = 'Booked Date';
  defaultBookingSortBookingsDashboardWatchlist = 'Booked Date';
  clickBookingSortOption = new Subject();
  clickSortOption = new Subject();
  myFilterName: any = '';
  @Output() onSortSelectedHandler: any = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Input() bookingSearchText: string = '';
  @Output() downloadExcel = new EventEmitter<any>();
  @Output() clearAllFilterValue = new EventEmitter<any>();
  @Input() urlShare = '';
  @Output() selectedUrlFilters = new EventEmitter<any>();
  @Output() clearSearchFilter = new EventEmitter<any>();
  @Output() onBookingSearchHandler = new EventEmitter<any>();
  @Output() locFilter = new EventEmitter<any>();
  @Input() selectedDateRangeRender: any;
  @Input() resentList: any;
  @Input() paramCheck: any;
  @ViewChild(AllLocationFilterComponent)
  allLocation!: AllLocationFilterComponent;
  clickEvent = new Subject();
  copyText: any = '';
  bookingSharePopup = false;
  myEditFilterName: any = '';
  scrHeight: any;
  scrWidth: any;
  isMobileViewParent = false;
  offsetOrgin: any = 0;
  offsetDestination: any = 0;
  offsetShipper: any = 0;
  offsetPickup: any = 0;
  offsetFinalDelivery: any = 0;
  accountID: any;
  orginEmail: any;
  mobileFilterContainer: boolean = false;
  isGroup: any;
  currentAccDetails = this.accountSessionData.getCurrentAccountData();
  currentUserDetails = this.accountSessionData.getCurrentUserData();
  shipperList: any[] = [];
  pickupList: any[] = [];
  allLocationList: any[] = [];
  finalDeliveryList: any[] = [];
  originList: any[] = [];
  destinationList: any[] = [];
  isProgressLocation: boolean = false;

  resentListOrigin: any = [];
  resentListDestination: any = [];
  resentListShipper: any = [];
  resentListPickUp: any = [];
  resentListFinalDelivery: any = [];
  resentAllLocationList: any = [];
  mobileStatusFilter: any = [];
  mobileDateRangeFilter: any = [];
  mobileTransportFilter: any = [];
  mobileOriginFilter: any = [];
  mobileDestinationtFilter: any = [];
  mobileWatchListFilter: any = [];
  mobileShipperFilter: any = [];
  mobileAllLocationFilter: any = [];
  mobileLocationFilterReselect: any = [];
  mobileOrginFilterReselect: any = [];
  mobileDestinationFilterReselect: any = [];
  mobilePickupFilterReselect = [];
  mobileFinalDeliveryFilterReselect = [];
  mobileShipperFilterReselect: any = [];
  @Input() tempFilterDiff: any;
  @Input() isfilterEdit: any;
  tempSortDiff: any;
  isShowEditPopup = false;
  createUserFilterPopUp: boolean = false;
  myFilterError: boolean = false;
  myFilterNameEmpty: boolean = false;
  isShowSaveAsBtn: boolean = false;
  isShowSaveBtn: boolean = false;
  tempUndoArray: any;
  @Input() saveFilterJson: any;
  @Input() savedFilters: any;

  @Output() onUserFilterSaved = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<any>();
  @Output() userFilterFetch = new EventEmitter<any>();
  statusFilterValue: boolean = false;
  transportFilterValue: boolean = false;
  favoritesFilterValue: boolean = false;
  shipperFilterValue: boolean = false;
  locationFilterValue: boolean = false;
  dateFilterValue: boolean = false;
  fetchMobileRecords = 30;
  allLocationReselectedList: any[] = [];
  isCustomFilterSecHide: boolean;
  isCustomFilterSecShow: boolean;
  isFilterHide: boolean;
  isFilterShow: boolean;
  isSearchBoxHide: boolean;
  isSearchBoxShow: boolean;
  isSortShow: boolean;
  isSortHide: boolean;
  @Input() guidedTourFilter: boolean;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768 ? true : false;
    this.clearMobileFilters();
  }
  filterItemset = [
    { filt: 'Date Range' },
    { filt: 'Location' },
    { filt: 'Shipper' },
    { filt: 'Status' },
    {
      filt: 'Transport Type',
    },
    { filt: 'Watchlist' },
  ];
  filterItem: any[] = [
    { filt: 'Date Range', val: 5, col_name: '', checked: false },
    { filt: 'Location', val: 4, col_name: 'allLocation', checked: false },
    { filt: 'Shipper', val: 2, col_name: 'shipper', checked: false },
    { filt: 'Status', val: 1, col_name: 'statuscustom', checked: false },
    {
      filt: 'Transport Type',
      val: 0,
      col_name: 'transport',
      checked: false,
    },
    { filt: 'Watchlist', val: 7, col_name: '', checked: false },
  ];

  statusFilterItem: any[] = [
    { filt: 'Pending', val: 1, checked: false },
    { filt: 'Converted', val: 2, checked: false },
    { filt: 'Canceled', val: 3, checked: false },
    { filt: 'Archived', val: 4, checked: false },
  ];

  transportFilterItem: any[] = [
    { filt: 'Sea', val: 1, checked: false },
    { filt: 'Air', val: 2, checked: false },
    { filt: 'Road', val: 3, checked: false },
    { filt: 'Rail', val: 4, checked: false },
    { filt: 'Courier', val: 5, checked: false },
  ];

  shipmentFilterRange: any[] = [
    { status: 'Est Pickup', val: 0, checked: false },
    { status: 'Pickup', val: 1, checked: false },
    { status: 'ETD', val: 2, checked: false },
    { status: 'ATD', val: 3, checked: false },
    { status: 'ETA', val: 4, checked: false },
    { status: 'ATA', val: 5, checked: false },
    { status: 'Est Delivery', val: 6, checked: false },
    { status: 'Delivery', val: 7, checked: false },
  ];

  exceptionsFilterItem: any[] = [
    { filt: 'No Exceptions', val: 1, checked: false },
    { filt: 'Expected Delay', val: 2, checked: false },
    { filt: 'Delayed In Transit', val: 4, checked: false },
    { filt: 'Delivered Late', val: 3, checked: false },
  ];

  @Output() selectedDefualtUrlFilters = new EventEmitter<any>();
  constructor(
    public bookingService: BookingServies,
    private accountSessionData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService,
    private dataSharingService: DataSharingService
  ) {
    this.loadInitialPageDataForConstructor();
  }

  loadInitialPageDataForConstructor() {
    this.accountID = this.currentAccDetails.accountId;
    this.orginEmail = this.currentUserDetails.emailAddress;
    this.isGroup = this.currentAccDetails.isGroup;
    this.dataSharingService.clearShipmentSearchTextBox.subscribe(
      (res: boolean) => {
        if (res) {
          this.bookingSearchText = '';
        }
      }
    );
  }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  loadInitialPageData() {
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768 ? true : false;
    this.clearMobileFilters();
    this.clickSortOption.pipe(debounceTime(900)).subscribe((e: any) => {
      this.onSortHandler(e);
    });
    this.clickEvent.pipe(debounceTime(900)).subscribe((e) => {
      this.onSearchHandler(e);
    });
    $(document).ready(function () {
      $('.restrict-close').on('click', function (e: any) {
        e.stopPropagation();
      });
    });

    if (this.isMobileViewParent) {
      this.fetchMobileRecords = 10;
    }
    this.subscribeDataSharingEvents();
  }

  subscribeDataSharingEvents() {
    this.dataSharingService.isGuidedTourCustomFilterCompHide.subscribe(
      (value) => {
        this.isCustomFilterSecHide = value;
      }
    );
    this.dataSharingService.isGuidedTourCustomFilterCompShow.subscribe(
      (value) => {
        this.isCustomFilterSecShow = value;
      }
    );
    this.dataSharingService.isGuidedTourCustomFilterHide.subscribe((value) => {
      this.isFilterHide = value;
    });
    this.dataSharingService.isGuidedTourCustomFilterShow.subscribe((value) => {
      this.isFilterShow = value;
    });
    this.dataSharingService.isGuidedTourSortHide.subscribe((value) => {
      this.isSortHide = value;
    });
    this.dataSharingService.isGuidedTourSortShow.subscribe((value) => {
      this.isSortShow = value;
    });
    this.dataSharingService.isGuidedTourSearchTextBoxHide.subscribe((value) => {
      this.isSearchBoxHide = value;
    });
    this.dataSharingService.isGuidedTourSearchTextBoxShow.subscribe((value) => {
      this.isSearchBoxShow = value;
    });
  }

  ngOnChanges() {
    if (this.paramCheck) {
      this.highlightSelectedFilter();
    }
    this.defaultBookingSortSelectedColumn =
      this.bookingSortOptions[this.defaultBookingSortSelectedValue - 1]?.name;
    this.initialRecentLocation();
  }

  highlightSelectedFilter() {
    let filterOptions = this.paramCheck.filter_options;
    this.setAllMobFilterFalse();
    if (filterOptions.length < 1) {
      this.setAllMobFilterFalse();
    } else {
      filterOptions.forEach((value: any) => {
        switch (value.column_name) {
          case 'statuscustom':
            this.statusFilterValue = true;
            break;
          case 'transport':
            this.transportFilterValue = true;
            break;
          case 'favorites':
            this.favoritesFilterValue = true;
            break;
          case 'shipper':
            this.shipperFilterValue = true;
            break;
          case 'pickuplocation':
          case 'delivery':
          case 'origin':
          case 'destination':
            this.locationFilterValue = true;
            break;
          case 'estimatepickup':
          case 'bookeddate':
          case 'etd':
          case 'eta':
          case 'estimatedelivery':
            this.dateFilterValue = true;
            break;
          default:
            //this.setAllMobFilterFalse()
            break;
        }
      });
    }
  }

  setAllMobFilterFalse() {
    this.statusFilterValue = false;
    this.transportFilterValue = false;
    this.favoritesFilterValue = false;
    this.shipperFilterValue = false;
    this.locationFilterValue = false;
    this.dateFilterValue = false;
  }

  clearMobileFilters() {
    if (!this.isMobileViewParent) {
      this.mobileStatusFilter = [];
      this.mobileDateRangeFilter = [];
      this.mobileTransportFilter = [];
      this.mobileOriginFilter = [];
      this.mobileDestinationtFilter = [];
      this.mobileWatchListFilter = [];
      this.mobileSelectedFilter = '';
    }
  }

  onSortHandler(sortOption: any) {
    if (sortOption.id === this.defaultBookingSortSelectedValue) {
      this.defaultBookingSortDirection =
        this.defaultBookingSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.defaultBookingSortDirection = 'asc';
    }
    this.appInsightsService.logEvent(AI_CommonEvents.SortData.toString(), {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      [AI_CustomProps.SortBy]: sortOption.name,
      [AI_CustomProps.SortOrder]:
        this.defaultBookingSortDirection == 'asc'
          ? 'Ascending Order'
          : 'Descending Order',
    });
    sortOption.sort_direction = this.defaultBookingSortDirection;

    if (!this.isDashboard) {
      this.defaultBookingSortSelectedColumn = sortOption.name;
    } else if (this.isBookingsDashboard) {
      this.defaultBookingSortBookingsDashboard = sortOption.name;
    } else if (this.isBookingsDashboardWatchlist) {
      this.defaultBookingSortBookingsDashboardWatchlist = sortOption.name;
    }
    this.onSortSelectedHandler.emit(sortOption);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  isFilterSelected(
    status: any,
    filterValue: any,
    filterDisplayName: any,
    filterText: any,
    index: any
  ) {
    if (status) {
      this.appInsightsService.logEvent(filterDisplayName + ' Filter Applied');
      switch (filterValue) {
        case 0:
          this.setBookingMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.transportFilterItem,
            true
          );
          break;
        case 1:
          this.setBookingMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.statusFilterItem,
            true
          );
          break;
        case 2:
          this.getShipperList('');
          this.setBookingMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.shipperList,
            true
          );
          break;

        case 4:
          $('#watchListId').click();
          this.setBookingMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          this.getPickUpList('');
          break;
        case 5:
          $('#watchListId').click();
          this.setBookingMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          break;

        case 7:
          this.appInsightsService.logEvent(
            AI_CommonEvents.WatchListFilterApplied.toString(),
            {
              [AI_CustomProps.IsWatchList]: 'true',
              [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
            }
          );

          this.setBookingMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          $('#watchListId').click();
          this.selectedFilterArray.emit({
            type: 'favorites',
            selectedValue: [
              { column_name: 'favorites', operater: '=', value: true },
            ],
            clearFrom: '',
          });
          setTimeout(() => {
            this.saveAsButtonVisibility();
          }, 300);
          break;
      }
    } else {
      let filterCloseData = {
        type: filterValue == 7 ? 'favorites' : filterText,
        index: index,
        id: filterValue,
        eventType: '',
      };
      this.closeCustomFilters(filterCloseData);
    }
    this.selectedMainFilterArray.emit(this.selectedMainFilter);
  }

  getSelectedFilterArray(event: any) {
    this.selectedFilterArray.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  getDateRange(event: any) {
    this.selectedDateRange.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  filterIsOpen(
    event: any,
    status: any,
    filterValue: any,
    filterDisplayName: any,
    filterText: any,
    index: any
  ) {
    let isVisible = $(event).attr('aria-expanded');
    if (isVisible == 'true') {
      this.isFilterSelected(
        status,
        filterValue,
        filterDisplayName,
        filterText,
        index
      );
      if (filterText == 'location') {
        this.allLocation.allLocationDefaultCustomfilterM('Pickup');
      }
    } else {
      let checker: any;
      switch (filterValue) {
        case 0:
          checker = Object.values(this.transportFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 1:
          checker = Object.values(this.statusFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 6:
          checker = Object.values(this.exceptionsFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 2:
          this.shipperList = [];
          this.offsetShipper = 0;
          break;
      }
    }
  }

  removeMainFilter(isPresent: boolean, filterValue: any) {
    if (!isPresent) {
      let elementPos = this.selectedMainFilter
        .map(function (x: any) {
          return x.id;
        })
        .indexOf(filterValue);
      if (elementPos > -1) {
        this.selectedMainFilter.splice(elementPos, 1);
      }
    }
  }

  watchlistSelected(event: any) {
    if (event.target.checked) {
      this.checkWatchlist = event.target.checked;
      this.isFilterSelected(true, 7, 'Watchlist', 'watchlist', 7);
    } else {
      this.checkWatchlist = event.target.checked;
      this.closeWatchFilter();
    }
  }

  closeCustomFilters(event: any) {
    if (event.id == '0') {
      this.transportFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '1') {
      this.statusFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '6') {
      this.exceptionsFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '2') {
      this.shipperList = [];
      this.offsetShipper = 0;
    } else if (event.id == '4') {
      this.allLocationList = [];
      this.offsetOrgin = 0;
      this.offsetPickup = 0;
      this.offsetDestination = 0;
      this.offsetFinalDelivery = 0;
    }
    if (event.closeFrom == 'defaultFilter') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: 'defaultFilter',
        account: [],
      });
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    } else if (event.closeFrom !== 'clearFilterOnly') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: '',
        account: [],
      });
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    }
    let elementPos = this.selectedMainFilter
      .map(function (x: any) {
        return x.index;
      })
      .indexOf(event.index);

    if (elementPos > -1) {
      this.selectedMainFilter.splice(elementPos, 1);
    }

    if (event.eventType == 'closeBtn') {
      this.filterItem.forEach((e: any) => {
        if (e.val == event.id) {
          e.checked = false;
        }
      });
    }
    this.selectedMainFilterArray.emit(this.selectedMainFilter);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  setBookingMainFilterArray(
    id: any,
    filterDisplayName: any,
    type: any,
    index: any,
    filterArray: any,
    autoOpen: boolean
  ) {
    let currentFilterData = {
      id,
      filterDisplayName,
      type,
      index,
      filterArray,
      autoOpen,
    };
    if (this.checkFilterPresent(id) === -1) {
      this.selectedMainFilter.push(currentFilterData);
    }
    if (this.isMobileViewParent) {
      this.setMobileFilterData(id, currentFilterData);
    }
  }

  setMobileFilterData(id: any, currentFilterData: any) {
    currentFilterData.autoOpen = false;
    switch (id) {
      case 0:
        this.mobileTransportFilter = currentFilterData;
        break;
      case 1:
        this.mobileStatusFilter = currentFilterData;
        break;
      case 2:
        this.mobileShipperFilter = currentFilterData;
        if (typeof currentFilterData.filterDisplayName === 'object') {
          this.mobileShipperFilterReselect =
            currentFilterData.filterDisplayName;
        }
        break;
      case 3:
        break;
      case 4:
        this.mobileAllLocationFilter = currentFilterData;
        break;
      case 5:
        this.mobileDateRangeFilter = currentFilterData;
        break;

      case 7:
        this.mobileWatchListFilter = currentFilterData;
        break;
    }
  }

  checkFilterPresent(filterValue: any) {
    return this.selectedMainFilter
      .map(function (x: any) {
        return x.id;
      })
      .indexOf(parseInt(filterValue));
  }

  openMobileFilter() {
    this.mobileFilterContainer = true;
  }

  closeFilter() {
    this.mobileFilterContainer = false;
    if (this.mobileLocationFilterReselect.type == 'shipper') {
      this.mobileShipperFilterReselect =
        this.mobileLocationFilterReselect.account;
    }

    this.allLocationReselectedList = [
      { origin: this.mobileOrginFilterReselect },
      { destination: this.mobileDestinationFilterReselect },
      { pickup: this.mobilePickupFilterReselect },
      { finalDelivery: this.mobileFinalDeliveryFilterReselect },
    ];
  }

  clearAllFilter() {
    this.filterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.statusFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.transportFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.exceptionsFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.selectedMainFilter = [];

    this.mobileShipperFilterReselect = [];
    this.selectedDateRangeRender = [];
    this.checkWatchlist = false;
    this.mobileSelectedFilter = '';
    this.clearAllFilterValue.emit('clearAll');
    this.mobileAllLocationFilter.filterArray = [];

    this.mobileOrginFilterReselect = [];
    this.mobileDestinationFilterReselect = [];
    this.mobilePickupFilterReselect = [];
    this.mobileFinalDeliveryFilterReselect = [];

    this.allLocationReselectedList = [
      { origin: this.mobileOrginFilterReselect },
      { destination: this.mobileDestinationFilterReselect },
      { pickup: this.mobilePickupFilterReselect },
      { finalDelivery: this.mobileFinalDeliveryFilterReselect },
    ];
    this.selectedDateRangeRender = [];
  }

  closeWatchFilter() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.WatchListFilterCleared.toString(),
      {
        [AI_CustomProps.IsWatchList]: 'false',
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
    let event = {
      type: 'favorites',
      id: '7',
      eventType: 'closeBtn',
      index: 7,
      closeFrom: '',
    };

    this.closeCustomFilters(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  share(event: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.SharedAll.toString(), {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });

    this.bookingSharePopup = true;
    this.prepareUrlShare.emit({
      type: 'All',
      bookingId: this.bookingSearchText,
    });
  }

  closeSharePopUp() {
    this.bookingSharePopup = false;
  }

  excelExport(download: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.ExcelExported.toString(), {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });

    this.downloadExcel.emit(download);
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  loadURLFilters(filterURl: any) {
    let status = filterURl.statusFilter;
    let transport = filterURl.transportType;
    let origin = filterURl.allLocation?.selectedValue[0]?.originValue;
    let destination = filterURl.allLocation?.selectedValue[1]?.destinationValue;
    let dateRange = filterURl.dateRange;
    let shipper = filterURl.shipper;
    let pickUp = filterURl.allLocation?.selectedValue[2]?.pickupValue;
    let finalDelivery =
      filterURl.allLocation?.selectedValue[3]?.finalDeliveryValue;
    let allLocation = filterURl.allLocation;
    filterURl?.mainFilter?.forEach((element: any) => {
      this.filterItem.forEach((e: any) => {
        if (element.id == e.val) {
          e.checked = true;
        }
      });

      if (element.id == '0') {
        this.ApplyElementCase2(transport, element);
      } else if (element.id == '1') {
        this.ApplyElementCase0(status, element);
      } else if (element.id == '2') {
        this.getShipperList('');
        this.mapFilterData(
          element,
          '',
          shipper.selectedAccount,
          this.shipperList
        );
      } else if (element.id == '4') {
        this.mapFilterData(element, '', '', allLocation.selectedAccount);
      } else if (element.id == '5') {
        this.mapFilterData(element, '', '', dateRange.selectedDateRange);
      } else if (element.id == '7') {
        this.mapFilterData(element, 'Watchlist', '', filterURl.watchFilter);
      }
    });
    if (filterURl.type != 'defaultFilter') {
      this.selectedUrlFilters.emit({
        status: status,
        transport: transport,
        origin: origin,
        destination: destination,
        dateRange: dateRange.selectedValue ? dateRange.selectedValue : [],
        mainfilter: filterURl.mainFilter,
        watchFilter: filterURl.watchFilter,
        bookingId: filterURl.bookingId,
        shipper: shipper.selectedValue ? shipper.selectedValue : [],
        pickUp: pickUp,
        finalDelivery: finalDelivery,
        shipperAccount: shipper.selectedAccount ? shipper.selectedAccount : [],
        originAccount: filterURl.allLocation?.selectedAccount[0]?.origin
          ? filterURl.allLocation?.selectedAccount[0]?.origin
          : [],
        destinationAccount: filterURl.allLocation?.selectedAccount[1]
          ?.destination
          ? filterURl.allLocation?.selectedAccount[1]?.destination
          : [],
        pickUpAccount: filterURl.allLocation?.selectedAccount[2]?.pickup
          ? filterURl.allLocation?.selectedAccount[2]?.pickup
          : [],
        finalDeliveryAccount: filterURl.allLocation?.selectedAccount[3]
          ?.finalDelivery
          ? filterURl.allLocation?.selectedAccount[3]?.finalDelivery
          : [],
      });
    } else if (filterURl.type === 'defaultFilter') {
      this.selectedDefualtUrlFilters.emit({
        mainfilter: filterURl.mainFilter,
      });
    }
  }

  mapFilterData(
    element: any,
    displayText: any,
    selectedAcc: any,
    filterArray: any
  ) {
    let mapData = {
      id: element.id,
      filterDisplayName: displayText ? displayText : element.filterDisplayName,
      selectedAccount: selectedAcc,
      type: element.type,
      index: element.index,
      filterArray: filterArray,
    };
    this.selectedMainFilter.push(mapData);
    this.setMobileFilterData(element.id, mapData);
  }

  ApplyElementCase0(status: any, element: any) {
    if (status) {
      let statusChecked = status[0]?.value?.split(',');
      statusChecked?.forEach((ele: any) => {
        let elementPos = this.statusFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        if (elementPos > -1) {
          this.statusFilterItem[elementPos].checked = true;
        } else {
          this.statusFilterItem[elementPos].checked = false;
        }
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.statusFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.statusFilterItem);
  }

  ApplyElementCase2(transport: any, element: any) {
    if (transport) {
      let transportChecked = transport[0]?.value?.split(',');
      transportChecked?.forEach((ele: any) => {
        let elementPos = this.transportFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        if (elementPos > -1) {
          this.transportFilterItem[elementPos].checked = true;
        } else {
          this.transportFilterItem[elementPos].checked = false;
        }
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.transportFilterItem,
      element.filterDisplayName
    );

    this.mapFilterData(element, displayText, '', this.transportFilterItem);
  }

  trimDisplayStringCustomFilter(filterArray: any, filterDisplayName: any) {
    let displayText = '';
    filterArray
      .filter((t: any) => t.checked)
      .map((t: any) => (displayText += t.filt + ', '));
    if (displayText.trim().length > 5) {
      displayText = displayText.replace(/,\s*$/, '');
    }
    displayText = displayText?.trim();
    displayText = displayText.replace(/,\s*$/, '');
    if (displayText?.length > 5) {
      displayText =
        filterDisplayName + ' : ' + displayText?.substring(0, 5) + '...';
    } else {
      displayText = filterDisplayName + ' : ' + displayText;
    }

    return displayText;
  }

  clearSearchText(event: any) {
    if (!event) {
      this.clearSearchFilter.emit(event);
      this.bookingSearchText = '';
    }
  }

  onSearchHandler(event: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.SeachData.toString(), {
      [AI_CustomProps.SearchText]: this.bookingSearchText.trim(),
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });
    this.onBookingSearchHandler.emit({
      searchText: this.bookingSearchText.trim(),
      event,
    });
  }

  getOriginList(searchText: any) {
    this.isProgressLocation = true;
    this.bookingService
      .getOriginFilter(
        searchText,
        this.offsetOrgin,
        'origin_port',
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (this.offsetOrgin >= 10) {
          this.originList = [...this.originList, ...locationL.result];
          this.allLocationList = this.originList;
          this.isProgressLocation = false;
        } else {
          this.isProgressLocation = false;
          this.originList = locationL.result;
          this.allLocationList = this.originList;
        }
      });
  }

  getDestinationList(searchText: any) {
    this.isProgressLocation = true;
    this.bookingService
      .getOriginFilter(
        searchText,
        this.offsetDestination,
        'final_destination_port',
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((destinationL: any) => {
        if (this.offsetDestination >= 10) {
          this.destinationList = [
            ...this.destinationList,
            ...destinationL.result,
          ];
          this.allLocationList = this.destinationList;
          this.isProgressLocation = false;
        } else {
          this.isProgressLocation = false;
          this.destinationList = destinationL.result;
          this.allLocationList = this.destinationList;
        }
      });
  }

  getShipperList(searchText: any) {
    this.isProgressLocation = true;
    this.bookingService
      .getShipperFilter(
        searchText,
        this.offsetShipper,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (locationL.result && locationL.result != null) {
          if (this.offsetShipper >= 10) {
            this.isProgressLocation = false;
            this.shipperList = [...this.shipperList, ...locationL.result];
          } else {
            this.isProgressLocation = false;
            this.shipperList = locationL.result;
          }
        } else {
          this.shipperList = [];
        }
      });
  }

  getPickUpList(searchText: any) {
    this.isProgressLocation = true;
    this.bookingService
      .getPickupFilter(
        searchText,
        this.offsetPickup,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        'ship_from',
        this.fetchMobileRecords
      )
      .subscribe((pickUpL: any) => {
        if (pickUpL.result != null) {
          if (this.offsetPickup >= 10) {
            this.isProgressLocation = false;
            this.pickupList = [...this.pickupList, ...pickUpL.result];
            this.allLocationList = this.pickupList;
          } else {
            this.isProgressLocation = false;
            this.pickupList = pickUpL.result;
            this.allLocationList = this.pickupList;
          }
        } else {
          this.isProgressLocation = false;
          this.pickupList = [];
          this.allLocationList = [];
        }
      });
  }

  getFinalDeliveryList(searchText: any) {
    this.isProgressLocation = true;
    this.bookingService
      .getFinalDeliveryFilter(
        searchText,
        this.offsetFinalDelivery,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        'ship_to',
        this.fetchMobileRecords
      )
      .subscribe((deliveryL: any) => {
        if (deliveryL.result && deliveryL.result != null) {
          if (this.offsetFinalDelivery >= 10) {
            this.finalDeliveryList = [
              ...this.finalDeliveryList,
              ...deliveryL.result,
            ];
            this.isProgressLocation = false;
            this.allLocationList = this.finalDeliveryList;
          } else {
            this.isProgressLocation = false;
            this.finalDeliveryList = deliveryL.result;
            this.allLocationList = this.finalDeliveryList;
          }
        } else {
          this.isProgressLocation = false;
          this.finalDeliveryList = [];
          this.allLocationList = [];
        }
      });
  }

  loadLocationFilter(value: any) {
    switch (value) {
      case 'Pickup':
        this.allLocationList = [];
        this.offsetPickup = 0;
        this.getPickUpList('');
        break;
      case 'Origin':
        this.allLocationList = [];
        this.offsetOrgin = 0;
        this.getOriginList('');
        break;
      case 'Destination':
        this.allLocationList = [];
        this.offsetDestination = 0;
        this.getDestinationList('');
        break;
      case 'Final Delivery':
        this.allLocationList = [];
        this.offsetFinalDelivery = 0;
        this.getFinalDeliveryList('');
        break;
      default:
        this.getPickUpList('');
        break;
    }
  }

  offsetLocation(event: any) {
    if (event.type == 'Shipper') {
      this.offsetShipper = event.offset;
      this.getShipperList(event.value);
    } else if (event.type == 'origin') {
      this.offsetOrgin = event.offset;
      this.getOriginList(event.value);
    } else if (event.type == 'destination') {
      this.offsetDestination = event.offset;
      this.getDestinationList(event.value);
    } else if (event.type == 'pickup') {
      this.offsetPickup = event.offset;
      this.getPickUpList(event.value);
    } else if (event.type == 'finalDelivery') {
      this.offsetFinalDelivery = event.offset;
      this.getFinalDeliveryList(event.value);
    }
  }

  clearFilterSearch(e: any) {
    if (e.type == 'Shipper') {
      this.shipperList = [];
      this.offsetShipper = 0;
    } else if (e.type == 'Pickup') {
      this.allLocationList = [];
      this.offsetPickup = 0;
    } else if (e.type == 'Origin') {
      this.allLocationList = [];
      this.offsetOrgin = 0;
    } else if (e.type == 'Destination') {
      this.allLocationList = [];
      this.offsetDestination = 0;
    } else if (e.type == 'Final Delivery') {
      this.allLocationList = [];
      this.offsetFinalDelivery = 0;
    }
    this.getLocationList(e);
  }

  getLocationList(event: any) {
    if (event.type == 'shipper') {
      this.getShipperList(event.value);
    } else if (event.type == 'Pickup') {
      this.getPickUpList(event.value);
      this.offsetPickup = 0;
    } else if (event.type == 'Origin') {
      this.getOriginList(event.value);
      this.offsetOrgin = 0;
    } else if (event.type == 'Destination') {
      this.getDestinationList(event.value);
      this.offsetDestination = 0;
    } else if (event.type == 'Final Delivery') {
      this.getFinalDeliveryList(event.value);
      this.offsetFinalDelivery = 0;
    }
  }

  locationSelectedFilter(event: any) {
    this.mobileLocationFilterReselect = event;
    if (this.mobileLocationFilterReselect.type == 'Origin') {
      this.mobileOrginFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Destination') {
      this.mobileDestinationFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Pickup') {
      this.mobilePickupFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Final Delivery') {
      this.mobileFinalDeliveryFilterReselect =
        this.mobileLocationFilterReselect.account;
    }

    this.locFilter.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  initialRecentLocation() {
    setTimeout(() => {
      let recentShipper = sessionStorage.getItem('BookingRecentShipper');
      let recentOrigin = sessionStorage.getItem('BookingRecentOrgin');
      let recentDestination = sessionStorage.getItem(
        'BookingRecentDestination'
      );
      let recentPickUp = sessionStorage.getItem('BookingRecentPickup');
      let recentFinalDelivery = sessionStorage.getItem(
        'BookingRecentFinalDelivery'
      );
      this.resentListShipper =
        recentShipper != null ? JSON.parse(recentShipper) : [];
      this.resentListOrigin =
        recentOrigin != null ? JSON.parse(recentOrigin) : [];
      this.resentListDestination =
        recentDestination != null ? JSON.parse(recentDestination) : [];
      this.resentListPickUp =
        recentPickUp != null ? JSON.parse(recentPickUp) : [];
      this.resentListFinalDelivery =
        recentFinalDelivery != null ? JSON.parse(recentFinalDelivery) : [];
      setTimeout(() => {
        this.resentAllLocationList = [
          { recentOriginList: this.resentListOrigin },
          { recentDestinationList: this.resentListDestination },
          { recentPickupList: this.resentListPickUp },
          { recentFinalDeliveryList: this.resentListFinalDelivery },
        ];
      }, 300);
    }, 1000);
  }

  closeUserFilterPopUp() {
    this.createUserFilterPopUp = false;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
  }

  closeEditUserFilterPopUp() {
    this.isShowEditPopup = false;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
  }

  openSaveFilterPopUp() {
    this.createUserFilterPopUp = true;
    this.prepareUrlShare.emit({
      type: 'All',
      bookingId: this.bookingSearchText,
    });
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
  }

  saveMyFilter() {
    let filterName = this.myFilterName.trim();
    if (filterName) {
      this.appInsightsService.logEvent(
        AI_CommonEvents.MySavedFilterAdded.toString(),
        {
          [AI_CustomProps.FilterName]: filterName,
          [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
        }
      );
      this.mainFilterValueCheck();
      this.saveFilterJson.mainFilter.forEach((e: any) => {
        e.filterDisplayName = e.filterDisplayName.includes(':')
          ? e.filterDisplayName?.split(':')[0]
          : e.filterDisplayName;
      });
      this.isLoading.emit(true);
      this.bookingService
        .saveUserFilter(
          this.currentUserDetails.emailAddress,
          filterName,
          this.saveFilterJson,
          this.currentAccDetails.accountId,
          this.currentAccDetails.isGroup
        )
        .subscribe((res: any) => {
          if (res.statusCode == 'OC200') {
            this.isShowSaveAsBtn = false;
            this.isShowSaveBtn = false;
            this.myFilterName = '';
            this.createUserFilterPopUp = false;
            this.tempSortDiff = '';
            this.isfilterEdit = '';
            this.myEditFilterName = '';
            this.tempFilterDiff = '';
            this.onUserFilterSaved.emit({
              message: 'Filter has been saved successfully',
              type: 'save',
              filterName: filterName,
            });
            this.isLoading.emit(false);
          } else if (res.statusCode == 'OC401') {
            this.myFilterError = true;
            this.isLoading.emit(false);
            setTimeout(() => {
              this.myFilterError = false;
            }, 3000);
          }
        });
    } else {
      this.myFilterNameEmpty = true;
    }
  }

  saveAsButtonVisibility() {
    this.myEditFilterName = '';
    this.myFilterName = '';
    if (!this.isMobileViewParent && this.paramCheck) {
      let filterValue = this.paramCheck.filter_options;
      let isEdit = this.isfilterEdit?.isEditTrue || false;
      if (!this.tempSortDiff) {
        this.tempSortDiff = this.paramCheck.sort_options;
      }
      setTimeout(() => {
        if (
          JSON.stringify(this.paramCheck) !=
            JSON.stringify(this.tempFilterDiff) &&
          filterValue.length > 0
        ) {
          this.isEditFn(isEdit);
        } else if (
          JSON.stringify(this.paramCheck) == JSON.stringify(this.tempFilterDiff)
        ) {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
        } else {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          this.clearAllFilterValue.emit('saveButton');
        }
      }, 100);
    }
  }

  isEditFn(isEdit: boolean) {
    this.isShowSaveAsBtn = true;
    if (isEdit) {
      this.isShowSaveBtn = true;
    } else {
      this.isShowSaveBtn = false;
    }
  }

  clearAllFilters() {
    this.filterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.statusFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.exceptionsFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.transportFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.originList = [];
    this.offsetOrgin = 0;

    this.destinationList = [];
    this.offsetDestination = 0;

    this.selectedMainFilter = [];
    this.isShowSaveAsBtn = false;
    this.isShowSaveBtn = false;
    this.tempSortDiff = '';
    this.shipperList = [];
    this.offsetShipper = 0;

    this.mobileShipperFilterReselect = [];

    this.pickupList = [];
    this.finalDeliveryList = [];
    this.offsetPickup = 0;
    this.offsetFinalDelivery = 0;
    this.allLocationReselectedList = [
      { origin: [] },
      { destination: [] },
      { pickup: [] },
      { finalDelivery: [] },
    ];
  }

  setUpdateFilterName() {
    this.isShowEditPopup = true;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
    this.myEditFilterName = this.isfilterEdit.savedValue.filterName;
  }

  updateUserFilter() {
    this.callPrepareShareUrl();
    setTimeout(() => {
      let filterJson: any = '';
      let msgText: any = '';
      if (this.myEditFilterName != '' && this.isShowEditPopup) {
        filterJson = this.isfilterEdit.savedValue.jsonFilter;
        msgText = this.myEditFilterName;
      } else if (this.myEditFilterName == '' && !this.isShowEditPopup) {
        msgText = this.isfilterEdit.savedValue.filterName;
        this.mainFilterValueCheck();
        this.saveFilterJson.mainFilter.forEach((e: any) => {
          e.filterDisplayName = e.filterDisplayName.includes(':')
            ? e.filterDisplayName?.split(':')[0]
            : e.filterDisplayName;
        });
        filterJson = JSON.stringify(this.saveFilterJson);
      }
      if (msgText) {
        this.appInsightsService.logEvent(
          AI_CommonEvents.MySavedFilterEdited.toString(),
          {
            [AI_CustomProps.FilterName]: msgText,
            [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
          }
        );

        this.isLoading.emit(true);
        this.bookingService
          .updateUserFilter(
            this.isfilterEdit.savedValue,
            filterJson,
            msgText,
            this.currentAccDetails.accountId,
            this.currentAccDetails.isGroup
          )
          .subscribe((res: any) => {
            if (res.statusCode == 'OC200') {
              this.isShowSaveAsBtn = false;
              this.isShowSaveBtn = false;
              this.tempSortDiff = '';
              this.myEditFilterName = '';
              this.isShowEditPopup = false;
              this.isLoading.emit(false);
              this.onUserFilterSaved.emit({
                message: msgText + ' Saved filter Updated',
                type: 'update',
                filterName: msgText,
              });
              this.isfilterEdit = '';
              this.tempFilterDiff = '';
              this.tempSortDiff = '';
            } else if (res.statusCode == 'OC401') {
              this.myFilterError = true;
              this.isLoading.emit(false);
              setTimeout(() => {
                this.myFilterError = false;
              }, 3000);
            }
          });
      } else {
        this.myFilterNameEmpty = true;
      }
    }, 400);
  }

  callPrepareShareUrl() {
    setTimeout(() => {
      this.prepareUrlShare.emit({
        type: 'All',
        bookingId: this.bookingSearchText,
      });
    }, 100);
  }

  deleteUserFilter() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.MySavedFilterDeleted.toString(),
      {
        [AI_CustomProps.FilterName]: this.isfilterEdit?.savedValue?.filterName,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
    this.isLoading.emit(true);
    this.bookingService
      .deleteUserFilter(
        this.currentUserDetails.emailAddress,
        this.isfilterEdit.savedValue.id
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          this.isShowEditPopup = false;
          this.tempSortDiff = '';
          this.isLoading.emit(false);
          this.onUserFilterSaved.emit({
            message:
              this.isfilterEdit.savedValue.filterName + ' Filter Deleted',
            type: 'delete',
            filterName: this.isfilterEdit.savedValue.filterName,
          });
          this.tempUndoArray = this.isfilterEdit.savedValue;
          this.clearAllFilters();
          this.clearAllFilterValue.emit('clearAll');
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          setTimeout(() => {
            this.tempUndoArray = '';
          }, 5000);
        }
      });
  }

  undoChanges() {
    this.isLoading.emit(true);
    this.bookingService
      .saveUserFilter(
        this.currentUserDetails.emailAddress,
        this.tempUndoArray.filterName,
        this.tempUndoArray.jsonFilter,
        this.currentAccDetails.accountId,
        this.currentAccDetails.isGroup
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          this.isLoading.emit(false);
          this.onUserFilterSaved.emit({
            message: this.tempUndoArray.filterName + ' Filter Restored',
            type: 'save',
          });
          this.tempUndoArray = '';
        }
      });
  }

  selectedUserFilter(userFilterRow: any) {
    this.mobileSelectedFilter = userFilterRow.id;
    this.userFilterFetch.emit({ userFilterRow: userFilterRow, type: 'view' });
  }

  mainFilterValueCheck() {
    if (this.saveFilterJson.mainFilter.length <= 0) {
      if (Object.keys(this.saveFilterJson.statusFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 1,
          filterDisplayName: 'Status',
          type: 'statuscustom',
          index: 1,
        });
      }
      if (Object.keys(this.saveFilterJson.transportType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 0,
          filterDisplayName: 'Transport Type',
          type: 'transport',
          index: 0,
        });
      }
      if (this.saveFilterJson.shipper.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 2,
          filterDisplayName: 'Shipper',
          type: 'shipper',
          index: 2,
        });
      }

      if (
        this.saveFilterJson.allLocation.selectedAccount[0].origin.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[1].destination.length >
          0 ||
        this.saveFilterJson.allLocation.selectedAccount[2].pickup.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[3].finalDelivery
          .length > 0
      ) {
        this.saveFilterJson.mainFilter.push({
          id: 4,
          filterDisplayName: 'Location',
          type: 'location',
          index: 4,
        });
      }
      if (this.saveFilterJson.dateRange.selectedDateRange.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 5,
          filterDisplayName: 'Date Range',
          type: '',
          index: 5,
        });
      }
      if (Object.keys(this.saveFilterJson.watchFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 7,
          filterDisplayName: 'Watchlist',
          type: '',
          index: 7,
        });
      }
    }
  }
}
