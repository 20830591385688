import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserTableViewPreference } from 'src/app/common/shipment.enums';
import {
  AI_CustomProps,
  AI_CommonEvents,
  AppInsightsService,
  AI_ModulesName,
} from 'src/app/services/appinsights.service';
import { CommonUserService } from 'src/app/shared/services/common-user.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

import { ShipmentServies } from '../../services/shipment.service';
import { AdvancedFilterTabComponent } from '../advanced-filter-tab/advanced-filter-tab.component';
import { AllLocationFilterComponent } from '../all-location-filter/all-location-filter.component';
import { CommonUtils } from 'src/app/common/utils';

declare var $: any;
@Component({
  selector: 'app-shipment-custom-filters',
  templateUrl: './shipment-custom-filters.component.html',
  styleUrls: ['./shipment-custom-filters.component.scss'],
})

export class ShipmentCustomFiltersComponent implements OnInit, OnChanges {
  filterItemset = [
    { filt: 'Transport Type' },
    { filt: 'Container Type' },
    { filt: 'Status' },
    { filt: 'Shipper' },
    { filt: 'Consignee' },
    { filt: 'Controlling Customer' },
    { filt: 'Location' },
    { filt: 'Data Range' },
    { filt: 'Exception Type' },
    { filt: 'Watchlist' },
  ];
  // Sorting
  @Output() onSortSelectedHandler: any = new EventEmitter<any>();
  @Input() sortOptions: any = [];
  @Input() defaultSortSelectedValue: number = 1;
  @Input() defaultSortDirection: string = 'asc';
  defaultSortSelectedColumn: string = '';
  @Input() resentList: any;
  // Search
  @Input() shipmentSearchText: string = '';
  @Input() guidedTourFilter: boolean;
  @Input() guidedTourSort: boolean;
  @Input() urlShare = '';
  @Output() onShipmentSearchHandler: any = new EventEmitter<any>();
  @Output() selectedFilterArray = new EventEmitter<any>();
  @Output() locFilter = new EventEmitter<any>();
  @Output() selectedDateRange = new EventEmitter<any>();
  @Output() downloadExcel = new EventEmitter<any>();
  @Output() selectedMainFilterArray = new EventEmitter<any>();
  @Output() checkDefultFilter = new EventEmitter<boolean>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Output() selectedUrlFilters = new EventEmitter<any>();
  @Output() clearSearchFilter = new EventEmitter<any>();
  @Output() clearAllFilterValue = new EventEmitter<any>();
  @Output() onUserFilterSaved = new EventEmitter<any>();
  @Output() onSystemFilterSaved = new EventEmitter<any>();
  @Output() showAndAutoClearToasterMessage = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<any>();
  @Output() userFilterFetch = new EventEmitter<any>();
  @Output() clearMysavedFilter = new EventEmitter<any>();
  @Input() resultCount: any;
  @Input() paramCheck: any;
  @Input() saveFilterJson: any;
  @Input() savedFilters: any;
  @Input() selectedDateRangeRender: any;
  @ViewChild(AllLocationFilterComponent)
  allLocation!: AllLocationFilterComponent;
  isProgressLocation: boolean = false;
  allLocationReselectedList: any[] = [];
  selectedMainFilter: any[] = [];
  offsetOrgin: any = 0;
  offsetDestination: any = 0;
  offsetConsignee: any = 0;
  offsetControlingCustomer: any = 0;
  offsetShipper: any = 0;
  offsetPickup: any = 0;
  offsetFinalDelivery: any = 0;
  offsetOriginCountry: any = 0;
  offsetDestinationCountry: any = 0;
  isFilterRange: boolean = false;
  activeRangeSelection: any[] = [];
  popupToggle = false;
  originList: any[] = [];
  destinationList: any[] = [];
  textfilterRange: boolean = false;
  copyText: any = '';
  watchlistFilter = 'Watchlist';
  resentListOrigin: any = [];
  resentListDestination: any = [];
  resentListConsignee: any = [];
  resentListControlingCustomer: any = [];
  resentListShipper: any = [];
  resentListPickUp: any = [];
  resentListFinalDelivery: any = [];
  resentAllLocationList: any = [];
  clickEvent = new Subject();
  mobileFilterContainer: boolean = false;
  public checkWatchlist: boolean;
  dateRangeFilterType: any;
  isSelectedRangeValue: any;
  mobileStatusFilter: any = [];
  mobileExceptionFilter: any = [];
  mobileDateRangeFilter: any = [];
  mobileTransportFilter: any = [];
  mobileOriginFilter: any = [];
  mobileDestinationtFilter: any = [];
  mobileWatchListFilter: any = [];
  mobileShipperFilter: any = [];
  mobileConsigneeFilter: any = [];
  mobileControllingFilter: any = [];
  mobileAllLocationFilter: any = [];
  mobileLocationFilterReselect: any = [];
  mobileOrginFilterReselect: any = [];
  mobileDestinationFilterReselect: any = [];
  mobilePickupFilterReselect = [];
  mobileFinalDeliveryFilterReselect = [];
  mobileDestinationCountryFilterReselect = [];
  mobileOriginCountryFilterReselect = [];
  mobileConsigneeFilterReselect: any = [];
  mobileControlingCustomerReselect: any = [];
  mobileShipperFilterReselect: any = [];
  currentAccDetails = this.accountSessionData.getCurrentAccountData();
  currentUserDetails = this.accountSessionData.getCurrentUserData();
  isCustomFilterSecHide: boolean;
  isCustomFilterSecShow: boolean;
  isFilterHide: boolean;
  isFilterShow: boolean;
  isSearchBoxHide: boolean;
  isSearchBoxShow: boolean;
  isSortShow: boolean;
  isSortHide: boolean;
  isShareShow: boolean;
  isShareHide: boolean;
  @Input() tempFilterDiff: any;
  @Input() isfilterEdit: any;
  tempSortDiff: any;
  isShowEditPopup = false;
  createUserFilterPopUp: boolean = false;
  myFilterName: any = '';
  myEditFilterName: any = '';
  myFilterError: boolean = false;
  myFilterNameEmpty: boolean = false;
  isShowSaveAsBtn: boolean = false;
  isShowSaveBtn: boolean = false;
  mobileSelectedFilter: any = '';
  tempUndoArray: any;
  shipperList: any[] = [];
  consigneeList: any[] = [];
  controllingCustomerList: any[] = [];
  pickupList: any[] = [];
  allLocationList: any[] = [];
  finalDeliveryList: any[] = [];
  accountID: any;
  orginEmail: any;
  isGroup: any;
  statusFilterValue: boolean = false;
  exceptionFilterValue: boolean = false;
  transportFilterValue: boolean = false;
  favoritesFilterValue: boolean = false;
  shipperFilterValue: boolean = false;
  consigneeFilterValue: boolean = false;
  controlingCustomerFilterValue: boolean = false;
  locationFilterValue: boolean = false;
  dateFilterValue: boolean = false;
  fetchMobileRecords = 30;
  filterSystemDefined = false;
  public form: FormGroup;
  currentUserRole = '';
  resentListOriginCountry: any = [];
  resentListDestinationCountry: any = [];
  @ViewChild('auto') auto: any;
  @Output() selectedDefualtUrlFilters = new EventEmitter<any>();
  showCancelPopup = true;
  showIsSysDefinedPopup = true;
  showIsUserRemovePopup = true;
  showIsDeletePopup = true;
  showModeDropDown: boolean = false;
  showSortDropDown: boolean = false;
  showShareDropDown: boolean = false;
  showWatchList: boolean = false;
  showPickupAndDelivery: boolean = false;
  showDownloadDropDown: boolean = false;
  showClearAllButton: boolean = false;
  displayModeTooltipText: string = '';
  displaySortTooltipText: string = '';
  filterModeRibbonValue: any = [];
  filterModeRibbonText: any = [];
  isTransportFilterOpen: boolean = false;
  showAdvancedFilterCancelModal: boolean = false;
  showAdvancedFilterPopUp: boolean = false;
  isAdvanceFilterSelected: boolean = false;
  advFiltername: any = "";
  showAdvSaveMessage: boolean = false;
  isFilterFromGlobalSearch: boolean = false;
  allLocationFilterResults: any = [];
  mode = {
    type: 'transport',
    selectedValue: [{ column_name: 'transport', operator: '=', value: 0 }],
    clearFrom: '',
  };
  @Output() isTableViewSelected = new EventEmitter<any>();
  @Output() pickUpAndDeliveryToggle = new EventEmitter<boolean>();
  @Output() callSwitchFilterWarningPopup = new EventEmitter<any>();
  advancedFilterAdded: any = [];
  isTableView: boolean = false;
  @Input() tableViewColumnHeaderList: any;
  @ViewChild('customDropdownBtn') customDropdownButton: ElementRef;
  filterItem: any[] = [
    {
      filt: 'Transport Type',
      val: 0,
      col_name: 'transport',
      checked: false,
    },
    {
      filt: 'Container Type',
      val: 9,
      col_name: 'containertype',
      checked: false,
    },
    { filt: 'Status', val: 1, col_name: 'statuscustom', checked: false },
    { filt: 'Shipper', val: 2, col_name: 'shipper', checked: false },
    { filt: 'Consignee', val: 3, col_name: 'consignee', checked: false },
    {
      filt: 'Controlling Customer',
      val: 8,
      col_name: 'controllingcustomer',
      checked: false,
    },
    { filt: 'Location', val: 4, col_name: 'allLocation', checked: false },
    { filt: 'Date Range', val: 5, col_name: '', checked: false },
    {
      filt: 'Exception Type',
      val: 6,
      col_name: 'exception',
      checked: false,
    },
    { filt: 'Watchlist', val: 7, col_name: '', checked: false },
  ];

  statusFilterItem: any[] = [
    { filt: 'Pickup Scheduled', val: 1, checked: false },
    { filt: 'Picked Up', val: 2, checked: false },
    { filt: 'Scheduled', val: 3, checked: false },
    { filt: 'In Transit', val: 4, checked: false },
    { filt: 'Arrived', val: 5, checked: false },
    { filt: 'Delivery Scheduled', val: 6, checked: false },
    { filt: 'Delivered', val: 7, checked: false },
  ];

  transportFilterItem: any[] = [
    { filt: 'Sea', val: 1, checked: false },
    { filt: 'Air', val: 2, checked: false },
    { filt: 'Road', val: 3, checked: false },
    { filt: 'Rail', val: 4, checked: false },
    { filt: 'Courier', val: 5, checked: false },
  ];

  containerFilterItem: any[] = [
    { filt: 'FCL', val: 'fcl', checked: false },
    { filt: 'LCL', val: 'lcl', checked: false },
    { filt: 'BCN', val: 'bcn', checked: false },
    { filt: 'LSE', val: 'lse', checked: false },
    { filt: 'FTL', val: 'ftl', checked: false },
    { filt: 'LTL', val: 'ltl', checked: false },
    { filt: 'Other', val: 'other', checked: false },
  ];

  shipmentFilterRange: any[] = [
    { status: 'Est Pickup', val: 0, checked: false },
    { status: 'Pickup', val: 1, checked: false },
    { status: 'ETD', val: 2, checked: false },
    { status: 'ATD', val: 3, checked: false },
    { status: 'ETA', val: 4, checked: false },
    { status: 'ATA', val: 5, checked: false },
    { status: 'Est Delivery', val: 6, checked: false },
    { status: 'Delivery', val: 7, checked: false },
  ];

  exceptionsFilterItem: any[] = [
    { filt: 'No Exceptions', val: 1, checked: false },
    { filt: 'Expected Delay', val: 2, checked: false },
    { filt: 'Delayed In Transit', val: 4, checked: false },
    { filt: 'Delivered Late', val: 3, checked: false },
  ];

  scrHeight: any;
  scrWidth: any;
  isMobileViewParent = false;
  clickSortOption = new Subject();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 1200 ? true : false;
    this.clearMobileFilters();
  }

  constructor(
    private shipmentService: ShipmentServies,
    private accountSessionData: CurrentSessionDataService,
    private dataSharingService: DataSharingService,
    private appInsightsService: AppInsightsService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private commonUserService: CommonUserService
  ) {
    this.accountID = this.currentAccDetails.accountId;
    this.orginEmail = this.currentUserDetails.emailAddress;
    this.currentUserRole = this.currentUserDetails.userType;
    this.isGroup = this.currentAccDetails.isGroup;
    this.getUserViewPreference();
    this.dataSharingService.isGuidedTourCustomFilterCompHide.subscribe(
      (value) => {
        this.isCustomFilterSecHide = value;
      }
    );
    this.dataSharingService.isGuidedTourCustomFilterCompShow.subscribe(
      (value) => {
        this.isCustomFilterSecShow = value;
      }
    );
    this.dataSharingService.isGuidedTourCustomFilterHide.subscribe((value) => {
      this.isFilterHide = value;
    });
    this.dataSharingService.isGuidedTourCustomFilterShow.subscribe((value) => {
      this.isFilterShow = value;
    });
    this.dataSharingService.isGuidedTourShareShipmentHide.subscribe((value) => {
      this.isShareHide = value;
    });
    this.dataSharingService.isGuidedTourShareShipmentShow.subscribe((value) => {
      this.isShareShow = value;
    });
    this.dataSharingService.isGuidedTourSortHide.subscribe((value) => {
      this.isSortHide = value;
    });
    this.dataSharingService.isGuidedTourSortShow.subscribe((value) => {
      this.isSortShow = value;
    });
    this.dataSharingService.isGuidedTourSearchTextBoxHide.subscribe((value) => {
      this.isSearchBoxHide = value;
    });
    this.dataSharingService.isGuidedTourSearchTextBoxShow.subscribe((value) => {
      this.isSearchBoxShow = value;
    });

    this.dataSharingService.clearShipmentSearchTextBox.subscribe(
      (res: boolean) => {
        if (res) {
          this.shipmentSearchText = '';
          this.clearAllRibbonFilters(false)
        }
      }
    );
  }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  ngOnChanges() {
    if (this.paramCheck) {
      this.highlightSelectedFilter();
    }
    if (this.resentList.type == 'origin') {
      this.resentListOrigin = this.resentList.list;
    } else if (this.resentList.type == 'destination') {
      this.resentListDestination = this.resentList.list;
    }
    this.initialRecentLocation();
    this.defaultSortSelectedColumn =
      this.sortOptions[this.defaultSortSelectedValue - 1]?.name;
    setTimeout(() => {
      if (this.isfilterEdit.isEditTrue) {
        this.form?.valueChanges?.subscribe(() => {
          this.saveButtonVisible = false;
          this.saveButtonDisabled();
        });
      }
    }, 300);
  }

  loadInitialPageData() {
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768 ? true : false;
    this.clearMobileFilters();
    this.isSelectedRangeValue = 'ETD';
    this.clickEvent.pipe(debounceTime(900)).subscribe((e) => {
      this.onSearchHandler(e);
    });
    $(document).ready(function () {
      $('.restrict-close').on('click', function (e: any) {
        e.stopPropagation();
      });
    });

    if (this.isMobileViewParent) {
      this.fetchMobileRecords = 10;
    }

    $('.button').on('click', function (e: any) {
      if (e) {
        $('.filterRange-wrap').hide();
        $('.accountStatusFilterDropdown').hide();
      } else {
        $('.filterRange-wrap').show();
        $('.accountStatusFilterDropdown').show();
      }
    });
    this.defaultSortSelectedColumn =
      this.sortOptions[this.defaultSortSelectedValue - 1]?.name;

    this.clickSortOption.pipe(debounceTime(800)).subscribe((e: any) => {
      this.displaySortTooltipText = e.name;
      this.onSortHandler(e);
    });
  }

  initialRecentLocation() {
    setTimeout(() => {
      let recentConsignee = sessionStorage.getItem('resentConsignee');
      let recentControlling = sessionStorage.getItem('resentControlling');
      let recentShipper = sessionStorage.getItem('resentShipper');
      let recentOrigin = sessionStorage.getItem('resentOrgin');
      let recentDestination = sessionStorage.getItem('resentDestination');
      let recentPickUp = sessionStorage.getItem('recentPickup');
      let recentFinalDelivery = sessionStorage.getItem('recentFinalDelivery');
      let recentOriginCountry = sessionStorage.getItem('recentOriginCountry');
      let recentDestinationCountry = sessionStorage.getItem(
        'recentDestinationCountry'
      );
      this.resentListShipper =
        recentShipper != null ? JSON.parse(recentShipper) : [];
      this.resentListConsignee =
        recentConsignee != null ? JSON.parse(recentConsignee) : [];
      this.resentListControlingCustomer =
        recentControlling != null ? JSON.parse(recentControlling) : [];
      this.resentListOrigin =
        recentOrigin != null ? JSON.parse(recentOrigin) : [];
      this.resentListDestination =
        recentDestination != null ? JSON.parse(recentDestination) : [];
      this.resentListPickUp =
        recentPickUp != null ? JSON.parse(recentPickUp) : [];
      this.resentListFinalDelivery =
        recentFinalDelivery != null ? JSON.parse(recentFinalDelivery) : [];
      this.resentListOriginCountry =
        recentOriginCountry != null ? JSON.parse(recentOriginCountry) : [];
      this.resentListDestinationCountry =
        recentDestinationCountry != null
          ? JSON.parse(recentDestinationCountry)
          : [];
      setTimeout(() => {
        this.resentAllLocationList = [
          { recentOriginList: this.resentListOrigin },
          { recentDestinationList: this.resentListDestination },
          { recentPickupList: this.resentListPickUp },
          { recentFinalDeliveryList: this.resentListFinalDelivery },
          { recentOriginCountryList: this.resentListOriginCountry },
          { recentDestinationCountryList: this.resentListDestinationCountry },
        ];
      }, 300);
    }, 1000);
  }

  isFilterSelected(
    status: any,
    filterValue: any,
    filterDisplayName: any,
    filterText: any,
    index: any
  ) {
    if (status) {
      this.appInsightsService.logEvent(filterDisplayName + ' Filter Applied');
      this.showClearAllButton = true;
      switch (filterValue) {
        case 0:
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.transportFilterItem,
            true
          );
          break;
        case 9:
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.containerFilterItem,
            true
          );
          break;
        case 1:
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.statusFilterItem,
            true
          );
          break;
        case 2:
          this.getShipperList('');
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.shipperList,
            true
          );
          break;
        case 3:
          this.getConsigneeList('');
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.consigneeList,
            true
          );

          break;
        case 4:
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          this.getPickUpList('');
          $('#watchListId').click();
          break;
        case 5:
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          $('#watchListId').click();
          break;
        case 6:
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.exceptionsFilterItem,
            true
          );
          break;

        case 7:
          this.appInsightsService.logEvent(
            AI_CommonEvents.WatchListFilterApplied.toString(),
            {
              [AI_CustomProps.IsWatchList]: 'true',
              [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
            }
          );
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            [],
            true
          );
          $('#watchListId').click();
          this.selectedFilterArray.emit({
            type: 'favorites',
            selectedValue: [
              { column_name: 'favorites', operater: '=', value: true },
            ],
            clearFrom: '',
          });
          setTimeout(() => {
            this.saveAsButtonVisibility();
          }, 300);
          this.openOrCloseWatchListRibbonFilter({ type: 'favorites' });
          break;
        case 8:
          this.getControlingCustomer('');
          this.setShipmentMainFilterArray(
            filterValue,
            filterDisplayName,
            filterText,
            index,
            this.controllingCustomerList,
            true
          );

          break;
      }
    } else {
      let filterCloseData = {
        type: filterValue == 7 ? 'favorites' : filterText,
        index: index,
        id: filterValue,
        eventType: '',
      };
      this.showClearAllButton = false;
      this.closeCustomFilters(filterCloseData);
    }
    //this.shipmentService.touchedCustomDropdown = false;
    this.selectedMainFilterArray.emit(this.selectedMainFilter);
  }

  clearAllFilter() {
    this.filterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.statusFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.transportFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.exceptionsFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.containerFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.selectedMainFilter = [];
    this.mobileConsigneeFilterReselect = [];
    this.mobileControlingCustomerReselect = [];
    this.mobileShipperFilterReselect = [];
    this.selectedDateRangeRender = [];
    this.checkWatchlist = false;
    this.mobileSelectedFilter = '';
    this.clearAllFilterValue.emit('clearAll');
    this.mobileAllLocationFilter.filterArray = [];
    this.mobileOrginFilterReselect = [];
    this.mobileDestinationFilterReselect = [];
    this.mobilePickupFilterReselect = [];
    this.mobileFinalDeliveryFilterReselect = [];
    this.mobileOriginCountryFilterReselect = [];
    this.mobileDestinationCountryFilterReselect = [];

    this.allLocationReselectedList = [
      { origin: this.mobileOrginFilterReselect },
      { destination: this.mobileDestinationFilterReselect },
      { pickup: this.mobilePickupFilterReselect },
      { finalDelivery: this.mobileFinalDeliveryFilterReselect },
      { origincountry: this.mobileOriginCountryFilterReselect },
      { destinationcounty: this.mobileDestinationCountryFilterReselect },
    ];
  }

  setShipmentMainFilterArray(
    id: any,
    filterDisplayName: any,
    type: any,
    index: any,
    filterArray: any,
    autoOpen: boolean
  ) {
    let currentFilterData = {
      id,
      filterDisplayName,
      type,
      index,
      filterArray,
      autoOpen,
    };
    if (this.checkFilterPresent(id) === -1) {
      this.selectedMainFilter.push(currentFilterData);
    }
    if (this.isMobileViewParent) {
      this.setMobileFilterData(id, currentFilterData);
    }
  }

  setMobileFilterData(id: any, currentFilterData: any) {
    currentFilterData.autoOpen = false;
    switch (id) {
      case 0:
        this.mobileTransportFilter = currentFilterData;
        break;
      case 1:
        this.mobileStatusFilter = currentFilterData;
        break;
      case 2:
        this.mobileShipperFilter = currentFilterData;
        if (typeof currentFilterData.filterDisplayName === 'object') {
          this.mobileShipperFilterReselect =
            currentFilterData.filterDisplayName;
        }
        break;
      case 3:
        this.mobileConsigneeFilter = currentFilterData;
        if (typeof currentFilterData.filterDisplayName === 'object') {
          this.mobileConsigneeFilterReselect =
            currentFilterData.filterDisplayName;
        }
        break;
      case 4:
        this.mobileAllLocationFilter = currentFilterData;
        break;
      case 5:
        this.mobileDateRangeFilter = currentFilterData;
        break;
      case 6:
        this.mobileExceptionFilter = currentFilterData;
        break;
      case 7:
        this.mobileWatchListFilter = currentFilterData;
        break;
      case 8:
        this.mobileControllingFilter = currentFilterData;
        if (typeof currentFilterData.filterDisplayName === 'object') {
          this.mobileControlingCustomerReselect =
            currentFilterData.filterDisplayName;
        }
        break;
    }
  }

  checkFilterPresent(filterValue: any) {
    const selectedMainFilter = this.selectedMainFilter;
    return selectedMainFilter
      .map(function (x: any) {
        return x.id;
      })
      .indexOf(parseInt(filterValue));
  }

  loadAdvanceFilterData(filterURl: any) {
    this.advancedFilterAdded = filterURl?.advancedFilter
      ? filterURl?.advancedFilter
      : [];
    if (this.advancedFilterAdded.length > 0) {
      this.showClearAllButton = true;
    }
  }

  loadURLFilters(filterURl: any) {
    this.shipmentService.recentFilterUrl = filterURl;
    if (this.determineIsAdvancedFilterApplied(filterURl?.advancedFilter)) {
      return;  // this.shipmentService.backupLastFilterUrl=filterURl; to do- navigator
    }
    this.showClearAllButton = false;
    if (filterURl?.transportType?.length > 0) {
      this.openOrCloseModeRibbonFilter({
        type: "transport", selectedValue: filterURl.transportType,
        clearFrom: ""
      });
    }
    else {
      this.filterModeRibbonValue = [];
      this.filterModeRibbonText = [];
      this.displayModeTooltipText = '';
    }
    if (filterURl?.watchFilter?.length > 0) {
      this.openOrCloseWatchListRibbonFilter({
        type: "favorites", selectedValue: filterURl.watchFilter,
        clearFrom: ""
      });
    }
    else {
      this.showWatchList = false;
    }
    this.loadAdvanceFilterData(filterURl);
    let filterObj = {
      advancedFilter: filterURl.advancedFilter,
      status: filterURl.statusFilter,
      container: filterURl.containerType ? filterURl.containerType : {},
      transport: filterURl.transportType,
      exception: filterURl.exceptionType,
      origin: filterURl.allLocation?.selectedValue[0]?.originValue,
      destination: filterURl.allLocation?.selectedValue[1]?.destinationValue,
      origincountry: filterURl.allLocation?.selectedValue[4]?.originCountryValue
        ? filterURl.allLocation?.selectedValue[4]?.originCountryValue
        : [],
      destinationcountry: filterURl.allLocation?.selectedValue[5]
        ?.destinationCountryValue
        ? filterURl.allLocation?.selectedValue[5]?.destinationCountryValue
        : [],
      dateRange: filterURl.dateRange,
      shipper: filterURl.shipper,
      consignee: filterURl.consignee,
      controllingCustomer: filterURl.controllingCustomer
        ? filterURl.controllingCustomer
        : {
          selectedValue: [],
          selectedAccount: [],
        },
      pickUp: filterURl.allLocation?.selectedValue[2]?.pickupValue,
      finalDelivery:
        filterURl.allLocation?.selectedValue[3]?.finalDeliveryValue,
      allLocation: filterURl.allLocation,
    };
    this.setShareFilters(filterURl, filterObj);
    if (filterURl.type != 'defaultFilter') {
      this.selectedUrlFilters.emit({
        advancedFilter: (JSON.stringify(filterObj?.advancedFilter) == "{}")
          || (JSON.stringify(filterObj?.advancedFilter) == "[]") ||
          (filterObj?.advancedFilter == undefined) ? [] : filterObj?.advancedFilter,
        status: filterObj.status,
        exception: filterObj.exception,
        transport: filterObj.transport,
        container: filterObj.container,
        origin: filterObj.origin,
        originCountry: filterObj.origincountry,
        destination: filterObj.destination,
        destinationCountry: filterObj.destinationcountry,
        dateRange: filterObj.dateRange.selectedValue,
        mainfilter: filterURl.mainFilter,
        watchFilter: filterURl.watchFilter,
        shipmentId: filterURl.shipmentId,
        consignee: filterObj.consignee.selectedValue,
        controllingCustomer: filterObj.controllingCustomer?.selectedValue,
        shipper: filterObj.shipper.selectedValue,
        pickUp: filterObj.pickUp,
        finalDelivery: filterObj.finalDelivery,
        shipperAccount: filterObj.shipper.selectedAccount,
        consigneeAccount: filterObj.consignee.selectedAccount,
        controllingCustomerAccount:
          filterObj.controllingCustomer?.selectedAccount,
        originAccount: filterURl.allLocation?.selectedAccount[0]?.origin,
        originCountryAccount: filterURl.allLocation?.selectedAccount[4]
          ?.originCountry
          ? filterURl.allLocation?.selectedAccount[4]?.originCountry
          : [],
        destinationAccount:
          filterURl.allLocation?.selectedAccount[1]?.destination,
        destinationCountryAccount: filterURl.allLocation?.selectedAccount[5]
          ?.destinationCountry
          ? filterURl.allLocation?.selectedAccount[5]?.destinationCountry
          : [],
        pickUpAccount: filterURl.allLocation?.selectedAccount[2]?.pickup,
        finalDeliveryAccount:
          filterURl.allLocation?.selectedAccount[3]?.finalDelivery,
      });
    } else if (filterURl.type === 'defaultFilter') {
      this.selectedDefualtUrlFilters.emit({
        mainfilter: filterURl.mainFilter,
      });
    }
  }

  setShareFilters(filterURl: any, filterObj: any) {
    filterURl?.mainFilter?.forEach((element: any) => {
      this.filterItem.forEach((e: any) => {
        if (e.val == element.id) {
          e.checked = true;
        }
      });
      if (element.id == '0') {
        this.ApplyElementCase2(filterObj.transport, element);
      } else if (element.id == '1') {
        this.ApplyElementCase0(filterObj.status, element);
      } else if (element.id == '9') {
        this.ApplyElementCase3(filterObj.container, element);
      } else if (element.id == '2') {
        this.getShipperList('');
        this.mapFilterData(
          element,
          '',
          filterURl.shipper.selectedAccount,
          this.shipperList
        );
      } else if (element.id == '3') {
        this.getConsigneeList('');
        this.mapFilterData(
          element,
          '',
          filterURl.consignee.selectedAccount,
          this.consigneeList
        );
      } else if (element.id == '4') {
        this.mapFilterData(
          element,
          '',
          '',
          filterURl.allLocation.selectedAccount
        );
      } else if (element.id == '5') {
        this.mapFilterData(
          element,
          '',
          '',
          filterURl.dateRange.selectedDateRange
        );
      } else if (element.id == '6') {
        this.ApplyElementCase1(filterObj.exception, element);
      } else if (element.id == '7') {
        this.mapFilterData(element, 'Watchlist', '', filterURl.watchFilter);
      } else if (element.id == '8') {
        this.getControlingCustomer('');
        this.mapFilterData(
          element,
          '',
          filterURl.controllingCustomer.selectedAccount,
          this.controllingCustomerList
        );
      }
    });
  }

  offsetLocation(event: any) {
    if (event.type == 'Shipper') {
      this.offsetShipper = event.offset;
      this.getShipperList(event.value);
    } else if (event.type == 'Consignee') {
      this.offsetConsignee = event.offset;
      this.getConsigneeList(event.value);
    } else if (event.type == 'Controlling Customer') {
      this.offsetControlingCustomer = event.offset;
      this.getControlingCustomer(event.value);
    } else if (event.type == 'origin') {
      this.offsetOrgin = event.offset;
      this.getOriginList(event.value);
    } else if (event.type == 'destination') {
      this.offsetDestination = event.offset;
      this.getDestinationList(event.value);
    } else if (event.type == 'pickup') {
      this.offsetPickup = event.offset;
      this.getPickUpList(event.value);
    } else if (event.type == 'finalDelivery') {
      this.offsetFinalDelivery = event.offset;
      this.getFinalDeliveryList(event.value);
    } else if (event.type == 'origincountry') {
      this.offsetOriginCountry = event.offset;
      this.getOriginCountryList(event.value);
    } else if (event.type == 'destinationcounty') {
      this.offsetDestinationCountry = event.offset;
      this.getDestinationCountryList(event.value);
    }
  }

  trimDisplayStringCustomFilter(filterArray: any, filterDisplayName: any) {
    let displayText = '';
    filterArray
      .filter((t: any) => t.checked)
      .map((t: any) => (displayText += t.filt + ', '));
    if (displayText.trim().length > 5) {
      displayText = displayText.replace(/,\s*$/, '');
    }
    displayText = displayText?.trim();
    displayText = displayText.replace(/,\s*$/, '');
    if (displayText?.length > 5) {
      displayText =
        filterDisplayName + ' : ' + displayText?.substring(0, 5) + '...';
    } else {
      displayText = filterDisplayName + ' : ' + displayText;
    }

    return displayText;
  }

  closeWatchFilter(close?: boolean) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.WatchListFilterCleared.toString(),
      {
        [AI_CustomProps.IsWatchList]: 'false',
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );

    let event = {
      type: 'favorites',
      id: '7',
      eventType: 'closeBtn',
      index: 9,
      closeFrom: '',
    };

    this.closeCustomFilters(event, close);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  mapFilterData(
    element: any,
    displayText: any,
    selectedAcc: any,
    filterArray: any
  ) {
    let mapData = {
      id: element.id,
      filterDisplayName: displayText ? displayText : element.filterDisplayName,
      selectedAccount: selectedAcc,
      type: element.type,
      index: element.index,
      filterArray: filterArray,
    };

    if (this.isFilterFromGlobalSearch) {
      this.removeRedudantFilter();
    }
    this.selectedMainFilter.push(mapData);
    this.setMobileFilterData(element.id, mapData);
  }

  removeRedudantFilter() {
    this.selectedMainFilter = [];
    this.filterItem?.forEach((e: any) => {
      if (e.val != 1) { // e.val => except selected status custom is selected true, though the call comes from global search filter selection
        e.checked = false;
      }
    });
    this.isFilterFromGlobalSearch = false;
  }

  removeAllGlobalFilter() {
    this.selectedMainFilter = [];
    this.filterItem?.forEach((e: any) => {
      e.checked = false;
    });
  }

  closeCustomFilters(event: any, close?: boolean) {
    this.showPickupAndDelivery = false
    this.openOrCloseModeRibbonFilter(event, true);
    this.openOrCloseWatchListRibbonFilter(event, true);
    if (event.id == '0') {
      this.transportFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '1') {
      this.statusFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '9') {
      this.containerFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '6') {
      this.exceptionsFilterItem.forEach((ele) => {
        ele.checked = false;
      });
    } else if (event.id == '2') {
      this.shipperList = [];
      this.offsetShipper = 0;
    } else if (event.id == '3') {
      this.consigneeList = [];
      this.offsetConsignee = 0;
    } else if (event.id == '8') {
      this.controllingCustomerList = [];
      this.offsetControlingCustomer = 0;
    } else if (event.id == '4') {
      this.allLocationList = [];
      this.offsetOrgin = 0;
      this.offsetPickup = 0;
      this.offsetDestination = 0;
      this.offsetFinalDelivery = 0;
      this.offsetOriginCountry = 0;
      this.offsetDestinationCountry = 0;
    }
    if (event.closeFrom == 'defaultFilter') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: 'defaultFilter',
        account: [],
      });
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    } else if (event.closeFrom == 'quickSearch') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: 'defaultFilter',
        account: [],
      });
      this.isShowSaveAsBtn = false;
      this.isShowSaveBtn = false;
    } else if (event.closeFrom !== 'clearFilterOnly') {
      this.selectedFilterArray.emit({
        type: event.type,
        selectedValue: [],
        clearFrom: '',
        account: [],
      });
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    }
    let elementPos = this.selectedMainFilter
      .map(function (x: any) {
        return x.index;
      })
      .indexOf(event.index);
    if (elementPos > -1) {
      this.selectedMainFilter.splice(elementPos, 1);
    }
    if (event.eventType == 'closeBtn') {
      this.filterItem.forEach((e: any) => {
        if (e.val == event.id) {
          e.checked = false;
        }
      });
    }
    if (this.selectedMainFilter.length < 1) {
      this.isShowSaveAsBtn = false;
      this.isShowSaveBtn = false;
      this.showClearAllButton = false;
      this.isAdvanceFilterSelected = false;
      this.advancedFilterTabComponent.resetToDefaultState();
      this.clearMysavedFilter.next(true);
    }
    this.selectedMainFilterArray.emit(this.selectedMainFilter);
    if (event.closeFrom != 'quickSearch') {
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    }
    if (close) {
      this.checkDefultFilter.emit(true);
    }
  }

  locationSelectedFilter(event: any) {
    this.mobileLocationFilterReselect = event;
    if (this.mobileLocationFilterReselect.type == 'Origin') {
      this.mobileOrginFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Destination') {
      this.mobileDestinationFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Pickup') {
      this.mobilePickupFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Final Delivery') {
      this.mobileFinalDeliveryFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'Origin Country') {
      this.mobileOriginCountryFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (
      this.mobileLocationFilterReselect.type == 'Destination Country'
    ) {
      this.mobileDestinationCountryFilterReselect =
        this.mobileLocationFilterReselect.account;
    }

    this.locFilter.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  highlightSelectedFilter() {
    let filterOptions = this.paramCheck.filter_options;
    this.setAllMobFilterFalse();
    if (filterOptions.length < 1) {
      this.setAllMobFilterFalse();
    } else {
      filterOptions.forEach((value: any) => {
        switch (value.column_name) {
          case 'statuscustom':
            this.statusFilterValue = true;
            break;
          case 'transport':
            this.transportFilterValue = true;
            break;
          case 'exception':
            this.exceptionFilterValue = true;
            break;
          case 'favorites':
            this.favoritesFilterValue = true;
            break;
          case 'shipper':
            this.shipperFilterValue = true;
            break;
          case 'consignee':
            this.consigneeFilterValue = true;
            break;
          case 'controllingcustomer':
            this.controlingCustomerFilterValue = true;
            break;
          case 'pickuplocation':
          case 'delivery':
          case 'origin':
          case 'destination':
            this.locationFilterValue = true;
            break;
          case 'estimatepickup':
          case 'pickup':
          case 'etd':
          case 'atd':
          case 'eta':
          case 'ata':
          case 'estimatedelivery':
          case 'deliveryactual':
            this.dateFilterValue = true;
            break;
          default:
            break;
        }
      });
    }
  }

  setAllMobFilterFalse() {
    this.statusFilterValue = false;
    this.transportFilterValue = false;
    this.exceptionFilterValue = false;
    this.favoritesFilterValue = false;
    this.shipperFilterValue = false;
    this.consigneeFilterValue = false;
    this.controlingCustomerFilterValue = false;
    this.locationFilterValue = false;
    this.dateFilterValue = false;
  }

  getSelectedFilterArray(event: any) {
    this.selectedFilterArray.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
    this.openOrCloseModeRibbonFilter(event);

  }

  onSearchHandler(event: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.SeachData.toString(), {
      [AI_CustomProps.SearchText]: this.shipmentSearchText.trim(),
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
    });
    this.onShipmentSearchHandler.emit({
      searchText: this.shipmentSearchText.trim(),
      event,
    });
  }

  clearSearchText(event: any) {
    if (!event) {
      this.clearSearchFilter.emit(event);
      this.shipmentSearchText = '';
    }
  }

  onSortHandler(sortOption: any) {
    if (sortOption.id === this.defaultSortSelectedValue) {
      this.defaultSortDirection =
        this.defaultSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.defaultSortDirection = 'asc';
    }

    this.appInsightsService.logEvent(AI_CommonEvents.SortData.toString(), {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      [AI_CustomProps.SortBy]: sortOption.name,
      [AI_CustomProps.SortOrder]:
        this.defaultSortDirection == 'asc'
          ? 'Ascending Order'
          : 'Descending Order',
    });

    sortOption.sort_direction = this.defaultSortDirection;
    this.defaultSortSelectedColumn = sortOption.name;
    this.onSortSelectedHandler.emit(sortOption);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  selectDateRange(filter: any) {
    this.isSelectedRangeValue = filter;
  }

  clearFilterSearch(e: any) {
    if (e.type == 'Shipper') {
      this.shipperList = [];
      this.offsetShipper = 0;
    } else if (e.type == 'Consignee') {
      this.consigneeList = [];
      this.offsetConsignee = 0;
    } else if (e.type == 'Controlling Customer') {
      this.controllingCustomerList = [];
      this.offsetControlingCustomer = 0;
    } else if (e.type == 'Pickup') {
      this.allLocationList = [];
      this.offsetPickup = 0;
    } else if (e.type == 'Origin') {
      this.allLocationList = [];
      this.offsetOrgin = 0;
    } else if (e.type == 'Destination') {
      this.allLocationList = [];
      this.offsetDestination = 0;
    } else if (e.type == 'Final Delivery') {
      this.allLocationList = [];
      this.offsetFinalDelivery = 0;
    } else if (e.type == 'Origin Country') {
      this.allLocationList = [];
      this.offsetOriginCountry = 0;
    } else if (e.type == 'Destination Country') {
      this.allLocationList = [];
      this.offsetDestinationCountry = 0;
    }
    this.getLocationList(e);
  }

  getLocationList(event: any) {
    if (event.type == 'shipper') {
      this.getShipperList(event.value);
    } else if (event.type == 'consignee') {
      this.getConsigneeList(event.value);
    } else if (event.type == 'controllingcustomer') {
      this.getControlingCustomer(event.value);
    } else if (event.type == 'Pickup') {
      this.getPickUpList(event.value);
      this.offsetPickup = 0;
    } else if (event.type == 'Origin') {
      this.getOriginList(event.value);
      this.offsetOrgin = 0;
    } else if (event.type == 'Destination') {
      this.getDestinationList(event.value);
      this.offsetDestination = 0;
    } else if (event.type == 'Final Delivery') {
      this.getFinalDeliveryList(event.value);
      this.offsetFinalDelivery = 0;
    } else if (event.type == 'Origin Country') {
      this.getOriginCountryList(event.value);
      this.offsetFinalDelivery = 0;
    } else if (event.type == 'Destination Country') {
      this.getDestinationCountryList(event.value);
      this.offsetFinalDelivery = 0;
    }
  }

  selectActiveDateFilter(filterType: any) {
    this.dateRangeFilterType = filterType;
  }

  getOriginList(searchText: any) {
    this.originList = this.allLocationFilterResults.origin;
    this.allLocationList = CommonUtils.sortAlphaNumeric(this.originList, 'asc', 'rL_PortName');
  }

  getDestinationList(searchText: any) {
    this.destinationList = this.allLocationFilterResults.destination;
    this.allLocationList = CommonUtils.sortAlphaNumeric(this.destinationList, 'asc', 'rL_PortName');
  }

  getShipperList(searchText: any) {
    this.isProgressLocation = true;
    this.shipmentService
      .getShipperFilter(
        searchText,
        this.offsetShipper,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (this.offsetShipper >= 10) {
          this.isProgressLocation = false;
          this.shipperList = [...this.shipperList, ...locationL.result];
        } else {
          this.isProgressLocation = false;
          this.shipperList = locationL.result;
        }
      });
  }

  getConsigneeList(searchText: any) {
    this.isProgressLocation = true;
    this.shipmentService
      .getConsigneeFilter(
        searchText,
        this.offsetConsignee,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (this.offsetConsignee >= 10) {
          this.isProgressLocation = false;
          this.consigneeList = [...this.consigneeList, ...locationL.result];
        } else {
          this.isProgressLocation = false;
          this.consigneeList = locationL.result;
        }
      });
  }

  getControlingCustomer(searchText: any) {
    this.isProgressLocation = true;
    this.shipmentService
      .getControllingCustomer(
        searchText,
        this.offsetConsignee,
        this.accountID,
        this.orginEmail,
        this.isGroup,
        this.fetchMobileRecords
      )
      .subscribe((locationL: any) => {
        if (this.offsetConsignee >= 10) {
          this.isProgressLocation = false;
          this.controllingCustomerList = [
            ...this.controllingCustomerList,
            ...locationL.result,
          ];
        } else {
          this.isProgressLocation = false;
          this.controllingCustomerList = locationL.result;
        }
      });
  }

  getPickUpList(searchText: any) {
    if (this.pickupList?.length == 0) {
      this.isProgressLocation = true;
      this.shipmentService.locationFilter(this.accountID, this.orginEmail, this.isGroup).subscribe((e: any) => {
        this.allLocationFilterResults = e.result;
        this.pickupList = e.result.pickupDelivery;
        this.allLocationList = this.pickupList;
        this.isProgressLocation = false;
      }, (error: any) => { this.isProgressLocation = false }, () => { this.isProgressLocation = false; })
    }
    else {
      this.pickupList = this.allLocationFilterResults.pickupDelivery;
      this.allLocationList = this.pickupList;
    }
  }


  getFinalDeliveryList(searchText: any) {
    this.finalDeliveryList = this.allLocationFilterResults.finalDelivery;
    this.allLocationList = this.finalDeliveryList;
  }

  getOriginCountryList(searchText: any) {
    this.finalDeliveryList = this.allLocationFilterResults.originCoutry;
    this.allLocationList = CommonUtils.sortAlphaNumeric(this.finalDeliveryList, 'asc', 'country');
  }

  getDestinationCountryList(searchText: any) {
    this.finalDeliveryList = this.allLocationFilterResults.destinationCountry;
    this.allLocationList = CommonUtils.sortAlphaNumeric(this.finalDeliveryList, 'asc', 'country');
  }

  loadLocationFilter(value: any) {
    switch (value) {
      case 'Pickup':
        this.allLocationList = [];
        this.offsetPickup = 0;
        this.getPickUpList('');
        break;
      case 'Origin':
        this.allLocationList = [];
        this.offsetOrgin = 0;
        this.getOriginList('');
        break;
      case 'Destination':
        this.allLocationList = [];
        this.offsetDestination = 0;
        this.getDestinationList('');
        break;
      case 'Final Delivery':
        this.allLocationList = [];
        this.offsetFinalDelivery = 0;
        this.getFinalDeliveryList('');
        break;
      case 'Origin Country':
        this.allLocationList = [];
        this.offsetFinalDelivery = 0;
        this.getOriginCountryList('');
        break;
      case 'Destination Country':
        this.allLocationList = [];
        this.offsetFinalDelivery = 0;
        this.getDestinationCountryList('');
        break;
      default:
        this.getPickUpList('');
        break;
    }
  }

  share(event: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.SharedAll.toString(), {});

    this.popupToggle = true;
    this.prepareUrlShare.emit({
      type: 'All',
      shipmentId: this.shipmentSearchText,
    });
  }

  newShare(event: any) {
    this.prepareUrlShare.emit({
      type: 'All',
      shipmentId: this.shipmentSearchText,
    });
  }

  featureHide() {
    this.popupToggle = false;
  }

  flagTouchedFilter(customDropdown: boolean, transportRibbon: boolean, watchlistRibbon: boolean) {  // custom dropdown, ribbon => transport and watch list
    this.shipmentService.touchedCustomDropdown = customDropdown;
    this.shipmentService.touchedTransportRibbonMode = transportRibbon;
    this.shipmentService.touchedWatchlistRibbonMode = watchlistRibbon;
  }

  openMobileFilter() {
    this.flagTouchedFilter(true, false, false);
    this.shipmentService.advancedFilterSavedRecently = false;
    this.closeAdvanceFilterPopup();
    if (this.determineIsAdvancedFilterApplied()) {
      return;
    }
    else {
      this.mobileFilterContainer = true;
    }
  }

  closeFilter() {
    this.mobileFilterContainer = false;
    if (this.mobileLocationFilterReselect.type == 'consignee') {
      this.mobileConsigneeFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (
      this.mobileLocationFilterReselect.type == 'controllingcustomer'
    ) {
      this.mobileConsigneeFilterReselect =
        this.mobileLocationFilterReselect.account;
    } else if (this.mobileLocationFilterReselect.type == 'shipper') {
      this.mobileShipperFilterReselect =
        this.mobileLocationFilterReselect.account;
    }

    this.allLocationReselectedList = [
      { origin: this.mobileOrginFilterReselect },
      { destination: this.mobileDestinationFilterReselect },
      { pickup: this.mobilePickupFilterReselect },
      { finalDelivery: this.mobileFinalDeliveryFilterReselect },
      { origincountry: this.mobileOriginCountryFilterReselect },
      { destinationcounty: this.mobileDestinationCountryFilterReselect },
    ];
  }

  excelExport(download: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.ExcelExported.toString(), {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
    });
    this.downloadExcel.emit(download);
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  getDateRange(event: any) {
    this.selectedDateRange.emit(event);
    setTimeout(() => {
      this.saveAsButtonVisibility();
    }, 300);
  }

  ApplyElementCase0(status: any, element: any) {
    if (status) {
      if (this.isFilterFromGlobalSearch) {
        this.statusFilterItem?.forEach((e: any) => {
          e.checked = false;
        })
      }
      let statusChecked = status[0]?.value?.split(',');
      statusChecked?.forEach((ele: any) => {
        let elementPos = this.statusFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        elementPos > -1
          ? (this.statusFilterItem[elementPos].checked = true)
          : (this.statusFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.statusFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.statusFilterItem);
  }

  ApplyElementCase1(exception: any, element: any) {
    if (exception) {
      let exceptionChecked = exception[0]?.value?.split(',');
      exceptionChecked?.forEach((ele: any) => {
        let elementPos = this.exceptionsFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        elementPos > -1
          ? (this.exceptionsFilterItem[elementPos].checked = true)
          : (this.exceptionsFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.exceptionsFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.exceptionsFilterItem);
  }

  ApplyElementCase2(transport: any, element: any) {
    if (transport) {
      let transportChecked = transport[0]?.value?.split(',');
      transportChecked?.forEach((ele: any) => {
        let elementPos = this.transportFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(parseInt(ele));
        elementPos > -1
          ? (this.transportFilterItem[elementPos].checked = true)
          : (this.transportFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.transportFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.transportFilterItem);
  }

  ApplyElementCase3(container: any, element: any) {
    if (container) {
      let containerChecked = container[0]?.value?.split(',');
      containerChecked?.forEach((ele: any) => {
        let elementPos = this.containerFilterItem
          .map(function (x: any) {
            return x.val;
          })
          .indexOf(ele);
        elementPos > -1
          ? (this.containerFilterItem[elementPos].checked = true)
          : (this.containerFilterItem[elementPos].checked = false);
      });
    }
    let displayText = this.trimDisplayStringCustomFilter(
      this.containerFilterItem,
      element.filterDisplayName
    );
    this.mapFilterData(element, displayText, '', this.containerFilterItem);
  }

  clearMobileFilters() {
    if (!this.isMobileViewParent) {
      this.mobileStatusFilter = [];
      this.mobileExceptionFilter = [];
      this.mobileDateRangeFilter = [];
      this.mobileTransportFilter = [];
      this.mobileOriginFilter = [];
      this.mobileDestinationtFilter = [];
      this.mobileWatchListFilter = [];

      this.mobileSelectedFilter = '';
    }
  }

  filterIsOpen(
    event: any,
    status: any,
    filterValue: any,
    filterDisplayName: any,
    filterText: any,
    index: any
  ) {
    let isVisible = $(event).attr('aria-expanded');
    if (isVisible == 'true') {
      this.isFilterSelected(
        status,
        filterValue,
        filterDisplayName,
        filterText,
        index
      );
      this.allLocation.allLocationDefaultCustomfilterM('Pickup');
    } else {
      let checker: any;
      switch (filterValue) {
        case 0:
          checker = Object.values(this.transportFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 1:
          checker = Object.values(this.statusFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 6:
          checker = Object.values(this.exceptionsFilterItem).some(
            (item) => item.checked === true
          );
          this.removeMainFilter(checker, filterValue);
          break;
        case 2:
          this.shipperList = [];
          this.offsetShipper = 0;
          break;
        case 3:
          this.consigneeList = [];
          this.offsetConsignee = 0;
          break;
      }
    }
  }

  removeMainFilter(isPresent: boolean, filterValue: any) {
    if (!isPresent) {
      let elementPos = this.selectedMainFilter
        .map(function (x: any) {
          return x.id;
        })
        .indexOf(filterValue);
      if (elementPos > -1) {
        this.selectedMainFilter.splice(elementPos, 1);
      }
    }
  }

  watchlistSelected(event: any) {
    if (event.target.checked) {
      this.checkWatchlist = event.target.checked;
      this.isFilterSelected(true, 7, 'Watchlist', 'watchlist', 7);
    } else {
      this.checkWatchlist = event.target.checked;
      this.closeWatchFilter();
    }
  }

  closeUserFilterPopUp() {
    this.createUserFilterPopUp = false;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
    this.myFilterName = '';
    this.isfilterEdit = '';
    this.clearUserList();
    this.filterSystemDefined = false;
  }

  closeEditUserFilterPopUp() {
    this.isShowEditPopup = false;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
    this.myEditFilterName = '';
    this.filterSystemDefined = false;
    this.isfilterEdit = '';
    this.clearUserList();
  }

  openSaveFilterPopUp() {
    this.createUserFilterPopUp = true;
    this.prepareUrlShare.emit({
      type: 'All',
      shipmentId: this.shipmentSearchText,
    });
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
    this.filterSystemDefined = false;
    this.form?.value?.users?.forEach((element: any, index: number) => {
      this.removeFromList(index);
    });
    this.searchFilter = '';
  }

  checkAdvFilterOnSaveMode() {
    if (JSON.stringify(this.advanceFilterValue) != "{}" || this.advanceFilterValue?.length > 0) {
      this.shipmentService.advancedFilterSavedRecently = true;
    }
  }

  emitSystemFilterToastMessage(message: any, type: any, filterName: any, timeOut: number) {
    setTimeout(() => {
      this.onSystemFilterSaved.emit({
        message: message,
        type: type,
        filterName: filterName,
      });
    }, timeOut)
  }

  emitUserFilterToastMessage(message: any, type: any, filterName: any, timeOut: number) {
    setTimeout(() => {
      this.onUserFilterSaved.emit({
        message: message,
        type: type,
        filterName: filterName,
      });
    }, timeOut)
  }

  attachAdvanceFilter() {
    return {
      ...this.saveFilterJson,
      advancedFilter: this.advanceFilterValue,
    };
  }

  checkAndUpdateMainFilterValue() {
    this.mainFilterValueCheck();
    this.saveFilterJson?.mainFilter?.forEach((e: any) => {
      e.filterDisplayName = e.filterDisplayName.includes(':')
        ? e.filterDisplayName?.split(':')[0]
        : e.filterDisplayName;
    });
  }

  loadLoader(load: boolean) {
    this.isLoading.emit(load);
  }

  isSuccess(res: any) {
    return (res?.statusCode == 'OC200');
  }

  isDuplicate(res: any) {
    return (res?.statusCode == 'OC401');
  }

  saveUserFilter(filterName: any) {
    this.loadLoader(true);
    this.shipmentService
      .saveUserFilter(
        this.currentUserDetails.emailAddress,
        filterName,
        this.saveFilterJson,
        this.currentAccDetails.accountId,
        this.accountID,
        this.isGroup
      )
      .subscribe((res: any) => {
        if (this.isSuccess(res)) {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
          this.createUserFilterPopUp = false;
          this.tempSortDiff = '';
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.searchFilter = '';
          this.emitUserFilterToastMessage('Filter has been saved successfully',
            'save', filterName, 0);
          this.loadLoader(false);
        } else if (this.isDuplicate(res)) {
          this.myFilterError = true;
          this.loadLoader(false);
          setTimeout(() => {
            this.myFilterError = false;
          }, 3000);
        }
      });
  }

  saveUserDefinedFilter(filterName: any, userArray: any) {
    this.loadLoader(true);
    this.shipmentService
      .saveSystemFilter(
        this.currentUserDetails.emailAddress,
        filterName,
        this.saveFilterJson,
        this.currentAccDetails.accountId,
        userArray,
        this.currentAccDetails.isGroup
      )
      .subscribe((res: any) => {
        if (this.isSuccess(res)) {
          let temp = this.isfilterEdit;
          if (temp) {
            setTimeout(() => {
              this.isfilterEdit = temp;
              this.delUserFilter(true);
            }, 500);
          }
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
          this.createUserFilterPopUp = false;
          this.isShowEditPopup = false;
          this.tempSortDiff = '';
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.searchFilter = '';
          this.emitSystemFilterToastMessage('Filter has been saved successfully',
            'save', filterName, 0);
          this.loadLoader(false);
          this.clearUserList();
        } else if (this.isDuplicate(res)) {
          this.myFilterError = true;
          this.loadLoader(false);
          setTimeout(() => {
            this.myFilterError = false;
          }, 3000);
        }
      });
  }

  logAppInsightFilterSaveEvents(filterName: any, event: any) {
    this.appInsightsService.logEvent(
      event.toString(),
      {
        [AI_CustomProps.FilterName]: filterName,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
  }

  saveMyFilter(users?: any) {
    let filterName = this.myEditFilterName.trim() ? this.myEditFilterName.trim() : this.myFilterName.trim();
    let userArray = users?.users ? users?.users : [];
    this.saveFilterJson = this.attachAdvanceFilter();
    this.checkAdvFilterOnSaveMode();
    if (filterName && !this.filterSystemDefined) {
      this.logAppInsightFilterSaveEvents(filterName, AI_CommonEvents.MySavedFilterAdded);
      this.checkAndUpdateMainFilterValue();
      this.saveUserFilter(filterName);
    } else if (filterName && this.filterSystemDefined) {
      this.logAppInsightFilterSaveEvents(filterName, AI_CommonEvents.SystemDefinedFilterAdded);
      this.checkAndUpdateMainFilterValue();
      this.saveUserDefinedFilter(filterName, userArray);
    } else {
      this.myFilterNameEmpty = true;
    }
  }

  saveAsButtonVisibility() {
    this.myEditFilterName = '';
    this.myFilterName = '';
    if (!this.isMobileViewParent && this.paramCheck) {
      let filterValue = this.paramCheck.filter_options;
      let isEdit = this.isfilterEdit?.isEditTrue || false;
      if (!this.tempSortDiff) {
        this.tempSortDiff = this.paramCheck.sort_options;
      }
      setTimeout(() => {
        if (
          JSON.stringify(this.paramCheck) !=
          JSON.stringify(this.tempFilterDiff) &&
          filterValue?.length > 0 && this.filterItem.some((e: any) => e.checked)
        ) {
          this.isEditFn(isEdit, this.isfilterEdit);
        } else if (
          JSON.stringify(this.paramCheck) == JSON.stringify(this.tempFilterDiff)
        ) {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
        } else {
          this.isShowSaveAsBtn = false;
          this.isShowSaveBtn = false;
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          this.clearAllFilterValue.emit('saveButton');
        }
      }, 100);
    }
  }

  isEditFn(isEdit: boolean, filters: any) {
    this.isShowSaveAsBtn = true;
    if (
      isEdit &&
      ((filters.savedValue.filterType === 'shipment' &&
        this.currentUserRole === 'systemadmin') ||
        filters.savedValue.filterType === 'shipping')
    ) {
      this.isShowSaveBtn = true;
    } else {
      this.isShowSaveBtn = false;
    }
  }
  clearAllFilters() {
    this.filterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.statusFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.exceptionsFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.transportFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.containerFilterItem.forEach((ele) => {
      ele.checked = false;
    });
    this.originList = [];
    this.offsetOrgin = 0;

    this.destinationList = [];
    this.offsetDestination = 0;

    this.selectedMainFilter = [];
    this.isShowSaveAsBtn = false;
    this.isShowSaveBtn = false;
    this.tempSortDiff = '';
    this.shipperList = [];
    this.offsetShipper = 0;

    this.consigneeList = [];
    this.offsetConsignee = 0;

    this.mobileShipperFilterReselect = [];
    this.mobileConsigneeFilterReselect = [];
    this.mobileControlingCustomerReselect = [];

    this.pickupList = [];
    this.finalDeliveryList = [];
    this.offsetPickup = 0;
    this.offsetFinalDelivery = 0;
    this.allLocationReselectedList = [
      { origin: [] },
      { destination: [] },
      { pickup: [] },
      { finalDelivery: [] },
    ];

    this.advancedFilterAdded = [];
    this.isAdvanceFilterSelected = false;
  }

  lastSavedStateSysDefineCheckBox = false;
  setUpdateFilterName() {
    this.form?.value?.users?.forEach((element: any, index: number) => {
      this.removeFromList(index);
    });
    this.showCancelPopup = true;
    this.isShowEditPopup = true;
    this.saveButtonVisible = true;
    this.myFilterError = false;
    this.myFilterNameEmpty = false;
    this.searchFilter = '';
    this.myEditFilterName = this.isfilterEdit.savedValue.filterName;
    this.lastSavedStateSysDefineCheckBox = this.isfilterEdit.savedValue
      .isSystemDefined
      ? this.isfilterEdit.savedValue.isSystemDefined
      : false;
    this.filterSystemDefined = this.isfilterEdit.savedValue.isSystemDefined
      ? this.isfilterEdit.savedValue.isSystemDefined
      : false;
    if (this.isfilterEdit.savedValue.filterusers?.length > 0) {
      this.isfilterEdit.savedValue.filterusers.forEach(
        (item: any, index: number) => {
          if (this.selectedUser.length < 1) {
            this.form = this.fb.group({
              users: this.fb.array([this.createSystemDefinedForm(item)]),
            });
            this.selectedUser.push(item);
          } else {
            let isUserAvailable = this.form.value?.users?.some(
              (x: any) => x.userEmail === item.emailAddress
            );
            if (!isUserAvailable) {
              this.addUserToList(item);
              this.selectedUser.push(item);
            }
          }
        }
      );
    }
  }

  updateUserFilter(users?: any) {
    if (this.isfilterEdit.savedValue.filterType === 'shipment') {
      let userArr = users && users.users.length > 0 ? users : [];
      this.systemFilterEdit(userArr);
    } else if (this.isfilterEdit.savedValue.filterType === 'shipping') {
      if (this.filterSystemDefined) {
        let userArr = users && users.users.length > 0 ? users : [];
        this.myFilterName = this.isfilterEdit.savedValue.filterName;
        this.saveMyFilter(userArr);
      } else {
        this.mySavedFilterEdit();
      }
    }
  }

  mySavedFilterEdit(hasAdvancedFilterSaveButtonClicked?: boolean) {
    this.callPrepareShareUrl();
    setTimeout(() => {
      let filterJson: any = '';
      let msgText: any = '';
      this.saveFilterJson = {
        ...this.saveFilterJson,
        advancedFilter: this.advanceFilterValue,
      };
      if (this.myEditFilterName != '' && this.isShowEditPopup) {
        filterJson = this.isfilterEdit.savedValue.jsonFilter;
        msgText = this.myEditFilterName;
      } else if (this.myEditFilterName == '' && !this.isShowEditPopup) {
        msgText = this.isfilterEdit.savedValue.filterName;
        this.mainFilterValueCheck();
        this.saveFilterJson.mainFilter.forEach((e: any) => {
          e.filterDisplayName = e.filterDisplayName.includes(':')
            ? e.filterDisplayName?.split(':')[0]
            : e.filterDisplayName;
        });
        filterJson = JSON.stringify(this.saveFilterJson);
      }
      if (msgText) {
        this.appInsightsService.logEvent(
          AI_CommonEvents.MySavedFilterEdited.toString(),
          {
            [AI_CustomProps.FilterName]: msgText,
            [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
          }
        );
        this.isLoading.emit(true);
        this.shipmentService
          .updateUserFilter(
            this.isfilterEdit.savedValue,
            filterJson,
            msgText,
            this.currentAccDetails.accountId,
            this.currentAccDetails.isGroup
          )
          .subscribe((res: any) => {
            if (res.statusCode == 'OC200') {
              this.isShowSaveAsBtn = false;
              this.isShowSaveBtn = false;
              this.tempSortDiff = '';
              this.myEditFilterName = '';
              this.isShowEditPopup = false;
              this.searchFilter = '';
              this.isLoading.emit(false);
              this.showAdvSaveMessage = false;
              if (hasAdvancedFilterSaveButtonClicked) {
                this.showAdvSaveMessage = true;
                this.emitUserFilterToastMessage(undefined, 'update', msgText, 0);
                this.clearAdvanceFilterToastMEssage();
              } else {
                this.emitUserFilterToastMessage(msgText + ' Saved filter Updated', 'update', msgText, 0);
              }
              this.isfilterEdit = '';
              this.tempFilterDiff = '';
              this.tempSortDiff = '';
            } else if (res.statusCode == 'OC401') {
              this.myFilterError = true;
              this.isLoading.emit(false);
              setTimeout(() => {
                this.myFilterError = false;
              }, 3000);
            }
          });
      } else {
        this.myFilterNameEmpty = true;
      }
    }, 400);
    //checking purpose
  }

  systemFilterEdit(users: any, hasAdvancedFilterSaveButtonClicked?: boolean) {
    let userArray = users?.users?.length > 0 ? users?.users : [];
    //this.isfilterEdit.savedValue.filterusers.length > 0 ? this.isfilterEdit.savedValue.filterusers : [];
    this.callPrepareShareUrl();
    setTimeout(() => {
      let filterJson: any = '';
      let msgText: any = '';
      this.saveFilterJson = {
        ...this.saveFilterJson,
        advancedFilter: this.advanceFilterValue,
      };
      if (this.myEditFilterName != '' && this.isShowEditPopup) {
        filterJson = this.isfilterEdit.savedValue.jsonFilter;
        msgText = this.myEditFilterName;
      } else if (this.myEditFilterName == '' && !this.isShowEditPopup) {
        msgText = this.isfilterEdit.savedValue.filterName;
        this.mainFilterValueCheck();
        this.saveFilterJson.mainFilter.forEach((e: any) => {
          e.filterDisplayName = e.filterDisplayName.includes(':')
            ? e.filterDisplayName?.split(':')[0]
            : e.filterDisplayName;
        });
        filterJson = JSON.stringify(this.saveFilterJson);
      }
      if (msgText) {
        this.appInsightsService.logEvent(
          AI_CommonEvents.MySavedFilterEdited.toString(),
          {
            [AI_CustomProps.FilterName]: msgText,
            [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
          }
        );
        this.isLoading.emit(true);
        this.shipmentService
          .updateSystemFilter(
            this.isfilterEdit.savedValue,
            filterJson,
            msgText,
            this.currentAccDetails.accountId,
            userArray,
            this.filterSystemDefined,
            this.currentAccDetails.isGroup
          )
          .subscribe((res: any) => {
            if (res.statusCode == 'OC200') {
              this.isShowSaveAsBtn = false;
              this.isShowSaveBtn = false;
              this.tempSortDiff = '';
              this.myEditFilterName = '';
              this.isShowEditPopup = false;
              this.searchFilter = '';
              this.isLoading.emit(false);
              this.showAdvSaveMessage = false;
              if (
                res.result.toString().includes(
                  'Deleted from System filter and Saved to Shipment Filter'
                )
              ) {
                this.emitSystemFilterToastMessage(msgText + ' Saved filter Updated',
                  'delete', msgText, 0)
                setTimeout(() => {
                  this.emitUserFilterToastMessage('', 'filter-added-from-systemFilter', msgText, 0);
                }, 300);
              } else {
                if (hasAdvancedFilterSaveButtonClicked) {
                  this.showAdvSaveMessage = true;
                  this.emitSystemFilterToastMessage(undefined, 'update', msgText, 0);
                  this.clearAdvanceFilterToastMEssage();
                }
                else {
                  this.emitSystemFilterToastMessage(msgText + ' Saved filter Updated',
                    'update', msgText, 0);
                }
              }

              this.isfilterEdit = '';
              this.tempFilterDiff = '';
              this.tempSortDiff = '';
              this.clearUserList();
            } else if (res.statusCode == 'OC401') {
              this.myFilterError = true;
              this.isLoading.emit(false);
              setTimeout(() => {
                this.myFilterError = false;
              }, 3000);
            }
          });
      } else {
        this.myFilterNameEmpty = true;
      }
    }, 400);
  }

  callPrepareShareUrl() {
    setTimeout(() => {
      this.prepareUrlShare.emit({
        type: 'All',
        shipmentId: this.shipmentSearchText,
      });
    }, 100);
  }

  deleteFilter() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.MySavedFilterDeleted.toString(),
      {
        [AI_CustomProps.FilterName]: this.isfilterEdit?.savedValue?.filterName,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
    if (this.isfilterEdit.savedValue.filterType === 'shipment') {
      this.deleteSystemFilter();
    } else {
      this.delUserFilter();
    }
    this.clearRibonAdvFiltersOnUserDelete();
  }


  clearRibonAdvFiltersOnUserDelete() {
    this.filterModeRibbonValue = [];
    this.filterModeRibbonText = [];
    this.displayModeTooltipText = '';
    this.closeModeRibonFilter();
    // this.showPickupAndDelivery = false;
    this.showWatchList = false;
    // this.showClearAllButton = false;
    this.isAdvanceFilterSelected = false;
  }


  delUserFilter(isManualDel?: boolean) {
    this.isLoading.emit(true);
    const hasFilterValue = this.isfilterEdit.savedValue;
    this.shipmentService
      .deleteUserFilter(
        this.currentUserDetails.emailAddress,
        hasFilterValue.id
      )
      .subscribe((res: any) => {
        if (res.statusCode == "OC200" || res.result.string().includes("Successfully Deleted.")) {
          this.isShowEditPopup = false;
          this.showIsDeletePopup = true;
          this.tempSortDiff = '';
          this.searchFilter = '';
          this.isLoading.emit(false);
          if (isManualDel) {
            this.emitUserFilterToastMessage('', 'start', '', 0);
          } else {
            this.emitUserFilterToastMessage(hasFilterValue?.filterName + ' Filter Deleted',
              'delete', hasFilterValue?.filterName, 0);
          }
          this.tempUndoArray = this.isfilterEdit?.savedValue;
          this.clearAllFilters();
          this.clearAllFilterValue.emit('clearAll');
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          this.filterSystemDefined = false;
          this.clearUserList();
          setTimeout(() => {
            this.tempUndoArray = '';
          }, 5000);
        }
        else {
          console.log("Error=>", res)
        }
      });
  }

  deleteSystemFilter() {
    this.isLoading.emit(true);
    const hasFilterValue = this.isfilterEdit.savedValue;
    this.shipmentService
      .deleteSystemFilter(
        this.currentUserDetails.emailAddress,
        hasFilterValue.id
      )
      .subscribe((res: any) => {
        if (res.statusCode == "OC200" || res.result.string().includes("Successfully Deleted.")) {
          this.isShowEditPopup = false;
          this.showIsDeletePopup = true;
          this.searchFilter = '';
          this.tempSortDiff = '';
          this.isLoading.emit(false);
          this.emitSystemFilterToastMessage(hasFilterValue?.filterName + ' Filter Deleted',
            'delete', hasFilterValue?.filterName, 0);
          this.tempUndoArray = this.isfilterEdit?.savedValue;
          this.clearAllFilters();
          this.clearAllFilterValue.emit('clearAll');
          this.isfilterEdit = '';
          this.tempFilterDiff = '';
          this.tempSortDiff = '';
          this.filterSystemDefined = false;
          this.clearUserList();
          setTimeout(() => {
            this.tempUndoArray = '';
          }, 5000);
        }
      });
  }

  undoFilterChanges() {
    if (this.tempUndoArray.filterType === 'shipment') {
      this.undoChangesSystemFilter();
    } else {
      this.undoChanges();
    }
  }

  undoChanges() {
    this.isLoading.emit(true);
    this.shipmentService
      .saveUserFilter(
        this.currentUserDetails.emailAddress,
        this.tempUndoArray.filterName,
        this.tempUndoArray.jsonFilter,
        this.currentAccDetails.accountId,
        this.accountID,
        this.isGroup
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          this.isLoading.emit(false);
          this.emitUserFilterToastMessage(this.tempUndoArray.filterName + ' Filter Restored', 'save', undefined, 0)
          this.tempUndoArray = '';
        }
      });
  }

  undoChangesSystemFilter() {
    this.isLoading.emit(true);
    this.shipmentService
      .saveSystemFilter(
        this.currentUserDetails.emailAddress,
        this.tempUndoArray.filterName,
        this.tempUndoArray.jsonFilter,
        this.currentAccDetails.accountId,
        this.tempUndoArray.filterusers,
        this.currentAccDetails.isGroup
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          this.isLoading.emit(false);
          this.emitSystemFilterToastMessage(this.tempUndoArray.filterName + ' Filter Restored', 'save', "", 0);
          this.tempUndoArray = '';
        }
      });
  }

  selectedUserFilter(userFilterRow: any) {
    this.mobileSelectedFilter = userFilterRow.id;
    this.userFilterFetch.emit({ userFilterRow: userFilterRow, type: 'view' });
  }

  /* istanbul ignore next */
  mainFilterValueCheck() {
    if (this.saveFilterJson?.mainFilter?.length <= 0) {
      if (Object.keys(this.saveFilterJson.statusFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 1,
          filterDisplayName: 'Status',
          type: 'statuscustom',
          index: 1,
        });
      }
      if (Object.keys(this.saveFilterJson.transportType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 0,
          filterDisplayName: 'Transport Type',
          type: 'transport',
          index: 0,
        });
      }
      if (Object.keys(this.saveFilterJson.containerType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 9,
          filterDisplayName: 'Container Type',
          type: 'containertype',
          index: 9,
        });
      }
      if (this.saveFilterJson.shipper.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 2,
          filterDisplayName: 'Shipper',
          type: 'shipper',
          index: 2,
        });
      }
      if (this.saveFilterJson.consignee.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 3,
          filterDisplayName: 'Consignee',
          type: 'consignee',
          index: 3,
        });
      }
      if (
        this.saveFilterJson.allLocation.selectedAccount[0].origin.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[1].destination.length >
        0 ||
        this.saveFilterJson.allLocation.selectedAccount[2].pickup.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[3].finalDelivery
          .length > 0
      ) {
        this.saveFilterJson.mainFilter.push({
          id: 4,
          filterDisplayName: 'Location',
          type: 'location',
          index: 4,
        });
      }
      if (this.saveFilterJson.dateRange.selectedDateRange.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 5,
          filterDisplayName: 'Date Range',
          type: '',
          index: 5,
        });
      }

      if (Object.keys(this.saveFilterJson.exceptionType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 6,
          filterDisplayName: 'Exception Type',
          type: 'exception',
          index: 6,
        });
      }
      if (Object.keys(this.saveFilterJson.watchFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 7,
          filterDisplayName: 'Watchlist',
          type: '',
          index: 7,
        });
      }
      if (this.saveFilterJson.consignee.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 8,
          filterDisplayName: 'Controlling Customer',
          type: 'controllingcustomer',
          index: 8,
        });
      }
    }
  }

  getFilterOffset(id: number) {
    if (id === 2) {
      return this.offsetShipper;
    } else if (id === 3) {
      return this.offsetConsignee;
    } else if (id === 8) {
      return this.offsetControlingCustomer;
    } else {
      return null;
    }
  }

  currentShipperTypeFilter(id: number) {
    if (id === 2) {
      return this.shipperList;
    } else if (id === 3) {
      return this.consigneeList;
    } else if (id === 8) {
      return this.controllingCustomerList;
    } else {
      return null;
    }
  }

  currentShippertTypeFilerRecent(id: number) {
    if (id === 2) {
      return this.resentListShipper;
    } else if (id === 3) {
      return this.resentListConsignee;
    } else if (id === 8) {
      return this.resentListControlingCustomer;
    } else {
      return null;
    }
  }

  userAutoComplete: any[] = [];
  keyword = 'nameWithEmail';
  searchFilter = '';
  autoCompleteInProgress = false;
  userCount = 0;
  selectedUser: any[] = [];
  createSystemDefinedForm(item: any): FormGroup {
    return this.fb.group({
      userEmail: [item.emailAddress ? item.emailAddress : item.userEmail],
      firstName: [item.firstName],
      lastName: [item.lastName],
      firstNamelastName: [item.firstName + ' ' + item.lastName],
      firstNamelastNamelower: [
        item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase(),
      ],
      isDisplayFilterSection: [
        item.isDisplayFilterSection ? item.isDisplayFilterSection : false,
      ],
      isDefaultFilter: [item.isDefaultFilter ? item.isDefaultFilter : false],
      isConsiderForDataConsolidation: [
        item.isConsiderForDataConsolidation
          ? item.isConsiderForDataConsolidation
          : false,
      ],
      //  account: [this.currentAccDetails.accountId],
    });
  }

  selectEvent(item: any) {
    if (this.selectedUser.length < 1) {
      this.form = this.fb.group({
        users: this.fb.array([this.createSystemDefinedForm(item)]),
      });
      this.selectedUser.push(item);
    } else {
      let isUserAvailable = this.form.value?.users?.some(
        (x: any) => x.userEmail === item.emailAddress
      );
      if (!isUserAvailable) {
        this.addUserToList(item);
        this.selectedUser.push(item);
      }
    }
    this.userCount = 1;
    this.userAutoComplete = [];
    this.searchFilter = '';
    this.auto?.clear();
    this.saveButtonDisabled();
  }

  blurEvent(item: any) { }

  autoCompleteOnKeyUp(item: any) {
    if (item.length > 2) {
      this.shipmentService.accountUsers(this.currentAccDetails.accountId, item).subscribe((res: any) => {
        res.result?.forEach((element: any) => {
          element.nameWithEmail = element.firstName + ' ' + element.lastName + ' ' + element.emailAddress
        });
        this.userAutoComplete = res.result?.filter((e: any) => (!this.selectedUser.some((f) => e.emailAddress === f.userEmail || e.emailAddress === f.emailAddress)) &&
          e.statusName.toLowerCase() == "active");
      })
    }
  }

  public addUserToList(item: any): void {
    const users = this.form.get('users') as FormArray;
    users.push(this.createSystemDefinedForm(item));
  }

  public removeFromList(index?: number): void {
    let i = index ? index : parseInt(this.currentRemoveIndex);
    const users = this.form.get('users') as FormArray;
    users.removeAt(i);
    this.selectedUser.splice(i, 1);
    setTimeout(() => {
      this.currentRemoveIndex = '';
    }, 200);
    this.showIsUserRemovePopup = true;
  }

  get getControls() {
    return (this.form.get('users') as FormArray).controls;
  }

  get FControl() {
    return this.form.get('users') as FormArray;
  }

  cancelPopuCheck() {
    if (this.isfilterEdit.isEditTrue && this.isShowEditPopup) {
      this.editCancelPopup();
    } else {
      this.createCancelPopup();
    }
  }

  editCancelPopup() {
    let savedUser = [];
    let formUser = [];
    if (this.isfilterEdit?.savedValue?.filterusers) {
      savedUser = this.isfilterEdit?.savedValue?.filterusers;
    }
    if (this.form?.value?.users) {
      formUser = this.form?.value?.users;
    }
    if (
      this.isfilterEdit.savedValue.filterName !== this.myEditFilterName ||
      this.lastSavedStateSysDefineCheckBox !== this.filterSystemDefined ||
      (JSON.stringify(savedUser) !== JSON.stringify(formUser) &&
        this.currentUserRole === 'systemadmin')
    ) {
      this.showCancelPopup = false;
    } else {
      this.confirmCancelPopup();
    }
  }

  createCancelPopup() {
    if (
      this.myFilterName ||
      ((this.filterSystemDefined || this.form?.value?.users?.length > 0) &&
        this.currentUserRole === 'systemadmin')
    ) {
      this.showCancelPopup = false;
    } else {
      this.confirmCancelPopup();
    }
  }

  saveButtonVisible = true;
  saveButtonDisabled() {
    if (
      this.isfilterEdit?.savedValue?.filterName !== this.myEditFilterName ||
      (JSON.stringify(this.isfilterEdit?.savedValue?.filterusers) !==
        JSON.stringify(this.form?.value?.users) &&
        this.currentUserRole === 'systemadmin')
    ) {
      this.saveButtonVisible = false;
    } else {
      this.saveButtonVisible = true;
    }
  }

  confirmCancelPopup() {
    this.showCancelPopup = true;
    if (this.isfilterEdit.isEditTrue && this.isShowEditPopup) {
      this.closeEditUserFilterPopUp();
    } else {
      this.closeUserFilterPopUp();
    }
  }

  sortFormArray(array: any, args: any, tablId: any, trId: any) {
    $(tablId + '>thead >tr>th').removeClass('factive');
    const elem: any = document.getElementById(trId);
    const order = elem.getAttribute('data-order');
    if (array !== undefined) {
      return array.controls.sort((a: any, b: any) => {
        const aValue = a.controls[args].value;
        const bValue = b.controls[args].value;
        let condition1 = aValue > bValue;
        let condition2 = aValue < bValue;
        if (order === 'asc') {
          condition1 = aValue < bValue;
          condition2 = aValue > bValue;
          elem.setAttribute('data-order', 'desc');
          this.renderer.removeClass(elem, 'asc');
          this.renderer.addClass(elem, 'desc');
          this.renderer.addClass(elem, 'sort');
          this.renderer.addClass(elem, 'factive');
        } else {
          condition2 = aValue < bValue;
          condition1 = aValue > bValue;
          elem.setAttribute('data-order', 'asc');
          this.renderer.removeClass(elem, 'desc');
          this.renderer.addClass(elem, 'asc');
          this.renderer.addClass(elem, 'sort');
          this.renderer.addClass(elem, 'factive');
        }

        if (condition1) {
          return -1;
        } else if (condition2) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    const tableId = elem.getAttribute('data-parent');
    $(tableId + '>thead >tr>th').removeClass('factive');
    var id: any = document.getElementById('scroll-table-added');
    id.scrollTop = 0;
    return array;
  }

  showIsSysDefinedPopupFn() {
    let userArray =
      this.form?.value?.users && this.form?.value?.users?.length > 0
        ? this.form?.value?.users
        : this.isfilterEdit?.savedValue?.filterusers?.length > 0
          ? this.isfilterEdit?.savedValue?.filterusers
          : [];
    if (!this.filterSystemDefined && userArray.length > 0) {
      this.showIsSysDefinedPopup = false;
    }
  }

  onConfirmingSysDefined() {
    this.form?.value?.users?.forEach((element: any, index: number) => {
      this.removeFromList(index);
    });
    this.auto?.clear();
    this.selectedUser = [];
    this.searchFilter = '';
    this.showIsSysDefinedPopup = true;
  }

  onCancelingSystemDefined() {
    this.filterSystemDefined = true;
    this.showIsSysDefinedPopup = true;
  }

  currentRemoveIndex: any = '';
  showUserDeletePoup(index: number) {
    this.currentRemoveIndex = index;
    this.showIsUserRemovePopup = false;
  }

  onCancelingUserDelete() {
    this.currentRemoveIndex = '';
    this.showIsUserRemovePopup = true;
  }

  clearUserList() {
    this.form?.reset();
    this.form?.value?.users?.forEach((element: any, index: number) => {
      this.removeFromList(index);
    });
    this.auto?.clear();
    this.selectedUser = [];
    this.searchFilter = '';
  }

  saveButtonClicked(event: any, isAdvSaveClicked: any) {
    if (event === 'shipment') {
      let users = { users: this.isfilterEdit.savedValue.filterusers };
      this.filterSystemDefined = true;
      this.systemFilterEdit(users, isAdvSaveClicked);
    } else {
      this.filterSystemDefined = false;
      this.mySavedFilterEdit(isAdvSaveClicked);
    }

  }

  clearAdvanceFilterToastMEssage() {
    setTimeout(() => {
      this.showAdvSaveMessage = false;
    }, 3000)
  }

  displayFilterHintActive: boolean = false;
  dataConsolidationHintActive: boolean = false;
  isFilterMouseOver(value: number) {
    if (value === 1) {
      this.displayFilterHintActive = true;
    } else if (value === 2) {
      this.dataConsolidationHintActive = true;
    } else {
    }
  }

  isFilterMouseOut(value: number) {
    if (value === 1) {
      this.displayFilterHintActive = false;
    } else if (value === 2) {
      this.dataConsolidationHintActive = false;
    } else {
    }
  }

  openTransportFilter() {
    if (this.isTransportFilterOpen) {
      this.isFilterSelected(true, 0, 'Transport Type', 'transport', 0);
      setTimeout(() => {
        this.saveAsButtonVisibility();
      }, 300);
    }

    let index = this.selectedMainFilter.findIndex((e: any) => e.type == "transport");
    this.selectedMainFilter[index].filterArray?.forEach((e: any) => {
      if (this.filterModeRibbonValue.includes(e.val)) {
        e.checked = true;
      } else {
        e.checked = false;
      }
    });
  }

  toggleTransportRibbonMode() {
    this.flagTouchedFilter(false, true, false);
    this.shipmentService.advancedFilterSavedRecently = false;
    if (this.determineIsAdvancedFilterApplied()) {
      return;
    }
    this.showModeDropDown = !this.showModeDropDown;
  }

  filterMode(value: number, displayModeName: string) {
    if (this.filterModeRibbonValue?.includes(value)) {
      this.filterModeRibbonValue.splice(
        this.filterModeRibbonValue.indexOf(value),
        1
      );
      this.filterModeRibbonText.splice(
        this.filterModeRibbonText.indexOf(displayModeName),
        1
      );
    } else {
      this.filterModeRibbonValue.push(value);
      this.filterModeRibbonText.push(displayModeName);
      this.isTransportFilterOpen = true;
    }
    let modeValue = this.filterModeRibbonValue.join(',');
    this.mode.selectedValue[0].value = modeValue;
    this.displayModeTooltipText = this.filterModeRibbonText.join(', ');
    if (this.filterModeRibbonValue?.length > 0) {
      this.openTransportFilter();
      this.filterItem[0].checked = true;
      this.selectedFilterArray.emit(this.mode);
    } else {
      this.closeModeRibonFilter();
      this.filterItem[0].checked = false;
      this.isTransportFilterOpen = false;
    }
    this.determineClearAllFilterVisibility();
  }

  changeTableOrStandardView(value: any) {
    this.updateUserViewPreference(value);
    this.isTableViewSelected.emit(value);
  }

  offModeDropDown() {
    this.showModeDropDown = false;
  }

  toggleWatchList() {
    this.flagTouchedFilter(false, false, true);
    this.shipmentService.advancedFilterSavedRecently = false;
    if (this.determineIsAdvancedFilterApplied()) {
      return;
    }
    this.showWatchList = !this.showWatchList;
    if (this.showWatchList) {
      this.filterItem[9].checked = true;
      this.isFilterSelected(true, 7, 'Watchlist', 'watchlist', 9);
    } else {
      this.filterItem[9].checked = false;
      this.closeWatchFilter();
    }
    this.determineClearAllFilterVisibility();
  }

  togglePickupAndDelivery() {
    this.showPickupAndDelivery = !this.showPickupAndDelivery;
    this.pickUpAndDeliveryToggle.emit(this.showPickupAndDelivery);
    this.determineClearAllFilterVisibility();
  }

  determineClearAllFilterVisibility() {
    if (
      this.showPickupAndDelivery ||
      this.showWatchList ||
      this.filterModeRibbonValue?.length > 0 ||
      this.advancedFilterAdded.length > 0
    ) {
      this.showClearAllButton = true;
    } else {
      this.showClearAllButton = false;
      this.pickUpAndDeliveryToggle.emit(this.showPickupAndDelivery);
    }
  }

  closeModeRibonFilter() {
    let event = {
      type: 'transport',
      index: 0,
      id: 0,
      eventType: 'closeBtn',
      closeFrom: '',
    };
    this.filterModeRibbonValue = [];
    this.filterModeRibbonText = [];
    this.displayModeTooltipText = '';
    this.closeCustomFilters(event);
  }

  @Output() setToDefault = new EventEmitter();
  clearAllRibbonFilters(setToDefault: boolean) {
    this.clearAllFilters();
    this.filterModeRibbonValue = [];
    this.filterModeRibbonText = [];
    this.displayModeTooltipText = '';
    //this.closeModeRibonFilter();   not required here, getting added while code merge
    this.showPickupAndDelivery = false;
    this.showWatchList = false;
    this.showClearAllButton = false;
    this.isAdvanceFilterSelected = false;
    this.advancedFilterAdded = [];
    if (setToDefault) {
      this.setToDefaultShipmentLoad();
    }
  }

  setToDefaultShipmentLoad() {
    this.checkDefultFilter.emit(true);
    this.setToDefault.emit({
      name: 'All Shipments',
      value: 0,
    });
  }

  getTransportTypeName(value: number) {
    switch (value) {
      case 1: {
        return 'Sea';
      }
      case 2: {
        return 'Air';
      }
      case 3: {
        return 'Road';
      }
      case 4: {
        return 'Rail';
      }
      case 5: {
        return 'Courier';
      }
      default: {
        return null;
      }
    }
  }

  openOrCloseModeRibbonFilter(event: any, close?: boolean) {
    if (event.type == 'transport' && !close) {
      let selectedValue = event.selectedValue[0]?.value.split(',');
      this.filterModeRibbonValue = [];
      this.displayModeTooltipText = '';
      if (selectedValue) {
        let text: string = '';
        selectedValue?.forEach((e: any) => {
          text += this.getTransportTypeName(parseInt(e)) + ', ';
          this.filterModeRibbonValue.push(parseInt(e));
        });
        this.displayModeTooltipText = text.substring(0, text?.length - 2);
        this.showClearAllButton = true;
      }
    } else if (event.type == 'transport' && close) {
      this.filterModeRibbonValue = [];
      this.filterModeRibbonText = [];
      this.displayModeTooltipText = '';
      this.determineClearAllFilterVisibility();
    }
  }

  openOrCloseWatchListRibbonFilter(event: any, close?: boolean) {
    if (event.type == 'favorites' && !close) {
      this.showWatchList = true;
      this.showClearAllButton = true;
    } else if (event.type == 'favorites' && close) {
      this.showWatchList = false;
      this.determineClearAllFilterVisibility();
    }
  }


  getAdvancedFilterParam(event: any) {
    this.selectedFilterArray.emit(event);
    this.showClearAllButton = true;
    if (event.selectedValue?.length > 0) {
      this.isAdvanceFilterSelected = true;
    }
    else {
      this.isAdvanceFilterSelected = false;
    }
  }

  advanceFilterValue: any = [];
  openSaveAdvFilterPopUp(event: any) {
    this.closeAdvanceFilterPopup();
    this.advanceFilterValue = event;
    this.saveFilterJson = { ...this.saveFilterJson, advancedFilter: event };
    this.openSaveFilterPopUp();
  }

  callOnAdvFilterUpdate(event: any) {
    this.advanceFilterValue = event[1];
    this.saveFilterJson = { ...this.saveFilterJson, advancedFilter: event[1] };
    this.saveButtonClicked(event[0], event[2]);

  }

  openAdvanceFilterModal() {
    if (this.determineIsCustomFilterApplied()) {
      return;
    }
    else {
      this.showAdvancedFilterPopUp = true;
    }
  }

  closeAdvanceFilterPopup() {
    this.showAdvancedFilterPopUp = false;
  }

  showAdvFilterResetPopup: boolean = false;
  popupShow(event: any) {
    this.showAdvFilterResetPopup = false;
    if (event == 'reset') {
      this.showAdvFilterResetPopup = true;
      $('#advanced-filter-reset-popup').modal('show');
      $('.modal-backdrop').removeClass('modal-backdrop fade show');
    } else if (event == 'cancel') {
      this.showAdvancedFilterCancelModal = true;
    } else if (event == 'close') {
      this.closeAdvanceFilterPopup();
    } else if (event == 'resetted-completely') {
      // calling the list back to default state while resetted completely
      this.setToDefault.emit({
        name: 'All Shipments',
        value: 0,
      });
    }
  }

  closeAdvFilterReset(event: any) {
    this.showAdvFilterResetPopup = false;
  }

  @ViewChild(AdvancedFilterTabComponent)
  advancedFilterTabComponent!: AdvancedFilterTabComponent;
  resetAdvFilter() {
    this.advancedFilterTabComponent?.onReset();
    this.showAdvFilterResetPopup = false;
    this.isAdvanceFilterSelected = false;
  }

  closeAdvancedFilterOnConfirmation() {
    this.closeAdvanceFilterPopup();
    this.showAdvancedFilterCancelModal = false;
    this.resetAdvFilter();
  }

  // #region user_view_preference
  userViewPreference: UserViewPreference = {
    oH_Code: '',
    groupId: '',
    shipmentListViewPreference: UserTableViewPreference.Standard.toString(),
    bookingListViewPreference: UserTableViewPreference.Standard.toString(),
    customsListViewPreference: UserTableViewPreference.Standard.toString(),
  };

  updateUserViewPreference(isTableViewSelected: boolean) {
    this.userViewPreference.shipmentListViewPreference = isTableViewSelected
      ? UserTableViewPreference.Table.toString()
      : UserTableViewPreference.Standard.toString();

    this.commonUserService
      .updateUserViewPreference(this.userViewPreference)
      .subscribe((res: any) => {
        if (res.result) {
          // this.showAndAutoClearToasterMessage.emit({
          //   message: 'Your wiew preference has been updated',
          //   timeout: 3000,
          // });
        }
      });
  }

  getUserViewPreference() {
    this.commonUserService
      .getUserViewPreference(this.accountID, this.isGroup)
      .subscribe((res: any) => {
        if (res.result) {
          this.userViewPreference = res.result;
          this.isTableView =
            this.userViewPreference.shipmentListViewPreference ==
            UserTableViewPreference.Table.toString();
        }
        this.userViewPreference.oH_Code = this.isGroup ? '' : this.accountID;
        this.userViewPreference.groupId = this.isGroup ? this.accountID : '';
        this.isTableViewSelected.emit(this.isTableView);
      });
  }
  // #endregion  user_view_preference
  // check which filter is applied currently [adv/custom]
  determineIsCustomFilterApplied() {
    if (this.selectedMainFilter?.length) { // custom filter is applied
      this.callSwitchFilterWarningPopup.emit({ type: "custom", remarks: "custom is applied" });
      return true;
    }
    return false;
  }

  determineIsAdvancedFilterApplied(appliedFilter?: any) {
    if ((this.advancedFilterAdded?.length > 0 ||
      this.isAdvanceFilterSelected)
      && (!this.shipmentService.advancedFilterSavedRecently)) { // adv filter is applied
      this.callSwitchFilterWarningPopup.emit({ type: "adv", remarks: "adv is applied" });
      return true;
    }
    this.shipmentService.advancedFilterSavedRecently = false;
    return false;
  }

  requiredProps(item: any) {
    if (JSON.stringify(item) === "{}" || JSON.stringify(item) === "[]" || !item) {
      return item;
    }
    let currentItem = [...JSON.parse(JSON.stringify(item))];
    let newItem: any = [];
    currentItem?.forEach((e: any) => {
      e.conditions?.forEach((f: any) => {
        newItem.push(f.columnDisplayName, f.columnDisplayName, f.column_name, f.controlTypeName
          , f.dataType, f.elementType, f.operator, f.order, f.value)
      });
    });
    return newItem;
  }

  isAppliedFilterAndAdvFilterSame(appliedFilter: any) {
    if (JSON.stringify(this.requiredProps(this.shipmentService.backupOfAdvFilterCurrentData)) ===
      JSON.stringify(this.requiredProps(appliedFilter))) {
      return true;
    }
    else {
      return false;
    }
  }

  closeAdvFilterForm(event: any) {
    const target = event?.target as HTMLElement;
    let parentDiv: HTMLElement | null = target;
    while (parentDiv && parentDiv?.id !== "advanced-filter-reset-popup") {
      parentDiv = parentDiv?.parentElement;
    }
    if (event?.target?.id != "adv-open-popup-btn" && event?.target?.parentElement?.className != "range-selection-area"
      && event?.target?.className != "list-sec" && event?.target?.id != "select-btn1" && event?.target?.id != "select-btn1"
      && event?.target?.alt != "del" && event?.target?.id != "filter-proceed-btn" && !parentDiv) {
      this.advancedFilterTabComponent.closeAdvFilterForm();
    }
  }

  openCustomDropdownButton() {
    this.customDropdownButton.nativeElement.click();
  }
}

export class UserViewPreference {
  oH_Code: any;
  groupId: any;
  shipmentListViewPreference: any;
  bookingListViewPreference: any;
  customsListViewPreference: any;
}
