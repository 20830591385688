import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ShipmentComponent } from './container/shipment/shipment.component';
import { ShipmentRoutingModule } from './shipment-routing.module';
import { ShipmentListComponent } from './components/shipment-list/shipment-list.component';
import { ShipmentCustomFiltersComponent } from './components/shipment-custom-filters/shipment-custom-filters.component';
import { ShipmentServies } from './services/shipment.service';
import { UserManagmentService } from '../admin/users/services/usermanagment.service';
import {
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DaterangeFilterComponent } from './components/daterange-filter/daterange-filter.component';
import { ShipmentDetailComponent } from './components/shipment-detail/shipment-detail.component';
import { ShipmentDetailTabComponent } from './components/shipment-detail-tab/shipment-detail-tab.component';
import { ShipmentCustomsTabComponent } from './components/shipment-customs-tab/shipment-customs-tab.component';
import { ShipmentDocumentsTabComponent } from './components/shipment-documents-tab/shipment-documents-tab.component';
import { ShipmentContainersTabComponent } from './components/shipment-containers-tab/shipment-containers-tab.component';
import { ShipmentHeaderComponent } from './components/shipment-header/shipment-header.component';
import { HandleFileNamePipe } from './common/handle-file-name.pipe';
import { ViewAllShipmentsComponent } from './components/view-all-shipments/view-all-shipments.component';
import { ShipmenListServies } from './services/shipment.list.service';
import { SucessToastComponent } from './common/sucess-toast/sucess-toast.component';
import { AllLocationFilterComponent } from './components/all-location-filter/all-location-filter.component';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { BookingModule } from '../booking/booking.module';
import { CustomsModule } from '../customs/customs.module';
import { ShipmentSearchComponent } from './components/shipment-search/shipment-search.component';
import { ActionDetailTabComponent } from './components/action-detail-tab/action-detail-tab.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActionFieldService } from '../admin/actionfield/services/action-field.service';
import { AccountServies } from '../admin/accounts/services/account.service';
import { ContainerListPipe } from './common/container-list.pipe';
import { AdvancedFilterTabComponent } from './components/advanced-filter-tab/advanced-filter-tab.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdvFilterValueControlComponent } from './components/adv-filter-value-control/adv-filter-value-control.component';
@NgModule({
  declarations: [
    ShipmentComponent,
    ShipmentListComponent,
    ShipmentListComponent,
    ShipmentCustomFiltersComponent,
    DaterangeFilterComponent,
    ShipmentDetailComponent,
    ShipmentDetailTabComponent,
    ShipmentCustomsTabComponent,
    ShipmentDocumentsTabComponent,
    ShipmentContainersTabComponent,
    ShipmentHeaderComponent,
    HandleFileNamePipe,
    ContainerListPipe,
    ViewAllShipmentsComponent,
    SucessToastComponent,
    AllLocationFilterComponent,
    ShipmentSearchComponent,
    ActionDetailTabComponent,
    AdvancedFilterTabComponent,
    AdvFilterValueControlComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ShipmentRoutingModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    InfiniteScrollModule,
    BookingModule,
    CustomsModule,
    AutocompleteLibModule,
    DragDropModule,
    MatCheckboxModule,
    NgSelectModule,
  ],
  exports: [ShipmentListComponent, ShipmentComponent, ShipmentDetailComponent],
  providers: [
    DatePipe,
    ShipmentServies,
    UserManagmentService,
    ShipmenListServies,
    TitleCasePipe,
    AppInsightsService,
    AccountServies,
    ActionFieldService,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShipmentModule {}
