import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipToDetails'
})
export class ShipToDetailsPipe implements PipeTransform {

  transform(data: unknown): unknown {
    return CommonUtils.getShipToDetails(data);
  }

}
