import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppInsightsService } from './appinsights.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) { }

  handleError(error: any): void {
    this.injector.get<AppInsightsService>(AppInsightsService).logError(error);
    // console.log(error);
  }
}
