import { UUID } from 'angular2-uuid';
import {
  ProgressBar,
  ProgressBarLegType,
  ProgressBarType,
} from './shipment.enums';

export class CommonUtils {
  static month_array = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  static getExceptionClass(status: any, exception: any, module?: string) {
    let className = 'blue';
    if (!status || status.toLowerCase() === 'delivered' || !exception)
      return className;

    //For bookings
    if (module == "bookings") {
      if (exception.toLowerCase() == 'archived') {
        return 'gray';
      }
      return className;
    }
    // For custom
    if (module == 'customs') {
      if (exception.toLowerCase() == 'on hold' && status != 'Released') {
        return 'red';
      } else if (exception.toLowerCase() == 'delayed' && status != 'Released') {
        return 'orange';
      }
    }
    // For Shipments
    switch (exception.toLowerCase()) {
      case 'expected delay': // For shipment
        className = 'orange';
        break;
      case 'delayed': // For shipment
        className = 'red';
        break;
      case 'archived': // For bookings
        className = 'gray';
        break;
    }
    return className;
  }

  static getShipmentReferenceNumbers(order: any, limit: number) {
    if (!order) return '---';
    let message = order.map((s: any) => s.customer_order_number).join(', ');
    if (limit > 0 && message.length > limit) {
      return message.substring(0, limit - 3) + '...';
    }
    return message;
  }

  static getCustomReferenceNumbers(order: any, limit: number) {
    if (!order) return '';
    let message = order.map((s: any) => s.customer_order_number).join(', ');
    if (limit > 0 && message.length > limit) {
      return message.substring(0, limit - 3) + '...';
    }
    return message;
  }

  static getDocumentFileName(order: any, limit: number, ext: any) {
    if (!order) return '';
    if (limit > 0 && order.length > limit) {
      return order.substring(0, limit - 1) + '...' + ext;
    }
    return order + '.' + ext;
  }

  static getMonthIn3CharName(month: number) {
    return this.month_array[month];
  }

  static formatDateMMMDDYYYY(dateString: string) {
    if (!dateString) return '---';

    let strArray = this.splitDateString(dateString.toString());
    let year = parseInt(strArray[0]);
    let month = parseInt(strArray[1]);
    let day = parseInt(strArray[2]);
    return this.month_array[month - 1] + ' ' + this.padding2(day) + ', ' + year;
  }

  static getScheduledHintsText(data: any) {
    let displayUnloco = '';
    let statusHintText = '';

    if (data?.shipment_legs) {
      let shipment_legs = this.sortShipmentLegsBySequenceNumber(data);

      let min_leg = shipment_legs[0];
      displayUnloco = min_leg?.lading_port?.unlocode;

      statusHintText =
        'Scheduled to depart from ' +
        displayUnloco +
        ' - ETD: ' +
        this.formatDateMMMDDYYYY(min_leg?.departure_estimated);
    }
    return statusHintText;
  }

  static sortShipmentLegsBySequenceNumber(data: any) {
    if (!data.shipment_legs) return null;

    return data.shipment_legs.sort(function (a: any, b: any) {
      return a.leg_sequence_number - b.leg_sequence_number;
    });
  }

  static getInTransitHintsText(data: any) {
    let displayUnloco = '';
    let statusHintText = '';

    if (data?.shipment_legs) {
      let arrival_estimated = '';
      let leg_count = data.shipment_legs.length - 1;

      for (var i = leg_count; i >= 0; i--) {
        displayUnloco = data.shipment_legs[i].lading_port?.unlocode;
        arrival_estimated = data.shipment_legs[i].arrival_estimated;
        if (
          (data.shipment_legs[i].departure_actual &&
            !data.shipment_legs[i].arrival_actual) ||
          (i > 0 &&
            !data.shipment_legs[i].departure_actual &&
            data.shipment_legs[i - 1].arrival_actual)
        ) {
          displayUnloco = data.shipment_legs[i].arrival_port?.unlocode;
          break;
        }
      }

      statusHintText =
        'In transit to ' +
        displayUnloco +
        ' - ETA: ' +
        this.formatDateMMMDDYYYY(arrival_estimated);
    }
    return statusHintText;
  }

  static getArrivedHintsText(data: any) {
    let statusHintText = '';
    if (data?.shipment_legs) {
      let max_leg = data.shipment_legs[data.shipment_legs.length - 1];
      statusHintText =
        'Arrived at destination port - ' +
        this.formatDateMMMDDYYYY(max_leg?.arrival_actual);
    }
    return statusHintText;
  }

  static getStatusHint(data: any) {
    let statusHintText = '';

    switch (data?.status?.toLowerCase()) {
      case 'pickup scheduled':
        statusHintText = 'Pending pickup';
        break;
      case 'picked up':
        statusHintText = 'Pickup completed';
        break;
      case 'scheduled': {
        statusHintText = this.getScheduledHintsText(data);
        break;
      }
      case 'in transit': {
        statusHintText = this.getInTransitHintsText(data);
        break;
      }
      case 'arrived':
        statusHintText = this.getArrivedHintsText(data);
        break;
      case 'delivery scheduled':
        statusHintText =
          'Scheduled to final destination - ' +
          this.formatDateMMMDDYYYY(data?.delivery_estimated);
        break;
      case 'delivered':
        statusHintText =
          'Delivered at final destination - ' +
          this.formatDateMMMDDYYYY(data?.delivery_actual);
        break;
    }

    if (
      data?.exception &&
      data.exception.toLowerCase() !== 'no exception' &&
      data?.status.toLowerCase() !== 'delivered'
    ) {
      statusHintText = data.exception + ' ' + statusHintText;
    }
    return statusHintText;
  }

  static toTitleCase = (str: string) => {
    if (!str) return str;

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  static toCamelCase(text: string) {
    if (!text) return text;
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  }

  static setPageTitle(titleService: any, pageTitle: string) {
    titleService.setTitle(pageTitle + ' - OIA Connect');
  }

  static removeLastComma(str: string) {
    if (!str) return '';
    return str.replace(/,(\s+)?$/, '');
  }

  static generateProgressBar(
    shipment: any,
    togglePickupDeliveryLegs: boolean = false,
    searchText: string = ''
  ) {
    let htmlString = '';
    if (!shipment) return htmlString;

    let pb = [];

    let pickup_leg: ProgressBar = new ProgressBar();
    pickup_leg.isTogglePickupDeliveryLegs = togglePickupDeliveryLegs;
    pickup_leg.progressBarType = ProgressBarLegType.PickUpLeg;
    pickup_leg.status = shipment.status?.toLowerCase();
    pickup_leg.isCurrentLeg = false;
    pickup_leg.portToolTipText = this.getPortAndCountryToolTipText(
      this.toTitleCase(shipment.ship_from?.portName),
      '',
      shipment.ship_from?.country,
      '',
      searchText
    );
    pickup_leg.statusToolTipText = this.getStatusToolTipText(
      'Picked up',
      'Est Pickup',
      shipment.origin_pickup_actual,
      shipment.origin_pickup_estimated,
      ProgressBarType.ShipmentListingPage
    );

    let delivery_leg: ProgressBar = new ProgressBar();
    delivery_leg.isTogglePickupDeliveryLegs = togglePickupDeliveryLegs;
    delivery_leg.progressBarType = ProgressBarLegType.PickUpLeg;
    delivery_leg.status = shipment.status?.toLowerCase();
    delivery_leg.isCurrentLeg = false;
    delivery_leg.portToolTipText = this.getPortAndCountryToolTipText(
      this.toTitleCase(shipment.ship_to?.portName),
      '',
      shipment.ship_to?.country,
      '',
      searchText
    );
    delivery_leg.statusToolTipText = this.getStatusToolTipText(
      'Delivered',
      'Est Delivery',
      shipment.delivery_actual,
      shipment.delivery_estimated,
      ProgressBarType.ShipmentListingPage
    );

    pb.push(pickup_leg);
    pb.push(delivery_leg);

    if (togglePickupDeliveryLegs) return htmlString;
    return pb;
  }

  static highlightSearchText(value: any, args: any) {
    if (!args) {
      return value;
    }
    let arr = args
      ?.trim()
      .split(',')
      .map(function (item: any) {
        return item.trim();
      });

    let re = new RegExp(arr.join('|'), 'gi');

    let replacedValue = '';
    replacedValue += value.replace(
      re,
      (x: any) => '<mark class="mark-highlight">' + x + '</mark>'
    );
    return replacedValue;
  }

  static getPortAndCountryToolTipText(
    city: any,
    state: any,
    country: any,
    country_name: any,
    searchText: string
  ) {
    var message = '';
    message +=
      '<h6 class="m-0">' +
      (city
        ? this.highlightSearchText(this.toTitleCase(city), searchText) + ', '
        : '') +
      CommonUtils.getUSCanadaStateText(country_name, state) +
      (country ? this.highlightSearchText(country, searchText) : '') +
      '</h6>';
    return message;
  }

  static getStatusToolTipText(
    actual_status_name: string,
    estimated_status_name: string,
    actual: any,
    estimated: any,
    progressBarType: ProgressBarType
  ) {
    var message = '';
    var dateToDisplay = '---';
    if (actual) {
      dateToDisplay =
        progressBarType == ProgressBarType.ShipmentContainerTab
          ? this.formatDateMMMDDYYYY(actual)
          : this.formatDateMMMDD(actual);
      message +=
        '<p>' +
        actual_status_name +
        ': ' +
        (actual ? dateToDisplay : '---') +
        '</p>';
    } else {
      if (estimated) {
        dateToDisplay =
          progressBarType == ProgressBarType.ShipmentContainerTab
            ? this.formatDateMMMDDYYYY(estimated)
            : this.formatDateMMMDD(estimated);
      }
      message +=
        '<p>' +
        estimated_status_name +
        ': ' +
        (estimated ? dateToDisplay : '---') +
        '</p>';
    }
    return message;
  }

  static getPickupToolTipText(
    data: any,
    searchText: string,
    progressBarType: ProgressBarType
  ) {
    if (progressBarType === ProgressBarType.ShipmentListingPage) {
      var start_leg_text = '<div class="rap">';
      start_leg_text += this.getPortAndCountryToolTipText(
        this.toTitleCase(data?.ship_from?.city),
        data?.ship_from?.state,
        data?.ship_from?.country,
        data?.ship_from?.country_name,
        searchText
      );
      start_leg_text += this.getStatusToolTipText(
        'Picked up',
        'Est Pickup',
        data?.origin_pickup_actual,
        data?.origin_pickup_estimated,
        progressBarType
      );
      start_leg_text += '</div>';

      return start_leg_text;
    }
    if (progressBarType === ProgressBarType.ShipmentDetailPage) {
      var message = '<div class="rap">';
      message += '<h6 class="title">Pickup From</h6>';
      message += this.getShipFromPortCountryDetail(data);
      if (data.isFullViewAccess) {
        message += this.getShipFromAddress(data);
      }
      message += this.getShipFromToolTips(data);
      message += this.getShipFromRecepitDate(data);
      message += '</div>';
      return message;
    }
    return null;
  }

  static getYearFormattedDate(date: any) {
    return this.formatDateMMMDDYYYY(date);
  }

  static getShipFromRecepitDate(data: any) {
    var message = '';
    if (data?.cfs_receipt_date) {
      message += '<div *ngIf="">';
      message += '<span>';
      message += '<h6 class="d-inline-block">On Hand Date:&nbsp;</h6>';
      message += this.getYearFormattedDate(data.cfs_receipt_date);
      message += '</span>';
      message += '</div>';
    }
    return message;
  }

  static getTransportIcon(mode: any) {
    var message = '';

    message =
      '<img src="' + this.getTransportModeImagePath(mode) + '" alt=""/>';

    return message;
  }

  static getTransportModeImagePath(mode: any) {
    let imageName = '';
    switch (mode.toUpperCase()) {
      case 'SEA':
        imageName = 'ship_progress';
        break;
      case 'ROAD':
      case 'ROA':
      case 'TRK':
        imageName = 'truck_progress';
        break;
      case 'AIR':
        imageName = 'air_progress';
        break;
      case 'RAIL':
      case 'RAI':
        imageName = 'rail_progress';
        break;
      case 'COURIER':
      case 'COU':
        imageName = 'courier_progress';
        break;
      case 'SHIPMENT-INTRANSIT':
        imageName = 'blank';
        break;
    }

    return '/assets/img/shipments/' + imageName + '.svg';
  }

  static getShipFromToolTips(data: any) {
    var message = '';

    message += '<div class="mt-3 update-change">';
    message += '<span class="display-sec">';
    message += '<h6 class="d-inline-block pr-1">Estimated Pickup: </h6>';
    message += this.getYearFormattedDate(data?.origin_pickup_estimated);
    message += '</span>';
    message += '<span class="display-sec">';
    message += '<h6 class="d-inline-block pr-1">Actual Pickup: </h6>';
    message += this.getYearFormattedDate(data?.origin_pickup_actual);
    message += '</span>';
    message += '</div>';

    return message;
  }

  static getShipFromAddress(data: any) {
    var message = '';
    message += '<p class="address-sec">';
    message += '<span class="carrier-name" >';
    message += data?.ship_from?.name ? data.ship_from.name + ', ' : '';
    message += '</span> ';
    message += data?.ship_from?.address_1
      ? this.toTitleCase(data.ship_from.address_1) + ', '
      : '';
    message += data?.ship_from?.address_2
      ? this.toTitleCase(data.ship_from.address_2) + ', '
      : '';
    message += data?.ship_from?.city
      ? this.toTitleCase(data.ship_from.city) + ', '
      : '';
    message += CommonUtils.getUSCanadaStateText(
      data?.ship_from?.country_name,
      data?.ship_from?.state
    );
    message += data?.ship_from?.country ? data.ship_from.country + ', ' : '';
    message += data?.ship_from?.postal_code ? data.ship_from.postal_code : '';
    message = this.removeLastComma(message);
    message += '</p>';

    return message.replace(',, ', ', ');
  }

  static getShipFromPortCountryDetail(data: any) {
    var message = '';
    if (!data?.ship_from) return message;

    message += '<p class="country">';
    message += data.ship_from.city
      ? this.toTitleCase(data.ship_from.city) + ', '
      : '';
    message += CommonUtils.getUSCanadaStateText(
      data?.ship_from?.country_name,
      data?.ship_from?.state
    );
    message += data.ship_from.country_name
      ? this.toTitleCase(data.ship_from.country_name)
      : '';
    message += '</p>';
    return message;
  }

  static splitDateString(str: string) {
    return str.split('-').map((item) => item.trim());
  }

  static splitTimeString(str: string) {
    return str.split(':').map((item) => item.trim());
  }

  static getShipToPortCountryDetail(data: any) {
    var message = '';
    if (!data?.ship_to) return message;

    message += '<p class="country">';

    message += data.ship_to.city
      ? this.toTitleCase(data.ship_to.city) + ', '
      : '';
    message += CommonUtils.getUSCanadaStateText(
      data?.ship_to?.country_name,
      data?.ship_to?.state
    );
    message += data.ship_to.country_name
      ? this.toTitleCase(data.ship_to.country_name)
      : '';
    message += '</p>';

    return message;
  }

  static getShipToToolTips(data: any) {
    var message = '';
    message += '<div class="mt-3 update-change">';
    message += '<span class="display-sec">';
    message += '<h6 class="d-inline-block pr-1">Estimated Delivery:</h6>';
    message += data?.delivery_estimated
      ? this.getYearFormattedDate(data?.delivery_estimated)
      : '---';
    message += '</span>';

    message += '<span class="display-sec">';
    message += '<h6 class="d-inline-block pr-1">Actual Delivery:</h6>';
    message += data?.delivery_actual
      ? this.getYearFormattedDate(data?.delivery_actual)
      : '---';
    message += '</span>';
    message += '</div>';
    return message;
  }

  static getShipToDetails(data: any) {
    let message = '';
    let publicViewClass = data?.isFullViewAccess ? '' : 'd-none';
    if (data?.ship_to) {
      message += '<p class="address-sec ' + publicViewClass + '">';
      message += data.ship_to.name ? data.ship_to.name + ',  ' : '';
      message += data.ship_to.address_1
        ? this.toTitleCase(data.ship_to.address_1) + ', '
        : '';
      message += data.ship_to.address_2
        ? this.toTitleCase(data.ship_to.address_2) + ', '
        : '';
      message += data.ship_to.city
        ? this.toTitleCase(data.ship_to.city) + ', '
        : '';
      message += CommonUtils.getUSCanadaStateText(
        data?.ship_to?.country_name,
        data?.ship_to?.state
      );
      message += data.ship_to.country ? data.ship_to.country + ', ' : '';
      message += data.ship_to.postal_code ? data.ship_to.postal_code : '';
      message = this.removeLastComma(message);
      message += '</p>';
    }
    return message;
  }

  static getDeliveryToolTipText(
    data: any,
    searchText: string,
    progressBarType: ProgressBarType
  ): string {
    if (progressBarType === ProgressBarType.ShipmentDetailPage) {
      let message = '';
      message += '<div class="rap">';
      message += '<h6 class="pt-2">Deliver To</h6>';

      message += this.getShipToPortCountryDetail(data);
      message += this.getShipToDetails(data);
      message += this.getShipToToolTips(data);
      message += '</div>';
      message = message.replace(',, ', ', ');
      return message;
    }
    if (
      progressBarType === ProgressBarType.ShipmentListingPage ||
      progressBarType === ProgressBarType.ShipmentContainerTab
    ) {
      let final_leg_text = '<div class="rap">';

      final_leg_text += this.getPortAndCountryToolTipText(
        this.toTitleCase(data?.ship_to?.city),
        data?.ship_to?.state,
        data?.ship_to?.country,
        data?.ship_to?.country_name,
        searchText
      );

      final_leg_text += this.getStatusToolTipText(
        'Delivered',
        'Est Delivery',
        data?.delivery_actual,
        data?.delivery_estimated,
        progressBarType
      );

      final_leg_text += '</div>';
      return final_leg_text;
    }
    return '';
  }

  static isFutureDate(actualDate: string): boolean {
    if (!actualDate || actualDate.length < 10) return false;
    let current_date = new Date();

    let cdate = new Date(
      current_date.getFullYear(),
      current_date.getMonth(),
      current_date.getDate(),
      current_date.getHours(),
      current_date.getMinutes(),
      current_date.getSeconds()
    );

    let strDateArray = this.splitDateString(actualDate?.substring(0, 10));
    let strTimeArray = this.splitTimeString(actualDate?.substring(11, 19));
    let year = parseInt(strDateArray[0]);
    let month = parseInt(strDateArray[1]);
    let day = parseInt(strDateArray[2]);
    let hours = parseInt(strTimeArray[0]);
    let minutes = parseInt(strTimeArray[1]);
    let seconds = parseInt(strTimeArray[2]);

    let adate = new Date(year, month - 1, day, hours, minutes, seconds);
    if (adate.valueOf() > cdate.valueOf()) {
      return true;
    }
    return false;
  }

  static getVarianceCss(
    i: number,
    data: any,
    arrival_estimated_date: any,
    current_date: any
  ) {
    let departure_actual_date = new Date(
      data.shipment_legs[i].departure_actual
    );
    let portion = this.dateDiffInDays(
      arrival_estimated_date,
      departure_actual_date
    );

    let variance = this.dateDiffInDays(current_date, departure_actual_date);

    let percentage = (variance / portion) * 100;

    var percentage_css = '';
    var round_per = Math.round(percentage);
    if (round_per > 0 && round_per <= 20) {
      percentage_css = '20';
    } else if (round_per > 20 && round_per <= 40) {
      percentage_css = '40';
    } else if (round_per > 40 && round_per <= 60) {
      percentage_css = '60';
    } else {
      percentage_css = '80';
    }

    return percentage_css;
  }

  static dateDiffInDays(a: Date, b: Date) {
    if (!a || !b) return 0;
    var difference_In_Time = a.getTime() - b.getTime();

    return difference_In_Time / (1000 * 3600 * 24);
  }

  static full_All_ProgressItems(pbItems: any) {
    for (const obj of pbItems) {
      obj.isCompletedLeg = true;
      obj.css = 'progtrckr-done';
    }
    return pbItems;
  }

  static fill_80_ProgressItems(pbItems: any) {
    for (var i = 0; i < pbItems.length - 1; i++) {
      if (i === pbItems.length - 2) {
        pbItems[i].isCurrentLeg = true;
        pbItems[i].isVarianceLeg = true;
        pbItems[i].css = 'progtrckr-todo variance-80';
      } else if (i < pbItems.length - 2) {
        pbItems[i].isCompletedLeg = true;
        pbItems[i].css = 'progtrckr-done';
      }
    }
    return pbItems;
  }

  static fill_upto_current_legs(pbItems: any, i: number) {
    for (let j = 0; j <= i; j++) {
      pbItems[j].isCompletedLeg = true;
      pbItems[j].css = 'progtrckr-done';
      if (j == i) {
        pbItems[j + 1].isCompletedLeg = true;
        pbItems[j + 1].css = 'progtrckr-done';
      }
    }
    return pbItems;
  }

  static fill_variance_upto_current_leg(
    pbItems: any,
    i: number,
    percentage_css: string
  ) {
    pbItems[i + 1].isCurrentLeg = true;
    pbItems[i + 1].isVarianceLeg = true;
    pbItems[i + 1].css = 'progtrckr-todo variance-' + percentage_css;

    for (let j = 0; j <= i; j++) {
      pbItems[j].isCompletedLeg = true;
      pbItems[j].css = 'progtrckr-done';
    }
    return pbItems;
  }

  static fill_previous_leg_variance(pbItems: any, i: number) {
    pbItems[i + 1].isCurrentLeg = true;
    pbItems[i + 1].isVarianceLeg = true;
    pbItems[i + 1].css = 'progtrckr-todo variance-80';

    for (let j = 0; j <= i; j++) {
      pbItems[j].isCompletedLeg = true;
      pbItems[j].css = 'progtrckr-done';
    }
    return pbItems;
  }

  static isFutureDatedShipmentLegs(data: any, leg_count: number) {
    return (
      (leg_count > 0 &&
        data.delivery_actual &&
        this.isFutureDate(data.delivery_actual) &&
        data.shipment_legs[leg_count - 1].arrival_actual &&
        this.isFutureDate(data.shipment_legs[leg_count - 1].arrival_actual)) ||
      (leg_count >= 0 &&
        data.delivery_actual &&
        this.isFutureDate(data.delivery_actual))
    );
  }

  static fill_legs_in_reverse_order(data: any, pbItems: any, leg_count: any) {
    for (var i = leg_count - 1; i >= 0; i--) {
      if (data.shipment_legs[i].arrival_actual) {
        return this.fill_upto_current_legs(pbItems, i);
      } else if (data.shipment_legs[i].departure_actual) {
        let current_date = new Date();

        if (data.shipment_legs[i].arrival_estimated) {
          var arrival_estimated_date = new Date(
            data.shipment_legs[i].arrival_estimated
          );
          if (arrival_estimated_date <= current_date) {
            return this.fill_previous_leg_variance(pbItems, i);
          } else {
            var percentage_css = this.getVarianceCss(
              i,
              data,
              arrival_estimated_date,
              current_date
            );
            return this.fill_variance_upto_current_leg(
              pbItems,
              i,
              percentage_css
            );
          }
        }
      }
    }
    return pbItems;
  }

  static fillLegProgress(pbItems: any, data: any, container: any) {
    if (!pbItems) return pbItems;

    if (
      (data.delivery_actual && !this.isFutureDate(data.delivery_actual)) ||
      container?.arrival_delivery_actual
    ) {
      return this.full_All_ProgressItems(pbItems);
    }

    var leg_count = data.shipment_legs?.length;
    if (this.isFutureDatedShipmentLegs(data, leg_count)) {
      return this.fill_80_ProgressItems(pbItems);
    }
    if (!data.shipment_legs) return pbItems;

    return this.fill_legs_in_reverse_order(data, pbItems, leg_count);
  }

  static getProgressBarLayout(
    data: any,
    progressBarType: any,
    togglePickupDeliveryLegs: boolean
  ): string {
    if (!data) return '';
    var total_legs = 0;
    if (data.shipment_legs) {
      total_legs = data.shipment_legs.length;
    }
    var moreThan2NodeCss = '';
    if (total_legs >= 3) {
      moreThan2NodeCss = ' node-2 ';
    }
    var htmlString: string = '';
    if (progressBarType === ProgressBarType.ShipmentListingPage) {
      var pb_spl = ' leg-' + total_legs;
      if (togglePickupDeliveryLegs) {
        htmlString = '<ul class="timeline pb-active' + pb_spl + '">';
      } else {
        htmlString = '<ul class="timeline' + pb_spl + '">';
      }
    } else if (progressBarType === ProgressBarType.ShipmentContainerTab) {
      htmlString =
        '<ul class="timeline-details container-leg-count-' +
        total_legs +
        moreThan2NodeCss +
        this.getCssForPickUpAndDeliveryContainer(data) +
        '">';
    }
    return htmlString;
  }

  static getEstAstToolTip(
    date: any,
    title: string,
    progressBarType: ProgressBarType
  ) {
    var message = '';
    var dateToDisplay =
      progressBarType == ProgressBarType.ShipmentContainerTab
        ? this.formatDateMMMDDYYYY(date)
        : this.formatDateMMMDD(date);

    if (date) {
      message += '<p class="country">' + title + ': ' + dateToDisplay + '</p>';
    } else {
      message +=
        '<p class="country">' +
        title +
        ': ---&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>';
    }
    return message;
  }

  static getFirstShipmentLeg(data: any) {
    var shipment_legs = this.sortShipmentLegsBySequenceNumber(data);
    if (shipment_legs) return shipment_legs[0];
    return null;
  }

  static getLastShipmentLeg(data: any) {
    var shipment_legs = this.sortShipmentLegsBySequenceNumber(data);
    if (shipment_legs) return shipment_legs[shipment_legs.length - 1];
    return null;
  }

  static getShipmentContainerProgressBarPickupCss(
    container: any,
    total_legs: number
  ) {
    var htmlString = '';
    var start_leg_text = '';
    start_leg_text += '<h6>Pickup</h6>';

    start_leg_text += this.getEstAstToolTip(
      container.origin_pickup_estimated,
      'Est',
      ProgressBarType.ShipmentContainerTab
    );
    start_leg_text += this.getEstAstToolTip(
      container.origin_pickup_actual,
      'Act',
      ProgressBarType.ShipmentContainerTab
    );

    var pb_css =
      container.origin_pickup_actual ||
        total_legs > 0 ||
        container.arrival_delivery_actual
        ? 'progtrckr-done blue'
        : 'progtrckr-todo';

    htmlString +=
      '<li class ="' +
      pb_css +
      '"><div class="rap">' +
      start_leg_text +
      '</div></li>';

    return htmlString;
  }

  static getShipmentContainerProgressBarDeliveryCss(container: any) {
    var htmlString = '';
    var start_leg_text = '';
    start_leg_text += '<h6>Delivered</h6>';
    start_leg_text += this.getEstAstToolTip(
      container.arrival_delivery_estimated,
      'Est',
      ProgressBarType.ShipmentContainerTab
    );
    start_leg_text += this.getEstAstToolTip(
      container.arrival_delivery_actual,
      'Act',
      ProgressBarType.ShipmentContainerTab
    );

    var pb_css = container.arrival_delivery_actual
      ? 'progtrckr-done'
      : 'progtrckr-todo';

    htmlString +=
      '<li class ="' +
      pb_css +
      '"><div class="rap">' +
      start_leg_text +
      '</div></li>';

    return htmlString;
  }

  static getShipmentListingProgressBarPickupCss(
    data: any,
    exception_css: string
  ) {
    var css = '';
    if (
      data.status?.toLowerCase() === 'pending info' ||
      data.status?.toLowerCase() === 'pickup scheduled' ||
      !data.origin_pickup_actual
    ) {
      css = 'progtrckr-todo';
    } else if (data.shipment_legs && data.shipment_legs.length > 0) {
      css = 'progtrckr-done ' + exception_css;
    } else if (
      data.status?.toLowerCase() === 'Picked up' ||
      (data.origin_pickup_actual && !data.shipment_legs)
    ) {
      css = 'progtrckr-todo variance-80 ' + exception_css;
    } else {
      css = 'progtrckr-done  ' + exception_css;
    }
    return css;
  }

  static fillPickupLegDetail(
    data: any,
    progressBarType: ProgressBarType,
    container: any,
    exception_css: string,
    togglePickupDeliveryLegs: boolean,
    searchText: string
  ): any {
    var css = '';
    if (
      progressBarType === ProgressBarType.ShipmentListingPage ||
      progressBarType === ProgressBarType.ShipmentDetailPage
    ) {
      css = this.getShipmentListingProgressBarPickupCss(data, exception_css);
      return {
        type_of_leg: ProgressBarLegType.PickUpLeg,
        showPickupDeliveryLegs: togglePickupDeliveryLegs,
        leg_sequence_number: null,
        actual_value: null,
        toolTipText: this.getPickupToolTipText(
          data,
          searchText,
          progressBarType
        ),
        isCompletedLeg: css === 'progtrckr-done ' + exception_css,
        isCurrentLeg: false,
        isVarianceLeg: false,
        css: css,
        hasLegData: true,
        transportImageMode: 'ROAD',
      };
    }
    if (progressBarType === ProgressBarType.ShipmentContainerTab) {
      var hasPickupContainer = true;
      var toolTipText = '';
      if (
        !container.origin_pickup_actual &&
        !container.origin_pickup_estimated
      ) {
        hasPickupContainer = false;
      } else {
        toolTipText = this.getContainerPickupLegDisplayData(
          container,
          data.shipment_legs.length
        );
      }
      return {
        type_of_leg: ProgressBarLegType.ContainerPickupLeg,
        showPickupDeliveryLegs: togglePickupDeliveryLegs,
        leg_sequence_number: null,
        actual_value: null,
        toolTipText: toolTipText,
        isCompletedLeg: false,
        isCurrentLeg: false,
        isVarianceLeg: false,
        css: css,
        hasLegData: hasPickupContainer,
        transportImageMode: 'ROAD',
      };
    }
    return null;
  }

  static fillDeliveryLegDetail(
    data: any,
    progressBarType: ProgressBarType,
    container: any,
    togglePickupDeliveryLegs: boolean,
    searchText: string
  ): any {
    if (
      progressBarType === ProgressBarType.ShipmentListingPage ||
      progressBarType === ProgressBarType.ShipmentDetailPage
    ) {
      return {
        type_of_leg: ProgressBarLegType.DeliveryLeg,
        showPickupDeliveryLegs: togglePickupDeliveryLegs,
        leg_sequence_number: null,
        actual_value: null,
        toolTipText: this.getDeliveryToolTipText(
          data,
          searchText,
          progressBarType
        ),
        isCompletedLeg: false,
        isCurrentLeg: false,
        isVarianceLeg: false,
        css: 'progtrckr-todo',
        hasLegData: true,
        transportImageMode: 'ROAD',
      };
    }
    if (progressBarType === ProgressBarType.ShipmentContainerTab) {
      var hasDeliveryContainer = true;
      var css = '';
      var toolTipText = '';
      if (
        !container.arrival_delivery_actual &&
        !container.arrival_delivery_estimated
      ) {
        hasDeliveryContainer = false;
      } else {
        toolTipText =
          this.getShipmentContainerProgressBarDeliveryCss(container);
      }
      return {
        type_of_leg: ProgressBarLegType.ContainerDeliveryLeg,
        showPickupDeliveryLegs: togglePickupDeliveryLegs,
        leg_sequence_number: null,
        actual_value: null,
        toolTipText: toolTipText,
        isCompletedLeg: false,
        isCurrentLeg: false,
        isVarianceLeg: false,
        css: css,
        hasLegData: hasDeliveryContainer,
        transportImageMode: 'ROAD',
      };
    }
  }

  static getShipmentListRoutingLegToolTipDetail(
    data: any,
    i: number,
    total_legs: number,
    searchText: string,
    progressBarType: ProgressBarType
  ) {
    var toolTipText = '';
    if (i == 0) {
      toolTipText += '<p class="update-origin">Origin</p>';
      toolTipText += this.getPortAndCountryToolTipText(
        this.toTitleCase(data.shipment_legs[i].lading_port?.portName),
        '',
        data.shipment_legs[i].lading_port?.countryCode,
        '',
        searchText
      );
      toolTipText += this.getRoutingToolTipText(
        'ATD',
        'ETD',
        data.shipment_legs[i].departure_actual,
        data.shipment_legs[i].departure_estimated,
        progressBarType
      );
    }

    if (i > 0 && i < total_legs) {
      if (!data.shipment_legs[i].lading_port) {
        toolTipText += this.getPortAndCountryToolTipText(
          this.toTitleCase(data.shipment_legs[i - 1]?.arrival_port?.portName),
          '',
          data.shipment_legs[i - 1]?.arrival_port?.countryCode,
          '',
          searchText
        );
      } else {
        toolTipText += this.getPortAndCountryToolTipText(
          this.toTitleCase(data.shipment_legs[i]?.lading_port?.portName),
          '',
          data.shipment_legs[i]?.lading_port?.countryCode,
          '',
          searchText
        );
      }
      toolTipText += this.getRoutingToolTipText(
        'ATA',
        'ETA',
        data.shipment_legs[i - 1].arrival_actual,
        data.shipment_legs[i - 1].arrival_estimated,
        progressBarType
      );

      toolTipText += this.getRoutingToolTipText(
        'ATD',
        'ETD',
        data.shipment_legs[i].departure_actual,
        data.shipment_legs[i].departure_estimated,
        progressBarType
      );
    }

    if (i === total_legs) {
      toolTipText += '<p class="update-origin">Destination</p>';
      toolTipText += this.getPortAndCountryToolTipText(
        this.toTitleCase(data.shipment_legs[i - 1].arrival_port?.portName),
        '',
        data.shipment_legs[i - 1].arrival_port?.countryCode,
        '',
        searchText
      );
      toolTipText += this.getRoutingToolTipText(
        'ATA',
        'ETA',
        data.shipment_legs[i - 1].arrival_actual,
        data.shipment_legs[i - 1].arrival_estimated,
        progressBarType
      );
    }
    return toolTipText;
  }

  static getShipmentDetailRoutingLegToolTipDetail(data: any, i: number) {
    let htmlString = '';
    if (i <= data?.shipment_legs?.length) {
      var leg =
        i < data?.shipment_legs?.length
          ? data.shipment_legs[i]
          : data.shipment_legs[i - 1];
      htmlString += this.getCurrentLegPortCountryDetail(leg, data, i);
      let carrierInfo = this.getCurrentLegCarrierDetail(leg);
      let voyageInfo = this.getVesselVoyageText(leg, data?.transport_mode);
      let carrierCss = 'new-set-group one';
      if (carrierInfo || voyageInfo) {
        if (carrierInfo && voyageInfo) {
          carrierCss = 'new-set-group';
        }
        htmlString += '<div class="' + carrierCss + '">';
      }
      htmlString += carrierInfo;
      htmlString += voyageInfo;
      if (carrierInfo || voyageInfo) {
        htmlString += '</div>';
      }
      if (i > 0) {
        htmlString += this.getArrivalDeliveryDisplayText(
          'Estimated Arrival',
          data?.shipment_legs[i - 1]?.arrival_estimated
        );
      }

      if (i < data?.shipment_legs?.length) {
        htmlString += this.getArrivalDeliveryDisplayText(
          'Estimated Departure',
          leg.departure_estimated
        );
      }
    }

    if (i > 0) {
      htmlString += this.getArrivalDeliveryDisplayText(
        'Actual Arrival',
        data?.shipment_legs[i - 1]?.arrival_actual
      );
    }

    if (i < data?.shipment_legs?.length) {
      htmlString += this.getArrivalDeliveryDisplayText(
        'Actual Departure',
        leg.departure_actual
      );
    }
    return htmlString;
  }

  static getCurrentLegCarrierDetail(leg: any) {
    let htmlString = '';

    if (leg.carrier?.name) {
      htmlString += '<p class="rap-legs">';
      htmlString +=
        '<span class="font-bold">Carrier: </span><span> ' +
        leg.carrier.name +
        '</span>';
      htmlString += '</p>';
    }
    return htmlString;
  }

  static getVesselVoyage(transport_mode: any) {
    let message = '';
    if (transport_mode.toUpperCase() === 'SEA') {
      message = 'Vessel/Voyage: ';
    } else if (transport_mode.toUpperCase() === 'AIR') {
      message = 'Flight: ';
    } else if (
      transport_mode.toUpperCase() === 'ROAD' ||
      transport_mode.toUpperCase() === 'ROA' ||
      transport_mode.toUpperCase() === 'TRK' ||
      transport_mode.toUpperCase() === 'RAIL' ||
      transport_mode.toUpperCase() === 'RAI'
    ) {
      message = 'Vehicle: ';
    }
    return message;
  }

  static getVesselVoyageText(leg: any, transport_mode: any) {
    let htmlString = '';
    if (!leg.voyage_number && !leg.vessel_name) return htmlString;
    let vesselVoyageAvail = this.getVesselVoyage(leg.transport_mode);
    if (vesselVoyageAvail.trim().length > 0) {
      htmlString += '<p class="rap-legs">';
      htmlString += '<span class="font-bold">';
      htmlString += vesselVoyageAvail;
      htmlString += '</span><span>';
      let message = '';
      if (leg.voyage_number && leg.vessel_name) {
        message = leg.vessel_name + ' / ' + leg.voyage_number;
      } else if (leg.vessel_name) {
        message = leg.vessel_name;
      } else if (leg.voyage_number) {
        message = leg.voyage_number;
      }
      htmlString += (message != '' ? message : '---') + '</span>';
      htmlString += '</p>';
    }
    return htmlString;
  }

  static getArrivalDeliveryDisplayText(title: string, date: any) {
    let htmlString = '';
    htmlString += '<p class="display-sec">';
    htmlString += '<span class="font-bold">' + title + ':  </span>';
    htmlString += '<span>';
    htmlString += this.formatDateMMMDDYYYY(date);
    htmlString += '</span>';
    htmlString += '</p>';
    return htmlString;
  }

  static getArrivalPortCountryDetail(data: any, index: number) {
    if (index <= 0 || !data.shipment_legs) return '';
    let htmlString = '';
    let leg = data.shipment_legs[index - 1];

    htmlString += leg.arrival_port?.portName
      ? this.toTitleCase(leg.arrival_port.portName) + ', '
      : '';

    htmlString += leg.arrival_port?.country_name
      ? this.toTitleCase(leg.arrival_port.country_name)
      : '';

    htmlString += leg.arrival_port?.unlocode
      ? ' - ' + leg.arrival_port.unlocode
      : '';
    return htmlString;
  }

  static getLadingPortCountryDetail(leg: any) {
    if (!leg) return '';
    let htmlString = '';
    htmlString += leg.lading_port?.portName
      ? this.toTitleCase(leg.lading_port.portName) + ', '
      : '';

    htmlString += leg.lading_port?.country_name
      ? this.toTitleCase(leg.lading_port.country_name)
      : '';

    htmlString += leg.lading_port?.unlocode
      ? ' - ' + leg.lading_port.unlocode
      : '';
    return htmlString;
  }

  static getCurrentLegPortCountryDetail(leg: any, data: any, index: number) {
    let htmlString = '';
    htmlString += '<div class="country">';
    if (
      index > 0 &&
      (!leg.lading_port || index === data.shipment_legs?.length)
    ) {
      htmlString += this.getArrivalPortCountryDetail(data, index);
    } else {
      htmlString += this.getLadingPortCountryDetail(leg);
    }
    htmlString += '</div>';
    return htmlString;
  }

  static getRouteToolTip(
    data: any,
    searchText: string,
    progressBarType: ProgressBarType,
    i: number,
    total_legs: number
  ) {
    let toolTipText = '';
    if (
      progressBarType === ProgressBarType.ShipmentListingPage ||
      progressBarType === ProgressBarType.ShipmentContainerTab
    ) {
      toolTipText += this.getShipmentListRoutingLegToolTipDetail(
        data,
        i,
        total_legs,
        searchText,
        progressBarType
      );
    } else if (progressBarType === ProgressBarType.ShipmentDetailPage) {
      toolTipText += this.getShipmentDetailRoutingLegToolTipDetail(data, i);
    }
    let leg_text = '';
    if (toolTipText) {
      if (
        progressBarType === ProgressBarType.ShipmentDetailPage &&
        i < total_legs
      ) {
        leg_text =
          '<h6 class="title">Leg ' +
          data.shipment_legs[i].leg_sequence_number +
          '</h6>';
      }

      if (
        progressBarType === ProgressBarType.ShipmentContainerTab &&
        i < total_legs
      ) {
        if (i >= 1) {
          leg_text =
            '<h6 class="title">Leg ' +
            (parseInt(data.shipment_legs[i].leg_sequence_number) - 1) +
            '</h6>';
        }
      }
      toolTipText = '<div class="rap">' + leg_text + toolTipText + '</div>';
    }
    return toolTipText;
  }

  static fillRoutingLegDetail(
    data: any,
    searchText: string,
    progressBarType: ProgressBarType
  ): any {
    var routePbItems: any[] = [];
    var total_legs = 0;
    if (data.shipment_legs) {
      total_legs = data.shipment_legs.length;
    }
    if (total_legs > 0) {
      var toolTipText = '';
      for (let i = 0; i <= total_legs; i++) {
        toolTipText = this.getRouteToolTip(
          data,
          searchText,
          progressBarType,
          i,
          total_legs
        );

        routePbItems.push({
          type_of_leg: ProgressBarLegType.RoutingLeg,
          showPickupDeliveryLegs: false,
          leg_sequence_number: i + 1,
          actual_value:
            i === total_legs
              ? data.shipment_legs[i - 1].leg_sequence_number
              : data.shipment_legs[i].leg_sequence_number,
          toolTipText: toolTipText,
          isCompletedLeg: false,
          isCurrentLeg: false,
          isVarianceLeg: false,
          css: 'progtrckr-todo',
          hasLegData: true,
          transportImageMode:
            i === total_legs
              ? data.shipment_legs[i - 1].transport_mode
              : data.shipment_legs[i].transport_mode,
        });
      }
    }
    return routePbItems;
  }

  static generateAllProgressBar(
    data: any,
    progressBarType: ProgressBarType,
    togglePickupDeliveryLegs: boolean,
    exception_css: string,
    pbItems: any
  ) {
    var htmlString: string = this.getProgressBarLayout(
      data,
      progressBarType,
      togglePickupDeliveryLegs
    );

    var index = 0;
    for (let pbItem of pbItems) {
      htmlString += this.generateHtml(
        pbItems,
        pbItem,
        progressBarType,
        index,
        exception_css
      );
      index++;
    }
    htmlString += '</ul>';
    return htmlString;
  }

  static generateHtml(
    pbItems: any,
    pbItem: any,
    progressBarType: ProgressBarType,
    index: number,
    exception_css: string
  ) {
    var htmlString = '';
    var image_detail = '';
    var css = pbItem.css;
    var tool_text = pbItem.toolTipText;
    if (!pbItem.showPickupDeliveryLegs && pbItem.hasLegData) {
      if (progressBarType === ProgressBarType.ShipmentDetailPage) {
        image_detail = this.getTransportIcon(pbItem.transportImageMode);
      }
      if (
        pbItem.type_of_leg === ProgressBarLegType.ContainerPickupLeg ||
        pbItem.type_of_leg === ProgressBarLegType.ContainerDeliveryLeg
      ) {
        htmlString += tool_text;
      } else {
        css += this.generateDymaicCss(
          pbItems,
          pbItem,
          progressBarType,
          index,
          exception_css
        );
        tool_text += this.generateDynamicProgressBarStyle(
          progressBarType,
          pbItem,
          css
        );
        htmlString +=
          '<li class="' + css + '">' + image_detail + tool_text + '</li>';
      }
    }
    return htmlString;
  }

  static generateDymaicCss(
    pbItems: any,
    pbItem: any,
    progressBarType: ProgressBarType,
    index: number,
    exception_css: string
  ) {
    var css = '';
    if (
      pbItem.isCompletedLeg ||
      pbItem.isVarianceLeg ||
      (index > 0 &&
        index < pbItems.length &&
        pbItems[index - 1].isCompletedLeg &&
        !pbItems[index].isCompletedLeg)
    ) {
      css += ' ' + exception_css;
      if (
        progressBarType === ProgressBarType.ShipmentContainerTab ||
        progressBarType === ProgressBarType.ShipmentDetailPage
      ) {
        css += ' end-point ';
      }
    }
    return css;
  }

  static generateDynamicProgressBarStyle(
    progressBarType: ProgressBarType,
    pbItem: any,
    css: string
  ) {
    var tool_text = '';
    if (
      progressBarType === ProgressBarType.ShipmentContainerTab &&
      (pbItem.isCompletedLeg || pbItem.isVarianceLeg)
    ) {
      tool_text += '<div class="dynamic-progress-container ' + css + '"></div>';
    } else if (progressBarType === ProgressBarType.ShipmentListingPage) {
      tool_text += '<div class="dynamic-progress ' + css + '"></div>';
    } else if (progressBarType === ProgressBarType.ShipmentDetailPage) {
      tool_text =
        '<div class="dynamic-progress ' + css + '"></div>' + tool_text;
    }
    return tool_text;
  }

  static getContainerPickupLegDisplayData(container: any, total_legs: any) {
    var htmlString = '';

    if (!container.origin_pickup_actual && !container.origin_pickup_estimated) {
      return htmlString;
    }
    var start_leg_text = '';
    start_leg_text += '<h6>Pickup</h6>';

    start_leg_text += this.getEstAstToolTip(
      container.origin_pickup_estimated,
      'Est',
      ProgressBarType.ShipmentContainerTab
    );
    start_leg_text += this.getEstAstToolTip(
      container.origin_pickup_actual,
      'Act',
      ProgressBarType.ShipmentContainerTab
    );

    var pb_css =
      container.origin_pickup_actual ||
        total_legs > 0 ||
        container.arrival_delivery_actual
        ? 'progtrckr-done blue'
        : 'progtrckr-todo';

    htmlString +=
      '<li class ="' +
      pb_css +
      '"><div class="rap">' +
      start_leg_text +
      '</div></li>';

    return htmlString;
  }

  static getRoutingToolTipText(
    actual_text: any,
    estimated_text: any,
    actual: any,
    estimated: any,
    progressBarType: ProgressBarType
  ) {
    var message = '';
    var dateToDisplay = '---';
    if (actual) {
      dateToDisplay =
        progressBarType == ProgressBarType.ShipmentContainerTab
          ? this.formatDateMMMDDYYYY(actual)
          : this.formatDateMMMDD(actual);
      message +=
        '<p class="country">' + actual_text + ': ' + dateToDisplay + '</p>';
    } else {
      if (estimated) {
        dateToDisplay =
          progressBarType == ProgressBarType.ShipmentContainerTab
            ? this.formatDateMMMDDYYYY(estimated)
            : this.formatDateMMMDD(estimated);
      }
      message +=
        '<p class="country">' + estimated_text + ': ' + dateToDisplay + '</p>';
    }
    return message;
  }

  static padding2(data: number) {
    return ('0' + data).slice(-2);
  }

  static formatDateMMMDD(dateString: string) {
    if (!dateString) return '---';

    var strArray = this.splitDateString(dateString);
    var month = parseInt(strArray[1]);
    var day = parseInt(strArray[2]);

    return this.month_array[month - 1] + ' ' + this.padding2(day);
  }

  static getShipmentListingProgressBar(
    data: any,
    togglePickupDeliveryLegs: boolean = false,
    searchText: any = '',
    progressBarType: ProgressBarType = ProgressBarType.ShipmentListingPage,
    container: any = null
  ) {
    if (!data) return '';

    var exception_css = this.getExceptionClass(data?.status, data?.exception);
    var pbItems: any[] = [];

    pbItems.push(
      this.fillPickupLegDetail(
        data,
        progressBarType,
        container,
        exception_css,
        togglePickupDeliveryLegs,
        searchText
      )
    );
    var routeItems = this.fillRoutingLegDetail(
      data,
      searchText,
      progressBarType
    );
    Array.prototype.push.apply(pbItems, routeItems);
    pbItems.push(
      this.fillDeliveryLegDetail(
        data,
        progressBarType,
        container,
        togglePickupDeliveryLegs,
        searchText
      )
    );

    pbItems = this.fillLegProgress(pbItems, data, container);

    return this.generateAllProgressBar(
      data,
      progressBarType,
      togglePickupDeliveryLegs,
      exception_css,
      pbItems
    );
  }

  static getUSCanadaStateText(country: any, state: any) {
    if (country) {
      if (
        country.toLowerCase() == 'united states' ||
        country.toLowerCase() == 'canada'
      )
        return state ? state + ', ' : '';
    }
    return '';
  }

  static getBookingExceptionClass(status: any, exception: any) {
    let className = 'blue';
    if (!status || !exception) return className;

    if (exception.toLowerCase() == 'archived') {
      className = 'gray';
    }
    return className;
  }

  static getCustomExceptionClass(status: any, exception: any) {
    let className = 'blue';
    if (!status || !exception) return className;

    if (exception.toLowerCase() == 'on hold' && status != 'Released') {
      className = 'red';
    } else if (exception.toLowerCase() == 'delayed' && status != 'Released') {
      className = 'orange';
    }
    return className;
  }

  static getMimeFileTypes() {
    var mimeType: any[] = [
      {
        extension: 'aac',
        mime: 'audio/aac',
      },
      {
        extension: 'abw',
        mime: 'application/x-abiword',
      },
      {
        extension: 'avi',
        mime: 'video/x-msvideo',
      },
      {
        extension: 'azw',
        mime: 'application/vnd.amazon.ebook',
      },
      {
        extension: 'bin',
        mime: 'application/octet-stream',
      },
      {
        extension: 'bmp',
        mime: 'image/bmp',
      },
      {
        extension: 'bz',
        mime: 'application/x-bzip',
      },
      {
        extension: 'cda',
        mime: 'application/x-cdf',
      },
      {
        extension: 'csh',
        mime: 'application/x-csh',
      },
      {
        extension: 'csv',
        mime: 'text/csv',
      },
      {
        extension: 'doc',
        mime: 'application/msword',
      },
      {
        extension: 'docx',
        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
      {
        extension: 'eot',
        mime: 'application/vnd.ms-fontobject',
      },
      {
        extension: 'epub',
        mime: 'application/epub+zip',
      },
      {
        extension: 'gz',
        mime: 'application/gzip',
      },
      {
        extension: 'gif',
        mime: 'image/gif',
      },
      {
        extension: 'html',
        mime: 'text/html',
      },
      {
        extension: 'ics',
        mime: 'text/calendar',
      },
      {
        extension: 'jpeg',
        mime: 'image/jpeg',
      },
      {
        extension: 'jpg',
        mime: 'image/jpeg',
      },
      {
        extension: 'json',
        mime: 'application/json',
      },
      {
        extension: 'midi',
        mime: 'audio/midi',
      },
      {
        extension: 'mp3',
        mime: 'audio/mpeg',
      },
      {
        extension: 'mp4',
        mime: 'video/mp4',
      },
      {
        extension: 'mpeg',
        mime: 'video/mpeg',
      },
      {
        extension: 'odp',
        mime: 'application/vnd.oasis.opendocument.presentation',
      },
      {
        extension: 'ods',
        mime: 'application/vnd.oasis.opendocument.spreadsheet',
      },
      {
        extension: 'odt',
        mime: 'application/vnd.oasis.opendocument.text',
      },
      {
        extension: 'oga',
        mime: 'audio/ogg',
      },
      {
        extension: 'ogv',
        mime: 'video/ogg',
      },
      {
        extension: 'png',
        mime: 'image/png',
      },
      {
        extension: 'pdf',
        mime: 'application/pdf',
      },
      {
        extension: 'ppt',
        mime: 'application/vnd.ms-powerpoint',
      },
      {
        extension: 'pptx',
        mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      },
      {
        extension: 'rar',
        mime: 'application/vnd.rar',
      },
      {
        extension: 'svg',
        mime: 'image/svg+xml',
      },
      {
        extension: 'txt',
        mime: 'text/plain',
      },
      {
        extension: 'wav',
        mime: 'audio/wav',
      },
      {
        extension: 'weba',
        mime: 'audio/webm',
      },
      {
        extension: 'webp',
        mime: 'image/webp',
      },
      {
        extension: 'webm',
        mime: 'video/webm',
      },
      {
        extension: 'xls',
        mime: 'application/vnd.ms-excel',
      },
      {
        extension: 'xlsx',
        mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      {
        extension: 'xml',
        mime: 'application/xml',
      },
      {
        extension: 'zip',
        mime: 'application/zip',
      },
      {
        extension: 'msg',
        mime: 'application/vnd.ms-outlook',
      },
    ];
    return mimeType;
  }

  static trimEdocText(code: any, desc: any) {
    if (!code || !desc) return '';
    return code + ' - ' + desc;
  }

  static downloadBlobFile(
    data: any,
    fileType: any,
    actionType: any,
    fileNames: any,
    mimeTypes: any[]
  ) {
    var filterMimeType = mimeTypes.find((x) => x.extension == fileType);
    const blob = new Blob([data], {
      type: filterMimeType ? filterMimeType.mime : 'application/octet-stream',
    });

    if (actionType == 'preview') {
      if (
        fileType == 'pdf' ||
        fileType == 'jpeg' ||
        fileType == 'png' ||
        fileType == 'jpg' ||
        fileType == 'txt'
      ) {
        window.open(URL.createObjectURL(blob));
      } else {
        this.downloadBlobMethod(blob, fileNames);
      }
    } else if (actionType == 'download') {
      this.downloadBlobMethod(blob, fileNames);
    }
  }

  static downloadBlobMethod(blob: any, fileNames: any) {
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement('a');
    anchor.download = fileNames;
    anchor.href = url;
    anchor.click();
    URL.revokeObjectURL(url);
  }

  static generateGUID() {
    return UUID.UUID();
  }

  static getDataRangeParameterApiValue(
    dataRangeType: string,
    selectedDateRangeFilters: any[],
    apiParamsArray: any[],
    coloumnName: string,
    groupedSelectedArray: any[]
  ) {
    let rangeSelected: any[] = [];
    let selectedValueRange: any[] = [];
    selectedDateRangeFilters
      .filter((x) => x.status == dataRangeType)
      .forEach((ele) => {
        rangeSelected.push(ele.fromDate + ',' + ele.toDate);
        selectedValueRange.push({
          uniqueId: ele.uniqueId,
          fromDate: ele.fromDate,
          toDate: ele.toDate,
        });
      });

    if (rangeSelected.length > 0) {
      let newApiParamsArray = [
        ...apiParamsArray,
        {
          column_name: coloumnName,
          operator: '=',
          value: rangeSelected.map((x) => x).join('|'),
        },
      ];
      let newGroupedSelectedArray = [
        ...groupedSelectedArray,
        {
          type: dataRangeType,
          selectedDates: selectedValueRange,
        },
      ];
      return {
        apiParamsArray: newApiParamsArray,
        groupedSelectedArray: newGroupedSelectedArray,
      };
    }
    return { apiParamsArray, groupedSelectedArray };
  }

  static isValidDate(strDate: string) {
    var dtArray = strDate.split('/');
    if (dtArray.length !== 3) return false;
    var convertedDt =
      ('0' + dtArray[0]).slice(-2) +
      '/' +
      ('0' + dtArray[1]).slice(-2) +
      '/' +
      ('0' + dtArray[2]).slice(-4);

    var reg =
      /(0[1-9]|1[012])[- /.](0[1-9]|[12][\d]|3[01])[- /.](19|20|21)\d\d/;
    if (convertedDt.match(reg)) {
      return true;
    }
    return false;
  }

  static isAllowedDateChars(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }

    return true;
  }

  static getShipperConsigneeName(data: any) {
    return {
      shipperName: data.shipper?.name ? data.shipper.name : '---',
      consigneeName: data.consignee?.name ? data.consignee.name : '---',
    };
  }

  static titleCase(str: string) {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  static getCssForPickUpAndDeliveryContainer(data: any) {
    if (!data.containers || data.containers.length == 0)
      return ' no-pickup no-delivery ';

    var containerCss = '';
    if (
      !data.containers[0].origin_pickup_actual &&
      !data.containers[0].origin_pickup_estimated
    ) {
      containerCss += ' no-pickup ';
    }
    if (
      !data.containers[0].arrival_delivery_estimated &&
      !data.containers[0].arrival_delivery_actual
    ) {
      containerCss += ' no-delivery ';
    }
    return containerCss;
  }

  static sortMileStonesByActualDate(shipment: any) {
    shipment?.milestones?.sort((a: any, b: any) => {
      let dateA = new Date(a.actual_date).getTime();
      let dateB = new Date(b.actual_date).getTime();
      return dateA > dateB ? -1 : 1;
    });
  }

  static sortMileStonesByDescription(shipment: any) {
    shipment?.milestones?.sort((a: any, b: any) => {
      a = a.event_name?.toLowerCase();
      b = b.event_name?.toLowerCase();
      return a > b ? 1 : -1;
    });
  }

  static REGEX_NON_ALPHA = /[^a-zA-Z]/g;
  static REGEX_NUMERIC = /[+-]?(\d*\.\d+|\d+)/g;

  static sortAlphaNumeric(data: any, orderBy: any, key: any) {
    let order: any = orderBy.toUpperCase() == 'DESC' ? -1 : 1;
    const sortedData = data.sort((a: any, b: any) => {
      const itemA = key ? a[key].toString() : a.toString();
      const itemB = key ? b[key].toString() : b.toString();
      const aAlpha = itemA.replace(CommonUtils.REGEX_NON_ALPHA, '');
      const bAlpha = itemB.replace(CommonUtils.REGEX_NON_ALPHA, '');
      if (aAlpha === bAlpha) {
        let aNum = itemA.match(CommonUtils.REGEX_NUMERIC);
        let bNum = itemB.match(CommonUtils.REGEX_NUMERIC);
        aNum = aNum ? parseFloat(aNum.join('')) : 0;
        bNum = bNum ? parseFloat(bNum.join('')) : 0;
        return aNum === bNum ? 0 : aNum < bNum ? -order : order;
      }
      return aAlpha < bAlpha ? -order : order;
    });
    return sortedData;
  }

  static getRandomDateTime() {
    return new Date().toLocaleString().replace(/\D/gm, '') + '' + new Date().getMilliseconds();
  }

  static doAdditionalReferencesExist(list: any[]) {
    if (!list) {
      return false;
    }

    let additionalReferences = list.filter(x => x.data_source !== "CustomizedField");

    return additionalReferences.length > 0;
  }

  // end of Utils file
}
