import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomsComponent } from './container/customs/customs.component';

const routes: Routes = [
  {
    path: '',
    component: CustomsComponent,
  },
  {
    path: ':filterParam',
    component: CustomsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomsRoutingModule { }
