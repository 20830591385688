<div class="container-fluid"
  [ngClass]="{'guided-back-drop' : isCustomFilterSecHide,'guided-visible-sec':isCustomFilterSecShow}">
  <div class="row d-flex align-items-start mobiletop-pad">
    <div class="col-lg-9 col-md-8 shipment-flex-wrap">
      <div *ngIf="!isDashboard" class="shipment-search-wdth">
        <div class="shipment-title-mobile">Customs</div>
        <div id="bookingSearchBox" class="d-flex position-relative"
          [ngClass]="{'guided-back-drop' : isSearchBoxHide,'guided-visible-sec':isSearchBoxShow}">
          <input type="text" [(ngModel)]="customsSearchText"
            placeholder="Search by customs, shipment or reference number"
            class="form-control search-input ng-pristine ng-valid ng-touched"
            (keyup.backspace)="clearSearchText(customsSearchText)" (blur)="clickEvent.next($event)"
            (keyup.enter)="clickEvent.next($event)" ngDefaultControl #searchTextbox />
          <span class="form-control-feedback search-icon">
            <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-0"
              *ngIf="customsSearchText.length > 0"
              (click)="searchTextbox.value='';clearSearchText(searchTextbox.value)">
            <img src="../../assets/img/search.svg" alt="Search" (click)="clickEvent.next($event)" /></span>
        </div>
      </div>
      <div *ngIf="!isDashboard" class="filter-overflow">
        <button #dropdownBtn
          [ngClass]="{'guided-back-drop' : isFilterHide,'guided-visible-sec button-click':isFilterShow}" type="button"
          id="MainDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="btn btn-group filter-btn" (click)="openMobileFilter()">
          <span class="filter-btn-text">Filter</span>
        </button>
        <div id="dropdownMenuButton" #dropdownElementDiv class="dropdown-menu mobile-hide"
          aria-labelledby="dropdownMenuButton">
          <ul class="custom-drop-menu">
            <li class="dropdown-item" *ngFor="let filter of filterItem; let index = index">
              <input [(ngModel)]="filter.checked" class="test-selection" type="checkbox" name="filter-{{ filter.filt }}"
                value="{{ filter.val }}" id="MainFilterId-{{ filter.val }}" (change)="
                  isFilterSelected(
                    filter.checked,
                    filter.val,
                    filter.filt,
                    filter.col_name,
                    filter.val
                  )
                " ngDefaultControl />
              <label class="selection-list" for="MainFilterId-{{ filter.val }}">
                <span class="header-set">{{ filter.filt }}</span>
              </label>
            </li>
          </ul>
        </div>
        <div class="static-menu-filter" *ngIf="guidedTourFilter">
          <ul class="custom-drop-menu newset">
            <li class="dropdown-item" *ngFor="let filter of filterItemset">
              <input type="checkbox">
              <label class="selection-list">
                <span class="header-set">{{ filter.filt }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <ng-container *ngIf="isMobileViewParent">
        <div class="mobile-filter-container" *ngIf="mobileFilterContainer">
          <div class="filter-header">
            <div class="close-filter" (click)="closeFilter()">
              <img src="../../assets/img/close-popup.svg" alt="closepopup" />
            </div>
            <span>Filters</span>
            <span (click)="clearAllFilter()">Clear All</span>
          </div>
          <div class="accordion filter-accordian">
            <div class="accordion accordion-flush" id="Savefilter01">
              <!-- <div class="accordion-item">
                                <h2 class="accordion-header" id="Savefilter01">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#Savefilter" aria-expanded="false" aria-controls="flush-collapseThree">
                                        My Saved Filters
                                    </button>
                                </h2>
                                <div id="Savefilter" class="accordion-collapse collapse" aria-labelledby="Savefilter01"
                                    data-bs-parent="#Savefilter01">
                                    <div class="accordion-body new-set">
                                        <ul class="custom-drop-menu mb-0 pb-4" *ngIf="savedFilters.length > 0">
                                            <li class="dropdown-item add-new-set"
                                                *ngFor="let userFilter of savedFilters;let i = index">
                                                <span class="rap-radio">
                                                    <input type="radio" name="userFilter" (change)="selectedUserFilter(userFilter)"
                                                        id="userFilter{{i}}" value="{{userFilter.id}}"
                                                        [checked]="mobileSelectedFilter == userFilter.id ? true:false">
                                                    <label for="userFilter{{i}}">{{userFilter.filterName}}</label>
                                                </span>
                                            </li>
                                        </ul>
                                        <ul *ngIf="savedFilters.length < 1">
                                            <li class="dropdown-item">No Filters</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#DateRange" [ngClass]="{ activeFilters: dateFilterValue}" aria-expanded="false"
                    aria-controls="flush-collapseThree">
                    Date Range
                  </button>
                </h2>
                <div id="DateRange" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <app-daterange-filter [filterType]="mobileDateRangeFilter"
                    [selectedDateRangeRender]="selectedDateRangeRender"
                    (closeSelectedFilterControl)="closeCustomFilters($event)" (dateRangeFilter)="getDateRange($event)">
                  </app-daterange-filter>
                </div>
              </div>
              <div class="accordion-item">
                <h2 id="filterAccordian" class="accordion-header"><button id="mobileSupplierFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#mobileSupplierFilter" aria-expanded="false"
                    aria-controls="flush-collapseThree" class="accordion-button collapsed"> Shipper </button></h2>
                <div id="mobileSupplierFilterId" aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian"
                  class="accordion-collapse collapse">
                  <div class="accordion-body origin-Location">
                    <div class="p-2">
                      <div class="input-group mt-3 mb-3"><input type="text" name="search" autocomplete="off"
                          class="form-control border-right-0 search-area ng-untouched ng-pristine ng-valid"
                          placeholder="Search Shippers">
                        <div class="input-group-append">
                          <div class="search-bg input-group-text"><img src="assets/img/search-sm.svg" alt=""></div>
                        </div>
                      </div>
                      <div class="location-innerscroll">
                        <h6 class="mb-1 mt-2">All Shippers</h6>
                        <div>
                          <div class="font-w"> No Records Found </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 id="filterAccordian" class="accordion-header"><button id="mobileShipperFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#mobileShipperFilterId" aria-expanded="false"
                    aria-controls="flush-collapseThree" class="accordion-button collapsed"> Shipper </button></h2>
                <div id="mobileShipperFilterId" aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian"
                  class="accordion-collapse collapse">
                  <div class="accordion-body origin-Location">
                    <div class="p-2">
                      <div class="input-group mt-3 mb-3"><input type="text" name="search" autocomplete="off"
                          class="form-control border-right-0 search-area ng-untouched ng-pristine ng-valid"
                          placeholder="Search Shippers">
                        <div class="input-group-append">
                          <div class="search-bg input-group-text"><img src="assets/img/search-sm.svg" alt=""></div>
                        </div>
                      </div>
                      <div class="location-innerscroll">
                        <h6 class="mb-1 mt-2">All Shippers</h6>
                        <div>
                          <div class="font-w"> No Records Found </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 id="filterAccordian" class="accordion-header"><button id="mobileConsigneeFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#mobileConsigneeFilter" aria-expanded="false"
                    aria-controls="flush-collapseThree" class="accordion-button collapsed"> Consignee </button></h2>
                <div id="mobileConsigneeFilter" aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian"
                  class="accordion-collapse collapse">
                  <div class="accordion-body origin-Location">
                    <div class="p-2">
                      <div class="input-group mt-3 mb-3"><input type="text" name="search" autocomplete="off"
                          class="form-control border-right-0 search-area ng-untouched ng-pristine ng-valid"
                          placeholder="Search Importers">
                        <div class="input-group-append">
                          <div class="search-bg input-group-text"><img src="assets/img/search-sm.svg" alt=""></div>
                        </div>
                      </div>
                      <div class="location-innerscroll">
                        <h6 class="mb-1 mt-2">All Importers</h6>
                        <div>
                          <div class="font-w"> No Records Found </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 id="filterAccordian" class="accordion-header"><button id="mobileImporterFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#mobileImporterFilterId" aria-expanded="false"
                    aria-controls="flush-collapseThree" class="accordion-button collapsed"> Importer </button></h2>
                <div id="mobileImporterFilterId" aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian"
                  class="accordion-collapse collapse">
                  <div class="accordion-body origin-Location">
                    <div class="p-2">
                      <div class="input-group mt-3 mb-3"><input type="text" name="search" autocomplete="off"
                          class="form-control border-right-0 search-area ng-untouched ng-pristine ng-valid"
                          placeholder="Search Importers">
                        <div class="input-group-append">
                          <div class="search-bg input-group-text"><img src="assets/img/search-sm.svg" alt=""></div>
                        </div>
                      </div>
                      <div class="location-innerscroll">
                        <h6 class="mb-1 mt-2">All Importers</h6>
                        <div>
                          <div class="font-w"> No Records Found </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 id="filterAccordian" class="accordion-header"><button id="mobileImporterRecordFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#mobileImporterRecordFilterId" aria-expanded="false"
                    aria-controls="flush-collapseThree" class="accordion-button collapsed"> Importer of Records
                  </button>
                </h2>
                <div id="mobileImporterRecordFilterId" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian" class="accordion-collapse collapse">
                  <div class="accordion-body origin-Location">
                    <div class="p-2">
                      <div class="input-group mt-3 mb-3"><input type="text" name="search" autocomplete="off"
                          class="form-control border-right-0 search-area ng-untouched ng-pristine ng-valid"
                          placeholder="Search Importer of Records">
                        <div class="input-group-append">
                          <div class="search-bg input-group-text"><img src="assets/img/search-sm.svg" alt=""></div>
                        </div>
                      </div>
                      <div class="location-innerscroll">
                        <h6 class="mb-1 mt-2">All Importer of Records</h6>
                        <div>
                          <div class="font-w"> No Records Found </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" id="mobileStatusFilter"
                    [ngClass]="{ activeFilters: statusFilterValue }" aria-expanded="false"
                    aria-controls="flush-collapseOne" (click)="
                      filterIsOpen(
                        '#mobileStatusFilter',
                        true,
                        1,
                        'Status',
                        'statuscustom',
                        1
                      )
                    ">
                    Status
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne">
                  <ng-container>
                    <app-common-dropdown-filters [filterType]="mobileStatusFilter"
                      (closeSelectedFilterControl)="closeCustomFilters($event)"
                      (selectedFiltersArray)="getSelectedFilterArray($event)">
                    </app-common-dropdown-filters>
                  </ng-container>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button id="mobileTransportFilter" class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false"
                    aria-controls="flush-collapseThree" [ngClass]="{ activeFilters: transportFilterValue}" (click)="
                                 filterIsOpen('#mobileTransportFilter',
                                true,
                                0,
                                'Transport Type',
                                'transport',
                                0)
                               ">
                    Transport Type
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <ng-container>
                    <app-common-dropdown-filters [filterType]="mobileTransportFilter"
                      (closeSelectedFilterControl)="closeCustomFilters($event)"
                      (selectedFiltersArray)="getSelectedFilterArray($event)">
                    </app-common-dropdown-filters>
                  </ng-container>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" id="mobileExceptionFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
                    aria-controls="flush-collapseTwo" [ngClass]="{ activeFilters: exceptionFilterValue }" (click)="
                      filterIsOpen(
                        '#mobileExceptionFilter',
                        true,
                        6,
                        'Exception Type',
                        'exception',
                        6
                      )
                    ">
                    Exception Type
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#filterAccordian">
                  <app-common-dropdown-filters [filterType]="mobileExceptionFilter"
                    (closeSelectedFilterControl)="closeCustomFilters($event)"
                    (selectedFiltersArray)="getSelectedFilterArray($event)">
                  </app-common-dropdown-filters>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" id="mobileCustomTypeFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false"
                    aria-controls="flush-collapseFour" [ngClass]="{ activeFilters: customsTypeFilterValue }" (click)="
                                      filterIsOpen(
                                        '#mobileCustomTypeFilter',
                                        true,
                                        8,
                                        'Customs Type',
                                        'customstype',
                                        8
                                      )
                                    ">
                    Customs Type
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo">
                  <app-common-dropdown-filters [filterType]="mobileCustomsTypeFilter"
                    (closeSelectedFilterControl)="closeCustomFilters($event)"
                    (selectedFiltersArray)="getSelectedFilterArray($event)">
                  </app-common-dropdown-filters>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Watchlist" [ngClass]="{ activeFilters: favoritesFilterValue }"
                    aria-expanded="false" aria-controls="flush-collapseThree">
                    Watchlist
                  </button>
                </h2>

                <div id="Watchlist" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <div class="accordion-body new-set">
                    <ul class="custom-drop-menu">
                      <li class="dropdown-item">
                        <input type="checkbox" id="checkList" [checked]="checkWatchlist"
                          (change)="watchlistSelected($event)" />
                        <label class="selection-list" for="checkList">
                          <span class="header-set">Watchlist</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#pgaExists" [ngClass]="{ activeFilters: pgaExistsFilterValue }"
                    aria-expanded="false" aria-controls="flush-collapseThree">
                    PGA Exists
                  </button>
                </h2>

                <div id="pgaExists" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <div class="accordion-body new-set">
                    <ul class="custom-drop-menu">
                      <li class="dropdown-item">
                        <input type="checkbox" id="pgaExistCheck" [checked]="checkPgaExists"
                          (change)="pgaExistSelected($event)" />
                        <label class="selection-list" for="pgaExistCheck">
                          <span class="header-set">PGA Exists</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
            <div class="showall-filter">
              <button class="btn btn-yellow" (click)="closeFilter()">
                SHOW
                <span *ngIf="paramCheck?.filter_options?.length < 1">ALL</span><span
                  *ngIf="paramCheck?.filter_options?.length > 0">{{
                  resultCount
                  }}</span>
                CUSTOMS
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isMobileViewParent">
        <div class="d-inline-block mt-2 mr-2" *ngFor="let row of selectedMainFilter">
          <ng-container *ngIf="row.id == 0 || row.id == 1 || row.id == 6 || row.id == 8">
            <app-common-dropdown-filters [filterType]="row" (closeSelectedFilterControl)="closeCustomFilters($event)"
              (selectedFiltersArray)="getSelectedFilterArray($event)">
            </app-common-dropdown-filters>
          </ng-container>

          <ng-container *ngIf="row.id == 5">
            <app-daterange-filter [filterType]="row" (closeSelectedFilterControl)="closeCustomFilters($event)"
              (dateRangeFilter)="getDateRange($event)"></app-daterange-filter>
          </ng-container>

          <ng-container *ngIf="row.id == 2 || row.id == 3 || row.id == 10 || row.id == 11 || row.id == 12">
            <app-common-shipper-type-filter (locationFilterSearch)="getLocationList($event)"
              (emitOffsetLoc)="offsetLocation($event)" [locationList]="currentShipperTypeFilter(row.id)"
              [resentList]="currentShippertTypeFilerRecent(row.id)" (locationFilterClear)="clearFilterSearch($event)"
              (locationFilter)="locationSelectedFilter($event)"
              (closeSelectedFilterControl)="closeCustomFilters($event)" [listData]="row"
              [offset]="getFilterOffset(row.id)" [isProgress]="isProgressLocation">
            </app-common-shipper-type-filter>
          </ng-container>
          <ng-container *ngIf="row.id == 4">
            <app-all-location-filter (emitOffsetLoc)="offsetLocation($event)" [listData]="row"
              (closeSelectedFilterControl)="closeCustomFilters($event)" [resentList]='resentAllLocationList'
              (alllocationFilter)="locationSelectedFilter($event)" (locationFilterSearch)="getLocationList($event)"
              [isProgress]="isProgressLocation" (locationFilterValueEmit)='loadLocationFilter($event)'
              (locationFilterClear)="clearFilterSearch($event)" [offsetLoadingPort]="offsetLoadingPort"
              [offsetDischargePort]="offsetDischargePort" [offsetPortofClearance]="offsetPortofClearance"
              [offsetShipmentOrigin]="offsetShipmentOrigin" [offsetShipmentDestination]="offsetShipmentDestination"
              [allLocationList]='allLocationList'>
            </app-all-location-filter>
          </ng-container>
          <ng-container *ngIf="row.id == 7">
            <div class="inbox-set m-0">
              <input type="text" readonly class="form-control" name="search" [value]="watchlistFilter"
                autocomplete="off" />
              <span class="update-fix"><img src="../../../../../assets/img/close-sm.png" class="close-sm" alt=""
                  (click)="closeWatchFilter()" /></span>
            </div>
          </ng-container>
          <ng-container *ngIf="row.id == 9">
            <div class="inbox-set m-0">
              <input type="text" readonly class="form-control" name="search" [value]="PGAExistsFilter"
                autocomplete="off" />
              <span class="update-fix"><img src="../../../../../assets/img/close-sm.png" class="close-sm" alt=""
                  (click)="closePGAExistsFilter()" /></span>
            </div>
          </ng-container>

          <div id="watchListId"></div>
        </div>
      </ng-container>
      <span class="rap-save">
        <button type="button" class="btn-save-as" *ngIf="isShowSaveBtn" (click)="updateUserFilter()">Save
        </button>
        <button type="button" class="btn-save-as" *ngIf="isShowSaveAsBtn" (click)="openSaveFilterPopUp()">Save
          As</button>
      </span>
    </div>
    <div id="sortRapper" class="col-lg-3 col-md-4 col-12 d-lg-flex justify-content-lg-end p-0">
      <div class="sortby-wrap">
        <span id="sortSection" [ngClass]="{'guided-back-drop' : isSortHide,'guided-visible-sec':isSortShow}">
          <div *ngIf="!isDashboard" class="mt-1 child">Sort By:</div>
          <div class="btn-group dropdown child filterDropdown">
            <button type="button" id="sortButtonId" data-toggle="dropdown" aria-expanded="false"
              class="btn shipment-drop-btn dropdown-toggle mx-2 statusdrop custom-dropdown">
              <span class="shipment-drop-text">
                <img *ngIf="defaultSortDirection === 'desc'" alt="desc"
                  src="assets/img/shipments/shipment-sort-icon.svg" class="mr-1" />
                <img *ngIf="defaultSortDirection === 'asc'" alt="asc" src="assets/img/shipments/shipment-sort-icon.svg"
                  class="mr-1 update" />
                {{ defaultSortSelectedColumn }}</span>

            </button>
            <div class="dropdown-menu restrict-close" aria-labelledby="dropdownMenuButton">
              <ul class="custom-drop-menu">
                <li class="dropdown-item pointer" [ngClass]="{
                    active: defaultSortSelectedValue == sortOption.id
                  }" *ngFor="let sortOption of sortOptions" (click)="clickSortOption.next(sortOption)">
                  <span class="shipment-drop-text">
                    <img *ngIf="
                        defaultSortSelectedValue == sortOption.id &&
                        defaultSortDirection === 'desc'
                      " alt="desc" src="../../../../../assets/img/shipments/shipment-sort-icon.svg" class="mr-1" />
                    <img *ngIf="
                        defaultSortSelectedValue == sortOption.id &&
                        defaultSortDirection === 'asc'
                      " alt="asc" src="../../../../../assets/img/shipments/shipment-sort-icon-blue.svg"
                      class="mr-1 update" />
                    <span *ngIf="defaultSortSelectedValue != sortOption.id"></span>{{ sortOption.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </span>
        <div [ngClass]="{'guided-back-drop' : isShareHide,'guided-visible-sec':isShareShow}" *ngIf="!isDashboard"
          class="upload-btn child" (click)="share($event)" id="shareSection">
          <img src="../../assets/img/upload.svg" class="upload-icon" alt="upload" />
        </div>
      </div>
    </div>
  </div>
</div>
<div id="pop-up4" *ngIf="createUserFilterPopUp" class="dimScreen">
  <div class="add-user-popup new-filter">
    <button class="close" type="button" (click)="closeUserFilterPopUp()">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
    </button>
    <div class="body-section fix-size">
      <h3>Create New Filter</h3>
      <div class="content-sec">
        <label>Filter Name<span class="red-set">*</span></label>
        <input type="text" [(ngModel)]="myFilterName" placeholder="Enter Name" class="form-control" maxlength="30"
          (keyup.enter)="saveMyFilter()">
        <p *ngIf="myFilterError && myFilterName.length > 0" class="error-set red-set">A filter with this name already
          exists.</p>
        <p *ngIf="myFilterNameEmpty && myFilterName.length < 1" class="error-set red-set">Please enter the filter name.
        </p>
        <p *ngIf="myFilterName.length >= 30" class="error-set red-set">Filter name reached maximum 30 characters
          allowed.</p>
        <button type="button" id="shipmentSaveBtn" (click)="saveMyFilter()">save</button>
      </div>
    </div>
  </div>
</div>
<div id="pop-up5" *ngIf="isShowEditPopup" class="dimScreen">
  <div class="add-user-popup new-filter">
    <button class="close" type="button" (click)="closeEditUserFilterPopUp()">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
    </button>
    <div class="body-section fix-size">
      <h3>Edit Filter</h3>
      <div class="content-sec">

        <label>Filter Name<span class="red-set">*</span></label>
        <input type="text" [(ngModel)]="myEditFilterName" (keyup.enter)="updateUserFilter()" placeholder="Enter Name"
          class="form-control" maxlength="30">
        <p *ngIf="myFilterError && myEditFilterName.length > 0" class="error-set red-set">A filter with this name
          already exists.</p>
        <p *ngIf="myFilterNameEmpty && myEditFilterName.length < 1" class="error-set red-set">Please enter the filter
          name.</p>
        <p *ngIf="myEditFilterName.length >= 30" class="error-set red-set">Filter name reached maximum 30 characters
          allowed.</p>
        <button type="button" id="shipmentUpdateBtn" (click)="updateUserFilter()">save</button>
        <button type="button" class="delete-btn" (click)="deleteUserFilter()">delete</button>
      </div>
    </div>
  </div>
</div>
<div class="align-items-center justify-content-xl-end shipment-list-filter">
  <div id="customsSharePopup" *ngIf="customsSharePopup" class="dimScreen">
    <div *ngIf="customsSharePopup" class="add-user-popup">
      <button class="close" type="button" (click)="closeSharePopUp()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
      </button>
      <div class="body-section">
        <h3>Share <span>Customs</span></h3>
        <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" id="tabDetail">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../../../../../assets/img/share/url-img.svg" alt="" />
              URL
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link " id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
              role="tab" aria-controls="profile" aria-selected="false">
              <img src="../../../../../assets/img/share/download-img.svg" alt="download" />
              Download
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div class="discription">
              <p class="text-des">
                Anyone with a Connect account and access to this company will be able to view this list.
              </p>
              <div class="copy-section">
                <input type="text" id="shareUrl" [value]="urlShare" readonly />
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
          <div class="tab-pane fade show justify-content-center" id="profile" role="tabpanel"
            aria-labelledby="profile-tab">
            <div class="discription">
              <p>
                Download all customs with the current filters that are applied to the list.
              </p>
              <button class="add-type mobile-set" (click)="excelExport('download')">
                Download AS Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>