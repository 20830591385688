import {
  Component,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtils } from 'src/app/common/utils';
import { FilterService } from 'src/app/features/header/services/filter.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { SwitchAccountService } from 'src/app/shared/services/switch-account.service';
import { CustomsService } from '../../services/customs.service';
import { Location } from '@angular/common';
import { CustomsCustomFiltersComponent } from '../../component/customs-custom-filters/customs-custom-filters.component';
import { MyGroupService } from 'src/app/features/profile/my-groups/services/my-group.service';
import { environment } from 'src/environments/environment';
import { UserManagmentService } from 'src/app/features/admin/users/services/usermanagment.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { OC3Constants } from 'src/app/common/oc3-constants';
import {
  AI_ModulesName,
  AI_PageName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-customs',
  templateUrl: './customs.component.html',
  styleUrls: ['./customs.component.scss'],
})
export class CustomsComponent implements OnInit {
  private titleService: Title;
  private customsService: CustomsService;
  private activatedRoute: ActivatedRoute;
  private currentSessionUserData: CurrentSessionDataService;
  private filterService: FilterService;
  private switchAccount: SwitchAccountService;
  private location: Location;
  private router: Router;
  private dataSharingService: DataSharingService;
  private myGroupService: MyGroupService;
  private userService: UserManagmentService;
  private fileUploadService: FileUploadService;
  private breakpointObserver: BreakpointObserver;
  apiCall: any = null;
  resentList: any = [];
  defaultFilterArray = [];
  defaultFilterSelectedValue: any = 0;
  loadingPortFilterSelectedArray = [];
  dischargePortFilterSelectedArray = [];
  shipperFilterSelectedArray = [];
  consigneeFilterSelectedArray = [];
  shipmentOriginFilterSelectedArray = [];
  shipmentDestinationFilterSelectedArray = [];
  portOfClearanceFilterSelectedArray = [];
  importerOfRecordFilterSelectedArray = [];
  importerFilterSelectedArray = [];
  supplierFilterSelectedArray = [];
  selectedLoadingPortAccount: any = [];
  selectedDischargePortAccount: any = [];
  selectedShipperAccount: any = [];
  selectedConsigneeAccount: any = [];
  selectedShipmentOriginAccount: any = [];
  selectedShipmentDestinationAccount: any = [];
  selectedPortOfClearanceAccount: any = [];
  selectedImporterOfRecordAccount: any = [];
  selectedImporterAccount: any = [];
  selectedSupplierAccount: any = [];
  dateRangeFilterSelectedArray = [];
  selectedDateRange = [];
  totalCustomsCount = 0;
  isInfiniteDisabled = false;
  filterCheck: any;
  isGroupShare: any = '';
  isProgressShow = false;
  currentUserData: any;
  isCustomFilterShow = false;
  currentAccountData: any;
  isUserHaveAcc: any;
  customsData: any[] = [];
  hasPermission: boolean = true;
  errorMessage = '';
  defaultCustomsFilterSelectedValue = 0;
  defaultCustomsFilterSelectedName: string = 'All Customs';
  combinedSelectedFiltersArray: any[] = [];
  page = 0;
  limit = OC3Constants.CUSTOM_LIST_DEFAULT_COUNT;
  customsSearchText = '';
  defaultCustomsSortSelectedColumn = 'loading_dep';
  defaultCustomsSortDirection = 'desc';
  defaultCustomsSortSelectedValue: number = 1;
  customsDefaultFiltersList = [
    {
      name: 'All Customs',
      value: 0,
    },
    {
      name: 'Prepared',
      value: 1,
    },
    {
      name: 'Filed',
      value: 2,
    },
    {
      name: 'In Progress',
      value: 3,
    },
    {
      name: 'Released',
      value: 4,
    },
    {
      name: 'Archived',
      value: 5,
    },
  ];
  statusFilterSelectedArray: any = [];
  mainFilterSelectedArray: any[] = [];
  transportTypeFilterSelectedArray = [];
  exceptionTypeFilterSelectedArray = [];
  customsTypeFilterSelectedArray = [];
  watchListFilterSelectedArray = [];
  pgaExistsFilterSelectedArray = [];
  url = '';
  saveFilterJson: any = '';
  copyText: any = '';
  isDetailFullView = false;
  popupToggle = false;
  urlCustomsId: any = '';
  getCustomsDetails: any = [];
  selectedCustomsLen: number = 0;
  detailCustomsShare = false;
  customsSharePopup = false;
  publicShareEnrcyptedData: any = '';
  @ViewChild(CustomsCustomFiltersComponent)
  customFilterComponent!: CustomsCustomFiltersComponent;
  myFilterEdit: any = [];
  isShowMessage = false;
  showMessage: any = '';
  isShowMessageDel = false;
  showMessageDel: any = '';
  savedFilterRow = '';
  savedFiltersList: any[] = [];
  filterCheckTemp: any;
  publicShareFilter: any = '';
  publicShareData: any = '';
  publicShareVisibility: any = '';
  publicShareType: any = '';
  appInsightsService: AppInsightsService;

  constructor(private injector: Injector) {
    this.titleService = injector.get<Title>(Title);
    this.customsService = injector.get<CustomsService>(CustomsService);
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.currentSessionUserData = injector.get<CurrentSessionDataService>(
      CurrentSessionDataService
    );
    this.filterService = injector.get<FilterService>(FilterService);
    this.switchAccount =
      injector.get<SwitchAccountService>(SwitchAccountService);
    this.location = injector.get<Location>(Location);
    this.router = injector.get<Router>(Router);
    this.myGroupService = injector.get<MyGroupService>(MyGroupService);
    this.userService = injector.get<UserManagmentService>(UserManagmentService);
    this.fileUploadService = injector.get<FileUploadService>(FileUploadService);
    this.breakpointObserver =
      injector.get<BreakpointObserver>(BreakpointObserver);
    this.dataSharingService =
      injector.get<DataSharingService>(DataSharingService);
    this.appInsightsService =
      injector.get<AppInsightsService>(AppInsightsService);
    this.showPageTitle();
    this.fileUploadService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        let fileUploadPercentage: any[] = [];
        let cancelledDocs: any[] = [];
        setTimeout(() => {
          fileUploadPercentage = res;
          fileUploadPercentage.some((el: any) => {
            if (el.Percentage < 100 && !el.Error) {
              cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
            }
          });
          if (cancelledDocs.length > 0) {
            this.isCustomFilterShow = true;
          } else {
            this.isCustomFilterShow = false;
          }
        }, 0);
      }
    });
    this.breakpointObserver
      .observe(['(min-height: 1100px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.limit = OC3Constants.CUSTOM_LIST_DEFAULT_COUNT + 10;
        } else {
          this.limit = OC3Constants.CUSTOM_LIST_DEFAULT_COUNT;
        }
      });
  }
  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  sortBy = [
    { item: 'Loading Dep.' },
    { item: 'Discharge Arr.' },
    { item: 'Entry Arr.' },
    { item: 'Export Date' },
    { item: 'Filed Date' },
    { item: 'Released Date' },
    { item: 'Status' },
    { item: 'Customs #' },
  ];
  guidedTourJson = [
    {
      heading: 'Customs List',
      para: 'View all your customs here with the Customs Number, Status and overview of the customs route.',
    },
    {
      heading: 'Navigate to Shipment',
      para: 'If the customs is linked to a shipment, you can navigate to the shipment details using this button.',
    },
    {
      heading: 'Quick Filters',
      para: 'Refine your customs list using these default filters based on the customs status to make browsing easier.',
    },
    {
      heading: 'Filters',
      para: 'Still need to filter your customs list further? Use any of these filter options to discover the customs data you need to find. After adding your filters, you can save it to create your own custom quick filters.',
    },
    {
      heading: 'Quick Search',
      para: 'Need to find a customs record? Use this search bar with either the customs number, shipment number, reference number to refine the customs list. Place commas between numbers to search for multiple customs no. at a time.',
    },
    {
      heading: 'Customs Sort',
      para: 'Sort the customs list in ascending or descending order based on these criteria. Click the same sort type again to alternate the sort order.',
    },
    {
      heading: 'Download And Share',
      para: 'Share the customs list with the current filters applied to your list or download the list to an excel file.',
    },
    {
      heading: 'Customs Details',
      para: 'Click on a custom no. in the list screen to view the details, documents, PGA status, and, if available, invoice details.',
    },
  ];
  guidedTourPopup = false;
  guidedTourFlag: any;
  guidedTourFlagNoShipment: any;
  guidedTourCustomsContext = false;
  guidedTourCustomsFilter = false;
  guidedTourCustomsDetail: any = '';
  guidedTourBookingSort = false;
  guidedFeatureModalShow = false;
  guidedFeatureManualModalShow = false;
  guidedTourPopupHeader: any;
  guidedTourPopupPara: any;
  guidedTourSlides: any;
  guidedTourIsConvertedBooking: any[] = [];
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
  }

  ngOnInit(): void {
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
    let isPublicView = false;
    this.sessionUser();
    this.trackAIPageView(isPublicView);
    let encryptedFilter = this.activatedRoute.snapshot.params['filterParam'];
    let encrypFilterDashboard =
      this.activatedRoute.snapshot.queryParams['filterParam'];
    if (encryptedFilter) {
      this.encryptData(encryptedFilter);
    } else if (encrypFilterDashboard) {
      this.encryptData(encrypFilterDashboard);
    } else {
      if (this.currentUserData) {
        this.loadAllCustoms();
        if (!this.isMobileView) {
          this.getUserDetailGuidedTour();
        }
      } else {
        this.dataSharingService.quickSearch.subscribe((value) => {
          if (value.type == 'Detail') {
            isPublicView = true;
            this.publicSearchFn(value);
          } else {
            this.router.navigate(['/login/log-in']);
          }
        });
      }
    }

    if (this.isDetailFullView) {
      this.fileUploadService.reloadCustoms.subscribe((value) => {
        if (value.reload) {
          this.selectedListCustoms(value.shipmentId);
        }
      });
    }
    if (this.currentUserData) {
      this.getUserFilters({ message: '', type: 'start' });
    }
    if (this.currentAccountData) {
      if (!this.currentAccountData.customsIsVisible && encryptedFilter) {
        let decryUrlFilter = JSON.parse(atob(encryptedFilter));
        if (decryUrlFilter.type == 'Detail') {
          this.router.navigate(['/dashboard/' + encryptedFilter]);
        }
      } else if (
        !this.currentAccountData.customsIsVisible &&
        !encryptedFilter
      ) {
        this.router.navigate(['/dashboard']);
      }
    }
    this.customsService.isFullScreenTabEnabled = this.isDetailFullView;
  }

  loadAllCustoms() {
    let getPatameter = this.prepareApiBodyParams();
    if (getPatameter.bodyParam.offset <= 0) {
      this.totalCustomsCount = 0;
      this.isInfiniteDisabled = true;
      setTimeout(() => {
        this.isInfiniteDisabled = false;
      }, 200);
      this.apiCancel();
    }
    this.filterCheck = getPatameter.bodyParam;
    let isGroup: any;
    if (this.isGroupShare != '') {
      isGroup = this.isGroupShare;
    } else {
      isGroup = getPatameter.isGroup;
    }
    this.isProgressShow = true;
    this.apiCall = this.customsService
      .getAllCustoms(
        getPatameter.accountId,
        getPatameter.emailId,
        getPatameter.bodyParam,
        isGroup
      )
      .subscribe(
        (res) => {
          if (this.isUserHaveAcc < 0) {
            this.totalCustomsCount = 0;
            this.customsData = [];
            this.isProgressShow = false;
          } else {
            this.totalCustomsCount = res?.total_count;
            if (res?.result?.includes('does not have persmission')) {
              this.customsData = [];
              this.hasPermission = false;
              this.errorMessage =
                'You do not have a permission to access this account. Please select an another account!';
            } else {
              this.customsData = [...this.customsData, ...res?.result];
              this.hasPermission = true;
              this.errorMessage = '';
            }
            this.isProgressShow = false;
          }
          this.isGroupShare = '';
        },
        (error: any) => {
          this.isProgressShow = false;
        }
      );
  }

  prepareApiBodyParams() {
    this.sessionUser();
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let filterOption = [];
    if (this.combinedSelectedFiltersArray.length > 0) {
      filterOption = this.combinedSelectedFiltersArray;
    }
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: this.customsSearchText,
      parameters: [],
      sort_options: [
        {
          column_name: this.defaultCustomsSortSelectedColumn,
          direction: this.defaultCustomsSortDirection,
        },
      ],
      filter_options: filterOption,
    };

    return { bodyParam, accountId, emailId, isGroup };
  }

  public apiCancel() {
    if (this.apiCall != null) {
      this.apiCall.unsubscribe();
      this.customsService.stopRequest.next();
    }
  }

  showPageTitle() {
    CommonUtils.setPageTitle(this.titleService, 'Customs');
  }

  onDefaultFilterSelectedHandler(event: any) {
    this.customsSearchText = '';
    this.page = 0;
    if (this.myFilterEdit?.isEditTrue) {
      this.customFilterComponent.clearAllFilters();
      this.myFilterEdit = {
        savedValue: '',
        isEditTrue: false,
      };
      this.combinedSelectedFiltersArray = [];
      this.statusFilterSelectedArray = [];
      this.transportTypeFilterSelectedArray = [];
      this.exceptionTypeFilterSelectedArray = [];
      this.customsTypeFilterSelectedArray = [];
      this.importerOfRecordFilterSelectedArray = [];
      this.consigneeFilterSelectedArray = [];
      this.importerFilterSelectedArray = [];
      this.supplierFilterSelectedArray = [];
      this.loadingPortFilterSelectedArray = [];
      this.dischargePortFilterSelectedArray = [];
      this.dateRangeFilterSelectedArray = [];
      this.watchListFilterSelectedArray = [];
      this.mainFilterSelectedArray = [];
      this.customsSearchText = '';
      this.defaultFilterArray = [];
      this.shipperFilterSelectedArray = [];
      this.portOfClearanceFilterSelectedArray = [];
      this.shipmentOriginFilterSelectedArray = [];
      this.shipmentDestinationFilterSelectedArray = [];
      this.selectedImporterAccount = [];
      this.selectedConsigneeAccount = [];
      this.selectedDischargePortAccount = [];
      this.selectedImporterOfRecordAccount = [];
      this.selectedPortOfClearanceAccount = [];
      this.selectedLoadingPortAccount = [];
      this.selectedShipmentDestinationAccount = [];
      this.selectedShipmentOriginAccount = [];
      this.selectedShipperAccount = [];
      this.selectedSupplierAccount = [];
      this.defaultCustomsFilterSelectedValue = 0;
    }
    this.mainFilterSelectedArray = [
      { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 1 },
    ];
    this.customsSearchText = '';
    this.defaultCustomsFilterSelectedValue = event?.value;
    this.defaultCustomsFilterSelectedName = event?.name;
    let selectedFilters = {
      mainFilter: [
        { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 1 },
      ],
      statusFilter: [
        {
          column_name: 'statuscustom',
          operator: '=',
          value: event.value.toString(),
        },
      ],
      transportType: [],
      customsType: [],
      watchFilter: [],
      exceptionType: [],
      pgaFilter: [],
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },
      consignee: {
        selectedValue: [],
        selectedAccount: [],
      },
      importer: {
        selectedValue: [],
        selectedAccount: [],
      },
      importerOfRecord: {
        selectedValue: [],
        selectedAccount: [],
      },
      supplier: {
        selectedValue: [],
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { loadingPort: [] },
          { dischargePort: [] },
          { shipmentOrigin: [] },
          { shipmentDestination: [] },
          { portOfClearance: [] },
        ],
        selectedValue: [
          { loadingPortValue: [] },
          { dischargePortValue: [] },
          { shipmentOriginValue: [] },
          { shipmentDestinationValue: [] },
          { portOfClearanceValue: [] },
        ],
      },
      sorting: [],
      accountId: '',
      customsId: '',
      type: 'defaultFilter',
    };
    if (this.statusFilterSelectedArray.length > 0) {
      this.setCloseButtonTimeout();
    } else {
      this.setCloseButtonTimeoutWithoutCallApi();
    }
    setTimeout(() => {
      if (event?.value != 0) {
        this.setUrlFilters(selectedFilters, false);
      }
    }, 100);
    setTimeout(() => {
      let test: any[] = [];
      if (event?.value > 0) {
        test.push({
          column_name: 'statuscustom',
          operator: '=',
          value: event?.value.toString(),
        });
      }
      let statusSelect: any = '';
      if (event?.value > 0) {
        statusSelect = { type: 'statuscustom', selectedValue: test };

        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      } else if (event?.value == 0) {
        statusSelect = { type: 'statuscustom', selectedValue: [] };
        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      }
      this.onSelectedCustomFilter(statusSelect);
    }, 1000);
  }

  sessionUser() {
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
  }

  getListingOffset(event: any) {
    this.page = event;
    this.loadAllCustoms();
  }

  emptyCustomList() {
    this.customsData = [];
    this.customsData.splice(0, this.customsData.length);
    this.page = 0;
  }

  clearSearchFilter(e: any) {
    this.customsSearchText = '';
    this.emptyCustomList();
    this.loadAllCustoms();
  }

  onSelectedCustomFilter(event: any) {
    this.combinedSelectedFiltersArray = [];
    if (event) {
      if (event.type == 'statuscustom') {
        this.statusFilterSelectedArray = event.selectedValue;

        let statusChecked =
          event.selectedValue.length > 0
            ? event.selectedValue[0].value.split(',')
            : '';
        if (statusChecked.length == 1) {
          if (this.defaultCustomsFilterSelectedValue !== statusChecked[0]) {
            this.defaultCustomsFilterSelectedValue = parseInt(statusChecked[0]);
          }
        } else if (
          ((!statusChecked || statusChecked.length == 0) &&
            event.clearFrom !== 'defaultFilter') ||
          statusChecked.length > 0
        ) {
          this.defaultCustomsFilterSelectedValue = 0;
        }
      } else if (event.type == 'transport') {
        this.transportTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'exception') {
        this.exceptionTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'customstype') {
        this.customsTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'Loading Port') {
        if (event.selectedValue) {
          // event.selectedValue.column_name = 'loading';
          this.loadingPortFilterSelectedArray = event.selectedValue;
        }
        this.selectedLoadingPortAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let loadingPortJsonGet = sessionStorage.getItem(
            'CustomsRecentLoadingPort'
          );
          this.filterRecentSearch(loadingPortJsonGet, event);
        }
      } else if (event.type == 'Discharge Port') {
        if (event.selectedValue) {
          // event.selectedValue.column_name = 'Discharge Port';
          this.dischargePortFilterSelectedArray = event.selectedValue;
        }
        this.selectedDischargePortAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let dischargePortJsonGet = sessionStorage.getItem(
            'CustomsRecentDischargePort'
          );
          this.filterRecentSearch(dischargePortJsonGet, event);
        }
      } else if (event.type == 'shipper') {
        this.shipperFilterSelectedArray = event.selectedValue;
        this.selectedShipperAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let shipperJsonGet = sessionStorage.getItem('customsRecentShipper');
          this.filterRecentSearch(shipperJsonGet, event);
        }
      } else if (event.type == 'Port of Clearance') {
        if (event.selectedValue) {
          // event.selectedValue.column_name = 'Port of Clearance';
          this.portOfClearanceFilterSelectedArray = event.selectedValue;
        }
        this.selectedPortOfClearanceAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let portOfClearanceJsonGet = sessionStorage.getItem(
            'CustomsRecentPortOfClearance'
          );
          this.filterRecentSearch(portOfClearanceJsonGet, event);
        }
      } else if (event.type == 'Shipment Origin') {
        if (event.selectedValue) {
          // event.selectedValue.column_name = 'Shipment Origin';
          this.shipmentOriginFilterSelectedArray = event.selectedValue;
        }
        this.selectedShipmentOriginAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let shipmentOriginJsonGet = sessionStorage.getItem(
            'CustomsRecentShipmentOrigin'
          );
          this.filterRecentSearch(shipmentOriginJsonGet, event);
        }
      } else if (event.type == 'Shipment Destination') {
        if (event.selectedValue) {
          // event.selectedValue.column_name = 'Shipment Destination';
          this.shipmentDestinationFilterSelectedArray = event.selectedValue;
        }
        this.selectedShipmentDestinationAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let shipmentDestinationJsonGet = sessionStorage.getItem(
            'CustomsRecentShipmentDestination'
          );
          this.filterRecentSearch(shipmentDestinationJsonGet, event);
        }
      } else if (event.type == 'dateRange') {
        this.dateRangeFilterSelectedArray = event.selectedValue;
        this.selectedDateRange = event.selectedDateRange;
      } else if (event.type == 'favorites') {
        this.watchListFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'pga') {
        this.pgaExistsFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'allLocation') {
        this.portOfClearanceFilterSelectedArray = [];
        this.shipmentOriginFilterSelectedArray = [];
        this.shipmentDestinationFilterSelectedArray = [];
        this.loadingPortFilterSelectedArray = [];
        this.dischargePortFilterSelectedArray = [];
        this.selectedPortOfClearanceAccount = [];
        this.selectedShipmentOriginAccount = [];
        this.selectedShipmentDestinationAccount = [];
        this.selectedLoadingPortAccount = [];
        this.selectedDischargePortAccount = [];
      } else if (event.type == 'importer_of_record') {
        this.importerOfRecordFilterSelectedArray = event.selectedValue;
        this.selectedImporterOfRecordAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let importerOfRecordJsonGet = sessionStorage.getItem(
            'customsRecentImporterOfRecord'
          );
          this.filterRecentSearch(importerOfRecordJsonGet, event);
        }
      } else if (event.type == 'importer') {
        this.importerFilterSelectedArray = event.selectedValue;
        this.selectedImporterAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let importerJsonGet = sessionStorage.getItem('customsRecentImporter');
          this.filterRecentSearch(importerJsonGet, event);
        }
      } else if (event.type == 'consignee') {
        this.consigneeFilterSelectedArray = event.selectedValue;
        this.selectedConsigneeAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let consigneeJsonGet = sessionStorage.getItem(
            'customsRecentConsignee'
          );
          this.filterRecentSearch(consigneeJsonGet, event);
        }
      } else if (event.type == 'supplier') {
        this.supplierFilterSelectedArray = event.selectedValue;
        this.selectedSupplierAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let supplierJsonGet = sessionStorage.getItem('customsRecentSupplier');
          this.filterRecentSearch(supplierJsonGet, event);
        }
      }
      this.combinedSelectedFiltersArray = [].concat(
        this.statusFilterSelectedArray,
        this.transportTypeFilterSelectedArray,
        this.exceptionTypeFilterSelectedArray,
        this.watchListFilterSelectedArray,
        this.dateRangeFilterSelectedArray,
        this.customsTypeFilterSelectedArray,
        this.pgaExistsFilterSelectedArray,
        this.importerOfRecordFilterSelectedArray,
        this.shipperFilterSelectedArray,
        this.consigneeFilterSelectedArray,
        this.importerFilterSelectedArray,
        this.supplierFilterSelectedArray,
        this.loadingPortFilterSelectedArray,
        this.dischargePortFilterSelectedArray,
        this.defaultFilterArray,
        this.portOfClearanceFilterSelectedArray,
        this.shipmentOriginFilterSelectedArray,
        this.shipmentDestinationFilterSelectedArray
      );
    } else {
      this.combinedSelectedFiltersArray = [];
    }
    if (event?.clearFrom != 'defaultFilter') {
      this.emptyCustomList();
      this.loadAllCustoms();
      if (event?.selectedValue?.length == 0) {
        this.defaultCustomsFilterSelectedValue = 0;
      }
    }
  }

  clearAllFilterValue(e: any) {
    this.combinedSelectedFiltersArray = [];
    this.statusFilterSelectedArray = [];
    this.transportTypeFilterSelectedArray = [];
    this.exceptionTypeFilterSelectedArray = [];
    this.loadingPortFilterSelectedArray = [];
    this.dischargePortFilterSelectedArray = [];
    this.dateRangeFilterSelectedArray = [];
    this.watchListFilterSelectedArray = [];
    this.mainFilterSelectedArray = [];
    this.customsSearchText = '';
    this.defaultFilterArray = [];
    this.shipperFilterSelectedArray = [];
    this.shipmentOriginFilterSelectedArray = [];
    this.shipmentDestinationFilterSelectedArray = [];
    this.portOfClearanceFilterSelectedArray = [];
    this.selectedLoadingPortAccount = [];
    this.selectedDischargePortAccount = [];
    this.selectedShipmentOriginAccount = [];
    this.selectedShipmentDestinationAccount = [];
    this.selectedPortOfClearanceAccount = [];
    this.selectedShipperAccount = [];
    this.customsTypeFilterSelectedArray = [];
    this.pgaExistsFilterSelectedArray = [];
    if (e != 'saveButton') {
      this.defaultCustomsFilterSelectedValue = 0;
      this.emptyCustomList();
      this.customsSearchText = '';
      this.loadAllCustoms();
    }
  }

  getMainFilterSelection(event: any) {
    let selectedId: any[] = [];
    event.forEach((element: any) => {
      selectedId.push({
        id: element.id,
        filterDisplayName: element.filterDisplayName,
        type: element.type,
        index: element.index,
      });
    });
    this.mainFilterSelectedArray = selectedId;
  }

  setCloseButtonTimeout() {
    setTimeout(() => {
      this.customFilterComponent.closeCustomFilters({
        type: 'statuscustom',
        index: 1,
        id: 1,
        eventType: 'closeBtn',
        closeFrom: 'defaultFilter',
      });
    }, 100);
  }
  setCloseButtonTimeoutWithoutCallApi() {
    setTimeout(() => {
      this.customFilterComponent.closeCustomFilters({
        type: 'statuscustom',
        index: 1,
        id: 1,
        eventType: 'closeBtn',
        closeFrom: 'clearFilterOnly',
      });
    }, 100);
  }

  // setUrlFilters(filters: any, encryptedFilter: any) {
  //   if (
  //     this.currentUserData.userType == 'user' &&
  //     filters.type != 'defaultFilter'
  //   ) {
  //     this.switchAccountUser(filters, encryptedFilter);
  //   } else if (
  //     this.currentUserData.userType == 'systemadmin' &&
  //     filters.type != 'defaultFilter'
  //   ) {
  //     this.switchAccountAdmin(filters, encryptedFilter);
  //   } else if (filters.type == 'defaultFilter') {
  //     this.urlFilterCall(filters);
  //   } else {
  //     this.isGroupShare = filters.accountDetail.isGroup;
  //     this.urlFilterCall(filters);
  //   }
  // }

  urlFilterCall(filters: any) {
    if (
      filters.type != 'Detail' &&
      filters.type != 'Single' &&
      filters.type != 'All'
    ) {
      setTimeout(() => {
        this.customFilterComponent.loadURLFilters(filters);
      }, 300);
    }
    this.urlFilterSort(filters);
    if (filters.mainFilter.length < 1 && filters.type == 'defaultFilter') {
      //this.urlBookingId = filters.bookingId;
      this.customsSearchText = filters.customsId;
      this.loadAllCustoms();
    }
    if (filters.type == 'Single') {
      this.urlCustomsId = filters.customsId;
    }
    if (filters.type == 'Detail' && filters.subType != 'publicSearch') {
      this.publicShareFilter = filters;
      let getPatameter = this.prepareApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'customsNumber',
            value: filters.customsId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultCustomsSortSelectedColumn,
            direction: this.defaultCustomsSortDirection,
          },
        ],
        filter_options: [],
      };
      this.customsService
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.isProgressShow = false;
          if (res.result[0].isFullViewAccess) {
            this.selectedListCustoms(filters.customsId);
            setTimeout(() => {
              this.popupToggle = true;
              setTimeout(() => {
                this.urlCustomsId = filters.customsId;
                this.selectedCustomsLen = Object.keys(
                  this.getCustomsDetails
                ).length;
              }, 0);
            }, 0);
          } else {
            setTimeout(() => {
              this.popupToggle = true;
              this.getCustomsDetails = res.result[0];
              this.selectedCustomsLen = Object.keys(
                this.getCustomsDetails
              ).length;
            }, 0);
            this.urlCustomsId = filters.customsId;
          }
        });
    } else if (filters.type == 'Detail' && filters.subType == 'publicSearch') {
      this.publicShareFilter = filters;
      this.customsPublicSearchShare('url');
    } else if (filters.type == 'All') {
      this.setGroupUrlFilterCriteria(filters);
    }
  }

  urlFilterSort(filters: any) {
    let len = 0;

    if (filters && filters?.sorting) {
      let keys = Object.keys(filters?.sorting);
      len = keys?.length;
    }

    if (len > 0) {
      this.defaultCustomsSortSelectedColumn = filters.sorting
        ? filters.sorting.selectedValue.column_name
        : 'loading_dep';
      this.defaultCustomsSortDirection = filters.sorting
        ? filters.sorting.selectedValue.direction
        : 'desc';
      this.defaultCustomsSortSelectedValue = filters.sorting
        ? filters.sorting.sortId
        : 1;
    }
  }

  getAllURLFilter(event: any) {
    this.mainFilterSelectedArray = event.mainfilter;
    this.statusFilterSelectedArray = event.status;
    this.transportTypeFilterSelectedArray = event.transport;
    this.exceptionTypeFilterSelectedArray = event.exception;
    this.customsTypeFilterSelectedArray = event.customs;
    this.watchListFilterSelectedArray = event.watchFilter;
    this.pgaExistsFilterSelectedArray = event.pgaFilter;
    this.shipperFilterSelectedArray = event.shipper;
    this.selectedShipperAccount = event.shipperAccount;
    this.consigneeFilterSelectedArray = event.consignee;
    this.selectedConsigneeAccount = event.consigneeAccount;
    this.supplierFilterSelectedArray = event.supplier;
    this.selectedSupplierAccount = event.supplierAccount;
    this.importerFilterSelectedArray = event.importer;
    this.selectedImporterAccount = event.importerAccount;
    this.importerOfRecordFilterSelectedArray = event.importerOfRecord;
    this.selectedImporterOfRecordAccount = event.importerOfRecordAccount;
    this.dateRangeFilterSelectedArray = event.dateRange;
    this.loadingPortFilterSelectedArray = event.loadingPort;
    this.dischargePortFilterSelectedArray = event.dischargePort;
    this.shipmentOriginFilterSelectedArray = event.shipmentOrigin;
    this.shipmentDestinationFilterSelectedArray = event.shipmentDestination;
    this.portOfClearanceFilterSelectedArray = event.portOfClearance;

    this.selectedLoadingPortAccount = event.loadingPortAcc;
    this.selectedShipmentOriginAccount = event.shipmentOriginAcc;
    this.selectedDischargePortAccount = event.dischargePortAcc;
    this.selectedShipmentDestinationAccount = event.shipmentDestinationAcc;
    this.selectedPortOfClearanceAccount = event.portOfClearanceAcc;
    this.combinedSelectedFiltersArray = [].concat(
      this.statusFilterSelectedArray,
      this.transportTypeFilterSelectedArray,
      this.exceptionTypeFilterSelectedArray,
      this.customsTypeFilterSelectedArray,
      this.dateRangeFilterSelectedArray,
      this.watchListFilterSelectedArray,
      this.pgaExistsFilterSelectedArray,
      this.shipperFilterSelectedArray,
      this.consigneeFilterSelectedArray,
      this.supplierFilterSelectedArray,
      this.importerFilterSelectedArray,
      this.importerOfRecordFilterSelectedArray,
      this.loadingPortFilterSelectedArray,
      this.dischargePortFilterSelectedArray,
      this.shipmentOriginFilterSelectedArray,
      this.shipmentDestinationFilterSelectedArray,
      this.portOfClearanceFilterSelectedArray
    );
    this.customsSearchText = event.customsId;
    this.emptyCustomList();
    this.loadAllCustoms();
  }

  onCustomsSearchHandler(emit: any) {
    this.customsSearchText = '';
    this.customsSearchText = emit.searchText;
    if (
      emit.event.keyCode == 13 ||
      emit.event.key == 'Enter' ||
      emit.event.type === 'click'
    ) {
      this.emptyCustomList();
      this.loadAllCustoms();
    }
  }

  onSortSelectedHandler(event: any) {
    this.defaultCustomsSortSelectedValue = event.id;
    this.defaultCustomsSortSelectedColumn = event.sort_column;
    this.defaultCustomsSortDirection = event.sort_direction;
    this.emptyCustomList();
    this.loadAllCustoms();
  }

  filterRecentSearch(filterJsonGet: any, event: any) {
    let filterResentGet =
      filterJsonGet !== null ? JSON.parse(filterJsonGet) : '';
    let filterRecentList: any[] = [];
    if (filterResentGet) {
      let elementPos: any;
      event.account.forEach((element: any) => {
        if (
          event.type == 'shipper' ||
          event.type == 'consignee' ||
          event.type === 'importer_of_record' ||
          event.type == 'supplier' ||
          event.type == 'importer'
        ) {
          elementPos = filterResentGet
            .map(function (e: any) {
              return e.target_party_id;
            })
            .indexOf(element.target_party_id);
        } else if (
          event.type == 'Loading Port' ||
          event.type == 'Discharge Port' ||
          event.type == 'Shipment Origin' ||
          event.type == 'Shipment Destination' ||
          event.type == 'Port of Clearance'
        ) {
          elementPos = filterResentGet
            .map(function (elementLoad: any) {
              return elementLoad.index;
            })
            .indexOf(element.index);
        }

        if (elementPos < 0) {
          filterRecentList = [].concat(element, filterResentGet);
        } else {
          filterRecentList = [].concat(filterResentGet);
        }
      });
    } else {
      if (event.account.length > 0) {
        filterRecentList = [].concat(event.account);
      } else {
        filterRecentList = [].concat(filterResentGet);
      }
    }
    setTimeout(() => {
      let filterOutput = filterRecentList.slice(0, 3);

      if (event.account) {
        switch (event.type) {
          case 'Loading Port':
            sessionStorage.setItem(
              'CustomsRecentLoadingPort',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Discharge Port':
            sessionStorage.setItem(
              'CustomsRecentDischargePort',
              JSON.stringify(filterOutput)
            );
            break;
          case 'shipper':
            sessionStorage.setItem(
              'customsRecentShipper',
              JSON.stringify(filterOutput)
            );
            break;
          case 'consignee':
            sessionStorage.setItem(
              'customsRecentConsignee',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Shipment Origin':
            sessionStorage.setItem(
              'CustomsRecentShipmentOrigin',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Shipment Destination':
            sessionStorage.setItem(
              'CustomsRecentShipmentDestination',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Port of Clearance':
            sessionStorage.setItem(
              'CustomsRecentPortOfClearance',
              JSON.stringify(filterOutput)
            );
            break;
          case 'importer_of_record':
            sessionStorage.setItem(
              'customsRecentImporterOfRecord',
              JSON.stringify(filterOutput)
            );
            break;
          case 'importer':
            sessionStorage.setItem(
              'customsRecentImporter',
              JSON.stringify(filterOutput)
            );
            break;
          case 'supplier':
            sessionStorage.setItem(
              'customsRecentSupplier',
              JSON.stringify(filterOutput)
            );
            break;
        }
      }
      let filterJsonSet: any;
      switch (event.type) {
        case 'Loading Port':
          filterJsonSet = sessionStorage.getItem('CustomsRecentLoadingPort');
          break;
        case 'Discharge Port':
          filterJsonSet = sessionStorage.getItem('CustomsRecentDischargePort');
          break;
        case 'Shipment Origin':
          filterJsonSet = sessionStorage.getItem('CustomsRecentShipmentOrigin');
          break;
        case 'Shipment Destination':
          filterJsonSet = sessionStorage.getItem(
            'CustomsRecentShipmentDestination'
          );
          break;
        case 'Port of Clearance':
          filterJsonSet = sessionStorage.getItem(
            'CustomsRecentPortOfClearance'
          );
          break;
        case 'shipper':
          filterJsonSet = sessionStorage.getItem('customsRecentShipper');
          break;
        case 'consignee':
          filterJsonSet = sessionStorage.getItem('customsRecentConsignee');
          break;
        case 'importer_of_record':
          filterJsonSet = sessionStorage.getItem(
            'customsRecentImporterOfRecord'
          );
          break;
        case 'importer':
          filterJsonSet = sessionStorage.getItem('customsRecentImporter');
          break;
        case 'supplier':
          filterJsonSet = sessionStorage.getItem('customsRecentSupplier');
          break;
      }
      let filterResentSet =
        filterJsonSet !== null ? JSON.parse(filterJsonSet) : '';

      this.resentList = {
        list: filterResentSet,
        type: event.type,
      };
    }, 1000);
  }

  prepareExcelDownload(event: any) {
    let getPatameter = this.prepareApiBodyParams();
    this.isProgressShow = true;
    this.customsService
      .prepareExcelDowload(
        getPatameter.accountId,
        getPatameter.emailId,
        getPatameter.bodyParam,
        getPatameter.isGroup
      )
      .subscribe((res: Blob) => {
        this.downloadFile(res);
        this.isProgressShow = false;
      });
  }

  downloadFile(data: any) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    let today = new Date();

    let date =
      today.getFullYear() +
      CommonUtils.padding2(today.getMonth() + 1) +
      today.getDate();

    let time =
      CommonUtils.padding2(today.getHours()) +
      CommonUtils.padding2(today.getMinutes()) +
      CommonUtils.padding2(today.getSeconds());
    let dateTime = date + '_' + time;
    let filename =
      this.currentAccountData.accountAliasName +
      '_Customs_' +
      dateTime +
      '.xlsx';
    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
    URL.revokeObjectURL(url);
  }

  encryptData(encryptedFilter: any) {
    if (encryptedFilter) {
      let decryUrlFilter = JSON.parse(atob(encryptedFilter));
      if (decryUrlFilter.type == 'Single' && this.currentUserData) {
        this.isDetailFullView = true;
        this.location.replaceState('/customs');
        if (this.currentUserData.userType == 'user') {
          this.switchAccountUser(decryUrlFilter, encryptedFilter);
        } else if (this.currentUserData.userType == 'systemadmin') {
          this.switchAccountAdmin(decryUrlFilter, encryptedFilter);
        }
        this.singleShareApply(decryUrlFilter);
      } else if (decryUrlFilter.type == 'All' && this.currentUserData) {
        this.customsService
          .getShareFilter(decryUrlFilter.id)
          .subscribe((res: any) => {
            let decryptFilter = JSON.parse(res.result.jsonFilter);
            if (decryptFilter?.statusFilter[0]?.value?.length === 1) {
              this.defaultCustomsFilterSelectedValue =
                decryptFilter?.statusFilter[0]?.value;
            }
            if (decryptFilter.accountDetail.byGroup) {
              this.isProgressShow = true;
            }
            this.location.replaceState('/customs');
            this.setUrlFilters(decryptFilter, encryptedFilter);
          });
      } else if (
        (decryUrlFilter.type === 'Detail' &&
          decryUrlFilter.type != 'Single' &&
          decryUrlFilter.type != 'All') ||
        this.currentUserData
      ) {
        this.location.replaceState('/customs');
        this.isProgressShow = true;
        if (decryUrlFilter.type === 'Detail') {
          this.isDetailFullView = true;
        }
        this.setUrlFilters(decryUrlFilter, encryptedFilter);

        this.publicShareEnrcyptedData = encryptedFilter;
      } else {
        this.router.navigate(['/login/log-in'], {
          queryParams: { returnUrl: '/customs/' + encryptedFilter },
        });
      }
    }
  }

  setUrlFilters(filters: any, encryptedFilter: any) {
    if (
      this.currentUserData.userType == 'user' &&
      filters.type != 'defaultFilter'
    ) {
      this.switchAccountUser(filters, encryptedFilter);
    } else if (
      this.currentUserData.userType == 'systemadmin' &&
      filters.type != 'defaultFilter'
    ) {
      this.switchAccountAdmin(filters, encryptedFilter);
    } else if (filters.type == 'defaultFilter') {
      this.urlFilterCall(filters);
    } else {
      this.isGroupShare = filters.accountDetail.isGroup;
      this.urlFilterCall(filters);
    }
  }

  switchAccountUser(filters: any, encryptedFilter: any) {
    if (filters.accountDetail?.oH_Code && !filters.accountDetail?.byGroup) {
      this.filterService
        .getData(
          environment.base_api_desktop_url +
            'useraccount?userEmailAddresss=' +
            this.currentUserData.emailAddress
        )
        .subscribe((res) => {
          this.isUserHaveAcc = res.result
            .map(function (x: any) {
              return x.oH_Code;
            })
            .indexOf(filters.accountDetail.oH_Code);
          if (this.isUserHaveAcc >= 0) {
            const isSwitchAccount = this.switchAccount.switchAccount(
              filters.accountDetail,
              'shareUrlCustom',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              if (filters.type == 'All') {
                this.urlFilterSort(filters);
                setTimeout(() => {
                  this.customFilterComponent.loadURLFilters(filters);
                }, 300);
              } else {
                this.urlFilterCall(filters);
              }
            }
          } else {
            if (
              filters.type == 'All' &&
              filters.accountDetail?.isControllingCustomer
            ) {
              this.publicShareLimitedApiUser(filters, encryptedFilter);
            } else if (filters.type == 'Detail') {
              this.urlFilterCall(filters);
            }
          }
        });
    } else if (filters.accountDetail?.byGroup) {
      this.publicShareLimitedApiUser(filters, encryptedFilter);
    } else {
      this.urlFilterCall(filters);
    }
  }

  switchAccountAdmin(filters: any, encryptedFilter: any) {
    if (filters.accountDetail?.oH_Code && !filters.accountDetail?.byGroup) {
      const switchAcc = this.switchAccount.switchAccount(
        filters.accountDetail,
        'shareUrlCustom',
        encryptedFilter
      );
      if (!switchAcc) {
        if (filters.type == 'All') {
          this.urlFilterSort(filters);
          setTimeout(() => {
            this.customFilterComponent.loadURLFilters(filters);
          }, 300);
        } else {
          this.urlFilterCall(filters);
        }
      }
    } else if (filters.accountDetail?.byGroup) {
      this.publicShareLimitedApiAdmin(filters, encryptedFilter);
    } else {
      this.urlFilterCall(filters);
    }
  }

  publicShareLimitedApiUser(filters: any, encryptedFilter: any) {
    if (filters.type == 'Detail') {
      let getPatameter = this.prepareApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'customsNumber',
            value: filters.customsId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultCustomsSortSelectedColumn,
            direction: this.defaultCustomsSortDirection,
          },
        ],
        filter_options: [],
      };
      this.customsService
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.getCustomsDetails = res.result[0];
          this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
          this.filterService
            .getData(
              environment.base_api_desktop_url +
                'useraccount?userEmailAddresss=' +
                this.currentUserData.emailAddress
            )
            .subscribe((response) => {
              this.isUserHaveAcc = response.result
                .map(function (x: any) {
                  return x.oH_Code;
                })
                .indexOf(this.getCustomsDetails.accountId);
              if (this.isUserHaveAcc >= 0) {
                let accountDetail = {
                  aliasName: this.getCustomsDetails.account_alias_name,
                  oH_Code: this.getCustomsDetails.accountId,
                  byGroup: false,
                  isControllingCustomer: false,
                  shipment: {
                    isVisible:
                      this.getCustomsDetails.account_shipment.isVisible,
                  },
                  booking: {
                    isVisible: this.getCustomsDetails.account_booking.isVisible,
                  },
                  customs: {
                    isVisible: this.getCustomsDetails.account_customs.isVisible,
                  },
                };
                const isSwitchAccount = this.switchAccount.switchAccount(
                  accountDetail,
                  'shareUrlCustom',
                  encryptedFilter
                );
                if (!isSwitchAccount) {
                  if (filters.type == 'All' || filters.type == 'Detail') {
                    this.urlFilterCall(filters);
                  }
                }
              } else {
                if (filters.type == 'Detail') {
                  this.urlFilterCall(filters);
                }
              }
            });
        });
    } else if (filters.type == 'All') {
      let groupCount: any = localStorage.getItem('groupTotalCount') || 0;
      let totalGroup: number;
      if (parseInt(groupCount) > 0) {
        totalGroup = parseInt(groupCount);
      } else {
        totalGroup = 0;
      }
      if (totalGroup > 0) {
        this.groupSwitching(filters, encryptedFilter);
      } else {
        this.userService
          .getUserDetail(this.currentUserData.emailAddress)
          .subscribe((accountRes: any) => {
            let accountDetail = {
              aliasName: accountRes.result.accountUserRole.alias_name,
              oH_Code: accountRes.result.accountUserRole.accountId,
              byGroup: false,
              isControllingCustomer: false,
              shipment: { isVisible: false },
              booking: { isVisible: false },
              customs: { isVisible: false },
            };

            this.isUserHaveAcc = accountRes.result;
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlCustom',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              this.urlFilterCall(filters);
            }
          });
      }
    }
  }

  publicShareLimitedApiAdmin(filters: any, encryptedFilter: any) {
    if (filters.type == 'Detail') {
      let getPatameter = this.prepareApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'customsNumber',
            value: filters.customsId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultCustomsSortSelectedColumn,
            direction: this.defaultCustomsSortDirection,
          },
        ],
        filter_options: [],
      };
      this.customsService
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.getCustomsDetails = res.result[0];
          this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
          if (res.result.length > 0) {
            let accountDetail = {
              aliasName: this.getCustomsDetails.account_alias_name,
              oH_Code: this.getCustomsDetails.accountId,
              byGroup: false,
              isControllingCustomer: false,
              shipment: {
                isVisible: this.getCustomsDetails.account_shipment.isVisible,
              },
              booking: {
                isVisible: this.getCustomsDetails.account_booking.isVisible,
              },
              customs: {
                isVisible: this.getCustomsDetails.account_customs.isVisible,
              },
            };
            const switchAcc = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlCustom',
              encryptedFilter
            );
            if (!switchAcc) {
              this.urlFilterCall(filters);
            }
          }
        });
    } else if (filters.type == 'All') {
      let groupCount: any = localStorage.getItem('groupTotalCount') || 0;
      let totalGroup: number;
      if (parseInt(groupCount) > 0) {
        totalGroup = parseInt(groupCount);
      } else {
        totalGroup = 0;
      }
      if (totalGroup > 0) {
        this.groupSwitching(filters, encryptedFilter);
      } else {
        this.filterService
          .getAutoCompleteSearchRecords('', 0, 1)
          .subscribe((accountRes: any) => {
            let accountDetail = {
              aliasName: accountRes.result[0].aliasName,
              oH_Code: accountRes.result[0].oH_Code,
              byGroup: false,
              isControllingCustomer: false,
              shipment: { isVisible: accountRes.result[0].booking.isVisible },
              booking: { isVisible: accountRes.result[0].shipment.isVisible },
              customs: { isVisible: accountRes.result[0].custom.isVisible },
            };
            this.isUserHaveAcc = accountRes.result;
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlCustom',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              this.urlFilterCall(filters);
            }
          });
      }
    }
  }

  publicShareLimitedApi(filters: any) {
    let getPatameter = this.prepareApiBodyParams();
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: '',
      parameters: [
        {
          name: 'customsNumber',
          value: filters.customsId,
        },
      ],
      sort_options: [
        {
          column_name: this.defaultCustomsSortSelectedColumn,
          direction: this.defaultCustomsSortDirection,
        },
      ],
      filter_options: [],
    };
    this.customsService
      .publicShare(
        filters.accountDetail.oH_Code,
        getPatameter.emailId,
        bodyParam,
        filters.accountDetail.byGroup,
        filters.accountDetail.isControllingCustomer
      )
      .subscribe((res: any) => {
        this.getCustomsDetails = res.result[0];
        this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
      });
  }

  groupSwitching(filters: any, encryptedFilter: any) {
    this.myGroupService
      .getGroupName(this.currentUserData.emailAddress)
      .subscribe((res: any) => {
        this.isUserHaveAcc = res.result
          .map(function (x: any) {
            return x.groupName.trim().toLowerCase();
          })
          .indexOf(filters.accountDetail.aliasName.trim().toLowerCase());
        let switchGroup = res.result.filter(
          (x: any) =>
            x.groupName.trim().toLowerCase() ==
            filters.accountDetail.aliasName.trim().toLowerCase()
        );
        if (this.isUserHaveAcc >= 0) {
          let accountDetail1 = {
            aliasName: switchGroup[0].groupName,
            oH_Code: switchGroup[0].id,
            byGroup: true,
            isControllingCustomer: false,
            shipment: { isVisible: false },
            booking: { isVisible: false },
            customs: { isVisible: false },
          };
          const isSwitchAccount = this.switchAccount.switchAccount(
            accountDetail1,
            'shareUrlCustom',
            encryptedFilter
          );
          if (!isSwitchAccount) {
            this.setGroupUrlFilterCriteria(filters);
          }
        } else {
          let accountDetail2 = {
            aliasName: res.result[0].groupName,
            oH_Code: res.result[0].id,
            byGroup: true,
            isControllingCustomer: false,
            shipment: { isVisible: false },
            booking: { isVisible: false },
            customs: { isVisible: false },
          };
          this.isUserHaveAcc = res.result;
          const isSwitchAccount = this.switchAccount.switchAccount(
            accountDetail2,
            'shareUrlCustom',
            encryptedFilter
          );
          if (!isSwitchAccount) {
            this.setGroupUrlFilterCriteria(filters);
          }
        }
      });
  }

  async setGroupUrlFilterCriteria(filters: any) {
    let searchText = '';
    let offset = 0;
    let accountID = this.currentAccountData.accountId;
    let userEmail = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let fetch = 1000;

    if (filters.shipper.selectedAccount.length > 0) {
      await this.customsService
        .getShipperFilter(searchText, 0, accountID, userEmail, isGroup, fetch)
        .toPromise()
        .then((res: any) => {
          let shipper = res.result.filter((o1: any) =>
            filters.shipper.selectedAccount.some(
              (o2: any) =>
                o1.name === o2.name && o1.target_party_id === o2.target_party_id
            )
          );
          let shipperFilter = filters.shipper.selectedValue.value
            .split(',')
            .filter((val: any) =>
              res.result.some((acc: any) => acc.target_party_id === val)
            );
          filters.shipper.selectedAccount = shipper;
          if (shipperFilter.toString().length > 0) {
            filters.shipper.selectedValue.value = shipperFilter.toString();
          } else {
            filters.shipper.selectedValue = [];
          }
        });
      if (filters.consignee.selectedAccount.length > 0) {
        await this.customsService
          .getConsigneeFilter(
            searchText,
            0,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let consignee = res.result.filter((o1: any) =>
              filters.consignee.selectedAccount.some(
                (o2: any) =>
                  o1.name === o2.name &&
                  o1.target_party_id === o2.target_party_id
              )
            );
            let consigneeFilter = filters.consignee.selectedValue.value
              .split(',')
              .filter((val: any) =>
                res.result.some((acc: any) => acc.target_party_id === val)
              );
            filters.consignee.selectedAccount = consignee;
            if (consigneeFilter.toString().length > 0) {
              filters.consignee.selectedValue.value =
                consigneeFilter.toString();
            } else {
              filters.consignee.selectedValue = [];
            }
          });
      }
      if (filters.supplier.selectedAccount.length > 0) {
        await this.customsService
          .getSupplierFilter(
            searchText,
            0,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let supplier = res.result.filter((o1: any) =>
              filters.supplier.selectedAccount.some(
                (o2: any) =>
                  o1.name === o2.name &&
                  o1.target_party_id === o2.target_party_id
              )
            );
            let supplierFilter = filters.supplier.selectedValue.value
              .split(',')
              .filter((val: any) =>
                res.result.some((acc: any) => acc.target_party_id === val)
              );
            filters.supplier.selectedAccount = supplier;
            if (supplierFilter.toString().length > 0) {
              filters.supplier.selectedValue.value = supplierFilter.toString();
            } else {
              filters.supplier.selectedValue = [];
            }
          });
      }
      if (filters.importer.selectedAccount.length > 0) {
        await this.customsService
          .getImporterFilter(
            searchText,
            0,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let importer = res.result.filter((o1: any) =>
              filters.importer.selectedAccount.some(
                (o2: any) =>
                  o1.name === o2.name &&
                  o1.target_party_id === o2.target_party_id
              )
            );
            let importerFilter = filters.importer.selectedValue.value
              .split(',')
              .filter((val: any) =>
                res.result.some((acc: any) => acc.target_party_id === val)
              );
            filters.importer.selectedAccount = importer;
            if (importerFilter.toString().length > 0) {
              filters.importer.selectedValue.value = importerFilter.toString();
            } else {
              filters.importer.selectedValue = [];
            }
          });
      }
      if (filters.importerOfRecord.selectedAccount.length > 0) {
        await this.customsService
          .getImporterOfRecordFilter(
            searchText,
            0,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let importerOfRecord = res.result.filter((o1: any) =>
              filters.importerOfRecord.selectedAccount.some(
                (o2: any) =>
                  o1.name === o2.name &&
                  o1.target_party_id === o2.target_party_id
              )
            );
            let importerOfRecordFilter =
              filters.importerOfRecord.selectedValue.value
                .split(',')
                .filter((val: any) =>
                  res.result.some((acc: any) => acc.target_party_id === val)
                );
            filters.importerOfRecord.selectedAccount = importerOfRecord;
            if (importerOfRecordFilter.toString().length > 0) {
              filters.importerOfRecord.selectedValue.value =
                importerOfRecordFilter.toString();
            } else {
              filters.importerOfRecord.selectedValue = [];
            }
          });
      }

      if (filters.allLocation.selectedAccount[0].loadingPort.length > 0) {
        await this.customsService
          .getLoadingPortFilter(
            searchText,
            offset,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let loadingPortFinalVal: any[] = [];
            let loadingPortValueConvert: any[] = [];
            let loadingPortValueFinalConvert = '';
            let loadingPort = res.result.filter((o1: any) =>
              filters.allLocation.selectedAccount[0].loadingPort.some(
                (o2: any) =>
                  o1.countryCode === o2.countryCode &&
                  o1.unlocode === o2.unlocode
              )
            );
            let loadingPortSpliedVal =
              filters.allLocation.selectedValue[0].loadingPortValue.value.split(
                '|'
              );
            loadingPortSpliedVal.forEach((ele: any) => {
              let tempLoadingPort = ele.split(',');
              loadingPortFinalVal.push({
                countryCode: tempLoadingPort[0],
                unlocode: tempLoadingPort[1],
              });
            });
            let loadingPortFilter = loadingPortFinalVal.filter((val: any) =>
              res.result.some(
                (acc: any) =>
                  acc.countryCode === val.countryCode &&
                  acc.unlocode === val.unlocode
              )
            );
            loadingPortFilter.forEach((e: any) => {
              loadingPortValueConvert.push(e.countryCode + ',' + e.unlocode);
              loadingPortValueFinalConvert = loadingPortValueConvert
                .map((x) => x)
                .join('|');
            });
            if (loadingPortValueFinalConvert.length > 0) {
              filters.allLocation.selectedValue[0].loadingPortValue.value =
                loadingPortValueFinalConvert;
            } else {
              filters.allLocation.selectedValue[0].loadingPortValue = [];
            }
            filters.allLocation.selectedAccount[0].loadingPort = loadingPort;
          });
      }

      if (filters.allLocation.selectedAccount[1].dischargePort.length > 0) {
        await this.customsService
          .getDischargePortFilter(
            searchText,
            offset,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let dischargePortFinalVal: any[] = [];
            let dischargePortValueConvert: any[] = [];
            let dischargePortValueFinalConvert = '';
            let dischargePort = res.result.filter((o1: any) =>
              filters.allLocation.selectedAccount[1].dischargePort.some(
                (o2: any) =>
                  o1.countryCode === o2.countryCode &&
                  o1.unlocode === o2.unlocode
              )
            );
            let dischargePortSpliedVal =
              filters.allLocation.selectedValue[1].dischargePortValue.value.split(
                '|'
              );
            dischargePortSpliedVal.forEach((ele: any) => {
              let tempDischargePort = ele.split(',');
              dischargePortFinalVal.push({
                countryCode: tempDischargePort[0],
                unlocode: tempDischargePort[1],
              });
            });
            let dischargePortFilter = dischargePortFinalVal.filter((val: any) =>
              res.result.some(
                (acc: any) =>
                  acc.countryCode === val.countryCode &&
                  acc.unlocode === val.unlocode
              )
            );
            dischargePortFilter.forEach((e: any) => {
              dischargePortValueConvert.push(e.countryCode + ',' + e.unlocode);
              dischargePortValueFinalConvert = dischargePortValueConvert
                .map((x) => x)
                .join('|');
            });
            if (dischargePortValueFinalConvert.length > 0) {
              filters.allLocation.selectedValue[1].dischargePortValue.value =
                dischargePortValueFinalConvert;
            } else {
              filters.allLocation.selectedValue[1].dischargePortValue = [];
            }
            filters.allLocation.selectedAccount[1].dischargePort =
              dischargePort;
          });
      }

      if (filters.allLocation.selectedAccount[2].shipmentOrigin.length > 0) {
        await this.customsService
          .getShipmentOriginFilter(
            searchText,
            offset,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let shipmentOriginFinalVal: any[] = [];
            let shipmentOriginValueConvert: any[] = [];
            let shipmentOriginValueFinalConvert = '';
            let shipmentOrigin = res.result.filter((o1: any) =>
              filters.allLocation.selectedAccount[2].shipmentOrigin.some(
                (o2: any) =>
                  o1.countryCode === o2.countryCode &&
                  o1.unlocode === o2.unlocode
              )
            );
            let shipmentOriginSpliedVal =
              filters.allLocation.selectedValue[2].shipmentOriginValue.value.split(
                '|'
              );
            shipmentOriginSpliedVal.forEach((ele: any) => {
              let tempShipmentOrigin = ele.split(',');
              shipmentOriginFinalVal.push({
                countryCode: tempShipmentOrigin[0],
                unlocode: tempShipmentOrigin[1],
              });
            });
            let shipmentOriginFilter = shipmentOriginFinalVal.filter(
              (val: any) =>
                res.result.some(
                  (acc: any) =>
                    acc.countryCode === val.countryCode &&
                    acc.unlocode === val.unlocode
                )
            );
            shipmentOriginFilter.forEach((e: any) => {
              shipmentOriginValueConvert.push(e.countryCode + ',' + e.unlocode);
              shipmentOriginValueFinalConvert = shipmentOriginValueConvert
                .map((x) => x)
                .join('|');
            });
            if (shipmentOriginValueFinalConvert.length > 0) {
              filters.allLocation.selectedValue[2].shipmentOriginValue.value =
                shipmentOriginValueFinalConvert;
            } else {
              filters.allLocation.selectedValue[2].shipmentOriginValue = [];
            }
            filters.allLocation.selectedAccount[2].shipmentOrigin =
              shipmentOrigin;
          });
      }
      if (
        filters.allLocation.selectedAccount[3].shipmentDestination.length > 0
      ) {
        await this.customsService
          .getShipmentDestinationFilter(
            searchText,
            offset,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let shipmentDestinationFinalVal: any[] = [];
            let shipmentDestinationValueConvert: any[] = [];
            let shipmentDestinationValueFinalConvert = '';
            let shipmentDestination = res.result.filter((o1: any) =>
              filters.allLocation.selectedAccount[3].shipmentDestination.some(
                (o2: any) =>
                  o1.countryCode === o2.countryCode &&
                  o1.unlocode === o2.unlocode
              )
            );
            let shipmentDestinationSpliedVal =
              filters.allLocation.selectedValue[3].shipmentDestinationValue.value.split(
                '|'
              );
            shipmentDestinationSpliedVal.forEach((ele: any) => {
              let tempShipmentDestination = ele.split(',');
              shipmentDestinationFinalVal.push({
                countryCode: tempShipmentDestination[0],
                unlocode: tempShipmentDestination[1],
              });
            });
            let shipmentDestinationFilter = shipmentDestinationFinalVal.filter(
              (val: any) =>
                res.result.some(
                  (acc: any) =>
                    acc.countryCode === val.countryCode &&
                    acc.unlocode === val.unlocode
                )
            );
            shipmentDestinationFilter.forEach((e: any) => {
              shipmentDestinationValueConvert.push(
                e.countryCode + ',' + e.unlocode
              );
              shipmentDestinationValueFinalConvert =
                shipmentDestinationValueConvert.map((x) => x).join('|');
            });
            if (shipmentDestinationValueFinalConvert.length > 0) {
              filters.allLocation.selectedValue[3].shipmentDestinationValue.value =
                shipmentDestinationValueFinalConvert;
            } else {
              filters.allLocation.selectedValue[3].shipmentDestinationValue =
                [];
            }
            filters.allLocation.selectedAccount[3].shipmentDestination =
              shipmentDestination;
          });
      }

      if (filters.allLocation.selectedAccount[4].portOfClearance.length > 0) {
        await this.customsService
          .getPortofClearanceFilter(
            searchText,
            offset,
            accountID,
            userEmail,
            isGroup,
            fetch
          )
          .toPromise()
          .then((res: any) => {
            let portOfClearanceFinalVal: any[] = [];
            let portOfClearanceValueConvert: any[] = [];
            let portOfClearanceValueFinalConvert = '';
            let portOfClearance = res.result.filter((o1: any) =>
              filters.allLocation.selectedAccount[4].portOfClearance.some(
                (o2: any) =>
                  o1.countryCode === o2.countryCode &&
                  o1.unlocode === o2.unlocode
              )
            );
            let portOfClearanceSpliedVal =
              filters.allLocation.selectedValue[4].portOfClearanceValue.value.split(
                '|'
              );
            portOfClearanceSpliedVal.forEach((ele: any) => {
              let tempPortOfClearance = ele.split(',');
              portOfClearanceFinalVal.push({
                countryCode: tempPortOfClearance[0],
                unlocode: tempPortOfClearance[1],
              });
            });
            let portOfClearanceFilter = portOfClearanceFinalVal.filter(
              (val: any) =>
                res.result.some(
                  (acc: any) =>
                    acc.countryName === val.countryName &&
                    acc.unlocode === val.unlocode
                )
            );
            portOfClearanceFilter.forEach((e: any) => {
              portOfClearanceValueConvert.push(
                e.countryCode + ',' + e.unlocode
              );
              portOfClearanceValueFinalConvert = portOfClearanceValueConvert
                .map((x) => x)
                .join('|');
            });
            if (portOfClearanceValueFinalConvert.length > 0) {
              filters.allLocation.selectedValue[4].portOfClearanceValue.value =
                portOfClearanceValueFinalConvert;
            } else {
              filters.allLocation.selectedValue[4].portOfClearanceValue = [];
            }
            filters.allLocation.selectedAccount[4].portOfClearance =
              portOfClearance;
          });
      }
    }
    this.urlFilterSort(filters);
    setTimeout(() => {
      this.customFilterComponent.loadURLFilters(filters);
    }, 500);
  }

  prepareShareUrl(event: any) {
    let feature: any = this.currentSessionUserData.getCurrentAccountData();
    let selectedFilters = {
      mainFilter: event.type === 'Detail' ? [] : this.mainFilterSelectedArray,
      statusFilter:
        event.type === 'Detail' ? [] : this.statusFilterSelectedArray,
      transportType:
        event.type === 'Detail' ? [] : this.transportTypeFilterSelectedArray,
      exceptionType:
        event.type === 'Detail' ? [] : this.exceptionTypeFilterSelectedArray,
      customsType:
        event.type === 'Detail' ? [] : this.customsTypeFilterSelectedArray,
      watchFilter:
        event.type == 'Detail' ? [] : this.watchListFilterSelectedArray,
      pgaFilter:
        event.type == 'Detail' ? [] : this.pgaExistsFilterSelectedArray,
      dateRange: {
        selectedValue:
          event.type == 'Detail' ? [] : this.dateRangeFilterSelectedArray,
        selectedDateRange: event.type == 'Detail' ? [] : this.selectedDateRange,
      },
      shipper: {
        selectedValue:
          event.type == 'Detail' ? [] : this.shipperFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedShipperAccount,
      },
      supplier: {
        selectedValue:
          event.type == 'Detail' ? [] : this.supplierFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedSupplierAccount,
      },
      consignee: {
        selectedValue:
          event.type == 'Detail' ? [] : this.consigneeFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedConsigneeAccount,
      },
      importer: {
        selectedValue:
          event.type == 'Detail' ? [] : this.importerFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedImporterAccount,
      },
      importerOfRecord: {
        selectedValue:
          event.type == 'Detail'
            ? []
            : this.importerOfRecordFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedImporterOfRecordAccount,
      },

      allLocation: {
        selectedAccount: [
          {
            loadingPort:
              event.type == 'Detail' ? [] : this.selectedLoadingPortAccount,
          },
          {
            dischargePort:
              event.type == 'Detail' ? [] : this.selectedDischargePortAccount,
          },
          {
            shipmentOrigin:
              event.type == 'Detail' ? [] : this.selectedShipmentOriginAccount,
          },
          {
            shipmentDestination:
              event.type == 'Detail'
                ? []
                : this.selectedShipmentDestinationAccount,
          },
          {
            portOfClearance:
              event.type == 'Detail' ? [] : this.selectedPortOfClearanceAccount,
          },
        ],
        selectedValue: [
          {
            loadingPortValue:
              event.type == 'Detail' ? [] : this.loadingPortFilterSelectedArray,
          },
          {
            dischargePortValue:
              event.type == 'Detail'
                ? []
                : this.dischargePortFilterSelectedArray,
          },
          {
            shipmentOriginValue:
              event.type == 'Detail'
                ? []
                : this.shipmentOriginFilterSelectedArray,
          },
          {
            shipmentDestinationValue:
              event.type == 'Detail'
                ? []
                : this.shipmentDestinationFilterSelectedArray,
          },
          {
            portOfClearanceValue:
              event.type == 'Detail'
                ? []
                : this.portOfClearanceFilterSelectedArray,
          },
        ],
      },
      sorting: {
        sortId: this.defaultCustomsSortSelectedValue,
        selectedValue: {
          column_name: this.defaultCustomsSortSelectedColumn,
          direction: this.defaultCustomsSortDirection,
        },
      },
      accountDetail: {
        aliasName:
          event.type == 'Detail' ? event.aliasName : feature.accountAliasName,
        oH_Code: event.type == 'Detail' ? event.accountId : feature.accountId,
        byGroup: event.type == 'Detail' ? event.isGroup : feature.isGroup,
        shipment: { isVisible: feature.shipmentIsVisible },
        booking: { isVisible: feature.bookingIsVisible },
        customs: { isVisible: feature.bookingIsVisible },
        isControllingCustomer:
          event.type == 'Detail' ? event.isParent : feature.isParent,
      },
      customsId: event.customsId,
      type: event.type,
      subType: event.type == 'Detail' ? event.subType : '',
      module: 'Customs',
    };
    this.saveFilterJson = selectedFilters;
    if (event.type == 'All') {
      this.saveFilterJson.mainFilter.forEach((e: any) => {
        e.filterDisplayName = e.filterDisplayName.includes(':')
          ? e.filterDisplayName?.split(':')[0]
          : e.filterDisplayName;
      });
      this.mainFilterValueCheck();
      this.customsService
        .saveShareFilter(
          this.currentUserData.emailAddress,
          '',
          this.saveFilterJson,
          this.currentAccountData.accountId
        )
        .subscribe((res: any) => {
          if (res.statusCode == 'OC200') {
            var allFilter: any = {
              id: res.result,
              type: 'All',
            };
          }
          let encryAll = btoa(JSON.stringify(allFilter));
          this.url = environment.localUrl + '/customs/' + encryAll;
        });
    } else if (event.type === 'Detail') {
      this.url = environment.localUrl + '/' + event?.shipment?.encryptedId;
    } else {
      let encry = btoa(JSON.stringify(selectedFilters));
      this.url = environment.localUrl + '/customs/' + encry;
    }
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  mainFilterValueCheck() {
    if (this.saveFilterJson.mainFilter.length <= 0) {
      if (Object.keys(this.saveFilterJson.statusFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 1,
          filterDisplayName: 'Status',
          type: 'statuscustom',
          index: 1,
        });
      }

      if (Object.keys(this.saveFilterJson.transportType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 0,
          filterDisplayName: 'Transport Type',
          type: 'transport',
          index: 0,
        });
      }
      if (Object.keys(this.saveFilterJson.exceptionType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 6,
          filterDisplayName: 'Exception Type',
          type: 'exception',
          index: 6,
        });
      }
      if (Object.keys(this.saveFilterJson.customsType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 8,
          filterDisplayName: 'Customs Type',
          type: 'customstype',
          index: 8,
        });
      }
      if (this.saveFilterJson.shipper.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 2,
          filterDisplayName: 'Shipper',
          type: 'shipper',
          index: 2,
        });
      }
      if (this.saveFilterJson.consignee.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 3,
          filterDisplayName: 'Consignee',
          type: 'consignee',
          index: 3,
        });
      }
      if (this.saveFilterJson.supplier.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 11,
          filterDisplayName: 'Supplier',
          type: 'supplier',
          index: 11,
        });
      }
      if (this.saveFilterJson.importer.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 12,
          filterDisplayName: 'Importer',
          type: 'importer',
          index: 12,
        });
      }
      if (this.saveFilterJson.importerOfRecord.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 10,
          filterDisplayName: 'Importer of Record',
          type: 'importer_of_record',
          index: 10,
        });
      }

      if (this.saveFilterJson.dateRange.selectedDateRange.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 5,
          filterDisplayName: 'Date Range',
          type: '',
          index: 5,
        });
      }
      if (Object.keys(this.saveFilterJson.watchFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 7,
          filterDisplayName: 'Watchlist',
          type: '',
          index: 7,
        });
      }
      if (Object.keys(this.saveFilterJson.pgaFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 9,
          filterDisplayName: 'PGA Exists',
          type: '',
          index: 9,
        });
      }
      if (
        this.saveFilterJson.allLocation.selectedAccount[0].loadingPort.length >
          0 ||
        this.saveFilterJson.allLocation.selectedAccount[1].dischargePort
          .length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[2].shipmentOrigin
          .length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[3].shipmentDestination
          .length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[4].portOfClearance
          .length > 0
      ) {
        this.saveFilterJson.mainFilter.push({
          id: 4,
          filterDisplayName: 'Location',
          type: 'location',
          index: 4,
        });
      }
    }
  }

  singleShareApply(urlFilter: any) {
    this.singleCustoms(urlFilter.customsId);

    setTimeout(() => {
      this.popupToggle = true;
    }, 0);
    this.urlCustomsId = urlFilter.customsId;
  }

  singleCustoms(customsId: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let isParent = this.currentAccountData.isParent;
    this.customsService
      .getCustomDetails(accountId, emailId, customsId, isGroup)
      .subscribe({
        next: (getDetails: any) => {
          if (getDetails.result && getDetails.result.length > 0) {
            this.isProgressShow = false;
            this.getCustomsDetails = getDetails.result[0];
            this.selectedCustomsLen = Object.keys(
              this.getCustomsDetails
            ).length;
          } else {
            let bodyParam = {
              offset: this.page,
              fetch: this.limit,
              search_text: '',
              parameters: [
                {
                  name: 'customsNumber',
                  value: customsId,
                },
              ],
              sort_options: [
                {
                  column_name: this.defaultCustomsSortSelectedColumn,
                  direction: this.defaultCustomsSortDirection,
                },
              ],
              filter_options: [],
            };
            this.customsService
              .publicShare(accountId, emailId, bodyParam, isGroup, isParent)
              .subscribe((res: any) => {
                this.isProgressShow = false;
                setTimeout(() => {
                  this.getCustomsDetails = res.result[0];
                  this.selectedCustomsLen = Object.keys(
                    this.getCustomsDetails
                  ).length;
                }, 100);
              });
          }
        },
      });
  }

  selectedListCustoms(customsId: any) {
    this.appInsightsService.logPageView(
      AI_PageName.CustomDetail,
      window.location.href,
      {
        'Shipment Id': customsId,
      }
    );
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.customsService
      .getCustomDetails(accountId, emailId, customsId, isGroup)
      .subscribe({
        next: (getDetails: any) => {
          this.isProgressShow = false;
          this.getCustomsDetails = getDetails.result[0];
          this.selectedCustomsLen = getDetails.result.length;
        },
      });
  }

  featureHide() {
    this.popupToggle = false;
  }

  detailShare(event: any) {
    this.detailCustomsShare = true;
    this.customsSharePopup = true;

    this.prepareShareUrl(event);
  }

  userFilterFetch(event: any) {
    let getPatameter: any = '';
    if (event.type == 'view') {
      this.defaultCustomsFilterSelectedValue = -1;
      this.customFilterComponent.clearAllFilters();
      setTimeout(() => {
        let decryptFilter = JSON.parse(event.userFilterRow.jsonFilter);
        if (decryptFilter.mainFilter.length <= 0) {
          if (Object.keys(decryptFilter.statusFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 1,
              filterDisplayName: 'Status',
              type: 'statuscustom',
              index: 1,
            });
          }
          if (Object.keys(decryptFilter.transportType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 0,
              filterDisplayName: 'Transport Type',
              type: 'transport',
              index: 0,
            });
          }

          if (Object.keys(decryptFilter.exceptionType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 6,
              filterDisplayName: 'Exception Type',
              type: 'exception',
              index: 6,
            });
          }
          if (Object.keys(decryptFilter.customType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 8,
              filterDisplayName: 'Customs Type',
              type: 'customstype',
              index: 8,
            });
          }
          if (decryptFilter.shipper.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 2,
              filterDisplayName: 'Shipper',
              type: 'shipper',
              index: 2,
            });
          }
          if (decryptFilter.consignee.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 3,
              filterDisplayName: 'Consignee',
              type: 'consignee',
              index: 3,
            });
          }
          if (decryptFilter.supplier.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 11,
              filterDisplayName: 'Supplier',
              type: 'supplier',
              index: 11,
            });
          }
          if (decryptFilter.importer.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 12,
              filterDisplayName: 'Importer',
              type: 'importer',
              index: 12,
            });
          }
          if (decryptFilter.importerOfRecord.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 10,
              filterDisplayName: 'Importer of Record',
              type: 'importer_of_record',
              index: 10,
            });
          }

          if (Object.keys(decryptFilter.pgaFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 9,
              filterDisplayName: 'PGA Exists',
              type: '',
              index: 9,
            });
          }

          if (
            decryptFilter.allLocation.selectedAccount[0].loadingPort.length >
              0 ||
            decryptFilter.allLocation.selectedAccount[1].dischargePort.length >
              0 ||
            decryptFilter.allLocation.selectedAccount[2].shipmentOrigin.length >
              0 ||
            decryptFilter.allLocation.selectedAccount[3].shipmentDestination
              .length > 0 ||
            decryptFilter.allLocation.selectedAccount[4].portOfClearance
              .length > 0
          ) {
            decryptFilter.mainFilter.push({
              id: 4,
              filterDisplayName: 'Location',
              type: 'location',
              index: 4,
            });
          }
          if (decryptFilter.dateRange.selectedDateRange.length > 0) {
            decryptFilter.mainFilter.push({
              id: 5,
              filterDisplayName: 'Date Range',
              type: '',
              index: 5,
            });
          }
          if (Object.keys(decryptFilter.watchFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 7,
              filterDisplayName: 'Watchlist',
              type: '',
              index: 7,
            });
          }
        }
        if (
          decryptFilter.allLocation.selectedAccount[0].loadingPort.length > 0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[0].loadingPortValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[0].loadingPort = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[1].dischargePort.length >
            0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[1].dischargePortValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[1].dischargePort = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[2].shipmentOrigin.length >
            0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[2].shipmentOriginValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[2].shipmentOrigin = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[3].shipmentDestination
            .length > 0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[3].shipmentDestinationValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[3].shipmentDestination = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[4].portOfClearance.length >
            0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[4].portOfClearanceValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[4].portOfClearance = [];
        }
        setTimeout(() => {
          this.customFilterComponent.loadURLFilters(decryptFilter);
        }, 500);
        setTimeout(() => {
          getPatameter = this.prepareApiBodyParams();
          this.filterCheckTemp = getPatameter.bodyParam;
        }, 500);
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
      }, 300);
    } else if (event.type == 'update') {
      setTimeout(() => {
        getPatameter = this.prepareApiBodyParams();
        this.filterCheckTemp = getPatameter.bodyParam;
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
        setTimeout(() => {
          this.customFilterComponent.setUpdateFilterName();
        }, 200);
      }, 300);
    }
  }

  sortByFilterNameName(collection: any) {
    if (collection?.length > 0) {
      return collection.sort((a: any, b: any) =>
        a?.filterName.localeCompare(b?.filterName)
      );
    } else {
      return [];
    }
  }

  getUserFilters(event: any) {
    if (event.message && event.type != 'delete') {
      this.isShowMessage = true;
      this.showMessage = event.message;
    } else if (event.message && event.type == 'delete') {
      this.isShowMessageDel = true;
      this.showMessageDel = event.message;
    }

    this.customsService
      .getUserFilter(
        this.currentUserData.emailAddress,
        this.currentAccountData.accountId,
        this.currentAccountData.isGroup
      )
      .subscribe((res: any) => {
        this.savedFiltersList = this.sortByFilterNameName(res.result);
        this.myFilterEdit = '';
        if (event.type == 'save' || event.type == 'update') {
          let elementPos = res.result.filter(
            (x: any) => x.filterName == event.filterName
          );
          this.savedFilterRow = elementPos;
        } else {
          this.savedFilterRow = '';
        }
      });
    this.clearToaster();
  }

  clearToaster() {
    setTimeout(() => {
      this.isShowMessage = false;
      this.showMessage = '';
      this.isShowMessageDel = false;
      this.showMessageDel = '';
    }, 5000);
  }

  customFilterApiLoader(event: any) {
    this.isProgressShow = event;
  }

  undoDeleteUserFilter(event: any) {
    if (event) {
      setTimeout(() => {
        this.customFilterComponent.undoChanges();
        this.isShowMessageDel = false;
        this.showMessageDel = '';
      }, 200);
    }
  }

  setUrlDefaultFilter(e: any) {
    if (e.mainfilter.length > 0) {
      this.mainFilterSelectedArray.push(e.mainfilter[0]);
    }
  }

  sharePopUpfeatureHide() {
    this.detailCustomsShare = false;
  }

  onRefreshDetailFromDetail(event: any) {
    this.isProgressShow = true;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (event.value == 'LOAD DETAILS') {
      if (this.currentAccountData && event.access) {
        let accountId = this.currentAccountData.accountId;
        let emailId = this.currentUserData.emailAddress
          ? this.currentUserData.emailAddress
          : '';
        let isGroup = this.currentAccountData.isGroup;

        this.customsService
          .getCustomDetails(accountId, emailId, event.shipmentId, isGroup)
          .subscribe({
            next: (getDetails: any) => {
              this.isProgressShow = false;
              this.dataSharingService.refreshShipmentDetailsInHeader.next(
                false
              );
              this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
                true
              );
              this.getCustomsDetails = getDetails.result[0];
            },
          });
      } else if (
        this.publicShareFilter.type === 'Detail' &&
        this.publicShareFilter.subType === 'publicSearch'
      ) {
        this.customsPublicSearchShare('refresh');
      } else {
        this.refreshPublicView();
      }
    } else {
      this.refreshPublicView();
    }
  }

  refreshPublicView() {
    let getPatameter = this.prepareApiBodyParams();
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: this.publicShareFilter.shipmentId,
        },
      ],
      sort_options: [
        {
          column_name: this.defaultCustomsSortSelectedColumn,
          direction: this.defaultCustomsSortDirection,
        },
      ],
      filter_options: [],
    };
    let oH_Code = this.publicShareFilter?.accountDetail?.oH_Code
      ? this.publicShareFilter?.accountDetail?.oH_Code
      : this.currentAccountData?.accountId
      ? this.currentAccountData?.accountId
      : '';
    let byGroup = this.publicShareFilter?.accountDetail?.byGroup
      ? this.publicShareFilter?.accountDetail?.byGroup
      : this.currentAccountData?.isGroup
      ? this.currentAccountData?.isGroup
      : false;
    let isControllingCustomer = this.publicShareFilter?.accountDetail
      ?.isControllingCustomer
      ? this.publicShareFilter?.accountDetail?.isControllingCustomer
      : this.currentAccountData?.isParent
      ? this.currentAccountData?.isParent
      : false;
    this.customsService
      .publicShare(
        oH_Code,
        getPatameter.emailId,
        bodyParam,
        byGroup,
        isControllingCustomer
      )
      .subscribe((res: any) => {
        this.isProgressShow = false;
        this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
        this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
          true
        );
        setTimeout(() => {
          this.getCustomsDetails = res.result[0];
          this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
        }, 0);
      });
  }

  publicSearchFn(filters: any) {
    if (filters) {
      this.dataSharingService.notLoggedIn.next(true);
      this.isDetailFullView = true;
      this.popupToggle = true;
      this.urlCustomsId = filters.customsId;
      this.getCustomsDetails = filters.shipment;
      this.publicShareFilter = filters;
      let filter = {
        type: 'Detail',
        customsId: filters.customsId,
        accountDetail: {
          aliasName: filters.accountDetail.aliasName,
          oH_Code: filters.accountDetail.oH_Code,
          byGroup: false,
          shipment: { isVisible: false },
          booking: { isVisible: false },
          customs: { isVisible: false },
          isControllingCustomer: false,
        },
        subType: 'publicSearch',
        module: 'Customs',
      };
      this.publicShareFilter = filter;
      let encry = btoa(JSON.stringify(filter));
      this.publicShareEnrcyptedData = encry;
      this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
    }
  }

  customsPublicSearchShare(from: any) {
    if (this.publicShareFilter) {
      this.customsService
        .customsPublicSearch(this.publicShareFilter.customsId)
        .subscribe((x) => {
          if (x.result) {
            if (from === 'refresh') {
              this.dataSharingService.refreshShipmentDetailsInHeader.next(
                false
              );
              this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
                true
              );
            }
            this.isProgressShow = false;
            this.urlCustomsId = this.publicShareFilter.customsId;
            this.popupToggle = true;
            this.getCustomsDetails = x.result;
            this.selectedCustomsLen = Object.keys(
              this.getCustomsDetails
            ).length;
          }
        });
    }
  }

  getUserDetailGuidedTour() {
    this.userService
      .getUserDetail(this.currentUserData.emailAddress)
      .subscribe((res) => {
        let userGuidedTourDetails = res.result.user.guidedTour.filter(function (
          el: any
        ) {
          return el.featureName == 'customs';
        });
        let guidedTour =
          userGuidedTourDetails.length > 0 ? userGuidedTourDetails[0] : null;
        if (!guidedTour || (!guidedTour.isVisited && !this.isMobileView)) {
          this.defaultCustomsSortSelectedValue = 8;
          this.defaultCustomsSortSelectedColumn = 'custom';
          this.defaultCustomsSortDirection = 'desc';
          this.appInsightsService.trackAIGuidedTour(AI_ModulesName.Custom);
          this.loadAllCustoms();
          this.showGuidedTourPopup();
          this.endTour();
        }
      });
  }

  showGuidedTourPopup() {
    this.guidedTourPopup = true;
  }

  endTour() {
    let params = {
      featureName: 'customs',
      isVisited: true,
      whenFinished: new Date(),
    };
    this.customsService
      .skipTour(this.currentUserData.emailAddress, params)
      .subscribe((res) => {
        if (res.result.includes('Guided tour updated')) {
          let setUser = this.currentSessionUserData.getCurrentUserData();
          setUser.guidedTour = [params];
          localStorage.setItem(
            'loggedInUser',
            window.btoa(unescape(encodeURIComponent(JSON.stringify(setUser))))
          );
        }
      });
  }

  startGuidedTour(type: any) {
    if (type == 'first') {
      this.hideGuidedTourPopup();
    }

    this.dataSharingService.isGuidedTourHeaderHide.next(true);
    this.dataSharingService.isGuidedTourShipment.next(true);
    this.guidedFeatureModalShow = true;
    if (this.totalCustomsCount > 0) {
      this.guidedTourFlag = 1;
      this.guidedTourPopupHeader = this.guidedTourJson[0].heading;
      this.guidedTourPopupPara = this.guidedTourJson[0].para;
      if (
        this.guidedTourIsConvertedBooking.length < 1 &&
        this.totalCustomsCount > 0
      ) {
        this.guidedTourFlagNoShipment = 1;
      }
    } else {
      this.guidedTourFlag = 3;
      this.guidedTourFlagNoShipment = 1;
      this.guidedTourPopupHeader = this.guidedTourJson[2].heading;
      this.guidedTourPopupPara = this.guidedTourJson[2].para;
    }
    setTimeout(() => {
      $('#rapModal').addClass('dynamicRapClass' + this.guidedTourFlag);
    }, 0);
    this.getGuidedTourFeature();
  }

  gudideTourCloseset() {
    this.guidedFeatureManualModalShow = false;
  }

  navigateToFaq() {
    this.router.navigate([]).then((result) => {
      window.open('/faqs', '_blank');
    });
  }

  nextFeature() {
    this.guidedTourCustomsDetail = 'false';
    if (this.guidedTourFlag == 8) {
      this.guidedFeatureModalShow = false;
      this.clearAllGuidedTourCss();
    }
    if (this.guidedTourFlag < 8) {
      this.guidedTourFlag += 1;
      if (this.guidedTourFlag == 8 && this.totalCustomsCount < 1) {
        this.guidedFeatureModalShow = false;
        this.clearAllGuidedTourCss();
      } else {
        if (this.totalCustomsCount < 1) {
          this.guidedTourFlagNoShipment += 1;
        }
        this.dataSharingService.isGuidedTourHeaderHide.next(true);
        $('#rapModal').removeClass();
        $('#rapModal').addClass(
          'rap-set dynamicRapClass' + this.guidedTourFlag
        );
        this.getGuidedTourFeature();
      }
    }
  }

  previousFeature() {
    this.dataSharingService.isGuidedTourHeaderHide.next(true);
    if (this.guidedTourFlag == 8) {
      if (!$('#shipmentFull').is(':visible')) {
        return;
      }
    }

    this.guidedTourFlag -= 1;
    if (this.savedFiltersList.length < 1 && this.totalCustomsCount > 0) {
      if (this.guidedTourFlag == 5) {
        this.guidedTourFlag -= 1;
      }
      this.guidedTourFlagNoShipment += 1;
    }
    if (this.totalCustomsCount < 1) {
      this.guidedTourFlagNoShipment -= 1;
    }
    $('#rapModal').removeClass();
    $('#rapModal').addClass('rap-set dynamicRapClass' + this.guidedTourFlag);
    this.getGuidedTourFeature();
  }

  getGuidedTourFeature() {
    this.dataSharingService.isGuidedTourDefaultFilterHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterCompHide.next(true);
    this.dataSharingService.isGuidedTourToggleShow.next(true);
    this.dataSharingService.isGuidedTourShipmentSecHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterHide.next(true);
    this.dataSharingService.isGuidedTourSearchTextBoxHide.next(true);
    this.dataSharingService.isGuidedTourSortHide.next(true);
    this.dataSharingService.isGuidedTourShipment.next(false);
    this.dataSharingService.isGuidedTourShareShipmentHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterShow.next(false);
    this.dataSharingService.bookingDefaultFilterListHide.next(true);
    this.dataSharingService.bookingSavedFilterListHide.next(true);
    switch (this.guidedTourFlag) {
      case 1:
        this.dataSharingService.isGuidedTourShipmentSecShow.next(true);
        this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
        this.guidedTourPopupHeader = this.guidedTourJson[0].heading;
        this.guidedTourPopupPara = this.guidedTourJson[0].para;
        break;
      case 2:
        this.dataSharingService.isGuidedTourShipmentSecShow.next(true);
        this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
        this.guidedTourPopupHeader = this.guidedTourJson[1].heading;
        this.guidedTourPopupPara = this.guidedTourJson[1].para;
        break;
      case 3:
        this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
        this.dataSharingService.isGuidedTourDefaultFilterShow.next(true);
        this.dataSharingService.bookingDefaultFilterListShow.next(true);
        this.dataSharingService.bookingDefaultFilterListHide.next(false);
        this.guidedTourCustomsFilter = false;
        this.guidedTourPopupHeader = this.guidedTourJson[2].heading;
        this.guidedTourPopupPara = this.guidedTourJson[2].para;
        break;
      case 4:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourCustomFilterHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterShow.next(true);
        this.guidedTourCustomsFilter = true;
        this.guidedTourPopupHeader = this.guidedTourJson[3].heading;
        this.guidedTourPopupPara = this.guidedTourJson[3].para;
        break;

      case 5:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourSearchTextBoxHide.next(false);
        this.dataSharingService.isGuidedTourSearchTextBoxShow.next(true);
        this.guidedTourPopupHeader = this.guidedTourJson[4].heading;
        this.guidedTourPopupPara = this.guidedTourJson[4].para;
        this.guidedTourCustomsFilter = false;
        break;
      case 6:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourSortHide.next(false);
        this.dataSharingService.isGuidedTourSortShow.next(true);
        this.guidedTourPopupHeader = this.guidedTourJson[5].heading;
        this.guidedTourPopupPara = this.guidedTourJson[5].para;
        this.guidedTourCustomsDetail = 'false';
        break;
      case 7:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourShareShipmentHide.next(false);
        this.dataSharingService.isGuidedTourShareShipmentShow.next(true);
        this.guidedTourCustomsDetail = 'false';
        this.guidedTourPopupHeader = this.guidedTourJson[6].heading;
        this.guidedTourPopupPara = this.guidedTourJson[6].para;
        break;
      case 8:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(true);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(false);
        if (this.totalCustomsCount > 0) {
          this.dataSharingService.isGuidedTourShipment.next(true);
          this.guidedTourCustomsDetail = 'true';
          this.guidedTourPopupHeader = this.guidedTourJson[7].heading;
          this.guidedTourPopupPara = this.guidedTourJson[7].para;
        }
        break;
    }
  }

  guidedTourStartManually(event: any) {
    if (event) {
      this.defaultCustomsSortSelectedValue = 8;
      this.defaultCustomsSortSelectedColumn = 'custom';
      this.defaultCustomsSortDirection = 'desc';
      this.emptyCustomList();
      this.loadAllCustoms();
      this.guidedFeatureManualModalShow = true;
    }
  }

  gudideTourStart() {
    if (this.isProgressShow) {
      return;
    }
    this.guidedFeatureManualModalShow = false;
    this.startGuidedTour('manual');
  }

  skipTour() {
    this.hideGuidedTourPopup();
  }

  clearAllGuidedTourCss() {
    this.dataSharingService.isGuidedTourHeaderHide.next(false);
    this.dataSharingService.isGuidedTourHeaderShow.next(false);
    this.dataSharingService.isGuidedTourShipment.next(false);
    this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
    this.dataSharingService.isGuidedTourShipmentSecShow.next(false);
    this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
    this.dataSharingService.isGuidedTourCustomFilterCompShow.next(false);
    this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
    this.dataSharingService.isGuidedTourDefaultFilterShow.next(false);
    this.dataSharingService.isGuidedTourSearchTextBoxHide.next(false);
    this.dataSharingService.isGuidedTourSearchTextBoxShow.next(false);
    this.dataSharingService.isGuidedTourCustomFilterHide.next(false);
    this.dataSharingService.isGuidedTourCustomFilterShow.next(false);
    this.dataSharingService.isGuidedTourSortHide.next(false);
    this.dataSharingService.isGuidedTourSortShow.next(false);
    this.dataSharingService.isGuidedTourShareShipmentHide.next(false);
    this.dataSharingService.isGuidedTourShareShipmentShow.next(false);
    this.dataSharingService.isGuidedTourToggleShow.next(false);
    this.dataSharingService.bookingSavedFilterListShow.next(false);
    this.dataSharingService.bookingSavedFilterListHide.next(false);
    this.dataSharingService.bookingDefaultFilterListShow.next(false);
    this.dataSharingService.bookingDefaultFilterListHide.next(false);
    this.guidedTourCustomsDetail = '';
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  hideGuidedTourPopup() {
    this.guidedTourPopup = false;
  }

  trackAIPageView(isPublicView: boolean) {
    this.appInsightsService.logPageView(
      isPublicView ? AI_PageName.CustomsPublicView : AI_PageName.Custom,
      window.location.href
    );
  }
}
