import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ActionFieldService } from 'src/app/features/admin/actionfield/services/action-field.service';

import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { ChangeLogsComponent } from 'src/app/shared/component/change-logs/change-logs.component';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { EdocUploadStatusService } from 'src/app/shared/services/eDoc-upload-status.service';
import { environment } from 'src/environments/environment';
import { ActionDetailTabComponent } from '../action-detail-tab/action-detail-tab.component';

import { ShipmentHeaderComponent } from '../shipment-header/shipment-header.component';
import { ShipmentServies } from '../../services/shipment.service';
declare var $: any;
@Component({
  selector: 'app-shipment-detail',
  templateUrl: './shipment-detail.component.html',
  styleUrls: ['./shipment-detail.component.scss'],
})
export class ShipmentDetailComponent implements OnChanges {
  @Input() selectedShipment: any = [];
  @Input() popupToggle: any;
  @Output() onDetailPopupCloseHandler: any = new EventEmitter<any>();
  @Output() onRefreshDetails: any = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Input() urlShare = '';
  @Input() urlShipmentId = '';
  @Input() publicShareVisibility = false;
  @Input() publicShareType: any = '';
  @Input() publicShareEnrcyptedData: any = '';
  @Input() refreshFalse: boolean = false;
  @Input() actionFieldRecords: any = [];
  readMoreNotes: boolean = false;
  readMoreBookRef: boolean = false;
  copyText: any = '';
  popupToggleDetail = false;
  isShipmentDetailFullScreen = false;
  @ViewChild('navCenter', { read: ElementRef, static: false })
  navCenter: ElementRef;
  percentageCompleted = [];
  @ViewChild('shipmentFull', { static: false }) shipmentFull: ElementRef;
  @ViewChild(ShipmentHeaderComponent)
  shipmentHeaderComponent!: ShipmentHeaderComponent;
  @ViewChild(ChangeLogsComponent)
  ChangeLogsComponent!: ChangeLogsComponent;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();

  constructor(
    private appInsightsService: AppInsightsService,
    private dataSharing: DataSharingService,
    private edocUploadStatusService: EdocUploadStatusService,
    private currentSessionUserData: CurrentSessionDataService,
    private router: Router,
    public dataSharingService: DataSharingService,
    private actionFieldService: ActionFieldService,
    private shipmentService: ShipmentServies
  ) { }

  ngOnChanges(): void {
    this.readMoreBookRef =
      this.selectedShipment?.booking_reference?.length > 60;
    if (this.selectedShipment?.notes) {
      this.readMoreNotes = this.selectedShipment?.notes[0]?.body?.length > 180;
    }
    if (this.urlShipmentId && this.urlShipmentId !== '') {
      this.isShipmentDetailFullScreen = true;
      setTimeout(() => {
        this.shipmentHeaderComponent.openShipmentFullScreen(
          'url',
          this.urlShipmentId,
          ''
        );
        this.urlShipmentId = '';
      }, 0);
    }
    this.loadDocuments();
    //this.loadActionFieldsDetails(this.selectedShipment);
  }

  @ViewChild(ActionDetailTabComponent)
  actionFieldtabComponent: ActionDetailTabComponent;
  featureHide() {
    this.shipmentService.openDocumentTab = false;
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      if (this.shipmentService.actionFieldExist && this.shipmentService.isActionFieldChanged) {
        this.actionFieldtabComponent.closeActionField();
      } else {
        this.popUpClose();
      }
    } else {
      this.dataSharing.eDocUploadTrigger.next({ popup: true, reload: false });
    }
  }

  popUpClose() {
    this.popupToggle = false;
    $('#shipment-sec-id > div').removeClass('active');
    $('#shipment-table-view-sec > tr').removeClass('active');
    this.shipmentService.isActionFieldChanged = false;
  }

  actionFieldExist: boolean = false;
  loadActionFieldsDetails(selectedShipment: any) {
    this.actionFieldService
      .getSectionDetailsByOrgCode(
        this.currentAccountData.accountId,
        selectedShipment.id,
        'shipments',
        this.currentAccountData.isGroup
      )
      .subscribe((e: any) => {
        if (e.result?.length > 0) {
          this.actionFieldExist = false;
        } else {
          this.actionFieldExist = true;
        }
      });
  }

  onReloadList(shipmentId: any) {
    this.onDetailPopupCloseHandler.emit({
      value: 'LOAD SHIPMENT',
      shipmentId: shipmentId,
    });
  }
  /* istanbul ignore next */
  onReloadDetails(event: any) {
    if (this.currentUserData && event.access) {
      this.ChangeLogsComponent.getLogsCount();

      if (this.ChangeLogsComponent.categoryTypes.length > 0) {
        let sort = [
          {
            column_name: 'createDateTime',
            direction: 'desc',
          },
        ];
        this.ChangeLogsComponent.categoryTypes.forEach((element: any) => {
          this.ChangeLogsComponent.getChangeLogs(
            element.changeLogCategory.toString(),
            sort
          );
        });
      }
    }

    this.onRefreshDetails.emit({
      value: 'LOAD DETAILS',
      shipmentId: event.shipmentId,
      access: event.access,
    });
  }

  share(event: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ShareSingleItem.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
    this.prepareUrlShare.emit(event);
  }

  loadDocuments() {
    this.selectedShipment?.attachments?.forEach((ele: any, index: any) => {
      let file_exe = ele.file_name.split('.').pop().toUpperCase();
      this.selectedShipment.attachments[index].file_type = file_exe;
      let regex = /[+-]?\d+(\.\d+)?/g;
      let floats = ele.file_size.match(regex).map(function (v: any) {
        return parseFloat(v);
      });
      let fileSizeSplit = ele.file_size.toLowerCase();
      if (fileSizeSplit.includes('kb')) {
        this.selectedShipment.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 1);
      } else if (fileSizeSplit.includes('mb')) {
        this.selectedShipment.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 2);
      } else if (fileSizeSplit.includes('gb')) {
        this.selectedShipment.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 3);
      } else if (fileSizeSplit.includes('bytes')) {
        this.selectedShipment.attachments[index].file_size_bytes = floats[0];
      }
    });
  }

  loadFullReferenceNum(event: any) {
    if (event) {
      this.shipmentHeaderComponent.gotoRefSection();
    }
  }
  /* istanbul ignore next */
  loadCustomDetails(event: any) {
    let selectedFilters: any;
    if (this.currentAccountData?.customsIsVisible) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        customsType: [],
        watchFilter: [],
        exceptionType: [],
        pgaFilter: [],
        dateRange: {
          selectedValue: [],
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: [],
          selectedAccount: [],
        },
        consignee: {
          selectedValue: [],
          selectedAccount: [],
        },
        importer: {
          selectedValue: [],
          selectedAccount: [],
        },
        importerOfRecord: {
          selectedValue: [],
          selectedAccount: [],
        },
        supplier: {
          selectedValue: [],
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { loadingPort: [] },
            { dischargePort: [] },
            { shipmentOrigin: [] },
            { shipmentDestination: [] },
            { portOfClearance: [] },
          ],
          selectedValue: [
            { loadingPortValue: [] },
            { dischargePortValue: [] },
            { shipmentOriginValue: [] },
            { shipmentDestinationValue: [] },
            { portOfClearanceValue: [] },
          ],
        },
        sorting: [],
        accountId: '',
        customsId: event.customsId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/customs/' + encry;
      window.open(url, '_blank');
    } else if (
      this.currentAccountData &&
      !this.currentAccountData.customsIsVisible
    ) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        customsType: [],
        watchFilter: [],
        exceptionType: [],
        pgaFilter: [],
        dateRange: {
          selectedValue: [],
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: [],
          selectedAccount: [],
        },
        consignee: {
          selectedValue: [],
          selectedAccount: [],
        },
        importer: {
          selectedValue: [],
          selectedAccount: [],
        },
        importerOfRecord: {
          selectedValue: [],
          selectedAccount: [],
        },
        supplier: {
          selectedValue: [],
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { loadingPort: [] },
            { dischargePort: [] },
            { shipmentOrigin: [] },
            { shipmentDestination: [] },
            { portOfClearance: [] },
          ],
          selectedValue: [
            { loadingPortValue: [] },
            { dischargePortValue: [] },
            { shipmentOriginValue: [] },
            { shipmentDestinationValue: [] },
            { portOfClearanceValue: [] },
          ],
        },
        sorting: [],
        accountId: '',
        customsId: event.customsId,
        type: 'Single',
        module: 'Customs',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/dashboard/' + encry;
      window.open(url, '_blank');
    } else {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));

      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        customsType: [],
        watchFilter: [],
        exceptionType: [],
        pgaFilter: [],
        dateRange: {
          selectedValue: [],
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: [],
          selectedAccount: [],
        },
        consignee: {
          selectedValue: [],
          selectedAccount: [],
        },
        importer: {
          selectedValue: [],
          selectedAccount: [],
        },
        importerOfRecord: {
          selectedValue: [],
          selectedAccount: [],
        },
        supplier: {
          selectedValue: [],
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { loadingPort: [] },
            { dischargePort: [] },
            { shipmentOrigin: [] },
            { shipmentDestination: [] },
            { portOfClearance: [] },
          ],
          selectedValue: [
            { loadingPortValue: [] },
            { dischargePortValue: [] },
            { shipmentOriginValue: [] },
            { shipmentDestinationValue: [] },
            { portOfClearanceValue: [] },
          ],
        },
        sorting: [],
        accountDetail: {
          aliasName: decryUrlFilter.accountDetail.aliasName,
          oH_Code: decryUrlFilter.accountDetail.oH_Code,
          byGroup: decryUrlFilter.accountDetail.byGroup,
          isControllingCustomer:
            decryUrlFilter.accountDetail.isControllingCustomer,
        },
        customsId: event.customsId,
        type: 'Detail',
        module: 'Customs',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/customs/' + encry;
      window.open(url, '_blank');
    }
  }

  @Output() loadShipmentList = new EventEmitter();

  loadShipmentListById(id: any) {
    this.loadShipmentList.emit(id);
  }
}
