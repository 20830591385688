import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionFieldService } from 'src/app/features/admin/actionfield/services/action-field.service';
import { BookingServies } from '../../services/booking.service';
import { CommonValidation } from 'src/app/shared/common/common-validation';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
declare var $: any;

@Component({
  selector: 'app-action-detail-tab',
  templateUrl: './action-detail-tab.component.html',
  styleUrls: ['./action-detail-tab.component.scss'],
})
export class ActionDetailTabComponent
  extends CommonValidation
  implements OnChanges {
  isDefault: boolean = false;
  isDefaultDate: boolean = false;
  isAlreadySaved: boolean = false;
  isDefaultDatetime: boolean = false;

  constructor(
    private actionFieldService: ActionFieldService,
    private formBuilder: FormBuilder,
    private bookingServices: BookingServies,
    private appInsightsService: AppInsightsService
  ) {
    super();
    this.loadFormPropeties();
  }

  @Input() orgCode: any;
  @Input() moduleId: any;
  @Input() moduleName: any;
  @Input() selectedBooking: any;
  @Input() currentUserData: any;
  @Output() toastEmitter = new EventEmitter();
  actionFieldDetails: any = [];
  sectionBasedDetails: any = [];
  actionFieldForm: FormGroup;
  disableSave: boolean = true;
  tempActionFieldSelected: any = [];
  isProgressShow: boolean = false;
  canLoadTabData: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'selectedBooking') {
        if (this.selectedBooking) {
          this.trackAITabView();
          this.loadFormPropeties();
          this.loadActionFieldsDetails(this.selectedBooking);
        }
      }
    }
  }

  getMonth(n: number) {
    const monthNames = [
      'Jan',
      'Feb',
      'March',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    return monthNames[n - 1];
  }

  date: any;
  time: any;
  changeDate(
    event: any,
    selectedDate: any,
    sectionIndex: number,
    index: number
  ) {
    let date =
      this.getMonth(selectedDate.month) +
      ' ' +
      selectedDate.day +
      ', ' +
      selectedDate.year;
    this.actionField(sectionIndex).controls[index].patchValue({
      actionFieldValue: [{ key: '', value: date }],
    });
    this.ActionFieldFormChanges();
    let value =
      this.actionField(sectionIndex).controls[index].value.actionFieldValue;
    if (value && value[0]?.value?.trim() != '') {
      this.showOrHideClearIcon(sectionIndex, index, true);
    }
    this.isDefaultDate = false;
    this.isDefaultDatetime = false;
  }

  convertTimeTo12HrUTC(time: any) {
    // format: 8:00:00
    const timeString12hr = new Date(
      '1970-01-01T' + time + 'Z'
    ).toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });

    return timeString12hr;
  }

  changeDateTime(
    event: any,
    selectedDate: any,
    selectedTime: any,
    sectionIndex: number,
    index: number,
    currentValue: any
  ) {
    if (selectedDate || selectedTime) {
      let temp: any = '';
      if (
        currentValue !== null ||
        currentValue !== undefined ||
        currentValue != ''
      ) {
        temp = currentValue.split('-');
      }
      let date = temp === '' ? '' : temp[0] === undefined ? '' : temp[0];
      let time = temp === '' ? '' : temp[1] === undefined ? '' : temp[1];
      if (selectedDate) {
        date =
          this.getMonth(selectedDate.month) +
          ' ' +
          selectedDate.day +
          ', ' +
          selectedDate.year;
      }

      if (selectedTime) {
        let hour = selectedTime.hour;
        if (hour < 10) {
          hour = '0' + hour;
        }
        let minute = selectedTime.minute;
        if (minute < 10) {
          minute = '0' + minute;
        }

        time = this.convertTimeTo12HrUTC(hour + ':' + minute + ':00');
        const [hours, minutes] = time?.split(':');
        if (parseInt(hours) < 10) {  // converting hour 3 => 03 
          time = time?.replace(/(^\d+)(?=:)/, hours.toString().padStart(2, '0'));
        }
      }

      let dateTime = date + '-' + time;
      this.actionField(sectionIndex).controls[index].patchValue({
        actionFieldValue: [{ key: '', value: dateTime }],
      });
      this.ActionFieldFormChanges();
      this.patchDateTimeValidation(false, sectionIndex, index);
      let value =
        this.actionField(sectionIndex).controls[index].value.actionFieldValue;
      if (value && value[0]?.value?.trim() != '') {
        this.showOrHideClearIcon(sectionIndex, index, true);
      }
      this.isDefaultDate = false;
      this.isDefaultDatetime = false;
    }
  }

  actionFieldItemList(item: any) {
    return {
      actionFieldId: item.actionFieldId,
      actionFieldName: item.actionFieldName,
      module: item.module,
      controlType: item.controlType,
      dataType: item.dataType,
      org_Code: item.org_Code,
      dropdownList: item.dropdownList,
      displayOrder: item.displayOrder,
      updatedOn: item.updatedOn,
      lastUsed: item.lastUsed,
      actionFieldValue: item.actionFieldValue
        ? item.actionFieldValue
        : [{ key: '', value: '' }],
      hintText: item.hintText,
      toolTipText: item.toolTipText,
      minimumLength: item.minimumLength,
      maximumLength: item.maximumLength,
      dateDropdownToggleFlag: false,
      dateTimeDropdownToggleFlag: false,
      dateTimeValidation: false,
      //dataTypeChange: false,
      numberValidation: false,
      decimalValidation: false,
      startDate: this.setStartDate(
        item?.actionFieldValue,
        item.controlType.displayText
      ),
      dates: this.setDate(item?.actionFieldValue, item.controlType.displayText),
      times: this.setTime(item?.actionFieldValue, item.controlType.displayText),
      clearIcon: false,
    };
  }

  setTime(value: any, controlType: any) {
    if (value !== null) {
      let item = value[0].value;
      if (item?.trim() != '') {
        if (controlType === 'Datetime') {
          let date = item.split(' ');
          let time = date[2].split('-')[1].split(':');
          let hour = time[0];
          let minute = time[1];
          return {
            hour: parseInt(hour),
            minute: parseInt(minute),
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  setStartDate(value: any, controlType: any) {
    if (value !== null) {
      let item = value[0].value;
      if (item?.trim() != '') {
        if (controlType === 'Date') {
          let date = item.split(' ');
          return {
            year: parseInt(date[2]),
            month: this.getMonthIndex(date[0]),
          };
        } else if (controlType === 'Datetime') {
          let date = item.split(' ');
          let year = date[2].split('-');
          return {
            year: parseInt(year),
            month: this.getMonthIndex(date[0]),
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  setDate(value: any, controlType: any) {
    if (value !== null) {
      let item = value[0].value;
      if (item?.trim() != '') {
        if (controlType === 'Date') {
          let date = item.split(' ');
          return {
            year: parseInt(date[2]),
            month: this.getMonthIndex(date[0]),
            day: parseInt(date[1].replace(',', '')),
          };
        } else if (controlType === 'Datetime') {
          let date = item.split(' ');
          let year = date[2].split('-');
          return {
            year: parseInt(year),
            month: this.getMonthIndex(date[0]),
            day: parseInt(date[1].replace(',', '')),
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getMonthIndex(month: any) {
    const monthNames = [
      'Jan',
      'Feb',
      'March',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    return monthNames.indexOf(month) + 1;
  }

  loadFormPropeties() {
    this.actionFieldForm = this.formBuilder.group({
      sections: this.formBuilder.array([]),
    });
  }

  loadActionFieldsDetails(selectedBooking: any) {
    this.actionFieldDetails = [];
    this.isProgressShow = true;
    this.actionFieldService
      .getSectionDetailsByOrgCode(
        this.currentUserData.accountId,
        selectedBooking.id,
        'bookings',
        this.currentUserData.isGroup
      )
      .subscribe(
        (e: any) => {
          this.loadFormPropeties();
          this.isProgressShow = false;
          this.actionFieldDetails = [...e.result];
          if (e.result?.length > 0) {
            this.bindActionFieldInEditFlow(this.actionFieldDetails);
          } else {
            this.actionFieldService.sectionBasedItemAtCargoWise = [];
          }
        },
        () => {
          this.isProgressShow = false;
        }
      );
  }

  creatSectionBasedList(list: any) {
    let sectionBasedList: any = [];
    let actionFieldItem = [];
    let initial = false;
    let skip: any = [];
    for (let i = 0; i < list.length; i++) {
      initial = true;
      if (!skip.includes(i)) {
        for (let j = i + 1; j < list.length; j++) {
          if (
            list[i].sectionName === list[j].sectionName &&
            list[i].sectionOrder === list[j].sectionOrder
          ) {
            actionFieldItem.push(this.actionFieldItemList(list[j]));
            skip.push(j);
            if (initial) {
              actionFieldItem.push(this.actionFieldItemList(list[i]));
              initial = false;
            }
          }
        }
        if (initial) {
          actionFieldItem.push(this.actionFieldItemList(list[i]));
        }
        let sectionList = {
          sectionName: list[i].sectionName,
          sectionOrder: list[i].sectionOrder,
          showFullWidth: list[i].showFullWidth,
          actionFields: actionFieldItem,
        };
        sectionBasedList.push(sectionList);
        actionFieldItem = [];
      }
    }
    return sectionBasedList;
  }

  getType(value: any) {
    return parseFloat(value).toString().length === value.length;
  }

  checkDataTypeIsValid(dataType: any, value: any) {
    if (
      dataType.toLowerCase() === 'number' ||
      dataType.toLowerCase() === 'decimal'
    ) {
      return this.getType(value);
    } else {
      return true;
    }
  }
  /* istanbul ignore next */
  bindDropDownDefaultValue(list: any) {
    list.forEach((e: any, i: number) => {
      e.actionFields.forEach((f: any, j: number) => {
        if (
          f.controlType.displayText === 'Checkbox' &&
          f.actionFieldValue[0].value === ''
        ) {
          this.handleCheckBoxEmptyValue(i, j);
        }
        if (
          f.controlType.displayText === 'Dropdown' &&
          f.actionFieldValue[0].value === ''
        ) {
          f.dropdownList.forEach((g: any) => {
            if (g.defaultValue) {
              this.actionField(i).controls[j].patchValue({
                actionFieldValue: [g],
              });
            }
          });
        }
      });
    });
  }
  /* istanbul ignore next */
  handleCheckBoxEmptyValue(sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      actionFieldValue: [{ key: '', value: 'false' }],
    });
  }

  validateDataTypeChange(list: any) {
    let numberValidation: any = [];
    let decimalValidation: any = [];
    list.forEach((e: any, i: number) => {
      e.actionFields.forEach((f: any, j: number) => {
        numberValidation = this.validateNumber(i, j, list);
        decimalValidation = this.validateDecimal(i, j, list);
        if (numberValidation) {
          this.patchNumberValidation(
            true,
            numberValidation[0],
            numberValidation[1]
          );
        }
        if (decimalValidation) {
          this.patchDecimalValidation(
            true,
            decimalValidation[0],
            decimalValidation[1]
          );
        }
      });
    });
  }

  defaultSections() {
    return {
      sectionName: '',
      sectionOrder: '',
      showFullWidth: true,
      actionFields: [],
    };
  }

  reOrderSectionOrder(list: any) {
    list.forEach((e: any, i: number) => {
      e.sectionOrder = i + 1;
    });
    return list;
  }

  handleDefaultFeed(list: any) {
    let exist: boolean = false;
    for (let i = 0; i < list.length; i++) {
      if (list[i].sectionName === '') {
        exist = true;
        break;
      }
    }

    if (!exist) {
      list.unshift(this.defaultSections());
      return this.reOrderSectionOrder(list);
    } else {
      return list;
    }
  }

  bindActionFieldInEditFlow(list: any) {
    let sectionBaseList = this.creatSectionBasedList(list);
    sectionBaseList?.sort((a: any, b: any) => a.sectionOrder - b.sectionOrder);
    sectionBaseList?.forEach((e: any, i: number) => {
      e.actionFields?.sort((a: any, b: any) => a.displayOrder - b.displayOrder);
    });

    sectionBaseList = this.handleDefaultFeed(sectionBaseList);
    this.sectionBasedDetails = sectionBaseList;

    sectionBaseList.forEach((e: any, i: number) => {
      this.sectionsField().push(this.newSections());
      this.sectionsField().controls[i].patchValue({
        sectionName: e.sectionName,
        sectionOrder: e.sectionOrder,
        showFullWidth: e.showFullWidth,
      });
      e.actionFields.forEach((f: any, j: number) => {
        this.addDynamicActionField(i);
        this.tempActionFieldSelected.push(f.actionFieldName);
      });
      this.actionField(i).setValue(e.actionFields);
    });
    this.disableSave = true;
    this.bindDropDownDefaultValue(this.sectionBasedDetails);
    this.validateDataTypeChange(this.sectionBasedDetails);
    this.actionFieldService.sectionBasedItemAtCargoWise =
      this.actionFieldForm.value.sections;
  }

  sectionsField(): FormArray {
    return this.actionFieldForm?.get('sections') as FormArray;
  }

  actionField(sectionIndex: number): FormArray {
    return this.sectionsField()
      ?.at(sectionIndex)
      ?.get('actionFields') as FormArray;
  }

  newSections(): FormGroup {
    return this.formBuilder.group({
      sectionName: '',
      sectionOrder: 0,
      showFullWidth: true,
      actionFields: this.formBuilder.array([]),
    });
  }

  newActionField(): FormGroup {
    return this.formBuilder.group({
      actionFieldId: '',
      actionFieldName: ['', Validators.required],
      module: this.newModule(),
      controlType: '',
      dropdownList: '',
      dataType: '',
      org_Code: '',
      displayOrder: 0,
      updatedOn: '',
      lastUsed: '',
      actionFieldValue: this.formBuilder.array([
        this.newActionFieldValueProperty(),
      ]),
      hintText: '',
      toolTipText: '',
      minimumLength: '',
      maximumLength: '',
      dateDropdownToggleFlag: false,
      dateTimeDropdownToggleFlag: false,
      dateTimeValidation: false,
      //dataTypeChange: false,
      numberValidation: false,
      decimalValidation: false,
      startDate: null,
      dates: null,
      times: null,
      clearIcon: false,
    });
  }

  newModule(): FormGroup {
    return this.formBuilder.group({
      isShipment: false,
      isBooking: false,
      isCustoms: false,
    });
  }
  /* istanbul ignore next */
  addSelectedCheckBox(event: any, sectionIndex: number, index: number) {
    let checked: string = event.target.checked ? 'true' : 'false';
    this.actionField(sectionIndex).controls[index].patchValue({
      actionFieldValue: [{ key: '', value: checked }],
    });
    this.ActionFieldFormChanges();
  }
  /* istanbul ignore next */
  addSelectedDropDownValue(item: any, sectionIndex: number, index: number) {
    this.isDefault = false;
    this.isAlreadySaved = false;
    this.actionField(sectionIndex).controls[index].patchValue({
      actionFieldValue: [item],
    });
    this.ActionFieldFormChanges();
    this.showOrHideClearIcon(sectionIndex, index, true);
  }
  /* istanbul ignore next */
  toggleDateDropDown(sectionIndex: number, index: number) {
    this.showOrHideClearIcon(sectionIndex, index, true);
    let flag =
      this.actionFieldForm.value.sections[sectionIndex].actionFields[index]
        .dateDropdownToggleFlag;
    this.actionField(sectionIndex).controls[index].patchValue({
      dateDropdownToggleFlag: !flag,
    });
  }
  /* istanbul ignore next */
  toggleDateTimeDropDown(sectionIndex: number, index: number) {
    let flag =
      this.actionFieldForm.value.sections[sectionIndex].actionFields[index]
        .dateTimeDropdownToggleFlag;
    this.actionField(sectionIndex).controls[index].patchValue({
      dateTimeDropdownToggleFlag: !flag,
    });
  }
  /* istanbul ignore next */
  offToggleDateTime(sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      dateTimeDropdownToggleFlag: false,
    });
    this.offAllClearIconFlagOnSpecificIndex(sectionIndex, index);
  }
  /* istanbul ignore next */
  offToggleDate(sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      dateDropdownToggleFlag: false,
    });
    this.offAllClearIconFlagOnSpecificIndex(sectionIndex, index);
  }

  newActionFieldValueProperty(): FormGroup {
    return this.formBuilder.group({
      key: [''],
      value: [''],
    });
  }

  addSections() {
    this.sectionsField().push(this.newSections());
    this.ActionFieldFormChanges();
  }
  /* istanbul ignore next */
  addDynamicActionField(sectionIndex: number) {
    this.actionField(sectionIndex).push(this.newActionField());
  }

  ActionFieldFormChanges() {
    setTimeout(() => {
      let noChanges = this.compareCurrentAndPreviousValues();
      this.disableSave = noChanges;
      this.bookingServices.isActionFieldChanged = !noChanges;
    }, 0);
  }

  extractProperties(item: any) {
    let newItem = [...JSON.parse(JSON.stringify(item))];
    let propertyChange: any = [];
    newItem?.forEach((e: any) => {
      e.sectionOrder = 0;
      e.actionFields?.forEach((f: any) => {
        (f.displayOrder = 0), (f.updatedOn = ''), (f.lastUsed = '');
        propertyChange.push(f.actionFieldValue);
      });
    });
    return propertyChange;
  }

  compareCurrentAndPreviousValues() {
    let currentData = this.extractProperties(
      this.actionFieldForm.value.sections
    );
    let lastSavedData = this.extractProperties(
      this.actionFieldService?.sectionBasedItemAtCargoWise
    );
    console.log(JSON.stringify(currentData))
    console.log(JSON.stringify(lastSavedData))
    if (JSON.stringify(currentData) === JSON.stringify(lastSavedData)) {
      return true;
    } else {
      return false;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control: any = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        for (const nestedFormGroup of control.controls) {
          this.validateAllFormFields(nestedFormGroup as FormGroup);
        }
      } else {
        control.markAsTouched();
      }
    });
  }

  setValue() {
    this.validateAllFormFields(this.actionFieldForm);
  }
  /* istanbul ignore next */
  configureActionFieldParam() {
    let sectionBasedList = this.actionFieldForm.value;
    let list: any = [];
    sectionBasedList.sections.forEach((e: any, i: number) => {
      if (e.actionFields.length > 0) {
        e.actionFields.forEach((s: any, j: number) => {
          list.push({
            actionFieldId: s.actionFieldId,
            actionFieldName: s.actionFieldName,
            org_Code: s.org_Code,
            sectionName: e.sectionName,
            sectionOrder: e.sectionOrder,
            showFullWidth: e.showFullWidth,
            module: s.module,
            controlType: s.controlType,
            dataType: s.dataType,
            dropdownList: s.dropdownList,
            displayOrder: s.displayOrder,
            updatedOn: s.updatedOn,
            lastUsed: s.lastUsed,
            actionFieldValue: s.actionFieldValue,
            hintText: s.hintText,
            toolTipText: s.toolTipText,
            minimumLength: s.minimumLength,
            maximumLength: s.maximumLength,
          });
        });
      }
    });

    return list;
  }
  /* istanbul ignore next */
  patchDateTypeChangeValidation(
    flag: boolean,
    sectionIndex: number,
    index: number
  ) {
    this.actionField(sectionIndex).controls[index].patchValue({
      dataTypeChange: flag,
    });
  }
  /* istanbul ignore next */
  patchDateTimeValidation(flag: boolean, sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      dateTimeValidation: flag,
    });
  }

  validateNumber(i: number, j: number, list: any) {
    let value = list[i].actionFields[j]?.actionFieldValue[0].value;
    if (
      (list[i].actionFields[j]?.controlType?.displayText == 'Text Box' ||
        list[i].actionFields[j]?.controlType?.displayText
          ?.toLowerCase()
          ?.replaceAll(' ', '') == 'textbox') &&
      (list[i].actionFields[j]?.dataType?.displayText == 'Number' ||
        list[i].actionFields[j]?.dataType?.displayText?.toLowerCase() ==
        'number')
    ) {
      if (value.trim() && !super.isNumber(value)) {
        return [i, j];
      }
    }
    return null;
  }

  validateDecimal(i: number, j: number, list: any) {
    let value = list[i].actionFields[j]?.actionFieldValue[0].value;
    if (
      (list[i].actionFields[j]?.controlType?.displayText == 'Text Box' ||
        list[i].actionFields[j]?.controlType?.displayText
          ?.toLowerCase()
          ?.replaceAll(' ', '') == 'textbox') &&
      (list[i].actionFields[j]?.dataType?.displayText == 'Decimal' ||
        list[i].actionFields[j]?.dataType?.displayText?.toLowerCase() ==
        'decimal')
    ) {
      if (value.trim() && !super.isDecimal(value)) {
        return [i, j];
      }
    }
    return null;
  }

  patchNumberValidation(flag: boolean, sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      numberValidation: flag,
    });
  }

  patchDecimalValidation(flag: boolean, sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      decimalValidation: flag,
    });
  }

  unSetAllValidation(i: number, j: number) {
    this.patchNumberValidation(false, i, j);
    this.patchDecimalValidation(false, i, j);
  }

  validateDataType(list: any) {
    let exist: boolean = false;
    let numberValidation: any = [];
    let decimalValidation: any = [];
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].actionFields.length; j++) {
        this.unSetAllValidation(i, j);
        numberValidation = this.validateNumber(i, j, list);
        decimalValidation = this.validateDecimal(i, j, list);
        if (numberValidation) {
          this.patchNumberValidation(
            true,
            numberValidation[0],
            numberValidation[1]
          );
          exist = true;
        }
        if (decimalValidation) {
          this.patchDecimalValidation(
            true,
            decimalValidation[0],
            decimalValidation[1]
          );
          exist = true;
        }
      }
    }
    if (exist) {
      return false;
    } else {
      return true;
    }
  }

  pointMissingField(sectionIndex: number, index: number) {
    let offset: any = $('#dateTime-' + sectionIndex + '-' + index).offset();
    $('#actionField-scroll-div').scrollTop(offset.top);
  }

  validateDateAndTime(list: any) {
    let sectionIndex: any = [];
    let findIndex: any = [];
    list.forEach((e: any, i: number) => {
      if (e.dataType.displayText === 'Datetime') {
        if (
          e.actionFieldValue[0].value.split('-').filter((e: any) => e !== '')
            ?.length == 1
        ) {
          sectionIndex.push(e.sectionOrder - 1);
          findIndex.push(i);
          this.patchDateTimeValidation(true, e.sectionOrder - 1, i);
        }
      }
    });

    if (sectionIndex.length > 0 || findIndex.length > 0) {
      this.pointMissingField(sectionIndex[0], findIndex[0]);
      return false;
    } else {
      return true;
    }
  }

  makeRequest() {
    let dateTimeValidation = this.validateDateAndTime(
      this.configureActionFieldParam()
    );
    let dataTypeValidation = this.validateDataType(
      this.actionFieldForm.value.sections
    );
    if (
      this.actionFieldForm.valid &&
      dateTimeValidation &&
      dataTypeValidation
    ) {
      this.isProgressShow = true;
      this.actionFieldService
        .captureActionFieldConfiguration(
          this.configureActionFieldParam(),
          this.selectedBooking.id,
          'bookings'
        )
        .subscribe(
          (e: any) => {
            if (e.statusCode === 'OC200') {
              this.isProgressShow = false;
              this.trackAIActionFieldSubmitEvent();
              this.showToastMessage('Updated successfully');
              let sectionBaseList = this.creatSectionBasedList(e.result);
              sectionBaseList?.sort(
                (a: any, b: any) => a.sectionOrder - b.sectionOrder
              );
              sectionBaseList?.forEach((e: any, i: number) => {
                e.actionFields?.sort(
                  (a: any, b: any) => a.displayOrder - b.displayOrder
                );
              });

              this.actionFieldService.sectionBasedItemAtCargoWise = [
                ...sectionBaseList,
              ];
              this.ActionFieldFormChanges();
              this.loadBookingListById(this.selectedBooking.id);
            }
          },
          () => {
            this.isProgressShow = false;
          }
        );
    } else {
      this.validateAllFormFields(this.actionFieldForm);
    }
  }

  isShowMessage: boolean = false;
  showMessage: any = '';
  showToastMessage(msg: string) {
    this.isShowMessage = true;
    this.showMessage = msg;
    this.clearToast();
  }

  clearToast() {
    setTimeout((e: any) => {
      this.isShowMessage = false;
      this.showMessage = '';
    }, 4000);
  }

  showCancelModal: boolean = false;
  @Output() closePopUp = new EventEmitter();
  closeActionField() {
    let noChanges = this.compareCurrentAndPreviousValues();
    if (noChanges) {
      this.closePopUp.emit('closeModal');
    } else {
      this.retainTab();
      this.showCancelModal = true;
    }
  }
  /* istanbul ignore next */
  retainTab() {
    $('#action-tab').tab('show');
  }

  getStringOccourance(char: string, value: string) {
    let count = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i].indexOf(char) != -1) {
        count = count + 1;
      }
    }
    return count;
  }

  offValidation(sectionIndex: number, index: number) {
    this.patchNumberValidation(false, sectionIndex, index);
    this.patchDecimalValidation(false, sectionIndex, index);
    this.ActionFieldFormChanges();
  }

  /* istanbul ignore next */
  patternMatching(
    dataType: any,
    event: any,
    isDataTypeInValid: boolean,
    sectionIndex: number,
    index: number
  ) {
    if (isDataTypeInValid) {
      event.target.value = event.target.value.replace(event.target.value, '');
      this.patchDateTypeChangeValidation(false, sectionIndex, index);
    }
    if (dataType == 'Number') {
      if (event.keyCode == 32) {
        let value = ''.trim();
        event.target.value = event.target.value.replace('.', value);
        event.target.value = event.target.value.replace(' ', value);
        event.preventDefault();
      }
      if (
        !(
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          event.keyCode == 8 ||
          (event.keyCode >= 96 && event.keyCode <= 105)
        )
      ) {
        event.preventDefault();
      }
    } else if (dataType == 'Decimal') {
      let typed = event.target.value;
      if (event.keyCode == 32) {
        let value = ''.trim();
        if (this.getStringOccourance('.', typed) == 0) {
          event.target.value = event.target.value.replace('.', value);
        }
        event.target.value = event.target.value.replace(' ', value);
        event.preventDefault();
      }

      if (
        !(
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          event.keyCode == 8 ||
          event.keyCode == 190 ||
          (event.keyCode >= 96 && event.keyCode <= 105)
        )
      ) {
        event.preventDefault();
      } else if (
        event.keyCode == 190 &&
        this.getStringOccourance('.', typed) > 0
      ) {
        event.preventDefault();
      }
    }
    this.ActionFieldFormChanges();
  }

  @Output() loadBookingList = new EventEmitter();
  loadBookingListById(id: any) {
    this.loadBookingList.emit(id);
  }

  closeModal() {
    if (this.bookingServices.isFullScreenTabEnabled) {
      this.showCancelModal = false;
      this.loadFormPropeties();
      this.loadActionFieldsDetails(this.selectedBooking);
    } else {
      this.closePopUp.emit('closeModal');
    }
  }

  closeInfo(sectionIndex: number, index: number) {
    this.patchNumberValidation(false, sectionIndex, index);
    this.patchDecimalValidation(false, sectionIndex, index);
    this.ActionFieldFormChanges();
    if (
      this.actionField(sectionIndex).controls[index].value.controlType
        .displayText == 'Dropdown'
    ) {
      this.actionField(sectionIndex).controls[index].value.dropdownList.forEach(
        (g: any) => {
          if (g.defaultValue) {
            this.actionField(sectionIndex).controls[index].patchValue({
              actionFieldValue: [g],
            });
            this.isDefault = true;
          }
          if (!this.isDefault) {
            this.actionField(sectionIndex).controls[index].patchValue({
              actionFieldValue: [{ key: '', value: '' }],
            });
          }
        }
      );
    } else {
      this.actionField(sectionIndex).controls[index].patchValue({
        actionFieldValue: [{ key: '', value: '' }],
      });
    }
    let noChanges = this.compareCurrentAndPreviousValues();
    this.disableSave = noChanges;

    this.showOrHideClearIcon(sectionIndex, index, false);
  }
  showOrHideClearIcon(sectionIndex: number, index: number, flag: boolean) {
    this.offAllClearIconFlag();
    let value =
      this.actionField(sectionIndex).controls[index].value.actionFieldValue;

    if (value && value[0]?.value?.trim() != '') {
      this.actionField(sectionIndex).controls[index].patchValue({
        clearIcon: flag,
      });
    } else {
      this.actionField(sectionIndex).controls[index].patchValue({
        clearIcon: false,
      });
    }
  }
  offAllClearIconFlag() {
    this.actionFieldForm
      .value.sections.forEach((e: any, sectionIndex: number) => {
        e.actionFields.forEach((f: any, index: number) => {
          this.actionField(sectionIndex).controls[index].patchValue({
            clearIcon: false,
          });
          // console.log(sectionIndex, index)
        });
      }
      );
  }

  offAllClearIconFlagOnSpecificIndex(sectionIndex: number, index: number) {
    this.actionField(sectionIndex).controls[index].patchValue({
      clearIcon: false,
    });
  }

  trackAITabView() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ActionTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedBooking?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
  }

  trackAIActionFieldSubmitEvent() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ActionFieldSubmitted.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedBooking?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
  }
}
