import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AI_CustomProps,
  AI_PageName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
@Component({
  selector: 'app-booking-global-search',
  templateUrl: './booking-global-search.component.html',
  styleUrls: ['./booking-global-search.component.scss'],
})
export class BookingGlobalSearchComponent implements OnChanges {
  defaultBookingSortSelectedColumn: string = 'booking_confirmed_actual';
  defaultBookingSortDirection: string = 'desc';
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  bookingMasterData: any[] = [];
  isProgressShow: boolean = false;
  defaultFilterSelectedValue: any = 0;
  @Input() bookingAutoSuggestedRecord: any[] = [];
  @Input() totalBookingCount: number = 0;
  @Input() searchedText: any = '';
  globalSearchPopup = false;
  @Output() viewAllEmit = new EventEmitter<any>();

  constructor(
    private currentSessionUserData: CurrentSessionDataService,
    public router: Router,
    private appInsightsService: AppInsightsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.searchedText.length > 0) {
      this.globalSearchPopup = true;
    } else {
      this.globalSearchPopup = false;
    }
  }

  resultPage() {
    let val = this.searchedText;
    if (val.length > 2) {
      this.trackAISearchClickEvent(val);
      this.viewAllEmit.emit('emit');
      let filterVal = JSON.stringify({ val: val, from: 'Booking' });
      this.router.navigate(['/shipments/view/search-result'], {
        queryParams: { filterParam: filterVal },
        skipLocationChange: true,
      });
    }
  }

  trackAISearchClickEvent(searchText: any) {
    this.appInsightsService.logEvent(AI_PageName.GlobalSearch, {
      [AI_CustomProps.ModuleName]: 'Booking Global Search',
      [AI_CustomProps.SearchText]: searchText,
    });
  }
}
