import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../model/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    let userJson = localStorage.getItem('googleAccount')
      ? localStorage.getItem('googleAccount')
      : localStorage.getItem('microSoftAccount');
    let currentUserJson = userJson !== null ? JSON.parse(userJson) : '';
    this.currentUserSubject = new BehaviorSubject<User>(currentUserJson);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getUser(email: string) {
    return this.http
      .get<User[]>(environment.base_api_desktop_url + 'user?userEmail=' + email)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError({
            status: error.status,
            statusText: error.statusText,
          });
        })
      );
  }

  loggedIn(user: any) {
    if (user) {
      this.currentUserSubject.next(user);
    } else {
      let test: any = {};
      this.currentUserSubject.next(test);
    }
  }

  logout() {
    let test: any = {};
    this.currentUserSubject.next(test);
  }

  register(param: any) {
    return this.http
      .post(environment.base_api_desktop_url + 'user', param)
      .pipe(
        catchError((errorRes: HttpErrorResponse) => {
          return throwError({
            status: errorRes.status,
            statusText: errorRes.statusText,
          });
        })
      );
  }

  outlookUserInfo() {
    return this.http
      .request('GET', 'https://graph.microsoft.com/oidc/userinfo')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError({
            status: error.status,
            statusText: error.statusText,
          });
        })
      );
  }
}
