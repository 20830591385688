import { Pipe, PipeTransform } from '@angular/core';
import { ProgressBarType } from './shipment.enums';
import { CommonUtils } from './utils';

@Pipe({
  name: 'departureLocation',
})
export class DepartureLocationPipe implements PipeTransform {
  transform(data: any): unknown {
    let displayText = '<div class="rap">';
    //if (data?.shipment_legs) {
    let shipment_legs = CommonUtils.sortShipmentLegsBySequenceNumber(data);
    let min_leg = shipment_legs != null ? shipment_legs[0] : {};
    displayText +=
      '<span class="font-size-set">' +
      CommonUtils.getPortAndCountryToolTipText(
        CommonUtils.toTitleCase(min_leg?.lading_port?.portName),
        '',
        min_leg?.lading_port?.country_name,
        min_leg?.lading_port?.country_name,
        ''
      ) +
      '</span>';
    displayText += CommonUtils.getRoutingToolTipText(
      'ATD',
      'ETD',
      min_leg?.departure_actual, //departure_arrival
      min_leg?.departure_estimated,
      ProgressBarType.ShipmentListingPage
    );
    //}
    displayText += '</div>';
    return displayText;
  }
}
