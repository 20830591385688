import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { EdocUploadStatusService } from 'src/app/shared/services/eDoc-upload-status.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from '../../services/customs.service';
import { CustomsDetailsComponent } from '../customs-details/customs-details.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { OC3Constants } from 'src/app/common/oc3-constants';
import { AppInsightsService } from 'src/app/services/appinsights.service';
declare var $: any;
@Component({
  selector: 'app-customs-list',
  templateUrl: './customs-list.component.html',
  styleUrls: ['./customs-list.component.scss'],
})
export class CustomsListComponent implements OnChanges {
  @Input() isInfiniteDisabled = false;
  @Input() customsListData: any = [];
  @Input() dashboardWatchlist: boolean;
  @Input() dashboardCustom: boolean;
  @Input() totalCustomsCount = 0;
  @Input() offset = 0;
  @Input() isProgressShow: boolean = false;
  @Input() searchText: any = '';
  @Input() urlCustomsId: any = '';
  @Output() emitOffset = new EventEmitter();
  @Input() globalSearchPopup: boolean = false;
  @Input() globalSearch: boolean;
  @Input() resultPageOffset = 0;
  @Input() type: any = '';
  @Input() currentStatus: number;
  @Input() urlShare = '';
  @Input() publicShareEnrcyptedData: any = '';
  @Output() reloadCustomsList = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  @ViewChild(CustomsDetailsComponent)
  childShipment!: CustomsDetailsComponent;
  @Input() publicShareFilter: any = '';
  @Input() publicShareData: any = '';
  @Input() publicShareType: any = '';
  @Input() publicShareVisibility = false;
  @Input() guidedTourDetail: any = '';
  currentSessionUser: any;
  currentSessionAccount: any;
  getCustomDetails: any = [];
  popupToggle = false;
  sessionUserRole: any = '';
  sharecustomsIdToChild: any = '';
  edocUploadTigger = false;
  detailShipmentShare: boolean = false;
  popupToggleList = false;
  detailSharePopup = false;
  singleCustomShare: boolean = false;
  isListComponentShow = false;
  copyText: any = '';
  isGuidedTourCustoms: boolean;
  isCustomsSecHide: boolean;
  isCustomsSecShow: boolean;
  isToggleShow: boolean = false;
  countSetvalue: number = 0;
  showActionFieldCancelModal: boolean = false;
  lastSelectedCustomsId: any;
  isActionField: boolean = false;

  constructor(
    private router: Router,
    public currentUserDataService: CurrentSessionDataService,
    public customService: CustomsService,
    private fileService: FileUploadService,
    private edocUploadStatusService: EdocUploadStatusService,
    private dataSharingService: DataSharingService,
    private breakpointObserver: BreakpointObserver,
    private appInsightsService: AppInsightsService
  ) {
    this.breakpointObserver
      .observe(['(min-height: 1100px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.countSetvalue = OC3Constants.CUSTOM_LIST_DEFAULT_COUNT + 10;
        } else {
          this.countSetvalue = OC3Constants.CUSTOM_LIST_DEFAULT_COUNT;
        }
      });
    this.currentSessionUser = this.currentUserDataService.getCurrentUserData();
    this.sessionUserRole = this.currentSessionUser.userType;
    this.currentSessionAccount =
      this.currentUserDataService.getCurrentAccountData();
    this.isActionField = this.currentSessionAccount.isCustomActionField;
    this.dataSharingService.isGuidedTourShipment.subscribe((value) => {
      this.isGuidedTourCustoms = value;
    });
    this.dataSharingService.isGuidedTourShipmentSecHide.subscribe((value) => {
      this.isCustomsSecHide = value;
    });
    this.dataSharingService.isGuidedTourShipmentSecShow.subscribe((value) => {
      this.isCustomsSecShow = value;
    });
    this.dataSharingService.isGuidedTourToggleShow.subscribe((value) => {
      this.isToggleShow = value;
    });
    this.fileService.reloadCustoms.subscribe((value) => {
      if (value.reload) {
        this.dashboardCustom = false;
        this.dashboardWatchlist = false;
        this.selectedListCustom(value.shipmentId);
      }
    });

    this.fileService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        let fileUploadPercentage: any[] = [];
        let cancelledDocs: any[] = [];
        setTimeout(() => {
          fileUploadPercentage = res;
          fileUploadPercentage.some((el: any) => {
            if (el.Percentage < 100 && !el.Error) {
              cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
            }
          });
          if (cancelledDocs.length > 0) {
            this.isListComponentShow = true;
          } else {
            this.isListComponentShow = false;
          }
        }, 0);
      }
    });

    this.dataSharingService.eDocUploadTrigger.subscribe((value: any) => {
      if (value.popup) {
        this.edocUploadTigger = true;
      } else if (value.detailPopup && this.childShipment) {
        this.childShipment.popCloseForUpload();
      } else {
        this.edocUploadTigger = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.urlCustomsId) {
      this.selectedListCustom(this.urlCustomsId);
      setTimeout(() => {
        this.sharecustomsIdToChild = this.urlCustomsId;
      }, 1500);
    }
    let root = this;
    $(document).on('mouseup', function (e: any) {
      let container = $('#pop-up3');
      if (
        !container.is(e.target) &&
        container.has(e.target).length === 0 &&
        e.originalEvent.detail === 1
      ) {
        if (
          !root.popupToggleList &&
          !root.edocUploadTigger &&
          !root.isGuidedTourCustoms
        ) {
          root.checkIsUploadInProgress();
        }
        // root.checkIsUploadInProgress();
      }
    });
    $(document).ready(function () {
      $('#left-slide-icon').click(function () {
        let hidden = $('.filter-nav');
        if (hidden.hasClass('visible')) {
          $('.oia-container').removeClass('nav-toggle');
          $('.oia-content-wrap').animate(
            { 'margin-left': '200px', width: '100%', height: '100%' },
            600
          );
        } else {
          $('.oia-container').addClass('nav-toggle');
          $('.oia-content-wrap').animate(
            { 'margin-left': '15px', width: '100%', height: '100%' },
            600
          );
        }
      });
    });
  }

  ngOnChanges(): void {
    if (this.guidedTourDetail == 'true') {
      this.selectedListCustom(this.customsListData[0].forwarder_reference);
    } else if (this.guidedTourDetail == 'false') {
      this.popupToggle = false;
      $('#customs-sec-id > div').removeClass('active');
    }
  }

  scrollHandler(event: any) {
    if (this.type == 'searchScreen') {
      if (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5) {
        this.emitOffset.emit({
          id: this.currentStatus,
          type: 'customs',
        });
      }
    }
  }

  onScroll(e: any) {
    if (this.type != 'searchScreen') {
      this.offset += this.countSetvalue;
      this.emitOffset.emit(this.offset);
    }
  }

  checkIsUploadInProgress() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      //this.popupToggle = false;
      if (this.customService.isActionFieldChanged) {
        this.showActionFieldCancelModal = true;
        this.retainTab();
      } else {
        this.showActionFieldCancelModal = false;
        this.closeTabModel();
      }
    } else {
      this.edocUploadTigger = true;
    }
  }

  retainTab() {
    $('#action-tab').tab('show');
  }

  switchTab() {
    if (this.customService.switchTabInitiated) {
      this.customService.isActionFieldChanged = false;
      this.selectedListCustom(this.lastSelectedCustomsId, true);
      this.showActionFieldCancelModal = false;
    } else {
      this.closeTabModel();
    }
    this.customService.switchTabInitiated = false;
  }

  closeTabModel() {
    this.showActionFieldCancelModal = false;
    this.popupToggle = false;
    $('#custom-sec-id > div').removeClass('active');
    this.customService.isActionFieldChanged = false;
    this.customService.switchTabInitiated = false;
  }

  // getCustomsExceptionClass(status: any, exception: any) {
  //   return CommonUtils.getCustomExceptionClass(status, exception);
  // }

  checkSFile(id: any) {
    if (id.charAt(0).toLowerCase() === 's') {
      return true;
    } else {
      return false;
    }
  }

  viewShipment(customsId: any) {
    this.appInsightsService.logEvent('Custom View Shipment Clicked', {
      'Shipment Id': customsId,
    });
    let selectedFilters: any;
    if (this.currentSessionAccount.shipmentIsVisible) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: customsId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    } else {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: customsId,
        type: 'Single',
        module: 'Shipment',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/dashboard/' + encry;
      window.open(url, '_blank');
    }
  }

  addWatchList(action: any, shipmentId: any) {
    let emailId = this.currentSessionUser.emailAddress;
    this.isProgressShow = true;
    this.appInsightsService.logEvent(
      action == 'Add' ? 'Custom Watch List Added' : 'Custom Watch List Removed',
      {
        'Shipment Id': shipmentId,
      }
    );
    this.customService
      .addRemoveWatchList(shipmentId, emailId, action)
      .subscribe({
        next: () => {
          this.isProgressShow = false;
          if (this.dashboardWatchlist || this.dashboardCustom) {
            this.reloadCustomsList.emit('reload');
          } else {
            this.fetchSingleShipment(shipmentId);
          }
        },
      });
  }

  fetchSingleShipment(shipmentId: any) {
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: shipmentId,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.customService
      .getAllCustoms(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        let filterMimeType = this.customsListData
          .map(function (x: any) {
            return x.forwarder_reference;
          })
          .indexOf(shipmentId);
        this.customsListData.splice(filterMimeType, 1, res.result[0]);
      });
  }

  checkTransportMode(data: any) {
    let transportTypeFile = '';
    if (!this.checkSFile(data.forwarder_reference)) {
      transportTypeFile = data?.transport_mode?.toUpperCase();
    } else {
      transportTypeFile =
        data.customs_declarations[0]?.transport_mode?.toUpperCase();
    }
    if (transportTypeFile === 'AIR') {
      return 'AIR';
    } else if (transportTypeFile === 'SEA') {
      return 'SEA';
    } else if (
      transportTypeFile === 'ROAD' ||
      transportTypeFile === 'ROA' ||
      transportTypeFile === 'TRK'
    ) {
      return 'ROAD';
    } else if (transportTypeFile === 'RAIL' || transportTypeFile === 'RAI') {
      return 'RAIL';
    }
    return '';
  }

  // mblSet(data: any) {
  //   return data.master_bill ? 'MBL: ' + data.master_bill : 'MBL: ---';
  // }

  onDetailPopupCloseHandler(event: any) {
    if (event.value != 'LOAD CUSTOMS') {
      this.popupToggle = false;
    }
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: event.customsId,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.customService
      .getAllCustoms(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        let filterMimeType = this.customsListData
          .map(function (x: any) {
            return x.forwarder_reference;
          })
          .indexOf(event.customsId);
        this.customsListData.splice(filterMimeType, 1, res.result[0]);
        setTimeout(() => {
          $('#row-' + event.customsId).addClass('active');
        }, 0);
      });
  }
  navigateToDocumentTab(data: any) {
    localStorage.setItem('selectedTab', 'document/customs');
    this.selectedListCustom(data.forwarder_reference);
  }

  selectedListCustom(customsId: any, actionFlag?: boolean) {
    this.appInsightsService.logEvent('Custom Action Icon Clicked', {
      'Shipment Id': customsId,
    });
    // if (actionFlag) {
    //   localStorage.setItem('selectedTab', 'Action')
    // }
    if (!this.customService.isActionFieldChanged) {
      $('#custom-sec-id > div').removeClass('active');
      this.isProgressShow = true;
      let accountId = this.currentSessionAccount.accountId;
      let emailId = this.currentSessionUser.emailAddress;
      let isGroup = this.currentSessionAccount.isGroup;
      this.customService
        .getCustomDetails(accountId, emailId, customsId, isGroup)
        .subscribe({
          next: (getDetails: any) => {
            this.isProgressShow = false;
            this.getCustomDetails = getDetails.result[0];
            if (
              this.dashboardWatchlist ||
              this.dashboardCustom ||
              this.globalSearch
            ) {
              this.selectedStatus(customsId, actionFlag);
            } else {
              this.popupToggle = true;
              $('#row-' + this.getCustomDetails.forwarder_reference).addClass(
                'active'
              );

              if (actionFlag) {
                localStorage.setItem('selectedTab', 'Action');
                this.customService.openActionTab = true;
                // this.openActionField();
              } else {
                this.customService.openActionTab = false;
              }
            }
          },
        });
      this.customService.switchTabInitiated = false;
    } else {
      this.customService.switchTabInitiated = true;
      this.lastSelectedCustomsId = customsId;
    }
  }

  featureHide() {
    this.showActionFieldCancelModal = false;
    this.popupToggle = false;
    $('#custom-sec-id > div').removeClass('active');
    this.customService.isActionFieldChanged = false;
    this.customService.switchTabInitiated = false;
  }

  selectedStatus(customsId: any, actionFlag: any) {
    if (actionFlag) {
      localStorage.setItem('selectedTab', 'Action');
    }
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      customsType: [],
      watchFilter: [],
      exceptionType: [],
      pgaFilter: [],
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },
      consignee: {
        selectedValue: [],
        selectedAccount: [],
      },
      importer: {
        selectedValue: [],
        selectedAccount: [],
      },
      importerOfRecord: {
        selectedValue: [],
        selectedAccount: [],
      },
      supplier: {
        selectedValue: [],
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { loadingPort: [] },
          { dischargePort: [] },
          { shipmentOrigin: [] },
          { shipmentDestination: [] },
          { portOfClearance: [] },
        ],
        selectedValue: [
          { loadingPortValue: [] },
          { dischargePortValue: [] },
          { shipmentOriginValue: [] },
          { shipmentDestinationValue: [] },
          { portOfClearanceValue: [] },
        ],
      },
      sorting: [],
      accountId: '',
      customsId: customsId,
      type: 'Single',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/customs/${encry}`])
    );

    window.open(url, '_blank');
  }

  share(event: any, customsId: any, type: any, currentShipment: any) {
    this.appInsightsService.logEvent('Custom Share Clicked', {
      'Shipment Id': customsId,
    });
    this.detailShipmentShare = true;
    this.popupToggleList = true;
    let accountId = '';
    let aliasName = '';
    let isGroup = false;
    let isParent = false;
    if (!this.currentSessionAccount) {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      accountId = decryUrlFilter.accountDetail.oH_Code;
      aliasName = decryUrlFilter.accountDetail.aliasName;
      isGroup = decryUrlFilter.accountDetail.byGroup;
      isParent = decryUrlFilter.accountDetail.isControllingCustomer;
    } else {
      accountId = this.currentSessionAccount.accountId;
      aliasName = this.currentSessionAccount.accountAliasName;
      isGroup = this.currentSessionAccount.isGroup;
      isParent = this.currentSessionAccount.isParent;
    }

    this.prepareUrlShare.emit({
      type: type,
      customsId: customsId,
      accountId: accountId,
      aliasName: aliasName,
      popUp: true,
      isGroup: isGroup,
      shipment: currentShipment,
      isParent: isParent,
    });
  }

  detailShare(event: any) {
    this.detailShipmentShare = true;
    this.prepareUrlShare.emit(event);
    if (event.popUp) {
      this.popupToggleList = true;
      this.detailSharePopup = event.popUp;
    }
  }

  sharePopUpfeatureHide() {
    this.singleCustomShare = false;
    this.detailShipmentShare = false;
    this.popupToggleList = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  onRefreshDetailFromDetail(event: any) {
    this.isProgressShow = true;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (event.value == 'LOAD DETAILS') {
      let accountId = this.currentSessionAccount.accountId;
      let emailId = this.currentSessionUser.emailAddress;
      let isGroup = this.currentSessionAccount.isGroup;
      this.customService
        .getCustomDetails(accountId, emailId, event.shipmentId, isGroup)
        .subscribe({
          next: (getDetails: any) => {
            this.isProgressShow = false;
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
            this.getCustomDetails = getDetails.result[0];
          },
        });
    }
  }

  calculateDiff(demandDate: any) {
    if (demandDate) {
      let currentDate = new Date();
      let dateSent = new Date(demandDate);
      let hours = Math.floor(
        (Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate(),
            dateSent.getHours(),
            dateSent.getMinutes(),
            dateSent.getSeconds()
          )) /
        (1000 * 60 * 60)
      );
      return hours <= 24 ? true : false;
    } else {
      return false;
    }
  }

  hideEdocUploadPopup() {
    this.edocUploadTigger = false;
  }

  /* istanbul ignore next */
  exitEdocPopup() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      this.edocUploadTigger = false;
    } else {
      this.dataSharingService.eDocUploadCancelTrigger.next(
        this.edocUploadStatusService.fileUploadStatus_LocalStorage()
      );
      this.edocUploadTigger = false;
    }
  }

  updateShipement(shipmentId: any, eDocImport: boolean) {
    this.appInsightsService.logEvent(
      eDocImport
        ? 'Customs Update with Documents Clicked'
        : 'Customs Update Clicked',
      {
        'Shipment Id': shipmentId,
      }
    );
    let params = {
      shipment: shipmentId,
      isCustoms: true,
      accountId: this.currentSessionAccount.accountId,
      eDocImport: eDocImport,
      importUserDetails: [
        {
          userTriggerred: this.currentSessionUser.emailAddress,
        },
      ],
    };
    this.customService.updateShipment(params).subscribe((res) => {
      if (res.statusCode == 'OC200') {
        this.fetchSingleShipment(shipmentId);
      }
    });
  }

  // getTrimmedAddressData(city: any, state: any, country: any) {
  //   let message = '';
  //   if (city) message += CommonUtils.toTitleCase(city) + ', ';
  //   message += CommonUtils.getUSCanadaStateText(country, state);
  //   if (country) message += country + ', ';
  //   return CommonUtils.removeLastComma(message);
  // }

  //TODO:
  openActionField() {
    setTimeout(() => {
      $('#action-tab').tab('show');
    }, 20);
  }

  loadCustomListById(id: any) {
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: id,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.customService
      .getAllCustoms(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        this.replaceCustomListValue(id, res.result[0]);
      });
  }

  replaceCustomListValue(id: any, newItem: any) {
    let index = this.customsListData.findIndex((e: any) => e.id === id);
    this.customsListData.splice(index, 1, newItem);
    setTimeout(() => {
      $('#row-' + id).addClass('active');
    }, 100);
  }

  trackById(index: number, item: any) {
    return item.forwarder_reference;
  }
}
