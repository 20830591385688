<div class="col-12 contain-top faq-list-set">
  <div *ngIf="linkCopied" class="newuser-toast-bg link-copied-faq lnk-toast">
    <div class="contain-bg">
      Link copied to clipboard!
      <span (click)="closeCopyText()" class="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231">
          <g data-name="Group 2379">
            <path data-name="Path 6681"
              d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
              transform="translate(.288 .287)" style="fill: #01457c; stroke: #01457c; stroke-width: 0.5px" />
          </g>
        </svg>
      </span>
    </div>
  </div>
  <div *ngIf="publicPage && !linkCopied" class="newuser-toast-bg link-copied-faq logn-toast">
    <div (click)="redirectToLogin()" class="contain-bg">
      <div class="inner-toast-msg">
        This is a public page. To access the application, please login here.
        <span (click)="closeToast()" class="close-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231">
            <g data-name="Group 2379">
              <path data-name="Path 6681"
                d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
                transform="translate(.288 .287)" style="fill: #01457c; stroke: #01457c; stroke-width: 0.5px" />
            </g>
          </svg>
        </span>
      </div>
    </div>
  </div>

  <div class="pb-5 mb-3 pr-4 pl-4">
    <div class="row faq-search-ctr">
      <div class="col-lg-4 col-md-12 title-set">
        <h2 class="mt-3">Frequently Asked Questions</h2>
      </div>
      <div class="col-lg-8 col-md-12 fix-middle">
        <div class="shipment-search-wdth">
          <div id="shipmentSearchBox" class="d-flex position-relative">
            <input type="text" id="faq-inp" [(ngModel)]="searchedText" (keyup.backspace)="reloadPage()"
              (keyup)="arraySearch(searchedText)" placeholder="Search by keyword"
              class="form-control search-input ng-pristine ng-valid ng-touched desktop-set" />
            <span class="form-control-feedback search-icon">
              <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-2"
                *ngIf="searchedText.length >0" (click)="removeSet()">
            </span>
          </div>
        </div>
        <span *ngIf="resultNotFound" class="results_found error_result">0 results found</span>
        <span [ngClass]="{'remove-search' : resultCount > 0}" class="results_found next-preview">
          <span class="button-set" tabindex="1" (keyup.arrowup)="previous()" (click)="previous()">
            <span class="ngb-dp-navigation-chevron"></span>
          </span>
           <span class="text-update">
              <span class="set-number"><ng-container *ngIf="item + 1 <= 9">0</ng-container>{{ item + 1 }}</span> 
              <span class="set-number">&nbsp;of&nbsp;</span> 
              <span class="set-number"><ng-container *ngIf="resultCount <= 9">0</ng-container>{{ resultCount }}</span>
              <span>&nbsp;results found</span>
          </span> 
          <span class="button-set" tabindex="2"  (keyup.arrowdown)="next()" (click)="next()">
            <span class="ngb-dp-navigation-chevron ngb-dp-right-arrow"></span>
          </span>
        </span>
        <div class="col_exp_ctr">
          <span class="collapse_all" (click)="collapseAll()"><img src="../../../assets/img/collapse.svg"
              alt="collapse_icon" />
            <span>Collapse <i class="all-remove">All</i></span></span>
          <span class="expand_all" (click)="expandAll()"><img src="../../../assets/img/expand.svg" alt="expand_icon" />
            <span>Expand <i class="all-remove">All</i></span></span>
        </div>
      </div>
    </div>
    <div class="row faq-accordion-ctr" id="faqHigh">
      <div class="col-12 change-set">
        <div class="scroll-update">
          <div class="accordion" id="faq">
            <div class="card" id="card{{ faq.id }}" *ngFor="let faq of faqs; let i = index">
              <div class="card-header" id="faqId{{ faq.id }}" data-toggle="collapse"
                [attr.data-target]="'#body' + faq.id" aria-expanded="true" [attr.aria-controls]="'#body' + faq.id"
                (click)="
                  fnScrollTop('card' + faq.id); addClassFor('#card' + faq.id)
                ">
                <a class="btn btn-header-link collapsed" [innerHTML]="faq.id + '. ' + faq.question | safe: 'html'"></a>
              </div>
              <div id="body{{ faq.id }}" class="collapse add-padding-set" aria-labelledby="faqhead1">
                <div class="add-p-set">
                  <ng-container *ngFor="let faqAns of faq.answer">
                    <div class="card-body" [ngClass]="faqAns.classPo" *ngIf="faqAns.point"
                      [innerHTML]="faqAns.point | safe: 'html'">
                    </div>
                    <div *ngIf="faqAns.image">
                      <img [class]="faqAns.classImg" [src]="faqAns.image" alt="Search" />
                    </div>
                  </ng-container>
                  <div>
                    <button type="button" class="copy_share_link" (click)="copyFaqUrl(faq.path)">
                      <img alt="copy link icon" src="assets/img/copylink.svg" />Copy FAQ Share Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>