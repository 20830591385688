import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

declare var $: any;
@Component({
  selector: 'app-common-dropdown-filters',
  templateUrl: './common-dropdown-filters.component.html',
  styleUrls: ['./common-dropdown-filters.component.scss'],
})
export class CommonDropdownFiltersComponent implements OnInit, OnChanges {
  @Input()
  filterType: any;

  @Output()
  selectedFiltersArray = new EventEmitter();

  @Output()
  closeSelectedFilterControl = new EventEmitter();

  combinedFilters: any[] = [];
  statusFilterArray: any[] = [];
  statusFilterString: string = '';
  statusFilterArrayApi: any[] = [];
  statusSelectedText: string = '';
  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  clickDropdownFilter = new Subject();
  private __ngSimpleChanges__: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
  }

  constructor(private appInsightsService: AppInsightsService) { }

  ngOnInit(): void {
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
    let root = this;

    $(document).ready(function () {
      if (root.filterType.autoOpen) {
        let filterArray = root?.__ngSimpleChanges__?.previous?.filterType?.currentValue?.filterArray;
        let executeOnclick = true;
        for (var i = 0; i < filterArray?.length; i++) {
          if (filterArray[i]?.checked) {
            executeOnclick = false;
            break;
          }
        }
        if (executeOnclick) {
          $('#button-' + root.filterType.type).click();
        }
      }
      $('.dropdown-comp').on('click', function (e: any) {
        e.stopPropagation();
      });
    });
    this.clickDropdownFilter.pipe(debounceTime(1000)).subscribe((e: any) => {
      this.isStatusSelected(e.filterDisplayName, e.type);
    });
  }

  ngOnChanges(): void {
    $('button').click(function (e: any) {
      $('.filterRange-wrap').hide();
      $('.accountStatusFilterDropdown').hide();
    });
    if (Object.keys(this.filterType).length > 0) {
      if (this.filterType.filterDisplayName.includes(':')) {
        this.statusSelectedText = this.filterType.filterDisplayName;
      } else {
        this.statusSelectedText = this.filterType.filterDisplayName + ': ';
      }
    }
  }

  isStatusSelected(filterTitle: any, filterType: any) {
    filterTitle = filterTitle.split(':')[0];
    this.statusSelectedText = '';
    this.filterType.filterArray
      .filter((t: any) => t.checked)
      .map((t: any) => (this.statusSelectedText += t.filt + ', '));

    this.statusFilterArray = [];
    this.statusFilterString = '';
    let statusNames = '';
    this.filterType.filterArray.forEach((element: any) => {
      if (element.checked) {
        statusNames += element.filt + ', ';
        this.statusFilterArray.push(element.val);
        this.statusFilterString = this.statusFilterArray
          .map((x) => x)
          .join(',');
      }
    });

    statusNames = CommonUtils.removeLastComma(statusNames);

    this.trackAITransportOrStatusFilterAppliedEvent(filterType, statusNames);

    if (this.statusSelectedText.trim().length > 0) {
      this.statusSelectedText = this.statusSelectedText.replace(/,\s*$/, '');
    }
    if (this.statusSelectedText?.length > 5) {
      this.statusSelectedText =
        filterTitle + ': ' + this.statusSelectedText?.substring(0, 5) + '...';
    } else {
      this.statusSelectedText = filterTitle + ': ' + this.statusSelectedText;
    }
    if (this.isMobileView && this.statusFilterArray.length < 1) {
      this.clearFilters(filterType, this.filterType.index, this.filterType.id);
    } else if (this.isMobileView && this.statusFilterArray.length > 0) {
      this.statusDataFiltering(filterType, this.statusFilterString);
    } else if (!this.isMobileView) {
      this.statusDataFiltering(filterType, this.statusFilterString);
    }
  }

  statusDataFiltering(columnName: any, filterArrayString: any) {
    this.statusFilterArrayApi = [];

    this.statusFilterArrayApi.push({
      column_name: columnName,
      operator: '=',
      value: filterArrayString,
    });
    if (filterArrayString) {
      this.selectedFiltersArray.emit({
        type: columnName,
        selectedValue: this.statusFilterArrayApi,
        clearFrom: '',
      });
    } else {
      this.selectedFiltersArray.emit({
        type: columnName,
        selectedValue: [],
        clearFrom: '',
      });
    }
  }

  clearFilters(filterType: any, filterIndex: any, filterId: any) {
    this.trackAITransportOrStatueFilterClearedEvent(filterType);

    this.closeSelectedFilterControl.emit({
      type: filterType,
      index: filterIndex,
      id: filterId,
      eventType: 'closeBtn',
      closeFrom: '',
    });
  }

  trackAITransportOrStatueFilterClearedEvent(filterType: any) {
    let params = { [AI_CustomProps.ModuleName]: AI_ModulesName.Booking };
    if (filterType == 'transport') {
      this.appInsightsService.logEvent(
        AI_CommonEvents.TransportFilterCleared.toString(),
        params
      );
    }
    if (filterType == 'status') {
      this.appInsightsService.logEvent(
        AI_CommonEvents.StatusFilterCleared.toString(),
        params
      );
    }
  }

  trackAITransportOrStatusFilterAppliedEvent(
    filterType: any,
    statusNames: any
  ) {
    let params = {
      [AI_CustomProps.TransportTypes]: statusNames,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    };
    if (filterType == 'transport') {
      this.appInsightsService.logEvent(
        AI_CommonEvents.TransportFilterApplied.toString(),
        params
      );
    }
    if (filterType == 'status') {
      this.appInsightsService.logEvent(
        AI_CommonEvents.StatusFilterApplied.toString(),
        params
      );
    }
  }
}
