<div *ngIf="(hideUploadButton | async).length > 0 " [ngClass]="{'upload-container-added': hideUploadSec}"
    class="upload-files-ctr-overall">
    <div class="text-right upload-btn-ctr">
        <button type="button" class="upload-btn" (click)="openDocUpload()">
            <img alt="upload icon" src="../../../assets/img/upload_icon.svg" />Upload Document
        </button>
    </div>
    <div *ngIf="hideUploadSec" class="upload-files-ctr">
        <div class="container mb-5 pb-3 upload-wrapper upload-doc-ctr">
            <h2>Upload Document</h2>
            <div *ngIf="showErrorMsg" class="error-msg">{{showErrorMsg}}</div>
            <label class="doc-cat-lbl">Document Category <span class="red">*</span></label>
            <span class="refresh_section">
                <img [ngClass]="{'refresh-icon': autoCompleteInProgressRef}" alt="upload icon"
                    src="../../../assets/img/refresh_icon.svg" />
                <a *ngIf="!autoCompleteInProgressRef" href="javascript:void(0);" (click)="refershEdocList()">Update
                    Document Categories</a>
                <a *ngIf="autoCompleteInProgressRef" class="refresh-icon-text" href="javascript:void(0);">Updating Document
                    Categories</a>
            </span>
            <div _ngcontent-gsp-c197="" class="btn-group dropdown">
                <div class="ng-autocomplete col-lg-12 pl-0">
                    <ng-autocomplete [data]="edocTypeAutoComplete" [searchKeyword]="keyword"
                        placeholder="Search Category" [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate" [(ngModel)]="searchFilter"
                        (selected)="selectEvent($event)" (inputChanged)="autoCompleteOnKeyUp(searchFilter)"
                        [debounceTime]="600" class="custom-style" id="accountAutoComplete" ngDefaultControl>
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a href="javascript:void(0)">
                            <span [innerHTML]="item.codeWithDesc"></span>
                        </a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                        <div *ngIf="searchFilter.length > 3 && !autoCompleteInProgress" [innerHTML]="notFound"></div>
                    </ng-template>

                </div>
            </div>

            <div *ngIf="fileNameArray.length == 0" class="upload-document">
                <label class="doc-cat-lbl">File Upload <span class="red">*</span></label>
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
                <img alt="upload icon" src="../../../assets/img/upload_icon.svg" class="upload-icon" />
                <h3>Drag and Drop File to Upload</h3>
                <p class="or">or</p>
                <label for="fileDropRef" class="choose-file-btn">Choose File</label>
            </div>
            <div *ngIf="fileNameArray.length>0" class="upload-document upload-browse">
                <label class="doc-cat-lbl">File Upload <span class="red">*</span></label>
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
                <img alt="upload icon" src="../../../assets/img/upload_icon.svg" class="upload-icon" />
                <div class="mble-file-ctr">
                    <label for="fileDropRef" class="choose-file-btn">Choose File</label>
                    <h3>
                        <ul>
                            <li *ngFor="let item of fileNameArray ; let indexOfelement = index">{{item}}<span
                                    (click)="closeList(indexOfelement)" class="close-upload-files">
                                    <img alt="close icon" src="../../assets/img/close_whitebg_icon.svg" alt="" />
                                </span></li>
                        </ul>
                    </h3>
                </div>
                <p class="or">Drag and Drop to upload an additional file or <label for="fileDropRef"
                        class="choose-file-btn">browse</label></p>
            </div>

            <button type="button" class="btn btn-yellow upload-doc-btn" (click)="uploadDocuments()">
                UPLOAD DOCUMENT
            </button>
            <button type="button" class="btn btn-yellow btn-cancel upload-doc-btn" (click)="closeFileUploadSec()">
                CANCEL
            </button>
        </div>


    </div>

    <div class="files-list" *ngFor="let item of testEmitter | async ; let i = index">
        <div class="single-file">
            <span *ngIf="item?.Error && item?.Percentage != '100' && item?.fileId != retryMsgIndex" class="ticki-icon i-icon"><img
                    alt="info icon" src="../../../assets/img/i-icon.svg" /></span>
            <span *ngIf="item?.Percentage == '100'" class="ticki-icon"><img alt="tick icon"
                    src="../../../assets/img/tick-icon.svg" /></span>
            <div class="file-icon">
                <img alt="document icon" src="../../../../../assets/img/doc-icon.svg" class="doc-icon" />
            </div>
            <div class="info">
                <h4 class="name">
                    {{ item?.filename}}
                </h4>
                <p class="file_details">{{item?.eDocTypes}}</p>
                <span class="name-error-msg"
                    *ngIf="item?.Error && item?.Percentage != '100' && item?.fileId != retryMsgIndex">{{item?.Error}}<span
                        (click)="retryFileUpload(item?.fileId,i)" class="text-under-line"
                        *ngIf="item?.Error == 'Unable to upload due to network error.' && item?.Percentage != '100' && item?.fileId != retryMsgIndex">
                        Click here to retry upload.
                    </span></span>
                <div class="progress-cont-ctr">
                    <div class="progress-cont">
                        <div class="progress" [style.width]="item?.Percentage + '%'">
                        </div>
                    </div>
                    <span class="progress-txt">{{item?.completedSize}}</span>
                </div>
            </div>
        </div>
    </div>
</div>