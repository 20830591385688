import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './containers/home.component';
import { HomeRoutingModule } from './home.routing.module'
import { SessionStorageService } from '../../shared/services/session-storage.service'

@NgModule({
  imports: [CommonModule,
    HomeRoutingModule,
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent],
  providers: [SessionStorageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeModule { }
