<div class="shipment-detail" id="shipmentDetailTab">
  <ng-container *ngIf="closeIntransitToast">
    <div *ngIf="(selectedShipment?.status | exceptionClass : selectedShipment?.exception) == 'orange' && (changeLogCategory | async).length > 0
      " class="newuser-toast-new">
      <div class="text-center contain-bg  {{selectedShipment?.status | exceptionClass : selectedShipment?.exception}}">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.864" viewBox="0 0 20 19.864"
          style="margin-right: 8px; vertical-align: middle; margin-bottom: 2px">
          <g data-name="noun_Check Mark_415271">
            <path data-name="Path 38074"
              d="M10.265 17.393A7.2 7.2 0 1 0 3 10.2a7.239 7.239 0 0 0 7.265 7.193zM6.607 9.876A1 1 0 0 1 8 10.008L9.326 11.6 12.3 6.711a1 1 0 0 1 1.36-.334.977.977 0 0 1 .34 1.347L10.3 13.8a.992.992 0 0 1-.775.472h-.073a.993.993 0 0 1-.763-.357l-2.214-2.657a.974.974 0 0 1 .132-1.382z"
              transform="translate(-.265 -.265)" style="fill: none" />
            <path data-name="Path 38076"
              d="M10 19.864A9.932 9.932 0 1 0 0 9.932a9.966 9.966 0 0 0 10 9.932zm0-17.736a7.8 7.8 0 1 1-7.857 7.8A7.84 7.84 0 0 1 10 2.128z"
              class="color-change" />
            <path data-name="Path 38111"
              d="M42.065 16.148a1.057 1.057 0 1 1-2.063 0 1.057 1.057 0 1 1 2.063 0zM41.034 5a1.024 1.024 0 0 0-.806.429 1.6 1.6 0 0 0-.309 1.023l.2 5.979a.926.926 0 1 0 1.822 0l.2-5.979a1.6 1.6 0 0 0-.309-1.023A1.024 1.024 0 0 0 41.034 5z"
              transform="translate(-31.256 -1.419)" class="color-change" />
          </g>
        </svg>
        <span *ngIf="(selectedShipment?.status | exceptionClass : selectedShipment?.exception) == 'orange'
          " class="text-set">To view estimated date changes,
          <a (click)="loadLogsTab()">click the Logs tab for more details.</a></span>
        <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231" class="close"
          (click)="closeIntransitToastFun()">
          <g data-name="Group 2379">
            <path data-name="Path 6681"
              d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
              transform="translate(.288 .287)" style="stroke-width: 0.5px" class="color-change" />
          </g>
        </svg>
      </div>
    </div>
    <div *ngIf="(selectedShipment?.status | exceptionClass : selectedShipment?.exception) == 'red'"
      class="newuser-toast-new">
      <div class="text-center contain-bg  {{selectedShipment?.status | exceptionClass : selectedShipment?.exception}}">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.864" viewBox="0 0 20 19.864"
          style="margin-right: 8px; vertical-align: middle; margin-bottom: 2px">
          <g data-name="noun_Check Mark_415271">
            <path data-name="Path 38074"
              d="M10.265 17.393A7.2 7.2 0 1 0 3 10.2a7.239 7.239 0 0 0 7.265 7.193zM6.607 9.876A1 1 0 0 1 8 10.008L9.326 11.6 12.3 6.711a1 1 0 0 1 1.36-.334.977.977 0 0 1 .34 1.347L10.3 13.8a.992.992 0 0 1-.775.472h-.073a.993.993 0 0 1-.763-.357l-2.214-2.657a.974.974 0 0 1 .132-1.382z"
              transform="translate(-.265 -.265)" style="fill: none" />
            <path data-name="Path 38076"
              d="M10 19.864A9.932 9.932 0 1 0 0 9.932a9.966 9.966 0 0 0 10 9.932zm0-17.736a7.8 7.8 0 1 1-7.857 7.8A7.84 7.84 0 0 1 10 2.128z"
              class="color-change" />
            <path data-name="Path 38111"
              d="M42.065 16.148a1.057 1.057 0 1 1-2.063 0 1.057 1.057 0 1 1 2.063 0zM41.034 5a1.024 1.024 0 0 0-.806.429 1.6 1.6 0 0 0-.309 1.023l.2 5.979a.926.926 0 1 0 1.822 0l.2-5.979a1.6 1.6 0 0 0-.309-1.023A1.024 1.024 0 0 0 41.034 5z"
              transform="translate(-31.256 -1.419)" class="color-change" />
          </g>
        </svg>
        <span *ngIf="(selectedShipment?.status | exceptionClass : selectedShipment?.exception) == 'red'
          " class="text-set">Please note actual dates are later than the estimates.</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231" class="close"
          (click)="closeIntransitToastFun()">
          <g data-name="Group 2379">
            <path data-name="Path 6681"
              d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
              transform="translate(.288 .287)" style="stroke-width: 0.5px" class="color-change" />
          </g>
        </svg>
      </div>
    </div>
  </ng-container>
  <h3 class="update-sec">SHIPMENT PROGRESS</h3>
  <div class="full-rap-set">
    <div class="full-src">
      <span [innerHTML]="selectedShipment | shipmentDetailProgressBar"></span>
    </div>
    <div class="full-src setup-top" *ngIf="selectedShipment?.isFullViewAccess">
      <h3 class="update-sec pt-0">ADDITIONAL DETAILS</h3>
      <div class="logs">
        <div id="sectionmilestones" class="card change-icon add-border"
          *ngIf="selectedShipment?.milestones?.length > 0">
          <div class="card-header" (click)="addClassFor('#sectionmilestones')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne" class="btn btn-link">
                <a class="btn-header-link">Milestones</a>
              </button>
            </h5>
          </div>
          <div id="collapseOne" aria-labelledby="sectionmilestones" class="collapse show">
            <div class="card-body container-color-bg">
              <div class="mobile-view row">
                <table class="table table-sortable document-sec mb-0" aria-labelledby="document-table"
                  id="account-table">
                  <thead class="border-0">
                    <tr style="height: 46px">
                      <th [appSort]="selectedShipment?.milestones" id="event_code" class="desc sort fix-w"
                        data-order="asc" data-name="event_code" data-parent="#account-table">
                        Event Code
                      </th>
                      <th [appSort]="selectedShipment?.milestones" id="event_name" class="asc sort" data-order="asc"
                        data-name="event_name" data-parent="#account-table">
                        Event Description
                      </th>
                      <th [appSort]="selectedShipment?.milestones" id="estimated_date" class="asc sort fix-w"
                        data-order="asc" data-name="estimated_date" data-parent="#account-table">
                        Estimated Date
                      </th>
                      <th [appSort]="selectedShipment?.milestones" id="actual_date" class="desc factive sort fix-w"
                        data-order="asc" data-name="actual_date" data-parent="#account-table">
                        Actual Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="height: 46px" *ngFor="
                        let file of selectedShipment?.milestones;
                        let i = index
                      ">
                      <td [title]="file?.event_code">
                        <span class="add-middle-dots">{{
                          file.event_code
                          }}</span>
                      </td>
                      <td [title]="file?.event_name">
                        <span class="trim-set-mile"><span>{{ file.event_name }}</span></span>
                      </td>
                      <td>
                        {{ file.estimated_date | dateFormatPipe : "MMMDDYYYY" }}
                      </td>
                      <td>
                        {{ file.actual_date | dateFormatPipe : "MMMDDYYYY" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="shipmentdetailstab" class="card change-icon add-border" *ngIf="selectedShipment?.isFullViewAccess">
          <div class="card-header" (click)="addClassFor('#shipmentdetailstab')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#shipmentcollapse" aria-expanded="true"
                aria-controls="shipmentcollapse" class="btn btn-link">
                <a class="btn-header-link">Shipment Details</a>
              </button>
            </h5>
          </div>
          <div id="shipmentcollapse" aria-labelledby="shipmentdetailstab" class="collapse show">
            <div class="card-body container-color-bg">
              <div class="mobile-view row">
                <div class="col-12 mb-2">
                  <h5>Goods Description</h5>
                  <p>
                    {{
                    selectedShipment?.description_of_goods
                    ? selectedShipment?.description_of_goods.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-12 mob-top" style="padding-bottom: 20px">
                  <h5 class="mt-1">Marks & Numbers</h5>

                  <p class="inline-set" *ngIf="selectedShipment?.marks_and_numbers">
                    <span [innerText]="
                        readMoreMarksNumbers
                          ? (selectedShipment?.marks_and_numbers
                            | slice : 0 : 60)
                          : selectedShipment?.marks_and_numbers
                      "></span>
                  </p>
                  <p class="displayset set-top" *ngIf="selectedShipment?.marks_and_numbers">
                    <span *ngIf="readMoreMarksNumbers"> ...</span>
                    <a href="javascript:;" *ngIf="readMoreMarksNumbers" (click)="readMoreMarksNumbers = false">More</a>
                  </p>
                  <p *ngIf="!selectedShipment?.marks_and_numbers">---</p>
                </div>
                <div class="col-sm-6 col-md-3 mt-2 line-set mb-3 col-6 mt-2">
                  <h5>Gross Weight</h5>
                  <p *ngIf="
                      selectedShipment?.gross_weight_kgs &&
                      selectedShipment?.gross_weight_kgs > 0
                    ">
                    {{ selectedShipment?.gross_weight_kgs }}&nbsp;KGS
                  </p>
                  <p *ngIf="
                      (!selectedShipment?.gross_weight_kgs ||
                        selectedShipment?.gross_weight_kgs <= 0) &&
                      selectedShipment?.gross_weight_lbs &&
                      selectedShipment?.gross_weight_lbs > 0
                    ">
                    {{ selectedShipment?.gross_weight_lbs }}&nbsp;LBS
                  </p>
                  <p *ngIf="
                      ((!selectedShipment?.gross_weight_kgs ||
                        selectedShipment?.gross_weight_kgs <= 0) &&
                        !selectedShipment?.gross_weight_lbs) ||
                      selectedShipment?.gross_weight_lbs <= 0
                    ">
                    ---
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6">
                  <h5>Chargeable Weight</h5>
                  <p>
                    {{
                    selectedShipment?.chargeable_weight
                    ? selectedShipment?.chargeable_weight
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6">
                  <h5>Volume</h5>
                  <p *ngIf="
                      selectedShipment?.volume_cbms &&
                      selectedShipment?.volume_cbms >= 0
                    ">
                    {{ selectedShipment?.volume_cbms }}&nbsp;M<sup>3</sup>
                  </p>
                  <p *ngIf="
                      (!selectedShipment?.volume_cbms ||
                        selectedShipment?.volume_cbms < 0) &&
                      selectedShipment?.volume_cf &&
                      selectedShipment?.volume_cf >= 0
                    ">
                    {{ selectedShipment?.volume_cf }}&nbsp;CF
                  </p>
                  <p *ngIf="
                      (!selectedShipment?.volume_cbms ||
                        selectedShipment?.volume_cbms < 0) &&
                      (!selectedShipment?.volume_cf ||
                        selectedShipment?.volume_cf < 0)
                    ">
                    ---
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6">
                  <h5>Packs</h5>
                  <p>
                    {{
                    selectedShipment?.number_of_outer_packages
                    ? selectedShipment?.number_of_outer_packages?.toUpperCase() +
                    " "
                    : "--- "
                    }}
                    {{ selectedShipment?.outer_pack_package_type_code }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set mb-3 col-6 mob-top">
                  <h5>Incoterm</h5>
                  <p>
                    {{
                    selectedShipment?.inco_terms_description
                    ? selectedShipment?.inco_terms_description?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6 mob-top">
                  <h5>Release Type</h5>
                  <p>
                    {{
                    selectedShipment?.release_type_description
                    ? selectedShipment?.release_type_description?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6" *ngIf="selectedShipment?.isMRNAccount">
                  <h5>MRN Type</h5>
                  <p>
                    {{
                    selectedShipment?.customs_declarations &&
                    selectedShipment.customs_declarations[0]?.declaration_type
                    ? selectedShipment.customs_declarations[0]
                    .declaration_type
                    : "--- "
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6" *ngIf="selectedShipment?.isMRNAccount">
                  <h5>MRN Number</h5>
                  <p>
                    {{
                    selectedShipment?.customs_declarations &&
                    selectedShipment.customs_declarations[0]
                    ?.declaration_number
                    ? selectedShipment.customs_declarations[0]
                    .declaration_number
                    : "--- "
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6" *ngIf="selectedShipment?.isMRNAccount">
                  <h5>MRN Issue Date</h5>
                  <p>
                    {{
                    selectedShipment?.customs_declarations &&
                    selectedShipment?.customs_declarations[0]
                    ?.declaration_date
                    ? (selectedShipment.customs_declarations[0]
                    .declaration_date | dateFormatPipe : "MMMDDYYYY")
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 line-set col-6" *ngIf="selectedShipment?.isMRNAccount">
                  <h5>MRN Expiry Date</h5>
                  <p>
                    {{
                    selectedShipment?.customs_declarations &&
                    selectedShipment?.customs_declarations[0]?.expiry_date
                    ? (selectedShipment.customs_declarations[0].expiry_date
                    | dateFormatPipe : "MMMDDYYYY")
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set"></div>
                <div class="col-sm-6 col-md-3 line-set"></div>
                <div class="col-12 mb-2 new-pipe-design" *ngIf="showCustomField" [innerHTML]="
                    customFieldList | customFieldRenderPipe : 'Shipment'
                  "></div>
              </div>
            </div>
          </div>
        </div>
        <div id="oiaglobal" class="card change-icon add-border" *ngIf="selectedShipment.isFullViewAccess">
          <div class="card-header" (click)="addClassFor('#oiaglobal')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#oiaglobalcollapse" aria-expanded="true"
                aria-controls="oiaglobalcollapse" class="btn btn-link">
                <a class="btn-header-link">OIA Global & Carrier</a>
              </button>
            </h5>
          </div>
          <div id="oiaglobalcollapse" aria-labelledby="oiaglobal" class="collapse show">
            <div class="card-body container-color-bg">
              <div class="mobile-view row">
                <div class="col-md-4 col-6">
                  <h5>OIA Consol Reference</h5>
                  <p>
                    {{
                    selectedShipment?.consolidation_forwarder_reference
                    ? selectedShipment?.consolidation_forwarder_reference?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-md-4 col-6">
                  <h5>Carrier</h5>
                  <p>
                    {{
                    selectedShipment?.carrier?.name
                    ? selectedShipment?.carrier?.name?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <h5>Carrier Booking Reference</h5>
                  <p>
                    {{
                    selectedShipment?.carrier_booking_number
                    ? selectedShipment?.carrier_booking_number?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-md-4 col-6 mb-3">
                  <h5>House Bill of Lading</h5>
                  <p>
                    {{
                    selectedShipment?.house_bill
                    ? selectedShipment?.house_bill?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-md-4 col-12">
                  <h5>Master Bill of Lading</h5>
                  <p>
                    {{
                    selectedShipment?.master_bill
                    ? selectedShipment?.master_bill?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="notes" class="card change-icon add-border" *ngIf="displayNotes && selectedShipment.isFullViewAccess">
          <div class="card-header" (click)="addClassFor('#notes')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#notescollapse" aria-expanded="true"
                aria-controls="oiaglobalcollapse" class="btn btn-link">
                <a class="btn-header-link">Notes</a>
              </button>
            </h5>
          </div>
          <div id="notescollapse" aria-labelledby="notes" class="collapse show">
            <div class="card-body container-color-bg">
              <div class="row">
                <div class="col-md-12 pb-2">
                  <p class="shipNoteFontNormal" [innerText]="
                      readMoreNotes
                        ? (displayNotes | slice : 0 : 180)
                        : displayNotes
                    "></p>
                  <P class="displayset">
                    <span *ngIf="readMoreNotes"> ...</span>
                    <a href="javascript:;" *ngIf="readMoreNotes" (click)="readMoreNotes = false">More</a>
                  </P>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="shipmentRefSec" class="card change-icon add-border" *ngIf="
            (selectedShipment?.purchase_orders?.length > 0 ||
              doAdditionalReferencesExist(selectedShipment?.native_reference_numbers)) &&
            selectedShipment?.isFullViewAccess
          ">
          <div class="card-header" (click)="addClassFor('#shipmentRefSec')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#shipmentRefSeccollapse"
                aria-expanded="true" aria-controls="shipmentRefSeccollapse" class="btn btn-link">
                <a class="btn-header-link">References</a>
              </button>
            </h5>
          </div>
          <div id="shipmentRefSeccollapse" aria-labelledby="shipmentRefSec" class="collapse show">
            <div class="card-body container-color-bg">
              <div class="row">
                <div class="col-md-12 pb-3" *ngIf="
                    selectedShipment?.purchase_orders &&
                    selectedShipment?.purchase_orders?.length > 0
                  ">
                  <h5 class="displayset d-block">Job References</h5>
                  <p class="displayset"
                    [innerHTML]="selectedShipment?.purchase_orders | shipmentReferenceNumberPipe : 0">
                  </p>
                </div>
                <div class="col-md-12 container-color-bg-ctrl" *ngIf="
                    selectedShipment?.native_reference_numbers &&
                    selectedShipment?.native_reference_numbers?.length > 0
                  ">
                  <span [innerHTML]="
                      selectedShipment?.native_reference_numbers
                        | additionalReferenceRenderPipe
                    "></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>