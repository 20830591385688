import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { LocalStorageService } from './services/local-storage.service';
import { FormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InternationalizationModule } from './shared/Internationalization/internationalization.module';
import { HeaderModule } from './features/header/header.module';
import { HomeModule } from './features/home/home.module';
import { SharedModule } from './shared/shared.module';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
} from '@azure/msal-angular';

/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
import { RouteGuard } from './shared/guards/route.guard';
import { GlobalSearchComponent } from './features/global-search/comtainer/global-search/global-search.component';
import { TokenInterceptorService } from './shared/interceptors/token-interceptor.service';
import {
  PublicClientApplication,
  InteractionType,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { FaqListComponent } from './features/faq-list/faq-list.component';
import { msalConfig } from './auth-config';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { DataSharingService } from './shared/services/data-sharing.service';
import { UserManagmentService } from './features/admin/users/services/usermanagment.service';
import { AppInsightsService } from './services/appinsights.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { EditorPocComponent } from './features/editor-poc/editor-poc.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MentionModule } from 'angular-mentions';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PublicSearchComponent } from './features/public-search/public-search.component';
import { ShipmentModule } from './features/shipments/shipment.module';
import { BookingModule } from './features/booking/booking.module';
import { CustomsModule } from './features/customs/customs.module';
import { ShipmentServies } from './features/shipments/services/shipment.service';
import { ShipmentNotFoundPageComponent } from './features/public-search/shipment-not-found-page/shipment-not-found-page.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { CacheInterceptorService } from './shared/interceptors/cache-handling.interceptor';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}
@NgModule({
  declarations: [
    AppComponent,
    GlobalSearchComponent,
    FaqListComponent,
    EditorPocComponent,
    PublicSearchComponent,
    ShipmentNotFoundPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HomeModule,
    BrowserAnimationsModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    SharedModule,
    HeaderModule,
    SharedModule,
    SlickCarouselModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('access_token'),
      },
    }),
    CKEditorModule,
    MentionModule,
    ShipmentModule,
    BookingModule,
    CustomsModule,
    RecaptchaV3Module,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    RouteGuard,
    LocalStorageService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },

    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    MsalService,
    MsalBroadcastService,
    DataSharingService,
    UserManagmentService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: Window,
      useValue: window,
    },
    ShipmentServies,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
