<div class=" dropdown close-wrap " *ngIf="!isMobileView">
  <button id='locationFilter-{{listData.type}}' class="btn filter-selct-btn  w100 custom-arow" type="button"
    id="dropdownLocationButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
    (click)="statusTextBoxHandler()">
    {{ selectedFilterText }}
    <span><img src="assets/img/down_arrow-lg.svg" alt="" [ngClass]="{'rotate-arrow':accountDropdownToggle}" /></span>
    <span><img src="assets/img/close-sm.png" class="close-sm" alt=""
        (click)="clearFilters(listData.type, listData.index, listData.id)" /></span>
  </button>
  <div class="dropdown-menu accountStatusFilterDropdown {{listData.type}}" aria-labelledby="dropdownLocationButton">
    <ul class="statusList" id="locationPopup-{{listData.type}}"
      [ngClass]="{'add-auto': selectedStatusFilterArray.length > 0}">
      <div class="status-list " *ngIf="selectedStatusFilterArray.length > 0">
        <div class="con-set" *ngFor="let show of selectedStatusFilterArray; let indexOfelement = index">
          <span class="move-right">
            <span class="close-div"><img src="assets/img/close-sm.png"
                (click)="childSelectedText(indexOfelement, listData.type, show)" alt="" />
              <span class="set-one">{{ show?.name | titlecase }}</span>
            </span>
          </span>
          <span class="move-left">{{ show?.target_party_id }}</span>
        </div>
      </div>
      <div class="search-area" id="searchArea-{{ listData.type }}">
        <input type="text" class="form-control" name="search" [value]="searchAccountFilterText" [(ngModel)]="searchText"
          (keyup)="getAccountList(searchText)" (keyup.backspace)="clearData(searchText)"
          (keyup.enter)="onSearchHandler(searchText)" autocomplete="off"
          placeholder="Search {{ listData.filterDisplayName }}s" #searchTextbox />
        <span class="right-set-search">
          <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-1"
            *ngIf="searchText.length >0" (click)="searchTextbox.value='';searchText = ''; getAccountList('')">
          <img class="search-set" src="assets/img/Iconfeather-search.png" alt=""
            (click)="onSearchHandler(searchText)" />
        </span>
      </div>
      <div class="scroll-sec" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        [scrollWindow]="false" (scrolled)="onScroll($event, searchText)">
        <ng-container>
          <h6 class="mb-1" *ngIf="resentList?.length > 0">
            Recent {{ listData.filterDisplayName }}s
          </h6>

          <div *ngIf="resentList?.length > 0">
            <div class="status-text" *ngFor="let resent of resentList; let indexOfelement = index" (click)="
                accountSelectedText(resent, listData.type, indexOfelement)
              ">
              <div class="con-set">
                <span class="move-right">
                  <span class="list-data">{{ resent.name | titlecase }}</span>
                </span>
                <span class="move-left">{{ resent.target_party_id }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <h6 *ngIf="listData.filterDisplayName !=='Importer of Record'" class="mb-1 mt-2">All {{
            listData.filterDisplayName }}s</h6>
          <h6 *ngIf="listData.filterDisplayName ==='Importer of Record'" class="mb-1 mt-2">All Importer of Records</h6>
          <ng-container>
            <app-progressbar *ngIf="isProgress"></app-progressbar>
          </ng-container>
          <div>
            <div class="status-text" *ngFor="let show of locationList; let indexOfelement = index"
              (click)="accountSelectedText(show, listData.type, indexOfelement)">
              <ng-container *ngIf="locationList?.length > 0">
                <div class="con-set">
                  <span class="move-right">
                    <span class="list-data">{{ show.name | titlecase }}</span>
                  </span>
                  <span class="move-left">{{ show.target_party_id }}</span>
                </div>
              </ng-container>
            </div>
            <div class="font-w" *ngIf="
                locationList?.length == 0 &&
                !isProgress
              ">
              No Records Found
            </div>
          </div>
        </ng-container>
      </div>
    </ul>
  </div>
</div>

<div class="accordion-body origin-Location" *ngIf="isMobileView">
  <div class="p-2">
    <div class="status-list" *ngIf="
          selectedStatusFilterArray && selectedStatusFilterArray.length > 0
        ">
      <div class="con-set ruler" *ngFor="
            let show of selectedStatusFilterArray;
            let indexOfelement = index
          ">
        <div class="move-right d-flex align-items-center justify-content-between">
          <span class="close-div"><img src="assets/img/close-sm.png" style="width: 15px"
              (click)="childSelectedText(indexOfelement, listData.type, show)" alt="" />
            <span class="set-one">{{ show?.name | titlecase }}</span>
          </span>
          <span class="move-left">{{ show?.target_party_id }}</span>
        </div>
      </div>
    </div>

    <div class="input-group mt-3 mb-3">
      <input type="text" class="form-control border-right-0 search-area" name="search" [value]="searchAccountFilterText"
        [(ngModel)]="searchText" (keyup)="getAccountList(searchText)" (keyup.backspace)="clearData(searchText)"
        (keyup.enter)="onSearchHandler(searchText)" autocomplete="off"
        placeholder="Search {{ listData.filterDisplayName }}s" />
      <div class="input-group-append">
        <div class="search-bg input-group-text">
          <img src="assets/img/search-sm.svg" (click)="onSearchHandler(searchText)" alt="" />
        </div>
      </div>
    </div>

    <div class="location-innerscroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [scrollWindow]="false" (scrolled)="onScroll($event, searchText)" [fromRoot]="true">
      <ng-container>
        <h6 class="mb-1" *ngIf="resentList?.length > 0">
          Recent {{ listData.filterDisplayName }}s
        </h6>

        <div *ngIf="resentList?.length > 0">
          <div class="status-text ruler" *ngFor="let resent of resentList; let indexOfelement = index" (click)="
                accountSelectedText(resent, listData.type, indexOfelement)
              ">
            <div class="d-flex align-items-center con-set">
              <span class="move-right">
                <span class="list-data">{{ resent.name | titlecase }}</span>
              </span>
              <span class="move-left">{{ resent.target_party_id }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <h6 *ngIf="listData.filterDisplayName !=='Importer of Record'" class="mb-1 mt-2">All {{
          listData.filterDisplayName }}s</h6>
        <h6 *ngIf="listData.filterDisplayName ==='Importer of Record'" class="mb-1 mt-2">All Importer of Records</h6>

        <div>
          <div class="status-text ruler" *ngFor="let show of locationList; let indexOfelement = index"
            (click)="accountSelectedText(show, listData.type, indexOfelement)">
            <ng-container *ngIf="locationList?.length > 0">
              <div class="d-flex align-items-center justify-content-between con-set">
                <span class="move-right">
                  <span class="list-data">{{ show.name | titlecase }}</span>
                </span>
                <span class="move-left">{{ show.target_party_id }}</span>
              </div>
            </ng-container>
          </div>
          <ng-container>
            <app-progressbar *ngIf="isProgress"></app-progressbar>
          </ng-container>
          <div class="font-w" *ngIf="
                locationList?.length == 0 &&
                !isProgress
              ">
            No Records Found
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>