import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class BroadCastService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  saveBroadCastSettings(request: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'Broadcast/Create',
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getBroadCastSettings(value: any): Observable<any> {
    return this.http
      .get<any>(
        environment.base_api_desktop_url +
          'Broadcast/Broadcast?isAdminPage=' +
          value
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
}
