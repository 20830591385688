<div id="pop-up3" class="dimScreen" *ngIf="popupToggle">
  <div *ngIf="popupToggle" class="shipment-details" id="shipmentFull" #shipmentFull>
    <div class="overall-scroll-mobtab">
      <button class="close" type="button" (click)="featureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
      </button>
      <div class="body-section">
        <!--Header starts here-->
        <app-customs-details-header [selectedCustom]="selectedCustom"
          (refreshCustomsDetailsFromHeader)="onReloadDetails($event)"
          (reloadCustomsListFromHeader)="reloadWatchListCustom($event)"
          [publicShareEnrcyptedData]="publicShareEnrcyptedData" (prepareUrlShare)="share($event)">
        </app-customs-details-header>
        <!--Header ends here-->

        <div class="col-lg-12 p-0">
          <div class="tab-content shipment-tab-topmargin" id="myTabContent"
            [class.add-sp-set]="!checkSFile(selectedCustom?.forwarder_reference)">
            <div class="tab-pane fade show active" id="customDetail" role="tabpanel" aria-labelledby="home-tab">
              <app-customs-details-tab [selectedCustom]="selectedCustom"></app-customs-details-tab>
            </div>
            <div class="tab-pane fade overall-scroll" *ngIf="selectedCustom.isFullViewAccess" id="customDocuments"
              role="tabpanel" aria-labelledby="profile-tab">
              <app-customs-document-tab [selectedCustom]="selectedCustom"></app-customs-document-tab>
            </div>
            <div class="tab-pane fade overall-scroll" *ngIf="selectedCustom?.commercial_invoices?.length >0"
              id="customInvoice" role="tabpanel" aria-labelledby="profile-tab">
              <app-customs-invoices-tab [selectedCustom]="selectedCustom"></app-customs-invoices-tab>
            </div>
            <div class="tab-pane fade overall-scroll" id="customPga" role="tabpanel" aria-labelledby="profile-tab"
              *ngIf="selectedCustom.isFullViewAccess && 
                  selectedCustom.customs_declarations[0]?.customs_agency_statuses
                    ?.length > 0 && selectedCustom.customs_declarations[0]?.customs_type !== 'EXP'
                    && selectedCustom.customs_declarations[0]?.customs_type !== 'OUT'
                    && selectedCustom.customs_declarations[0]?.customs_type !== 'IPT' && selectedCustom?.commercial_invoices?.length >0
                ">
              <app-customs-pga-tab [selectedCustom]="selectedCustom"></app-customs-pga-tab>
            </div>

            <div class="tab-pane fade overall-scroll" id="action-tab-cutoms" role="tabpanel" aria-labelledby="logs-tab">
              <app-action-detail-tab [selectedCustom]="selectedCustom" (closePopUp)="popUpClose()"
                (loadCustomList)="loadCustomListById($event)"
                [currentUserData]="currentAccountData"></app-action-detail-tab>
            </div>

            <div class="tab-pane fade overall-scroll" id="logs1" role="tabpanel" aria-labelledby="logs-tab"
              *ngIf="selectedCustom.isFullViewAccess">
              <app-change-logs [selectedShipment]="selectedCustom" [type]="'customs'"
                (showViewReference)="loadFullReferenceNum($event)"></app-change-logs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>