import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'bookedDate'
})
export class BookedDatePipe implements PipeTransform {

  transform(data: any): unknown {
    let message = '---';
    if (data?.booking_confirmed_actual) {
      message = CommonUtils.formatDateMMMDDYYYY(data?.booking_confirmed_actual);
    }
    return message;
  }

}
