import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from '../app/shared/guards/route.guard';
import { EditorPocComponent } from './features/editor-poc/editor-poc.component';
import { FaqListComponent } from './features/faq-list/faq-list.component';
import { PublicSearchComponent } from './features/public-search/public-search.component';
import { NavigationAuthGuard } from './shared/guards/navigation-auth.guard';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'faqs', component: FaqListComponent },
  { path: 'editor', component: EditorPocComponent },
  { path: 'faqs/:question', component: FaqListComponent },
  {
    path: 'login',
    loadChildren: () =>
      import('../app/features/login-register/login-register.module').then(
        (mod) => mod.LoginRegisterModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../app/features/dashboard/dashboard.module').then(
        (mod) => mod.DashboardModule
      ),
    canActivate: [RouteGuard, NavigationAuthGuard],
  },
  {
    path: 'admin',
    canActivate: [RouteGuard],
    data: {
      roles: ['Admin'],
    },
    loadChildren: () =>
      import('../app/features/admin/admin.module').then(
        (mod) => mod.AdminModule
      ),
  },
  {
    path: 'shipments', canActivate:[NavigationAuthGuard],
    loadChildren: () =>
      import('../app/features/shipments/shipment.module').then(
        (mod) => mod.ShipmentModule
      ),
  },
  {
    path: 'bookings', canActivate:[NavigationAuthGuard],
    loadChildren: () =>
      import('../app/features/booking/booking.module').then(
        (mod) => mod.BookingModule
      ),
  },
  {
    path: 'profile',
    canActivate: [RouteGuard],
    loadChildren: () =>
      import('../app/features/profile/profile.module').then(
        (mod) => mod.ProfileModule
      ),
  },
  {
    path: 'reports',
    canActivate: [RouteGuard, NavigationAuthGuard],
    loadChildren: () =>
      import('../app/features/power-bi/power-bi/power-bi.module').then(
        (mod) => mod.PowerBiModule
      ),
  },
  {
    path: 'customs', canActivate:[NavigationAuthGuard],
    loadChildren: () =>
      import('../app/features/customs/customs.module').then(
        (mod) => mod.CustomsModule
      ),
  },
  { path: ':searchParam', component: PublicSearchComponent },
  { path: '**', redirectTo: '/' },
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule { }
