<div class="container-fluid"
    [ngClass]="{'guided-back-drop' : isCustomFilterSecHide,'guided-visible-sec':isCustomFilterSecShow}">
    <div class="row d-flex align-items-start mobiletop-pad">
        <div class="col-lg-9 col-md-8 shipment-flex-wrap">
            <div *ngIf="!isDashboard" class="shipment-search-wdth">
                <div class="shipment-title-mobile">Bookings</div>
                <div class="d-flex position-relative" id="bookingSearchBox"
                    [ngClass]="{'guided-back-drop' : isSearchBoxHide,'guided-visible-sec':isSearchBoxShow}">
                    <input type="text" [(ngModel)]="bookingSearchText" class="form-control search-input"
                        placeholder="Search by booking or reference number"
                        (keyup.backspace)="clearSearchText(bookingSearchText)" (keyup.enter)="clickEvent.next($event)"
                        (blur)="clickEvent.next($event)" #searchTextbox />
                    <span class="form-control-feedback search-icon">
                        <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-0"
                            *ngIf="bookingSearchText.length >0"
                            (click)="searchTextbox.value='';clearSearchText(searchTextbox.value)">
                        <img src="../../assets/img/search.svg" alt="Search" (click)="clickEvent.next($event)" />
                    </span>
                </div>
            </div>
            <div *ngIf="!isDashboard" class="filter-overflow">
                <button class="btn btn-group filter-btn" (click)="openMobileFilter()" type="button"
                    id="MainDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    [ngClass]="{'guided-back-drop' : isFilterHide,'guided-visible-sec button-click':isFilterShow}">
                    <span class="filter-btn-text">Filter</span>
                </button>
                <div id="dropdownMenuButton" class="dropdown-menu mobile-hide" aria-labelledby="dropdownMenuButton">
                    <ul class="custom-drop-menu">
                        <li class="dropdown-item" *ngFor="let filter of filterItem; let index = index">
                            <input [(ngModel)]="filter.checked" class="test-selection" type="checkbox"
                                name="filter-{{ filter.filt }}" value="{{ filter.val }}"
                                id="MainFilterId-{{ filter.val }}" (change)="
                                  isFilterSelected(
                                    filter.checked,
                                    filter.val,
                                    filter.filt,
                                    filter.col_name,
                                    filter.val
                                  )
                                " />
                            <label class="selection-list" for="MainFilterId-{{ filter.val }}">
                                <span class="header-set">{{ filter.filt }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="static-menu-filter" *ngIf="guidedTourFilter">
                    <ul class="custom-drop-menu newset">
                        <li class="dropdown-item" *ngFor="let filter of filterItemset">
                            <input type="checkbox">
                            <label class="selection-list">
                                <span class="header-set">{{ filter.filt }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- mobile filter design -->
            <ng-container *ngIf="isMobileViewParent">
                <div class="mobile-filter-container" *ngIf="mobileFilterContainer">

                    <div class="filter-header">
                        <div class="close-filter" (click)="closeFilter()">
                            <img src="../../assets/img/close-popup.svg" alt="closepopup" />
                        </div>
                        <span>Filters</span>
                        <span (click)="clearAllFilter()">Clear All</span>
                    </div>
                    <div class="accordion filter-accordian">
                        <div class="accordion accordion-flush" id="Savefilter01">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="Savefilter01">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#Savefilter" aria-expanded="false"
                                        aria-controls="flush-collapseThree">
                                        My Saved Filters
                                    </button>
                                </h2>
                                <div id="Savefilter" class="accordion-collapse collapse" aria-labelledby="Savefilter01"
                                    data-bs-parent="#Savefilter01">
                                    <div class="accordion-body new-set">
                                        <ul class="custom-drop-menu mb-0 pb-4" *ngIf="savedFilters.length > 0">
                                            <li class="dropdown-item add-new-set"
                                                *ngFor="let userFilter of savedFilters;let i = index">
                                                <span class="rap-radio">
                                                    <input type="radio" name="userFilter"
                                                        (change)="selectedUserFilter(userFilter)" id="userFilter{{i}}"
                                                        value="{{userFilter.id}}"
                                                        [checked]="mobileSelectedFilter == userFilter.id ? true:false">
                                                    <label for="userFilter{{i}}">{{userFilter.filterName}}</label>
                                                </span>
                                            </li>
                                        </ul>
                                        <ul *ngIf="savedFilters.length < 1">
                                            <li class="dropdown-item">No Filters</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="filterAccordian">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#DateRange" [ngClass]="{ activeFilters: dateFilterValue}"
                                        aria-expanded="false" aria-controls="flush-collapseThree">
                                        Date Range
                                    </button>
                                </h2>
                                <div id="DateRange" class="accordion-collapse collapse"
                                    aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian">
                                    <app-daterange-filter [filterType]="mobileDateRangeFilter"
                                        [selectedDateRangeRender]="selectedDateRangeRender"
                                        (closeSelectedFilterControl)="closeCustomFilters($event)"
                                        (dateRangeFilter)="getDateRange($event)">
                                    </app-daterange-filter>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="filterAccordianLocation">
                                    <button id="mobileLocationFilter" class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseTen"
                                        [ngClass]="{ activeFilters: locationFilterValue}" (click)="
                     filterIsOpen('#mobileLocationFilter',
                    true,
                    4,
                    'Location Type',
                    'location',
                    4)" aria-expanded="false" aria-controls="flush-collapseTen">
                                        Location
                                    </button>
                                </h2>
                                <div id="flush-collapseTen" class="accordion-collapse collapse"
                                    aria-labelledby="filterAccordian" data-bs-parent="#filterAccordianLocation">
                                    <app-all-location-filter (emitOffsetLoc)="offsetLocation($event)"
                                        [listData]="mobileAllLocationFilter" [isProgress]="isProgressLocation"
                                        (closeSelectedFilterControl)="closeCustomFilters($event)"
                                        [resentList]='resentAllLocationList'
                                        (alllocationFilter)="locationSelectedFilter($event)"
                                        (locationFilterSearch)="getLocationList($event)"
                                        (locationFilterValueEmit)='loadLocationFilter($event)'
                                        [allLocationReselected]='allLocationReselectedList'
                                        (locationFilterClear)="clearFilterSearch($event)" [offsetOrigin]="offsetOrgin"
                                        [offsetDestination]="offsetDestination" [offsetPickup]="offsetPickup"
                                        [offsetDelivery]="offsetFinalDelivery" [allLocationList]='allLocationList'>
                                    </app-all-location-filter>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="filterAccordian">
                                    <button id="mobileShipperFilter" class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#mobileShipperFilterId"
                                        [ngClass]="{ activeFilters: shipperFilterValue}" (click)="
                   filterIsOpen('#mobileShipperFilter',
                    true,
                    2,
                    'Shipper',
                    'shipper',
                    2)
                  " aria-expanded="false" aria-controls="flush-collapseThree">
                                        Shipper
                                    </button>
                                </h2>
                                <div id="mobileShipperFilterId" class="accordion-collapse collapse"
                                    aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian">
                                    <app-common-shipper-type-filter (locationFilterSearch)="getLocationList($event)"
                                        [mobileFilterReselectValue]="mobileShipperFilterReselect"
                                        (emitOffsetLoc)="offsetLocation($event)" [locationList]="shipperList"
                                        [resentList]="resentListShipper"
                                        (locationFilterClear)="clearFilterSearch($event)"
                                        (locationFilter)="locationSelectedFilter($event)"
                                        (closeSelectedFilterControl)="closeCustomFilters($event)"
                                        [listData]="mobileShipperFilter" [offset]="offsetShipper"
                                        [isProgress]="isProgressLocation">
                                    </app-common-shipper-type-filter>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne" id="mobileStatusFilter"
                                        [ngClass]="{ activeFilters: statusFilterValue}" aria-expanded="false"
                                        aria-controls="flush-collapseOne" (click)="filterIsOpen('#mobileStatusFilter', true,
                    1,
                    'Status',
                    'statuscustom',
                    1)">
                                        Status
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne" data-bs-parent="#filterAccordian">
                                    <ng-container>
                                        <app-common-dropdown-filters [filterType]="mobileStatusFilter"
                                            (closeSelectedFilterControl)="closeCustomFilters($event)"
                                            (selectedFiltersArray)="getSelectedFilterArray($event)">
                                        </app-common-dropdown-filters>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="filterAccordian">
                                    <button id="mobileTransportFilter" class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                        aria-expanded="false" aria-controls="flush-collapseThree"
                                        [ngClass]="{ activeFilters: transportFilterValue}" (click)="
                     filterIsOpen('#mobileTransportFilter',
                    true,
                    0,
                    'Transport Type',
                    'transport',
                    0)
                   ">
                                        Transport Type
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian">
                                    <ng-container>
                                        <app-common-dropdown-filters [filterType]="mobileTransportFilter"
                                            (closeSelectedFilterControl)="closeCustomFilters($event)"
                                            (selectedFiltersArray)="getSelectedFilterArray($event)">
                                        </app-common-dropdown-filters>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="filterAccordian">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#Watchlist" [ngClass]="{ activeFilters: favoritesFilterValue}"
                                        aria-expanded="false" aria-controls="flush-collapseThree">
                                        Watchlist
                                    </button>
                                </h2>
                                <div id="Watchlist" class="accordion-collapse collapse"
                                    aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian">
                                    <div id="Watchlist" class="accordion-collapse collapse"
                                        aria-labelledby="filterAccordian" data-bs-parent="#filterAccordian">
                                        <div class="accordion-body new-set">
                                            <ul class="custom-drop-menu">
                                                <li class="dropdown-item">
                                                    <input type="checkbox" id="checkList" [checked]="checkWatchlist"
                                                        (change)="watchlistSelected($event)">
                                                    <label class="selection-list" for="checkList">
                                                        <span class="header-set">Watchlist</span>
                                                    </label>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="showall-filter">
                            <button class="btn btn-yellow" (click)="closeFilter()">SHOW <span
                                    *ngIf="paramCheck?.filter_options?.length < 1">ALL</span><span
                                    *ngIf="paramCheck?.filter_options?.length > 0">{{resultCount}}</span>
                                BOOKINGS</button>

                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- mobile filter design end -->
            <ng-container *ngIf="!isMobileViewParent">
                <div class="d-inline-block mt-2 mr-2" *ngFor="let row of selectedMainFilter">
                    <ng-container *ngIf="row.id == 0 || row.id == 1 || row.id == 6">
                        <app-common-dropdown-filters [filterType]="row"
                            (closeSelectedFilterControl)="closeCustomFilters($event)"
                            (selectedFiltersArray)="getSelectedFilterArray($event)">
                        </app-common-dropdown-filters>
                    </ng-container>

                    <ng-container *ngIf="row.id == 5">
                        <app-daterange-filter [filterType]="row"
                            (closeSelectedFilterControl)="closeCustomFilters($event)"
                            (dateRangeFilter)="getDateRange($event)"></app-daterange-filter>
                    </ng-container>

                    <ng-container *ngIf="row.id == 2 || row.id == 3">
                        <app-common-shipper-type-filter (locationFilterSearch)="getLocationList($event)"
                            (emitOffsetLoc)="offsetLocation($event)" [locationList]="shipperList"
                            [resentList]="resentListShipper" (locationFilterClear)="clearFilterSearch($event)"
                            (locationFilter)="locationSelectedFilter($event)"
                            (closeSelectedFilterControl)="closeCustomFilters($event)" [listData]="row"
                            [offset]="offsetShipper" [isProgress]="isProgressLocation">
                        </app-common-shipper-type-filter>
                    </ng-container>
                    <ng-container *ngIf="row.id == 4">
                        <app-all-location-filter (emitOffsetLoc)="offsetLocation($event)" [listData]="row"
                            (closeSelectedFilterControl)="closeCustomFilters($event)"
                            [resentList]='resentAllLocationList' (alllocationFilter)="locationSelectedFilter($event)"
                            (locationFilterSearch)="getLocationList($event)" [isProgress]="isProgressLocation"
                            (locationFilterValueEmit)='loadLocationFilter($event)'
                            (locationFilterClear)="clearFilterSearch($event)" [offsetOrigin]="offsetOrgin"
                            [offsetDestination]="offsetDestination" [offsetPickup]="offsetPickup"
                            [offsetDelivery]="offsetFinalDelivery" [allLocationList]='allLocationList'>
                        </app-all-location-filter>
                    </ng-container>
                    <ng-container *ngIf="row.id == 7">
                        <div class="inbox-set m-0">
                            <input type="text" readonly class="form-control" name="search" [value]="watchlistFilter"
                                autocomplete="off" />
                            <span class="update-fix"><img src="../../../../../assets/img/close-sm.png" class="close-sm"
                                    alt="" (click)="closeWatchFilter()" /></span>
                        </div>

                    </ng-container>

                    <div id="watchListId"></div>
                </div>
            </ng-container>
            <span class="rap-save">
                <button type="button" class="btn-save-as" *ngIf="isShowSaveBtn" (click)="updateUserFilter()">Save
                </button>
                <button type="button" class="btn-save-as" *ngIf="isShowSaveAsBtn" (click)="openSaveFilterPopUp()">Save
                    As</button>
            </span>
        </div>
        <div class="col-lg-3 col-md-4 col-12 d-lg-flex justify-content-lg-end p-0" id="sortRapper">
            <div class="sortby-wrap">
                <span id="sortSection" [ngClass]="{'guided-back-drop' : isSortHide,'guided-visible-sec':isSortShow}">
                    <div *ngIf="!isDashboard" class="mt-1 child">Sort By:</div>

                    <div class="btn-group dropdown child filterDropdown">
                        <button type="button" id="sortButtonId"
                            class="btn shipment-drop-btn dropdown-toggle mx-2 statusdrop" data-toggle="dropdown"
                            aria-expanded="false">
                            <span *ngIf="!isDashboard" class="shipment-drop-text">
                                <img [ngClass]="defaultBookingSortDirection === 'desc' ? 'mr-1' : 'mr-1 update'"
                                    alt="desc" src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                                    class="mr-1" />
                                {{ defaultBookingSortSelectedColumn }}
                            </span>
                            <span *ngIf="isBookingsDashboard" class="shipment-drop-text">
                                <img [ngClass]="defaultBookingSortDirection === 'desc' ? 'mr-1' : 'mr-1 update'"
                                    alt="desc" src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                                    class="mr-1" />
                                {{ defaultBookingSortBookingsDashboard }}
                            </span>
                            <span *ngIf="isBookingsDashboardWatchlist" class="shipment-drop-text">
                                <img [ngClass]="defaultBookingSortDirection === 'desc' ? 'mr-1' : 'mr-1 update'"
                                    alt="desc" src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                                    class="mr-1" />
                                {{ defaultBookingSortBookingsDashboardWatchlist }}
                            </span>
                        </button>
                        <div class="dropdown-menu restrict-close" aria-labelledby="dropdownMenuButton">
                            <ul class="custom-drop-menu">
                                <li class="dropdown-item pointer"
                                    [ngClass]="{'active': defaultBookingSortSelectedValue == sortOption.id}"
                                    *ngFor="let sortOption of bookingSortOptions"
                                    (click)=" clickSortOption.next(sortOption)">
                                    <span class="shipment-drop-text">
                                        <img *ngIf="
                                                  defaultBookingSortSelectedValue == sortOption.id &&
                                                  defaultBookingSortDirection === 'desc'
                                                " alt="desc"
                                            src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                                            class="mr-1" />
                                        <img *ngIf="
                                                  defaultBookingSortSelectedValue == sortOption.id &&
                                                  defaultBookingSortDirection === 'asc'
                                                " alt="asc"
                                            src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                                            class="mr-1 update" />
                                        <span *ngIf="defaultBookingSortSelectedValue != sortOption.id"></span>{{
                                        sortOption.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
                <div *ngIf="!isDashboard" class="upload-btn child" (click)="share($event)" id="shareSection">
                    <img src="../../assets/img/upload.svg" class="upload-icon" alt="upload" />
                </div>
            </div>
        </div>
    </div>
</div>
<div id="pop-up4" *ngIf="createUserFilterPopUp" class="dimScreen">
    <div class="add-user-popup new-filter">
        <button class="close" type="button" (click)="closeUserFilterPopUp()">
            <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
        </button>
        <div class="body-section fix-size">
            <h3>Create New Filter</h3>
            <div class="content-sec">
                <p *ngIf="myFilterError && myFilterName.length > 0" class="error-set red-set">A filter with this name
                    already
                    exists.</p>
                <p *ngIf="myFilterNameEmpty && myFilterName.length < 1" class="error-set red-set">Please enter the
                    filter name.
                </p>
                <p *ngIf="myFilterName.length >= 30" class="error-set red-set">Filter name reached maximum 30 characters
                    allowed.</p>

                <label>Filter Name<span class="red-set">*</span></label>
                <input type="text" [(ngModel)]="myFilterName" placeholder="Enter Name" class="form-control"
                    maxlength="30" (keyup.enter)="saveMyFilter()">
                <button type="button" (click)="saveMyFilter()">save</button>
            </div>
        </div>
    </div>
</div>
<div id="pop-up5" *ngIf="isShowEditPopup" class="dimScreen">
    <div class="add-user-popup new-filter">
        <button class="close" type="button" (click)="closeEditUserFilterPopUp()">
            <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
        </button>
        <div class="body-section fix-size">
            <h3>Edit Filter</h3>
            <div class="content-sec">
                <p *ngIf="myFilterError && myEditFilterName.length > 0" class="error-set red-set">A filter with this
                    name
                    already exists.</p>
                <p *ngIf="myFilterNameEmpty && myEditFilterName.length < 1" class="error-set red-set">Please enter the
                    filter
                    name.</p>
                <p *ngIf="myEditFilterName.length >= 30" class="error-set red-set">Filter name reached maximum 30
                    characters
                    allowed.</p>
                <label>Filter Name<span class="red-set">*</span></label>
                <input type="text" [(ngModel)]="myEditFilterName" placeholder="Enter Name" class="form-control"
                    maxlength="30" (keyup.enter)="updateUserFilter()">
                <button type="button" (click)="updateUserFilter()">save</button>
                <button type="button" class="delete-btn" (click)="deleteUserFilter()">delete</button>
            </div>
        </div>
    </div>
</div>
<div class="align-items-center justify-content-xl-end shipment-list-filter">
    <div id="bookingSharePopup" *ngIf="bookingSharePopup" class="dimScreen">
        <div *ngIf="bookingSharePopup" class="add-user-popup">
            <button class="close" type="button" (click)="closeSharePopUp()">
                <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
            </button>
            <div class="body-section">
                <h3>Share <span>Booking</span></h3>
                <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" id="tabDetail">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="home" aria-selected="true">
                            <img src="../../../../../assets/img/share/url-img.svg" alt="" />
                            URL
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link " id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false">
                            <img src="../../../../../assets/img/share/download-img.svg" alt="download" />
                            Download
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
                        aria-labelledby="home-tab">
                        <div class="discription">
                            <p class="text-des">
                                Anyone with a Connect account and access to this company will be able to view this list.
                            </p>
                            <div class="copy-section">
                                <input type="text" id="shareUrl" [value]="urlShare" readonly />
                                <button class="add-type" (click)="copyLink()">COPY LINK</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show  justify-content-center" id="profile" role="tabpanel"
                        aria-labelledby="profile-tab">
                        <div class="discription">
                            <p>
                                Download all bookings with the current filters that are applied to the list.
                            </p>
                            <button class="add-type mobile-set" (click)="excelExport('download')">
                                Download AS Excel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>