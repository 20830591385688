import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
declare var $: any;
@Component({
  selector: 'app-common-shipper-type-filter',
  templateUrl: './common-shipper-type-filter.component.html',
  styleUrls: ['./common-shipper-type-filter.component.scss'],
})
export class CommonShipperTypeFilterComponent implements OnInit, OnChanges {
  accountDropdownToggle: boolean = false;
  selectedFilterText: string = '';
  selectedStatusFilterArray: any[] = [];
  DISPLAY_TEXT_COUNT: number = 15;
  accountItemList: any[] = [];
  selectedAccountParameter: any;
  messageText: any[] = [];
  originType: any;
  inside = false;
  originType_Two: any;
  searchText: string = '';
  toggleLoc: boolean;
  searchAccountFilterText: string = ' ';
  concatString = '';
  concatArray: any[] = [];
  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  @Input() listData: any;
  @Input() locationList: any;
  @Input() mobileFilterReselectValue: any;
  @Input() offset: any;
  @Input() isProgress: any;
  @Input() resentList: any = [];
  @Output() locationFilter = new EventEmitter();
  @Output() locationFilterClear = new EventEmitter();
  @Output() locationFilterSearch = new EventEmitter();
  @Output() emitOffsetLoc = new EventEmitter();
  @Output() closeSelectedFilterControl = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 767 ? true : false;
  }

  constructor(private appInsightsService: AppInsightsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isMobileView) {
      if (typeof this.mobileFilterReselectValue === 'object') {
        this.selectedStatusFilterArray = this.mobileFilterReselectValue;
      }
      this.originType = this.listData.filterDisplayName;
    }
  }

  ngOnInit(): void {
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 767 ? true : false;
    this.mobileDataLoad();
    this.setMessageType();
    this.jqueryLoad();
  }

  setMessageType() {
    if (
      typeof this.listData.selectedAccount === 'object' &&
      !this.isMobileView
    ) {
      let displayValue: any[] = [];
      this.listData.selectedAccount.forEach((ele: any) => {
        displayValue.push(ele.target_party_id);
      });
      this.truncateDisplayText(displayValue, '');
      this.selectedStatusFilterArray = this.listData.selectedAccount;
    } else {
      this.selectedFilterText = this.originType + ': ';
    }
  }

  jqueryLoad() {
    let root = this;
    $(document).ready(function () {
      if (root.listData.autoOpen) {
        $('#locationFilter-' + root.listData.type).click();
      }
      $('.dropdown-menu').on('click', function (e: any) {
        e.stopPropagation();
      });
    });
  }

  mobileDataLoad() {
    if (!this.isMobileView) {
      this.originType = this.listData.filterDisplayName;
    }
    if (this.isMobileView) {
      if (typeof this.mobileFilterReselectValue === 'object') {
        this.selectedStatusFilterArray = this.mobileFilterReselectValue;
      }
      this.originType = this.listData.filterDisplayName;
    }
  }

  onScroll(event: any, searchText: any) {
    let offsetLocation = 30;
    if (this.isMobileView) {
      offsetLocation = 10;
    }
    this.offset += offsetLocation;
    this.emitOffsetLoc.emit({
      offset: this.offset,
      type: this.originType,
      value: searchText,
    });
  }

  statusTextBoxHandler() {
    this.accountDropdownToggle = !this.accountDropdownToggle;
  }

  onSearchHandler(val: any) {
    this.locationFilterSearch.emit({ value: val, type: this.originType });
  }

  getAccountList(val: any) {
    this.accountItemList = [];
    this.emitOffsetLoc.emit({
      offset: 0,
      type: this.originType,
      value: val,
    });
    if (val?.length > 2 || val?.length == 0) {
      this.locationFilterSearch.emit({ value: val, type: this.originType });
    }
  }

  clearData(val: any) {
    this.locationFilterClear.emit({ value: val, type: this.originType });
  }

  childSelectedText(indexId: any, type: any, filterText: string) {
    this.trackAIShipmentOrConsigneeFilterItemRemovedEvent(type, indexId);

    this.concatArray = [];
    this.concatString = '';
    this.selectedStatusFilterArray.splice(indexId, 1);
    this.messageText.splice(indexId, 1);
    this.accountItemList.push(filterText);
    this.selectedStatusFilterArray.forEach((e) => {
      this.concatArray.push(e.target_party_id);
    });
    this.concatString = this.concatArray.map((x) => x).join(',');
    this.selectedAccountParameter = [
      {
        column_name: type,
        operater: '=',
        value: this.concatString,
      },
    ];
    this.locationFilter.emit({
      selectedValue:
        this.concatString?.length > 0 ? this.selectedAccountParameter : [],
      type: type,
      account: this.selectedStatusFilterArray,
      clearFrom: '',
      removing: true,
    });
    this.accountItemList.sort((one, two) => (one > two ? 1 : -1));
    this.truncateDisplayText(this.messageText, '');
  }

  accountSelectedText(account: any, type: any, index: any) {
    this.trackAIShipmentOrConsigneeFilterItemAppliedEvent(type, account);

    let elementPosOrgin = this.selectedStatusFilterArray
      .map(function (x: any) {
        return x.target_party_id;
      })
      .indexOf(account.target_party_id);
    if (elementPosOrgin < 0) {
      this.concatArray = [];
      this.concatString = '';
      this.selectedStatusFilterArray.push(account);
      this.selectedStatusFilterArray.forEach((el) => {
        this.concatArray.push(el.target_party_id);
      });
      this.concatString = this.concatArray.map((x) => x).join(',');

      this.selectedAccountParameter = [
        {
          column_name: type,
          operater: '=',
          value: this.concatString,
        },
      ];
      this.locationFilter.emit({
        selectedValue: this.selectedAccountParameter,
        type: type,
        account: this.selectedStatusFilterArray,
        clearFrom: '',
        removing: false,
      });
      this.messageText.push(account.target_party_id);
      this.accountItemList.splice(index, 1);
      this.truncateDisplayText(this.messageText, type);
    }
  }

  truncateDisplayText(item: any, type: any) {
    if (!item || this.selectedStatusFilterArray?.length <= 0) {
      this.selectedFilterText = this.originType + ': ';
    }
    if (item?.length > 0) {
      this.selectedFilterText =
        this.originType +
        ': ' +
        item.join(', ').slice(0, this.DISPLAY_TEXT_COUNT);
      if (this.selectedFilterText?.length > this.DISPLAY_TEXT_COUNT) {
        this.selectedFilterText =
          this.selectedFilterText.substring(0, this.DISPLAY_TEXT_COUNT) + '...';
      }
    }
  }

  clearFilters(filterType: any, filterIndex: any, filterId: any) {
    this.trackAIShipmentOrConsigneeShipperFilterClearedEvent(filterType);
    this.closeSelectedFilterControl.emit({
      type: filterType,
      index: filterIndex,
      id: filterId,
      eventType: 'closeBtn',
      closeFrom: '',
    });

    this.locationList = [];
  }

  trackAIShipmentOrConsigneeFilterItemAppliedEvent(type: any, account: any) {
    let eventName = '';
    switch (type) {
      case 'shipper':
        eventName = AI_CommonEvents.ShipperFilterItemApplied.toString();
        break;
      case 'consignee':
        eventName = AI_CommonEvents.ConsigneeFilterItemApplied.toString();
        break;
    }
    this.appInsightsService.logEvent(eventName, {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      [AI_CustomProps.Name]: account?.name,
      [AI_CustomProps.TargetPartyId]: account?.target_party_id,
    });
  }

  trackAIShipmentOrConsigneeFilterItemRemovedEvent(type: any, indexId: any) {
    let eventName = '';
    switch (type) {
      case 'shipper':
        eventName = AI_CommonEvents.ShipperFilterItemRemoved.toString();
        break;
      case 'consignee':
        eventName = AI_CommonEvents.ConsigneeFilterItemRemoved.toString();
        break;
    }
    this.appInsightsService.logEvent(eventName, {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      [AI_CustomProps.Name]: this.selectedStatusFilterArray[indexId]?.name,
      [AI_CustomProps.TargetPartyId]:
        this.selectedStatusFilterArray[indexId]?.target_party_id,
    });
  }

  trackAIShipmentOrConsigneeShipperFilterClearedEvent(filterType: any) {
    let eventName = '';
    switch (filterType) {
      case 'shipper':
        eventName = AI_CommonEvents.ShipperFilterCleared.toString();
        break;
      case 'consignee':
        eventName = AI_CommonEvents.ConsigneeFilterCleared.toString();
        break;
    }
    this.appInsightsService.logEvent(eventName, {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
    });
  }
}
