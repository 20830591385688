import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';
import { ProgressBarType } from './shipment.enums';

@Pipe({
  name: 'shipmentContainerProgressBar'
})
export class ShipmentContainerProgressBarPipe implements PipeTransform {

  transform(data: any, container: any): unknown {
    let htmlString = '';

    let shipment_legs = CommonUtils.sortShipmentLegsBySequenceNumber(data);
    if (!shipment_legs) return htmlString;

    htmlString += '<h4 class="pl-0">Progress</h4>';
    htmlString += CommonUtils.getShipmentListingProgressBar(
      data,
      false,
      '',
      ProgressBarType.ShipmentContainerTab,
      container
    );

    return htmlString + '</ul>';
  }

}
