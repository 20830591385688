<div class="accordion" id="faq">
  <div
    class="card"
    *ngFor="let container of selectedShipment?.containers; let index = index"
    id="header-{{ container.container_number }}"
    [ngClass]="index == 0 ? 'change-icon add-border' : ''"
  >
    <div
      class="card-header"
      data-toggle="collapse"
      [attr.data-target]="'#body-' + container.container_number"
      aria-expanded="true"
      [attr.aria-controls]="'faq-' + container.container_number"
      (click)="addClassFor('#header-' + container.container_number)"
    >
      <a href="javascript:void(0);" class="btn btn-header-link"
        >{{ container.container_number }} ({{ container.size_code }})</a
      >
    </div>
    <div
      id="body-{{ container.container_number }}"
      [ngClass]="index == 0 ? 'collapse show' : 'collapse'"
      [attr.aria-labelledby]="'faqhead-' + container.container_number"
      [attr.data-parent]="'#header-' + container.container_number"
    >
      <div class="card-body">
        <div class="container-color-bg">
          <div
            class="road-map ship-container-head"
            [innerHTML]="
              selectedShipment | shipmentContainerProgressBar : container
            "
          ></div>
          <div class="mobile-view row change-p">
            <h4>DETAILS</h4>
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h5>Seal No.</h5>
                  <p>
                    {{ container | sealdata }}
                  </p>
                </div>
                <div class="col-md-4 col-6 mob-top">
                  <h5>OIA Reference</h5>
                  <p *ngIf="container?.oiaReference">
                    {{ container | oiaReferenceNumber }}
                  </p>
                  <p *ngIf="!container?.oiaReference">---</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-6 mob-top">
              <h5>Container Gross Weight</h5>
              <p *ngIf="container.gross_weight_kgs">
                {{ container.gross_weight_kgs }} Kgs
              </p>
              <p
                *ngIf="
                  !container.gross_weight_kgs && container.gross_weight_lbs
                "
              >
                {{ container.gross_weight_lbs }} Lbs
              </p>
              <p
                *ngIf="
                  !container.gross_weight_kgs && !container.gross_weight_lbs
                "
              >
                ---
              </p>
            </div>
            <div class="col-md-4 col-6 mob-top">
              <h5>Chargeable Weight</h5>
              <p *ngIf="container.net_weight_kgs">
                {{ container.net_weight_kgs }} Kgs
              </p>
              <p *ngIf="!container.net_weight_kgs && container.net_weight_lbs">
                {{ container.net_weight_lbs }} Lbs
              </p>
              <p *ngIf="!container.net_weight_kgs && !container.net_weight_lbs">
                ---
              </p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.origin_empty_pickup_gate_out"
            >
              <h5>Empty Released CY</h5>
              <p>
                {{ container.origin_empty_pickup_gate_out | yearFormat }}
              </p>
              <p *ngIf="!container.origin_empty_pickup_gate_out">---</p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.origin_gate_in_actual"
            >
              <h5>FCL Wharf Gate In</h5>
              <p *ngIf="container.origin_gate_in_actual">
                {{ container.origin_gate_in_actual | yearFormat }}
              </p>
              <p *ngIf="!container.origin_gate_in_actual">---</p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.loading_actual"
            >
              <h5>FCL Load</h5>
              <p *ngIf="container.loading_actual">
                {{ container.loading_actual | yearFormat }}
              </p>
              <p *ngIf="!container.loading_actual">---</p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.unloading_actual"
            >
              <h5>FCL Unload</h5>
              <p *ngIf="container.unloading_actual">
                {{ container.unloading_actual | yearFormat }}
              </p>
              <p *ngIf="!container.unloading_actual">---</p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.arrival_delivery_booked"
            >
              <h5>Port Transport Booked</h5>
              <p *ngIf="container.arrival_delivery_booked">
                {{ container.arrival_delivery_booked | yearFormat }}
              </p>
              <p *ngIf="!container.arrival_delivery_booked">---</p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.destination_gate_out_actual"
            >
              <h5>FCL Wharf Gate Out</h5>
              <p *ngIf="container.destination_gate_out_actual">
                {{ container.destination_gate_out_actual | yearFormat }}
              </p>
              <p *ngIf="!container.destination_gate_out_actual">---</p>
            </div>
            <div
              class="col-md-4 col-6 mob-top"
              *ngIf="container.empty_returned_requested"
            >
              <h5>Empty Return Req. By</h5>
              <p *ngIf="container.empty_returned_requested">
                {{ container.empty_returned_requested | yearFormat }}
              </p>
              <p *ngIf="!container.empty_returned_requested">---</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
