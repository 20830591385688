import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-customs-pga-tab',
  templateUrl: './customs-pga-tab.component.html',
  styleUrls: ['./customs-pga-tab.component.scss'],
})
export class CustomsPgaTabComponent implements OnInit, OnChanges {
  @Input() selectedCustom: any = [];
  pgaSet: any = [];
  pgaTypeCode: any;
  pgaCategory: any = [];

  constructor(private appInsightsService: AppInsightsService) {}

  ngOnInit(): void {
    this.pgaSet =
      this.selectedCustom?.customs_declarations[0]?.us_customs_declaration?.customs_disposition_us;
    this.pgaSeparateCategory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.trackAIPgaTabView();
  }

  pgaSeparateCategory() {
    if (this.pgaSet) {
      let grp = this.pgaSet.reduce((group: any, product: any) => {
        const { pga_identifier } = product;
        group[pga_identifier] = group[pga_identifier] ?? [];
        group[pga_identifier].push(product);
        return group;
      }, {});

      for (const obj in grp) {
        if (obj != 'null') {
          this.pgaCategory.push({ category: obj, items: grp[obj] });
        }
      }
      if (this.pgaCategory.length > 0) {
        this.pgaCategory.forEach((ele: any) => {
          ele.items.sort((one: any, two: any) =>
            one.disposition_date > two.disposition_date ? -1 : 1
          );
        });
      }
    }
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }

  getYearFormattedDate(date: any) {
    let message = '---';
    if (date) {
      message = CommonUtils.formatDateMMMDDYYYY(date);
    }
    return message;
  }

  trackAIPgaTabView() {
    this.appInsightsService.logEvent(AI_CommonEvents.PGATabViewed.toString(), {
      [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
    });
  }
}
