<div class="oia-container">
    <app-progressbar [hidden]="!isProgressShow"></app-progressbar>
    <app-common-default-filter [ngClass]="{'fileUpload-visible-list':isCustomFilterShow}"
        *ngIf="!isDetailFullView && currentUserData" [defaultFilterSelectedValue]="defaultCustomsFilterSelectedValue"
        (onDefaultFilterSelectedHandler)="onDefaultFilterSelectedHandler($event)"
        [defaultFiltersList]="customsDefaultFiltersList" [currentPage]="'Customs'"
        (userFilterFetch)="userFilterFetch($event)" [savedFilters]="savedFiltersList"
        [savedUserFilterRowId]="savedFilterRow" (guidedTourStartManually)="guidedTourStartManually($event)">
    </app-common-default-filter>
    <div class="oia-content-wrap">
        <div class="oia-inner-wrap">
            <app-sucess-toast *ngIf="isShowMessageDel" [message]="showMessageDel"
                (undoDelete)="undoDeleteUserFilter($event)" class="my-profile-toast"></app-sucess-toast>
            <app-success-toast *ngIf="isShowMessage" [message]="showMessage" class="my-profile-toast">
            </app-success-toast>
            <app-customs-custom-filters [ngClass]="{'fileUpload-visible-list':isCustomFilterShow}"
                *ngIf="!isDetailFullView && currentUserData" (selectedFilterArray)="onSelectedCustomFilter($event)"
                (clearAllFilterValue)="clearAllFilterValue($event)" (clearSearchFilter)="clearSearchFilter($event)"
                (selectedMainFilterArray)="getMainFilterSelection($event)" [resultCount]="totalCustomsCount"
                [paramCheck]="filterCheck" (onCustomsSearchHandler)="onCustomsSearchHandler($event)"
                (locFilter)="onSelectedCustomFilter($event)"
                [defaultSortSelectedValue]="defaultCustomsSortSelectedValue"
                (selectedDateRange)="onSelectedCustomFilter($event)"
                [defaultSortDirection]="defaultCustomsSortDirection" [selectedDateRangeRender]="selectedDateRange"
                (onSortSelectedHandler)="onSortSelectedHandler($event)" (downloadExcel)="prepareExcelDownload($event)"
                [urlShare]="url" (prepareUrlShare)="prepareShareUrl($event)"
                (selectedUrlFilters)="getAllURLFilter($event)" [saveFilterJson]="saveFilterJson"
                (onUserFilterSaved)="getUserFilters($event)" [tempFilterDiff]="filterCheckTemp"
                [isfilterEdit]="myFilterEdit" (isLoading)="customFilterApiLoader($event)"
                [savedFilters]="savedFiltersList" (userFilterFetch)="userFilterFetch($event)"
                (selectedDefualtUrlFilters)="setUrlDefaultFilter($event)" [guidedTourFilter]="guidedTourCustomsFilter">
            </app-customs-custom-filters>
            <app-customs-list *ngIf="!isDetailFullView && currentUserData" [customsListData]="customsData"
                [totalCustomsCount]="totalCustomsCount" [isProgressShow]='isProgressShow' [offset]="page"
                (emitOffset)="getListingOffset($event)" [isInfiniteDisabled]="isInfiniteDisabled"
                [urlCustomsId]="urlCustomsId" [urlShare]="url" (prepareUrlShare)="prepareShareUrl($event)"
                [publicShareFilter]="publicShareFilter" [publicShareData]="publicShareData"
                [publicShareVisibility]="publicShareVisibility" [publicShareType]="publicShareType"
                [publicShareEnrcyptedData]="publicShareEnrcyptedData" [guidedTourDetail]="guidedTourCustomsDetail">
            </app-customs-list>
            <app-customs-details *ngIf="isDetailFullView && selectedCustomsLen > 0" [urlCustomsId]="urlCustomsId"
                [popupToggle]="popupToggle" [selectedCustom]="getCustomsDetails" (detailPopClose)="featureHide()"
                (prepareUrlShare)="detailShare($event)" (onRefreshDetailsCustoms)="onRefreshDetailFromDetail($event)"
                [publicShareEnrcyptedData]="publicShareEnrcyptedData" [urlShare]="url"></app-customs-details>
        </div>
    </div>
    <div class="outer-close" *ngIf="guidedFeatureManualModalShow">
        <div class="outer-close-set" (click)="gudideTourCloseset()"></div>
        <div class="rap-set need-help" *ngIf="guidedFeatureManualModalShow">
            <div class="rap-header">
                <h2>Need help?</h2>
                <p>Use this guided tour to get a walkthrough of the customs page or visit the FAQ page <a
                        (click)="navigateToFaq()">here</a>.</p>
                <div class="rap-footer">
                    <div class="left-sec"></div>
                    <div class="right-sec">
                        <div class="text-one">Start Guided Tour</div>
                        <img *ngIf="!isProgressShow" src="../../../../../assets/img/yellow-back.png" alt="guide-tour"
                            (click)="gudideTourStart()">
                        <img *ngIf="isProgressShow" src="../../../../../assets/img/yellow-back.png" alt="guide-tour">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rap-set" id="rapModal" *ngIf="guidedFeatureModalShow">
        <div class="rap-header">
            <h2>{{guidedTourPopupHeader}}</h2>
            <p>{{guidedTourPopupPara}}</p>
            <div class="rap-footer">
                <div class="left-sec" *ngIf="totalCustomsCount > 0">
                    {{guidedTourFlag}}/8</div>
                <div class="left-sec" *ngIf="totalCustomsCount < 1">{{guidedTourFlagNoShipment}}/5</div>
                <div class="right-sec">
                    <img src="../../../../../assets/img/arrow-guide.png"
                        *ngIf="(totalCustomsCount < 1 && guidedTourFlag >3) ||(totalCustomsCount > 0 && guidedTourFlag > 1)"
                        alt="guided tour previous" (click)="previousFeature()" class="roate-sec">
                    <img src="../../../../../assets/img/arrow-guide.png" alt="guided tour next" (click)="nextFeature()">
                </div>
            </div>
        </div>
        <div class="static-menu-shipment sort-by">
            <ul class="custom-drop-menu m-0">
                <li class="dropdown-item" *ngFor="let sortitems of sortBy"
                    [ngClass]="sortitems.item == 'Customs #' ? 'active' : ''">
                    <img *ngIf="sortitems.item == 'Customs #'" alt="desc"
                        src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                        class="mr-1 update">{{sortitems.item}}
                </li>
            </ul>
        </div>
    </div>
</div>
<div id="pop-up3" *ngIf="detailCustomsShare" class="dimScreen">
    <div *ngIf="detailCustomsShare" class="add-user-popup">
        <button class="close" type="button" (click)="sharePopUpfeatureHide()">
            <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
        </button>
        <div class="body-section fix-size">
            <h3>Share Customs</h3>
            <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" id="tabDetail">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                        type="button" role="tab" aria-controls="home" aria-selected="true">
                        <img src="../../../../../assets/img/share/url-img.svg" alt="" />
                        URL
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
                    aria-labelledby="home-tab">
                    <div class="discription">
                        <p class="text-des" *ngIf="detailCustomsShare">For users with a Connect account and access to
                            this company, they will be able to see the full customs details.
                            Otherwise, they will be able to see a public URL with only customs details.</p>
                        <div class="copy-section">
                            <input type="text" id="shareUrl" [value]="url" readonly />
                            <button class="add-type" (click)="copyLink()">COPY LINK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="guidedTourPopup" class="user-guide-list">
    <div class="user-card guide-tour">
        <div class="pt-3 pb-3 text-center">
            <img src="assets/img/oia-logo@3x.png" class="img-fluid" alt="logo" />
        </div>

        <p class="update-font pt-2 head-sec">Customs Module Now Available!</p>
        <p>We are pleased that OIA connect now allows you to view your customs entries!</p>
        <p class="pb-3">To help you onboard, we have created a guided tour that will highlight the features we think
            will be of most value to
            you!</p>
        <button [disabled]="isProgressShow" class="btn btn-primary yellow-button w-100 btn-lg mb-3 text-uppercase"
            (click)="startGuidedTour('first')">
            START GUIDED TOUR
        </button>
        <p class="text-center">
            <a (click)="skipTour()" class="link">SKIP AND CONTINUE</a>
        </p>

        <div class="img-update">
            <img src="assets/img/login/delivery-5-Converted@2x.png" class="img-fluid" alt="" />
        </div>
    </div>
</div>