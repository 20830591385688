import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
})
export class StatusIconComponent implements OnChanges {
  @Input() transport_mode: any = '';
  @Input() status: any = '';
  @Input() exception: any = '';
  @Input() module:string;

  ngOnChanges() {
    //console.log('status = ', this.transport_mode, this.status, this.exception);
  }
}
