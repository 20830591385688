import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customType'
})
export class CustomTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === "IMP") {
      return "Import"
    }
    else if (value === "EXP") {
      return "Export"
    }
    else {
      return value;
    }
  }

}
