<!-- Advanced Filter popup start -->
<form [formGroup]="advancedFilterForm">
  <div class="modal" id="advancedfilter" tabindex="-1" role="dialog" [hidden]="!showAdvancedFilterPopUp"
    aria-labelledby="advancedfilterset" aria-hidden="true" data-bs-backdrop='static' data-bs-keyboard="false"
    style="display:block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close" (click)="closeAdvFilterForm()">
          <span aria-hidden="true"><img src="../../assets/img/close-sm.png" alt="close" /></span>
        </button>
        <h5 class="modal-title" id="advancedfilterset">Advanced Filter</h5>
        <app-success-toast *ngIf="showAdvSaveMessage" [message]="displayMessage"
          class="my-profile-toast set-alignment-issue save-message"
          (triggerToastClose)="successToast(false)"></app-success-toast>
        <div class="text-center mt-5 pt-5 mobile-set-center dashboardShipmentWrapper" *ngIf="
        tableViewConfiguredList?.length==0">
          <p class="title-set mt-5 pt-5">
            No column mapping found for this account or group selected
          </p>
        </div>
        <div class="container-table" formArrayName="groups" cdkDropList *ngIf="tableViewConfiguredList?.length>0"
          (cdkDropListDropped)="onDropGroup($event)">
          <p class="info-text">In this view, show records</p>
          <div class="cover-scroll">
            <div class="dymanic-set" *ngFor="
                let group of groupField()?.controls;
                let groupIndex = index
              ">
              <div [formGroupName]="groupIndex" style="width: 100%" cdkDrag [cdkDragData]="groupIndex">
                <div formArrayName="conditions">
                  <div class="left-set">
                    <span *ngIf="groupIndex == 0">
                      <h6>Where</h6>
                    </span>
                    <span *ngIf="groupIndex !== 0">
                      <div class="dropdown mr-1 and-or left-set">
                        <button class="btn custom-arow change-and change-and-or" data-flip="false" type="button"
                          id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          formControlName="logicaloperator" (change)="changeConditionLogicalOperator($event)">
                          {{
                          advancedFilterForm.value.groups[groupIndex-1]
                          .logicaloperator
                          ? advancedFilterForm.value.groups[groupIndex-1]
                          .logicaloperator
                          : "select"
                          }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                          <a class="dropdown-item" *ngFor="let condition of logicalOperatorList" (click)="
                              logicalOperatorSelection(condition, groupIndex-1)
                            ">{{ condition }}</a>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="right-set" id="cdk-adv-list-{{ groupIndex }}" [cdkDropListConnectedTo]="connectedTo"
                    cdkDropList (cdkDropListDropped)="
                            onDynamicChildDrop($event, groupIndex)
                          ">
                    <div class="first-set-bg pr-1">
                      <img src="../../assets/img/add-one-set.svg" style="margin-right: 8px;"
                        (click)="AddCondition(groupIndex)" alt="add" />
                      <img src="../..//assets/img/delete.svg" style="width: 24px" (click)="deleteGroup(groupIndex)"
                        alt="del" />
                      <img src="../../assets/img/drag_icon.svg" style="margin-right: 3px;margin-left: 6px;" alt="drag"
                        cdkDragHandle [class.disable-drag]="
                          advancedFilterForm.value.groups?.length == 1
                        " class="move-btn" />
                    </div>
                    <div class="table-dynamic">
                      <table>
                        <caption class="d-none"></caption>
                        <th class="d-none"></th>
                        <tbody>
                          <tr *ngIf="
                              conditionsField(groupIndex)?.controls?.length == 0
                            " cdkDrag cdkDragPreview>
                            <td colspan="3" style="text-align: center">
                              No records found
                            </td>
                          </tr>
                          <tr *ngFor="
                              let condition of conditionsField(groupIndex)
                                ?.controls;
                              let conditionIndex = index
                            " cdkDrag [cdkDragData]="conditionIndex" cdkDragPreview>
                            <div class="table-input" [formGroupName]="conditionIndex">
                              <td>
                                <span *ngIf="conditionIndex == 0">
                                  <h6>Where</h6>
                                </span>
                                <span *ngIf="conditionIndex !== 0">
                                  <div class="dropdown mr-1 and-or first-set">
                                    <button class="btn custom-arow change-and" data-flip="false" type="button"
                                      id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true"
                                      aria-expanded="false" formControlName="logicaloperator" (change)="
                                        changeConditionLogicalOperator($event)
                                      ">
                                      {{advancedFilterForm.value.groups[groupIndex]?.conditions[conditionIndex-1]?.logicaloperator?
                                      advancedFilterForm.value.groups[groupIndex]?.conditions[conditionIndex-1]?.logicaloperator:
                                      'select'}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                      <a class="dropdown-item" *ngFor="
                                          let condition of logicalOperatorList
                                        " (click)="
                                          logicalOperatorSelection(
                                            condition,
                                            groupIndex,
                                            conditionIndex-1
                                          )
                                        ">{{ condition }}</a>
                                    </div>
                                  </div>
                                </span>
                              </td>
                              <td class="table-map-w">
                                <div class="btn-group dropdown new-design">
                                  <button type="button" id="dropdownMenuButtons-open-{{groupIndex}}-{{conditionIndex}}" data-toggle="dropdown"
                                    aria-haspopup="true" data-flip="false" aria-expanded="true"
                                    class="btn btn-secondary dropdown-toggle custom-menu custom-arow new-pop border-left-set"
                                    [class.is-invalid-border]="
                                      condition.get('hasColumnError')?.value
                                    " (click)="filterField(advancedFilterForm.value.groups[groupIndex]?.conditions[conditionIndex].searchField)">
                                    <span class="settings-filter text-capitalize"
                                      [ngClass]="{'change-font': advancedFilterForm.value.groups[groupIndex].conditions[conditionIndex].columnDisplayName}">
                                      {{condition.get('columnDisplayName')?.value?
                                      condition.get('columnDisplayName')?.value:
                                      'Select an option'}}
                                    </span>
                                  </button>
                                  <div class="dropdown-menu border-set-one">
                                    <div class="search-icon">
                                      <input type="text" [(ngModel)]="advancedFilterForm.value.groups[groupIndex]?.conditions[conditionIndex].searchField"
                                        [ngModelOptions]="{ standalone: true }" (keyup)="filterField(advancedFilterForm.value.groups[groupIndex]?.conditions[conditionIndex].searchField)" />
                                      <img *ngIf="advancedFilterForm.value.groups[groupIndex]?.conditions[conditionIndex].searchField!=''" src="../../assets/img/close-icon-small.svg" style="cursor:pointer" (click)="clearSearch($event, groupIndex, conditionIndex)" alt="del" />
                                    </div>
                                    <button class="dropdown-item" type="button" *ngFor="
                                        let list of tableViewConfiguredList
                                      " (click)="
                                        onFieldSelection(
                                          list,
                                          groupIndex,
                                          conditionIndex
                                        )
                                      ">
                                      {{ list?.displayName }}
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td class="table-map-w">
                                <div class="btn-group dropdown new-design">
                                  <button type="button" id="dropdownMenuButtons" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="true" data-flip="false"
                                    class="btn btn-secondary dropdown-toggle custom-menu custom-arow new-pop border-none"
                                    [disabled]="condition.get('operatorList')?.value?.length==0"
                                    [class.is-invalid-border]="
                                      condition.get('hasOperatorError')?.value
                                    ">
                                    <span class="settings-filter text-capitalize"
                                      [ngClass]="{'change-font': advancedFilterForm.value.groups[groupIndex].conditions[conditionIndex]?.operator}">
                                      {{condition.get('operator')?.value?
                                      condition.get('operator')?.value:
                                      'Select Values'}}
                                    </span>
                                  </button>
                                  <div class="dropdown-menu second-border-set" x-placement="bottom-start">
                                    <div class="search-icon d-none">
                                      <input type="text" [(ngModel)]="searchOperator"
                                        [ngModelOptions]="{ standalone: true }"
                                        (keyup)="filterOperator(searchOperator)" />
                                      <img src="../../assets/img/search.svg" alt="search-img" />
                                    </div>
                                    <button class="dropdown-item" type="button"
                                      *ngFor="let list of condition.get('operatorList')?.value" (click)="
                                        onOperatorSelection(
                                          list,
                                          groupIndex,
                                          conditionIndex
                                        )
                                      ">
                                      {{ list?.conditionCompare }}
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td class="table-map-w input-set-control">
                                <!--import value control here-->
                                <app-adv-filter-value-control [hasError]="
                                    condition.get('hasValueError')?.value
                                  " [groupIndex]="groupIndex" [conditionIndex]="conditionIndex"
                                  [selectedValue]="advancedFilterForm.value"
                                  (valueControl)="bindValueControl($event)"></app-adv-filter-value-control>
                                <p style="color:red; font-size: 13.5px;">
                                  {{condition.get('valueControlErrorMessage')?.value}}</p>
                              </td>
                              <td>
                                <div class="first-set">
                                  <img style="width: 24px" src="../..//assets/img/delete.svg" (click)="
                                      deleteCondition(
                                        groupIndex,
                                        conditionIndex
                                      )
                                    " alt="del" />
                                  <img cdkDragHandle src="../../assets/img/drag_icon.svg" style="margin-right: 10px"
                                    alt="drag" [class.disable-drag]="
                                      advancedFilterForm.value.groups?.length ==
                                        1 &&
                                      advancedFilterForm.value.groups[
                                        groupIndex
                                      ]?.conditions?.length == 1
                                    " class="move-btn" />
                                </div>
                              </td>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Group Start -->
            <div class="condition-group text-right mt-2" (click)="AddGroups()">
              <img src="../../assets/img/addcase.svg" class="mr-2" alt="add group" />Add Condition Group
            </div>
            <!-- Add Group End -->
          </div>
        </div>
        <!---->
        <!---->
        <!-- Button Nagivation Start  -->
        <div class="bottom-action">
          <button type="button" class="reset" (click)="resetAdvFilterForm()" [class.is-btn-disabled]="disableSaveAs"
            [disabled]="disableSaveAs">
            <img src="../../assets/img/reset.svg" class="mr-1" alt="reset" />
            Reset
          </button>
          <div class="bottom-action-right">
            <button type="button" class="save" [class.is-btn-disabled]="disableSaveAs" [disabled]="disableSaveAs"
              (click)="saveAs()">Save as</button>

            <button type="button" class="save save-btn" [class.is-btn-disabled]="disableSave" [disabled]="disableSave"
              *ngIf="isfilterEdit?.savedValue?.filterType?true:false || advancedFilterAdded?.length!=0"
              (click)="save(isfilterEdit?.savedValue?.filterType)">
              Save
            </button>
          </div>

        </div>
        <!-- Button Nagivation End  -->
      </div>
    </div>
  </div>
</form>

<!-- Advanced Filter popup end -->