import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppheaderComponent } from './components/appheader/appheader.component';

import { HeaderComponent } from './containers/header.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FilterComponent } from './components/filter/filter.component';
import { FilterService } from './services/filter.service';
import { SharedModule } from '../../shared/shared.module'
import { GlobalSearchModule } from '../global-search/global-search/global-search.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BroadCastService } from '../admin/broadcast/services/broadcast.service';
import { BroadcastMessageComponent } from './components/broadcast-message/broadcast-message.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}


@NgModule({
  declarations: [FilterComponent, HeaderComponent, AppheaderComponent, BroadcastMessageComponent],
  imports: [
    CommonModule,
    HttpClientModule, AppRoutingModule, SharedModule,
    FormsModule, ReactiveFormsModule, GlobalSearchModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [FilterComponent, HeaderComponent, AppheaderComponent],
  providers: [FilterService, BroadCastService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule { }
