import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AI_TabsName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { CustomsService } from '../../services/customs.service';

@Component({
  selector: 'app-customs-invoices-tab',
  templateUrl: './customs-invoices-tab.component.html',
  styleUrls: ['./customs-invoices-tab.component.scss'],
})
export class CustomsInvoicesTabComponent implements OnChanges {
  currentUserData: any;
  currentAccountData: any;
  isProgressShow = false;
  @Input() selectedCustom: any;

  constructor(
    private customsService: CustomsService,
    private currentSessionUserData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.trackAIInvoiceTabView();
    this.selectedCustom?.commercial_invoices?.forEach((ele: any) => {
      ele.commercial_invoice_lines?.sort((one: any, two: any) =>
        parseInt(one.declaration_line_numbers) >
        parseInt(two.declaration_line_numbers)
          ? 1
          : -1
      );
    });
  }

  prepareInvocieExcelDownload(customsId: any) {
    let getPatameter = this.prepareApiBodyParams(customsId);
    this.isProgressShow = true;
    this.customsService
      .prepareInvoiceExcelDownload(
        getPatameter.accountId,
        getPatameter.emailId,
        getPatameter.bodyParam,
        getPatameter.isGroup
      )
      .subscribe((res: Blob) => {
        this.downloadFile(res, customsId);
        this.isProgressShow = false;
      });
  }

  downloadFile(data: any, customsId: any) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    let today = new Date();
    let date =
      today.getFullYear() +
      CommonUtils.padding2(today.getMonth() + 1) +
      today.getDate();
    let time =
      CommonUtils.padding2(today.getHours()) +
      CommonUtils.padding2(today.getMinutes()) +
      CommonUtils.padding2(today.getSeconds());
    let dateTime = date + '_' + time;
    let filename =
      this.currentAccountData.accountAliasName +
      '_Customs Invoices_' +
      customsId +
      '_' +
      dateTime +
      '.xlsx';

    this.appInsightsService.trackAIFileDownloadEvent(
      AI_CommonEvents.DocumentDownload.toString(),
      this.selectedCustom?.forwarder_reference,
      AI_ModulesName.Custom,
      AI_TabsName.InvoiceTab,
      filename,
      ''
    );

    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
    URL.revokeObjectURL(url);
  }

  prepareApiBodyParams(customsId: any) {
    this.sessionUser();
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: [{ name: 'customsNumber', value: customsId }],
      sort_options: [],
      filter_options: [],
    };
    return { bodyParam, accountId, emailId, isGroup };
  }

  sessionUser() {
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }

  trackAIInvoiceTabView() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.InvoiceTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
  }
}
