<div *ngIf="isShow">
   <div  class="broadCast-msg"
      [ngClass]="{'broadCast-bg-color-dark': messageColor === 'Dark Background', 'broadCast-bg-color-yellow' : messageColor === 'Yellow Background' ,'broadCast-bg-color-light' : messageColor === 'Light Background' ,'broadCast-bg-color-digitalblue ' : messageColor === 'Digital Blue Background' }">
      <span class="broadCast-Text"
         [ngClass]="{'broadCast-Text-Large': textSize === 'Large Font', 'broadCast-Text-Small' : textSize === 'Small Font' }">{{message!}}
      <a *ngIf="linkTextEnable" style=" text-decoration:underline " href="javascript:void(0)"
         (click)="openLink()">{{this.linkText}}</a></span>
      <img *ngIf="closeButtonEnable" (click)="close()" class="broad-close broad-close-white" src="../../../../../../assets/img/icon_close_white.svg" alt=""/>
      <img *ngIf="closeButtonEnable" (click)="close()" class="broad-close broad-close-blue" src="../../../../../../assets/img/icon_close_blue.svg" alt="" />
   </div>
</div>