import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EdocUploadStatusService {
  fileUploadStatus_LocalStorage() {
    let fileUploadPercentage: any[] = [];
    let cancelledDocs: any[] = [];

    fileUploadPercentage = JSON.parse(
      localStorage.getItem('fileUploadStatus') || '[]'
    );

    fileUploadPercentage.some((el: any) => {
      if (
        (el.Percentage < 100 && !el.Error) ||
        (el.Error == 'Unable to upload due to network error.' &&
          el.Percentage != 100)
      ) {
        cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
      }
    });
    return cancelledDocs;
  }
}
