import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-success-toast',
  templateUrl: './success-toast.component.html',
  styleUrls: ['./success-toast.component.scss'],
})
export class SuccessToastComponent {
  @Input() message: any;
  closePopup: any;

  close() {
    this.closePopup = true;
  }
}
