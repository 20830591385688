import {
    Injectable
} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {
    Observable,
    of
} from 'rxjs';
import {
    tap
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable()
export class CacheInterceptorService implements HttpInterceptor {
    // private cache = new Map < string, any> ();
    private requiredUrls: any = [
        {
            url: environment.base_api_desktop_url +
                'NotificationPreference/GetTransportTypes',
        },
        {
            url: environment.base_api_desktop_url +
                'NotificationPreference/GetContainerTypes',
        },
        {
            url: environment.base_api_desktop_url +
                'DataDefinition/GetControlType'
        },
        {
            url: environment.base_api_desktop_url +
                'WorkFlow/GetJSONorDataDefinition'
        }];

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<
            HttpEvent<any>> {
        if (!this.isUrlExist(this.getBaseUrl(req))) {
            return next.handle(req);
        }
        if (this.ignoreDfdCollection(req)) {
            return next.handle(req);
        }

        let cachedResponse: any = localStorage.getItem(req.url);//this.cache.get(req.url);
        if (cachedResponse) {
            return this.executeHttp(cachedResponse, req);
        }
        else if (this.ignoreIsArrayElementCollection(req)) {
            cachedResponse = localStorage.getItem(req.url.replace("&isArray=false", "&isArray=true"))
            return this.executeHttp(cachedResponse, req);
        }
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    //this.cache.set(req.url, event);
                    if (event?.body?.result?.length > 0) { // ignore if result is empty
                        localStorage.setItem(req.url, JSON.stringify(event))
                    }
                }
            })
        );
    }

    private isUrlExist(url: string): boolean {
        return this.requiredUrls.some((e: any) => e?.url?.trim() == url?.trim());
    }

    private getBaseUrl(req: any) {
        try {
            const urlObj = new URL(req?.url);
            return urlObj?.origin + urlObj?.pathname;
        }
        catch (e: any) {
            return "not/exist";
        }
    }

    executeHttp(cachedResponse: any, req: any) {
        if (this.isJsonCollection(req)) {
            let response = JSON.parse(cachedResponse);
            response.body.result = this.filterJsonCollection(response.body.result, req.body.search_text, req.body.filter_options, req.url);
            if (this.ignoreIsArrayElementCollection(req)) {
                response.url = req.url.replace("&isArray=true", "&isArray=false");
            }
            return of(new HttpResponse(response));
        }
        else {
            return of(new HttpResponse(JSON.parse(cachedResponse)));
        }
    }

    isJsonCollection(req: any) {
        return this.getBaseUrl(req) == environment.base_api_desktop_url +
            'WorkFlow/GetJSONorDataDefinition' && req.body.parameters[0].value.trim() == 'true';
    }

    ignoreIsArrayElementCollection(req: any) {
        return this.getBaseUrl(req) == environment.base_api_desktop_url +
            'WorkFlow/GetJSONorDataDefinition' && req.url.includes("&isArray=false");
    }

    ignoreDfdCollection(req: any) {
        return this.getBaseUrl(req) == environment.base_api_desktop_url +
            'WorkFlow/GetJSONorDataDefinition' && req.body.parameters[0].value.trim() == 'false';
    }

    filterJsonCollection(cachedResponse: any, searchText: string, filterOptions: any, url: any) {
        let modules = filterOptions[0]?.value?.split(",");
        if (modules) {
            modules = modules.map((e: any) => e.trim());
        }
        const staticModule = ['shipments', 'bookings', 'customs'];
        if (!staticModule.some((e: any) => modules?.includes(e))) {
            modules = staticModule;
        }

        let arrayRequired = true;
        if (url.includes("&isArray=false")) {
            arrayRequired = false;
        }
        return cachedResponse.filter((e: any) => e.aliasName.includes(searchText) &&
            modules.includes(e.module) && (arrayRequired || e.elementType != 1))
    }
}