import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vesselVoyageText'
})
export class VesselVoyageTextPipe implements PipeTransform {

  transform(voyage_number: any, vessel_name: any): unknown {
    let htmlString = '---';
    if (!voyage_number && !vessel_name) return htmlString;

    if (voyage_number && vessel_name) {
      htmlString = vessel_name + ' / ' + voyage_number;
    } else if (vessel_name) {
      htmlString = vessel_name;
    } else {
      htmlString = voyage_number;
    }
    return htmlString;
  }

}
