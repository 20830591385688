import {
  Component,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtils } from 'src/app/common/utils';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';
import { BookingServies } from '../../services/booking.service';
import { Location } from '@angular/common';
import { BookingCustomFiltersComponent } from '../../components/booking-custom-filters/booking-custom-filters.component';
import { FilterService } from 'src/app/features/header/services/filter.service';
import { SwitchAccountService } from 'src/app/shared/services/switch-account.service';
import {
  AppInsightsService,
  AI_PageName,
  AI_ModulesName,
} from 'src/app/services/appinsights.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { MyGroupService } from 'src/app/features/profile/my-groups/services/my-group.service';
import { UserManagmentService } from 'src/app/features/admin/users/services/usermanagment.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { OC3Constants } from 'src/app/common/oc3-constants';
declare var $: any;
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit {
  bookingMasterData: any[] = [];
  totalBookingCount: number = 0;
  bookingSortOptions: any = [];
  currentUserData: any;
  currentAccountData: any;
  defaultFilterSelectedValue: any = 0;
  defaultFilterSelectedText: any = 'All Bookings';
  combinedSelectedFiltersArray: any[] = [];
  page = 0;
  limit = OC3Constants.BOOKING_LIST_DEFAULT_COUNT;
  bookingSearchText: string = '';
  defaultBookingSortSelectedColumn: string = 'booking_confirmed_actual';
  defaultBookingSortDirection: string = 'desc';
  defaultBookingSortSelectedValue: number = 1;
  isProgressShow: boolean = false;
  isUserHaveAcc: any;
  errorMessage: string = '';
  hasPermission: boolean = true;
  statusFilterSelectedArray: any = [];
  originFilterSelectedArray = [];
  destinationFilterSelectedArray = [];
  shipperFilterSelectedArray = [];
  pickupFilterSelectedArray = [];
  finalDeliveryFilterSelectedArray = [];
  transportTypeFilterSelectedArray = [];
  dateRangeFilterSelectedArray = [];
  mainFilterSelectedArray: any[] = [];
  defaultFilterArray = [];
  selectedOriginAccount: any = [];
  selectedDestinationAccount: any = [];
  selectedShipperAccount: any = [];
  selectedPickupAccount: any = [];
  selectedfinalDeliveryAccount: any = [];
  watchListFilterSelectedArray: any = [];
  selectedDateRange = [];
  resentList: any = [];
  url = '';
  saveFilterJson: any = '';
  myFilterEdit: any = [];
  isShowMessage = false;
  showMessage: any = '';
  isShowMessageDel = false;
  showMessageDel: any = '';
  savedFilterRow = '';
  savedFiltersList: any[] = [];
  filterCheck: any;
  filterCheckTemp: any;
  selectedBookingLen = 0;
  @ViewChild(BookingCustomFiltersComponent)
  customFilterComponent!: BookingCustomFiltersComponent;
  publicShareEnrcyptedData: any;
  isGroupShare: any;
  urlBookingId: any;
  publicShareFilter: any;
  getBookingDetails: any = [];
  isDetailFullView = false;
  popupToggle = false;
  singleBookingShare = false;
  detailBookingShare = false;
  popupToggleList = false;
  copyText: any = '';
  bookingSharePopup = false;
  isInfiniteDisabled = false;
  isCustomFilterShow = false;
  apiCall: any = null;
  sortBy = [
    { item: 'Booked Date' },
    { item: 'Booking #' },
    { item: 'Status' },
    { item: 'Est. Pickup' },
    { item: 'ETD' },
    { item: 'ETA' },
    { item: 'Est. Delivery' },
  ];
  guidedTourJson = [
    {
      heading: 'Bookings List',
      para: 'View all your bookings here with the Booking Number, Status and an overview of the shipment route.',
    },
    {
      heading: 'Navigate to Shipment',
      para: 'If the booking has been converted to a shipment, you can navigate to the shipment details using this button.',
    },
    {
      heading: 'Quick Filters',
      para: 'Refine your booking list using these default filters based on the booking status to make browsing easier.',
    },
    {
      heading: 'Filters',
      para: 'Still need to filter your booking list further? Use any of these filter options to discover the bookings you need to find.',
    },
    {
      heading: 'Saved Filters',
      para: 'After applying filters to the booking list, you can save the filter criteria so that you can easily filter based on criteria defined by you.',
    },
    {
      heading: 'Quick Search',
      para: 'Need to find a booking? Use this search bar with either the booking number or reference number to refine the booking list. Place commas between numbers to search for multiple bookings at a time.',
    },
    {
      heading: 'Booking Sort',
      para: 'Sort the booking list in ascending or descending order based on these criteria.  Click the same sort type again to alternate the sort order.',
    },
    {
      heading: 'Booking Details',
      para: 'Click on a booking in the booking list screen to view the details and documents related to the booking.',
    },
  ];
  guidedTourPopup = false;
  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  guidedTourFlag: any;
  guidedTourFlagNoShipment: any;
  guidedTourBookingContext = false;
  guidedTourBookingFilter = false;
  guidedTourBookingDetail: any = '';
  guidedTourBookingSort = false;
  guidedFeatureModalShow = false;
  guidedFeatureManualModalShow = false;
  guidedTourPopupHeader: any;
  guidedTourPopupPara: any;
  guidedTourSlides: any;
  guidedTourIsConvertedBooking: any[] = [];
  bookingDefaultFiltersList = [
    {
      name: 'All Bookings',
      value: 0,
    },
    {
      name: 'Pending',
      value: 1,
    },
    {
      name: 'Converted',
      value: 2,
    },
    {
      name: 'Canceled',
      value: 3,
    },
    {
      name: 'Archived',
      value: 4,
    },
  ];
  private titleService: Title;
  private bookingServies: BookingServies;
  private currentSessionUserData: CurrentSessionDataService;
  private activatedRoute: ActivatedRoute;
  private router: Router;
  private location: Location;
  private filterService: FilterService;
  private switchAccount: SwitchAccountService;
  private appInsightsService: AppInsightsService;
  private fileUploadService: FileUploadService;
  private myGroupService: MyGroupService;
  private userService: UserManagmentService;
  private dataSharingService: DataSharingService;
  private breakpointObserver: BreakpointObserver;

  constructor(private injector: Injector) {
    this.titleService = injector.get<Title>(Title);
    this.bookingServies = injector.get<BookingServies>(BookingServies);
    this.currentSessionUserData = injector.get<CurrentSessionDataService>(
      CurrentSessionDataService
    );
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = injector.get<Router>(Router);
    this.location = injector.get<Location>(Location);
    this.appInsightsService =
      injector.get<AppInsightsService>(AppInsightsService);
    this.filterService = injector.get<FilterService>(FilterService);
    this.switchAccount =
      injector.get<SwitchAccountService>(SwitchAccountService);
    this.myGroupService = injector.get<MyGroupService>(MyGroupService);
    this.userService = injector.get<UserManagmentService>(UserManagmentService);
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.fileUploadService = injector.get<FileUploadService>(FileUploadService);
    this.breakpointObserver =
      injector.get<BreakpointObserver>(BreakpointObserver);
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
    this.dataSharingService =
      injector.get<DataSharingService>(DataSharingService);
    this.fileUploadService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        let fileUploadPercentage: any[] = [];
        let cancelledDocs: any[] = [];
        setTimeout(() => {
          fileUploadPercentage = res;
          fileUploadPercentage.some((el: any) => {
            if (el.Percentage < 100 && !el.Error) {
              cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
            }
          });
          if (cancelledDocs.length > 0) {
            this.isCustomFilterShow = true;
          } else {
            this.isCustomFilterShow = false;
          }
        }, 0);
      }
    });
    this.breakpointObserver
      .observe(['(min-height: 1100px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.limit = OC3Constants.BOOKING_LIST_DEFAULT_COUNT + 10;
        } else {
          this.limit = OC3Constants.BOOKING_LIST_DEFAULT_COUNT;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
  }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  /* istanbul ignore next */
  loadInitialPageData() {
    let isPublicView = false;
    if (this.currentUserData) {
      this.getUserFilters({ message: '', type: 'start' });
    }

    this.showPageTitle();
    this.loadAllBookingSortOptions();
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
    let encryptedFilter = this.activatedRoute.snapshot.params['filterParam'];
    let encrypFilterDashboard =
      this.activatedRoute.snapshot.queryParams['filterParam'];

    if (encryptedFilter) {
      this.encryptData(encryptedFilter);
    } else if (encrypFilterDashboard) {
      this.encryptData(encrypFilterDashboard);
    } else {
      if (this.currentUserData) {
        this.loadAllBookings();
        if (!this.isMobileView) {
          this.getUserDetailGuidedTour();
        }
      } else {
        this.dataSharingService.quickSearch.subscribe((value) => {
          if (value.type == 'Detail') {
            isPublicView = true;
            this.publicSearchFn(value);
          } else {
            this.router.navigate(['/login/log-in']);
          }
        });
      }
    }
    if (this.isDetailFullView) {
      this.fileUploadService.reloadBookings.subscribe((value) => {
        if (value.reload) {
          this.selectedListBooking(value.shipmentId);
        }
      });
    }
    if (this.currentAccountData) {
      if (!this.currentAccountData.bookingIsVisible && encryptedFilter) {
        let decryUrlFilter = JSON.parse(atob(encryptedFilter));
        if (decryUrlFilter.type == 'Detail') {
          this.router.navigate(['/dashboard/' + encryptedFilter]);
        }
      } else if (
        !this.currentAccountData.bookingIsVisible &&
        !encryptedFilter
      ) {
        this.router.navigate(['/dashboard']);
      }
    }
    this.trackAIPageView(isPublicView);
    this.setLeftSlideIconClick();
    this.bookingServies.isFullScreenTabEnabled = this.isDetailFullView;
  }

  /* istanbul ignore next */
  setLeftSlideIconClick() {
    $(document).ready(function () {
      $('#left-slide-icon').click(function () {
        let hidden = $('.filter-nav');
        if (hidden.hasClass('visible')) {
          $('.oia-container').addClass('nav-toggle');
          $('.oia-content-wrap').animate(
            { 'margin-left': '15px', width: '100%', height: '100%' },
            600
          );
        } else {
          $('.oia-container').removeClass('nav-toggle');
          $('.oia-content-wrap').animate(
            { 'margin-left': '200px', width: '100%', height: '100%' },
            600
          );
        }
      });
    });
  }

  /* istanbul ignore next */
  encryptData(encryptedFilter: any) {
    if (encryptedFilter) {
      let decryUrlFilter = JSON.parse(atob(encryptedFilter));
      if (decryUrlFilter.type == 'Single' && this.currentUserData) {
        this.isDetailFullView = true;
        this.location.replaceState('/bookings');
        if (this.currentUserData.userType == 'user') {
          this.switchAccountUser(decryUrlFilter, encryptedFilter);
        } else if (this.currentUserData.userType == 'systemadmin') {
          this.switchAccountAdmin(decryUrlFilter, encryptedFilter);
        }
        this.singleShareApply(decryUrlFilter);
      } else if (decryUrlFilter.type == 'All' && this.currentUserData) {
        this.bookingServies
          .getShareFilter(decryUrlFilter.id)
          .subscribe((res: any) => {
            let decryptFilter = JSON.parse(res.result.jsonFilter);
            if (decryptFilter?.statusFilter[0]?.value?.length === 1) {
              this.defaultFilterSelectedValue =
                decryptFilter?.statusFilter[0]?.value;
            }
            if (decryptFilter.accountDetail.byGroup) {
              this.isProgressShow = true;
            }
            this.location.replaceState('/bookings');
            this.setUrlFilters(decryptFilter, encryptedFilter);
          });
      } else if (
        (decryUrlFilter.type == 'Detail' &&
          decryUrlFilter.type != 'Single' &&
          decryUrlFilter.type != 'All') ||
        this.currentUserData
      ) {
        this.location.replaceState('/bookings');
        this.isProgressShow = true;
        if (decryUrlFilter.type == 'Detail') {
          this.isDetailFullView = true;
        }
        this.setUrlFilters(decryUrlFilter, encryptedFilter);
        this.publicShareEnrcyptedData = encryptedFilter;
      } else {
        this.router.navigate(['/login/log-in'], {
          queryParams: { returnUrl: '/bookings/' + encryptedFilter },
        });
      }
    }
  }

  singleShareApply(urlFilter: any) {
    this.selectedListBooking(urlFilter.bookingId);

    setTimeout(() => {
      this.popupToggle = true;
    }, 0);
    this.urlBookingId = urlFilter.bookingId;
  }

  selectedListBooking(bookingId: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.bookingServies
      .getBookingDetails(accountId, emailId, bookingId, isGroup)
      .subscribe({
        next: (getDetails: any) => {
          this.isProgressShow = false;
          this.getBookingDetails = getDetails.result[0];
          this.selectedBookingLen = getDetails.result.length;
        },
      });
  }

  setUrlFilters(filters: any, encryptedFilter: any) {
    if (
      this.currentUserData.userType == 'user' &&
      filters.type != 'defaultFilter'
    ) {
      this.switchAccountUser(filters, encryptedFilter);
    } else if (
      this.currentUserData.userType == 'systemadmin' &&
      filters.type != 'defaultFilter'
    ) {
      this.switchAccountAdmin(filters, encryptedFilter);
    } else if (filters.type == 'defaultFilter') {
      this.urlFilterCall(filters);
    } else {
      this.isGroupShare = filters.accountDetail.isGroup;
      this.urlFilterCall(filters);
    }
  }

  showPageTitle() {
    CommonUtils.setPageTitle(this.titleService, 'Bookings');
  }
  /* istanbul ignore next */
  urlFilterCall(filters: any) {
    if (
      filters.type != 'Detail' &&
      filters.type != 'Single' &&
      filters.type != 'All'
    ) {
      setTimeout(() => {
        this.customFilterComponent.loadURLFilters(filters);
      }, 300);
    }
    this.urlFilterSort(filters);
    if (filters.mainFilter.length < 1 && filters.type == 'defaultFilter') {
      this.urlBookingId = filters.bookingId;
      this.bookingSearchText = filters.bookingId;
      this.loadAllBookings();
    }
    if (filters.type == 'Single') {
      this.urlBookingId = filters.bookingId;
    }
    if (filters.type == 'Detail' && filters.subType != 'publicSearch') {
      this.publicShareFilter = filters;
      let getPatameter = this.prepareBookingApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'shipmentNumber',
            value: filters.bookingId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultBookingSortSelectedColumn,
            direction: this.defaultBookingSortDirection,
          },
        ],
        filter_options: [],
      };
      this.bookingServies
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.isProgressShow = false;
          if (res.result[0].isFullViewAccess) {
            this.selectedListBooking(filters.bookingId);
            setTimeout(() => {
              this.popupToggle = true;
              setTimeout(() => {
                this.urlBookingId = filters.bookingId;
                this.selectedBookingLen = Object.keys(
                  this.getBookingDetails
                ).length;
              }, 0);
            }, 0);
          } else {
            setTimeout(() => {
              this.popupToggle = true;
              this.getBookingDetails = res.result[0];
              this.selectedBookingLen = Object.keys(
                this.getBookingDetails
              ).length;
            }, 0);
            this.urlBookingId = filters.bookingId;
          }
        });
    } else if (filters.type == 'Detail' && filters.subType == 'publicSearch') {
      this.publicShareFilter = filters;
      this.bookingPublicSearchShare('url');
    } else if (filters.type == 'All') {
      this.setGroupUrlFilterCriteria(filters);
    }
  }

  urlFilterSort(filters: any) {
    let len = 0;

    if (filters && filters?.sorting) {
      let keys = Object.keys(filters?.sorting);
      len = keys?.length;
    }

    if (len > 0) {
      this.defaultBookingSortSelectedColumn = filters.sorting
        ? filters.sorting.selectedValue.column_name
        : 'first_leg_departure_actual';
      this.defaultBookingSortDirection = filters.sorting
        ? filters.sorting.selectedValue.direction
        : 'desc';
      this.defaultBookingSortSelectedValue = filters.sorting
        ? filters.sorting.sortId
        : 6;
    }
  }
  /* istanbul ignore next */
  switchAccountUser(filters: any, encryptedFilter: any) {
    if (filters.accountDetail?.oH_Code && !filters.accountDetail?.byGroup) {
      this.filterService
        .getData(
          environment.base_api_desktop_url +
            'useraccount?userEmailAddresss=' +
            this.currentUserData.emailAddress
        )
        .subscribe((res) => {
          this.isUserHaveAcc = res.result
            .map(function (x: any) {
              return x.oH_Code;
            })
            .indexOf(filters.accountDetail.oH_Code);
          if (this.isUserHaveAcc >= 0) {
            const isSwitchAccount = this.switchAccount.switchAccount(
              filters.accountDetail,
              'shareUrlBooking',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              if (filters.type == 'All') {
                this.urlFilterSort(filters);
                setTimeout(() => {
                  this.customFilterComponent.loadURLFilters(filters);
                }, 300);
              } else {
                this.urlFilterCall(filters);
              }
            }
          } else {
            if (
              filters.type == 'All' &&
              filters.accountDetail?.isControllingCustomer
            ) {
              this.publicShareLimitedApiUser(filters, encryptedFilter);
            } else if (filters.type == 'Detail') {
              this.urlFilterCall(filters);
            }
          }
        });
    } else if (filters.accountDetail?.byGroup) {
      this.publicShareLimitedApiUser(filters, encryptedFilter);
    } else {
      this.urlFilterCall(filters);
    }
  }
  /* istanbul ignore next */
  switchAccountAdmin(filters: any, encryptedFilter: any) {
    if (filters.accountDetail?.oH_Code && !filters.accountDetail?.byGroup) {
      const switchAcc = this.switchAccount.switchAccount(
        filters.accountDetail,
        'shareUrlBooking',
        encryptedFilter
      );
      if (!switchAcc) {
        if (filters.type == 'All') {
          this.urlFilterSort(filters);
          setTimeout(() => {
            this.customFilterComponent.loadURLFilters(filters);
          }, 300);
        } else {
          this.urlFilterCall(filters);
        }
      }
    } else if (filters.accountDetail?.byGroup) {
      this.publicShareLimitedApiAdmin(filters, encryptedFilter);
    } else {
      this.urlFilterCall(filters);
    }
  }

  loadAllBookingSortOptions() {
    this.bookingSortOptions = [
      {
        id: 1,
        name: 'Booked Date',
        sort_column: 'booking_confirmed_actual',
        sort_direction: 'asc',
      },
      {
        id: 2,
        name: 'Booking #',
        sort_column: 'forwarder_reference',
        sort_direction: 'asc',
      },
      {
        id: 3,
        name: 'Status',
        sort_column: 'status',
        sort_direction: 'asc',
      },
      {
        id: 4,
        name: 'Est. Pickup',
        sort_column: 'origin_pickup_estimated',
        sort_direction: 'asc',
      },
      {
        id: 5,
        name: 'ETD',
        sort_column: 'departure_estimated',
        sort_direction: 'asc',
      },
      {
        id: 6,
        name: 'ETA',
        sort_column: 'arrival_port_estimated',
        sort_direction: 'asc',
      },
      {
        id: 7,
        name: 'Est. Delivery',
        sort_column: 'delivery_estimated',
        sort_direction: 'asc',
      },
    ];
  }

  loadAllBookings() {
    let getPatameter = this.prepareBookingApiBodyParams();
    this.filterCheck = getPatameter.bodyParam;
    if (getPatameter.bodyParam.offset <= 0) {
      this.totalBookingCount = 0;
      this.isInfiniteDisabled = true;
      setTimeout(() => {
        this.isInfiniteDisabled = false;
      }, 200);
      this.apiCancel();
    }
    this.isProgressShow = true;
    this.apiCall = this.bookingServies
      .getAllBookings(
        getPatameter.accountId,
        getPatameter.emailId,
        getPatameter.bodyParam,
        getPatameter.isGroup
      )
      .subscribe(
        (res) => {
          if (this.isUserHaveAcc < 0) {
            this.totalBookingCount = 0;
            this.bookingMasterData = [];
            this.isProgressShow = false;
          } else {
            this.totalBookingCount = res?.total_count;
            if (res?.result?.includes('does not have persmission')) {
              this.bookingMasterData = [];
              this.hasPermission = false;
              this.errorMessage =
                'You do not have a permission to access this account. Please select an another account!';
            } else {
              this.totalBookingCount = res?.total_count;
              this.bookingMasterData = [
                ...this.bookingMasterData,
                ...res?.result,
              ];
              this.hasPermission = true;
              this.errorMessage = '';
            }
            this.isProgressShow = false;
            setTimeout(() => {
              this.guidedTourIsConvertedBooking = this.bookingMasterData.filter(
                function (el: any) {
                  return el.status == 'Converted';
                }
              );
            }, 0);
          }
        },
        (error) => {
          this.isProgressShow = false;
        }
      );
  }

  prepareBookingApiBodyParams() {
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let filterOption = [];
    if (this.combinedSelectedFiltersArray.length > 0) {
      filterOption = this.combinedSelectedFiltersArray;
    }

    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: this.bookingSearchText,
      parameters: [],
      sort_options: [
        {
          column_name: this.defaultBookingSortSelectedColumn,
          direction: this.defaultBookingSortDirection,
        },
      ],
      filter_options: filterOption,
    };

    return { bodyParam, accountId, emailId, isGroup };
  }

  clearBookingList() {
    this.page = 0;
  }

  onDefaultFilterSelectedHandler(event: any) {
    this.defaultFilterSelectedValue = event?.value;
    this.defaultFilterSelectedText = event?.name;
    this.bookingSearchText = '';
    this.clearBookingList();

    if (this.myFilterEdit?.isEditTrue) {
      this.customFilterComponent.clearAllFilters();
      this.myFilterEdit = {
        savedValue: '',
        isEditTrue: false,
      };
      this.combinedSelectedFiltersArray = [];
      this.statusFilterSelectedArray = [];
      this.transportTypeFilterSelectedArray = [];
      this.originFilterSelectedArray = [];
      this.destinationFilterSelectedArray = [];
      this.dateRangeFilterSelectedArray = [];
      this.watchListFilterSelectedArray = [];
      this.mainFilterSelectedArray = [];
      this.bookingSearchText = '';
      this.defaultFilterArray = [];
      this.shipperFilterSelectedArray = [];
      this.finalDeliveryFilterSelectedArray = [];
      this.pickupFilterSelectedArray = [];
      this.defaultFilterSelectedValue = 0;
      this.selectedDestinationAccount = [];
      this.selectedOriginAccount = [];
      this.selectedPickupAccount = [];
      this.selectedShipperAccount = [];
      this.selectedfinalDeliveryAccount = [];
    }
    this.mainFilterSelectedArray = [
      { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 1 },
    ];
    this.bookingSearchText = '';
    this.defaultFilterSelectedValue = event?.value;
    this.defaultFilterSelectedText = event?.name;
    let selectedFilters = {
      mainFilter: [
        { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 1 },
      ],
      statusFilter: [
        {
          column_name: 'statuscustom',
          operator: '=',
          value: event.value.toString(),
        },
      ],
      transportType: [],
      watchFilter: [],
      origin: {
        selectedValue: [],
        selectedAccount: [],
      },
      destination: {
        selectedValue: [],
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },

      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: [] },
          { destinationValue: [] },
          { pickupValue: [] },
          { finalDeliveryValue: [] },
        ],
      },
      sorting: [],
      accountId: '',
      bookingId: '',
      type: 'defaultFilter',
    };
    if (this.statusFilterSelectedArray.length > 0) {
      this.setCloseButtonTimeout();
    } else {
      this.setCloseButtonTimeoutWithoutCallApi();
    }
    setTimeout(() => {
      if (event?.value > 0) {
        this.setUrlFilters(selectedFilters, false);
      }
    }, 100);
    setTimeout(() => {
      let test: any[] = [];
      if (event?.value > 0) {
        test.push({
          column_name: 'statuscustom',
          operator: '=',
          value: event?.value.toString(),
        });
      }
      let statusSelect: any = '';
      if (event?.value > 0) {
        statusSelect = { type: 'statuscustom', selectedValue: test };

        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      } else if (event?.value == 0) {
        statusSelect = { type: 'statuscustom', selectedValue: [] };
        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      }
      this.onSelectedCustomFilter(statusSelect);
    }, 1000);
  }

  setCloseButtonTimeout() {
    setTimeout(() => {
      this.customFilterComponent.closeCustomFilters({
        type: 'statuscustom',
        index: 1,
        id: 1,
        eventType: 'closeBtn',
        closeFrom: 'defaultFilter',
      });
    }, 100);
  }

  setCloseButtonTimeoutWithoutCallApi() {
    setTimeout(() => {
      this.customFilterComponent.closeCustomFilters({
        type: 'statuscustom',
        index: 1,
        id: 1,
        eventType: 'closeBtn',
        closeFrom: 'clearFilterOnly',
      });
    }, 100);
  }

  onSortSelectedHandler(event: any) {
    this.defaultBookingSortSelectedValue = event.id;
    this.defaultBookingSortSelectedColumn = event.sort_column;
    this.defaultBookingSortDirection = event.sort_direction;
    this.clearBookingList();
    this.bookingMasterData = [];
    this.loadAllBookings();
  }

  getMainFilterSelection(event: any) {
    let selectedId: any[] = [];
    event.forEach((element: any) => {
      selectedId.push({
        id: element.id,
        filterDisplayName: element.filterDisplayName,
        type: element.type,
        index: element.index,
      });
    });
    this.mainFilterSelectedArray = selectedId;
  }

  onSelectedCustomFilter(event: any) {
    this.combinedSelectedFiltersArray = [];
    if (event) {
      if (event.type == 'statuscustom') {
        this.statusFilterSelectedArray = event.selectedValue;
        let statusChecked =
          event.selectedValue.length > 0
            ? event.selectedValue[0].value.split(',')
            : '';
        if (statusChecked.length == 1) {
          if (this.defaultFilterSelectedValue !== statusChecked[0]) {
            this.defaultFilterSelectedValue = parseInt(statusChecked[0]);
          }
        } else if (
          ((!statusChecked || statusChecked.length == 0) &&
            event.clearFrom !== 'defaultFilter') ||
          statusChecked.length > 0
        ) {
          this.defaultFilterSelectedValue = 0;
        }
      } else if (event.type == 'transport') {
        this.transportTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'Origin') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'origin';
          this.originFilterSelectedArray = event.selectedValue;
        }
        this.selectedOriginAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let originJsonGet = sessionStorage.getItem('BookingRecentOrgin');
          this.filterRecentSearch(originJsonGet, event);
        }
      } else if (event.type == 'Destination') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'destination';
          this.destinationFilterSelectedArray = event.selectedValue;
        }
        this.selectedDestinationAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let destinationJsonGet = sessionStorage.getItem(
            'BookingRecentDestination'
          );
          this.filterRecentSearch(destinationJsonGet, event);
        }
      } else if (event.type == 'shipper') {
        this.shipperFilterSelectedArray = event.selectedValue;
        this.selectedShipperAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let shipperJsonGet = sessionStorage.getItem('BookingRecentShipper');
          this.filterRecentSearch(shipperJsonGet, event);
        }
      } else if (event.type == 'Final Delivery') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'delivery';
          this.finalDeliveryFilterSelectedArray = event.selectedValue;
        }
        this.selectedfinalDeliveryAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let finalDeliveryJsonGet = sessionStorage.getItem(
            'BookingRecentFinalDelivery'
          );
          this.filterRecentSearch(finalDeliveryJsonGet, event);
        }
      } else if (event.type == 'Pickup') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'pickuplocation';
          this.pickupFilterSelectedArray = event.selectedValue;
        }
        this.selectedPickupAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let pickupJsonGet = sessionStorage.getItem('BookingRecentPickup');
          this.filterRecentSearch(pickupJsonGet, event);
        }
      } else if (event.type == 'dateRange') {
        this.dateRangeFilterSelectedArray = event.selectedValue;
        this.selectedDateRange = event.selectedDateRange;
      } else if (event.type == 'favorites') {
        this.watchListFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'allLocation') {
        this.finalDeliveryFilterSelectedArray = [];
        this.pickupFilterSelectedArray = [];
        this.originFilterSelectedArray = [];
        this.destinationFilterSelectedArray = [];
        this.selectedfinalDeliveryAccount = [];
        this.selectedPickupAccount = [];
        this.selectedOriginAccount = [];
        this.selectedDestinationAccount = [];
      }

      this.combinedSelectedFiltersArray = [].concat(
        this.statusFilterSelectedArray,
        this.transportTypeFilterSelectedArray,
        this.originFilterSelectedArray,
        this.destinationFilterSelectedArray,
        this.dateRangeFilterSelectedArray,
        this.watchListFilterSelectedArray,
        this.defaultFilterArray,
        this.shipperFilterSelectedArray,
        this.finalDeliveryFilterSelectedArray,
        this.pickupFilterSelectedArray
      );
    } else {
      this.combinedSelectedFiltersArray = [];
    }
    if (event?.clearFrom != 'defaultFilter') {
      this.clearData();
      this.loadAllBookings();
      if (event?.selectedValue?.length == 0) {
        this.defaultFilterSelectedValue = 0;
      }
    }
  }

  clearData() {
    this.page = 0;
    this.bookingMasterData = [];
    this.bookingMasterData.splice(0, this.bookingMasterData.length);
  }

  filterRecentSearch(filterJsonGet: any, event: any) {
    let filterResentGet =
      filterJsonGet !== null ? JSON.parse(filterJsonGet) : '';
    let filterRecentList: any[] = [];
    if (filterResentGet) {
      let elementPos: any;
      event.account.forEach((element: any) => {
        if (event.type == 'Origin' || event.type == 'Destination') {
          elementPos = filterResentGet
            .map(function (e: any) {
              return e.rL_Code;
            })
            .indexOf(element.rL_Code);
        } else if (event.type == 'shipper') {
          elementPos = filterResentGet
            .map(function (e: any) {
              return e.target_party_id;
            })
            .indexOf(element.target_party_id);
        } else if (event.type == 'Pickup' || event.type == 'Final Delivery') {
          elementPos = filterResentGet
            .map(function (elementLoad: any) {
              return elementLoad.index;
            })
            .indexOf(element.index);
        }

        if (elementPos < 0) {
          filterRecentList = [].concat(element, filterResentGet);
        } else {
          filterRecentList = [].concat(filterResentGet);
        }
      });
    } else {
      if (event.account.length > 0) {
        filterRecentList = [].concat(event.account);
      } else {
        filterRecentList = [].concat(filterResentGet);
      }
    }

    setTimeout(() => {
      let filterOutput = filterRecentList.slice(0, 3);
      if (event.account) {
        switch (event.type) {
          case 'Origin':
            sessionStorage.setItem(
              'BookingRecentOrgin',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Destination':
            sessionStorage.setItem(
              'BookingRecentDestination',
              JSON.stringify(filterOutput)
            );
            break;
          case 'shipper':
            sessionStorage.setItem(
              'BookingRecentShipper',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Pickup':
            sessionStorage.setItem(
              'BookingRecentPickup',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Final Delivery':
            sessionStorage.setItem(
              'BookingRecentFinalDelivery',
              JSON.stringify(filterOutput)
            );
            break;
        }
      }
      let filterJsonSet: any;
      switch (event.type) {
        case 'Origin':
          filterJsonSet = sessionStorage.getItem('BookingRecentOrgin');
          break;
        case 'Destination':
          filterJsonSet = sessionStorage.getItem('BookingRecentDestination');
          break;
        case 'shipper':
          filterJsonSet = sessionStorage.getItem('BookingRecentShipper');
          break;
        case 'Pickup':
          filterJsonSet = sessionStorage.getItem('BookingRecentPickup');
          break;
        case 'Final Delivery':
          filterJsonSet = sessionStorage.getItem('BookingRecentFinalDelivery');
          break;
      }
      let filterResentSet =
        filterJsonSet !== null ? JSON.parse(filterJsonSet) : '';

      this.resentList = {
        list: filterResentSet,
        type: event.type,
      };
    }, 1000);
  }

  prepareExcelDownload(event: any) {
    let getPatameter = this.prepareBookingApiBodyParams();
    this.isProgressShow = true;
    this.bookingServies
      .prepareExcelDowload(
        getPatameter.accountId,
        getPatameter.emailId,
        getPatameter.bodyParam,
        getPatameter.isGroup
      )
      .subscribe((res: Blob) => {
        this.downloadFile(res);
        this.isProgressShow = false;
      });
  }

  /* istanbul ignore next */
  downloadFile(data: any) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    let today = new Date();

    let date =
      today.getFullYear() +
      CommonUtils.padding2(today.getMonth() + 1) +
      today.getDate();

    let time =
      CommonUtils.padding2(today.getHours()) +
      CommonUtils.padding2(today.getMinutes()) +
      CommonUtils.padding2(today.getSeconds());
    let dateTime = date + '_' + time;
    let filename =
      this.currentAccountData.accountAliasName +
      '_Bookings_' +
      dateTime +
      '.xlsx';
    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
    URL.revokeObjectURL(url);
  }

  prepareShareUrl(event: any) {
    let feature: any = this.currentSessionUserData.getCurrentAccountData();
    let selectedFilters = {
      mainFilter: event.type == 'Detail' ? [] : this.mainFilterSelectedArray,
      statusFilter:
        event.type == 'Detail' ? [] : this.statusFilterSelectedArray,
      transportType:
        event.type == 'Detail' ? [] : this.transportTypeFilterSelectedArray,
      watchFilter:
        event.type == 'Detail' ? [] : this.watchListFilterSelectedArray,
      origin: {
        selectedValue:
          event.type == 'Detail' ? [] : this.originFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedOriginAccount,
      },
      destination: {
        selectedValue:
          event.type == 'Detail' ? [] : this.destinationFilterSelectedArray,
        selectedAccount: this.selectedDestinationAccount,
      },
      dateRange: {
        selectedValue:
          event.type == 'Detail' ? [] : this.dateRangeFilterSelectedArray,
        selectedDateRange: event.type == 'Detail' ? [] : this.selectedDateRange,
      },
      shipper: {
        selectedValue:
          event.type == 'Detail' ? [] : this.shipperFilterSelectedArray,
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedShipperAccount,
      },
      allLocation: {
        selectedAccount: [
          { origin: event.type == 'Detail' ? [] : this.selectedOriginAccount },
          {
            destination:
              event.type == 'Detail' ? [] : this.selectedDestinationAccount,
          },
          { pickup: event.type == 'Detail' ? [] : this.selectedPickupAccount },
          {
            finalDelivery:
              event.type == 'Detail' ? [] : this.selectedfinalDeliveryAccount,
          },
        ],
        selectedValue: [
          {
            originValue:
              event.type == 'Detail' ? [] : this.originFilterSelectedArray,
          },
          {
            destinationValue:
              event.type == 'Detail' ? [] : this.destinationFilterSelectedArray,
          },
          {
            pickupValue:
              event.type == 'Detail' ? [] : this.pickupFilterSelectedArray,
          },
          {
            finalDeliveryValue:
              event.type == 'Detail'
                ? []
                : this.finalDeliveryFilterSelectedArray,
          },
        ],
      },
      sorting: {
        sortId: this.defaultBookingSortSelectedValue,
        selectedValue: {
          column_name: this.defaultBookingSortSelectedColumn,
          direction: this.defaultBookingSortDirection,
        },
      },
      accountDetail: {
        aliasName:
          event.type == 'Detail' ? event.aliasName : feature.accountAliasName,
        oH_Code: event.type == 'Detail' ? event.accountId : feature.accountId,
        byGroup: event.type == 'Detail' ? event.isGroup : feature.isGroup,
        shipment: { isVisible: feature.shipmentIsVisible },
        booking: { isVisible: feature.bookingIsVisible },
        customs: { isVisible: feature.customsIsVisible },
        isControllingCustomer:
          event.type == 'Detail' ? event.isParent : feature.isParent,
      },
      bookingId: event.bookingId,
      type: event.type,
      subType: event.type == 'Detail' ? event.subType : '',
      module: 'Booking',
    };
    this.saveFilterJson = selectedFilters;
    if (event.type == 'All') {
      this.saveFilterJson.mainFilter.forEach((e: any) => {
        e.filterDisplayName = e.filterDisplayName.includes(':')
          ? e.filterDisplayName?.split(':')[0]
          : e.filterDisplayName;
      });
      this.mainFilterValueCheck();
      this.bookingServies
        .saveShareFilter(
          this.currentUserData.emailAddress,
          '',
          this.saveFilterJson,
          this.currentAccountData.accountId
        )
        .subscribe((res: any) => {
          if (res.statusCode == 'OC200') {
            var allFilter: any = {
              id: res.result,
              type: 'All',
            };
          }
          let encryAll = btoa(JSON.stringify(allFilter));
          this.url = environment.localUrl + '/bookings/' + encryAll;
        });
    } else if (event.type === 'Detail') {
      this.url = environment.localUrl + '/' + event.shipment?.encryptedId;
    } else {
      let encry = btoa(JSON.stringify(selectedFilters));
      this.url = environment.localUrl + '/bookings/' + encry;
    }
  }

  getAllURLFilter(event: any) {
    this.statusFilterSelectedArray = event.status;
    this.transportTypeFilterSelectedArray = event.transport;
    this.originFilterSelectedArray = event.origin;
    this.destinationFilterSelectedArray = event.destination;
    this.dateRangeFilterSelectedArray = event.dateRange;
    this.mainFilterSelectedArray = event.mainfilter;
    this.watchListFilterSelectedArray = event.watchFilter;
    this.shipperFilterSelectedArray = event.shipper;
    this.pickupFilterSelectedArray = event.pickUp;
    this.finalDeliveryFilterSelectedArray = event.finalDelivery;
    this.selectedShipperAccount = event.shipperAccount;
    this.selectedOriginAccount = event.originAccount;
    this.selectedDestinationAccount = event.destinationAccount;
    this.selectedPickupAccount = event.pickUpAccount;
    this.selectedfinalDeliveryAccount = event.finalDeliveryAccount;
    this.combinedSelectedFiltersArray = [].concat(
      this.statusFilterSelectedArray,
      this.transportTypeFilterSelectedArray,
      this.originFilterSelectedArray,
      this.destinationFilterSelectedArray,
      this.dateRangeFilterSelectedArray,
      this.watchListFilterSelectedArray,
      this.shipperFilterSelectedArray,
      this.pickupFilterSelectedArray,
      this.finalDeliveryFilterSelectedArray
    );
    this.bookingSearchText = event.bookingId;
    this.clearData();
    this.loadAllBookings();
  }

  clearSearchFilter(e: any) {
    this.bookingSearchText = '';
    this.clearData();
    this.loadAllBookings();
  }

  onBookingSearchHandler(emit: any) {
    this.bookingSearchText = '';
    this.bookingSearchText = emit.searchText;
    if (
      emit.event.keyCode == 13 ||
      emit.event.key == 'Enter' ||
      emit.event.type === 'click'
    ) {
      this.clearData();
      this.loadAllBookings();
    }
  }

  getListingOffset(event: any) {
    this.page = event;
    this.loadAllBookings();
  }

  userFilterFetch(event: any) {
    let getPatameter: any = '';
    if (event.type == 'view') {
      this.defaultFilterSelectedValue = -1;
      this.customFilterComponent.clearAllFilters();
      setTimeout(() => {
        let decryptFilter = JSON.parse(event.userFilterRow.jsonFilter);
        if (decryptFilter.mainFilter.length <= 0) {
          if (Object.keys(decryptFilter.statusFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 1,
              filterDisplayName: 'Status',
              type: 'statuscustom',
              index: 1,
            });
          }
          if (Object.keys(decryptFilter.transportType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 0,
              filterDisplayName: 'Transport Type',
              type: 'transport',
              index: 0,
            });
          }
          if (decryptFilter.shipper.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 2,
              filterDisplayName: 'Shipper',
              type: 'shipper',
              index: 2,
            });
          }
          if (
            decryptFilter.allLocation.selectedAccount[0].origin.length > 0 ||
            decryptFilter.allLocation.selectedAccount[1].destination.length >
              0 ||
            decryptFilter.allLocation.selectedAccount[2].pickup.length > 0 ||
            decryptFilter.allLocation.selectedAccount[3].finalDelivery.length >
              0
          ) {
            decryptFilter.mainFilter.push({
              id: 4,
              filterDisplayName: 'Location',
              type: 'location',
              index: 4,
            });
          }
          if (decryptFilter.dateRange.selectedDateRange.length > 0) {
            decryptFilter.mainFilter.push({
              id: 5,
              filterDisplayName: 'Date Range',
              type: '',
              index: 5,
            });
          }
          if (Object.keys(decryptFilter.watchFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 7,
              filterDisplayName: 'Watchlist',
              type: '',
              index: 7,
            });
          }
        }
        if (
          decryptFilter.allLocation.selectedAccount[0].origin.length > 0 &&
          Object.keys(decryptFilter.allLocation.selectedValue[0].originValue)
            .length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[0].origin = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[1].destination.length > 0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[1].destinationValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[1].destination = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[2].pickup.length > 0 &&
          Object.keys(decryptFilter.allLocation.selectedValue[2].pickupValue)
            .length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[2].pickup = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[3].finalDelivery.length >
            0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[3].finalDeliveryValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[3].finalDelivery = [];
        }
        setTimeout(() => {
          this.customFilterComponent.loadURLFilters(decryptFilter);
        }, 500);
        setTimeout(() => {
          getPatameter = this.prepareBookingApiBodyParams();
          this.filterCheckTemp = getPatameter.bodyParam;
        }, 500);
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
      }, 300);
    } else if (event.type == 'update') {
      setTimeout(() => {
        getPatameter = this.prepareBookingApiBodyParams();
        this.filterCheckTemp = getPatameter.bodyParam;
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
        setTimeout(() => {
          this.customFilterComponent.setUpdateFilterName();
        }, 200);
      }, 300);
    }
  }

  sortByFilterNameName(collection: any) {
    if (collection?.length > 0) {
      return collection.sort((a: any, b: any) =>
        a?.filterName.localeCompare(b?.filterName)
      );
    } else {
      return [];
    }
  }

  getUserFilters(event: any) {
    if (event.message && event.type != 'delete') {
      this.isShowMessage = true;
      this.showMessage = event.message;
    } else if (event.message && event.type == 'delete') {
      this.isShowMessageDel = true;
      this.showMessageDel = event.message;
    }

    this.bookingServies
      .getUserFilter(
        this.currentUserData.emailAddress,
        this.currentAccountData.accountId,
        this.currentAccountData.isGroup
      )
      .subscribe((res: any) => {
        this.savedFiltersList = this.sortByFilterNameName(res.result);
        this.myFilterEdit = '';
        if (event.type == 'save' || event.type == 'update') {
          let elementPos = res.result.filter(
            (x: any) => x.filterName == event.filterName
          );
          this.savedFilterRow = elementPos;
        } else {
          this.savedFilterRow = '';
        }
      });
    this.clearToaster();
  }

  clearToaster() {
    setTimeout(() => {
      this.isShowMessage = false;
      this.showMessage = '';
      this.isShowMessageDel = false;
      this.showMessageDel = '';
    }, 5000);
  }

  customFilterApiLoader(event: any) {
    this.isProgressShow = event;
  }

  undoDeleteUserFilter(event: any) {
    if (event) {
      setTimeout(() => {
        this.customFilterComponent.undoChanges();
        this.isShowMessageDel = false;
        this.showMessageDel = '';
      }, 200);
    }
  }

  clearAllFilterValue(e: any) {
    this.combinedSelectedFiltersArray = [];
    this.statusFilterSelectedArray = [];
    this.transportTypeFilterSelectedArray = [];
    this.originFilterSelectedArray = [];
    this.destinationFilterSelectedArray = [];
    this.dateRangeFilterSelectedArray = [];
    this.watchListFilterSelectedArray = [];
    this.mainFilterSelectedArray = [];
    this.defaultFilterArray = [];
    this.shipperFilterSelectedArray = [];
    this.pickupFilterSelectedArray = [];
    this.finalDeliveryFilterSelectedArray = [];
    this.selectedOriginAccount = [];
    this.selectedDestinationAccount = [];
    this.selectedPickupAccount = [];
    this.selectedfinalDeliveryAccount = [];
    this.selectedShipperAccount = [];
    if (e != 'saveButton') {
      this.defaultFilterSelectedValue = 0;
      this.clearData();
      this.bookingSearchText = '';
      this.loadAllBookings();
    }
  }

  featureHide() {
    this.popupToggle = false;
    $('#shipment-sec-id > div').removeClass('active');
    this.bookingServies.isActionFieldChanged = false;
    this.bookingServies.switchTabInitiated = false;
    this.bookingServies.isActionFieldChanged = false;
  }

  detailShare(event: any) {
    this.detailBookingShare = true;
    this.bookingSharePopup = true;

    this.prepareShareUrl(event);
  }

  sharePopUpfeatureHide() {
    this.singleBookingShare = false;
    this.detailBookingShare = false;
    this.bookingSharePopup = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  mainFilterValueCheck() {
    if (this.saveFilterJson.mainFilter.length <= 0) {
      if (Object.keys(this.saveFilterJson.statusFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 1,
          filterDisplayName: 'Status',
          type: 'statuscustom',
          index: 1,
        });
      }
      if (Object.keys(this.saveFilterJson.transportType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 0,
          filterDisplayName: 'Transport Type',
          type: 'transport',
          index: 0,
        });
      }
      if (this.saveFilterJson.shipper.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 2,
          filterDisplayName: 'Shipper',
          type: 'shipper',
          index: 2,
        });
      }

      if (
        this.saveFilterJson.allLocation.selectedAccount[0].origin.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[1].destination.length >
          0 ||
        this.saveFilterJson.allLocation.selectedAccount[2].pickup.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[3].finalDelivery
          .length > 0
      ) {
        this.saveFilterJson.mainFilter.push({
          id: 4,
          filterDisplayName: 'Location',
          type: 'location',
          index: 4,
        });
      }
      if (this.saveFilterJson.dateRange.selectedDateRange.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 5,
          filterDisplayName: 'Date Range',
          type: '',
          index: 5,
        });
      }
      if (Object.keys(this.saveFilterJson.watchFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 7,
          filterDisplayName: 'Watchlist',
          type: '',
          index: 7,
        });
      }
    }
  }

  publicShareLimitedApiUser(filters: any, encryptedFilter: any) {
    if (filters.type == 'Detail') {
      let getPatameter = this.prepareBookingApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'shipmentNumber',
            value: filters.bookingId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultBookingSortSelectedColumn,
            direction: this.defaultBookingSortDirection,
          },
        ],
        filter_options: [],
      };
      this.bookingServies
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.getBookingDetails = res.result[0];
          this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
          this.filterService
            .getData(
              environment.base_api_desktop_url +
                'useraccount?userEmailAddresss=' +
                this.currentUserData.emailAddress
            )
            .subscribe((response) => {
              this.isUserHaveAcc = response.result
                .map(function (x: any) {
                  return x.oH_Code;
                })
                .indexOf(this.getBookingDetails.accountId);
              if (this.isUserHaveAcc >= 0) {
                let accountDetail = {
                  aliasName: this.getBookingDetails.account_alias_name,
                  oH_Code: this.getBookingDetails.accountId,
                  byGroup: false,
                  isControllingCustomer: false,
                  shipment: {
                    isVisible:
                      this.getBookingDetails.account_shipment.isVisible,
                  },
                  booking: {
                    isVisible: this.getBookingDetails.account_booking.isVisible,
                  },
                  customs: {
                    isVisible: this.getBookingDetails.account_customs.isVisible,
                  },
                };
                const isSwitchAccount = this.switchAccount.switchAccount(
                  accountDetail,
                  'shareUrlBooking',
                  encryptedFilter
                );
                if (!isSwitchAccount) {
                  if (filters.type == 'All' || filters.type == 'Detail') {
                    this.urlFilterCall(filters);
                  }
                }
              } else {
                if (filters.type == 'Detail') {
                  this.urlFilterCall(filters);
                }
              }
            });
        });
    } else if (filters.type == 'All') {
      let groupCount: any = localStorage.getItem('groupTotalCount') || 0;
      let totalGroup: number;
      if (parseInt(groupCount) > 0) {
        totalGroup = parseInt(groupCount);
      } else {
        totalGroup = 0;
      }
      if (totalGroup > 0) {
        this.groupSwitching(filters, encryptedFilter);
      } else {
        this.userService
          .getUserDetail(this.currentUserData.emailAddress)
          .subscribe((accountRes: any) => {
            let accountDetail = {
              aliasName: accountRes.result.accountUserRole.alias_name,
              oH_Code: accountRes.result.accountUserRole.accountId,
              byGroup: false,
              isControllingCustomer: false,
              shipment: { isVisible: false },
              booking: { isVisible: false },
              customs: { isVisible: false },
            };

            this.isUserHaveAcc = accountRes.result;
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlBooking',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              this.urlFilterCall(filters);
            }
          });
      }
    }
  }

  publicShareLimitedApiAdmin(filters: any, encryptedFilter: any) {
    if (filters.type == 'Detail') {
      let getPatameter = this.prepareBookingApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'shipmentNumber',
            value: filters.bookingId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultBookingSortSelectedColumn,
            direction: this.defaultBookingSortDirection,
          },
        ],
        filter_options: [],
      };
      this.bookingServies
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.getBookingDetails = res.result[0];
          this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
          if (res.result.length > 0) {
            let accountDetail = {
              aliasName: this.getBookingDetails.account_alias_name,
              oH_Code: this.getBookingDetails.accountId,
              byGroup: false,
              isControllingCustomer: false,
              shipment: {
                isVisible: this.getBookingDetails.account_shipment.isVisible,
              },
              booking: {
                isVisible: this.getBookingDetails.account_booking.isVisible,
              },
              customs: {
                isVisible: this.getBookingDetails.account_customs.isVisible,
              },
            };
            const switchAcc = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlBooking',
              encryptedFilter
            );
            if (!switchAcc) {
              this.urlFilterCall(filters);
            }
          }
        });
    } else if (filters.type == 'All') {
      let groupCount: any = localStorage.getItem('groupTotalCount') || 0;
      let totalGroup: number;
      if (parseInt(groupCount) > 0) {
        totalGroup = parseInt(groupCount);
      } else {
        totalGroup = 0;
      }
      if (totalGroup > 0) {
        this.groupSwitching(filters, encryptedFilter);
      } else {
        this.filterService
          .getAutoCompleteSearchRecords('', 0, 1)
          .subscribe((accountRes: any) => {
            let accountDetail = {
              aliasName: accountRes.result[0].aliasName,
              oH_Code: accountRes.result[0].oH_Code,
              byGroup: false,
              isControllingCustomer: false,
              shipment: { isVisible: accountRes.result[0].shipment.isVisible },
              booking: { isVisible: accountRes.result[0].booking.isVisible },
              customs: { isVisible: accountRes.result[0].custom.isVisible },
            };
            this.isUserHaveAcc = accountRes.result;
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlBooking',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              this.urlFilterCall(filters);
            }
          });
      }
    }
  }

  publicShareLimitedApi(filters: any) {
    let getPatameter = this.prepareBookingApiBodyParams();
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: filters.bookingId,
        },
      ],
      sort_options: [
        {
          column_name: this.defaultBookingSortSelectedColumn,
          direction: this.defaultBookingSortDirection,
        },
      ],
      filter_options: [],
    };
    this.bookingServies
      .publicShare(
        filters.accountDetail.oH_Code,
        getPatameter.emailId,
        bodyParam,
        filters.accountDetail.byGroup,
        filters.accountDetail.isControllingCustomer
      )
      .subscribe((res: any) => {
        this.getBookingDetails = res.result[0];
        this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
      });
  }

  public apiCancel() {
    if (this.apiCall != null) {
      this.apiCall.unsubscribe();
      this.bookingServies.stopRequest.next();
    }
  }

  async setGroupUrlFilterCriteria(filters: any) {
    let searchText = '';
    let offset = 0;
    let accountID = this.currentAccountData.accountId;
    let userEmail = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let fetch = 1000;
    if (filters.allLocation.selectedAccount[0].origin.length > 0) {
      await this.bookingServies
        .getOriginFilter(
          searchText,
          offset,
          'origin_port',
          accountID,
          userEmail,
          isGroup,
          fetch
        )
        .toPromise()
        .then((res: any) => {
          let originFinalVal: any[] = [];
          let originValueConvert: any[] = [];
          let originValueFinalConvert = '';
          let origin = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[0].origin.some(
              (o2: any) =>
                o1.rL_RN_NKCountryCode === o2.rL_RN_NKCountryCode &&
                o1.rL_Code === o2.rL_Code
            )
          );
          let originSpliedVal =
            filters.allLocation.selectedValue[0].originValue.value.split('|');
          originSpliedVal.forEach((ele: any) => {
            let tempOrg = ele.split(',');
            originFinalVal.push({
              rL_RN_NKCountryCode: tempOrg[0],
              rL_Code: tempOrg[1],
            });
          });
          let originFilter = originFinalVal.filter((val: any) =>
            res.result.some(
              (acc: any) =>
                acc.rL_RN_NKCountryCode === val.rL_RN_NKCountryCode &&
                acc.rL_Code === val.rL_Code
            )
          );
          originFilter.forEach((e: any) => {
            originValueConvert.push(e.rL_RN_NKCountryCode + ',' + e.rL_Code);
            originValueFinalConvert = originValueConvert
              .map((x) => x)
              .join('|');
          });
          if (originValueFinalConvert.length > 0) {
            filters.allLocation.selectedValue[0].originValue.value =
              originValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[0].originValue = [];
          }

          filters.allLocation.selectedAccount[0].origin = origin;
        });
    }
    if (filters.allLocation.selectedAccount[1].destination.length > 0) {
      await this.bookingServies
        .getOriginFilter(
          searchText,
          offset,
          'final_destination_port',
          accountID,
          userEmail,
          isGroup,
          fetch
        )
        .toPromise()
        .then((res: any) => {
          let destinationFinalVal: any[] = [];
          let destinationValueConvert: any[] = [];
          let destinationValueFinalConvert = '';
          let destination = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[1].destination.some(
              (o2: any) =>
                o1.rL_RN_NKCountryCode === o2.rL_RN_NKCountryCode &&
                o1.rL_Code === o2.rL_Code
            )
          );
          let destinationSpliedVal =
            filters.allLocation.selectedValue[1].destinationValue.value.split(
              '|'
            );
          destinationSpliedVal.forEach((ele: any) => {
            let tempDest = ele.split(',');
            destinationFinalVal.push({
              rL_RN_NKCountryCode: tempDest[0],
              rL_Code: tempDest[1],
            });
          });
          let destinationFilter = destinationFinalVal.filter((value: any) =>
            res.result.some(
              (acc: any) =>
                acc.rL_RN_NKCountryCode === value.rL_RN_NKCountryCode &&
                acc.rL_Code === value.rL_Code
            )
          );
          destinationFilter.forEach((e: any) => {
            destinationValueConvert.push(
              e.rL_RN_NKCountryCode + ',' + e.rL_Code
            );
            destinationValueFinalConvert = destinationValueConvert
              .map((x) => x)
              .join('|');
          });
          if (destinationValueFinalConvert.length > 0) {
            filters.allLocation.selectedValue[1].destinationValue.value =
              destinationValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[1].destinationValue = [];
          }
          filters.allLocation.selectedAccount[1].destination = destination;
        });
    }
    if (filters.allLocation.selectedAccount[2].pickup.length > 0) {
      await this.bookingServies
        .getPickupFilter(
          searchText,
          offset,
          accountID,
          userEmail,
          isGroup,
          'ship_from',
          fetch
        )
        .toPromise()
        .then((res: any) => {
          let pickupFinalVal: any[] = [];
          let pickupValueConvert: any[] = [];
          let pickupValueFinalConvert = '';
          let pickup = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[2].pickup.some(
              (o2: any) =>
                o1.name === o2.name &&
                o1.city === o2.city &&
                o1.countryCode === o2.countryCode
            )
          );
          let pickupSpliedVal =
            filters.allLocation.selectedValue[2].pickupValue.value.split('|');
          pickupSpliedVal.forEach((ele: any) => {
            let tempPick = ele.split(',');
            const a = tempPick.slice(0, -2).join(',');
            const b = tempPick.slice(-2).join(',');
            let cityCoutry = b.split(',');
            pickupFinalVal.push({
              name: a,
              city: cityCoutry[0],
              countryCode: cityCoutry[1],
            });
          });
          let pickupFilter = pickupFinalVal.filter((val: any) =>
            res.result.some(
              (acc: any) =>
                acc.name === val.name &&
                acc.city === val.city &&
                acc.countryCode === val.countryCode
            )
          );
          pickupFilter.forEach((e: any) => {
            pickupValueConvert.push(
              e.name + ',' + e.city + ',' + e.countryCode
            );
            pickupValueFinalConvert = pickupValueConvert
              .map((x) => x)
              .join('|');
          });
          if (pickupValueFinalConvert.length > 0) {
            filters.allLocation.selectedValue[2].pickupValue.value =
              pickupValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[2].pickupValue = [];
          }
          filters.allLocation.selectedAccount[2].pickup = pickup;
        });
    }
    if (filters.allLocation.selectedAccount[3].finalDelivery.length > 0) {
      await this.bookingServies
        .getFinalDeliveryFilter(
          searchText,
          offset,
          accountID,
          userEmail,
          isGroup,
          'ship_to',
          fetch
        )
        .toPromise()
        .then((res: any) => {
          let finalDeliveryFinalVal: any[] = [];
          let finalDeliveryValueConvert: any[] = [];
          let finalDeliveryValueFinalConvert = '';
          let finalDelivery = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[3].finalDelivery.some(
              (o2Loc: any) =>
                o1.name === o2Loc.name &&
                o1.city === o2Loc.city &&
                o1.countryCode === o2Loc.countryCode
            )
          );
          let finalDeliverySpliedVal =
            filters.allLocation.selectedValue[3].finalDeliveryValue.value.split(
              '|'
            );
          finalDeliverySpliedVal.forEach((ele: any) => {
            let tempFinalDeli = ele.split(',');
            const a = tempFinalDeli.slice(0, -2).join(',');
            const b = tempFinalDeli.slice(-2).join(',');
            let cityCoutry = b.split(',');
            finalDeliveryFinalVal.push({
              name: a,
              city: cityCoutry[0],
              countryCode: cityCoutry[1],
            });
          });
          let finalDeliveryFilter = finalDeliveryFinalVal.filter((value: any) =>
            res.result.some(
              (account: any) =>
                account.name === value.name &&
                account.city === value.city &&
                account.countryCode === value.countryCode
            )
          );
          finalDeliveryFilter.forEach((e: any) => {
            finalDeliveryValueConvert.push(
              e.name + ',' + e.city + ',' + e.countryCode
            );
            finalDeliveryValueFinalConvert = finalDeliveryValueConvert
              .map((x) => x)
              .join('|');
          });
          if (finalDeliveryValueFinalConvert) {
            filters.allLocation.selectedValue[3].finalDeliveryValue.value =
              finalDeliveryValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[3].finalDeliveryValue = [];
          }
          filters.allLocation.selectedAccount[3].finalDelivery = finalDelivery;
        });
    }

    if (filters.shipper.selectedAccount.length > 0) {
      await this.bookingServies
        .getShipperFilter(searchText, 0, accountID, userEmail, isGroup, fetch)
        .toPromise()
        .then((res: any) => {
          let shipper = res.result.filter((o1: any) =>
            filters.shipper.selectedAccount.some(
              (o2: any) =>
                o1.name === o2.name && o1.target_party_id === o2.target_party_id
            )
          );
          let shipperFilter = filters.shipper.selectedValue.value
            .split(',')
            .filter((val: any) =>
              res.result.some((acc: any) => acc.target_party_id === val)
            );
          filters.shipper.selectedAccount = shipper;
          if (shipperFilter.toString().length > 0) {
            filters.shipper.selectedValue.value = shipperFilter.toString();
          } else {
            filters.shipper.selectedValue = [];
          }
        });
    }
    setTimeout(() => {
      this.customFilterComponent.loadURLFilters(filters);
    }, 500);
    this.urlFilterSort(filters);
  }

  groupSwitching(filters: any, encryptedFilter: any) {
    this.myGroupService
      .getGroupName(this.currentUserData.emailAddress)
      .subscribe((res: any) => {
        this.isUserHaveAcc = res.result
          .map(function (x: any) {
            return x.groupName.trim().toLowerCase();
          })
          .indexOf(filters.accountDetail.aliasName.trim().toLowerCase());
        let switchGroup = res.result.filter(
          (x: any) =>
            x.groupName.trim().toLowerCase() ==
            filters.accountDetail.aliasName.trim().toLowerCase()
        );
        if (this.isUserHaveAcc >= 0) {
          let accountDetail1 = {
            aliasName: switchGroup[0].groupName,
            oH_Code: switchGroup[0].id,
            byGroup: true,
            isControllingCustomer: false,
            shipment: { isVisible: false },
            booking: { isVisible: false },
            customs: { isVisible: false },
          };
          const isSwitchAccount = this.switchAccount.switchAccount(
            accountDetail1,
            'shareUrlBooking',
            encryptedFilter
          );
          if (!isSwitchAccount) {
            this.setGroupUrlFilterCriteria(filters);
          }
        } else {
          let accountDetail2 = {
            aliasName: res.result[0].groupName,
            oH_Code: res.result[0].id,
            byGroup: true,
            isControllingCustomer: false,
            shipment: { isVisible: false },
            booking: { isVisible: false },
            customs: { isVisible: false },
          };
          this.isUserHaveAcc = res.result;
          const isSwitchAccount = this.switchAccount.switchAccount(
            accountDetail2,
            'shareUrlBooking',
            encryptedFilter
          );
          if (!isSwitchAccount) {
            this.setGroupUrlFilterCriteria(filters);
          }
        }
      });
  }

  publicSearchFn(filters: any) {
    if (filters) {
      this.dataSharingService.notLoggedIn.next(true);
      this.isDetailFullView = true;
      this.popupToggle = true;
      this.urlBookingId = filters.bookingId;
      this.getBookingDetails = filters.booking;

      let filter = {
        type: 'Detail',
        bookingId: filters.bookingId,
        accountDetail: {
          aliasName: filters.accountDetail.aliasName,
          oH_Code: filters.accountDetail.oH_Code,
          byGroup: false,
          shipment: { isVisible: false },
          booking: { isVisible: false },
          customs: { isVisible: false },
          isControllingCustomer: false,
        },
        subType: 'publicSearch',
        module: 'Booking',
      };
      this.publicShareFilter = filter;
      let encry = btoa(JSON.stringify(filter));
      this.publicShareEnrcyptedData = encry;
      this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
    }
  }

  getUserDetailGuidedTour() {
    this.userService
      .getUserDetail(this.currentUserData.emailAddress)
      .subscribe((res) => {
        let userGuidedTourDetails = res.result.user.guidedTour.filter(function (
          el: any
        ) {
          return el.featureName == 'bookings';
        });
        let guidedTour =
          userGuidedTourDetails.length > 0 ? userGuidedTourDetails[0] : null;
        if (!guidedTour || (!guidedTour.isVisited && !this.isMobileView)) {
          this.combinedSelectedFiltersArray = [
            {
              column_name: 'statuscustom',
              operator: '=',
              value: '2,3,4',
            },
          ];
          this.defaultBookingSortSelectedValue = 3;
          this.defaultBookingSortSelectedColumn = 'status';
          this.defaultBookingSortDirection = 'asc';
          this.appInsightsService.trackAIGuidedTour(AI_ModulesName.Booking);
          this.loadAllBookings();
          this.showGuidedTourPopup();
          this.endTour();
        }
      });
  }

  showGuidedTourPopup() {
    this.guidedTourPopup = true;
  }

  endTour() {
    let params = {
      featureName: 'bookings',
      isVisited: true,
      whenFinished: new Date(),
    };
    this.bookingServies
      .skipTour(this.currentUserData.emailAddress, params)
      .subscribe((res) => {
        if (res.result.includes('Guided tour updated')) {
          let setUser = this.currentSessionUserData.getCurrentUserData();
          setUser.guidedTour = [params];
          localStorage.setItem(
            'loggedInUser',
            window.btoa(unescape(encodeURIComponent(JSON.stringify(setUser))))
          );
        }
      });
  }

  startGuidedTour(type: any) {
    if (type == 'first') {
      this.hideGuidedTourPopup();
    }

    this.dataSharingService.isGuidedTourHeaderHide.next(true);
    this.dataSharingService.isGuidedTourShipment.next(true);
    this.guidedFeatureModalShow = true;
    if (this.totalBookingCount > 0) {
      this.guidedTourFlag = 1;
      this.guidedTourPopupHeader = this.guidedTourJson[0].heading;
      this.guidedTourPopupPara = this.guidedTourJson[0].para;
      if (
        this.guidedTourIsConvertedBooking.length < 1 &&
        this.totalBookingCount > 0
      ) {
        this.guidedTourFlagNoShipment = 1;
      }
    } else {
      this.guidedTourFlag = 3;
      this.guidedTourFlagNoShipment = 1;
      this.guidedTourPopupHeader = this.guidedTourJson[2].heading;
      this.guidedTourPopupPara = this.guidedTourJson[2].para;
    }
    setTimeout(() => {
      $('#rapModal').addClass('dynamicRapClass' + this.guidedTourFlag);
    }, 0);
    this.getGuidedTourFeature();
  }

  nextFeature() {
    this.guidedTourBookingDetail = 'false';
    if (this.guidedTourFlag == 8) {
      this.guidedFeatureModalShow = false;
      this.clearAllGuidedTourCss();
    }
    if (this.guidedTourFlag < 8) {
      this.guidedTourFlag += 1;
      if (
        this.guidedTourIsConvertedBooking.length < 1 &&
        this.totalBookingCount > 0
      ) {
        if (this.guidedTourFlag == 2) {
          this.guidedTourFlag = 3;
        }
        this.guidedTourFlagNoShipment += 1;
      }
      if (this.savedFiltersList.length < 1 && this.totalBookingCount > 0) {
        if (this.guidedTourFlag == 5) {
          this.guidedTourFlag = 6;
        }
        this.guidedTourFlagNoShipment += 1;
      }
      if (this.guidedTourFlag == 8 && this.totalBookingCount < 1) {
        this.guidedFeatureModalShow = false;
        this.clearAllGuidedTourCss();
      } else {
        if (this.totalBookingCount < 1) {
          this.guidedTourFlagNoShipment += 1;
        }
        this.dataSharingService.isGuidedTourHeaderHide.next(true);
        $('#rapModal').removeClass();
        $('#rapModal').addClass(
          'rap-set dynamicRapClass' + this.guidedTourFlag
        );
        this.getGuidedTourFeature();
      }
    }
  }

  previousFeature() {
    this.dataSharingService.isGuidedTourHeaderHide.next(true);
    if (this.guidedTourFlag == 8) {
      if (!$('#shipmentFull').is(':visible')) {
        return;
      }
    }

    this.guidedTourFlag -= 1;
    if (this.savedFiltersList.length < 1 && this.totalBookingCount > 0) {
      if (this.guidedTourFlag == 5) {
        this.guidedTourFlag -= 1;
      }
      this.guidedTourFlagNoShipment += 1;
    }
    if (this.totalBookingCount < 1) {
      this.guidedTourFlagNoShipment -= 1;
    }
    $('#rapModal').removeClass();
    $('#rapModal').addClass('rap-set dynamicRapClass' + this.guidedTourFlag);
    this.getGuidedTourFeature();
  }

  getGuidedTourFeature() {
    this.dataSharingService.isGuidedTourDefaultFilterHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterCompHide.next(true);
    this.dataSharingService.isGuidedTourToggleShow.next(true);
    this.dataSharingService.isGuidedTourShipmentSecHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterHide.next(true);
    this.dataSharingService.isGuidedTourSearchTextBoxHide.next(true);
    this.dataSharingService.isGuidedTourSortHide.next(true);
    this.dataSharingService.isGuidedTourShipment.next(false);
    this.dataSharingService.isGuidedTourCustomFilterShow.next(false);
    this.dataSharingService.bookingDefaultFilterListHide.next(true);
    this.dataSharingService.bookingSavedFilterListHide.next(true);
    switch (this.guidedTourFlag) {
      case 1:
        this.dataSharingService.isGuidedTourShipmentSecShow.next(true);
        this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
        this.guidedTourPopupHeader = this.guidedTourJson[0].heading;
        this.guidedTourPopupPara = this.guidedTourJson[0].para;
        break;
      case 2:
        this.dataSharingService.isGuidedTourShipmentSecShow.next(true);
        this.dataSharingService.isGuidedTourShipmentSecHide.next(false);

        this.guidedTourPopupHeader = this.guidedTourJson[1].heading;
        this.guidedTourPopupPara = this.guidedTourJson[1].para;
        break;
      case 3:
        this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
        this.dataSharingService.isGuidedTourDefaultFilterShow.next(true);
        this.dataSharingService.bookingDefaultFilterListShow.next(true);
        this.dataSharingService.bookingDefaultFilterListHide.next(false);
        this.guidedTourBookingFilter = false;
        this.guidedTourPopupHeader = this.guidedTourJson[2].heading;
        this.guidedTourPopupPara = this.guidedTourJson[2].para;
        break;
      case 4:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourCustomFilterHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterShow.next(true);
        this.guidedTourBookingFilter = true;
        this.guidedTourPopupHeader = this.guidedTourJson[3].heading;
        this.guidedTourPopupPara = this.guidedTourJson[3].para;
        break;
      case 5:
        this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
        this.dataSharingService.isGuidedTourDefaultFilterShow.next(true);
        this.dataSharingService.bookingSavedFilterListShow.next(true);
        this.dataSharingService.bookingSavedFilterListHide.next(false);
        this.guidedTourBookingFilter = false;
        this.guidedTourPopupHeader = this.guidedTourJson[4].heading;
        this.guidedTourPopupPara = this.guidedTourJson[4].para;
        break;
      case 6:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourSearchTextBoxHide.next(false);
        this.dataSharingService.isGuidedTourSearchTextBoxShow.next(true);
        this.guidedTourPopupHeader = this.guidedTourJson[5].heading;
        this.guidedTourPopupPara = this.guidedTourJson[5].para;
        this.guidedTourBookingFilter = false;
        break;
      case 7:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourSortHide.next(false);
        this.dataSharingService.isGuidedTourSortShow.next(true);
        this.guidedTourPopupHeader = this.guidedTourJson[6].heading;
        this.guidedTourPopupPara = this.guidedTourJson[6].para;
        this.guidedTourBookingDetail = 'false';
        break;
      case 8:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(true);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(false);
        if (this.totalBookingCount > 0) {
          this.dataSharingService.isGuidedTourShipment.next(true);
          this.guidedTourBookingDetail = 'true';
          this.guidedTourPopupHeader = this.guidedTourJson[7].heading;
          this.guidedTourPopupPara = this.guidedTourJson[7].para;
        }
        break;
    }
  }

  guidedTourStartManually(event: any) {
    if (event) {
      this.combinedSelectedFiltersArray = [
        {
          column_name: 'statuscustom',
          operator: '=',
          value: '2,3,4',
        },
      ];
      this.defaultBookingSortSelectedValue = 3;
      this.defaultBookingSortSelectedColumn = 'status';
      this.defaultBookingSortDirection = 'asc';
      this.clearData();
      this.loadAllBookings();
      this.guidedFeatureManualModalShow = true;
    }
  }

  gudideTourStart() {
    if (this.isProgressShow) {
      return;
    }
    this.guidedFeatureManualModalShow = false;
    this.startGuidedTour('manual');
  }

  gudideTourCloseset() {
    this.guidedFeatureManualModalShow = false;
  }

  skipTour() {
    this.hideGuidedTourPopup();
  }

  clearAllGuidedTourCss() {
    this.dataSharingService.isGuidedTourHeaderHide.next(false);
    this.dataSharingService.isGuidedTourHeaderShow.next(false);
    this.dataSharingService.isGuidedTourShipment.next(false);
    this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
    this.dataSharingService.isGuidedTourShipmentSecShow.next(false);
    this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
    this.dataSharingService.isGuidedTourCustomFilterCompShow.next(false);
    this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
    this.dataSharingService.isGuidedTourDefaultFilterShow.next(false);
    this.dataSharingService.isGuidedTourSearchTextBoxHide.next(false);
    this.dataSharingService.isGuidedTourSearchTextBoxShow.next(false);
    this.dataSharingService.isGuidedTourCustomFilterHide.next(false);
    this.dataSharingService.isGuidedTourCustomFilterShow.next(false);
    this.dataSharingService.isGuidedTourSortHide.next(false);
    this.dataSharingService.isGuidedTourSortShow.next(false);
    this.dataSharingService.isGuidedTourShareShipmentHide.next(false);
    this.dataSharingService.isGuidedTourShareShipmentShow.next(false);
    this.dataSharingService.isGuidedTourToggleShow.next(false);
    this.dataSharingService.bookingSavedFilterListShow.next(false);
    this.dataSharingService.bookingSavedFilterListHide.next(false);
    this.dataSharingService.bookingDefaultFilterListShow.next(false);
    this.dataSharingService.bookingDefaultFilterListHide.next(false);
    this.guidedTourBookingDetail = '';
    this.reloadCurrentRoute();
  }

  /* istanbul ignore next */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onRefreshDetailFromDetail(event: any) {
    this.isProgressShow = true;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (event.value == 'LOAD DETAILS') {
      if (this.currentAccountData && event.access) {
        let accountId = this.currentAccountData.accountId;
        let emailId = this.currentUserData.emailAddress;
        let isGroup = this.currentAccountData.isGroup;
        this.bookingServies
          .getBookingDetails(accountId, emailId, event.shipmentId, isGroup)
          .subscribe({
            next: (getDetails: any) => {
              this.dataSharingService.refreshShipmentDetailsInHeader.next(
                false
              );
              this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
                true
              );
              this.isProgressShow = false;
              this.getBookingDetails = getDetails.result[0];
            },
          });
      } else if (
        this.publicShareFilter.type === 'Detail' &&
        this.publicShareFilter.subType === 'publicSearch'
      ) {
        this.bookingPublicSearchShare('refresh');
      } else {
        this.refreshPublicView();
      }
    } else {
      this.refreshPublicView();
    }
  }

  refreshPublicView() {
    let getPatameter = this.prepareBookingApiBodyParams();
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: this.publicShareFilter.bookingId,
        },
      ],
      sort_options: [
        {
          column_name: this.defaultBookingSortSelectedColumn,
          direction: this.defaultBookingSortDirection,
        },
      ],
      filter_options: [],
    };
    let oH_Code = this.publicShareFilter?.accountDetail?.oH_Code
      ? this.publicShareFilter?.accountDetail?.oH_Code
      : this.currentAccountData?.accountId
      ? this.currentAccountData?.accountId
      : '';
    let byGroup = this.publicShareFilter?.accountDetail?.byGroup
      ? this.publicShareFilter?.accountDetail?.byGroup
      : this.currentAccountData?.isGroup
      ? this.currentAccountData?.isGroup
      : false;
    let isControllingCustomer = this.publicShareFilter?.accountDetail
      ?.isControllingCustomer
      ? this.publicShareFilter?.accountDetail?.isControllingCustomer
      : this.currentAccountData?.isParent
      ? this.currentAccountData?.isParent
      : false;
    this.bookingServies
      .publicShare(
        oH_Code,
        getPatameter.emailId,
        bodyParam,
        byGroup,
        isControllingCustomer
      )
      .subscribe((res: any) => {
        this.isProgressShow = false;
        this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
        this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
          true
        );
        this.getBookingDetails = res.result[0];
        this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
      });
  }

  hideGuidedTourPopup() {
    this.guidedTourPopup = false;
  }

  bookingPublicSearchShare(from: any) {
    if (this.publicShareFilter) {
      this.bookingServies
        .shipmentPublicSearch(this.publicShareFilter.bookingId)
        .subscribe((x) => {
          if (x.result) {
            if (from === 'refresh') {
              this.dataSharingService.refreshShipmentDetailsInHeader.next(
                false
              );
              this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
                true
              );
            }
            this.isProgressShow = false;
            this.urlBookingId = this.publicShareFilter.bookingId;
            this.popupToggle = true;
            this.getBookingDetails = x.result;
            this.selectedBookingLen = Object.keys(
              this.getBookingDetails
            ).length;
          }
        });
    }
  }

  trackAIPageView(isPublicView: boolean) {
    this.appInsightsService.logPageView(
      isPublicView ? AI_PageName.BookingsPublicView : AI_PageName.Booking,
      window.location.href
    );
  }

  /* istanbul ignore next */
  navigateToFaq() {
    this.router.navigate([]).then((result) => {
      window.open('/faqs', '_blank');
    });
  }

  setUrlDefaultFilter(e: any) {
    if (e.mainfilter.length > 0) {
      this.mainFilterSelectedArray.push(e.mainfilter[0]);
    }
  }
}
