import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { DashboardShipmentListComponent } from 'src/app/features/dashboard/components/dashboard-shipment-list/dashboard-shipment-list.component';
import { DashboardWatchlistComponent } from 'src/app/features/dashboard/components/dashboard-watchlist/dashboard-watchlist.component';
import { ShipmentServies } from '../../services/shipment.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { CommonUtils } from 'src/app/common/utils';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import {
  AppInsightsService,
  AI_PageName,
  AI_CustomProps,
  AI_CommonEvents,
  AI_ModulesName,
} from 'src/app/services/appinsights.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { ShipmentDetailComponent } from '../shipment-detail/shipment-detail.component';
import { EdocUploadStatusService } from 'src/app/shared/services/eDoc-upload-status.service';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActionFieldService } from 'src/app/features/admin/actionfield/services/action-field.service';
import { TableViewService } from 'src/app/features/admin/accounts/services/table-view.service';
import { ProgressBarType } from 'src/app/common/shipment.enums';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OC3Constants } from 'src/app/common/oc3-constants';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
})
export class ShipmentListComponent implements OnChanges, OnInit, OnDestroy {
  @Input() shipmentData: any = [];
  @Input() totalShipmentCount: number = 0;
  @Input() dashboardShipment: boolean;
  @Input() dashboardWatchlist: boolean;
  @Input() shipmentProgressBar: any = [];
  @Output() emitOffset = new EventEmitter();
  @Input() urlShare = '';
  @ViewChild(DashboardShipmentListComponent)
  child!: DashboardShipmentListComponent;
  @ViewChild(DashboardWatchlistComponent)
  childWatch!: DashboardWatchlistComponent;
  @ViewChild(ShipmentDetailComponent)
  childShipment!: ShipmentDetailComponent;
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Output() reloadShipmentList = new EventEmitter<any>();
  @Input() urlShipmentId = '';
  @Input() getStatus = '';
  @Input() isShowToggle = true;
  edocUploadTigger = false;
  shareShipmentIdToChild = '';
  detailSharePopup = false;
  getShipmentDetails: any = [];
  isProgressShow = false;
  @Input() type: any = '';
  togglePickupDeliveryLegs: boolean = false;
  @Input() shipmentResult: boolean;
  @Input() loadingInProgress: boolean = false;
  popupToggle = false;
  isShow = true;
  popupToggleList = false;
  @Input()
  offset = 0;
  @Input()
  @Input()
  resultPageOffset = 0;
  @Input() currentStatus: number;
  @Input() selectedShipment: any = [];
  @Input() searchText: any = '';
  @Input() guidedTourContext: boolean;
  @Input() guidedTourDetail: any = '';
  @Input() publicShareFilter: any = '';
  @Input() publicShareData: any = '';
  @Input() publicShareType: any = '';
  @Input() publicShareVisibility = false;
  @Input() publicShareEnrcyptedData: any = '';
  @Input() isInfiniteDisabled = false;
  copyText: any = '';
  sessionUserRole: any = '';
  currentSessionUser: any;
  currentSessionAccount: any;
  shipmentImportWithOutDocumentSubmitted: boolean = false;
  shipmentImportWithDocumentSubmitted: boolean = false;
  isGuidedTourShipment: boolean;
  isShipmentSecHide: boolean;
  isShipmentSecShow: boolean;
  isShipmentToggleShow: boolean;
  singleShipmentShare: boolean = false;
  detailShipmentShare: boolean = false;
  isListComponentShow = false;
  countSetvalue: number = 0;
  isBiggerBrowser = false;
  isActionField = false;
  @Input() isTableView: boolean = false;
  @Input() isPickupAndDelivery: boolean = false;
  @Input() isCustomFilterAdded: boolean = false;
  tableViewColumnHeaderList: any = [];
  @Output() callLoadShipmentList = new EventEmitter();
  @Output() callFilterNavigator = new EventEmitter();
  @Output() callDenyProceedingFilter = new EventEmitter();
  isHeaderColumnSelectedIndex = -1;
  isGroup: boolean = false;
  showFilterSwitchWarningPopup: boolean = false;
  filterSwitchWarningMessage: string = '';
  filterSwitchHeaderMessage: string = '';
  showDocHintText: boolean = false;
  showActionHintText: boolean = false;
  showShareHintText: boolean = false;
  showMoreHintText: boolean = false;
  columnSearchDebounceText = new Subject();
  trackShipmentUpdateStatus: any = [];
  hasShipmentUpdateStatusCallExist: boolean = false;
  searchColumnInput: any = [];
  selectedColumnSearchParams: any = [];
  selectedColumnIndexList: any = [];

  constructor(
    private shipmentService: ShipmentServies,
    public currentUserDataService: CurrentSessionDataService,
    private dataSharingService: DataSharingService,
    private appInsightsService: AppInsightsService,
    private fileService: FileUploadService,
    private edocUploadStatusService: EdocUploadStatusService,
    private breakpointObserver: BreakpointObserver,
    private actionFieldService: ActionFieldService,
    private tableViewService: TableViewService,
    public renderer: Renderer2,
    private route: Router
  ) {
    this.breakpointObserver
      .observe(['(min-height: 1100px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.countSetvalue = OC3Constants.SHIPPING_LIST_DEFAULT_COUNT + 10;
        } else {
          this.countSetvalue = OC3Constants.SHIPPING_LIST_DEFAULT_COUNT; // limit has been set from 10 to 50 - 20/aug. reverting back to 10 22/aug
        }
      });
    this.currentSessionUser = this.currentUserDataService.getCurrentUserData();
    this.currentSessionAccount =
      this.currentUserDataService.getCurrentAccountData();
    this.sessionUserRole = this.currentSessionUser.userType;
    this.isGroup = this.currentSessionAccount.isGroup;
    this.isActionField = this.currentSessionAccount.isShipmentActionField;
    this.dataSharingService.isGuidedTourShipment.subscribe((value) => {
      this.isGuidedTourShipment = value;
    });
    this.dataSharingService.isGuidedTourShipmentSecHide.subscribe((value) => {
      this.isShipmentSecHide = value;
    });
    this.dataSharingService.isGuidedTourShipmentSecShow.subscribe((value) => {
      this.isShipmentSecShow = value;
    });
    this.dataSharingService.isGuidedTourToggleShow.subscribe((value) => {
      this.isShipmentToggleShow = value;
    });
    this.fileService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        let fileUploadPercentage: any[] = [];
        let cancelledDocs: any[] = [];
        setTimeout(() => {
          fileUploadPercentage = res;
          fileUploadPercentage.some((el: any) => {
            if (el.Percentage < 100 && !el.Error) {
              cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
            }
          });
          if (cancelledDocs.length > 0) {
            this.isListComponentShow = true;
          } else {
            this.isListComponentShow = false;
          }
        }, 0);
      }
    });

    this.fileService.reloadShipment.subscribe((value) => {
      if (value.reload) {
        this.dashboardShipment = false;
        this.dashboardWatchlist = false;
        this.selectedListShipment(value.shipmentId);
      }
    });

    this.dataSharingService.eDocUploadTrigger.subscribe((value: any) => {
      if (value.popup) {
        this.edocUploadTigger = true;
      } else if (value.detailPopup && this.childShipment) {
        this.childShipment.popUpClose();
      } else {
        this.edocUploadTigger = false;
      }
    });
  }

  ngOnInit(): void {
    this.loadTableViewColumns();
    this.columnSearchOnKeyPress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.togglePickupDeliveryLegs = this.isPickupAndDelivery;
    this.shipmentProgressBar = [
      {
        total_legs: 10,
        current_legs: 4,
        color: 'grey',
      },
      {
        total_legs: 14,
        current_legs: 9,
        color: 'blue',
      },
      {
        total_legs: 3,
        current_legs: 2,
        color: 'red',
      },
      {
        total_legs: 10,
        current_legs: 10,
        color: 'orange',
      },
      {
        total_legs: 2,
        current_legs: 2,
        color: 'blue',
      },
    ];

    let root = this;
    $(document).on('mouseup', function (e: any) {
      let container = $('#pop-up2');
      if (
        !container.is(e.target) &&
        container.has(e.target).length === 0 &&
        e.originalEvent.detail === 1
      ) {
        if (
          !root.popupToggleList &&
          !root.isGuidedTourShipment &&
          root.publicShareType != 'Detail' &&
          !root.edocUploadTigger
        ) {
          root.checkIsUploadInProgress();
        }
      }
    });
    if (this.publicShareFilter.type == 'Detail') {
      if (
        this.publicShareData.length > 0 &&
        this.publicShareData[0].isFullViewAccess
      ) {
        this.selectedListShipment(this.publicShareFilter.shipmentId);
        setTimeout(() => {
          this.openModel2(this.publicShareData);
        }, 100);
      } else {
        this.getShipmentDetails = this.publicShareData[0];
        this.openModel2(this.publicShareData);
      }
      setTimeout(() => {
        this.shareShipmentIdToChild = this.publicShareFilter.shipmentId;
      }, 0);
    }

    if (this.guidedTourDetail == 'true') {
      $('#' + this.shipmentData[0].forwarder_reference).click();
    } else if (this.guidedTourDetail == 'false') {
      this.popupToggle = false;
      $('#shipment-sec-id > div').removeClass('active');
      $('#shipment-table-view-sec > tr').removeClass('active');
    }
  }

  ngAfterViewInit() {
    const firstColumn = document.querySelector(
      'table th:first-child, table td:first-child'
    ) as HTMLElement;
    const lastColumn = document.querySelector(
      'table th:last-child, table td:last-child'
    ) as HTMLElement;
    const tableBody = document.querySelector('table tbody') as HTMLElement;

    tableBody?.addEventListener('scroll', () => {
      const scrollLeft = tableBody.scrollLeft;
      firstColumn.style.left = `${scrollLeft}px`;
      lastColumn.style.left = `${-scrollLeft}px`;
    });
  }

  columnSearchOnKeyPress() {
    this.columnSearchDebounceText
      .pipe(debounceTime(800))
      .subscribe((e: any) => {
        this.headerDropdownPopupMenuSearch(e[0], e[1], e[2], e[3]);
      });
  }

  start: any;
  pressed: any;
  startX: any;
  startWidth: any;
  onMouseDown(event: any, index: number) {
    if (index == 0) {
      return;
    }
    this.start = event.target;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = $(this.start).parent().width();
    this.initResizableColumns();
  }

  initResizableColumns() {
    this.renderer.listen('body', 'mousemove', (event: any) => {
      if (this.pressed) {
        let width = this.startWidth + (event.x - this.startX);
        $(this.start)
          .parent()
          .css({ 'min-width': width, 'max-   width': width });
        let index = $(this.start).parent().index() + 1;
        $('.glowTableBody tr td:nth-child(' + index + ')').css({
          'min-width': width,
          'max-width': width,
        });
      }
    });
    this.renderer.listen('body', 'mouseup', (event: any) => {
      if (this.pressed) {
        this.pressed = false;
      }
    });
  }

  getTableViewConfigurationListParam() {
    return {
      offset: 0,
      fetch: 1000,
      search_text: '',
      isGlobalSearch: false,
      parameters: [
        {
          name: this.isGroup ? 'groupId' : 'accountId',
          value: this.currentSessionAccount?.accountId,
        },
      ],
      sort_options: [
        {
          column_name: 'displayOrder',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'Shipments',
        },
      ],
    };
  }

  loadTableViewColumns() {
    if (this.route.url.toLowerCase().includes("/shipments")) { // checks if it is inside shipment page
      this.tableViewService
        .getTableViewConfiguration(this.getTableViewConfigurationListParam())
        .subscribe((res: any) => {
          let results = res.result;
          this.tableViewColumnHeaderList = [];
          results?.forEach((e: any) => {
            this.tableViewColumnHeaderList.push(e);
          });
          this.callLoadShipmentList.emit({
            type: 'columnList',
            data: this.tableViewColumnHeaderList,
          });
        });
    }
  }

  switchTab() {
    if (this.shipmentService.switchTabInitiated) {
      this.shipmentService.isActionFieldChanged = false;
      this.selectedListShipment(this.lastSelectedShipmentId, true);
      this.showActionFieldCancelModal = false;
    } else {
      this.closeTabModel();
    }
    this.shipmentService.switchTabInitiated = false;
  }

  closeTabModel() {
    this.showActionFieldCancelModal = false;
    this.popupToggle = false;
    $('#shipment-sec-id > div').removeClass('active');
    $('#shipment-table-view-sec > tr').removeClass('active');
    this.shipmentService.isActionFieldChanged = false;
    this.shipmentService.switchTabInitiated = false;
    this.shipmentService.openDocumentTab = false;
  }

  showActionFieldCancelModal: boolean = false;
  checkIsUploadInProgress() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      if (this.shipmentService.isActionFieldChanged) {
        this.showActionFieldCancelModal = true;
        this.retainTab();
      } else {
        this.showActionFieldCancelModal = false;
        this.closeTabModel();
      }
    } else {
      this.edocUploadTigger = true;
    }
  }

  retainTab() {
    $('#action-tab').tab('show');
  }

  calculateDiff(demandDate: any) {
    if (demandDate && demandDate != "errored") {
      let currentDate = new Date();
      let dateSent = new Date(demandDate);
      let hours = Math.floor(
        (Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate(),
            dateSent.getHours(),
            dateSent.getMinutes(),
            dateSent.getSeconds()
          )) /
        (1000 * 60 * 60)
      );
      return hours <= 24 ? true : false;
    } else {
      return false;
    }
  }

  // getShipmentReferenceNumbers(order: any, limit: number) {
  //   let msg = 'Ref: ';
  //   msg += CommonUtils.getShipmentReferenceNumbers(order, limit);
  //   return msg;
  // }

  /*onPickupDeliveryLegHandler(event: any) {
    this.togglePickupDeliveryLegs = !this.togglePickupDeliveryLegs;
  }*/

  // getStatusHint(data: any) {
  //   return CommonUtils.getStatusHint(data);
  // }

  // getProgressBarDetail(data: any): string {
  //   return CommonUtils.getShipmentListingProgressBar(
  //     data,
  //     this.togglePickupDeliveryLegs,
  //     this.searchText
  //   );
  // }

  trackAIPageView(shipmentId: any) {
    this.appInsightsService.logPageView(
      AI_PageName.ShipmentDetail,
      window.location.href,
      {
        'Shipment Id': shipmentId,
      }
    );
  }

  lastSelectedShipmentId: any;
  selectedListShipment(shipmentId: any, actionFlag?: boolean) {
    if (!this.shipmentService.isActionFieldChanged) {
      localStorage.removeItem('fileUploadStatus');
      this.trackAIPageView(shipmentId);
      $('#shipment-sec-id > div').removeClass('active');
      $('#shipment-table-view-sec > tr').removeClass('active');
      this.isProgressShow = true;
      let accountId = this.currentSessionAccount.accountId;
      let emailId = this.currentSessionUser.emailAddress;
      let isGroup = this.currentSessionAccount.isGroup;
      this.shipmentService
        .getShipmentsDetails(accountId, emailId, shipmentId, isGroup)
        .subscribe({
          next: (getDetails: any) => {
            this.isProgressShow = false;
            this.getShipmentDetails = getDetails.result[0];
            if (this.dashboardShipment || this.dashboardWatchlist) {
              this.selectedStatus(shipmentId, actionFlag);
            } else {
              this.openModel2(this.getShipmentDetails);
              if (actionFlag) {
                this.shipmentService.openActionTab = true;
              } else {
                this.shipmentService.openActionTab = false;
              }
            }
          },
        });
      this.shipmentService.switchTabInitiated = false;
    } else {
      this.shipmentService.switchTabInitiated = true;
      this.lastSelectedShipmentId = shipmentId;
    }
  }

  hideEdocUploadPopup() {
    this.edocUploadTigger = false;
  }

  /* istanbul ignore next */
  exitEdocPopup() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      this.edocUploadTigger = false;
    } else {
      this.dataSharingService.eDocUploadCancelTrigger.next(
        this.edocUploadStatusService.fileUploadStatus_LocalStorage()
      );
      this.edocUploadTigger = false;
    }
  }

  selectedStatus(selectedVal: any, actionFlag: any) {
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: {},
        selectedAccount: [],
      },
      destination: {
        selectedValue: {},
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: {},
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: {},
        selectedAccount: [],
      },
      consignee: {
        selectedValue: {},
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: {} },
          { destinationValue: {} },
          { pickupValue: {} },
          { finalDeliveryValue: {} },
        ],
      },
      isActionFlag: actionFlag ? true : false,
      sorting: [],
      accountId: '',
      shipmentId: selectedVal,
      type: 'Single',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    const url = environment.localUrl + '/shipments/' + encry;
    window.open(url, '_blank');
  }

  getPortAndCountryToolTipText(portName: any, country: any) {
    return (
      '<h6>' +
      (portName ? CommonUtils.toTitleCase(portName) + ', ' : '') +
      (country ? country : '') +
      '</h6>'
    );
  }

  addWatchList(action: any, shipmentId: any) {
    let eventName =
      action == 'Add'
        ? AI_CommonEvents.WatchListAdded.toString()
        : AI_CommonEvents.WatchListRemoved.toString();

    this.appInsightsService.trackAIWatchListEvent(
      eventName,
      shipmentId,
      AI_ModulesName.Shipment
    );

    let emailId = this.currentSessionUser.emailAddress;
    this.isProgressShow = true;

    this.shipmentService
      .addRemoveWatchList(shipmentId, emailId, action)
      .subscribe({
        next: (addRemoveWatch: any) => {
          this.isProgressShow = false;
          if (this.dashboardShipment || this.dashboardWatchlist) {
            this.reloadShipmentList.emit('reload');
          } else {
            //this.fetchSingleShipment(shipmentId);
            this.addOrRemoveShipmentWatchList(shipmentId, addRemoveWatch);
          }
        },
      });
  }

  setFavourate(shipmentId: any, flag: boolean) {
    let index = this.shipmentData
      .map(function (x: any) {
        return x.forwarder_reference;
      })
      .indexOf(shipmentId);
    this.shipmentData[index].favoriteflag = flag;
  }

  addOrRemoveShipmentWatchList(shipmentId: any, res: any) {
    if (res.result.toString().includes("Added to the Watchlist List")
      || res.result_type.toString().includes("Add WatchList")) {
      this.setFavourate(shipmentId, true);
    }
    else if (res.result.toString().includes("Removed from  the Watchlist List")
      || res.result_type.toString().includes("Remove WatchList")) {
      this.setFavourate(shipmentId, false);
    }
  }

  // TODO: to be refactored
  fetchSingleShipment(shipmentId: any) {
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: shipmentId,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      isTableView: this.isTableView,
      advanceFilterOptions: [],
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.shipmentService
      .getAllShipments(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        let filterMimeType = this.shipmentData
          .map(function (x: any) {
            return x.forwarder_reference;
          })
          .indexOf(shipmentId);
        this.shipmentData.splice(filterMimeType, 1, res.result[0]);
      });
  }

  // getExceptionClass(status: any, exception: any) {
  //   return CommonUtils.getExceptionClass(status, exception);
  // }

  openModel2(data: any) {
    this.urlShipmentId = '';
    this.popupToggle = true;
    $('#row-' + data.forwarder_reference).addClass('active');
    $('#tv-row-' + data.forwarder_reference).addClass('active');
  }

  //TODO:
  openActionField() {
    $('#action-tab').tab('show');
  }

  featureHide() {
    this.popupToggle = false;
  }

  // TODO: to be refactored
  onDetailPopupCloseHandler(event: any) {
    if (event.value != 'LOAD SHIPMENT') {
      this.popupToggle = false;
    }
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: event.shipmentId,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      isTableView: this.isTableView,
      advanceFilterOptions: [],
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.shipmentService
      .getAllShipments(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        let filterMimeType = this.shipmentData
          .map(function (x: any) {
            return x.forwarder_reference;
          })
          .indexOf(event.shipmentId);
        this.shipmentData.splice(filterMimeType, 1, res.result[0]);
        setTimeout(() => {
          $('#row-' + event.shipmentId).addClass('active');
          $('#tv-row-' + event.shipmentId).addClass('active');
        }, 0);
      });
  }

  onRefreshDetailFromDetail(event: any) {
    this.isProgressShow = true;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (event.value == 'LOAD DETAILS') {
      let accountId = this.currentSessionAccount.accountId;
      let emailId = this.currentSessionUser.emailAddress;
      let isGroup = this.currentSessionAccount.isGroup;
      this.shipmentService
        .getShipmentsDetails(accountId, emailId, event.shipmentId, isGroup)
        .subscribe({
          next: (getDetails: any) => {
            this.isProgressShow = false;
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
            this.getShipmentDetails = getDetails.result[0];
          },
        });
    }
  }

  scrollHandler(event: any) {
    if (this.type == 'searchScreen') {
      if (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5) {
        if (this.type == 'searchScreen') {
          this.emitOffset.emit({
            id: this.currentStatus,
            type: 'shipment',
          });
        }
      }
    }

  }

  onScroll(e: any) {
    if (this.type != 'searchScreen') {
      this.offset += this.countSetvalue;
      this.emitOffset.emit(this.offset);
    }
  }

  share(event: any, shipmentId: any, type: any, currentShipment: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ShareSingleItem.toString(),
      {
        [AI_CustomProps.ShipmentId]: shipmentId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
    this.detailShipmentShare = true;
    this.popupToggleList = true;
    let accountId = '';
    let aliasName = '';
    let isGroup = false;
    let isParent = false;
    if (!this.currentSessionAccount) {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      accountId = decryUrlFilter.accountDetail.oH_Code;
      aliasName = decryUrlFilter.accountDetail.aliasName;
      isGroup = decryUrlFilter.accountDetail.byGroup;
      isParent = decryUrlFilter.accountDetail.isControllingCustomer;
    } else {
      accountId = this.currentSessionAccount.accountId;
      aliasName = this.currentSessionAccount.accountAliasName;
      isGroup = this.currentSessionAccount.isGroup;
      isParent = this.currentSessionAccount.isParent;
    }

    this.prepareUrlShare.emit({
      type: type,
      shipmentId: shipmentId,
      accountId: accountId,
      aliasName: aliasName,
      popUp: true,
      isGroup: isGroup,
      shipment: currentShipment,
      isParent: isParent,
    });
  }

  detailShare(event: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.SharedAll.toString(), {
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
    });

    this.detailShipmentShare = true;
    this.prepareUrlShare.emit(event);
    if (event.popUp) {
      this.popupToggleList = true;
      this.detailSharePopup = event.popUp;
    }
  }

  sharePopUpfeatureHide() {
    this.singleShipmentShare = false;
    this.detailShipmentShare = false;
    this.popupToggleList = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  updateShipement(shipmentId: any, eDocImport: boolean) {
    let eventName = eDocImport
      ? AI_CommonEvents.JsonFileUpdatedWithDocument.toString()
      : AI_CommonEvents.JsonFileUpdated.toString();

    this.appInsightsService.logEvent(eventName, {
      [AI_CustomProps.ShipmentId]: shipmentId,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
    });

    let params = {
      shipment: shipmentId,
      accountId: this.currentSessionAccount.accountId,
      eDocImport: eDocImport,
      importUserDetails: [
        {
          userTriggerred: this.currentSessionUser.emailAddress,
        },
      ],
    };

    let success: boolean = false;
    this.shipmentService.updateShipment(params).subscribe((res) => {
      if (res.statusCode == 'OC200') {
        //this.fetchSingleShipment(shipmentId);
        success = true;
      }
    }, (error) => { }, () => {
      if (success) { this.updateShipmetStatus(shipmentId, eDocImport) };
    });

  }

  updateShipmetStatus(shipmentId: any, eDocImport: boolean) {
    let shipmentUpdateStatusParam: any = {
      id: shipmentId,
      withDoc: {
        callInitiated: eDocImport,
        callCompleted: false,
        numberOfCallsMade: 0,
        limitOfCalls: 3,
      },
      withoutDoc: {
        callInitiated: !eDocImport,
        callCompleted: false,
        numberOfCallsMade: 0,
        limitOfCalls: 3,
      },
      status: "",
      stack: false
    };
    let initialCall: boolean = false;
    let index = this.getShipmentIndex(shipmentUpdateStatusParam.id, this.trackShipmentUpdateStatus);
    if (index > -1) {
      this.updateStatusCallInitiated(index, shipmentUpdateStatusParam)
      initialCall = true;
    } else {
      this.trackShipmentUpdateStatus.push(shipmentUpdateStatusParam);
      initialCall = false;
    }
    this.updateShipmentStatusOnShipmentCollection(shipmentUpdateStatusParam.id, null, updateShipmentStatusCode.pending);
    if (initialCall || this.hasShipmentUpdateStatusCallExist) {
      this.updateStackList(shipmentUpdateStatusParam.id);
      return; // stopping the call, if calls made arleady for a shipment
    }
    this.getUpdatedShipmentStatus([shipmentUpdateStatusParam.id]);
  }

  updateStackList(shipmentId: any) {
    let index = this.getShipmentIndex(shipmentId, this.trackShipmentUpdateStatus);
    if (index > -1) {
      this.trackShipmentUpdateStatus[index].stack = true;
    }
  }


  updateStatusCallInitiated(index: number, shipmentUpdateStatusParam: any) {
    this.trackShipmentUpdateStatus[index].withDoc.callInitiated = this.trackShipmentUpdateStatus[index].withDoc.callInitiated ? this
      .trackShipmentUpdateStatus[index].withDoc.callInitiated : shipmentUpdateStatusParam.withDoc.callInitiated;
    this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated = this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated ? this
      .trackShipmentUpdateStatus[index].withoutDoc.callInitiated : shipmentUpdateStatusParam.withoutDoc.callInitiated;
  }

  updateCallCount(shipmentId: any) {
    let index = this.getShipmentIndex(shipmentId, this.trackShipmentUpdateStatus);
    if (this.trackShipmentUpdateStatus[index].withDoc.callInitiated) {
      this.trackShipmentUpdateStatus[index].withDoc.numberOfCallsMade++;
    }
    if (this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated) {
      this.trackShipmentUpdateStatus[index].withoutDoc.numberOfCallsMade++;
    }
  }

  getShipmentIndex(shipmentId: any, collection: any) {
    return collection.findIndex((e: any) => e.id == shipmentId);
  }

  isShipmentStatusCallSuccess: boolean = false;
  shipmentStatusApiCall: any;
  shipmentStatusSetTimeOutCall: any;
  getUpdatedShipmentStatus(shipmentId: any) {
    this.hasShipmentUpdateStatusCallExist = true;
    this.isShipmentStatusCallSuccess = false;
    this.shipmentStatusApiCall = this.shipmentService.shipmentUpdateStatus(shipmentId).subscribe((e: any) => {
      this.isShipmentStatusCallSuccess = true;
      let response = e.result;
      response?.forEach((e: any) => {
        let index = this.getShipmentIndex(e.id, this.trackShipmentUpdateStatus);
        this.updateCallCount(e.id);
        if (e.updateShipmentStatus.toLowerCase() == updateShipmentStatusCode.pending.toLowerCase()) {
          this.updateResponseStatusOnTrackList(index, updateShipmentStatusCode.pending);
          this.checkShipmentStatusUpdateCallLimitExceed(index, e.id);

        }
        else if (e.updateShipmentStatus.toLowerCase() == updateShipmentStatusCode.completed.toLowerCase()) {
          this.updateResponseStatusOnTrackList(index, updateShipmentStatusCode.completed);
          this.updateShipmentStatusOnShipmentCollection(e.id, e, updateShipmentStatusCode.completed);
        }
        else if (e.updateShipmentStatus.toLowerCase() == updateShipmentStatusCode.errored.toLowerCase()) {
          this.updateErrorStatus(index, e.id);
        }
        else {
          //   unknown status
          this.updateErrorStatus(index, e.id);
        }
      })
      this.shipmentStatusSetTimeOutCall = setTimeout(() => {
        this.makeAnotherCall();
      }, 1000 * 90);

    }, (error: any) => { }, () => {
      if (!this.isShipmentStatusCallSuccess) {
        // if api failed
        this.trackShipmentUpdateStatus?.forEach((e: any) => {
          let index = this.getShipmentIndex(e.id, this.trackShipmentUpdateStatus);
          this.updateErrorStatus(index, e.id);
        });
        this.clearTrack();
      }
    })
  }

  updateErrorStatus(index: number, id: any) {
    this.updateResponseStatusOnTrackList(index, updateShipmentStatusCode.errored);
    this.updateShipmentStatusOnShipmentCollection(id, null, updateShipmentStatusCode.errored);
  }

  makeAnotherCall() {
    console.log("track =>", this.trackShipmentUpdateStatus);
    let shipmentIdList: any = [];
    this.trackShipmentUpdateStatus.forEach((e: any, index: number) => {
      if ((e.status == updateShipmentStatusCode.pending || e.stack) && (!this.checkCallCompletedStatus(e)))
        shipmentIdList.push(e.id);
      this.trackShipmentUpdateStatus[index].stack = false;
    })

    if (shipmentIdList?.length > 0) {
      this.getUpdatedShipmentStatus(shipmentIdList);
    }
    else {
      this.clearTrack();
    }
    console.log("next list=>", shipmentIdList)
  }

  clearTrack() {
    this.trackShipmentUpdateStatus = [];
    this.hasShipmentUpdateStatusCallExist = false;
  }

  checkCallCompletedStatus(item: any) {
    let callCompletdStatuswithDoc: boolean = false;
    let callCompletdStatuswithoutDoc: boolean = false;
    if (item.withDoc.callInitiated) {
      if (item.withDoc.callCompleted) {
        callCompletdStatuswithDoc = true;
      }
    }
    else {
      callCompletdStatuswithDoc = true;
    }
    if (item.withoutDoc.callInitiated) {
      if (item.withoutDoc.callCompleted) {
        callCompletdStatuswithoutDoc = true;
      }
    }
    else {
      callCompletdStatuswithoutDoc = true;
    }

    if (callCompletdStatuswithoutDoc && callCompletdStatuswithDoc) {
      return true;
    }
    else {
      return false;
    }
  }

  updateResponseStatusOnTrackList(index: number, status: any) {
    this.trackShipmentUpdateStatus[index].status = status;
  }

  getStackList() {
    let list: any = [];
    this.trackShipmentUpdateStatus.forEach((e: any) => {
      if (e.stack) {
        list.push(e.id);
      }
    })
    return list;
  }

  updateCallCompletedStatus(index: number) {
    if (this.trackShipmentUpdateStatus[index].withDoc.callInitiated) {
      this.trackShipmentUpdateStatus[index].withDoc.callCompleted = true;
    }
    if (this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated) {
      this.trackShipmentUpdateStatus[index].withoutDoc.callCompleted = true;
    }
  }

  removeCompletedRecord(shipmentId: number) {
    let index = this.getShipmentIndex(shipmentId, this.trackShipmentUpdateStatus);
    if (index > -1) {
      this.trackShipmentUpdateStatus.splice(index, 1);
    }
    if (this.trackShipmentUpdateStatus?.length == 0) {
      this.hasShipmentUpdateStatusCallExist = false;
    }
  }

  updateShipmentStatusOnShipmentCollection(shipmentId: any, result: any, status: any) {
    let listIndex = this.getShipmentIndex(shipmentId, this.shipmentData);
    let trackIndex = this.getShipmentIndex(shipmentId, this.trackShipmentUpdateStatus);
    if (status.toLowerCase() == updateShipmentStatusCode.completed.toLocaleLowerCase()) {
      if (this.trackShipmentUpdateStatus[trackIndex].withoutDoc.callInitiated) {
        this.shipmentData[listIndex].reImportRequestCompletedWithoutDocuments = result.reImportRequestCompletedWithoutDocuments;
        this.shipmentData[listIndex].reImportRequestPendingWithoutDocuments = false;
      }
      if (this.trackShipmentUpdateStatus[trackIndex].withDoc.callInitiated) {
        this.shipmentData[listIndex].reImportRequestCompletedWithDocuments = result.reImportRequestCompletedWithDocuments;
        this.shipmentData[listIndex].reImportRequestPendingWithDocuments = false;
      }
    }
    else if (status.toLowerCase() == updateShipmentStatusCode.pending.toLocaleLowerCase()) {
      if (this.trackShipmentUpdateStatus[trackIndex].withDoc.callInitiated) {
        this.shipmentData[listIndex].reImportRequestPendingWithDocuments = true;
      }
      if (this.trackShipmentUpdateStatus[trackIndex].withoutDoc.callInitiated) {
        this.shipmentData[listIndex].reImportRequestPendingWithoutDocuments = true;
      }
    }
    else if (status.toLowerCase() == updateShipmentStatusCode.errored.toLocaleLowerCase()) {
      if (this.trackShipmentUpdateStatus[trackIndex].withDoc.callInitiated) {
        this.shipmentData[listIndex].reImportRequestCompletedWithDocuments = "errored";
      }
      if (this.trackShipmentUpdateStatus[trackIndex].withoutDoc.callInitiated) {
        this.shipmentData[listIndex].reImportRequestCompletedWithoutDocuments = "errored";
      }

    }
  }

  checkShipmentStatusUpdateCallLimitExceed(index: number, id: any) {
    let isCompletedWithDoc: boolean = false;
    let isCompletedWithoutDoc: boolean = false;
    if (this.trackShipmentUpdateStatus[index].withDoc.callInitiated) {
      if (this.trackShipmentUpdateStatus[index].withDoc.numberOfCallsMade >= this.trackShipmentUpdateStatus[index].withDoc.limitOfCalls) {
        this.trackShipmentUpdateStatus[index].withDoc.callCompleted = true;
        isCompletedWithDoc = true;
        this.updateErrorStatus(index, id);
      }
    }
    if (this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated) {
      if (this.trackShipmentUpdateStatus[index].withoutDoc.numberOfCallsMade >= this.trackShipmentUpdateStatus[index].withoutDoc.limitOfCalls) {
        this.trackShipmentUpdateStatus[index].withoutDoc.callCompleted = true;
        isCompletedWithoutDoc = true;
        this.updateErrorStatus(index, id);
      }
    }
    if (this.trackShipmentUpdateStatus[index].withDoc.callInitiated && this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated) {
      if (isCompletedWithDoc && isCompletedWithoutDoc) {
        return true;
      } else {
        return false;
      }
    } else if (this.trackShipmentUpdateStatus[index].withDoc.callInitiated && !this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated) {
      if (isCompletedWithDoc && !isCompletedWithoutDoc) {
        return true;
      } else {
        return false;
      }
    } else if (!this.trackShipmentUpdateStatus[index].withDoc.callInitiated && this.trackShipmentUpdateStatus[index].withoutDoc.callInitiated) {
      if (!isCompletedWithDoc && isCompletedWithoutDoc) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  actionFieldRecords: any = [];
  loadActionFieldsDetails(selectedShipment: any) {
    this.actionFieldRecords = [];

    this.actionFieldService
      .getSectionDetailsByOrgCode(
        this.currentSessionUser.accountId,
        selectedShipment.id,
        'shipments',
        this.currentSessionUser.isGroup
      )
      .subscribe(
        (e: any) => {
          this.isProgressShow = false;
          this.actionFieldRecords = [...e.result];
        },
        () => {
          this.isProgressShow = false;
        }
      );
  }

  initLoadActionFieldRecordsAssociated() {
    this.loadActionFieldsDetails(this.selectedShipment);
  }

  // TODO: to be refactored
  loadShipmentListById(id: any) {
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: id,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      isTableView: this.isTableView,
      advanceFilterOptions: [],
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.shipmentService
      .getAllShipments(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        this.replaceShipmentListValue(id, res.result[0]);
      });
  }

  replaceShipmentListValue(id: any, newItem: any) {
    let index = this.shipmentData.findIndex((e: any) => e.id === id);
    this.shipmentData.splice(index, 1, newItem);
    setTimeout(() => {
      $('#row-' + id).addClass('active');
      $('#tv-row-' + id).addClass('active');
    }, 100);
  }

  // getShipFromDetails(data: any) {
  //   let start_leg_text = '<div class="rap">';
  //   let searchText = '';
  //   start_leg_text +=
  //     '<span class="font-size-set">' +
  //     CommonUtils.getPortAndCountryToolTipText(
  //       CommonUtils.toTitleCase(data?.ship_from?.city),
  //       data?.ship_from?.state,
  //       data?.ship_from?.country_name,
  //       data?.ship_from?.country_name,
  //       searchText
  //     ) +
  //     '</span>';
  //   start_leg_text +=
  //     '<p class="m-0">' +
  //     CommonUtils.getStatusToolTipText(
  //       'Picked up',
  //       'Est Pickup',
  //       data?.origin_pickup_actual,
  //       data?.origin_pickup_estimated,
  //       ProgressBarType.ShipmentListingPage
  //     ) +
  //     '</p>';
  //   start_leg_text += '</div>';
  //   return start_leg_text; //CommonUtils.getShipToDetails(data);
  // }

  // getShipToDetails(data: any) {
  //   let start_leg_text = '<div class="rap">';
  //   let searchText = '';
  //   start_leg_text +=
  //     '<span class="font-size-set">' +
  //     CommonUtils.getPortAndCountryToolTipText(
  //       CommonUtils.toTitleCase(data?.ship_to?.city),
  //       data?.ship_to?.state,
  //       data?.ship_to?.country_name,
  //       data?.ship_to?.country_name,
  //       searchText
  //     ) +
  //     '</span>';
  //   start_leg_text +=
  //     '<p class="m-0">' +
  //     CommonUtils.getStatusToolTipText(
  //       'Delivered',
  //       'Est Delivery',
  //       data?.delivery_actual,
  //       data?.delivery_estimated,
  //       ProgressBarType.ShipmentListingPage
  //     ) +
  //     '</p>';
  //   start_leg_text += '</div>';

  //   return start_leg_text;
  // }

  // getDestinationLocation(data: any) {
  //   let displayText = '<div class="rap">';
  //   //if (data?.shipment_legs) {
  //   let shipment_legs = CommonUtils.sortShipmentLegsBySequenceNumber(data);

  //   let max_leg = shipment_legs != null ? data.shipment_legs[data.shipment_legs.length - 1] : {};
  //   displayText +=
  //     '<span class="font-size-set">' +
  //     CommonUtils.getPortAndCountryToolTipText(
  //       CommonUtils.toTitleCase(data?.final_destination_port?.portName),
  //       '',
  //       data?.final_destination_port?.country_name,
  //       data?.final_destination_port?.country_name,
  //       ''
  //     ) +
  //     '</span>';
  //   console.log('1', displayText)
  //   displayText += CommonUtils.getRoutingToolTipText(
  //     'ATA',
  //     'ETA',
  //     max_leg?.arrival_actual,
  //     max_leg?.arrival_estimated,
  //     ProgressBarType.ShipmentListingPage
  //   );
  //   //}

  //   displayText += '</div>';

  //   return displayText;
  // }

  // getDepartureLocation(data: any) {
  //   let displayText = '<div class="rap">';
  //   //if (data?.shipment_legs) {
  //   let shipment_legs = CommonUtils.sortShipmentLegsBySequenceNumber(data);
  //   let min_leg = shipment_legs != null ? shipment_legs[0] : {};
  //   displayText +=
  //     '<span class="font-size-set">' +
  //     CommonUtils.getPortAndCountryToolTipText(
  //       CommonUtils.toTitleCase(data?.origin_port?.portName),
  //       '',
  //       data?.origin_port?.country_name,
  //       data?.origin_port?.country_name,
  //       ''
  //     ) +
  //     '</span>';
  //   displayText += CommonUtils.getRoutingToolTipText(
  //     'ATD',
  //     'ETD',
  //     min_leg?.departure_actual, //departure_arrival
  //     min_leg?.departure_estimated,
  //     ProgressBarType.ShipmentListingPage
  //   );
  //   //}
  //   displayText += '</div>';

  //   return displayText;
  // }

  containerDisplayPopupIndex: number = -1;
  containerPopupClick(index: number) {
    this.containerDisplayPopupIndex = index;
  }

  headerDropdownPopupSortDirection: string = '';
  columnSortSelectedField: any;
  headerDropdownPopupSortDirectionClick(
    field: any,
    elementType: any,
    displayName: any
  ) {
    if (this.columnSortSelectedField != displayName) {
      this.headerDropdownPopupSortDirection = 'desc';
    }
    this.columnSortSelectedField = displayName;
    this.headerDropdownPopupSortDirection =
      this.headerDropdownPopupSortDirection == 'asc' ? 'desc' : 'asc';
    this.headerDropdownPopupSortDirection =
      this.headerDropdownPopupMenuIndex == -1
        ? ''
        : this.headerDropdownPopupSortDirection;
    this.callLoadShipmentList.emit({
      type: 'columnSort',
      field:
        elementType?.trim()?.toLowerCase() == 'data field'
          ? 'DFD-' + field
          : elementType?.trim()?.toLowerCase() == 'action field'
            ? 'AF-' + field
            : field,
      direction: this.headerDropdownPopupSortDirection,
    });
  }

  headerDropdownPopupMenuIndex: number = -1;
  headerDropdownPopupMenuClick(index: number) {
    this.headerDropdownPopupMenuIndex =
      this.headerDropdownPopupMenuIndex == index ? -1 : index;
    if (!this.searchColumnInput[index]) {
      this.searchColumnInput[index] = '';
    }
  }

  closeHeaderDropDown() {
    this.headerDropdownPopupMenuIndex = -1;
  }

  getField(fields: any, columnName: any) {
    return fields?.length == 1 ? fields[0].value : columnName;
  }

  generateColumnParam(elementType: any, field: any, index: number) {
    return {
      name:
        elementType?.trim()?.toLowerCase() == 'data field'
          ? 'DFD-' + field
          : elementType?.trim()?.toLowerCase() == 'action field'
            ? 'AF-' + field
            : field,
      value: this.searchColumnInput[index],
    };
  }

  addColumnParams(
    index: number,
    elementType: any,
    fields: any,
    columnName: any
  ) {
    let field = this.getField(fields, columnName);
    let param = this.generateColumnParam(elementType, field, index);
    let selectedColumnSearchParams = this.selectedColumnSearchParams;
    if (this.searchColumnInput[index] == ''
      && selectedColumnSearchParams.findIndex((e: any) => e?.name == param?.name) != -1) {
      let findIndex = this.selectedColumnIndexList.indexOf(index);
      this.selectedColumnIndexList.splice(findIndex, 1);
      this.selectedColumnSearchParams.splice(findIndex, 1);
    } else {
      if (!this.selectedColumnIndexList.includes(index) && this.searchColumnInput[index] != '') {
        this.selectedColumnIndexList.push(index);
      }

      if (
        selectedColumnSearchParams?.filter(
          (e: any) => e?.name == param?.name
        )?.length == 0 && this.searchColumnInput[index] != ''
      ) {
        this.selectedColumnSearchParams.push(param);
      } else if (
        selectedColumnSearchParams?.filter(
          (e: any) => e?.name == param?.name
        )?.length == 1
      ) {
        let index = selectedColumnSearchParams.findIndex((e: any) => e?.name == param?.name);
        this.selectedColumnSearchParams[index] = param;
      }
    }
  }

  headerDropdownPopupMenuSearch(
    name: string,
    fields: any,
    elementType: any,
    index: number
  ) {
    this.addColumnParams(index, elementType, fields, name);
    let selectedParams = this.selectedColumnSearchParams;
    this.callLoadShipmentList.emit({
      type: 'columnSearch',
      selectedValue: selectedParams.filter((e: any) => e !== ''),
    });
    this.isHeaderColumnSelectedIndex = index;
  }

  navigateToDocumentTab(data: any) {
    localStorage.setItem('selectedTab', 'document')
    this.shipmentService.openDocumentTab = true;
    this.selectedListShipment(data.forwarder_reference);
  }

  clearAllFilters() {
    this.showFilterSwitchWarningPopup = false;
    this.callFilterNavigator.emit(true);
  }

  denyProceedingNewFilter() {
    this.showFilterSwitchWarningPopup = false;
    this.callDenyProceedingFilter.emit(true);
  }

  clearText(
    name: string,
    fields: any,
    elementType: any,
    index: number
  ) {
    this.searchColumnInput[index] = '';
    this.headerDropdownPopupMenuSearch(name, fields, elementType, index);
    //this.searchColumnInput.length = 0;
  }

  trackById(index: number, item: any) {
    return item.forwarder_reference;
  }

  cancelShipmentStatusApiCall() {
    if (this.shipmentStatusApiCall != null) {
      this.shipmentStatusApiCall.unsubscribe();
    }
    clearTimeout(this.shipmentStatusSetTimeOutCall);
  }

  ngOnDestroy(): void {
    this.cancelShipmentStatusApiCall();
  }
}

enum updateShipmentStatusCode {
  pending = "Pending",
  errored = "Errored",
  completed = "Completed"
}
