import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonUtils } from 'src/app/common/utils';

@Pipe({
  name: 'highlight',
})
export class HighlightSearch implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, args: any): any {
     if (args.length > 2) {
      const replacedValue = CommonUtils.highlightSearchText(value, args);
      return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }
    else {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
