import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookingComponent } from './container/booking/booking.component';
import { RouterModule, Routes } from '@angular/router';
import { AI_PageName } from 'src/app/services/appinsights.service';

const routes: Routes = [
  {
    path: '',
    component: BookingComponent,
    data: { title: AI_PageName.Booking.toString() },
  },
  {
    path: ':filterParam',
    component: BookingComponent,
    data: { title: AI_PageName.Booking.toString() },
  },
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BookingRoutingModule { }
