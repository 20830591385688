import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
declare var $: any;

@Component({
  selector: 'app-all-location-filter',
  templateUrl: './all-location-filter.component.html',
  styleUrls: ['./all-location-filter.component.scss'],
})
export class AllLocationFilterComponent implements OnInit, OnChanges {
  locationFilter: any[] = [
    { status: 'Pickup', val: 0 },
    { status: 'Origin', val: 1 },
    { status: 'Destination', val: 2 },
    { status: 'Final Delivery', val: 3 },
  ];
  locationFilterValue = 'Pickup';
  selectedMobileValue = 'Pickup';
  searchText: string = '';
  selectedFilterText: string = 'Location: ';
  DISPLAY_TEXT_COUNT: number = 15;
  DEFAULT_DISPLAY_TEXT: string = 'Location: ';
  DEFAULT_DISPLAY_TEXT_MULTIPLE: string = 'Location: Multiple';
  accountDropdownToggle: boolean = false;
  locationToggleMobile: boolean = false;
  @Output() alllocationFilter = new EventEmitter();
  @Output() emitOffsetLoc = new EventEmitter();
  @Output() locationFilterValueEmit = new EventEmitter();
  @Input() offsetPickup: any;
  @Input() offsetOrigin: any;
  @Input() offsetDestination: any;
  @Input() offsetDelivery: any;
  @Input() allLocationList: any;
  @Output() locationFilterSearch = new EventEmitter();
  @Output() locationFilterClear = new EventEmitter();
  @Input() listData: any = [];
  @Input() isProgress: any;
  @Input() resentList: any;
  @Input() allLocationReselected: any = [];
  @Output() closeSelectedFilterControl = new EventEmitter();
  orginFilterSelectedList: any[] = [];
  pickupFilterSelectedList: any[] = [];
  destinationFilterSelectedList: any[] = [];
  finalDeliveryFilterSelectedList: any[] = [];
  orginRecentList: any[] = [];
  pickupRecentList: any[] = [];
  destinationRecentList: any[] = [];
  finalDeliveryRecentList: any[] = [];
  concatString = '';
  concatArray: any[] = [];
  selectedLocationFilterParameter: any;
  messageText: any[] = [];
  mutipleSelectedList: any[] = [];
  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  selectLocationDebounce = new Subject();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 767 ? true : false;
  }

  constructor(private appInsightsService: AppInsightsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resentList.length > 0) {
      this.orginRecentList = this.resentList[0].recentOriginList;
      this.destinationRecentList = this.resentList[1].recentDestinationList;
      this.pickupRecentList = this.resentList[2].recentPickupList;
      this.finalDeliveryRecentList = this.resentList[3].recentFinalDeliveryList;
    }

    if (this.allLocationReselected?.length > 0) {
      this.assignReselectedData(this.allLocationReselected);
    }
    if (
      this.isMobileView &&
      Object.keys(this.listData).length > 0 &&
      this.listData?.filterArray.length > 0
    ) {
      this.assignReselectedData(this.listData?.filterArray);
    }
  }

  allLocationDefaultCustomfilterM(value: any) {
    this.locationFilterValue = value;
    this.selectedMobileValue = value;
  }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  loadInitialPageData() {
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 767 ? true : false;
    this.saveFilterSelection();
    if (
      this.isMobileView &&
      Object.keys(this.listData).length > 0 &&
      this.listData?.filterArray.length > 0
    ) {
      this.assignReselectedData(this.listData?.filterArray);
    }
    this.setDocumentReadyEvent();
    this.selectLocationDebounce.pipe(debounceTime(1000)).subscribe((e) => {
      this.selectActivelocationFilter(e);
    });
  }
  /* istanbul ignore next */
  setDocumentReadyEvent() {
    let root = this;
    $(document).ready(function () {
      if (root.listData?.autoOpen) {
        root.accountDropdownToggle = true;
      }
    });

    $(document).on('mouseup', function (e: any) {
      if (
        e.target.id == 'accountSelect' ||
        e.originalEvent.path[1].id == 'btnGroupAddon' ||
        e.target.id == 'btnGroupAddon' ||
        e.target.id == 'profileSec' ||
        e.target.id == 'globalSearchIconDesktop'
      ) {
        root.accountDropdownToggle = false;
      }
    });
  }

  selectActivelocationFilter(filterValue: any) {
    this.searchText = '';
    this.locationFilterValue = filterValue.status;
    this.selectedMobileValue = filterValue.status;
    this.locationFilterValueEmit.emit(this.locationFilterValue);
    this.locationToggleMobile = false;
    this.setSectionScrollTop();
  }
  /* istanbul ignore next */
  setSectionScrollTop() {
    $('.scroll-sec').scrollTop(0);
  }

  assignReselectedData(data: any) {
    this.finalDeliveryFilterSelectedList = data[3]?.finalDelivery;
    this.orginFilterSelectedList = data[0]?.origin;
    this.destinationFilterSelectedList = data[1]?.destination;
    this.pickupFilterSelectedList = data[2]?.pickup;
  }

  onScroll(searchText: any) {
    this.checkFilterOffset(searchText);
  }

  checkFilterOffset(searchText: any) {
    let offsetRecords = 30;

    if (this.isMobileView) {
      offsetRecords = 10;
    }
    if (this.locationFilterValue == 'Pickup') {
      this.offsetPickup += offsetRecords;
      this.loadScrollValues(this.offsetPickup, 'pickup', searchText);
    } else if (this.locationFilterValue == 'Origin') {
      this.offsetOrigin += offsetRecords;
      this.loadScrollValues(this.offsetOrigin, 'origin', searchText);
    } else if (this.locationFilterValue == 'Destination') {
      this.offsetDestination += offsetRecords;
      this.loadScrollValues(this.offsetDestination, 'destination', searchText);
    } else if (this.locationFilterValue == 'Final Delivery') {
      this.offsetDelivery += offsetRecords;
      this.loadScrollValues(this.offsetDelivery, 'finalDelivery', searchText);
    }
  }

  loadScrollValues(offsetValue: any, filterName: any, searchText: any) {
    this.emitOffsetLoc.emit({
      offset: offsetValue,
      type: filterName,
      value: searchText,
    });
  }

  statusTextBoxHandler() {
    this.accountDropdownToggle = !this.accountDropdownToggle;
  }

  mobileLocationTextBoxHandler() {
    this.locationToggleMobile = !this.locationToggleMobile;
  }

  CloseOut() {
    this.accountDropdownToggle = false;
  }

  getSearchLocationList(searchText: any) {
    this.allLocationList = [];
    if (this.locationFilterValue == 'Pickup') {
      this.offsetPickup = 0;
      this.loadScrollValues(this.offsetPickup, 'pickup', searchText);
    } else if (this.locationFilterValue == 'Origin') {
      this.offsetOrigin = 0;
      this.loadScrollValues(this.offsetOrigin, 'origin', searchText);
    } else if (this.locationFilterValue == 'Destination') {
      this.offsetDestination = 0;
      this.loadScrollValues(this.offsetDestination, 'destination', searchText);
    } else if (this.locationFilterValue == 'Final Delivery') {
      this.offsetDelivery = 0;
      this.loadScrollValues(this.offsetDelivery, 'finalDelivery', searchText);
    }
    if (searchText.length > 2) {
      this.locationFilterSearch.emit({
        value: searchText,
        type: this.locationFilterValue,
      });
    }
  }

  clearData(data: any) {
    this.locationFilterClear.emit({
      value: data,
      type: this.locationFilterValue,
    });
  }

  onSearchHandler(value: any) {
    this.locationFilterSearch.emit({
      value: value,
      type: this.locationFilterValue,
    });
  }

  locationFilterSelected(selectedRow: any, index: any) {
    if (!selectedRow.index) {
      selectedRow.index = index;
    }
    switch (this.locationFilterValue) {
      case 'Pickup':
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected('Pickup');
        this.isDuplicate(selectedRow, index, 'Pickup');
        break;
      case 'Origin':
        if (
          this.checkDuplictateList(selectedRow, this.orginFilterSelectedList) <
          0
        ) {
          if (selectedRow.rL_Code) {
            this.concatArray = [];
            this.concatString = '';
            this.orginFilterSelectedList.push(selectedRow);
            this.mutipleSelected('Origin');
            this.setSelectedParameter(this.orginFilterSelectedList, index);
          }
        }
        break;
      case 'Destination':
        if (
          this.checkDuplictateList(
            selectedRow,
            this.destinationFilterSelectedList
          ) < 0
        ) {
          if (selectedRow.rL_Code) {
            this.concatArray = [];
            this.concatString = '';
            this.destinationFilterSelectedList.push(selectedRow);
            this.mutipleSelected('Destination');
            this.setSelectedParameter(
              this.destinationFilterSelectedList,
              index
            );
          }
        }
        break;
      case 'Final Delivery':
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected('Final Delivery');
        this.isDuplicate(selectedRow, index, 'Final Delivery');
        break;
      default:
        break;
    }
  }

  isDuplicate(entry: any, index: any, type: any) {
    if (type == 'Final Delivery') {
      if (entry.name) {
        let final = this.finalDeliveryFilterSelectedList.some(
          (x: any) =>
            entry.name == x.name &&
            entry.city == x.city &&
            entry.countryCode == x.countryCode
        );
        if (!final) {
          this.finalDeliveryFilterSelectedList.push(entry);
          this.setSelectedParameter(
            this.finalDeliveryFilterSelectedList,
            index
          );
        }
      }
    } else if (type == 'Pickup') {
      if (entry.name) {
        let pickup = this.pickupFilterSelectedList.some(
          (manuplated: any) =>
            entry.name == manuplated.name &&
            entry.city == manuplated.city &&
            entry.countryCode == manuplated.countryCode
        );
        if (!pickup) {
          this.pickupFilterSelectedList.push(entry);
          this.setSelectedParameter(this.pickupFilterSelectedList, index);
        }
      }
    }
  }

  mutipleSelected(type: any) {
    let elementPos = this.mutipleSelectedList
      .map(function (value: any) {
        return value;
      })
      .indexOf(type);

    if (elementPos < 0) {
      this.mutipleSelectedList.push(type);
    }
  }

  mutipleRemove(type: any) {
    let elementPos = this.mutipleSelectedList
      .map(function (value: any) {
        return value;
      })
      .indexOf(type);

    if (elementPos >= 0) {
      this.mutipleSelectedList.splice(elementPos, 1);
    }
  }

  checkDuplictateList(selectedRow: any, list: any) {
    let elementPos: any;
    if (this.checkSelectedType()) {
      elementPos = list
        .map(function (value: any) {
          return value.source_party_id;
        })
        .indexOf(selectedRow.source_party_id);
    } else {
      elementPos = list
        .map(function (value: any) {
          return value.rL_Code;
        })
        .indexOf(selectedRow.rL_Code);
    }

    return elementPos;
  }

  setSelectedParameter(list: any, index: any) {
    list.forEach((el: any) => {
      if (this.checkSelectedType()) {
        this.concatArray.push(el.name + ',' + el.city + ',' + el.countryCode);
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.source_party_id);
      } else {
        this.concatArray.push(el.rL_RN_NKCountryCode + ',' + el.rL_Code);
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.rL_Code);
      }
    });

    this.selectedLocationFilterParameter = {
      column_name: this.locationFilterValue,
      operater: '=',
      value: this.concatString,
    };
    this.alllocationFilter.emit({
      selectedValue: this.selectedLocationFilterParameter,
      type: this.locationFilterValue,
      account: list,
      clearFrom: '',
      removing: false,
    });
    if (this.mutipleSelectedList.length > 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT_MULTIPLE;
    } else if (this.mutipleSelectedList.length < 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT;
    } else {
      this.truncateDisplayText(this.messageText, list);
    }
  }

  truncateDisplayText(item: any, data: any) {
    if (!item || data.length <= 0) {
      this.selectedFilterText = this.mutipleSelectedList[0] + '- ';
    }
    if (item?.length > 0) {
      this.selectedFilterText =
        'Location: ' +
        this.mutipleSelectedList[0] +
        '- ' +
        item.join(', ').slice(0, this.DISPLAY_TEXT_COUNT);
      if (this.selectedFilterText.length > this.DISPLAY_TEXT_COUNT) {
        this.selectedFilterText =
          this.selectedFilterText.substring(0, this.DISPLAY_TEXT_COUNT) + '...';
      }
    }
  }

  checkSelectedType() {
    let value: any;
    if (
      this.locationFilterValue == 'Pickup' ||
      this.locationFilterValue == 'Final Delivery'
    ) {
      value = true;
    } else if (
      this.locationFilterValue == 'Origin' ||
      this.locationFilterValue == 'Destination'
    ) {
      value = false;
    }
    return value;
  }

  checkSelectedTypeRemoved(locationFilterValue: any) {
    let value: any;
    if (
      locationFilterValue == 'Pickup' ||
      locationFilterValue == 'Final Delivery'
    ) {
      value = true;
    } else if (
      locationFilterValue == 'Origin' ||
      locationFilterValue == 'Destination'
    ) {
      value = false;
    }
    return value;
  }

  childSelectedText(index: any, selectedChildRow: any, type: any) {
    this.concatArray = [];
    this.concatString = '';
    switch (type) {
      case 'Pickup':
        this.setUnselectedParameter(this.pickupFilterSelectedList, index, type);
        break;
      case 'Origin':
        this.setUnselectedParameter(this.orginFilterSelectedList, index, type);
        break;
      case 'Destination':
        this.setUnselectedParameter(
          this.destinationFilterSelectedList,
          index,
          type
        );
        break;
      case 'Final Delivery':
        this.setUnselectedParameter(
          this.finalDeliveryFilterSelectedList,
          index,
          type
        );
        break;
      default:
        break;
    }
  }

  setUnselectedParameter(childSelectedList: any, index: any, type: any) {
    childSelectedList.splice(index, 1);
    childSelectedList.forEach((el: any) => {
      if (this.checkSelectedTypeRemoved(type)) {
        this.concatArray.push(el.name + ',' + el.city + ',' + el.countryCode);
        this.concatString = this.concatArray.map((x) => x).join('|');
      } else {
        this.concatArray.push(el.rL_RN_NKCountryCode + ',' + el.rL_Code);
        this.concatString = this.concatArray.map((x) => x).join('|');
      }
    });

    this.selectedLocationFilterParameter = {
      column_name: type,
      operater: '=',
      value: this.concatString,
    };
    this.alllocationFilter.emit({
      selectedValue:
        this.concatString.length > 0
          ? this.selectedLocationFilterParameter
          : [],
      type: type,
      account: childSelectedList,
      clearFrom: '',
      removing: true,
    });

    this.pillDisplayTextRemove(index, type, childSelectedList);
  }

  pillDisplayTextRemove(index: any, type: any, childSelectedList: any) {
    this.messageText.splice(index, 1);
    switch (type) {
      case 'Origin':
        if (this.orginFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Destination':
        if (this.destinationFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Pickup':
        if (this.pickupFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Final Delivery':
        if (this.finalDeliveryFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
    }
    if (this.mutipleSelectedList.length > 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT_MULTIPLE;
    } else if (this.mutipleSelectedList.length == 0) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT;
    } else {
      this.truncateDisplayText(this.messageText, childSelectedList);
    }
  }

  clearFilters(filterIndex: any, filterId: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.LocationFilterCleared.toString(),
      { [AI_CustomProps.ModuleName]: AI_ModulesName.Booking }
    );

    this.closeSelectedFilterControl.emit({
      type: 'allLocation',
      index: filterIndex,
      id: filterId,
      eventType: 'closeBtn',
      closeFrom: '',
    });

    this.allLocationList = [];
  }

  saveFilterSelection() {
    if (
      Object.keys(this.listData).length > 0 &&
      this.listData?.filterArray.length > 0
    ) {
      this.finalDeliveryFilterSelectedList =
        this.listData?.filterArray[3].finalDelivery;
      this.orginFilterSelectedList = this.listData?.filterArray[0].origin;
      this.destinationFilterSelectedList =
        this.listData?.filterArray[1].destination;
      this.pickupFilterSelectedList = this.listData?.filterArray[2].pickup;
      this.listData.filterArray?.forEach((val: any) => {
        if (val.origin?.length > 0) {
          this.mutipleSelectedList.push('Origin');
          this.saveFilterPillText(val.origin, 'Orgin');
        } else if (val.finalDelivery?.length > 0) {
          this.mutipleSelectedList.push('Final Delivery');
          this.saveFilterPillText(val.finalDelivery, 'Final Delivery');
        } else if (val.destination?.length > 0) {
          this.mutipleSelectedList.push('Destination');
          this.saveFilterPillText(val.destination, 'Destination');
        } else if (val.pickup?.length > 0) {
          this.mutipleSelectedList.push('Pickup');
          this.saveFilterPillText(val.pickup, 'Pickup');
        }
      });
      this.locationFilterValueEmit.emit('Pickup');
    }
  }

  saveFilterPillText(list: any, type: any) {
    list.forEach((el: any) => {
      if (type == 'Pickup' || type == 'Final Delivery') {
        this.concatArray.push(el.name + ',' + el.city + ',' + el.countryCode);
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.source_party_id);
      } else {
        this.concatArray.push(el.rL_RN_NKCountryCode + ',' + el.rL_Code);
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.rL_Code);
      }
    });

    if (this.mutipleSelectedList.length > 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT_MULTIPLE;
    } else if (this.mutipleSelectedList.length < 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT;
    } else {
      this.truncateDisplayText(this.messageText, list);
    }
  }
}
