import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import { CustomsUtils } from './customs-utils';

@Pipe({
  name: 'customProgressBar',
})
export class CustomProgressBarPipe implements PipeTransform {
  transform(customData: any, args: any): any {
    if (!customData) {
      return '';
    }

    let htmlString = '';
    let hasThreeNodes = true;

    let table: any[] = [];
    let cssPercentage = 0;
    let portDetail = '';
    let dateDisplay = '';
    let toolTipText = '';

    for (let i = 1; i <= 3; i++) {
      portDetail = '';
      dateDisplay = '';
      toolTipText = '';

      /**
       *  Node 1: If customs_declarations.customs_type is "EXP" then
       * customs_declarations.port_of_clearance_description and
       * customs_declarations.export_country_code
       * Dep: customs_declarations.export_date;
       */
      /**
       * Node 3: If customs_declarations.customs_type is not "EXP" then
       * customs_declarations.port_of_clearance_description and
       * customs_declarations.import_country_code
       * Arr: customs_declarations.declaration_date
       */
      if (
        (i == 1 &&
          CustomsUtils.isCustomTypeExport(customData) &&
          !CustomsUtils.hasValidExportDate(customData)) ||
        (i == 3 &&
          !CustomsUtils.isCustomTypeExport(customData) &&
          !CustomsUtils.hasValidDeclarationDate(customData))
      ) {
        cssPercentage = -1;
        hasThreeNodes = false;
        htmlString += '';
      } else {
        cssPercentage = 100;
        portDetail = CustomsUtils.getInlineAddressForNode(customData, i);
        dateDisplay = CustomsUtils.getInlineDateForNode(customData, i);
        toolTipText = CustomsUtils.getToolTipForNode(customData, i);
      }

      table.push({
        nodeNumber: i,
        hasThreeNode: hasThreeNodes,
        cssPercentage: cssPercentage,
        cssClass: '',
        portDetail: portDetail,
        dateDisplay: dateDisplay,
        toolTipText: toolTipText,
      });
    }
    htmlString += this.generateProgressbar(table, args);

    if (hasThreeNodes) {
      htmlString = '<ul class="timeline">' + htmlString + '</ul>';
    } else {
      htmlString =
        '<ul class="timeline custom-single-node">' + htmlString + '</ul>';
    }
    return htmlString;
  }

  public generateProgressbar(table: any, searchText: any): string {
    let htmlString = '';
    let cssClass = '';
    for (let i = 0; i <= 2; i++) {
      let liCssClass = '';
      if (table[i].cssPercentage == -1) {
        htmlString += '';
      } else {
        cssClass = '<div class="custom-width-percent hundredpercent"></div>';
        if (table[i].cssClass == '80') {
          cssClass = '<div class="custom-width-percent eightypercent"></div>';
          liCssClass = 'no-circle';
        }
        if (table[i].cssClass == '0') {
          cssClass = '<div class="custom-width-percent"></div>';
          liCssClass = 'no-circle';
        }
        htmlString += '<li class="' + liCssClass + '">';
        htmlString += cssClass;
        htmlString += '<div class="rap">';
        htmlString += '<h6 class="title">' + CommonUtils.highlightSearchText(table[i].portDetail, searchText) + '</h6>';
        htmlString += '<p class="country">' + table[i].dateDisplay + '</p>';
        htmlString +=
          '<p class="tooltip-custom">' + table[i].toolTipText + '</p>';
        htmlString += '</div>';
        htmlString += '</li>';
      }
    }

    return htmlString;
  }
}
