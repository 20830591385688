import { Injectable } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';

@Injectable()
export class ShipmenListServies {
  shipmentData: any = [];
  totalShipmentCount: number = 0;
  shipmentProgressBar: any = [];
  urlShare = '';
  urlShipmentId = '';
  shareShipmentIdToChild = '';
  togglePickupDeliveryLegs: boolean = false;
  popupToggle = false;
  isShow = true;
  popupToggleList = false;
  searchText = '';
  offset = 0;
  selectedShipment: any = [];
  copyText: any = '';

  getTransportModeImagePath(mode: any) {
    return CommonUtils.getTransportModeImagePath(mode);
  }

  getShipmentReferenceNumbers(order: any, limit: number) {
    return CommonUtils.getShipmentReferenceNumbers(order, limit);
  }

  onPickupDeliveryLegHandler(event: any) {
    this.togglePickupDeliveryLegs = !this.togglePickupDeliveryLegs;
  }

  getStatusHint(data: any) {
    return CommonUtils.getStatusHint(data);
  }

  getProgressBarDetail(data: any): string {
    return CommonUtils.getShipmentListingProgressBar(
      data,
      this.togglePickupDeliveryLegs,
      this.searchText
    );
  }

  getExceptionClass(status: any, exception: any) {
    return CommonUtils.getExceptionClass(status, exception);
  }

  openModel2(data: any) {
    this.urlShipmentId = '';
    this.selectedShipment = data;
    this.popupToggle = true;
    $('#row-' + data.forwarder_reference).addClass('active');
  }
}
