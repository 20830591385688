import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { EdocUploadStatusService } from 'src/app/shared/services/eDoc-upload-status.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { environment } from 'src/environments/environment';
import { BookingServies } from '../../services/booking.service';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { OC3Constants } from 'src/app/common/oc3-constants';
import { CommonUtils } from 'src/app/common/utils';
declare var $: any;
@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss'],
})
export class BookingListComponent implements OnInit, OnChanges {
  @Output() reloadBookingList = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Input() bookingMasterData: any = [];
  @Input() totalBookingCount: number = 0;
  @Input() isDashboard: any;
  @Input() isBookingsWatchlist: any;
  @Input() searchText: any = '';
  @Input() dashboardShipment: boolean;
  @Input() dashboardWatchlist: boolean;
  @Input() shipmentSearchText: string = '';
  @Input() isProgressShow: boolean = false;
  @Input() globalSearchPopup: boolean = false;
  @Input() urlBookingId: any = '';
  @Input() urlShare = '';
  @Input() offset = 0;
  @Output() emitOffset = new EventEmitter();
  @Input() type: any = '';
  @Input() currentStatus: number;
  @ViewChild(BookingDetailsComponent)
  childBookings!: BookingDetailsComponent;
  @Input()
  resultPageOffset = 0;
  @Input() globalSearch: boolean;
  @Input() publicShareEnrcyptedData: any = '';
  @Input() isInfiniteDisabled = false;
  copyText: any = '';
  singleBookingShare: boolean = false;
  getBookingDetails: any = [];
  popupToggle = false;
  sessionUserRole: any = '';
  currentSessionUser: any;
  currentSessionAccount: any;
  bookingSharePopup = false;
  shareBookingIdToChild: any = '';
  detailShipmentShare: boolean = false;
  edocUploadTigger = false;
  isListComponentShow = false;
  isGuidedTourBooking: boolean;
  isBookingSecHide: boolean;
  isBookingSecShow: boolean;
  isToggleShow: boolean = false;
  @Input() guidedTourDetail: any = '';
  countSetvalue: number = 0;
  showActionFieldCancelModal: boolean = false;
  lastSelectedBookingId: any;
  hasActionField: boolean = false;

  /* istanbul ignore next */
  constructor(
    private bookingServies: BookingServies,
    private dataSharingService: DataSharingService,
    public currentUserDataService: CurrentSessionDataService,
    private appInsightsService: AppInsightsService,
    private fileService: FileUploadService,
    private edocUploadStatusService: EdocUploadStatusService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(['(min-height: 1100px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.countSetvalue = OC3Constants.BOOKING_LIST_DEFAULT_COUNT + 10;
        } else {
          this.countSetvalue = OC3Constants.BOOKING_LIST_DEFAULT_COUNT;
        }
      });
    this.currentSessionUser = this.currentUserDataService.getCurrentUserData();
    this.currentSessionAccount =
      this.currentUserDataService.getCurrentAccountData();
    this.hasActionField = this.currentSessionAccount.isBookingActionField;
    this.sessionUserRole = this.currentSessionUser.userType;

    this.fileService.reloadBookings.subscribe((value) => {
      if (value.reload) {
        this.dashboardShipment = false;
        this.dashboardWatchlist = false;
        this.globalSearch = false;
        this.selectedListBooking(value.shipmentId);
      }
    });

    this.dataSharingService.eDocUploadTrigger.subscribe((value: any) => {
      if (value.popup) {
        this.edocUploadTigger = true;
      } else if (value.detailPopup && this.childBookings) {
        this.childBookings.popUpClose();
      } else {
        this.edocUploadTigger = false;
      }
    });

    this.fileService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        let fileUploadPercentage: any[] = [];
        let cancelledDocs: any[] = [];
        setTimeout(() => {
          fileUploadPercentage = res;
          fileUploadPercentage.some((el: any) => {
            if (el.Percentage < 100 && !el.Error) {
              cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
            }
          });
          if (cancelledDocs.length > 0) {
            this.isListComponentShow = true;
          } else {
            this.isListComponentShow = false;
          }
        }, 0);
      }
    });
    this.dataSharingService.isGuidedTourShipment.subscribe((value) => {
      this.isGuidedTourBooking = value;
    });
    this.dataSharingService.isGuidedTourShipmentSecHide.subscribe((value) => {
      this.isBookingSecHide = value;
    });
    this.dataSharingService.isGuidedTourShipmentSecShow.subscribe((value) => {
      this.isBookingSecShow = value;
    });
    this.dataSharingService.isGuidedTourToggleShow.subscribe((value) => {
      this.isToggleShow = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.guidedTourDetail == 'true') {
      this.selectedListBooking(this.bookingMasterData[0].forwarder_reference);
    } else if (this.guidedTourDetail == 'false') {
      this.popupToggle = false;
      $('#shipment-sec-id > div').removeClass('active');
    }
  }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  loadInitialPageData() {
    if (this.urlBookingId) {
      this.selectedListBooking(this.urlBookingId);
      setTimeout(() => {
        this.shareBookingIdToChild = this.urlBookingId;
      }, 1500);
    }
    this.setDocumentEvent();
  }

  /* istanbul ignore next */
  setDocumentEvent() {
    let root = this;
    $(document).on('mouseup', function (e: any) {
      let container = $('#pop-up2');
      if (
        !container.is(e.target) &&
        container.has(e.target).length === 0 &&
        e.originalEvent.detail === 1
      ) {
        if (
          !root.bookingSharePopup &&
          !root.edocUploadTigger &&
          !root.isGuidedTourBooking
        ) {
          root.checkIsUploadInProgress();
        }
      }
    });
  }

  checkIsUploadInProgress() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      if (this.bookingServies.isActionFieldChanged) {
        this.showActionFieldCancelModal = true;
        this.retainTab();
      } else {
        this.showActionFieldCancelModal = false;
        this.closeTabModel();
      }
    } else {
      this.edocUploadTigger = true;
    }
  }

  getBookingReferenceNumbers(order: any, limit: number) {
    let msg = 'Job Ref: ';
    msg += CommonUtils.getShipmentReferenceNumbers(order, limit);
    return msg;
  }

  featureHide() {
    this.popupToggle = false;
    this.bookingServies.isActionFieldChanged = false;
    this.bookingServies.switchTabInitiated = false;
    $('#shipment-sec-id > div').removeClass('active');
  }

  getStatusHint(data: any) {
    return CommonUtils.getStatusHint(data);
  }

  getBookingExceptionClass(status: any, exception: any) {
    return CommonUtils.getBookingExceptionClass(status, exception);
  }

  getTrimmedAddressData(city: any, state: any, country: any) {
    let message = '';
    if (city) message += CommonUtils.toTitleCase(city) + ', ';
    message += CommonUtils.getUSCanadaStateText(country, state);
    if (country) message += country + ', ';
    return CommonUtils.removeLastComma(message);
  }

  getStatusData(statueName: any, displayDate: any) {
    let message = '';
    if (!statueName || !displayDate) return message;
    message += statueName + ': ';
    message += displayDate ? CommonUtils.formatDateMMMDD(displayDate) : '---';
    return message;
  }

  onRefreshDetailFromDetail(event: any) {
    this.isProgressShow = true;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (event.value == 'LOAD DETAILS') {
      let accountId = this.currentSessionAccount.accountId;
      let emailId = this.currentSessionUser.emailAddress;
      let isGroup = this.currentSessionAccount.isGroup;
      this.bookingServies
        .getBookingDetails(accountId, emailId, event.shipmentId, isGroup)
        .subscribe({
          next: (getDetails: any) => {
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
            this.isProgressShow = false;
            this.getBookingDetails = getDetails.result[0];
          },
        });
    }
  }

  switchTab() {
    if (this.bookingServies.switchTabInitiated) {
      this.bookingServies.isActionFieldChanged = false;
      this.selectedListBooking(this.lastSelectedBookingId, true);
      this.showActionFieldCancelModal = false;
    } else {
      this.closeTabModel();
    }
    this.bookingServies.switchTabInitiated = false;
  }

  /* istanbul ignore next */
  retainTab() {
    $('#action-tab').tab('show');
  }

  navigateToDocumentTab(data: any) {
    localStorage.setItem('selectedTab', 'document/bookings');
    this.selectedListBooking(data.forwarder_reference);
  }

  closeTabModel() {
    this.showActionFieldCancelModal = false;
    this.popupToggle = false;
    this.bookingServies.isActionFieldChanged = false;
    this.bookingServies.switchTabInitiated = false;
    $('#shipment-sec-id > div').removeClass('active');
  }

  selectedListBooking(bookingId: any, actionFlag?: boolean) {
    if (actionFlag) {
      localStorage.setItem('selectedTab', 'Action')
    }
    if (!this.bookingServies.isActionFieldChanged) {
      localStorage.removeItem('fileUploadStatus');

      this.appInsightsService.logEvent(
        AI_CommonEvents.DetailTabViewed.toString(),
        {
          [AI_CustomProps.BookingId]: bookingId,
          [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
        }
      );

      $('#shipment-sec-id > div').removeClass('active');
      this.isProgressShow = true;
      let accountId = this.currentSessionAccount.accountId;
      let emailId = this.currentSessionUser.emailAddress;
      let isGroup = this.currentSessionAccount.isGroup;
      this.bookingServies
        .getBookingDetails(accountId, emailId, bookingId, isGroup)
        .subscribe({
          next: (getDetails: any) => {
            this.isProgressShow = false;
            this.getBookingDetails = getDetails.result[0];
            if (
              this.dashboardShipment ||
              this.dashboardWatchlist ||
              this.globalSearch
            ) {
              this.selectedStatus(bookingId, actionFlag);
            } else {
              this.popupToggle = true;
              $('#row-' + this.getBookingDetails.forwarder_reference).addClass(
                'active'
              );

              if (actionFlag) {
                // localStorage.setItem('selectedTab', 'Action')
                this.bookingServies.openActionTab = true;
                // this.openActionField();
              } else {
                this.bookingServies.openActionTab = false;
              }
            }
          },
        });
      this.bookingServies.switchTabInitiated = false;
    } else {
      this.bookingServies.switchTabInitiated = true;
      this.lastSelectedBookingId = bookingId;
    }
  }

  selectedStatus(selectedVal: any, actionFlag: any) {
    if (actionFlag) {
      localStorage.setItem('selectedTab', 'Action')
    }
    // this.bookingServies.openActionTab = true;
    // this.openActionField();
    // } else {
    //   this.bookingServies.openActionTab = false;
    // }
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      watchFilter: [],
      origin: {
        selectedValue: [],
        selectedAccount: [],
      },
      destination: {
        selectedValue: [],
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },

      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: [] },
          { destinationValue: [] },
          { pickupValue: [] },
          { finalDeliveryValue: [] },
        ],
      },
      sorting: [],
      accountId: '',
      bookingId: selectedVal,
      type: 'Single',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    const url = environment.localUrl + '/bookings/' + encry;
    window.open(url, '_blank');
  }

  addWatchList(action: any, bookingId: any) {
    if (action == 'Add') {
      this.appInsightsService.trackAIWatchListEvent(
        AI_CommonEvents.WatchListAdded.toString(),
        bookingId,
        AI_ModulesName.Booking
      );
    }
    if (action == 'Remove') {
      this.appInsightsService.trackAIWatchListEvent(
        AI_CommonEvents.WatchListRemoved.toString(),
        bookingId,
        AI_ModulesName.Booking
      );
    }
    let emailId = this.currentSessionUser.emailAddress;
    this.isProgressShow = true;
    this.bookingServies
      .addRemoveWatchList(bookingId, emailId, action)
      .subscribe({
        next: (addRemoveWatch: any) => {
          this.isProgressShow = false;
          if (this.dashboardShipment || this.dashboardWatchlist) {
            this.reloadBookingList.emit('reload');
          } else {
            this.fetchSingleBooking(bookingId);
          }
        },
      });
  }

  fetchSingleBooking(bookingId: any) {
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: bookingId,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.bookingServies
      .getAllBookings(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        let filterMimeType = this.bookingMasterData
          .map(function (x: any) {
            return x.forwarder_reference;
          })
          .indexOf(bookingId);
        this.bookingMasterData.splice(filterMimeType, 1, res.result[0]);
      });
  }

  updateBookings(bookingId: any, eDocImport: boolean) {
    let eventName = eDocImport
      ? AI_CommonEvents.JsonFileUpdatedWithDocument.toString()
      : AI_CommonEvents.JsonFileUpdated.toString();

    this.appInsightsService.logEvent(eventName, {
      [AI_CustomProps.BookingId]: bookingId,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });

    let params = {
      shipment: bookingId,
      accountId: this.currentSessionAccount.accountId,
      eDocImport: eDocImport,
      importUserDetails: [
        {
          userTriggerred: this.currentSessionUser.emailAddress,
        },
      ],
    };
    this.bookingServies.updateBookings(params).subscribe((res) => {
      if (res.statusCode == 'OC200') {
        this.fetchSingleBooking(bookingId);
      }
    });
  }

  share(event: any, bookingId: any, type: any, currentBooking: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ShareSingleItem.toString(),
      {
        [AI_CustomProps.BookingId]: bookingId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
    this.bookingSharePopup = true;
    this.detailShipmentShare = true;
    let accountId = '';
    let aliasName = '';
    let isGroup = false;
    let isParent = false;
    if (!this.currentSessionAccount) {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      accountId = decryUrlFilter.accountDetail.oH_Code;
      aliasName = decryUrlFilter.accountDetail.aliasName;
      isGroup = decryUrlFilter.accountDetail.byGroup;
      isParent = decryUrlFilter.accountDetail.isControllingCustomer;
    } else {
      accountId = this.currentSessionAccount.accountId;
      aliasName = this.currentSessionAccount.accountAliasName;
      isGroup = this.currentSessionAccount.isGroup;
      isParent = this.currentSessionAccount.isParent;
    }
    this.prepareUrlShare.emit({
      type: type,
      bookingId: bookingId,
      accountId: accountId,
      aliasName: aliasName,
      popUp: true,
      isGroup: isGroup,
      shipment: currentBooking,
      isParent: isParent,
    });
  }

  detailShare(event: any) {
    this.prepareUrlShare.emit(event);
    if (event.popUp) {
      this.bookingSharePopup = true;
    }
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  closeUserFilterPopUp() {
    this.bookingSharePopup = false;
  }

  viewShipment(bookingId: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.ViewShipment.toString(), {
      [AI_CustomProps.BookingId]: bookingId,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });
    let selectedFilters: any;
    if (this.currentSessionAccount.shipmentIsVisible) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: bookingId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    } else {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: bookingId,
        type: 'Single',
        module: 'Shipment',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/dashboard/' + encry;
      window.open(url, '_blank');
    }
  }

  calculateDiff(demandDate: any) {
    if (demandDate) {
      let currentDate = new Date();
      let dateSent = new Date(demandDate);
      let hours = Math.floor(
        (Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate(),
            dateSent.getHours(),
            dateSent.getMinutes(),
            dateSent.getSeconds()
          )) /
        (1000 * 60 * 60)
      );
      return hours <= 24 ? true : false;
    } else {
      return false;
    }
  }

  scrollHandler(event: any) {
    if (this.type == 'searchScreen') {
      if (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5) {
        this.emitOffset.emit({
          id: this.currentStatus,
          type: 'booking',
        });
      }
    }
  }

  onScroll(e: any) {
    if (this.type != 'searchScreen') {
      this.offset += this.countSetvalue;
      this.emitOffset.emit(this.offset);
    }
  }

  onDetailPopupCloseHandler(event: any) {
    if (event.value != 'LOAD SHIPMENT') {
      this.popupToggle = false;
    }
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: event.bookingId,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.bookingServies
      .getAllBookings(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        let filterMimeType = this.bookingMasterData
          .map(function (x: any) {
            return x.forwarder_reference;
          })
          .indexOf(event.bookingId);
        this.bookingMasterData.splice(filterMimeType, 1, res.result[0]);
        setTimeout(() => {
          $('#row-' + event.bookingId).addClass('active');
        }, 0);
      });
  }

  // getRefNum(refNum: any, shipperName: any) {
  //   if (shipperName) {
  //     return ' - Ref: ' + refNum;
  //   } else {
  //     return 'Ref: ' + refNum;
  //   }
  // }

  hideEdocUploadPopup() {
    this.edocUploadTigger = false;
  }
  /* istanbul ignore next */
  exitEdocPopup() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      this.edocUploadTigger = false;
    } else {
      this.dataSharingService.eDocUploadCancelTrigger.next(
        this.edocUploadStatusService.fileUploadStatus_LocalStorage()
      );
      this.edocUploadTigger = false;
    }
  }
  /* istanbul ignore next */
  //TODO:
  openActionField() {
    setTimeout(() => {
      $('#action-tab').tab('show');
    }, 20);
  }

  loadBookingListById(id: any) {
    let accountId = this.currentSessionAccount.accountId;
    let emailId = this.currentSessionUser.emailAddress;
    let isGroup = this.currentSessionAccount.isGroup;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: id,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    this.bookingServies
      .getAllBookings(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        this.replaceBookingListValue(id, res.result[0]);
      });
  }

  replaceBookingListValue(id: any, newItem: any) {
    let index = this.bookingMasterData.findIndex((e: any) => e.id === id);
    this.bookingMasterData.splice(index, 1, newItem);
    setTimeout(() => {
      $('#row-' + id).addClass('active');
    }, 100);
  }

  trackById(index: number, item: any) {
    return item.forwarder_reference;
  }
}
