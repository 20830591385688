<div [hidden]="closePopup" class="newuser-toast-bg">
    <div class="text-center contain-bg">
        <span class="newuser-toast-txt">
            <img src="../../assets/img/okay.png" alt="" />
            <img class="new-close-toast mr-1" src="../../assets/img/Union.svg" alt="" />
            {{ message }}
        </span>
        <span class="close-button" (click)="close()">
            <img src="../../assets/img/close_whitebg_remove.svg" alt="" />
            <img class="new-close-toast close-set" src="../../assets/img/new-close.svg" alt="" />
        </span>
    </div>
</div>