import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  AI_CustomProps,
  AI_PageName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent implements OnInit {
  recentSearch: any[] = [];
  currentAccountData: any;
  isShipmentIsVisible: false;
  isBookingIsVisible: false;
  isCustomsIsVisible: false;

  constructor(
    public router: Router,
    private sessionData: CurrentSessionDataService,
    public dataShareService: DataSharingService,
    private appInsightsService: AppInsightsService
  ) {
    this.currentAccountData = this.sessionData.getCurrentAccountData();
    this.isShipmentIsVisible = this.currentAccountData.shipmentIsVisible;
    this.isBookingIsVisible = this.currentAccountData.bookingIsVisible;
    this.isCustomsIsVisible = this.currentAccountData.customsIsVisible;
  }

  ngOnInit(): void {
    this.loadRecentSearch();
    $(document).ready(function () {
      $('.Mobile-Menu-Btn').on('click', function (e: any) {
        if ($('.quick-search-wrap').is(':visible')) {
          $('.quick-search-wrap').hide();
        }
      });
    });
  }

  loadRecentSearch() {
    let getLocalStorageValue = localStorage.getItem('globalRecentSearch');
    this.recentSearch =
      getLocalStorageValue !== null ? JSON.parse(getLocalStorageValue) : '';
  }

  @Output()
  closeQuickSearchModal = new EventEmitter<any>();

  quickSearchButtons: any[] = [
    { name: 'Pickup Scheduled', val: 1 },
    { name: 'Picked Up', val: 2 },
    { name: 'Scheduled', val: 3 },
    { name: 'In Transit', val: 4 },
    { name: 'Arrived', val: 5 },
    { name: 'Delivery Scheduled', val: 6 },
    { name: 'Delivered', val: 7 },
  ];

  selectedStatus(selectedVal: any) {
    this.trackAISearchClickEvent('Quick Search', selectedVal?.name);
    this.closeQuickSearchModal.emit(false);
    let selectedFilters = {
      mainFilter: [
        { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 1 },
      ],
      statusFilter: [
        {
          column_name: 'statuscustom',
          operator: '=',
          value: selectedVal.val.toString(),
        },
      ],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: {},
        selectedAccount: [],
      },
      destination: {
        selectedValue: {},
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: {},
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: {},
        selectedAccount: [],
      },
      consignee: {
        selectedValue: {},
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: {} },
          { destinationValue: {} },
          { pickupValue: {} },
          { finalDeliveryValue: {} },
        ],
      },
      sorting: [],
      accountId: '',
      shipmentId: '',
      type: 'defaultFilter',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    this.dataShareService.quickSearch.next(encry);
    this.router.navigate(['/shipments'], {
      skipLocationChange: true,
    });
  }

  resultPage(val: any) {
    this.trackAISearchClickEvent('Recent Search', val);
    this.closeQuickSearchModal.emit(false);
    let filterVal = JSON.stringify({ val: val, from: '' });
    this.router.navigate(['/shipments/view/search-result'], {
      queryParams: { filterParam: filterVal },
      skipLocationChange: true,
    });
  }

  trackAISearchClickEvent(title: string, searchText: any) {
    this.appInsightsService.logEvent(AI_PageName.GlobalSearch, {
      [AI_CustomProps.ModuleName]: title,
      [AI_CustomProps.SearchText]: searchText,
    });
  }
}
