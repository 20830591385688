import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-customs-details-progressbar',
  templateUrl: './customs-details-progressbar.component.html',
  styleUrls: ['./customs-details-progressbar.component.scss'],
})
export class CustomsDetailsProgressbarComponent {
  @Input() selectedCustom: any = [];
}
