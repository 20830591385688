<div class="filter-nav visible">
  <div class="filter-nav-inner-fullview">
    <h3 class="shipmet-title" [ngClass]="{ 'guided-back-drop': defaultCompHide }">
      {{ currentPage }}
      <span *ngIf="currentPage !== 'Reports'">
        <img src="assets/img/Upload.png" alt="guidedTour" (click)="guidedTourManually()" />
      </span>
    </h3>
    <span id="left-slide-icon">
      <img src="assets/img/arrow-previous.svg" alt="" />
      <div class="left-icon-msg">
        <span class="collapse-text">Collapse</span>
        <span class="expand-text">Expand</span>
      </div>
    </span>
    <span class="set-scroll global-left-menu-ctr" id="global-menu" *ngIf="currentPage !== 'Reports'">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <div class="filter-icons-ctr" *ngIf="
              (defaultFilterSecShow && showGuidedTourIcon()) ||
              (!showGuidedTourIcon() && currentPage !== 'Reports')
            " (click)="showDefaultFilter($event, 'default')">
            <span id="defSec" class="filter-common-style filter-default-icon"
              [ngClass]="{ 'filter-active': isDefaultFilterActive }">
            </span>
          </div>
          <div class="left-menu-seperate-ctr" id="defaultFilterSec" *ngIf="
              (defaultFilterSecShow && showGuidedTourIcon()) ||
              (!showGuidedTourIcon() && currentPage !== 'Reports')
            ">
            <div class="rap-menu"></div>
            <h4 class="accordion-header" id="panelsStayOpen-headingOne" [ngClass]="{
                'guided-back-drop': defaultFilterHide,
                'guided-visible-sec': defaultFilterShow,
                'added-active': isDefaultFilterActive
              }">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                Default Filters
              </button>
            </h4>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <ul class="nav flex-column dropdown" [ngClass]="{
                    'guided-back-drop': defaultFilterHide,
                    'guided-visible-sec': defaultFilterShow
                  }">
                  <li *ngFor="let filterMenu of defaultFiltersList">
                    <a class="nav-link" [ngClass]="{
                        active: defaultFilterSelectedValue == filterMenu?.value
                      }" (click)="clickDefaultFilter.next(filterMenu)">
                      {{ filterMenu.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="filter-icons-ctr" (click)="showDefaultFilter($event, 'systemFilter')" *ngIf="
              showGuidedTourIcon() &&
              currentUserRole === 'systemadmin' &&
              systemFilters?.length > 0 &&
              currentPage !== 'Reports'
            ">
            <span id="sysDefSec" class="filter-common-style filter-system-icon"
              [ngClass]="{ 'filter-active': isSytemFilterActive }">
            </span>
          </div>
          <div class="left-menu-seperate-ctr" id="systemFilterSec" *ngIf="
              showGuidedTourIcon() &&
              currentUserRole === 'systemadmin' &&
              systemFilters?.length > 0 &&
              currentPage !== 'Reports'
            ">
            <h4 class="m-0 accordion-header" id="panelsStayOpen-headingThree"
              [ngClass]="{ 'added-active': isSytemFilterActive }">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree">
                System Filters
              </button>
            </h4>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <p class="nav-link no-filter" *ngIf="systemFilters?.length < 1">
                  No Filters
                </p>
                <ul class="nav flex-column dropdown" cdkDropList (cdkDropListDropped)="drop($event)">
                  <li style="list-style-type: none !important" *ngFor="let systemFil of systemFilters; let i = index"
                    class="my-filter" cdkDrag [cdkDragData]="i" [class.dragging]="isDragging" cdkDragPreview
                    (cdkDragStarted)="onDragged()" (cdkDragReleased)="onDragReleased()">
                    <a class="nav-link" id="systemFilt{{ i }}" [ngClass]="{
                        active: selectedSystemFilterRow == systemFil.id
                      }">
                      <div class="flex-set">
                        <span class="left-sec" cdkDragHandle>
                          <img src="assets/img/system-icon.svg" class="drag-system" alt="" />
                        </span>
                        <span class="right-sec">
                          <span (click)="clickSystemFilter.next(systemFil)">{{
                            systemFil.filterName
                            }}</span>

                          <span *ngIf="currentUserRole === 'systemadmin'" class="edit"
                            (click)="editSystemFilter(systemFil)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.663" height="14.655">
                              <g data-name="Group 8437">
                                <path data-name="Path 10888"
                                  d="m16.775 31.01 4.778-1.4h.022a.021.021 0 0 0 .022-.022.3.3 0 0 0 .087-.065l8.639-8.639a2.74 2.74 0 0 0-3.861-3.861l-8.639 8.661c-.022.022-.044.065-.065.087a.021.021 0 0 1-.022.022v.022l-1.4 4.778a.314.314 0 0 0 .087.327.328.328 0 0 0 .24.087c.047.025.068.003.112.003zm10.166-13.5a2.08 2.08 0 0 1 2.923 2.923l-.873.873-2.923-2.923zm-1.331 1.327 2.923 2.923-7.068 7.09-2.945-2.944zm-7.4 7.7 2.618 2.618-3.687 1.069z"
                                  transform="translate(-16.322 -16.365)" style="fill: #00457c"></path>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="filter-icons-ctr" (click)="showDefaultFilter($event, 'systemFilter')" *ngIf="
              showGuidedTourIcon() &&
              currentUserRole === 'user' &&
              systemFilters?.length > 0 &&
              currentPage !== 'Reports'
            ">
            <span id="sysDefSec" class="filter-common-style filter-system-icon"
              [ngClass]="{ 'filter-active': isSytemFilterActive }">
            </span>
          </div>
          <div class="left-menu-seperate-ctr" id="systemFilterSec" *ngIf="
              showGuidedTourIcon() &&
              currentUserRole === 'user' &&
              systemFilters?.length > 0 &&
              currentPage !== 'Reports'
            ">
            <h4 class="m-0 accordion-header" id="panelsStayOpen-headingThree"
              [ngClass]="{ 'added-active': isSytemFilterActive }">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree">
                System Filters
              </button>
            </h4>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <p class="nav-link no-filter" *ngIf="systemFilters?.length < 1">
                  No Filters
                </p>
                <ul class="nav flex-column dropdown">
                  <li style="list-style-type: none !important" *ngFor="let systemFil of systemFilters; let i = index"
                    class="my-filter">
                    <a class="nav-link" id="systemFilt{{ i }}" (click)="clickSystemFilter.next(systemFil)" [ngClass]="{
                        active: selectedSystemFilterRow == systemFil.id
                      }">
                      <div class="flex-set">
                        <span class="right-sec">
                          {{ systemFil.filterName }}
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="filter-icons-ctr" (click)="showDefaultFilter($event, 'mySaved')"
            *ngIf="currentPage !== 'Reports'">
            <span id="saveSec" class="filter-common-style filter-usersaved-icon"
              [ngClass]="{ 'filter-active': isMysavedFilterActive }">
            </span>
          </div>
          <div class="left-menu-seperate-ctr" id="mySavedSec" *ngIf="currentPage !== 'Reports'">
            <h4 class="m-0 accordion-header" id="panelsStayOpen-headingTwo" [ngClass]="{
                'guided-back-drop': savedFilterHide,
                'guided-visible-sec': savedFilterShow,
                'added-active': isMysavedFilterActive
              }">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo">
                My Saved Filters
              </button>
            </h4>

            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body">
                <div class="my-save-filter">
                  <p class="nav-link no-filter" *ngIf="savedFilters?.length < 1" [ngClass]="{
                      'guided-back-drop': savedFilterHide,
                      'guided-visible-sec': savedFilterShow
                    }">
                    No Filters
                  </p>
                  <ul class="nav flex-column dropdown set-filter saved-filters" *ngIf="savedFilters?.length > 0"
                    [ngClass]="{
                      'guided-back-drop': savedFilterHide,
                      'guided-visible-sec': savedFilterShow
                    }">
                    <li *ngFor="let userFilter of savedFilters; let i = index" class="my-filter">
                      <a class="nav-link" id="userFilt{{ i }}" [ngClass]="{
                          active: selectedUserFilterRow == userFilter.id
                        }">
                        <span (click)="clickUserFilter.next(userFilter)">{{
                          userFilter.filterName
                          }}</span>
                        <span class="edit" (click)="editUserFilter(userFilter)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14.663" height="14.655">
                            <g data-name="Group 8437">
                              <path data-name="Path 10888"
                                d="m16.775 31.01 4.778-1.4h.022a.021.021 0 0 0 .022-.022.3.3 0 0 0 .087-.065l8.639-8.639a2.74 2.74 0 0 0-3.861-3.861l-8.639 8.661c-.022.022-.044.065-.065.087a.021.021 0 0 1-.022.022v.022l-1.4 4.778a.314.314 0 0 0 .087.327.328.328 0 0 0 .24.087c.047.025.068.003.112.003zm10.166-13.5a2.08 2.08 0 0 1 2.923 2.923l-.873.873-2.923-2.923zm-1.331 1.327 2.923 2.923-7.068 7.09-2.945-2.944zm-7.4 7.7 2.618 2.618-3.687 1.069z"
                                transform="translate(-16.322 -16.365)" style="fill: #00457c"></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>

    <!--Reports left menu-->
    <div class="report-leftmenu-ctr-cntrl" id="report-menu" *ngIf="currentPage === 'Reports'">
      <span class="report-leftmenu-ctr">
        <ul class="list-unstyled components">
          <li *ngFor="let filterMenu of reportList">
            <a class="nav-link" [ngClass]="{ active: reportSelectedValue === filterMenu?.id }"
              (click)="clickReportFilter.next(filterMenu)">
              {{ filterMenu.reportDisplayName }}
            </a>
          </li>
        </ul>
      </span>
    </div>
  </div>
</div>