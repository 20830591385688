import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  selector: 'app-editor-poc',
  templateUrl: './editor-poc.component.html',
  styleUrls: ['./editor-poc.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EditorPocComponent implements OnInit {
  title = 'ckeditor';

  public classicEditor = Editor;
  public Editor = this.classicEditor.Editor;

  public userInfo = ['@user_first_name', '@user_last_name', '@user_email_id'];
  public jsonElementsInfo = [
    '#lading_port.unlocode',
    '#shipments.loading_actual',
    '#arrival_port.unlocode',
    '#customs_declarations.import_date',
    '#forwarder_reference',
  ];
  public dataFieldInfo = [
    '!Data_Field1',
    '!Data_Field2',
    '!Data_Field3',
    '!Data_FieldN',
  ];
  public emailContentPropertiesInfo = [
    '^RowTable',
    '^ColumnTable',
    '^RowTableIndividual',
    '^ColumnTableIndividual',
    '^HyperLink',
    '^USDate',
    '^EuropeDate',
    '^LongDate',
    '^Number',
    '^Decimal',
    '^Header',
    '^ShipmentProgress',
  ];

  public logPropertiesInfo = [
    '~LatestDayLog',
    '~TotalLog',
    '~FirstLog',
    '~LastLog',
    '~FirstLastLog',
  ];

  modules = {};
  constructor() {}

  public editorContent: any = '';

  public onChange({ editor }: ChangeEvent) {}

  mentionList = {
    feeds: [
      {
        marker: '@',
        feed: this.userInfo,
        minimumCharacters: 0,
      },
      {
        marker: '#',
        feed: this.jsonElementsInfo,
        minimumCharacters: 0,
      },
      {
        marker: '!',
        feed: this.dataFieldInfo,
        minimumCharacters: 0,
      },
      {
        marker: '^',
        feed: this.emailContentPropertiesInfo,
        minimumCharacters: 0,
      },
      {
        marker: '~',
        feed: this.logPropertiesInfo,
        minimumCharacters: 0,
      },
    ],
  };

  Config = {
    toolbar: {
      items: [
        'exportPDF',
        'exportWord',
        '|',
        'findAndReplace',
        'selectAll',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'code',
        'subscript',
        'superscript',
        'removeFormat',
        '|',
        'bulletedList',
        'numberedList',
        'todoList',
        '|',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo',
        '-',
        'fontSize',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        'highlight',
        '|',
        'alignment',
        '|',
        'link',
        'insertImage',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'codeBlock',
        'htmlEmbed',
        '|',
        'specialCharacters',
        'horizontalLine',
        'pageBreak',
        '|',
        //'textPartLanguage', '|',
        'sourceEditing',
      ],
      shouldNotGroupWhenFull: true,
    },
    language: {
      textPartLanguage: [
        { title: 'Arabic', languageCode: 'ar' },
        { title: 'French', languageCode: 'fr' },
      ],
    },

    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },

    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph',
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1',
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2',
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3',
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4',
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5',
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6',
        },
      ],
    },

    placeholder: 'Welcome to CKEditor 5!',

    fontFamily: {
      options: [
        'default',
        'Arial, Helvetica, sans-serif',
        'Courier New, Courier, monospace',
        'Georgia, serif',
        'Lucida Sans Unicode, Lucida Grande, sans-serif',
        'Tahoma, Geneva, sans-serif',
        'Times New Roman, Times, serif',
        'Trebuchet MS, Helvetica, sans-serif',
        'Verdana, Geneva, sans-serif',
      ],
      supportAllValues: true,
    },

    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true,
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },

    htmlEmbed: {
      showPreviews: true,
    },

    link: {
      decorators: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file',
          },
        },
      },
    },

    mention: this.mentionList,

    removePlugins: [
      'CKBox',
      'CKFinder',
      'EasyImage',
      'RealTimeCollaborativeComments',
      'RealTimeCollaborativeTrackChanges',
      'RealTimeCollaborativeRevisionHistory',
      'PresenceList',
      'Comments',
      'TrackChanges',
      'TrackChangesData',
      'RevisionHistory',
      'Pagination',
      'WProofreader',
      'Title',
    ],
  };

  ngOnInit() {}
}
