import {
  Directive,
  Input,
  Renderer2,
  ElementRef,
  HostListener,
} from '@angular/core';

import { Sort } from '../util/sort';

@Directive({
  selector: '[appSort]',
})
export class SortDirective {
  @Input()
  appSort: Array<any> = [];

  constructor(private renderer: Renderer2, private targetElement: ElementRef) { }
  @HostListener('click') sortData() {
    const sort = new Sort();
    const elem = this.targetElement.nativeElement;
    const tableId = elem.getAttribute('data-parent');

    $(tableId + '>thead >tr>th').removeClass('factive');

    const order = elem.getAttribute('data-order');
    const type = elem.getAttribute('data-type');
    const property = elem.getAttribute('data-name');

    if (order === 'desc') {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'asc');
      this.renderer.removeClass(elem, 'desc');
      this.renderer.addClass(elem, 'asc');
      this.renderer.addClass(elem, 'sort');
      this.renderer.addClass(elem, 'factive');
    } else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'desc');
      this.renderer.removeClass(elem, 'asc');
      this.renderer.addClass(elem, 'desc');
      this.renderer.addClass(elem, 'sort');
      this.renderer.addClass(elem, 'factive');
    }
    const id: any = document.getElementById('scroll-table-added');
    if (id) {
      id.scrollTop = 0;
    }
  }
}
