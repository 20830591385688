import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ChangeLogsComponent } from 'src/app/shared/component/change-logs/change-logs.component';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { EdocUploadStatusService } from 'src/app/shared/services/eDoc-upload-status.service';
import { BookingDetailsHeaderComponent } from '../booking-details-header/booking-details-header.component';
import { ActionDetailTabComponent } from '../action-detail-tab/action-detail-tab.component';
import { BookingServies } from '../../services/booking.service';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent implements OnChanges {
  @Input() selectedBooking: any = [];
  @Input() popupToggle: any;
  @Input() urlShare = '';
  @Output() detailPopClose = new EventEmitter<any>();
  @Output() onDetailPopupCloseHandler = new EventEmitter<any>();
  @Output() onRefreshDetails = new EventEmitter<any>();
  @Input() urlBookingId: any = '';
  @ViewChild(BookingDetailsHeaderComponent)
  bookingHeaderComponent!: BookingDetailsHeaderComponent;
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Input() publicShareEnrcyptedData: any = '';
  @ViewChild(ChangeLogsComponent)
  ChangeLogsComponent!: ChangeLogsComponent;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  shipmentService: any;
  hasActionField = this.currentAccountData.isBookingActionField;
  @ViewChild(ActionDetailTabComponent)
  actionFieldtabComponent: ActionDetailTabComponent;

  constructor(
    private edocUploadStatusService: EdocUploadStatusService,
    private dataSharing: DataSharingService,
    private currentSessionUserData: CurrentSessionDataService,
    private bookingServies:BookingServies
  ) {}

  /* istanbul ignore next */
  featureHide() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      if (this.hasActionField && this.bookingServies.isActionFieldChanged) {
        this.actionFieldtabComponent.closeActionField();
      } else {
        this.popUpClose();
      }
    } else {
      this.dataSharing.eDocUploadTrigger.next({ popup: true, reload: false });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.urlBookingId && this.urlBookingId !== '') {
      setTimeout(() => {
        this.addFullScreen(this.urlBookingId);
        setTimeout(() => {
          this.urlBookingId = '';
        }, 2000);
      }, 0);
    }
    this.loadDocuments();
  }

  popUpClose() {
    this.detailPopClose.emit(false);
  }

  addFullScreen(value: any) {
    if (value) {
      $('#navCenter').addClass('justify-content-center');
      $('#shipmentFull').addClass('shipmet-fullwidth shimpent-removeicon');
      $('.dimScreen').addClass('shipment-fullwidth');
    }
  }

  reloadWatchListBooking(event: any) {
    this.onDetailPopupCloseHandler.emit({
      value: 'LOAD SHIPMENT',
      bookingId: event,
    });
  }

  onReloadDetails(event: any) {
    if (this.currentUserData && event.access) {
      this.ChangeLogsComponent.getLogsCount();

      if (this.ChangeLogsComponent.categoryTypes.length > 0) {
        let sort = [
          {
            column_name: 'createDateTime',
            direction: 'desc',
          },
        ];
        this.ChangeLogsComponent.categoryTypes.forEach((element: any) => {
          this.ChangeLogsComponent.getChangeLogs(
            element.changeLogCategory.toString(),
            sort
          );
        });
      }
    }
    this.onRefreshDetails.emit({
      value: 'LOAD DETAILS',
      shipmentId: event.bookingId,
      access: event.access,
    });
  }

  share(event: any) {
    this.prepareUrlShare.emit(event);
  }

  loadDocuments() {
    this.selectedBooking?.attachments?.forEach((ele: any, index: any) => {
      let file_exe = ele.file_name.split('.').pop().toUpperCase();
      this.selectedBooking.attachments[index].file_type = file_exe;
      let regex = /[+-]?\d+(\.\d+)?/g;
      let floats = ele.file_size.match(regex).map(function (v: any) {
        return parseFloat(v);
      });
      let fileSizeSplit = ele.file_size.toLowerCase();
      if (fileSizeSplit.includes('kb')) {
        this.selectedBooking.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 1);
      } else if (fileSizeSplit.includes('mb')) {
        this.selectedBooking.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 2);
      } else if (fileSizeSplit.includes('gb')) {
        this.selectedBooking.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 3);
      } else if (fileSizeSplit.includes('bytes')) {
        this.selectedBooking.attachments[index].file_size_bytes = floats[0];
      }
    });
  }

  loadFullReferenceNum(event: any) {
    if (event) {
      this.bookingHeaderComponent.gotoRefSection();
    }
  }

  @Output() loadBookingList = new EventEmitter();
  loadBookingListById(id: any) {
    this.loadBookingList.emit(id);
  }
}
