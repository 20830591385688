import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommonUtils } from 'src/app/common/utils';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataFieldService } from 'src/app/features/admin/datafield/services/datafield.service';
import { debounceTime } from 'rxjs/operators';
const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? true
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? true
          : one.day > two.day
        : one.month > two.month
      : one.year > two.year;

@Component({
  selector: 'app-adv-filter-value-control',
  templateUrl: './adv-filter-value-control.component.html',
  styleUrls: ['./adv-filter-value-control.component.scss'],
})
export class AdvFilterValueControlComponent implements OnInit {
  @Input() conditionIndex: number;
  @Input() groupIndex: number;
  @Input() selectedValue: any;
  showCutOffDropdown = false;
  conditionCompare = '';
  conditionValue = '';
  modelDate: NgbDateStruct;
  date: { year: number; month: number };
  fromDates: NgbDate | null = null;
  toDates: NgbDate | null = null;
  hoveredDates: NgbDate | null = null;
  filterDateRange: any = [];
  selectedListDate: any = -1;
  selectedCustomDate = false;
  selectedCutOffDateText = '';
  currentDate: any;
  dateFrom: any = '';
  dateTo: any = '';
  apiDateFrom: any = '';
  apiDateTo: any = '';
  apiDateToSet: any = '';
  selectedCutOffDateFrom = '';
  selectedCutOffDateTo = '';
  uiDateFrom: any = '';
  uiDateTo: any = '';
  isConditionCompareEmpty = false;
  isDateRangeShow = false;
  showDateValidationErrorMsg: boolean = false;
  showTimeValidationErrorMsg: boolean = false;
  showTimeRange: boolean = false;
  fromTime: any = '';
  toTime: any = '';
  showTimeRangeDropDown: boolean = false;
  isTimeEmpty: boolean = false;
  isConditionValueRequired: boolean = false;
  fromTimeSelector: any = '';
  toTimeSelector: any = '';
  showInvalidSymbol: boolean = false;
  debounceValueText = new Subject();
  selectedConditionCompareText: any = '';
  conditionCompareDropDownFlag: boolean = false;
  isPredefinedDateSelected: boolean = false;
  lastSelectedDate:any='';
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  @Output() valueControl = new EventEmitter();
  @Input() hasError: boolean = false;
  currentUserData: any = this.currentUser.getCurrentUserData();
  currentAccountData: any = this.currentUser.getCurrentAccountData();

  constructor(
    private dataFieldService: DataFieldService,
    private calendar: NgbCalendar,
    private currentUser: CurrentSessionDataService
  ) { }

  ngOnInit(): void {
    this.debounceValueText.pipe(debounceTime(700)).subscribe((e: any) => {
      this.setValue();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.bindValues();
    for (const propName in changes) {
      if (propName == 'selectedValue') {
        if (!this.selectedValue) {
          return;
        }
      }
    }
  }

  dateSetting() {
    let today = new Date();
    this.currentDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.modelDate = this.calendar.getToday();
  }

  bindValues() {
    if (this.selectedValue) {
      this.isDateRangeShow = false;
      this.conditionValue =
        this.selectedValue?.groups[this.groupIndex]?.conditions[
          this.conditionIndex
        ]?.value;
      this.conditionCompare =
        this.selectedValue?.groups[this.groupIndex]?.conditions[
          this.conditionIndex
        ]?.operator;

      this.isConditionValueRequired =
        this.selectedValue?.groups[this.groupIndex]?.conditions[
          this.conditionIndex
        ]?.isConditionValueRequired;
      this.determineValueControl(
        this.selectedValue?.groups[this.groupIndex]?.conditions[
          this.conditionIndex
        ].controlTypeName
      );
    } else {
      this.fnEmpty();
    }
  }

  determineValueControl(type: number) {
    let controlType = type ? type : 2; // plain text box
    /*if(this.selectedValue?.groups[this.groupIndex]?.conditions[  // code written for mocking
      this.conditionIndex
    ]?.columnDisplayName=="estimated-time"){
    controlType=7
    }*/
    if (
      // only date
      controlType === 1 ||
      controlType === 3 ||
      controlType === 4
    ) {
      this.isDateRangeShow = true;
      this.showTimeRange = false;
    } else if (controlType === 6) {
      // both date and time
      this.isDateRangeShow = true;
      this.showTimeRange = true;
    } else if (controlType === 7) {
      // only time
      this.isDateRangeShow = false;
      this.showTimeRange = true;
    } else {
      this.isDateRangeShow = false;
      this.showTimeRange = false;
    }
    this.loadDate(controlType); // pass control type or data type of the json, dfd, af
    //this.setValue();
  }

  setValue() {
    let error = false;
    if (!this.conditionValue.trim()) {
      error = true;
    } else {
      if(this.isDateRangeShow && !this.showTimeRange && !this.isPredefinedDateSelected)
      {
       this.conditionValue= this.lastSelectedDate;
      }
      else if (this.isDateRangeShow && this.showTimeRange) {
        let hasTime = this.validateTimes(false);
        let hasDate = this.validateDates(false);
        if ((hasTime && hasDate) || (hasDate && this.isPredefinedDateSelected)) {
          error = false;
        } else {
          error = true;
        }
      } else if (!this.isDateRangeShow && this.showTimeRange) {
        error = !this.validateTimes(true);
      }
    }
    this.selectedValue.groups[this.groupIndex].conditions[
      this.conditionIndex
    ].value = this.conditionValue;
    let advanceFilterOptions = {
      advanceFilterOptions: this.selectedValue.groups,
    };
    // this.callAdvancedSearchApi(advanceFilterOptions);
    if (!error) {
      this.valueControl.emit([
        this.conditionValue,
        this.groupIndex,
        this.conditionIndex,
        error, ""
      ]);
    } else {
      let errorMessage = "";
      if (this.showTimeValidationErrorMsg && !this.showDateValidationErrorMsg) {
        errorMessage = "Please select Time";
      }
      else if (!this.showTimeValidationErrorMsg && this.showDateValidationErrorMsg) {
        errorMessage = "Please select Date";
      }
      else if (this.showTimeValidationErrorMsg && this.showDateValidationErrorMsg) {
        errorMessage = "Please select Date and Time";
      }
      this.valueControl.emit([this.conditionValue, this.groupIndex, this.conditionIndex, error, errorMessage]);
    }
  }

  fnEmpty() {
    this.conditionCompare = '';
    this.conditionValue = '';
    this.selectedCutOffDateText = '';
    this.isDateRangeShow = false;
    this.showTimeRange = false;
  }

  onSelectedCutOffRange(selectedCutOffDate: any, index: any) {
    this.selectedListDate = index;
    this.selectedCustomDate = false;
    this.selectedCutOffDateText = selectedCutOffDate?.range;
    this.conditionValue = selectedCutOffDate?.range;
    this.showCutOffDropdown = false;
    this.isPredefinedDateSelected = true;
    //this.setDefaultTimeForPreDefinedDates(selectedCutOffDate);
    //this.setTime();
    this.setValue();
  }

  setCurrentTimeForPastRange(selectedDate:any){
    let particularRange=["Last Week",
      "Last Month",
      "Last 3 Months",
      "Last 6 Months"];
      if(particularRange.includes(selectedDate.range)){
        var d = new Date(); 
        return {hour:d.getHours(), minute:d.getMinutes(), second:0};
      }
      else{
        return null;
      }
  }

  setCurrentTimeForFutureRange(selectedDate:any){
    let particularRange=[
      "Next Week",
      "Next Month",
      "Next 3 Months",
      "Next 6 Months"];
      if(particularRange.includes(selectedDate.range)){
        var d = new Date(); 
        return {hour:d.getHours(), minute:d.getMinutes(), second:0};
      }
      else{
        return null;
      }
  }

  setDefaultTimeForPreDefinedDates(selectedDate:any){
    let preDefinedPastTime=this.setCurrentTimeForPastRange(selectedDate)?
    this.setCurrentTimeForPastRange(selectedDate):{hour: 0, minute: 0, second: 0};
    
    let preDefinedFutureTime=this.setCurrentTimeForFutureRange(selectedDate)?
    this.setCurrentTimeForFutureRange(selectedDate):{hour: 23, minute: 59, second: 0};

     if(this.isDateRangeShow && this.showTimeRange){
       if(this.conditionCompare?.trim()?.toLocaleLowerCase() == 'between'){
        this.fromTime=preDefinedPastTime;
        this.toTime=preDefinedFutureTime;
       }
    else{
        this.fromTime=preDefinedPastTime;
        this.toTime=undefined;
      }
    }
  }

  cutOffDropdownToggle() {
    if (this.isConditionValueRequired) {
      let fromDates = this.changeDateFormat(new Date('01/01/2021'));
      this.showCutOffDropdown = !this.showCutOffDropdown;
      if (this.showCutOffDropdown) {
        this.dataFieldService.dateRangeFieldActive.next(true);
      } else {
        this.dataFieldService.dateRangeFieldActive.next(false);
      }
    }
  }

  loadDate(type: any) {
    let today = new Date();
    let uiToday = this.getMMMDDDateFormatted(today);

    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let uiTomorrow = this.getMMMDDDateFormatted(tomorrow);
    let thisWeek = new Date(today);
    let first = thisWeek.getDate() - thisWeek.getDay(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6
    let firstday = new Date(thisWeek.setDate(first)).toUTCString();
    let lastday = new Date(thisWeek.setDate(last)).toUTCString();
    //thisWeek.setDate(thisWeek.);
    let uiThisWeekStart = this.getMMMDDDateFormatted(firstday);
    let uiThisWeekEnd = this.getMMMDDDateFormatted(lastday);
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let uiYesterday = this.getMMMDDDateFormatted(yesterday);

    let last7day = new Date(today);
    last7day.setDate(last7day.getDate() - 6);
    let uiLast7day = this.getMMMDDDateFormatted(last7day);

    let last1month = new Date(today);
    last1month.setMonth(last1month.getMonth() - 1);
    let uiLast1month = this.getMMMDDDateFormatted(last1month);

    let last3month = new Date(today);
    last3month.setMonth(last3month.getMonth() - 3);
    let uiLast3month = this.getMMMDDDateFormatted(last3month);

    let last6month = new Date(today);
    last6month.setMonth(last6month.getMonth() - 6);
    let uiLast6month = this.getMMMDDDateFormatted(last6month);

    let last12month = new Date(today);
    last12month.setFullYear(last12month.getFullYear() - 1);

    let next7day = new Date(today);
    next7day.setDate(next7day.getDate() + 6);
    let uinext7day = this.getMMMDDDateFormatted(next7day);

    let next1month = new Date(today);
    next1month.setMonth(next1month.getMonth() + 1);
    let uinext1month = this.getMMMDDDateFormatted(next1month);

    let next3month = new Date(today);
    next3month.setMonth(next3month.getMonth() + 3);
    let uinext3month = this.getMMMDDDateFormatted(next3month);

    let next6month = new Date(today);
    next6month.setMonth(next6month.getMonth() + 6);
    let uinext6month = this.getMMMDDDateFormatted(next6month);

    let next12month = new Date(today);
    next12month.setFullYear(next12month.getFullYear() + 1);
    if (type === 1 || type === 6) {
      this.filterDateRange = [
        {
          range: 'Today',
          val: 2,
          fromDate: today,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uiToday,
        },
        {
          range: 'This Week',
          val: 10,
          fromDate: uiThisWeekStart,
          toDate: lastday,
          uiFromDate: uiThisWeekStart,
          uiToday: uiThisWeekEnd,
        },
        {
          range: 'Yesterday',
          val: 1,
          fromDate: yesterday,
          toDate: yesterday,
          uiFromDate: uiYesterday,
          uiToday: uiYesterday,
        },
        {
          range: 'Last Week',
          val: 3,
          fromDate: last7day,
          toDate: today,
          uiFromDate: uiLast7day,
          uiToday: uiToday,
        },
        {
          range: 'Last Month',
          val: 4,
          fromDate: last1month,
          toDate: today,
          uiFromDate: uiLast1month,
          uiToday: uiToday,
        },
        {
          range: 'Last 3 Months',
          val: 5,
          fromDate: last3month,
          toDate: today,
          uiFromDate: uiLast3month,
          uiToday: uiToday,
        },
        {
          range: 'Last 6 Months',
          val: 6,
          fromDate: last6month,
          toDate: today,
          uiFromDate: uiLast6month,
          uiToday: uiToday,
        },
        {
          range: 'Tomorrow',
          val: 2,
          fromDate: tomorrow,
          toDate: tomorrow,
          uiFromDate: uiTomorrow,
          uiToday: uiTomorrow,
        },
        {
          range: 'Next Week',
          val: 3,
          fromDate: next7day,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext7day,
        },

        {
          range: 'Next Month',
          val: 4,
          fromDate: next1month,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext1month,
        },
        {
          range: 'Next 3 Months',
          val: 5,
          fromDate: next3month,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext3month,
        },
        {
          range: 'Next 6 Months',
          val: 6,
          fromDate: next6month,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext6month,
        },
      ];
    } else if (type === 3) {
      this.filterDateRange = [
        {
          range: 'Select Value',
          val: 2,
          fromDate: '',
          toDate: '',
          uiFromDate: '',
          uiToday: '',
        },
        {
          range: 'Today',
          val: 2,
          fromDate: today,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uiToday,
        },
        {
          range: 'This Week',
          val: 10,
          fromDate: uiThisWeekStart,
          toDate: lastday,
          uiFromDate: uiThisWeekStart,
          uiToday: uiThisWeekEnd,
        },
        {
          range: 'Tomorrow',
          val: 2,
          fromDate: tomorrow,
          toDate: tomorrow,
          uiFromDate: uiTomorrow,
          uiToday: uiTomorrow,
        },
        {
          range: 'Next Week',
          val: 3,
          fromDate: next7day,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext7day,
        },
        {
          range: 'Next Month',
          val: 4,
          fromDate: next1month,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext1month,
        },
        {
          range: 'Next 3 Months',
          val: 5,
          fromDate: next3month,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext3month,
        },
        {
          range: 'Next 6 Months',
          val: 6,
          fromDate: next6month,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uinext6month,
        },
      ];
    } else if (type === 4) {
      this.filterDateRange = [
        {
          range: 'Select Value',
          val: 2,
          fromDate: '',
          toDate: '',
          uiFromDate: '',
          uiToday: '',
        },
        {
          range: 'Today',
          val: 2,
          fromDate: today,
          toDate: today,
          uiFromDate: uiToday,
          uiToday: uiToday,
        },
        {
          range: 'Yesterday',
          val: 1,
          fromDate: yesterday,
          toDate: yesterday,
          uiFromDate: uiYesterday,
          uiToday: uiYesterday,
        },
        {
          range: 'Last Week',
          val: 3,
          fromDate: last7day,
          toDate: today,
          uiFromDate: uiLast7day,
          uiToday: uiToday,
        },
        {
          range: 'Last Month',
          val: 4,
          fromDate: last1month,
          toDate: today,
          uiFromDate: uiLast1month,
          uiToday: uiToday,
        },
        {
          range: 'Last 3 Months',
          val: 5,
          fromDate: last3month,
          toDate: today,
          uiFromDate: uiLast3month,
          uiToday: uiToday,
        },
        {
          range: 'Last 6 Months',
          val: 6,
          fromDate: last6month,
          toDate: today,
          uiFromDate: uiLast6month,
          uiToday: uiToday,
        },
      ];
    }
  }

  getMonthYearFormatted(dateSelected: any) {
    let date = new Date(dateSelected);

    return (
      this.monthNames[date.getMonth()] +
      ' ' +
      date.getDate() +
      ' ,' +
      date.getFullYear()
    );
  }

  changeDateFormat(dates: any) {
    return (
      ('0' + (dates.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + dates.getDate()).slice(-2) +
      '/' +
      dates.getFullYear()
    );
  }

  selectDefaultDate() {
    this.selectedCustomDate = true;
    this.selectedListDate = -1;
  }

  selectDateNGBDate(navigateTo: any) {
    this.modelDate = this.currentDate;
  }

  onDateSelection(date: NgbDate) {
    this.isPredefinedDateSelected = false;
    if (!this.fromDates && !this.toDates) {
      this.fromDates = date;
    } else if (this.fromDates && !this.toDates && after(date, this.fromDates)) {
      this.toDates = date;
    } else {
      this.toDates = null;
      this.fromDates = date;
    }

    let fromdate = new Date(
      this.fromDates?.year,
      this.fromDates?.month - 1,
      this.fromDates?.day
    );
    this.dateFrom =
      ('0' + (fromdate.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + fromdate.getDate()).slice(-2) +
      '/' +
      fromdate.getFullYear();
    this.apiDateFrom =
      ('0' + fromdate.getDate()).slice(-2) +
      '/' +
      ('0' + (fromdate.getMonth() + 1)).slice(-2) +
      '/' +
      fromdate.getFullYear();
    if (this.toDates) {
      let todate = new Date(
        this.toDates?.year,
        this.toDates?.month - 1,
        this.toDates?.day
      );
      this.dateTo =
        ('0' + (todate.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + todate.getDate()).slice(-2) +
        '/' +
        todate.getFullYear();
      this.apiDateToSet =
        ('0' + todate.getDate()).slice(-2) +
        '/' +
        ('0' + (todate.getMonth() + 1)).slice(-2) +
        '/' +
        todate.getFullYear();
    }
    this.uiDateFrom = this.getMonthYearFormatted(this.dateFrom);
    this.uiDateTo = this.toDates
      ? this.getMonthYearFormatted(this.dateTo)
      : this.getMonthYearFormatted(this.dateFrom);
    this.apiDateTo = this.toDates ? this.dateTo : this.dateFrom;
    this.selectedCutOffDateText = this.uiDateFrom + ' to ' + this.uiDateTo;
    this.conditionValue = this.dateFrom + ',' + this.apiDateTo;
    this.lastSelectedDate =this.dateFrom + ',' + this.apiDateTo;
  }

  getMonthDateYearFormatted(dateSelected: any) {
    let date = new Date(dateSelected);

    return (
      this.monthNames[date.getMonth()] +
      ' ' +
      date.getDate() +
      ' ,' +
      date.getFullYear()
    );
  }

  getDDMMYYYDateFormatted(dateSelected: any) {
    let date = new Date(dateSelected).toJSON().slice(0, 10).replace(/-/g, '/');
    return date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
  }

  getMMDDYYYDateFormatted(dateSelected: any) {
    let date = new Date(dateSelected).toJSON().slice(0, 10).replace(/-/g, '/');
    return date.slice(5, 7) + '/' + date.slice(8, 10) + '/' + date.slice(0, 4);
  }

  getMMMDDDateFormatted(dateSelected: any) {
    let date = new Date(dateSelected).toJSON().slice(0, 10).replace(/-/g, '/');

    return (
      CommonUtils.getMonthIn3CharName(parseInt(date.slice(5, 7)) - 1) +
      ' ' +
      date.slice(8, 10)
    );
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDates &&
      !this.toDates &&
      this.hoveredDates &&
      date.after(this.fromDates) &&
      date.before(this.hoveredDates)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.toDates && date.after(this.fromDates) && date.before(this.toDates)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDates) ||
      (this.toDates && date.equals(this.toDates)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  bindDate(val: any) {
    if (val.conditionValue.indexOf(',') > -1) {
      let dateDisplay = val.conditionValue.split(',');
      let fromDate: any = '';
      let toDate: any = '';
      if (this.dataFieldService.validateBothDateAndTime(dateDisplay)) {
        let fromDates = dateDisplay[0].split('T');
        let toDates = dateDisplay[1].split('T');
        fromDate = fromDates[0];
        toDate = toDates[0];
      } else {
        fromDate = dateDisplay[0];
        toDate = dateDisplay[1];
      }
      let fromD = new Date(fromDate);
      let fromT = new Date(toDate);
      this.fromDates = new NgbDate(
        fromD.getFullYear(),
        fromD.getMonth() + 1,
        fromD.getDate()
      );
      this.toDates = new NgbDate(
        fromT.getFullYear(),
        fromT.getMonth() + 1,
        fromT.getDate()
      );
      this.uiDateFrom = this.getMonthYearFormatted(fromDate);
      this.uiDateTo = this.toDates
        ? this.getMonthYearFormatted(toDate)
        : this.getMonthYearFormatted(fromDate);
      this.selectedCutOffDateText = this.uiDateFrom + ' to ' + this.uiDateTo;
    } else {
      this.selectedCutOffDateText = val.conditionValue;
    }
  }

  convertTimeToJson(time: any) {
    let value = time?.split(':');
    return { hour: parseInt(value[0]), minute: parseInt(value[1]) };
  }

  bindTime(val: any) {
    let time: any = '';
    if (this.dataFieldService.validateBothDateAndTime(val)) {
      let timeIncludesDate = val?.split(',');
      let fromTime = timeIncludesDate[0]?.split('T');
      let toTime = timeIncludesDate[1]?.split('T');
      let temp = 'T' + fromTime[1] + ',T' + toTime[1];
      time = temp?.split(',');
    } else {
      time = val?.split(',');
    }
    let fromTime = time[0].substr(1).slice(0, -1);
    let toTime = time[1].substr(1).slice(0, -1);
    this.fromTime = this.convertTimeToJson(fromTime);
    this.toTime = this.convertTimeToJson(toTime);
    this.fromTimeSelector = this.convertTimeTo12HrUTC(fromTime + ':00');
    this.toTimeSelector = this.convertTimeTo12HrUTC(toTime + ':00');
  }

  setSelectedDate(val: any) {
    val.conditionValue = val.conditionValue.replaceAll(' ', '');
    if (this.dataFieldService.validateBothDateAndTime(val.conditionValue)) {
      //both date and time
      this.bindDate(val);
      this.bindTime(val.conditionValue);
    } else if (this.dataFieldService.validateOnlyDate(val.conditionValue)) {
      //only date
      this.bindDate(val);
    } else if (this.dataFieldService.validateOnlyTime(val.conditionValue)) {
      // only time
      this.bindTime(val.conditionValue);
    } else {
      this.selectedCutOffDateText = val.conditionValue;
    }
  }

  dateOutsideClick() {
    if (this.showCutOffDropdown) {
      this.showCutOffDropdown = false;
      this.dataFieldService.dateRangeFieldActive.next(false);
      this.setValue();
    }
  }

  validateDates(required?: boolean) {
    if (
      this.conditionValue?.split(',')?.length === 2 ||
      this.selectedCutOffDateText.trim() != ''
    ) {
      if (!required) {
        this.showDateValidationErrorMsg = false;
      }
      return true;
    } else {
      if (!required) {
        this.showDateValidationErrorMsg = true;
      }
      return false;
    }
  }

  validateTimes(required?: boolean) {
    if (
      this.fromTime === '' ||
      (this.toTime === '' &&
        this.conditionCompare?.trim()?.toLocaleLowerCase() == 'between') ||
      this.fromTime === null ||
      this.toTime === null
    ) {
      if (!required) {
        this.showTimeValidationErrorMsg = true;
      }
      return false;
    } else {
      if (!required) {
        this.showTimeValidationErrorMsg = false;
      }
      return true;
    }
  }

  convertTimeTo12HrUTC(time: any) {
    // 18:00:00
    const timeString12hr = new Date(
      '1970-01-01T' + time + 'Z'
    ).toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });

    return timeString12hr;
  }

  convertTimeRangeFormat(e: any, type: string) {
    let time: any = '';
    if (e) {
      let hour = e.hour;
      if (hour < 10) {
        hour = '0' + hour;
      }
      let minute = e.minute;
      if (minute < 10) {
        minute = '0' + minute;
      }
      time = hour + ':' + minute;

      if (type === 'from') {
        this.fromTimeSelector = this.convertTimeTo12HrUTC(time + ':00');
      } else {
        this.toTimeSelector = this.convertTimeTo12HrUTC(time + ':00');
      }
    } else {
      this.fromTimeSelector = '';
      this.toTimeSelector = '';
      time = '';
    }
    return time;
  }

  setTime() {
    if (this.isDateRangeShow && this.showTimeRange) {
      let dates: any = this.lastSelectedDate?.split(',');
      if (this.isPredefinedDateSelected) {
        //dates[0] = this.selectedCutOffDateText;
        //dates[1] = '';
      }
      this.fromTimeSelector = '';
      this.toTimeSelector = '';
      if (dates?.length == 2) {   //|| this.selectedCutOffDateText.trim() != ''
        if (dates[1] != '') {
          this.conditionValue =
            dates[0] +
            'T' +
            this.convertTimeRangeFormat(this.fromTime, 'from') +
            'Z,' +
            dates[1] +
            'T' +
            this.convertTimeRangeFormat(
              this.toTime == '' ? '00:00' : this.toTime,
              'to'
            ) +
            'Z';
        } else {
          this.conditionValue =
            dates[0] +
            'T' +
            this.convertTimeRangeFormat(this.fromTime, 'from') +
            'Z,' +
            'T' +
            this.convertTimeRangeFormat(
              this.toTime == '' ? '00:00' : this.toTime,
              'to'
            ) +
            'Z';
        }
      }
    } else if (this.isDateRangeShow && !this.showTimeRange) {
      if (this.isPredefinedDateSelected) {
        //this.conditionValue = this.selectedCutOffDateText;
      }
    } else {
      this.conditionValue =
        'T' +
        this.convertTimeRangeFormat(this.fromTime, 'from') +
        'Z, T' +
        this.convertTimeRangeFormat(
          this.toTime == '' ? '00:00' : this.toTime,
          'to'
        ) +
        'Z';
    }
  }

  validateDateRange() {
    this.isPredefinedDateSelected=false;
    let hasTime: boolean = true;
    if (this.showTimeRange) {
      hasTime = this.validateTimes();
    }
    let hasDate = this.validateDates();
    if (hasTime && hasDate) {
      if (this.showTimeRange) {
        this.setTime();
      }
      this.setValue();
      this.showCutOffDropdown = false;
      this.isConditionValueRequired = false;
    } else {
      this.isConditionValueRequired = true;
      this.showCutOffDropdown = true;
    }
  }

  validateTimeRange() {
    let hasTime = this.validateTimes();
    if (hasTime) {
      this.setTime();
      this.setValue();
      this.showTimeRangeDropDown = false;
      this.isTimeEmpty = false;
    } else {
      this.isTimeEmpty = true;
      this.showTimeRangeDropDown = true;
    }
  }

  validateDateAndTime() {
    if (this.isDateRangeShow && this.showTimeRange) {
      this.isConditionValueRequired =
        this.dataFieldService.validateBothDateAndTime(this.conditionValue);
    } else if (!this.isDateRangeShow && this.showTimeRange) {
      this.isTimeEmpty = this.dataFieldService.validateTime(
        this.conditionValue
      );
    }
  }

  showTimeDropDown() {
    if (this.isConditionValueRequired) {
      this.showTimeRangeDropDown = !this.showTimeRangeDropDown;
    }
  }
}
