import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookingComponent } from './container/booking/booking.component';
import { BookingRoutingModule } from './booking-routing.module';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { BookingCustomFiltersComponent } from './components/booking-custom-filters/booking-custom-filters.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { BookingDetailsHeaderComponent } from './components/booking-details-header/booking-details-header.component';
import { BookingServies } from './services/booking.service';
import { BookingDetailTabComponent } from './components/booking-detail-tab/booking-detail-tab.component';
import { BookingDocumentTabComponent } from './components/booking-document-tab/booking-document-tab.component';
import { DaterangeFilterComponent } from './components/daterange-filter/daterange-filter.component';
import {
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AllLocationFilterComponent } from './components/all-location-filter/all-location-filter.component';
import { SucessToastComponent } from './common/sucess-toast/sucess-toast.component';
import { ActionDetailTabComponent } from './components/action-detail-tab/action-detail-tab.component';

@NgModule({
  declarations: [
    BookingComponent,
    BookingListComponent,
    BookingCustomFiltersComponent,
    BookingDetailsComponent,
    BookingDetailsHeaderComponent,
    BookingDetailTabComponent,
    BookingDocumentTabComponent,
    DaterangeFilterComponent,
    AllLocationFilterComponent,
    SucessToastComponent,
    ActionDetailTabComponent,
  ],
  imports: [
    CommonModule,
    BookingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    SharedModule,
    InfiniteScrollModule,
  ],
  providers: [BookingServies],
  exports: [
    BookingListComponent,
    BookingComponent,
    BookingCustomFiltersComponent,
    BookingDetailsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BookingModule {}
