import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataFieldService {
  public cancelFormEvent = new EventEmitter<boolean>();
  public closeWindow = new EventEmitter<boolean>();
  public closeDataFieldForm: Subject<boolean> = new Subject<boolean>();
  public openDataFieldForm: Subject<boolean> = new Subject<boolean>();
  public stopRequest: Subject<void> = new Subject<void>();
  public applyConditionFilterErrorList = {
    default: false,
    condition: false,
    elseCondition: false,
  };
  public dataDefinitionFilterParam: any = new BehaviorSubject(
    'shipments,bookings,customs'
  );
  public dateRangeFieldActive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public arrayOrSeparatorDropdown: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  selectedDataFromDetail: any;
  setSelectedValue: boolean;
  jsonError: any;
  constructor(private http: HttpClient) { }

  closePopUpWindow() {
    this.closeWindow.emit(true);
  }

  confirmCancellingCreateForm() {
    this.cancelFormEvent.emit(true);
  }

  validateTime(value: string) {
    let time: any = value?.split(',');
    if ((time[0] !== '' && time[1] !== '') || time?.length !== 1) {
      return false;
    } else {
      return true;
    }
  }

  validateBothDateAndTime(value: string) {
    let pattern =
      /^(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z,){1,}(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z)$/i;
    return pattern.test(value);
  }

  validateOnlyDate(value: string) {
    let pattern = /^(\d{2}\/\d{2}\/\d{4},){1,}(\d{2}\/\d{2}\/\d{4})$/i;
    return pattern.test(value);
  }

  validateOnlyTime(value: string) {
    //let pattern=/^(\d{2}:\d{2},){1,}(\d{2}:\d{2})$/;
    let pattern = /^T\d{2}:\d{2}Z,T\d{2}:\d{2}Z$/;
    return pattern.test(value);
  }

  getDataFieldDefinitionList(params: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'DataDefinition/Get',
        params,
        this.httpOptions
      )
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getDataFieldAutoComplete(params: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'DataDefinition/Get',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  createDataFieldDefinition(params: any) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'DataDefinition/Create',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  CheckJsonDataDefinitionExist(params: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/CheckJsonExist?displayValue=' +
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  updateDataFieldDefinition(params: any) {
    return this.http
      .put<any[]>(
        environment.base_api_desktop_url + 'DataDefinition/Update',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  checkIsElementArray(params: any) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'DataDefinition/GetJSONProperty',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  deleteDataFieldDefinition(params: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url + 'DataDefinition/Delete',
        options
      )
      .pipe(catchError(this.handelError));
  }

  getTypeValue() {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url + 'DataDefinition/GetControlType',
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getTypeValueWithType(controlType: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetControlType?controlType=' +
        controlType,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getDisplayConditionValue(params: any) {
    params.search_text = params.search_text?.trim();
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetDataDefinitionJSON',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getAllAccounts(searchText: any, offset: any): Observable<any> {
    let request = {
      offset: offset,
      fetch: 30,
      search_text: searchText,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<any>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  prepareExcelDowload(bodyParam: any): Observable<any> {
    let url = environment.base_api_desktop_url + 'DataDefinition/ExcelExport';

    const headers: Object = {
      responseType: 'Blob',
    };

    return this.http
      .post<any[]>(url, bodyParam, headers)
      .pipe(catchError(this.handelError));
  }

  getWorkflowDataDefinitionList(dataDefinitionId: string) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetWorkflowDataDefinitionList?DataDefinitionId=' +
        dataDefinitionId,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getEmailTemplateDataDefinitionList(dataDefinitionId: string) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetEmailTemplateDataDefinitionList?DataDefinitionId=' +
        dataDefinitionId,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getSingleDefinitionList(dataDefinitionId: string) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetDatadefinitionById?id=' +
        dataDefinitionId,
        {},
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getPreviewlist(params: any, email: any) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/Preview?userEmail=' +
        email,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }
  getResetSysDefinedlist(WFId: any, dfId: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetDefaultDataDefinitionReset?DefaultDataDefinitionid=' +
        WFId +
        '&DataDefinitionid=' +
        dfId,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }
  getResetUserDfdlist(WFId: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetDataDefinitionReset?DefaultDataDefinitionid=' +
        WFId,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
  getJsonOrDfd(
    searchText: any,
    email: string,
    isArray: boolean,
    json: string,
    filter_options: any,
    isCacheCall?:boolean
  ) {
    let sort: any = [];
    if (json === 'false') {
      sort = [{ column_name: 'fieldName', direction: 'asc' }];
    }
    let param = {
      offset: 0,
      fetch: isCacheCall?10000:1000,
      search_text: searchText,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'jsonElement',
          value: json,
        },
      ],
      sort_options: sort,
      filter_options: filter_options,
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'WorkFlow/GetJSONorDataDefinition?userEmail=' +
        email +
        '&isArray=' +
        isArray,
        param
      )
      .pipe(catchError(this.handelError));
  }

  setSelectedDataDetailTab(data: any) {
    this.selectedDataFromDetail = JSON.stringify(data);
    this.setSelectedDetailFlag();
    return data;
  }

  getSelectedDataDetailTab() {
    return this.selectedDataFromDetail;
  }

  resetSelectedDetailTab() {
    return (this.selectedDataFromDetail = []);
  }

  setSelectedDetailFlag() {
    this.setSelectedValue = true;
    return this.setSelectedValue;
  }

  setJsonError(data: any) {
    this.jsonError = data;
  }

  getJsonError() {
    return this.jsonError;
  }

  passtoCondition(data: any) {
    return data;
  }
}
