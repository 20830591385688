<div *ngIf="isShowMessage" class="newuser-toast-bg full-ship-toast shipment-full-ship-toast shipment-login">
  <div class="text-center contain-bg">
    <span (click)="redirectToLogin()" class="newuser_link">{{
      showMessage
      }}</span>

    <span class="close-button" (click)="closeToasterMsg()">
      <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231">
        <g data-name="Group 2379">
          <path data-name="Path 6681"
            d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
            transform="translate(.288 .287)" style="fill: #01457c; stroke: #01457c; stroke-width: 0.5px" />
        </g>
      </svg>
    </span>
  </div>
</div>
<div *ngIf="showToastMessage" class="newuser-toast-bg remove-added-toast full-ship-toast">
  <div class="text-center contain-bg">
    <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
      {{ message }}
    </span>
    <span class="close-button" (click)="closeToasterMsg()"><img src="../../assets/img/close_whitebg_remove.svg"
        alt="" /></span>
  </div>
</div>
<div *ngIf="customsRefreshToast" class="newuser-toast-bg add-remove-toast toast-limited-acess page-refreshed">
  <div class="text-center contain-bg">
    <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
      Page is Refreshed
    </span>
    <span class="close-button" (click)="closeRefreshToast()"><img src="../../assets/img/close_whitebg_remove.svg"
        alt="" /></span>
  </div>
</div>
<div id="bookHeader" class="header-fix fixed-fullwidth add-job-ref ref-added remove-sm-bad">
  <div class="head-sec set-one">
    <div class="left-sec"></div>
    <div class="col-lg-6 col-md-12 text-right">
      <button class="btn btn-primary mr-2 shipment-btn {{
          getCustomExceptionClass(
            selectedCustom?.customstatus,
            selectedCustom?.customException
          )
        }} {{ selectedCustom?.customstatus | titlecase }}">
        {{ selectedCustom?.customstatus }}
      </button>
      <div class="image-container">
        <div class="dropdown added-set">
          <button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" class="btn btn-secondary dropdown-toggle">
            <img src="../../assets/img/actionmenu-header.png" alt="action-menu" />
          </button>
          <div aria-labelledby="dropdownMenuButton" class="dropdown-menu no-close">
            <a *ngIf="!watchListToggle && selectedCustom?.isFullViewAccess" href="javascript:void(0)"
              class="dropdown-item"
              (click)="addWatchList('Add', selectedCustom?.forwarder_reference)"><span>Watchlist</span></a>
            <a *ngIf="watchListToggle && selectedCustom?.isFullViewAccess" href="javascript:void(0)"
              class="dropdown-item checkedClass" (click)="
                addWatchList('Remove', selectedCustom?.forwarder_reference)
              "><img class="image-okay" src="../../assets/img/active-data.svg" alt="" /><span>Watchlist</span></a>
            <a class="dropdown-item"
              (click)="share(selectedCustom?.forwarder_reference, 'Detail')"><span>Share</span></a>
            <a (click)="refreshCustomsDetails()" class="dropdown-item">
              <div *ngIf="customsRefreshIcon" class="refresh-icon"></div>
              <span [ngClass]="{ 'refreshing-set': customsRefreshIcon }">{{
                customsRefreshIcon ? "Refreshing" : "Refresh"
                }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="head-sec">
    <div class="left-sec">
      <div class="detail-shipment">
        <div class="r-1">
          <div class="trans-status">
            <!-- Status icon render with exception color start-->
            <app-status-icon [transport_mode]="selectedCustom | trasportModeByFileType"
              [status]="selectedCustom?.customstatus" [exception]="selectedCustom?.customException"></app-status-icon>
            <!-- Status icon render with exception color end-->
          </div>
        </div>
        <div class="r-2">
          <h3 class="id-number pb-0 pr-0">
            {{ selectedCustom?.forwarder_reference }}
            <span class="ship-number-detail">
              <span class="header-point">•</span>
              <span class="container-sec">{{ selectedCustom?.containerization_type }}
                <span class="container-sec p-0" *ngIf="selectedCustom?.containers">
                  •
                  <span *ngIf="selectedCustom?.containers?.length <= 1">
                    {{ selectedCustom?.containers?.length }} Container</span>
                  <span *ngIf="selectedCustom?.containers?.length > 1">
                    {{ selectedCustom?.containers?.length }}
                    Containers</span></span>
              </span>
              <a (click)="
                  openCustomsFullScreen(
                    $event,
                    selectedCustom?.forwarder_reference,
                    'list'
                  )
                " class="pointer first-icon">
                <img src="../../../../../assets/img/shipments/open-new.svg" alt="" class="mr-1" />
              </a>
            </span>
          </h3>

          <div class="container-sec p-0">
            <span *ngIf="selectedCustom.customs_declarations[0]?.customs_type" class="imp">{{
              selectedCustom.customs_declarations[0]?.customs_type
              | customType
              }}</span>
            <span *ngIf="
                selectedCustom.customs_declarations[0]?.customs_type &&
                selectedCustom.customs_declarations[0]?.customs_agency_statuses
                  ?.length > 0 &&
                selectedCustom.customs_declarations[0]?.customs_type !==
                  'EXP' &&
                selectedCustom.customs_declarations[0]?.customs_type !==
                  'OUT' &&
                selectedCustom.customs_declarations[0]?.customs_type !== 'IPT'
              ">
              -
            </span>
            <span class="pga-exists" *ngIf="
                selectedCustom.isFullViewAccess &&
                selectedCustom.customs_declarations[0]?.customs_agency_statuses
                  ?.length > 0 &&
                selectedCustom.customs_declarations[0]?.customs_type !==
                  'EXP' &&
                selectedCustom.customs_declarations[0]?.customs_type !==
                  'OUT' &&
                selectedCustom.customs_declarations[0]?.customs_type !== 'IPT'
              ">PGA Exists</span>
          </div>
        </div>
        <span class="con-level"><span *ngIf="selectedCustom.isFullViewAccess" class="shipper-sec"><strong
              class="pr-1">Supplier:</strong>{{
            selectedCustom?.supplier?.name
            ? selectedCustom?.supplier?.name
            : "---"
            }}</span>
          <span class="shipper-sec" *ngIf="selectedCustom.isFullViewAccess"><strong class="pr-1">Importer:</strong>{{
            selectedCustom?.importer?.name
            ? selectedCustom?.importer?.name
            : "---"
            }}</span>
          <p class="fixed-w mobile-left m-top set-top" *ngIf="selectedCustom.isFullViewAccess">
            <strong>Consignee:</strong><span class="pl-1 consignee-name">{{
              selectedCustom?.ultimate_consignee?.name
              ? selectedCustom?.ultimate_consignee?.name
              : "---"
              }}</span>
          </p>
          <p *ngIf="
              selectedCustom.isFullViewAccess &&
              getCustomReferenceNumbers(selectedCustom?.shipper_reference, 0)
            " class="ref-width mobile-left p-0 linspace set-move-one">
            <span class="title-ref"><strong>Ref:</strong></span>
            <span class="data-ref">
              {{
              getCustomReferenceNumbers(
              selectedCustom?.shipper_reference,
              refLengthCheck
              )
              }}
            </span>
            <a href="javascript:void(0)" class="show-more" *ngIf="
                getCustomReferenceNumbers(selectedCustom?.shipper_reference, 0)
                  .length > refLengthCheck
              " (click)="gotoRefSection()">More</a>
          </p>
        </span>
      </div>
    </div>
    <div class="right-sec">
      <div class="detail-shipment update-top-space mobile-left m-top"
        [class.add-sp-one]="checkSFile(selectedCustom?.forwarder_reference)">
        <h3 class="location pr-0 pb-0" *ngIf="getPickupAndDeliveryPortCountry(selectedCustom)">
          <strong>
            {{ getPickupAndDeliveryPortCountry(selectedCustom) }}
          </strong>
        </h3>
        <p>
          <strong *ngIf="
              selectedCustom.customs_declarations[0]?.customs_type !== 'EXP'
            ">Entry Port Arrival Date:</strong>
          <strong *ngIf="
              selectedCustom.customs_declarations[0]?.customs_type == 'EXP'
            ">Export Date:</strong>
          <span class="pl-1">{{
            getEntryPortOrExportDate(selectedCustom)
            }}</span>
        </p>
        <p *ngIf="selectedCustom.isFullViewAccess">
          <strong *ngIf="
              selectedCustom?.customs_declarations[0]?.customs_type !== 'EXP'
            ">Entry Number:</strong>
          <strong *ngIf="
              selectedCustom?.customs_declarations[0]?.customs_type == 'EXP'
            ">ITN Number:</strong>
          <span class="pl-1">{{
            selectedCustom?.customs_declarations[0].declaration_number
            ? selectedCustom?.customs_declarations[0].declaration_number
            : "---"
            }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 text-right view-set" *ngIf="
      selectedCustom.isFullViewAccess &&
      checkSFile(selectedCustom?.forwarder_reference)
    ">
    <button class="view-shipment" (click)="viewShipment(selectedCustom?.forwarder_reference)">
      View shipment
    </button>
  </div>

  <div class="row">
    <ul id="navCenter" role="tablist" class="nav nav-tabs tab-nav"
      [class.add-ref-section]="selectedCustom?.shipper_reference?.length > 0"
      [class.add-sp-set]="!checkSFile(selectedCustom?.forwarder_reference)">
      <li role="presentation" class="nav-item">
        <button id="home-tab" data-bs-toggle="tab" data-bs-target="#customDetail" type="button" role="tab"
          aria-controls="home" aria-selected="true" class="nav-link active">
          Details
        </button>
      </li>
      <li *ngIf="selectedCustom.isFullViewAccess" role="presentation" class="nav-item">
        <button id="profile-tab" data-bs-toggle="tab" data-bs-target="#customDocuments" type="button" role="tab"
          aria-controls="profile" aria-selected="false" class="nav-link">
          Documents
          <strong>{{ selectedCustom?.attachments?.length || 0 }}</strong>
        </button>
      </li>
      <li role="presentation" *ngIf="selectedCustom?.commercial_invoices?.length > 0" class="nav-item">
        <button id="invoice-tab" data-bs-toggle="tab" data-bs-target="#customInvoice" type="button" role="tab"
          aria-controls="invoice" aria-selected="false" class="nav-link">
          Invoice
          <strong>{{
            selectedCustom?.commercial_invoices?.length || 0
            }}</strong>
        </button>
      </li>
      <li role="presentation" class="nav-item">
        <button id="pga-tab" data-bs-toggle="tab" data-bs-target="#customPga" type="button" role="tab"
          aria-controls="pga" aria-selected="false" class="nav-link" *ngIf="
            selectedCustom.customs_declarations[0]?.customs_agency_statuses
              ?.length > 0 &&
            selectedCustom.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedCustom.customs_declarations[0]?.customs_type !== 'OUT' &&
            selectedCustom.customs_declarations[0]?.customs_type !== 'IPT' &&
            selectedCustom?.commercial_invoices?.length > 0
          ">
          PGA
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="hasActionField">
        <button class="nav-link" id="action-tab" data-bs-toggle="tab" data-bs-target="#action-tab-cutoms" type="button"
          role="tab" aria-controls="profile" aria-selected="false">
          Actions
        </button>
      </li>
      <li *ngIf="selectedCustom.isFullViewAccess && logsTabCount > 0" class="nav-item" role="presentation">
        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#logs1" type="button" role="tab"
          aria-controls="profile" aria-selected="false" (click)="setCurrentTab(6)">
          Logs
        </button>
      </li>
    </ul>
  </div>
</div>