import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'bookingReferenceNumber'
})
export class BookingReferenceNumberPipe implements PipeTransform {

  transform(order: any, limit: number): string {
    let msg = '<strong>Job Ref</strong>: ';
    msg += CommonUtils.getShipmentReferenceNumbers(order, limit);
    return msg;
  }

}
