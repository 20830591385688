<div id="wrap-{{filterType.type}}" class="dropdown close-wrap" *ngIf="!isMobileView">
  <button id="button-{{filterType.type}}" class="btn filter-selct-btn w100 custom-arow border-added" type="button"
    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{ statusSelectedText }}
    <span><img src="../../../../../assets/img/down_arrow-lg.svg" alt="" /></span>
    <span><img src="../../../../../assets/img/close-sm.png" class="close-sm" alt="" (click)="
          clearFilters(filterType.type, filterType.index, filterType.id)
        " /></span>
  </button>
  <div class="dropdown-menu dropdown-comp" id="dropdownItem-{{filterType.type}}" aria-labelledby="dropdownMenuButton">
    <ul class="custom-drop-menu">
      <li class="dropdown-item" *ngFor="let filter of filterType.filterArray; let i = index">
        <input [(ngModel)]="filter.checked" class="test-selection" type="checkbox" name="filter-{{ filter.filt }}"
          value="{{ filter.val }}" id="{{ filterType.type }}-{{ filter.val }}" (change)="
            clickDropdownFilter.next({'filterDisplayName':filterType.filterDisplayName,'type': filterType.type})
          " />
        <label class="selection-list" for="{{ filterType.type }}-{{ filter.val }}">
          <span class="header-set">{{ filter.filt }}</span>
        </label>
      </li>
    </ul>
  </div>
</div>

<div class="accordion-body" *ngIf="isMobileView">
  <ul class="custom-drop-menu">
    <li class="dropdown-item" *ngFor="let filter of filterType.filterArray; let i = index">
      <input [(ngModel)]="filter.checked" class="test-selection" type="checkbox" name="filter-{{ filter.filt }}"
        value="{{ filter.val }}" id="{{ filterType.type }}-{{ filter.val }}" (change)="
              isStatusSelected(filterType.filterDisplayName, filterType.type)
            " />
      <label class="selection-list" for="{{ filterType.type }}-{{ filter.val }}">
        <span class="header-set">{{ filter.filt }}</span>
      </label>
    </li>
  </ul>
</div>