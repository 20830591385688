import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ShipmentComponent } from './container/shipment/shipment.component';
import { RouterModule, Routes } from '@angular/router';
import { ViewAllShipmentsComponent } from './components/view-all-shipments/view-all-shipments.component';
import { AI_PageName } from 'src/app/services/appinsights.service';
import { ShipmentSearchComponent } from './components/shipment-search/shipment-search.component';

const routes: Routes = [
  {
    path: '',
    component: ShipmentComponent,
    data: { title: AI_PageName.Shipment.toString() },
  },
  {
    path: ':filterParam',
    component: ShipmentComponent,
    data: { title: AI_PageName.Booking.toString() },
  },
  {
    path: 'view/search-result',
    component: ViewAllShipmentsComponent,
    data: { title: AI_PageName.ViewAllShipment.toString() },
  },
  {
    path: 'search/:searchParam',
    component: ShipmentSearchComponent,
    data: { title: AI_PageName.ViewAllShipment.toString() },
  },
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [],
  providers: [DatePipe],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShipmentRoutingModule { }
