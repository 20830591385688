import {
  Injectable,
  ViewChild
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import {
  from,
  Observable,
  of
} from 'rxjs';
import {
  MsalService
} from '@azure/msal-angular';
import {
  catchError,
  first,
  mergeMap
} from 'rxjs/operators';
import {
  BrowserCacheLocation,
  PublicClientApplication
} from '@azure/msal-browser';
import {
  environment
} from 'src/environments/environment';
import {
  HeaderComponent
} from 'src/app/features/header/containers/header.component';
import {
  AppInsightsService
} from 'src/app/services/appinsights.service';
import { CommonUtils } from 'src/app/common/utils';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
      private authServicemicro: MsalService,
      private appInsightService: AppInsightsService,
  ) {}
  private lastServerHitHappend: any = null;
  private skewTime:number= environment.session.reInitiate.skewTime;
  private idleLogoutTime:number= environment.session.idleLogoutTime;
  intercept(
      request: HttpRequest < any > ,
      next: HttpHandler
  ): Observable < HttpEvent < any >> {
      let isIE =
          window.navigator.userAgent.indexOf('MSIE ') > -1 ||
          window.navigator.userAgent.indexOf('Trident/') > -1;
      if (localStorage.getItem('tokenExpiresOn')) {
          const unixTime: any = localStorage.getItem('tokenExpiresOn') //1637143707;
          const actualDate = new Date(unixTime * 1000);
          const utcDate = actualDate.toUTCString();


          if (this.checkSystemIdleTime(this.getLastHit())) {
              this.Logout();
          }

          if (this.getCurrentDateTime() < new Date(utcDate)) {
              request = request.clone({
                  setHeaders: {
                      Authorization: `Bearer ${localStorage.getItem('authToken')}`
                  },
                  setParams:{
                    'rand':CommonUtils.getRandomDateTime()
                  }
              });
              this.captureLastHit();
              return next.handle(request);
          } else {
              if (localStorage.getItem('googleAccount')) {

                  const config = {
                      auth: {
                          clientId: environment.B2CClientId, // This is the ONLY mandatory field that you need to supply.
                          authority: environment.B2CAuthUrl + environment.googleB2C, // Defaults to "https://login.microsoftonline.com/common"
                          knownAuthorities: [environment.B2CKnownAuthorities], // Mark your B2C tenant's domain as trusted.
                          redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
                          postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
                          navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
                      },
                      cache: {
                          cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                          storeAuthStateInCookie: isIE,
                      },

                  }

                  const myMsal = new PublicClientApplication(config);
                  let account = this.authServicemicro.instance.getAllAccounts()[0];
                  let googleUser = localStorage.getItem('googleAccount')
                  let userEmail = googleUser ? JSON.parse(googleUser) : '';
                  const accessTokenRequest = {
                      scopes: ['openid', 'profile'],
                      account: account,
                      loginHint: userEmail.email
                  };

                  try {
                      return from(myMsal.acquireTokenSilent(accessTokenRequest)).pipe(
                          first(),
                          mergeMap((configData: any) => {
                              localStorage.setItem('tokenExpiresOn', configData.idTokenClaims.exp);
                              localStorage.setItem('authToken', configData.idToken);
                              request = request.clone({
                                  headers: new HttpHeaders({
                                      Authorization: 'Bearer ' + configData.idToken,
                                  }),
                              });
                              this.captureLastHit();
                              return next.handle(request);
                          }),
                          catchError((error: any) => {
                              localStorage.clear();
                              sessionStorage.clear();
                              location.reload();
                              this.clearLastHit();
                              return of(error);
                          })
                      );
                  } catch (ex) {
                      localStorage.clear();
                      sessionStorage.clear();
                      location.reload();
                      this.clearLastHit();
                  }
              } else if (localStorage.getItem('microSoftAccount')) {
                  const config = {
                      auth: {
                          clientId: environment.B2CClientId, // This is the ONLY mandatory field that you need to supply.
                          authority: environment.B2CAuthUrl + environment.microSoftB2C, // Defaults to "https://login.microsoftonline.com/common"
                          knownAuthorities: [environment.B2CKnownAuthorities], // Mark your B2C tenant's domain as trusted.
                          redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
                          postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
                          navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
                      },
                      cache: {
                          cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                          storeAuthStateInCookie: isIE,
                      },

                  }

                  const myMsal = new PublicClientApplication(config);
                  let account = this.authServicemicro.instance.getAllAccounts()[0];
                  let microUser = localStorage.getItem('microSoftAccount')
                  let userEmail = microUser ? JSON.parse(microUser) : '';
                  const accessTokenRequest = {
                      scopes: ['openid', 'profile'],
                      account: account,
                      loginHint: userEmail.email
                  };
                  try {
                      return from(myMsal.acquireTokenSilent(accessTokenRequest)).pipe(
                          first(),
                          mergeMap((configDataRes: any) => {
                              localStorage.setItem('tokenExpiresOn', configDataRes.idTokenClaims.exp);
                              localStorage.setItem('authToken', configDataRes.idToken);
                              request = request.clone({
                                  headers: new HttpHeaders({
                                      Authorization: 'Bearer ' + configDataRes.idToken,
                                  }),
                              });
                              this.captureLastHit();
                              return next.handle(request);
                          }),
                          catchError((errorValue: any) => {
                              localStorage.clear();
                              sessionStorage.clear();
                              location.reload();
                              this.clearLastHit();
                              return of(errorValue);
                          })
                      );
                  } catch (ex) {
                      localStorage.clear();
                      sessionStorage.clear();
                      location.reload();
                      this.clearLastHit();
                  }
              }
               this.captureLastHit();
              return next.handle(request);
          }
      } else if (localStorage.getItem('outlook')) {
          request = request.clone({
              setHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('outlook')}`
              }
          });
          this.captureLastHit();
          return next.handle(request);
      } else {
          request = request.clone({
              headers: new HttpHeaders({
                  Authorization: "",
              }),
          });
          this.clearLastHit();
          return next.handle(request);
      }

  }


  private captureLastHit() {
      localStorage.setItem("lastServerHitHappend", new Date().toString());
  }

  private getLastHit(){
     let lastServerHit:any=localStorage.getItem("lastServerHitHappend");
     return lastServerHit?new Date(lastServerHit):lastServerHit;
  }

  private clearLastHit() {
      localStorage.removeItem("lastServerHitHappend");
  }

  private Logout() {
    this.logAppInsight();
    this.clearLastHit();
    this.appInsightService.clearUserId();
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = environment.localUrl + '/login/log-in';
  }

  private checkSystemIdleTime(lastServerHit: any) {
      if (lastServerHit) {
          let now = new Date();
          let maxAllowedTime = new Date(new Date(lastServerHit).getTime() + (this.idleLogoutTime * 60) * 60000); // max limit set to 6hrs
          if (now < maxAllowedTime) {
              return false;
          } else {
              return true;
          }
      } else {
          return false;
      }

  }

  private logAppInsight()
 {
	this.appInsightService.logEvent('User Logs out automatically due to system idle exceeds more than a hour',
	{
		"last transaction happend": this.getLastHit().toString(),
		"log out time": new Date().toString()
	});
 } 

  private getCurrentDateTime() // added skew time, to re initiate token early
 { 
    return new Date(new Date().getTime() + this.skewTime * 60 * 1000)
 } 

}