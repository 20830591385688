import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'yearFormat',
})
export class YearFormatPipe implements PipeTransform {
  transform(date: any): unknown {
    return CommonUtils.formatDateMMMDDYYYY(date);
  }
}
