<div class="quick-search-wrap">
    <div class="row">
        <div class="col-lg-12 col-xs-6 col-sm-12 col-md-12 mb-3" *ngIf="isShipmentIsVisible">
            <h3>Quick Searches</h3>
            <button class="btn btn-blue" *ngFor="let button of quickSearchButtons"
                (click)="selectedStatus(button)">{{button.name}}</button>
        </div>
        <div class="col-lg-12 col-xs-6 col-sm-12 col-md-12 recent-search">
            <h5>Recent Searches</h5>
            <ul>
                <li *ngFor="let search of recentSearch" id="global-{{search}}" (click)="resultPage(search)">
                    {{search}}
                </li>
            </ul>
        </div>
    </div>

</div>