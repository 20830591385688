import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipmentReferenceNumberPipe',
})
export class ShipmentReferenceNumberPipe implements PipeTransform {
  transform(order: any, limit: number): string {
    let msg = '<strong>Ref</strong>: ';
    msg += CommonUtils.getShipmentReferenceNumbers(order, limit);
    return msg;
  }
}
