<div class="oia-container">
  <app-progressbar [hidden]="!isProgressShow"></app-progressbar>
  <app-common-default-filter [ngClass]="{'fileUpload-visible-list':isCustomFilterShow}"
    *ngIf="!isDetailFullView && currentUserData" [defaultFilterSelectedValue]="defaultFilterSelectedValue"
    (onDefaultFilterSelectedHandler)="onDefaultFilterSelectedHandler($event)"
    (userFilterFetch)="userFilterFetch($event)" [savedFilters]="savedFiltersList"
    [savedUserFilterRowId]="savedFilterRow" (guidedTourStartManually)="guidedTourStartManually($event)"
    [defaultFiltersList]="bookingDefaultFiltersList" [currentPage]="'Bookings'">
  </app-common-default-filter>
  <div class="oia-content-wrap">
    <div class="oia-inner-wrap">
      <app-sucess-toast *ngIf="isShowMessageDel" [message]="showMessageDel" (undoDelete)="undoDeleteUserFilter($event)"
        class="my-profile-toast delete-filter"></app-sucess-toast>
      <app-success-toast *ngIf="isShowMessage" [message]="showMessage" class="my-profile-toast"></app-success-toast>
      <app-booking-custom-filters [ngClass]="{'fileUpload-visible-list':isCustomFilterShow}" id="customFilter"
        *ngIf="!isDetailFullView && currentUserData" (onSortSelectedHandler)="onSortSelectedHandler($event)"
        [bookingSortOptions]="bookingSortOptions" [defaultBookingSortSelectedValue]="defaultBookingSortSelectedValue"
        [defaultBookingSortDirection]="defaultBookingSortDirection" (clearSearchFilter)="clearSearchFilter($event)"
        (selectedFilterArray)="onSelectedCustomFilter($event)" (clearAllFilterValue)="clearAllFilterValue($event)"
        (clearSearchFilter)="clearSearchFilter($event)" (selectedMainFilterArray)="getMainFilterSelection($event)"
        (selectedDateRange)="onSelectedCustomFilter($event)" (downloadExcel)="prepareExcelDownload($event)"
        (selectedUrlFilters)="getAllURLFilter($event)" (prepareUrlShare)="prepareShareUrl($event)" [urlShare]="url"
        [resultCount]="totalBookingCount" (onBookingSearchHandler)="onBookingSearchHandler($event)"
        [bookingSearchText]="bookingSearchText" (locFilter)="onSelectedCustomFilter($event)" [resentList]="resentList"
        [paramCheck]="filterCheck" [saveFilterJson]="saveFilterJson" (onUserFilterSaved)="getUserFilters($event)"
        [tempFilterDiff]="filterCheckTemp" [isfilterEdit]="myFilterEdit" (isLoading)="customFilterApiLoader($event)"
        [savedFilters]="savedFiltersList" (userFilterFetch)="userFilterFetch($event)"
        [selectedDateRangeRender]="selectedDateRange" [guidedTourFilter]="guidedTourBookingFilter"
        (selectedDefualtUrlFilters)="setUrlDefaultFilter($event)">
      </app-booking-custom-filters>
      <app-booking-list *ngIf="!isDetailFullView && currentUserData" [bookingMasterData]="bookingMasterData"
        [totalBookingCount]="totalBookingCount" [urlShare]="url" [isProgressShow]='isProgressShow' [offset]="page"
        [urlBookingId]="urlBookingId" (prepareUrlShare)="prepareShareUrl($event)"
        (emitOffset)="getListingOffset($event)" [publicShareEnrcyptedData]="publicShareEnrcyptedData"
        [isInfiniteDisabled]="isInfiniteDisabled" [guidedTourDetail]="guidedTourBookingDetail">
      </app-booking-list>
      <app-booking-details *ngIf="isDetailFullView && selectedBookingLen > 0" [urlBookingId]="urlBookingId"
        [popupToggle]="popupToggle" [selectedBooking]="getBookingDetails"
        (onRefreshDetails)="onRefreshDetailFromDetail($event)" (detailPopClose)="featureHide()"
        (prepareUrlShare)="detailShare($event)" [publicShareEnrcyptedData]="publicShareEnrcyptedData" [urlShare]="url">
      </app-booking-details>
    </div>
  </div>
  <div class="outer-close" *ngIf="guidedFeatureManualModalShow">
    <div class="outer-close-set" (click)="gudideTourCloseset()"></div>
    <div class="rap-set need-help" *ngIf="guidedFeatureManualModalShow">
      <div class="rap-header">
        <h2>Need help?</h2>
        <p>Use this guided tour to get a walkthrough of the bookings page or visit the FAQ page <a
            (click)="navigateToFaq()">here</a>.</p>
        <div class="rap-footer">
          <div class="left-sec"></div>
          <div class="right-sec">
            <div class="text-one">Start Guided Tour</div>
            <img *ngIf="!isProgressShow" src="../../../../../assets/img/yellow-back.png" alt="guide-tour"
              (click)="gudideTourStart()">
            <img *ngIf="isProgressShow" src="../../../../../assets/img/yellow-back.png" alt="guide-tour">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rap-set" id="rapModal" *ngIf="guidedFeatureModalShow">
    <div class="rap-header">
      <h2>{{guidedTourPopupHeader}}</h2>
      <p>{{guidedTourPopupPara}}</p>
      <div class="rap-footer">
        <div class="left-sec" *ngIf="totalBookingCount > 0 && guidedTourIsConvertedBooking.length > 0">
          {{guidedTourFlag}}/8</div>
        <div class="left-sec" *ngIf="totalBookingCount > 0 && guidedTourIsConvertedBooking.length < 1">
          {{guidedTourFlagNoShipment}}/7</div>
        <div class="left-sec" *ngIf="totalBookingCount < 1">{{guidedTourFlagNoShipment}}/5</div>
        <div class="right-sec">
          <img src="../../../../../assets/img/arrow-guide.png"
            *ngIf="(totalBookingCount < 1 && guidedTourFlag >3) ||(totalBookingCount > 0 && guidedTourFlag > 1)"
            alt="guided tour previous" (click)="previousFeature()" class="roate-sec">
          <img src="../../../../../assets/img/arrow-guide.png" alt="guided tour next" (click)="nextFeature()">
        </div>
      </div>
    </div>
    <div class="static-menu-shipment sort-by">
      <ul class="custom-drop-menu m-0">
        <li class="dropdown-item" *ngFor="let sortitems of sortBy" [ngClass]="sortitems.item == 'ATD' ? 'active' : ''">
          <img *ngIf="sortitems.item == 'ATD'" alt="desc"
            src="../../../../../assets/img/shipments/shipment-sort-icon.svg" class="mr-1 update">{{sortitems.item}}
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="guidedTourPopup" class="user-guide-list">
  <div class="user-card guide-tour">
    <div class="pt-3 pb-3 text-center">
      <img src="assets/img/oia-logo@3x.png" class="img-fluid" alt="logo" />
    </div>

    <p class="update-font pt-2 head-sec">Bookings Module Now Available!</p>
    <p>We are pleased that OIA Connect now allows you to view your shipment bookings!</p>
    <p class="pb-3">To help get you onboard, we have created a guided tour that will highlight the features we think
      will be of most value
      to you!</p>
    <button class="btn btn-primary yellow-button w-100 btn-lg mb-3 text-uppercase" (click)="startGuidedTour('first')">
      START GUIDED TOUR
    </button>
    <p class="text-center">
      <a (click)="skipTour()" class="link">SKIP AND CONTINUE</a>
    </p>

    <div>
      <img src="assets/img/login/delivery-5-Converted@2x.png" class="img-fluid" alt="" />
    </div>
  </div>
</div>

<div class="align-items-center justify-content-xl-end shipment-list-filter">
  <div id="bookingSharePopup" *ngIf="bookingSharePopup" class="dimScreen">
    <div *ngIf="bookingSharePopup" class="add-user-popup">
      <button class="close" type="button" (click)="sharePopUpfeatureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
      </button>
      <div class="body-section">
        <h3>Share <span>Booking</span></h3>
        <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" id="tabDetail">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../../../../../assets/img/share/url-img.svg" alt="" />
              URL
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div class="discription">
              <p class="text-des">
                For users with a Connect account and access to this company, they will be able to see the full booking
                details.
                Otherwise, they will be able to see a public URL with only booking details.
              </p>
              <div class="copy-section">
                <input type="text" id="shareUrl" [value]="url" readonly />
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>