import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'estimatedDelivery'
})
export class EstimatedDeliveryPipe implements PipeTransform {

  transform(data: any): unknown {
    let message = '---';
    if (data?.delivery_estimated) {
      message = CommonUtils.formatDateMMMDDYYYY(data?.delivery_estimated);
    }
    return message;
  }

}
