import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ProgressBarType } from 'src/app/common/shipment.enums';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-shipment-containers-tab',
  templateUrl: './shipment-containers-tab.component.html',
  styleUrls: ['./shipment-containers-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentContainersTabComponent implements OnInit {
  @Input() selectedShipment: any = [];

  constructor(private appInsightsService: AppInsightsService) { }

  ngOnInit(): void {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ContainerTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
  }

  formatDateMMMDD(date: any) {
    return CommonUtils.formatDateMMMDD(date);
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }
}
