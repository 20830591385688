import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'trimmedAddressData'
})
export class TrimmedAddressDataPipe implements PipeTransform {

  transform(city: any, state: any, country: any): unknown {
    let message = '';
    if (city) message += CommonUtils.toTitleCase(city) + ', ';
    message += CommonUtils.getUSCanadaStateText(country, state);
    if (country) message += country + ', ';
    return CommonUtils.removeLastComma(message);
  }

}
