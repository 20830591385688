<div class="oia-content-wrap">
    <div class="oia-inner-wrap">
        <app-progressbar [hidden]="!isLoading"></app-progressbar>
        <app-shipment-detail *ngIf="selectedShipmentLen > 0 && isShipmentView" [urlShipmentId]="urlShipmentId"
            (prepareUrlShare)="detailShare($event)" [selectedShipment]="getShipmentDetails" [refreshFalse]="false"
            [popupToggle]="popupToggle" (onRefreshDetails)="onRefreshShipmentFromDetail($event)"
            [publicShareEnrcyptedData]="publicShareEnrcyptedData">
        </app-shipment-detail>
        <app-booking-details *ngIf="isBookingView && selectedBookingLen > 0" [urlBookingId]="urlShipmentId"
            [popupToggle]="popupToggle" [selectedBooking]="getBookingDetails" (prepareUrlShare)="detailShare($event)"
            [urlShare]="url" (onRefreshDetails)="onRefreshBookingFromDetail($event)">
        </app-booking-details>
        <app-customs-details *ngIf="isCustomsView && selectedCustomsLen > 0" [urlCustomsId]="urlShipmentId"
            [popupToggle]="popupToggle" [selectedCustom]="getCustomsDetails" (prepareUrlShare)="detailShare($event)"
            [urlShare]="url" (onRefreshDetailsCustoms)="onRefreshCustomsFromDetail($event)"></app-customs-details>
        <app-shipment-not-found-page *ngIf="resultNotFound" [urlSearchedText]="urlShipmentId"
            (searchedText)="onSearchText($event)">

        </app-shipment-not-found-page>
        <div id="pop-up3" *ngIf="popupToggleList" class="dimScreen">
            <div *ngIf="popupToggleList" class="add-user-popup">
                <button class="close" type="button" (click)="sharePopUpfeatureHide()">
                    <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
                </button>
                <div class="body-section fix-size">
                    <h3>Share
                        <span *ngIf="isShipmentView">Shipment</span>
                        <span *ngIf="isCustomsView">Customs</span>
                        <span *ngIf="isBookingView">Bookings</span>
                    </h3>
                    <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" id="tabDetail">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                                type="button" role="tab" aria-controls="home" aria-selected="true">
                                <img src="../../../../../assets/img/share/url-img.svg" alt="" />
                                URL
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
                            aria-labelledby="home-tab">
                            <div class="discription">
                                <p class="text-des" *ngIf="isShipmentView">
                                    For users with a Connect account and access to this company, they will be able to
                                    see the full shipment details. Otherwise, they will be able to see a public URL with
                                    only shipment, container progress and customs information.</p>
                                <p class="text-des" *ngIf="isCustomsView">
                                    For users with a Connect account and access to this company, they will be able to
                                    see the full customs details. Otherwise, they will be able to see a public URL with
                                    only customs details.</p>
                                <p class="text-des" *ngIf="isBookingView">
                                    For users with a Connect account and access to this company, they will be able to
                                    see the full booking details. Otherwise, they will be able to see a public URL with
                                    only booking details.
                                </p>
                                <div class="copy-section">
                                    <input type="text" id="shareUrl" [value]="url" readonly />
                                    <button class="add-type" (click)="copyLink()">COPY LINK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>