import {
  Component,
  Input,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { DetailTabName } from 'src/app/common/shipment.enums';
import { CommonUtils } from 'src/app/common/utils';
import { ShipmentServies } from 'src/app/features/shipments/services/shipment.service';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { environment } from 'src/environments/environment';
import { CustomsService } from '../../services/customs.service';
declare var $: any;

@Component({
  selector: 'app-customs-details-header',
  templateUrl: './customs-details-header.component.html',
  styleUrls: ['./customs-details-header.component.scss'],
})
export class CustomsDetailsHeaderComponent implements OnInit, OnChanges {
  @Input() selectedCustom: any = [];
  @Input() urlShare: any = [];
  @Input() publicShareEnrcyptedData: any = '';
  @Output() reloadCustomsListFromHeader = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  isProgressShow = false;
  customRefreshToast = false;
  isShowMessageFileuploadSec = false;
  showToastMessage = false;
  message = '';
  watchListToggle: boolean;
  fullScreenStatus: boolean = false;
  isShowMessage = false;
  showMessage: any = '';
  logsTabCount = 0;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  hasActionField = this.currentAccountData.isCustomActionField;
  popupToggleDetail = false;
  shipperName: string = '---';
  consigneeName: string = '---';
  scrHeight: any;
  scrWidth: any;
  refLengthCheck: number = 0;
  customsRefreshIcon: boolean = false;
  customsRefreshToast: boolean = false;
  @Output() refreshCustomsDetailsFromHeader = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    public customService: CustomsService,
    private router: Router,
    private currentSessionUserData: CurrentSessionDataService,
    public dataSharingService: DataSharingService,
    private shipmentService: ShipmentServies,
    private appInsightsService: AppInsightsService
  ) {
    this.errorMessage();

    this.dataSharingService.refreshShipmentDetailsInHeader.subscribe(
      (value: any) => {
        if (value) {
          this.customsRefreshIcon = true;
        } else {
          this.customsRefreshIcon = false;
        }
      }
    );

    this.dataSharingService.refreshShipmentDetailsInHeaderToaster.subscribe(
      (value: any) => {
        if (value) {
          this.customsRefreshToast = true;
          this.isShowMessage = false;
          this.showToastMessage = false;
          setTimeout(() => {
            this.customsRefreshToast = false;
            this.errorMessage();
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              false
            );
          }, 4000);
        } else {
          this.customsRefreshToast = false;
        }
      }
    );
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.refLengthCal();

    $('.no-close').on('click', function (e: any) {
      e.stopPropagation();
    });

    let selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab == 'Action') {
      this.openActionField();
    }
    else if(selectedTab == 'document/customs'){
      this.openDocumentTab();
    }

    localStorage.removeItem('selectedTab');
  }

  ngOnChanges(): void {
    this.watchListToggle = this.selectedCustom?.favoriteflag;
    if (this.currentAccountData) {
      this.getLogsCount();
    }
  }

  refLengthCal() {
    setTimeout(() => {
      const classFullScreen = $('#shipmentFull').hasClass('shipmet-fullwidth');
      if (this.scrWidth >= 1100 && !classFullScreen) {
        this.refLengthCheck = 82;
      } else if (this.scrWidth > 1100 && classFullScreen) {
        this.refLengthCheck = 180;
      } else if (this.scrWidth < 1100 && classFullScreen) {
        this.refLengthCheck = 90;
      } else {
        this.refLengthCheck = 72;
      }
    }, 10);
  }

  getCustomExceptionClass(status: any, exception: any) {
    return CommonUtils.getCustomExceptionClass(status, exception);
  }

  getCustomReferenceNumbers(message: any, limit: number) {
    if (limit > 0 && message.length > limit) {
      return message.substring(0, limit - 3) + '...';
    }
    return message;
  }

  closeRefreshToast() {
    this.customRefreshToast = false;
  }

  closeToasterFromFileUpload() {
    this.isShowMessageFileuploadSec = false;
  }

  getPickupAndDeliveryPortCountry(data: any) {
    let message = '';
    let pickup = this.getPickupPortCountry(data);
    let delivery = this.getDeliveryPortCountry(data);
    if (pickup && delivery) {
      message += pickup + ' - ' + delivery;
    } else if (pickup) {
      message += pickup;
    } else if (delivery) {
      message += delivery;
    }
    return message;
  }

  getPickupPortCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.lading_port) {
      message += data.lading_port.portName
        ? CommonUtils.toTitleCase(data.lading_port.portName) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.lading_port.country_name,
        data.lading_port.stateCode
      );

      message += data.lading_port.country_name
        ? CommonUtils.toTitleCase(data.lading_port.country_name)
        : '';
    }
    return CommonUtils.removeLastComma(message);
  }

  getDeliveryPortCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data?.arrival_port) {
      message += data.arrival_port.portName
        ? CommonUtils.toTitleCase(data.arrival_port.portName) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.arrival_port.country_name,
        data.arrival_port.stateCode
      );

      message += data.arrival_port.country_name
        ? CommonUtils.toTitleCase(data.arrival_port.country_name)
        : '';
    }
    return CommonUtils.removeLastComma(message);
  }

  getEntryPortOrExportDate(data: any) {
    let message = '---';
    if (data && data.customs_declarations && data.customs_declarations[0]) {
      if (data.customs_declarations[0].customs_type == 'EXP') {
        message = CommonUtils.formatDateMMMDDYYYY(
          data.customs_declarations[0].export_date
        );
      } else {
        message = CommonUtils.formatDateMMMDDYYYY(
          data.customs_declarations[0].declaration_date
        );
      }
    }
    return message;
  }

  getEntryNumber(data: any) {
    let message = '---';
    if (data && data.customs_declarations && data.customs_declarations[0]) {
      if (data.customs_declarations[0].customs_type == 'EXP') {
        message =
          data.customs_declarations[0].us_customs_declaration?.it_number;
      } else {
        message = data.customs_declarations[0].declaration_number;
      }
    }
    return message;
  }

  gotoRefSection() {
    $('#navCenter > li> button').removeClass('active');
    $('#home-tab').addClass('active');
    $('#myTabContent > div').removeClass('show active');
    $('#home1').addClass('show active');
    const scrollDiv = window.document.getElementById('sectionReferences');
    if (scrollDiv != null) {
      scrollDiv.scrollIntoView();
    }
  }

  viewShipment(bookingId: any) {
    this.appInsightsService.logEvent('Custom View Shipment Clicked', {
      'Shipment Id': bookingId,
    });
    let selectedFilters: any;
    if (this.currentAccountData && this.currentAccountData.shipmentIsVisible) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: bookingId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    } else if (
      this.currentAccountData &&
      !this.currentAccountData.shipmentIsVisible
    ) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: bookingId,
        type: 'Single',
        module: 'Shipment',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/dashboard/' + encry;
      window.open(url, '_blank');
    } else {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountDetail: {
          aliasName: decryUrlFilter.accountDetail.aliasName,
          oH_Code: decryUrlFilter.accountDetail.oH_Code,
          byGroup: decryUrlFilter.accountDetail.byGroup,
          isControllingCustomer:
            decryUrlFilter.accountDetail.isControllingCustomer,
        },
        shipmentId: bookingId,
        type: 'Detail',
        module: 'Shipment',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    }
  }

  openCustomsFullScreen(event: any, customsId: any, type: any) {
    if (type == 'list') {
      let selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        customsType: [],
        watchFilter: [],
        exceptionType: [],
        pgaFilter: [],
        dateRange: {
          selectedValue: [],
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: [],
          selectedAccount: [],
        },
        consignee: {
          selectedValue: [],
          selectedAccount: [],
        },
        importer: {
          selectedValue: [],
          selectedAccount: [],
        },
        importerOfRecord: {
          selectedValue: [],
          selectedAccount: [],
        },
        supplier: {
          selectedValue: [],
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { loadingPort: [] },
            { dischargePort: [] },
            { shipmentOrigin: [] },
            { shipmentDestination: [] },
            { portOfClearance: [] },
          ],
          selectedValue: [
            { loadingPortValue: [] },
            { dischargePortValue: [] },
            { shipmentOriginValue: [] },
            { shipmentDestinationValue: [] },
            { portOfClearanceValue: [] },
          ],
        },
        sorting: [],
        accountId: '',
        customsId: customsId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/customs/' + encry;
      window.open(url, '_blank');
    } else {
      this.fullScreenStatus = true;
      $('#shipmentFull').addClass('shipmet-fullwidth shimpent-removeicon');
      $('#navCenter').addClass('justify-content-center');
      $('.dimScreen').addClass('shipment-fullwidth');
    }
  }

  checkSFile(id: any) {
    if (id?.charAt(0).toLowerCase() === 's') {
      return true;
    } else {
      return false;
    }
  }

  hideToast() {
    let root = this;
    setTimeout(function () {
      root.showToastMessage = false;
      root.isProgressShow = false;
    }, 3000);
  }

  closeToasterMsg() {
    this.showToastMessage = false;
    this.message = '';
    this.isShowMessage = false;
    this.showMessage = '';
  }

  addWatchList(action: any, bookingId: any) {
    let emailId = this.currentUserData.emailAddress;
    this.isProgressShow = true;
    this.customService
      .addRemoveWatchList(bookingId, emailId, action)
      .subscribe({
        next: (addRemoveWatch: any) => {
          this.showToastMessage = true;
          this.message = addRemoveWatch.result;
          this.isProgressShow = false;
          this.appInsightsService.logEvent(
            action == 'Add'
              ? AI_CommonEvents.WatchListAdded.toString()
              : AI_CommonEvents.WatchListRemoved.toString(),
            {
              [AI_CustomProps.ShipmentId]:
                this.selectedCustom?.forwarder_reference,
              [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
            }
          );
          if (action == 'Add') {
            this.watchListToggle = true;
          } else if (action == 'Remove') {
            this.watchListToggle = false;
          }

          this.reloadCustomsListFromHeader.emit(bookingId);
          this.hideToast();
        },
      });
  }

  getCustomsExceptionClass(status: any, exception: any) {
    return CommonUtils.getCustomExceptionClass(status, exception);
  }

  getLogsCount() {
    this.customService
      .getLogsCount(
        this.selectedCustom.forwarder_reference,
        this.currentUserData.emailAddress
      )
      .subscribe((res) => {
        if (res) {
          this.logsTabCount = res.recordCount;
        }
      });
  }

  redirectToLogin() {
    this.dataSharingService.notLoggedIn.next(false);
    let decryUrlFilter: any = {};
    if (this.publicShareEnrcyptedData) {
      decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
    }
    if (decryUrlFilter?.subType == 'publicSearch') {
      this.dataSharingService.quickSearch.next('');
      this.router.navigate(['/login/log-in']);
    } else {
      const url = '/customs/' + this.publicShareEnrcyptedData;
      this.router.navigate(['/login/log-in'], {
        queryParams: { returnUrl: url },
        skipLocationChange: true,
      });
    }
  }

  errorMessage() {
    if (!this.currentUserData) {
      this.isShowMessage = true;
      this.showMessage =
        'To see full customs details, please login by clicking here.';
    }
    setTimeout(() => {
      if (this.currentUserData && !this.selectedCustom?.isFullViewAccess) {
        this.showToastMessage = true;
        this.message = 'You are viewing a shared customs with limited access.';
      }
    }, 0);
  }

  share(customsId: any, type: any) {
    this.popupToggleDetail = true;
    let accountId = '';
    let aliasName = '';
    let isGroup = false;
    let subType = '';
    let isParent = '';
    if (this.publicShareEnrcyptedData) {
      var decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      subType = decryUrlFilter.subType;
    }
    if (this.currentAccountData) {
      if (decryUrlFilter) {
        if (
          !decryUrlFilter?.isFullViewAccess &&
          decryUrlFilter.type !== 'viewAll'
        ) {
          accountId = decryUrlFilter.accountDetail.oH_Code;
          aliasName = decryUrlFilter.accountDetail.aliasName;
          isGroup = decryUrlFilter.accountDetail.byGroup;
          isParent = decryUrlFilter.accountDetail.isControllingCustomer;
        } else {
          accountId = this.currentAccountData.accountId;
          aliasName = this.currentAccountData.accountAliasName;
          isGroup = this.currentAccountData.isGroup;
          isParent = this.currentAccountData.isParent;
        }
      } else {
        accountId = this.currentAccountData.accountId;
        aliasName = this.currentAccountData.accountAliasName;
        isGroup = this.currentAccountData.isGroup;
        isParent = this.currentAccountData.isParent;
      }
    } else {
      accountId = decryUrlFilter?.accountDetail?.oH_Code;
      aliasName = decryUrlFilter?.accountDetail?.aliasName;
      isGroup = decryUrlFilter?.accountDetail?.byGroup;
      isParent = decryUrlFilter?.accountDetail?.isControllingCustomer;
    }
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailPageRefreshed.toString(),
      {
        [AI_CustomProps.ShipmentId]: customsId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
        [AI_CustomProps.AccountName]: aliasName,
        [AI_CustomProps.IsGroup]: isGroup ? 'True' : 'False',
        [AI_CustomProps.EncryptedId]: this.selectedCustom?.encryptedId,
      }
    );
    this.prepareUrlShare.emit({
      type: type,
      customsId: customsId,
      accountId: accountId ? accountId : '',
      aliasName: aliasName ? aliasName : '',
      isGroup: isGroup ? isGroup : false,
      popUp: true,
      subType: subType,
      isParent: isParent ? isParent : false,
      shipment: { encryptedId: this.selectedCustom?.encryptedId },
    });
  }

  refreshCustomsDetails() {
    if (this.currentAccountData && this.selectedCustom?.isFullViewAccess) {
      this.getLogsCount();
    }
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailPageRefreshed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
    this.refreshCustomsDetailsFromHeader.emit({
      shipmentId: this.selectedCustom?.forwarder_reference,
      access: this.selectedCustom?.isFullViewAccess,
    });
  }

  detailShare(event: any) {
    this.prepareUrlShare.emit(event);
  }

  sharePopUpfeatureHide() {
    this.popupToggleDetail = false;
  }

  setCurrentTab(tabIndex: number = 1) {
    switch (tabIndex) {
      case 2:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.DocumentTab
        );
        break;
      case 3:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.ActionTab
        );
        break;
      case 6:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.LogsTab
        );
        break;
      case 7:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.Invoice
        );
        break;
      case 8:
        this.shipmentService.activeShipmentDetailTab.next(DetailTabName.PGA);
        break;
      default:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.DetailTab
        );
        break;
    }
  }
  loadActionFieldsDetails() {
    if (this.customService.openActionTab) {
      this.openActionField();
    }
  }

  openActionField() {
    this.isProgressShow = true;
    this.appInsightsService.logEvent(
      AI_CommonEvents.ActionTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
    setTimeout(() => {
      $('#action-tab').tab('show');
      this.isProgressShow = false;
    }, 0);
  }

  openDocumentTab() {
    this.isProgressShow = true;
    this.appInsightsService.logEvent(
      AI_CommonEvents.DocumentTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
    setTimeout(() => {
      $('#profile-tab').tab('show');
      this.isProgressShow = false;
    }, 0);
  }
}
