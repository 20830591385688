import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'exceptionClass',
})
export class ExceptionClassPipe implements PipeTransform {
  transform(status: any, exception: any, module?:string): string {
    return CommonUtils.getExceptionClass(status, exception, module);
  }
}
