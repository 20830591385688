import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sealdata'
})
export class SealdataPipe implements PipeTransform {

  transform(container: any): unknown {
    let message = '';
    if (container.seal_1) message += container.seal_1.trim() + ', ';
    if (container.seal_2) message += container.seal_2.trim() + ', ';
    if (container.seal_3) message += container.seal_3.trim();
    if (message.trim().length <= 0) {
      message = '---';
    }
    let index = message.trim().lastIndexOf(',');
    if (index > 0) {
      message = message.substring(0, index) + message.substring(index + 1);
    }
    return message
  }

}
