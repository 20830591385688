import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActionFieldService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public stopRequest: Subject<void> = new Subject<void>();
  public closeActionFieldForm: Subject<boolean> = new Subject<boolean>();
  public openActionFieldForm: Subject<boolean> = new Subject<boolean>();
  public isProgress: Subject<boolean> = new Subject<boolean>();
  public isCopied: Subject<any> = new Subject<any>();
  public formFieldChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public formFieldChangesPopup: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public closeWindow = new EventEmitter<boolean>();
  public dropDownControlValues: any;
  public dropDownFormValid: boolean;
  public selectedActionFieldItem: any;
  public sectionBasedItem: any;
  public sectionBasedItemAtCargoWise: any;
  public formChanged: boolean = false;
  public duplicateErrorMsg: any = "";
  public lastHighlightedRow:any="";

  constructor(private http: HttpClient) { }

  closePopUpWindow() {
    this.closeWindow.emit(true);
  }

  getActionFieldList(params: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'ActionField/GetActionFields',
        params,
        this.httpOptions
      )
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getSectionDetailsByOrgCode(orgCode: string, moduleId: string, moduleName: string, isGroup: boolean): Observable<any> {
    let param = "?orgCode=" + orgCode + "&shipmentId=" + moduleId + "&module=" + moduleName + "&isGroup=" + isGroup;
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'ActionField/getSectionDetailsByOrgCode' + param,
        this.httpOptions
      )
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }


  getAllAccounts(searchObj: any, offset: any): Observable<any> {
    let request = {
      offset: offset,
      fetch: 30,
      search_text: searchObj,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<any>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getControlType() {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url + 'ActionField/GetControlTypes',
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getParentActionField(accounts: any, actionField?: any) {

    let url = '';
    if (actionField) {
      url =
        environment.base_api_desktop_url +
        'ActionField/GetParentActionFields?actionField=' +
        actionField;
    } else {
      url =
        environment.base_api_desktop_url + 'ActionField/GetParentActionFields';
    }
    let params = {
      offset: 0,
      fetch: 1000,
      search_text: '',
      isGlobalSearch: true,
      "parameters": [
        {
          "name": "accounts",
          "value": accounts
        }
      ],
      sort_options: [],
      filter_options: [],

    };
    return this.http.post<any[]>(url, params, this.httpOptions).pipe(catchError(this.handelError));
  }

  getDataTypes(controlType: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'ActionField/GetDataTypes?controlType=' +
        controlType,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  createActionField(params: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'ActionField/Create',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  updateActionField(params: any): Observable<any> {
    return this.http
      .put<any>(
        environment.base_api_desktop_url + 'ActionField/Update',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getActionFieldById(id: any): Observable<any> {
    let params = {};
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'ActionField/GetActionFieldById?id=' +
        id,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getAccountListByActionField(
    actionFieldId: any,
    params: any
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'ActionField/GetAccountListByActionField?id=' +
        actionFieldId,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  removeFromAllAccounts(actionFieldId: any, params: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'ActionField/RemoveFromAllAccounts?actionFieldId=' +
        actionFieldId,
        options
      )
      .pipe(catchError(this.handelError));
  }

  removeFromAccountConfiguration(
    actionFieldId: any,
    orgCode: any,
    sectionName: any,
    params: any
  ) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };
    actionFieldId = actionFieldId ? actionFieldId : '';
    orgCode = orgCode ? orgCode : '';
    sectionName = sectionName ? sectionName : '';
    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'ActionField/RemoveFromAccountConfiguration?actionFieldId=' +
        actionFieldId +
        '&orgCode=' +
        orgCode +
        '&sectionName=' +
        sectionName,
        options
      )
      .pipe(catchError(this.handelError));
  }

  deleteActionField(params: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };
    return this.http
      .delete<any>(
        environment.base_api_desktop_url + 'ActionField/Delete',
        options
      )
      .pipe(catchError(this.handelError));
  }

  saveActionFieldConfiguration(params: any, orgCode: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'ActionField/ConfigureSections?orgCode=' +
        orgCode,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  captureActionFieldConfiguration(params: any, shipmentId: any, module: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        '/ActionField/CaptureActionFieldData?shipmentId=' +
        shipmentId + "&module=" + module,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
}
