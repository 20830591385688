<div class="oia-container">
  <app-progressbar [hidden]="!isProgressShow"></app-progressbar>
  <app-common-default-filter *ngIf="currentUserData && !isDetailFullView" [ngClass]="{
      'guided-back-drop': isDefaultSecHide,
      'guided-visible-sec': isDefaultSecShow
    }" id="defaultFIlter" [defaultFilterSelectedValue]="defaultFilterSelectedValue"
    (onDefaultFilterSelectedHandler)="onDefaultFilterSelectedHandler($event)"
    (guidedTourStartManually)="guidedTourStartManually($event)" (userFilterFetch)="userFilterFetch($event)"
    (systemFilterFetch)="systemFilterFetch($event)" [savedFilters]="savedFiltersList"
    [systemFilters]="systemFiltersList" [savedUserFilterRowId]="savedFilterRow"
    [defaultFiltersList]="shipmentDefaultFiltersList" [currentPage]="'Shipments'"
    [systemFilterDefault]="systemFilterDefault" [savedSystemFilterRowId]="systemFilterRow">
  </app-common-default-filter>
  <div class="oia-content-wrap adjest-top-set">
    <div class="oia-inner-wrap" style="margin-top: 170px;">
      <app-sucess-toast *ngIf="isShowMessageDel" [message]="showMessageDel" (triggerToastClose)="successToastClose()"
        (undoDelete)="undoDeleteUserFilter($event)" class="my-profile-toast set-alignment-issue"></app-sucess-toast>
      <app-success-toast *ngIf="isShowMessage" [message]="showMessage" class="my-profile-toast set-alignment-issue"
        (triggerToastClose)="successToastClose()"></app-success-toast>
      <app-shipment-custom-filters [ngClass]="{ 'guided-visible-sec': isCustomFilterShow }"
        *ngIf="currentUserData && !isDetailFullView" id="customFIlter" [shipmentSearchText]="shipmentSearchText"
        (onShipmentSearchHandler)="onShipmentSearchHandler($event)" [sortOptions]="sortOptions"
        [defaultSortSelectedValue]="defaultSortSelectedValue" [defaultSortDirection]="defaultSortDirection"
        (onSortSelectedHandler)="onSortSelectedHandler($event)" (selectedFilterArray)="onSelectedCustomFilter($event)"
        (downloadExcel)="prepareExcelDownload($event)" (selectedMainFilterArray)="getMainFilterSelection($event)"
        (selectedDateRange)="onSelectedCustomFilter($event)" (locFilter)="onSelectedCustomFilter($event)"
        [urlShare]="url" (prepareUrlShare)="prepareShareUrl($event)" (selectedUrlFilters)="getAllURLFilter($event)"
        [resentList]="resentList" (clearSearchFilter)="clearSearchFilter($event)"
        (clearAllFilterValue)="clearAllFilterValue($event)" [guidedTourFilter]="guidedTourShipmentFilter"
        [guidedTourSort]="guidedTourShipmentSort" [resultCount]="totalShipmentCount" [paramCheck]="filterCheck"
        [saveFilterJson]="saveFilterJson" (onUserFilterSaved)="getUserFilters($event)"
        (onSystemFilterSaved)="getSystemFilters($event)" [tempFilterDiff]="filterCheckTemp"
        [isfilterEdit]="myFilterEdit" (isLoading)="customFilterApiLoader($event)" [savedFilters]="savedFiltersList"
        (userFilterFetch)="userFilterFetch($event)" [selectedDateRangeRender]="selectedDateRange"
        (selectedDefualtUrlFilters)="setUrlDefaultFilter($event)" (clearMysavedFilter)="clearMySavedSystem()"
        (isTableViewSelected)="setStandardOrTableView($event)"
        (pickUpAndDeliveryToggle)="setPickUpAndDeliveryToggle($event)"
        (setToDefault)="onDefaultFilterSelectedHandler($event)" (showAndAutoClearToasterMessage)="
          showAndAutoClearToasterMessage($event)
        " (callSwitchFilterWarningPopup)="callFilterWarningPopup($event)"
        [tableViewColumnHeaderList]="tableViewColumnHeaderList" (checkDefultFilter)="checkForDefaultOption($event)">
      </app-shipment-custom-filters>

      <app-shipment-list id="shipmentListSelector" *ngIf="currentUserData && !isDetailFullView"
        [shipmentData]="shipmentData" [totalShipmentCount]="totalShipmentCount" (emitOffset)="getListingOffset($event)"
        [urlShipmentId]="urlShipmentId" [urlShare]="url" (prepareUrlShare)="prepareShareUrl($event)"
        [isShowToggle]="true" [offset]="page" [loadingInProgress]="isProgressShow"
        [guidedTourContext]="guidedTourShipmentContext" [guidedTourDetail]="guidedTourShipmentDetail"
        [publicShareFilter]="publicShareFilter" [publicShareData]="publicShareData"
        [publicShareVisibility]="publicShareVisibility" [publicShareType]="publicShareType"
        [publicShareEnrcyptedData]="publicShareEnrcyptedData" [isInfiniteDisabled]="isInfiniteDisabled"
        [isTableView]="isTableView" [isPickupAndDelivery]="isPickupAndDelivery"
        (callLoadShipmentList)="onSelectedCustomFilter($event)" (callFilterNavigator)="navigateToSelectedFilter()"
        (callDenyProceedingFilter)="denyProceedingFilterOption()" [isCustomFilterAdded]="isCustomFilterAdded">
      </app-shipment-list>
      <app-shipment-detail *ngIf="selectedShipmentLen > 0 && isDetailFullView" [urlShare]="url"
        [urlShipmentId]="urlShipmentId" (prepareUrlShare)="detailShare($event)"
        (onRefreshDetails)="onRefreshDetailFromDetail($event)" [selectedShipment]="getShipmentDetails"
        [popupToggle]="popupToggle" [publicShareVisibility]="publicShareVisibility" [publicShareType]="publicShareType"
        [publicShareEnrcyptedData]="publicShareEnrcyptedData">
      </app-shipment-detail>
    </div>
  </div>
  <div class="outer-close" *ngIf="guidedFeatureManualModalShow">
    <div class="outer-close-set" (click)="gudideTourCloseset()"></div>
    <div class="rap-set need-help" *ngIf="guidedFeatureManualModalShow">
      <div class="rap-header">
        <h2>Need help?</h2>
        <p>
          Use this guided tour to get a walkthrough of the shipments page or
          visit the FAQ page <a (click)="navigateToFaq()">here</a>.
        </p>
        <div class="rap-footer">
          <div class="left-sec"></div>
          <div class="right-sec">
            <div class="text-one">Start Guided Tour</div>
            <img src="../../../../../assets/img/yellow-back.png" alt="guide-tour" (click)="gudideTourStart()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rap-set" id="rapModal" *ngIf="guidedFeatureModalShow">
    <div class="rap-header">
      <h2>{{ guidedTourPopupHeader }}</h2>
      <p>{{ guidedTourPopupPara }}</p>
      <div class="rap-footer">
        <div class="left-sec" *ngIf="totalShipmentCount > 0">
          {{ guidedTourFlag }}/9
        </div>
        <div class="left-sec" *ngIf="totalShipmentCount < 1">
          {{ guidedTourFlagNoShipment }}/6
        </div>
        <div class="right-sec">
          <img src="../../../../../assets/img/arrow-guide.png" *ngIf="
              (totalShipmentCount < 1 && guidedTourFlag > 3) ||
              (totalShipmentCount > 0 && guidedTourFlag > 1)
            " alt="guided tour previous" (click)="previousFeature()" class="roate-sec" />
          <img src="../../../../../assets/img/arrow-guide.png" alt="guided tour next" (click)="nextFeature()" />
        </div>
      </div>
    </div>
    <div class="static-menu-shipment">
      <a class="dropdown-item">Watchlist</a>
      <a class="dropdown-item">Share</a>
      <a class="dropdown-item" *ngIf="currentUserData.userType == 'systemadmin'">Update Shipment</a>
      <a class="dropdown-item" *ngIf="currentUserData.userType == 'systemadmin'">Update with Documents</a>
    </div>
    <div class="static-menu-shipment sort-by">
      <ul class="custom-drop-menu m-0">
        <li class="dropdown-item" *ngFor="let sortitems of sortBy" [ngClass]="sortitems.item == 'ATD' ? 'active' : ''">
          <img *ngIf="sortitems.item == 'ATD'" alt="desc"
            src="../../../../../assets/img/shipments/shipment-sort-icon.svg" class="mr-1" />{{ sortitems.item }}
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="guidedTourPopup" class="user-guide-list">
  <div class="user-card guide-tour">
    <div class="pt-3 pb-3 text-center">
      <img src="assets/img/oia-logo@3x.png" class="img-fluid" alt="logo" />
    </div>

    <p class="update-font pt-2 head-sec">
      Welcome to a whole new world of supply chain visibility!
    </p>
    <p>
      We are pleased to welcome you to a whole new world of supply chain
      visibility, track & trace, and business intelligence with OIA Connect.
    </p>

    <p class="pb-3">
      To help get you onboard, we have created a guided tour that will highlight
      the features we think will be of most value to you!
    </p>
    <button class="btn btn-primary yellow-button w-100 btn-lg mb-3 text-uppercase" (click)="startGuidedTour('first')">
      START GUIDED TOUR
    </button>
    <p class="text-center">
      <a (click)="skipTour()" class="link">SKIP AND CONTINUE</a>
    </p>

    <div>
      <img src="assets/img/login/delivery-5-Converted@2x.png" class="img-fluid" alt="" />
    </div>
  </div>
</div>

<div id="pop-up3" *ngIf="popupToggleList" class="dimScreen">
  <div *ngIf="popupToggleList" class="add-user-popup">
    <button class="close" type="button" (click)="sharePopUpfeatureHide()">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
    </button>
    <div class="body-section fix-size">
      <h3>Share Shipment</h3>
      <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" id="tabDetail">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
            role="tab" aria-controls="home" aria-selected="true">
            <img src="../../../../../assets/img/share/url-img.svg" alt="" />
            URL
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
          aria-labelledby="home-tab">
          <div class="discription">
            <p class="text-des" *ngIf="detailShipmentShare">
              For users with a Connect account and access to this company, they
              will be able to see the full shipment details. Otherwise, they
              will be able to see a public URL with only shipment, container
              progress and customs information.
            </p>
            <div class="copy-section">
              <input type="text" id="shareUrl" [value]="url" readonly />
              <button class="add-type" (click)="copyLink()">COPY LINK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>