import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationPreferenceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public stopRequest: Subject<void> = new Subject<void>();
  lookup_params = {
    offset: 0,
    fetch: 1000,
    search_text: '',
    isGlobalSearch: true,
    parameters: [],
    sort_options: [],
    filter_options: [],
  };
  public eventBus: [
    {
      workFlowId: string;
      data: {
        description: string;
        accounts: any[];
        customSchedule: any;
        filterOptions: any;
        filterGuidNo: any;
        eDocTypes: any[];
        includedAttachments: boolean;
      }[];
    }
  ] = [
      {
        workFlowId: '',
        data: [
          {
            description: '',
            accounts: [],
            customSchedule: {},
            filterOptions: {},
            filterGuidNo: '',
            eDocTypes: [],
            includedAttachments: false
          },
        ],
      },
    ];
  public selectedItem: any;
  public hasValueChangedParam = {
    accounts: false,
    frequency: false,
    pickUPDateRange: false,
    originPortDateRange: false,
    destinationPortDateRange: false,
    deliveryDateRange: false,
    filterOptions: false,
  };
  public hasValueChanged = new BehaviorSubject(this.hasValueChangedParam);

  constructor(private http: HttpClient) { }

  getWorkflowListByUserId(userEmailId: string, params: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/GetWorkFlow?userEmail=' +
        userEmailId,
        params,
        this.httpOptions
      )
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getTransportTypeList() {
    return this.http
      .get<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/GetTransportTypes',
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getContainerTypesList() {
    return this.http
      .get<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/GetContainerTypes',
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  subscribeWorkflow(
    notificationPreferenceId: any,
    params: any
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/Subscribe?notificationPreferenceId=' +
        notificationPreferenceId,
        params,
        this.httpOptions
      )
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  unsubscribeWorkflow(
    notificationPreferenceId: any,
    params: any
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/UnSubscribe?notificationPreferenceId=' +
        notificationPreferenceId,
        options
      )
      .pipe(catchError(this.handelError));
  }

  createWorkflowNotificationPreference(params: any) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'NotificationPreference/Create',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  updatWorkflowNotificationPreference(params: any) {
    return this.http
      .put<any[]>(
        environment.base_api_desktop_url + 'NotificationPreference/Update',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getFrequencyList() {
    return this.getDropDownList('Frequency');
  }

  getWeekDaysList() {
    return this.getDropDownList('Days');
  }

  getDropDownList(dropDownFilterTypeName: string): Observable<any> {
    let params = {
      offset: 0,
      fetch: 1000,
      search_text: '',
      isGlobalSearch: true,
      parameters: [
        {
          name: 'string',
          value: dropDownFilterTypeName,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'GetDropDownList',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getAccountOrgDetail(
    userEmailId: string,
    isBooking: boolean,
    isCustoms: boolean,
    accounts: any,
    type: string,
    search: string
  ) {
    let params = {
      offset: 0,
      fetch: 1000,
      search_text: search,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'accounts',
          value: accounts,
        },
        {
          name: 'source',
          value: type,
        },
      ],
      sort_options: [
        {
          column_name: 'rL_PortName',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/Unlococodes?userEmail=' +
        userEmailId +
        '&isBooking=' +
        isBooking +
        '&isCustoms=' +
        isCustoms,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getAccountDetail(accounts: any) {
    let params = {
      offset: 0,
      fetch: 1000,
      parameters: [
        {
          name: "accounts",
          value: accounts.join(",")
        }
      ],
      sort_options: [
        {
          column_name: "aliasName",
          direction: "asc"
        }
      ]
    }
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'NotificationPreference/GetAccounts',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getNotificationPreferenceById(
    notificationPreferenceId: string,
    params: any
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'NotificationPreference/GetNotificationPreferenceById?id=' +
        notificationPreferenceId,
        params,
        this.httpOptions
      )
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
}
