import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trasportModeByFileType',
})
export class TrasportModeByFileTypePipe implements PipeTransform {
  transform(data: any): any {
    let transportTypeFile = '';
    if (!this.checkSFile(data?.forwarder_reference)) {
      transportTypeFile = data?.transport_mode?.toUpperCase();
    } else {
      transportTypeFile =
        data.customs_declarations[0]?.transport_mode?.toUpperCase();
    }
    if (transportTypeFile === 'AIR') {
      return 'AIR';
    } else if (transportTypeFile === 'SEA') {
      return 'SEA';
    } else if (
      transportTypeFile === 'ROAD' ||
      transportTypeFile === 'ROA' ||
      transportTypeFile === 'TRK'
    ) {
      return 'ROAD';
    } else if (transportTypeFile === 'RAIL' || transportTypeFile === 'RAI') {
      return 'RAIL';
    }
    return '';
  }

  checkSFile(id: any) {
    return id.charAt(0).toLowerCase() === 's';
  }
}
