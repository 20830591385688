import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'customExceptionClass'
})
export class CustomExceptionClassPipe implements PipeTransform {

  transform(status: any, exception: any): string {
    return CommonUtils.getCustomExceptionClass(status, exception);
  }

}
