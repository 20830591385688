import {
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentCustomFiltersComponent } from '../../components/shipment-custom-filters/shipment-custom-filters.component';
import { environment } from 'src/environments/environment';
import { ShipmentServies } from '../../services/shipment.service';
import { CommonUtils } from 'src/app/common/utils';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { FilterService } from 'src/app/features/header/services/filter.service';
import { SwitchAccountService } from 'src/app/shared/services/switch-account.service';
import { Location } from '@angular/common';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { UserManagmentService } from 'src/app/features/admin/users/services/usermanagment.service';
import {
  AppInsightsService,
  AI_PageName,
  AI_ModulesName,
} from 'src/app/services/appinsights.service';
import { MyGroupService } from 'src/app/features/profile/my-groups/services/my-group.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ShipmentListComponent } from '../../components/shipment-list/shipment-list.component';
import { OC3Constants } from 'src/app/common/oc3-constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss'],
})
export class ShipmentComponent implements OnInit, OnDestroy {
  shipmentData: any[] = [];
  totalShipmentCount: number = 0;
  shipmentSearchText: string = '';
  hasPermission: boolean = true;
  errorMessage: string = '';
  shipmentDefaultFilter: any = [];
  defaultFilterSelectedValue: any = 0;
  defaultFilterSelectedText: string = 'all shipments';
  statusFilterSelectedArray: any = [];
  originFilterSelectedArray = [];
  destinationFilterSelectedArray = [];
  shipperFilterSelectedArray = [];
  consigneeFilterSelectedArray = [];
  ControllingCustomerFilterSelectedArray = [];
  pickupFilterSelectedArray = [];
  finalDeliveryFilterSelectedArray = [];
  transportTypeFilterSelectedArray = [];
  containerTypeFilterSelectedArray = [];
  exceptionTypeFilterSelectedArray = [];
  dateRangeFilterSelectedArray = [];
  mainFilterSelectedArray: any[] = [];
  combinedSelectedFiltersArray: any[] = [];
  selectedOriginAccount: any = [];
  selectedDestinationAccount: any = [];
  selectedShipperAccount: any = [];
  selectedConsigneeAccount: any = [];
  selectedControllingCustomerAccount: any = [];
  selectedPickupAccount: any = [];
  selectedfinalDeliveryAccount: any = [];
  watchListFilterSelectedArray: any = [];
  selectedDateRange = [];
  sortOptions: any = [];
  defaultSortSelectedValue: number = 6;
  defaultSortSelectedColumn: string = 'first_leg_departure_actual';
  defaultSortDirection: string = 'desc';
  defaultFilterArray = [];
  shipmentProgressBar: any = [];
  popupToggle = false;
  isShow = true;
  shipmentlist: any;
  isProgressShow: boolean = false;
  url = '';
  saveFilterJson: any = '';
  page = 0;
  limit = OC3Constants.SHIPPING_LIST_DEFAULT_COUNT; // // limit has been set from 10 to 50 - 20/aug. reverting back to 10 22/aug
  urlShipmentId = '';
  resentList: any = [];
  isUserHaveAcc: any;
  filterCheck: any;
  filterCheckTemp: any;
  originCountryFilterSelectedArray = [];
  destinationCountryFilterSelectedArray = [];
  selectedOriginCountryAccount: any = [];
  selectedDestinationCountryAccount: any = [];
  @ViewChild(ShipmentCustomFiltersComponent)
  customFilterComponent!: ShipmentCustomFiltersComponent;
  @ViewChild(ShipmentListComponent)
  ShipmentListComponent!: ShipmentListComponent;
  currentUserData: any;
  currentAccountData: any;
  guidedTourPopup: boolean = false;
  guidedTourFlag: any;
  guidedTourFlagNoShipment: any;
  guidedTourShipmentContext = false;
  guidedTourShipmentFilter = false;
  guidedTourShipmentDetail: any = '';
  guidedTourShipmentSort = false;
  guidedFeatureModalShow = false;
  guidedFeatureManualModalShow = false;
  guidedTourPopupHeader: any;
  guidedTourPopupPara: any;
  savedFiltersList: any[] = [];
  systemFiltersList: any[] = [];
  publicShareEnrcyptedData: any = '';
  systemFilterDefault: any = {};
  captureFilterName: any = [];
  isCustomFilterAdded: boolean = false;
  hasDefaultFilterDefined: boolean = false;
  unSubscribe$ = new Subject();
  sortBy = [
    { item: 'Shipments #' },
    { item: 'Status' },
    { item: 'Est. Pickup' },
    { item: 'Actual Pickup' },
    { item: 'ETD' },
    { item: 'ATD' },
    { item: 'ETA' },
    { item: 'ATA' },
    { item: 'Est. Delivery' },
    { item: 'Actual Delivery' },
  ];
  guidedTourJson = [
    {
      heading: 'Shipment List',
      para: 'View all your shipments here with the Shipment Number, Status and an overview with a progress bar.',
    },
    {
      heading: 'Action Menu',
      para: 'Use the action menu by clicking on these 3 dots to add a Shipment to your Watchlist or to share the shipment.',
    },
    {
      heading: 'Quick Filters',
      para: "Refine your shipment list using these default filters based on your shipments' status to make browsing easier.",
    },
    {
      heading: 'Filters',
      para: 'Still need to filter your shipment list further? Use any of these filter options to discover the shipments you need to find. After adding your filters, you can save it to create your own custom quick filters.',
    },
    {
      heading: 'Quick Search',
      para: 'Need to find a shipment? Use this search bar with either the shipment number, container number, reference number to refine the shipment list. Place commas between numbers to search for multiple shipments at a time.',
    },
    {
      heading: 'Global Search',
      para: 'If you need a broader search, use the global search in the header.',
    },
    {
      heading: 'Shipment Sort',
      para: 'Sort the shipment list in ascending or descending order based on these criteria. Click the same sort type again to alternate the sort order.',
    },
    {
      heading: 'Download And Share',
      para: 'Share the shipment list with the current filters applied to your list or download the list to an excel file.',
    },
    {
      heading: 'Shipment Details',
      para: 'Click on a shipment in the shipment list screen to view the details, documents, individual container progress and customs information.',
    },
  ];
  isDefaultSecShow: boolean;
  isDefaultSecHide: boolean;
  myFilterEdit: any = [];
  isShowMessage = false;
  showMessage: any = '';
  isShowMessageDel = false;
  showMessageDel: any = '';
  savedFilterRow = '';
  systemFilterRow: any = '';
  publicShareFilter: any = '';
  publicShareData: any = '';
  publicShareVisibility: any = '';
  publicShareType: any = '';
  isGroupShare: any = '';
  isDetailFullView: boolean = false;
  getShipmentDetails: any = [];
  selectedShipmentLen: number = 0;
  private titleService: Title;
  private shipmentService: ShipmentServies;
  private activatedRoute: ActivatedRoute;
  private currentSessionUserData: CurrentSessionDataService;
  private filterService: FilterService;
  private switchAccount: SwitchAccountService;
  private location: Location;
  private router: Router;
  private dataSharingService: DataSharingService;
  private userService: UserManagmentService;
  private appInsightsService: AppInsightsService;
  private myGroupService: MyGroupService;
  private fileUploadService: FileUploadService;
  private breakpointObserver: BreakpointObserver;
  singleShipmentShare = false;
  detailShipmentShare = false;
  popupToggleList = false;
  edocUploadTigger = false;
  copyText: any = '';
  apiCall: any = null;
  isCustomFilterShow = false;
  isTableView: boolean = false;
  isPickupAndDelivery: boolean = false;
  advancedFilterValue: any = [];
  parameters: any = [];
  tableViewColumnHeaderList: any = [];

  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  isInfiniteDisabled = false;
  shipmentDefaultFiltersList = [
    {
      name: 'All Shipments',
      value: 0,
    },
    {
      name: 'Pickup Scheduled',
      value: 1,
    },
    {
      name: 'Picked Up',
      value: 2,
    },
    {
      name: 'Scheduled',
      value: 3,
    },
    {
      name: 'In Transit',
      value: 4,
    },
    {
      name: 'Arrived',
      value: 5,
    },
    {
      name: 'Delivery Scheduled',
      value: 6,
    },
    {
      name: 'Delivered',
      value: 7,
    },
  ];
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
  }

  constructor(private injector: Injector) {
    this.titleService = injector.get<Title>(Title);
    this.shipmentService = injector.get<ShipmentServies>(ShipmentServies);
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.currentSessionUserData = injector.get<CurrentSessionDataService>(
      CurrentSessionDataService
    );
    this.filterService = injector.get<FilterService>(FilterService);
    this.switchAccount =
      injector.get<SwitchAccountService>(SwitchAccountService);
    this.location = injector.get<Location>(Location);
    this.router = injector.get<Router>(Router);
    this.dataSharingService =
      injector.get<DataSharingService>(DataSharingService);
    this.userService = injector.get<UserManagmentService>(UserManagmentService);
    this.appInsightsService =
      injector.get<AppInsightsService>(AppInsightsService);
    this.myGroupService = injector.get<MyGroupService>(MyGroupService);
    this.fileUploadService = injector.get<FileUploadService>(FileUploadService);
    this.breakpointObserver =
      injector.get<BreakpointObserver>(BreakpointObserver);
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();

    this.showPageTitle();
    this.dataSharingService.isGuidedTourDefaultFilterHide.subscribe((value) => {
      this.isDefaultSecHide = value;
    });
    this.dataSharingService.isGuidedTourDefaultFilterShow.subscribe((value) => {
      this.isDefaultSecShow = value;
    });
    this.fileUploadService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        let fileUploadPercentage: any[] = [];
        let cancelledDocs: any[] = [];
        setTimeout(() => {
          fileUploadPercentage = res;
          fileUploadPercentage.some((el: any) => {
            if (el.Percentage < 100 && !el.Error) {
              cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
            }
          });
          if (cancelledDocs.length > 0) {
            this.isDefaultSecShow = true;
            this.isCustomFilterShow = true;
          } else {
            this.isDefaultSecShow = false;
            this.isCustomFilterShow = false;
          }
        }, 0);
      }
    });
    this.breakpointObserver
      .observe(['(min-height: 1100px)'])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.limit = OC3Constants.SHIPPING_LIST_DEFAULT_COUNT + 10;
        } else {
          this.limit = OC3Constants.SHIPPING_LIST_DEFAULT_COUNT; // limit has been set from 10 to 50 - 20/aug. reverting back to 10 22/aug
        }
      });
  }

  ngOnInit(): void {
    let isPublicView = false;
    this.isMobileView = this.scrWidth <= 1200 ? true : false;
    this.clearData();
    this.showPageTitle();
    this.getFilters();
    let [encrypFilterDashboard, encryptedFilter] = this.getRouteParams();
    this.checkActivatedRouteParam(encryptedFilter, encrypFilterDashboard, isPublicView);
    this.hasDetailFullView();
    this.hasCurrentAccountData(encryptedFilter);
    this.loadAllSortOptions();
    this.trackAIPageView(isPublicView);
    this.setOiaConnectWrapStyle();
    this.shipmentService.isFullScreenTabEnabled = this.isDetailFullView;
  }

  getFilters() {
    if (this.currentUserData) {
      this.getSystemFilterDefault();
      this.getUserFilters({ message: '', type: 'start' });
      setTimeout(() => {
        this.getSystemFilters({ message: '', type: 'start' });
      }, 0);
    }
  }

  getRouteParams() {
    return [this.activatedRoute.snapshot.queryParams['filterParam'],
    this.activatedRoute.snapshot.params['filterParam']];
  }

  hasDetailFullView() {
    if (this.isDetailFullView) {
      this.fileUploadService.reloadShipment.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
        if (value.reload) {
          this.selectedListShipment(value.shipmentId);
        }
      });
    }
  }

  hasCurrentAccountData(encryptedFilter: any) {
    if (this.currentAccountData) {
      if (!this.currentAccountData.shipmentIsVisible && encryptedFilter) {
        let decryUrlFilter = JSON.parse(atob(encryptedFilter));
        if (decryUrlFilter.type == 'Detail') {
          this.router.navigate(['/dashboard/' + encryptedFilter]);
        }
      } else if (
        !this.currentAccountData.shipmentIsVisible &&
        !encryptedFilter
      ) {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  checkActivatedRouteParam(encryptedFilter: any, encrypFilterDashboard: any, isPublicView: boolean) {
    let isInitialLoad = true;
    if (encryptedFilter) {
      this.encryptData(encryptedFilter);
    } else if (encrypFilterDashboard) {
      this.encryptData(encrypFilterDashboard);
    } else if (!encryptedFilter || !encrypFilterDashboard) {
      if (this.currentUserData) {
        if (!this.isMobileView) {
          this.getUserDetailGuidedTour();
        }
        this.dataSharingService.quickSearch.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
          if (value) {
            isInitialLoad = false;
            this.globalQuickSearch(value);
          } else if (!value && isInitialLoad) {
            isInitialLoad = false;
            this.loadAllShipments();
          }
        });
      } else {
        this.dataSharingService.quickSearch.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
          if (value.type == 'Detail') {
            isPublicView = true;
            this.publicSearchFn(value);
          } else {
            this.router.navigate(['/login/log-in']);
          }
        });
      }
    }


  }

  setOiaConnectWrapStyle() {
    $(document).ready(function () {
      $('.oia-inner-wrap').css('margin-top', '170px');
      $('#left-slide-icon').click(function () {
        let hidden = $('.filter-nav');
        if (hidden.hasClass('visible')) {
          $('.oia-container').addClass('nav-toggle');
          $('.oia-content-wrap').animate(
            { 'margin-left': '1%', width: '99%', height: '100%' },
            600
          );
        } else {
          $('.oia-container').removeClass('nav-toggle');
          if ($(window).width() <= 1350) {
            $('.oia-content-wrap').animate(
              { 'margin-left': '14%', width: '86%', height: '100%' },
              600
            );
          } else if ($(window).width() <= 1650) {
            $('.oia-content-wrap').animate(
              { 'margin-left': '12%', width: '88%', height: '100%' },
              600
            );
          } else {
            $('.oia-content-wrap').animate(
              { 'margin-left': '10%', width: '90%', height: '100%' },
              600
            );
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    // this.dataSharingService.quickSearch.next(false); // commented due to avoid redudancy in shipment call while component destroying
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  showGuidedTourPopup() {
    this.guidedTourPopup = true;
  }

  hideGuidedTourPopup() {
    this.guidedTourPopup = false;
  }

  setCloseButtonTimeout(closeFromEve: any) {
    setTimeout(() => {
      this.customFilterComponent.closeCustomFilters({
        type: 'statuscustom',
        index: 2,
        id: 1,
        eventType: 'closeBtn',
        closeFrom: closeFromEve == 'default' ? 'defaultFilter' : 'quickSearch',
      });
    }, 100);
  }

  setCloseButtonTimeoutWithoutCallApi() {
    setTimeout(() => {
      this.customFilterComponent.closeCustomFilters({
        type: 'statuscustom',
        index: 2,
        id: 1,
        eventType: 'closeBtn',
        closeFrom: 'clearFilterOnly',
      });
    }, 100);
  }

  setUrlFilters(filters: any, encryptedFilter: any) {
    if (
      this.currentUserData.userType == 'user' &&
      filters.type != 'defaultFilter' &&
      filters.type != 'viewAll'
    ) {
      this.switchAccountUser(filters, encryptedFilter);
    } else if (
      this.currentUserData.userType == 'systemadmin' &&
      filters.type != 'defaultFilter' &&
      filters.type != 'viewAll'
    ) {
      this.switchAccountAdmin(filters, encryptedFilter);
    } else if (filters.type == 'defaultFilter') {
      this.urlFilterCall(filters);
    } else {
      this.urlFilterCall(filters);
    }
  }

  urlFilterCall(filters: any) {
    if (
      filters.type != 'Detail' &&
      filters.type != 'Single' &&
      filters.type != 'All'
    ) {
      setTimeout(() => {
        this.customFilterComponent.isFilterFromGlobalSearch = true;
        this.customFilterComponent.loadURLFilters(filters);
      }, 300);
    }
    this.urlFilterSort(filters);

    if (filters.mainFilter.length < 1 && filters.type == 'defaultFilter') {
      this.urlShipmentId = filters.shipmentId;
      this.shipmentSearchText = filters.shipmentId;
      this.loadAllShipments();
    }
    if (filters.type == 'Detail' && filters.subType != 'publicSearch') {
      this.publicShareFilter = filters;
      let getPatameter = this.prepareApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'shipmentNumber',
            value: filters.shipmentId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultSortSelectedColumn,
            direction: this.defaultSortDirection,
          },
        ],
        filter_options: [],
      };
      this.shipmentService
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.isProgressShow = false;
          if (res.result[0].isFullViewAccess) {
            this.selectedListShipment(filters.shipmentId);
            setTimeout(() => {
              this.popupToggle = true;

              setTimeout(() => {
                this.urlShipmentId = filters.shipmentId;
                this.selectedShipmentLen = Object.keys(
                  this.getShipmentDetails
                ).length;
              }, 0);
            }, 0);
          } else {
            setTimeout(() => {
              this.popupToggle = true;
              this.getShipmentDetails = res.result[0];
              this.selectedShipmentLen = Object.keys(
                this.getShipmentDetails
              ).length;
            }, 0);
            this.urlShipmentId = filters.shipmentId;
          }
        });
    } else if (filters.type == 'Detail' && filters.subType == 'publicSearch') {
      this.publicShareFilter = filters;
      this.shipmentPublicSearchShare('url');
    } else if (filters.type == 'All') {
      this.setGroupUrlFilterCriteria(filters);
    }
  }

  urlFilterSort(filters: any) {
    let len = 0;

    if (filters && filters?.sorting) {
      let keys = Object.keys(filters?.sorting);
      len = keys?.length;
    }

    if (len > 0) {
      this.defaultSortSelectedColumn = filters.sorting
        ? filters.sorting.selectedValue.column_name
        : 'first_leg_departure_actual';
      this.defaultSortDirection = filters.sorting
        ? filters.sorting.selectedValue.direction
        : 'desc';
      this.defaultSortSelectedValue = filters.sorting
        ? filters.sorting.sortId
        : 6;
    }
  }

  switchAccountUser(filters: any, encryptedFilter: any) {
    if (filters.accountDetail?.oH_Code && !filters.accountDetail?.byGroup) {
      this.filterService
        .getData(
          environment.base_api_desktop_url +
          'useraccount?userEmailAddresss=' +
          this.currentUserData.emailAddress
        )
        .subscribe((res) => {
          this.isUserHaveAcc = res.result
            .map(function (x: any) {
              return x.oH_Code;
            })
            .indexOf(filters.accountDetail.oH_Code);
          if (this.isUserHaveAcc >= 0) {
            const isSwitchAccount = this.switchAccount.switchAccount(
              filters.accountDetail,
              'shareUrlShipment',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              if (filters.type == 'All') {
                setTimeout(() => {
                  this.customFilterComponent.loadURLFilters(filters);
                }, 300);
                this.urlFilterSort(filters);
              } else {
                this.urlFilterCall(filters);
              }
            }
          } else {
            if (
              filters.type == 'All' &&
              filters.accountDetail?.isControllingCustomer
            ) {
              this.publicShareLimitedApiUser(filters, encryptedFilter);
            } else if (filters.type == 'Detail') {
              this.urlFilterCall(filters);
            }
          }
        });
    } else if (filters.accountDetail?.byGroup) {
      this.publicShareLimitedApiUser(filters, encryptedFilter);
    } else {
      this.urlFilterCall(filters);
    }
  }

  switchAccountAdmin(filters: any, encryptedFilter: any) {
    if (filters.accountDetail?.oH_Code && !filters.accountDetail?.byGroup) {
      const isSwitchAccount = this.switchAccount.switchAccount(
        filters.accountDetail,
        'shareUrlShipment',
        encryptedFilter
      );
      if (!isSwitchAccount) {
        if (filters.type == 'All') {
          setTimeout(() => {
            this.customFilterComponent.loadURLFilters(filters);
          }, 300);
          this.urlFilterSort(filters);
        } else {
          this.urlFilterCall(filters);
        }
      }
    } else if (filters.accountDetail?.byGroup) {
      this.publicShareLimitedApiAdmin(filters, encryptedFilter);
    } else {
      this.urlFilterCall(filters);
    }
  }

  showPageTitle() {
    CommonUtils.setPageTitle(this.titleService, 'Shipments');
  }

  featureHide() {
    this.isShow = false;
  }

  onDefaultFilterSelectedHandler(event: any) {
    if (this.myFilterEdit?.isEditTrue) {
      this.customFilterComponent.clearAllFilters();
      this.myFilterEdit = {
        savedValue: '',
        isEditTrue: false,
      };
      this.combinedSelectedFiltersArray = [];
      this.statusFilterSelectedArray = [];
      this.transportTypeFilterSelectedArray = [];
      this.containerTypeFilterSelectedArray = [];
      this.exceptionTypeFilterSelectedArray = [];
      this.originFilterSelectedArray = [];
      this.destinationFilterSelectedArray = [];
      this.dateRangeFilterSelectedArray = [];
      this.watchListFilterSelectedArray = [];
      this.mainFilterSelectedArray = [];
      this.shipmentSearchText = '';
      this.defaultFilterArray = [];
      this.shipperFilterSelectedArray = [];
      this.consigneeFilterSelectedArray = [];
      this.ControllingCustomerFilterSelectedArray = [];
      this.finalDeliveryFilterSelectedArray = [];
      this.pickupFilterSelectedArray = [];
      this.defaultFilterSelectedValue = 0;
      this.selectedConsigneeAccount = [];
      this.selectedControllingCustomerAccount = [];
      this.selectedDestinationAccount = [];
      this.selectedDestinationCountryAccount = [];
      this.selectedOriginAccount = [];
      this.selectedOriginCountryAccount = [];
      this.selectedPickupAccount = [];
      this.selectedShipperAccount = [];
      this.selectedfinalDeliveryAccount = [];
    }
    this.defaultFilterSelectedValue = event?.value;
    this.defaultFilterSelectedText = event?.name;
    this.parameters = [];
    this.advancedFilterValue = [];
    //this.ShipmentListComponent.isHeaderColumnSelectedIndex = -1;
    this.customFilterComponent.clearRibonAdvFiltersOnUserDelete();
    this.customFilterComponent.removeAllGlobalFilter();
    this.ShipmentListComponent.searchColumnInput = [];
    this.ShipmentListComponent.selectedColumnSearchParams = [];
    this.ShipmentListComponent.selectedColumnIndexList = [];
    this.ShipmentListComponent.headerDropdownPopupSortDirection = "";
    this.mainFilterSelectedArray = [
      { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 2 },
    ];
    this.shipmentSearchText = '';
    let selectedFilters = {
      mainFilter: [
        { id: 1, filterDisplayName: 'Status', type: 'statuscustom', index: 2 },
      ],
      statusFilter: [
        {
          column_name: 'statuscustom',
          operator: '=',
          value: event.value.toString(),
        },
      ],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: [],
        selectedAccount: [],
      },
      destination: {
        selectedValue: [],
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },
      consignee: {
        selectedValue: [],
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: [] },
          { destinationValue: [] },
          { pickupValue: [] },
          { finalDeliveryValue: [] },
        ],
      },

      sorting: {
        sortId: this.defaultSortSelectedValue,
        selectedValue: {
          column_name: this.defaultSortSelectedColumn,
          direction: this.defaultSortDirection,
        },
      },
      accountId: '',
      shipmentId: '',
      type: 'defaultFilter',
    };
    if (this.statusFilterSelectedArray.length > 0) {
      this.setCloseButtonTimeout('default');
    } else {
      this.setCloseButtonTimeoutWithoutCallApi();
    }
    setTimeout(() => {
      if (event?.value != 0) {
        this.setUrlFilters(selectedFilters, false);
      }
    }, 100);
    setTimeout(() => {
      let test: any[] = [];
      if (event?.value > 0) {
        test.push({
          column_name: 'statuscustom',
          operator: '=',
          value: event?.value.toString(),
        });
      }
      let statusSelect: any = '';
      if (event?.value > 0) {
        statusSelect = { type: 'statuscustom', selectedValue: test };
        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      } else {
        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      }
      this.onSelectedCustomFilter(statusSelect);
    }, 1000);
  }

  onSortSelectedHandler(event: any) {
    this.defaultSortSelectedValue = event.id;
    this.defaultSortSelectedColumn = event.sort_column;
    this.defaultSortDirection = event.sort_direction;
    this.clearData();
    this.loadAllShipments();
  }

  onShipmentSearchHandler(emit: any) {
    this.shipmentSearchText = '';
    this.shipmentSearchText = emit.searchText;
    if (
      emit.event.keyCode == 13 ||
      emit.event.key == 'Enter' ||
      emit.event.type === 'click'
    ) {
      this.clearData();
      this.loadAllShipments();
    }
  }

  loadAllShipments() {
    let getPatameter = this.prepareApiBodyParams();
    if (getPatameter.bodyParam.offset <= 0) {
      this.totalShipmentCount = 0;
      this.isInfiniteDisabled = true;
      setTimeout(() => {
        this.isInfiniteDisabled = false;
      }, 200);
      this.apiCancel();
    }
    this.filterCheck = getPatameter.bodyParam;
    let isGroup: any;
    if (this.isGroupShare != '') {
      isGroup = this.isGroupShare;
    } else {
      isGroup = getPatameter.isGroup;
    }
    this.isProgressShow = true;
    this.apiCall = this.shipmentService
      .getAllShipments(
        getPatameter.accountId,
        getPatameter.emailId,
        getPatameter.bodyParam,
        isGroup
      )
      .subscribe(
        (res) => {
          if (this.isUserHaveAcc < 0) {
            this.totalShipmentCount = 0;
            this.shipmentData = [];
            this.isProgressShow = false;
          } else {
            this.totalShipmentCount = res?.total_count;
            if (res?.result?.includes('does not have persmission')) {
              this.shipmentData = [];
              this.hasPermission = false;
              this.errorMessage =
                'You do not have a permission to access this account. Please select an another account!';
            } else {
              this.shipmentData = [...this.shipmentData, ...res?.result];
              this.hasPermission = true;
              this.errorMessage = '';
            }
            this.isProgressShow = false;
          }
          this.isGroupShare = '';
        },
        (error) => {
          this.isProgressShow = false;
        }
      );
  }

  // TODO: to be refactored
  prepareApiBodyParams() {
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let filterOption = [];
    if (this.combinedSelectedFiltersArray.length > 0) {
      this.combinedSelectedFiltersArray =
        this.combinedSelectedFiltersArray.filter(function (element) {
          return element !== undefined;
        });
      filterOption = this.combinedSelectedFiltersArray.filter(
        (el) => Object.keys(el).length
      );
    }

    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: this.shipmentSearchText,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupId: isGroup ? accountId : '',
      isTableView: this.isTableView,
      isglobalsearch: false, //TODO:
      advanceFilterOptions: this.advancedFilterValue,
      parameters: this.parameters,
      sort_options: [
        {
          column_name: this.defaultSortSelectedColumn,
          direction: this.defaultSortDirection,
        },
      ],
      filter_options: filterOption,
    };

    return { bodyParam, accountId, emailId, isGroup };
  }

  getMainFilterSelection(event: any) {
    let selectedId: any[] = [];
    event.forEach((element: any) => {
      selectedId.push({
        id: element.id,
        filterDisplayName: element.filterDisplayName,
        type: element.type,
        index: element.index,
      });
    });
    this.mainFilterSelectedArray = selectedId;
    this.isCustomFilterAdded = this.mainFilterSelectedArray?.length == 0 ? false : true;
  }

  onSelectedCustomFilter(event: any) {
    this.combinedSelectedFiltersArray = [];
    // this.advancedFilterValue = [];  commented and added on line - 693 due to bug 107019
    if (event) {
      if (event.type == 'statuscustom') {
        this.statusFilterSelectedArray = event.selectedValue;
        let statusChecked =
          event.selectedValue.length > 0
            ? event.selectedValue[0].value.split(',')
            : '';
        if (statusChecked.length == 1) {
          if (this.defaultFilterSelectedValue !== parseInt(statusChecked[0])) {
            this.defaultFilterSelectedValue = parseInt(statusChecked[0]);
          }
        } else if (
          ((!statusChecked || statusChecked.length == 0) &&
            event.clearFrom !== 'defaultFilter') ||
          statusChecked.length > 0
        ) {
          this.defaultFilterSelectedValue = 0;
        }
      } else if (event.type == 'status') {
        if (event.selectedValue[0].value == 0) {
          this.defaultFilterArray = event.selectedValue;
        }
      } else if (event.type == 'transport') {
        this.transportTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'containertype') {
        this.containerTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'exception') {
        this.exceptionTypeFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'Origin') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'origin';
          this.originFilterSelectedArray = event.selectedValue;
        }
        this.selectedOriginAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let originJsonGet = sessionStorage.getItem('resentOrgin');
          this.filterRecentSearch(originJsonGet, event);
        }
      } else if (event.type == 'Destination') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'destination';
          this.destinationFilterSelectedArray = event.selectedValue;
        }
        this.selectedDestinationAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let destinationJsonGet = sessionStorage.getItem('resentDestination');
          this.filterRecentSearch(destinationJsonGet, event);
        }
      } else if (event.type == 'shipper') {
        this.shipperFilterSelectedArray = event.selectedValue;
        this.selectedShipperAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let shipperJsonGet = sessionStorage.getItem('resentShipper');
          this.filterRecentSearch(shipperJsonGet, event);
        }
      } else if (event.type == 'consignee') {
        this.consigneeFilterSelectedArray = event.selectedValue;
        this.selectedConsigneeAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let consigneeJsonGet = sessionStorage.getItem('resentConsignee');
          this.filterRecentSearch(consigneeJsonGet, event);
        }
      } else if (event.type == 'controllingcustomer') {
        this.ControllingCustomerFilterSelectedArray = event.selectedValue;
        this.selectedControllingCustomerAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let controllingcustomerJsonGet =
            sessionStorage.getItem('resentControlling');
          this.filterRecentSearch(controllingcustomerJsonGet, event);
        }
      } else if (event.type == 'Final Delivery') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'delivery';
          this.finalDeliveryFilterSelectedArray = event.selectedValue;
        }
        this.selectedfinalDeliveryAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let finalDeliveryJsonGet = sessionStorage.getItem(
            'recentFinalDelivery'
          );
          this.filterRecentSearch(finalDeliveryJsonGet, event);
        }
      } else if (event.type == 'Pickup') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'pickuplocation';
          this.pickupFilterSelectedArray = event.selectedValue;
        }
        this.selectedPickupAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let pickupJsonGet = sessionStorage.getItem('recentPickup');
          this.filterRecentSearch(pickupJsonGet, event);
        }
      } else if (event.type == 'Origin Country') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'originCountry';
          this.originCountryFilterSelectedArray = event.selectedValue;
        }
        this.selectedOriginCountryAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let originCountryJsonGet = sessionStorage.getItem(
            'recentOriginCountry'
          );
          this.filterRecentSearch(originCountryJsonGet, event);
        }
      } else if (event.type == 'Destination Country') {
        if (event.selectedValue) {
          event.selectedValue.column_name = 'destinationCountry';
          this.destinationCountryFilterSelectedArray = event.selectedValue;
        }
        this.selectedDestinationCountryAccount = event.account;
        if (!event.removing && event.account.length > 0) {
          let destinationCountryJsonGet = sessionStorage.getItem(
            'recentDestinationCountry'
          );
          this.filterRecentSearch(destinationCountryJsonGet, event);
        }
      } else if (event.type == 'dateRange') {
        this.dateRangeFilterSelectedArray = event.selectedValue;
        this.selectedDateRange = event.selectedDateRange;
      } else if (event.type == 'favorites') {
        this.watchListFilterSelectedArray = event.selectedValue;
      } else if (event.type == 'allLocation') {
        this.finalDeliveryFilterSelectedArray = [];
        this.pickupFilterSelectedArray = [];
        this.originFilterSelectedArray = [];
        this.destinationFilterSelectedArray = [];
        this.originCountryFilterSelectedArray = [];
        this.destinationCountryFilterSelectedArray = [];
        this.selectedfinalDeliveryAccount = [];
        this.selectedPickupAccount = [];
        this.selectedOriginAccount = [];
        this.selectedDestinationAccount = [];
        this.selectedOriginCountryAccount = [];
        this.selectedDestinationCountryAccount = [];
      } else if (event.type == 'advancedFilter') {
        this.advancedFilterValue = event.selectedValue;
      } else if (event.type == 'columnSearch') {
        this.parameters = event.selectedValue;
        this.clearData();
        this.loadAllShipments();
        if (this.parameters?.length > 0) {
          this.enableClearAllButton();
        } else {
          this.disableClearAllButton();
        }
      }
      else if (event.type == "columnSort") {
        this.defaultSortSelectedColumn = event.field;
        this.defaultSortDirection = event.direction;
        this.clearData();
        //this.loadAllShipments();
        this.enableClearAllButton();
      }
      else if (event.type == "loadToDefault") {
        this.customFilterComponent.clearAllRibbonFilters(true);
        this.resetColumnFilter(event.type);
      }
      else if (event.type == "columnList") {
        this.tableViewColumnHeaderList = event.data;
      }
      else if (event.type == "callFilterNavigator") {
        //this.navigateToSelectedFilter();
      }

      this.combinedSelectedFiltersArray = [].concat(
        this.statusFilterSelectedArray,
        this.transportTypeFilterSelectedArray,
        this.exceptionTypeFilterSelectedArray,
        this.originFilterSelectedArray,
        this.destinationFilterSelectedArray,
        this.dateRangeFilterSelectedArray,
        this.watchListFilterSelectedArray,
        this.defaultFilterArray,
        this.shipperFilterSelectedArray,
        this.consigneeFilterSelectedArray,
        this.finalDeliveryFilterSelectedArray,
        this.pickupFilterSelectedArray,
        this.ControllingCustomerFilterSelectedArray,
        this.containerTypeFilterSelectedArray,
        this.originCountryFilterSelectedArray,
        this.destinationCountryFilterSelectedArray
      );
    } else {
      this.combinedSelectedFiltersArray = [];
    }
    if (event?.clearFrom != 'defaultFilter' && event?.type != "columnSearch" &&
      event?.type != "callFilterNavigator") {
      this.clearData();
      this.resetColumnFilter(event?.type);
      this.loadAllShipments();
    }

    if (this.combinedSelectedFiltersArray?.length == 0 || this.checkIsAllObjectEmpty(this.combinedSelectedFiltersArray)) {
      if (!this.hasDefaultFilterDefined && this.customFilterComponent.advancedFilterAdded?.length == 0) {
        this.defaultFilterSelectedValue = 0;
      }
      this.isCustomFilterAdded = false;
    }
    else {
      this.isCustomFilterAdded = true;
    }
  }

  checkIsAllObjectEmpty(selectedArray: any) {
    let hasAllObjectEmpty = selectedArray.every((obj: any) => {
      return typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0;
    });
    return hasAllObjectEmpty;
  }

  resetColumnFilter(type: any) {
    if (type != "columnSort") {
      this.defaultSortSelectedColumn = " first_leg_departure_actual";
      this.defaultSortDirection = "desc";
    }
    this.parameters = [];
  }

  filterRecentSearch(filterJsonGet: any, event: any) {
    let filterResentGet =
      filterJsonGet !== null ? JSON.parse(filterJsonGet) : '';
    let filterRecentList: any[] = [];
    if (filterResentGet) {
      let elementPos: any;
      event.account.forEach((element: any) => {
        if (event.type == 'Origin' || event.type == 'Destination') {
          elementPos = filterResentGet
            .map(function (e: any) {
              return e.rL_Code;
            })
            .indexOf(element.rL_Code);
        } else if (
          event.type == 'Origin Country' ||
          event.type == 'Destination Country'
        ) {
          elementPos = filterResentGet
            .map(function (e: any) {
              return e.rL_RN_NKCountryCode;
            })
            .indexOf(element.rL_RN_NKCountryCode);
        } else if (
          event.type == 'shipper' ||
          event.type == 'consignee' ||
          event.type == 'controllingcustomer'
        ) {
          elementPos = filterResentGet
            .map(function (e: any) {
              return e.target_party_id;
            })
            .indexOf(element.target_party_id);
        } else if (event.type == 'Pickup' || event.type == 'Final Delivery') {
          elementPos = filterResentGet
            .map(function (elementLoad: any) {
              return elementLoad.index;
            })
            .indexOf(element.index);
        }

        if (elementPos < 0) {
          filterRecentList = [].concat(element, filterResentGet);
        } else {
          filterRecentList = [].concat(filterResentGet);
        }
      });
    } else {
      if (event.account.length > 0) {
        filterRecentList = [].concat(event.account);
      } else {
        filterRecentList = [].concat(filterResentGet);
      }
    }
    setTimeout(() => {
      let filterOutput = filterRecentList.slice(0, 3);
      if (event.account) {
        switch (event.type) {
          case 'Origin':
            sessionStorage.setItem('resentOrgin', JSON.stringify(filterOutput));
            break;
          case 'Destination':
            sessionStorage.setItem(
              'resentDestination',
              JSON.stringify(filterOutput)
            );
            break;
          case 'shipper':
            sessionStorage.setItem(
              'resentShipper',
              JSON.stringify(filterOutput)
            );
            break;
          case 'consignee':
            sessionStorage.setItem(
              'resentConsignee',
              JSON.stringify(filterOutput)
            );
            break;

          case 'controllingcustomer':
            sessionStorage.setItem(
              'resentControlling',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Pickup':
            sessionStorage.setItem(
              'recentPickup',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Final Delivery':
            sessionStorage.setItem(
              'recentFinalDelivery',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Origin Country':
            sessionStorage.setItem(
              'recentOriginCountry',
              JSON.stringify(filterOutput)
            );
            break;
          case 'Destination Country':
            sessionStorage.setItem(
              'recentDestinationCountry',
              JSON.stringify(filterOutput)
            );
            break;
        }
      }
      let filterJsonSet: any;
      switch (event.type) {
        case 'Origin':
          filterJsonSet = sessionStorage.getItem('resentOrgin');
          break;
        case 'Destination':
          filterJsonSet = sessionStorage.getItem('resentDestination');
          break;
        case 'shipper':
          filterJsonSet = sessionStorage.getItem('resentShipper');
          break;
        case 'consignee':
          filterJsonSet = sessionStorage.getItem('resentConsignee');
          break;
        case 'controllingCustomer':
          filterJsonSet = sessionStorage.getItem('resentControlling');
          break;
        case 'Pickup':
          filterJsonSet = sessionStorage.getItem('recentPickup');
          break;
        case 'Final Delivery':
          filterJsonSet = sessionStorage.getItem('recentFinalDelivery');
          break;
        case 'Origin Country':
          filterJsonSet = sessionStorage.getItem('recentOriginCountry');
          break;
        case 'Destination Country':
          filterJsonSet = sessionStorage.getItem('recentDestinationCountry');
          break;
      }
      let filterResentSet =
        filterJsonSet !== null ? JSON.parse(filterJsonSet) : '';

      this.resentList = {
        list: filterResentSet,
        type: event.type,
      };
    }, 1000);
  }

  getAllURLFilter(event: any) {
    this.statusFilterSelectedArray = event.status;
    this.transportTypeFilterSelectedArray = event.transport;
    this.exceptionTypeFilterSelectedArray = event.exception;
    this.originFilterSelectedArray = event.origin;
    this.destinationFilterSelectedArray = event.destination;
    this.dateRangeFilterSelectedArray = event.dateRange;
    this.mainFilterSelectedArray = event.mainfilter;
    this.watchListFilterSelectedArray = event.watchFilter;
    this.shipperFilterSelectedArray = event.shipper;
    this.consigneeFilterSelectedArray = event.consignee;
    this.pickupFilterSelectedArray = event.pickUp;
    this.finalDeliveryFilterSelectedArray = event.finalDelivery;
    this.selectedShipperAccount = event.shipperAccount;
    this.selectedConsigneeAccount = event.consigneeAccount;
    this.selectedOriginAccount = event.originAccount;
    this.selectedDestinationAccount = event.destinationAccount;
    this.selectedPickupAccount = event.pickUpAccount;
    this.selectedfinalDeliveryAccount = event.finalDeliveryAccount;
    this.ControllingCustomerFilterSelectedArray = event.controllingCustomer;
    this.selectedControllingCustomerAccount = event.controllingCustomerAccount;
    this.containerTypeFilterSelectedArray = event.container;
    this.selectedOriginCountryAccount = event.originCountryAccount;
    this.selectedDestinationAccount = event.destinationCountryAccount;
    this.originCountryFilterSelectedArray = event.originCountry;
    this.destinationCountryFilterSelectedArray = event.destinationCountry;
    this.advancedFilterValue = event.advancedFilter;
    this.combinedSelectedFiltersArray = [].concat(
      this.statusFilterSelectedArray,
      this.transportTypeFilterSelectedArray,
      this.exceptionTypeFilterSelectedArray,
      this.originFilterSelectedArray,
      this.destinationFilterSelectedArray,
      this.dateRangeFilterSelectedArray,
      this.watchListFilterSelectedArray,
      this.shipperFilterSelectedArray,
      this.consigneeFilterSelectedArray,
      this.pickupFilterSelectedArray,
      this.finalDeliveryFilterSelectedArray,
      this.ControllingCustomerFilterSelectedArray,
      this.containerTypeFilterSelectedArray,
      this.originCountryFilterSelectedArray,
      this.destinationCountryFilterSelectedArray,
      this.advancedFilterValue
    );
    if (this.combinedSelectedFiltersArray.length > 0) {
      this.combinedSelectedFiltersArray =
        this.combinedSelectedFiltersArray.filter(function (element) {
          return element !== undefined;
        });
      this.combinedSelectedFiltersArray =
        this.combinedSelectedFiltersArray.filter(
          (el) => Object.keys(el).length
        );
      this.isCustomFilterAdded = true;
    }
    else {
      this.isCustomFilterAdded = false;
    }
    this.shipmentSearchText = event.shipmentId;
    this.clearData();
    this.loadAllShipments();
  }

  loadAllSortOptions() {
    this.sortOptions = [
      {
        id: 1,
        name: 'Shipments #',
        sort_column: 'forwarder_reference',
        sort_direction: 'asc',
      },
      { id: 2, name: 'Status', sort_column: 'status', sort_direction: 'asc' },
      {
        id: 3,
        name: 'Est. Pickup',
        sort_column: 'origin_pickup_estimated',
        sort_direction: 'asc',
      },
      {
        id: 4,
        name: 'Actual Pickup',
        sort_column: 'origin_pickup_actual',
        sort_direction: 'asc',
      },
      {
        id: 5,
        name: 'ETD',
        sort_column: 'first_leg_departure_estimated',
        sort_direction: 'asc',
      },
      {
        id: 6,
        name: 'ATD',
        sort_column: 'first_leg_departure_actual',
        sort_direction: 'asc',
      },
      {
        id: 7,
        name: 'ETA',
        sort_column: 'last_leg_arrival_estimated',
        sort_direction: 'asc',
      },
      {
        id: 8,
        name: 'ATA',
        sort_column: 'last_leg_arrival_actual',
        sort_direction: 'asc',
      },
      {
        id: 9,
        name: 'Est. Delivery',
        sort_column: 'delivery_estimated',
        sort_direction: 'asc',
      },
      {
        id: 10,
        name: 'Actual Delivery',
        sort_column: 'delivery_actual',
        sort_direction: 'asc',
      },
    ];
  }




  getListingOffset(event: any) {
    this.page = event;
    this.loadAllShipments();
  }

  clearData() {
    this.page = 0;
    this.shipmentData = [];
    this.shipmentData.splice(0, this.shipmentData.length);
  }

  prepareExcelDownload(event: any) {
    let getParameter = this.prepareApiBodyParams();
    this.isProgressShow = true;
    this.shipmentService
      .prepareExcelDowload(
        getParameter.accountId,
        getParameter.emailId,
        getParameter.bodyParam,
        getParameter.isGroup
      )
      .subscribe((res: Blob) => {
        this.downloadFile(res);
        this.isProgressShow = false;
      });
  }

  downloadFile(data: any) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    let today = new Date();

    let date =
      today.getFullYear() +
      CommonUtils.padding2(today.getMonth() + 1) +
      today.getDate();

    let time =
      CommonUtils.padding2(today.getHours()) +
      CommonUtils.padding2(today.getMinutes()) +
      CommonUtils.padding2(today.getSeconds());
    let dateTime = date + '_' + time;
    let filename =
      this.currentAccountData.accountAliasName +
      '_Shipments_' +
      dateTime +
      '.xlsx';
    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
    URL.revokeObjectURL(url);
  }

  prepareShareUrl(event: any) {
    this.url = '';
    let feature: any = this.currentSessionUserData.getCurrentAccountData();
    let selectedFilters = {
      mainFilter: event.type == 'Detail' ? [] : this.mainFilterSelectedArray,
      statusFilter:
        event.type == 'Detail' ? [] : this.statusFilterSelectedArray,
      transportType:
        event.type == 'Detail' ? [] : this.transportTypeFilterSelectedArray,
      containerType:
        event.type == 'Detail' ? [] : this.containerTypeFilterSelectedArray,
      exceptionType:
        event.type == 'Detail' ? [] : this.exceptionTypeFilterSelectedArray,
      watchFilter:
        event.type == 'Detail' ? [] : this.watchListFilterSelectedArray,
      origin: {
        selectedValue:
          event.type == 'Detail'
            ? []
            : Object.keys(this.originFilterSelectedArray).length > 0
              ? this.originFilterSelectedArray
              : [],
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedOriginAccount,
      },
      destination: {
        selectedValue:
          event.type == 'Detail'
            ? []
            : Object.keys(this.destinationFilterSelectedArray).length > 0
              ? this.destinationFilterSelectedArray
              : [],
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedDestinationAccount,
      },
      dateRange: {
        selectedValue:
          event.type == 'Detail' ? [] : this.dateRangeFilterSelectedArray,
        selectedDateRange: event.type == 'Detail' ? [] : this.selectedDateRange,
      },
      shipper: {
        selectedValue:
          event.type == 'Detail'
            ? []
            : Object.keys(this.shipperFilterSelectedArray).length > 0
              ? this.shipperFilterSelectedArray
              : [],
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedShipperAccount,
      },
      consignee: {
        selectedValue:
          event.type == 'Detail'
            ? []
            : Object.keys(this.consigneeFilterSelectedArray).length > 0
              ? this.consigneeFilterSelectedArray
              : [],
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedConsigneeAccount,
      },
      controllingCustomer: {
        selectedValue:
          event.type == 'Detail'
            ? []
            : Object.keys(this.ControllingCustomerFilterSelectedArray).length >
              0
              ? this.ControllingCustomerFilterSelectedArray
              : [],
        selectedAccount:
          event.type == 'Detail' ? [] : this.selectedControllingCustomerAccount,
      },
      allLocation: {
        selectedAccount: [
          { origin: event.type == 'Detail' ? [] : this.selectedOriginAccount },
          {
            destination:
              event.type == 'Detail' ? [] : this.selectedDestinationAccount,
          },
          { pickup: event.type == 'Detail' ? [] : this.selectedPickupAccount },
          {
            finalDelivery:
              event.type == 'Detail' ? [] : this.selectedfinalDeliveryAccount,
          },
          {
            originCountry:
              event.type == 'Detail' ? [] : this.selectedOriginCountryAccount,
          },
          {
            destinationCountry:
              event.type == 'Detail'
                ? []
                : this.selectedDestinationCountryAccount,
          },
        ],
        selectedValue: [
          {
            originValue:
              event.type == 'Detail'
                ? {}
                : Object.keys(this.originFilterSelectedArray).length > 0
                  ? this.originFilterSelectedArray
                  : [],
          },
          {
            destinationValue:
              event.type == 'Detail'
                ? {}
                : Object.keys(this.destinationFilterSelectedArray).length > 0
                  ? this.destinationFilterSelectedArray
                  : [],
          },
          {
            pickupValue:
              event.type == 'Detail'
                ? {}
                : Object.keys(this.pickupFilterSelectedArray).length > 0
                  ? this.pickupFilterSelectedArray
                  : [],
          },
          {
            finalDeliveryValue:
              event.type == 'Detail'
                ? {}
                : Object.keys(this.finalDeliveryFilterSelectedArray).length > 0
                  ? this.finalDeliveryFilterSelectedArray
                  : [],
          },
          {
            originCountryValue:
              event.type == 'Detail'
                ? {}
                : Object.keys(this.originCountryFilterSelectedArray).length > 0
                  ? this.originCountryFilterSelectedArray
                  : [],
          },
          {
            destinationCountryValue:
              event.type == 'Detail'
                ? {}
                : Object.keys(this.destinationCountryFilterSelectedArray)
                  .length > 0
                  ? this.destinationCountryFilterSelectedArray
                  : [],
          },
        ],
      },
      sorting: {
        sortId: this.defaultSortSelectedValue,
        selectedValue: {
          column_name: this.defaultSortSelectedColumn,
          direction: this.defaultSortDirection,
        },
      },
      accountDetail: {
        aliasName:
          event.type == 'Detail' ? event.aliasName : feature.accountAliasName,
        oH_Code: event.type == 'Detail' ? event.accountId : feature.accountId,
        byGroup: event.type == 'Detail' ? event.isGroup : feature.isGroup,
        shipment: { isVisible: feature.shipmentIsVisible },
        booking: { isVisible: feature.bookingIsVisible },
        customs: { isVisible: feature.customsIsVisible },
        isControllingCustomer:
          event.type == 'Detail' ? event.isParent : feature.isParent,
      },
      shipmentId: event.shipmentId,
      type: event.type,
      subType: event.type == 'Detail' ? event.subType : '',
      module: 'Shipment',
    };
    this.saveFilterJson = selectedFilters;
    if (event.type == 'All') {
      this.prepareUrlForAllShipment();
    } else if (event.type === 'Detail') {
      this.url = environment.localUrl + '/' + event?.shipment?.encryptedId;
    } else {
      let encry = btoa(JSON.stringify(selectedFilters));
      this.url = environment.localUrl + '/shipments/' + encry;
    }
  }

  prepareUrlForAllShipment() {
    let allFilter: any = {};
    this.saveFilterJson.mainFilter.forEach((e: any) => {
      e.filterDisplayName = e.filterDisplayName.includes(':')
        ? e.filterDisplayName?.split(':')[0]
        : e.filterDisplayName;
    });
    this.mainFilterValueCheck();
    this.shipmentService
      .saveShareFilter(
        this.currentUserData.emailAddress,
        '',
        this.saveFilterJson,
        this.currentAccountData.accountId
      )
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          allFilter = {
            id: res.result,
            type: 'All',
          };
        }
        let encryAll = btoa(JSON.stringify(allFilter));
        this.url = environment.localUrl + '/shipments/' + encryAll;
      });
  }

  clearSearchFilter(e: any) {
    this.shipmentSearchText = '';
    this.clearData();
    this.loadAllShipments();
  }

  clearAllFilterValue(e: any) {
    this.combinedSelectedFiltersArray = [];
    this.statusFilterSelectedArray = [];
    this.containerTypeFilterSelectedArray = [];
    this.transportTypeFilterSelectedArray = [];
    this.exceptionTypeFilterSelectedArray = [];
    this.originFilterSelectedArray = [];
    this.destinationFilterSelectedArray = [];
    this.dateRangeFilterSelectedArray = [];
    this.watchListFilterSelectedArray = [];
    this.mainFilterSelectedArray = [];
    this.defaultFilterArray = [];
    this.shipperFilterSelectedArray = [];
    this.consigneeFilterSelectedArray = [];
    this.selectedShipperAccount = [];
    this.selectedConsigneeAccount = [];
    this.pickupFilterSelectedArray = [];
    this.finalDeliveryFilterSelectedArray = [];
    this.selectedOriginAccount = [];
    this.selectedDestinationAccount = [];
    this.selectedPickupAccount = [];
    this.selectedfinalDeliveryAccount = [];
    this.selectedShipperAccount = [];
    this.selectedConsigneeAccount = [];
    this.selectedControllingCustomerAccount = [];
    this.ControllingCustomerFilterSelectedArray = [];
    if (e != 'saveButton') {
      if (this.savedFilterRow || this.systemFilterRow) {
        this.defaultFilterSelectedValue = -1;
      } else {
        this.defaultFilterSelectedValue = 0;
      }

      this.clearData();
      this.shipmentSearchText = '';
      this.loadAllShipments();
    }
  }

  startGuidedTour(type: any) {
    if (type == 'first') {
      this.hideGuidedTourPopup();
    }
    this.dataSharingService.isGuidedTourHeaderHide.next(true);
    this.dataSharingService.isGuidedTourShipment.next(true);
    this.guidedFeatureModalShow = true;
    if (this.totalShipmentCount > 0) {
      this.guidedTourFlag = 1;
      this.guidedTourPopupHeader = this.guidedTourJson[0].heading;
      this.guidedTourPopupPara = this.guidedTourJson[0].para;
    } else {
      this.guidedTourFlag = 3;
      this.guidedTourFlagNoShipment = 1;
      this.guidedTourPopupHeader = this.guidedTourJson[2].heading;
      this.guidedTourPopupPara = this.guidedTourJson[2].para;
    }
    setTimeout(() => {
      $('#rapModal').addClass('dynamicRapClass' + this.guidedTourFlag);
    }, 0);
    this.getGuidedTourFeature();
  }

  nextFeature() {
    this.guidedTourShipmentDetail = 'false';
    if (this.guidedTourFlag == 9) {
      this.guidedFeatureModalShow = false;
      this.clearAllGuidedTourCss();
    }
    if (this.guidedTourFlag < 9) {
      this.guidedTourFlag += 1;
      if (this.guidedTourFlag == 9 && this.totalShipmentCount < 1) {
        this.guidedFeatureModalShow = false;
        this.clearAllGuidedTourCss();
      } else {
        if (this.totalShipmentCount < 1) {
          this.guidedTourFlagNoShipment += 1;
        }
        this.dataSharingService.isGuidedTourHeaderHide.next(true);
        $('#rapModal').removeClass();
        $('#rapModal').addClass(
          'rap-set dynamicRapClass' + this.guidedTourFlag
        );
        this.getGuidedTourFeature();
      }
    }
  }

  previousFeature() {
    this.dataSharingService.isGuidedTourHeaderHide.next(true);
    if (this.guidedTourFlag == 9) {
      if (!$('#shipmentFull').is(':visible')) {
        return;
      }
    }
    this.guidedTourFlag -= 1;
    if (this.totalShipmentCount < 1) {
      this.guidedTourFlagNoShipment -= 1;
    }
    $('#rapModal').removeClass();
    $('#rapModal').addClass('rap-set dynamicRapClass' + this.guidedTourFlag);
    this.getGuidedTourFeature();
  }

  getGuidedTourFeature() {
    this.dataSharingService.isGuidedTourDefaultFilterHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterCompHide.next(true);
    this.dataSharingService.isGuidedTourToggleShow.next(true);
    this.dataSharingService.isGuidedTourShipmentSecHide.next(true);
    this.dataSharingService.isGuidedTourCustomFilterHide.next(true);
    this.dataSharingService.isGuidedTourSearchTextBoxHide.next(true);
    this.dataSharingService.isGuidedTourSortHide.next(true);
    this.dataSharingService.isGuidedTourShipment.next(false);
    this.dataSharingService.isGuidedTourCustomFilterShow.next(false);
    switch (this.guidedTourFlag) {
      case 1:
        this.dataSharingService.isGuidedTourShipmentSecShow.next(true);
        this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
        this.guidedTourPopupHeader = this.guidedTourJson[0].heading;
        this.guidedTourPopupPara = this.guidedTourJson[0].para;
        break;
      case 2:
        this.dataSharingService.isGuidedTourShipmentSecShow.next(true);
        this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
        this.guidedTourPopupHeader = this.guidedTourJson[1].heading;
        this.guidedTourPopupPara = this.guidedTourJson[1].para;
        break;
      case 3:
        this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
        this.dataSharingService.isGuidedTourDefaultFilterShow.next(true);
        this.guidedTourShipmentFilter = false;
        this.guidedTourPopupHeader = this.guidedTourJson[2].heading;
        this.guidedTourPopupPara = this.guidedTourJson[2].para;
        break;
      case 4:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourCustomFilterHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterShow.next(true);
        this.guidedTourShipmentFilter = true;
        this.guidedTourPopupHeader = this.guidedTourJson[3].heading;
        this.guidedTourPopupPara = this.guidedTourJson[3].para;
        break;
      case 5:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourSearchTextBoxHide.next(false);
        this.dataSharingService.isGuidedTourSearchTextBoxShow.next(true);
        this.guidedTourShipmentFilter = false;
        this.guidedTourPopupHeader = this.guidedTourJson[4].heading;
        this.guidedTourPopupPara = this.guidedTourJson[4].para;
        break;
      case 6:
        this.dataSharingService.isGuidedTourHeaderHide.next(false);
        this.dataSharingService.isGuidedTourHeaderShow.next(true);
        this.guidedTourPopupHeader = this.guidedTourJson[5].heading;
        this.guidedTourPopupPara = this.guidedTourJson[5].para;
        break;
      case 7:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourSortHide.next(false);
        this.dataSharingService.isGuidedTourSortShow.next(true);
        this.guidedTourPopupHeader = this.guidedTourJson[6].heading;
        this.guidedTourPopupPara = this.guidedTourJson[6].para;
        break;
      case 8:
        this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
        this.dataSharingService.isGuidedTourCustomFilterCompShow.next(true);
        this.dataSharingService.isGuidedTourShareShipmentHide.next(false);
        this.dataSharingService.isGuidedTourShareShipmentShow.next(true);
        this.guidedTourShipmentDetail = 'false';
        this.guidedTourPopupHeader = this.guidedTourJson[7].heading;
        this.guidedTourPopupPara = this.guidedTourJson[7].para;
        break;
      case 9:
        if (this.totalShipmentCount > 0) {
          this.dataSharingService.isGuidedTourShipment.next(true);
          this.guidedTourShipmentDetail = 'true';
          this.guidedTourPopupHeader = this.guidedTourJson[8].heading;
          this.guidedTourPopupPara = this.guidedTourJson[8].para;
        }
        break;
    }
  }

  guidedTourStartManually(event: any) {
    if (event) {
      this.guidedFeatureManualModalShow = true;
    }
  }

  gudideTourStart() {
    if (this.isProgressShow) {
      return;
    }
    this.guidedFeatureManualModalShow = false;
    this.startGuidedTour('manual');
  }

  gudideTourCloseset() {
    this.guidedFeatureManualModalShow = false;
  }

  skipTour() {
    this.hideGuidedTourPopup();
  }

  endTour() {
    let params = {
      featureName: 'shipments',
      isVisited: true,
      whenFinished: new Date(),
    };
    this.shipmentService
      .skipTour(this.currentUserData.emailAddress, params)
      .subscribe((res) => {
        if (res.result.includes('Guided tour updated')) {
          let setUser = this.currentSessionUserData.getCurrentUserData();
          setUser.guidedTour = [params];
          localStorage.setItem(
            'loggedInUser',
            window.btoa(unescape(encodeURIComponent(JSON.stringify(setUser))))
          );
        }
      });
  }

  clearAllGuidedTourCss() {
    this.dataSharingService.isGuidedTourHeaderHide.next(false);
    this.dataSharingService.isGuidedTourHeaderShow.next(false);
    this.dataSharingService.isGuidedTourShipment.next(false);
    this.dataSharingService.isGuidedTourShipmentSecHide.next(false);
    this.dataSharingService.isGuidedTourShipmentSecShow.next(false);
    this.dataSharingService.isGuidedTourCustomFilterCompHide.next(false);
    this.dataSharingService.isGuidedTourCustomFilterCompShow.next(false);
    this.dataSharingService.isGuidedTourDefaultFilterHide.next(false);
    this.dataSharingService.isGuidedTourDefaultFilterShow.next(false);
    this.dataSharingService.isGuidedTourSearchTextBoxHide.next(false);
    this.dataSharingService.isGuidedTourSearchTextBoxShow.next(false);
    this.dataSharingService.isGuidedTourCustomFilterHide.next(false);
    this.dataSharingService.isGuidedTourCustomFilterShow.next(false);
    this.dataSharingService.isGuidedTourSortHide.next(false);
    this.dataSharingService.isGuidedTourSortShow.next(false);
    this.dataSharingService.isGuidedTourShareShipmentHide.next(false);
    this.dataSharingService.isGuidedTourShareShipmentShow.next(false);
    this.dataSharingService.isGuidedTourToggleShow.next(false);
    this.guidedTourShipmentDetail = '';
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getUserDetailGuidedTour() {
    this.userService
      .getUserDetail(this.currentUserData.emailAddress)
      .subscribe((res) => {
        let userGuidedTourDetails = res.result.user.guidedTour.filter(function (
          el: any
        ) {
          return el.featureName == 'shipments';
        });
        let guidedTour =
          userGuidedTourDetails.length > 0 ? userGuidedTourDetails[0] : null;
        if (!guidedTour || (!guidedTour.isVisited && !this.isMobileView)) {
          this.appInsightsService.trackAIGuidedTour(AI_ModulesName.Shipment);
          this.showGuidedTourPopup();
          this.endTour();
        }
      });
  }

  userFilterFetch(event: any) {
    this.customFilterComponent.advFiltername = event.userFilterRow.filterName;
    this.captureAllFilterName(event.userFilterRow, event.userFilterRow.filterName);
    let getPatameter: any = '';
    if (event.type == 'view') {
      this.defaultFilterSelectedValue = -1;
      this.customFilterComponent.clearAllFilters();
      this.customFilterComponent.clearMobileFilters();
      setTimeout(() => {
        let decryptFilter = JSON.parse(event.userFilterRow.jsonFilter);

        if (decryptFilter.mainFilter.length <= 0) {
          if (Object.keys(decryptFilter.statusFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 1,
              filterDisplayName: 'Status',
              type: 'statuscustom',
              index: 1,
            });
          }
          if (Object.keys(decryptFilter.transportType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 0,
              filterDisplayName: 'Transport Type',
              type: 'transport',
              index: 0,
            });
          }
          if (decryptFilter.shipper.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 2,
              filterDisplayName: 'Shipper',
              type: 'shipper',
              index: 2,
            });
          }
          if (decryptFilter.consignee.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 3,
              filterDisplayName: 'Consignee',
              type: 'consignee',
              index: 3,
            });
          }
          if (decryptFilter.controllingCustomer.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 8,
              filterDisplayName: 'Controlling Customer',
              type: 'controllingcustomer',
              index: 8,
            });
          }
          if (
            decryptFilter.allLocation.selectedAccount[0].origin.length > 0 ||
            decryptFilter.allLocation.selectedAccount[1].destination.length >
            0 ||
            decryptFilter.allLocation.selectedAccount[2].pickup.length > 0 ||
            decryptFilter.allLocation.selectedAccount[3].finalDelivery.length >
            0
          ) {
            decryptFilter.mainFilter.push({
              id: 4,
              filterDisplayName: 'Location',
              type: 'location',
              index: 4,
            });
          }
          if (decryptFilter.dateRange.selectedDateRange.length > 0) {
            decryptFilter.mainFilter.push({
              id: 5,
              filterDisplayName: 'Date Range',
              type: '',
              index: 5,
            });
          }

          if (Object.keys(decryptFilter.exceptionType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 6,
              filterDisplayName: 'Exception Type',
              type: 'exception',
              index: 6,
            });
          }
          if (Object.keys(decryptFilter.watchFilter).length > 0) {
            this.saveFilterJson.mainFilter.push({
              id: 7,
              filterDisplayName: 'Watchlist',
              type: '',
              index: 7,
            });
          }
        }
        if (
          decryptFilter.allLocation.selectedAccount[0].origin.length > 0 &&
          Object.keys(decryptFilter.allLocation.selectedValue[0].originValue)
            .length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[0].origin = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[1].destination.length > 0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[1].destinationValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[1].destination = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[2].pickup.length > 0 &&
          Object.keys(decryptFilter.allLocation.selectedValue[2].pickupValue)
            .length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[2].pickup = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[3].finalDelivery.length >
          0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[3].finalDeliveryValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[3].finalDelivery = [];
        }
        setTimeout(() => {
          this.customFilterComponent.loadURLFilters(decryptFilter);
        }, 500);
        setTimeout(() => {
          getPatameter = this.prepareApiBodyParams();
          this.filterCheckTemp = getPatameter.bodyParam;
        }, 500);
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
      }, 300);
    } else if (event.type == 'update') {
      if (this.combinedSelectedFiltersArray.length > 0) {  // commented not to reset while going edit mode
        // this.combinedSelectedFiltersArray = [];
        //this.defaultFilterSelectedValue = 0;
        //this.customFilterComponent.clearAllFilters();
        //this.customFilterComponent.clearMobileFilters();
        // this.clearData();
        // this.loadAllShipments();
      }
      setTimeout(() => {
        getPatameter = this.prepareApiBodyParams();
        this.filterCheckTemp = getPatameter.bodyParam;
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
        setTimeout(() => {
          this.customFilterComponent.setUpdateFilterName();
        }, 200);
      }, 300);
    }
  }

  captureAllFilterName(event: any, filterName: any) {
    let jsonFilterArray = typeof event?.jsonFilter == "string" ? JSON.parse(event?.jsonFilter) : event?.jsonFilter;
    if (jsonFilterArray?.advancedFilter?.length > 0) {
      this.captureFilterName.push(filterName);
    }
  }

  systemFilterFetch(event: any) {
    this.customFilterComponent.advFiltername = event.userFilterRow.filterName;
    this.captureAllFilterName(event.userFilterRow, event.userFilterRow.filterName);
    this.defaultFilterSelectedValue = -1;
    let getPatameter: any = '';
    let userSettings = event.userFilterRow
      ? event.userFilterRow.filterusers.filter(
        (x: any) => x.userEmail === this.currentUserData.emailAddress
      )
      : event;
    if (
      event.type == 'view' &&
      (!userSettings[0]?.isDefaultFilter ||
        this.currentUserData.userType === 'systemadmin')
    ) {
      //this.defaultFilterSelectedValue = 0;
      this.customFilterComponent.clearAllFilters();
      this.customFilterComponent.clearMobileFilters();
      setTimeout(() => {
        let decryptFilter = JSON.parse(event.userFilterRow.jsonFilter);
        if (decryptFilter.mainFilter.length <= 0) {
          if (Object.keys(decryptFilter.statusFilter).length > 0) {
            decryptFilter.mainFilter.push({
              id: 1,
              filterDisplayName: 'Status',
              type: 'statuscustom',
              index: 1,
            });
          }
          if (Object.keys(decryptFilter.transportType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 0,
              filterDisplayName: 'Transport Type',
              type: 'transport',
              index: 0,
            });
          }
          if (decryptFilter.shipper.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 2,
              filterDisplayName: 'Shipper',
              type: 'shipper',
              index: 2,
            });
          }
          if (decryptFilter.consignee.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 3,
              filterDisplayName: 'Consignee',
              type: 'consignee',
              index: 3,
            });
          }
          if (decryptFilter.controllingCustomer.selectedAccount.length > 0) {
            decryptFilter.mainFilter.push({
              id: 8,
              filterDisplayName: 'Controlling Customer',
              type: 'controllingcustomer',
              index: 8,
            });
          }
          if (
            decryptFilter.allLocation.selectedAccount[0].origin.length > 0 ||
            decryptFilter.allLocation.selectedAccount[1].destination.length >
            0 ||
            decryptFilter.allLocation.selectedAccount[2].pickup.length > 0 ||
            decryptFilter.allLocation.selectedAccount[3].finalDelivery.length >
            0
          ) {
            decryptFilter.mainFilter.push({
              id: 4,
              filterDisplayName: 'Location',
              type: 'location',
              index: 4,
            });
          }
          if (decryptFilter.dateRange.selectedDateRange.length > 0) {
            decryptFilter.mainFilter.push({
              id: 5,
              filterDisplayName: 'Date Range',
              type: '',
              index: 5,
            });
          }

          if (Object.keys(decryptFilter.exceptionType).length > 0) {
            decryptFilter.mainFilter.push({
              id: 6,
              filterDisplayName: 'Exception Type',
              type: 'exception',
              index: 6,
            });
          }
          if (Object.keys(decryptFilter.watchFilter).length > 0) {
            this.saveFilterJson.mainFilter.push({
              id: 7,
              filterDisplayName: 'Watchlist',
              type: '',
              index: 7,
            });
          }
        }
        if (
          decryptFilter.allLocation.selectedAccount[0].origin.length > 0 &&
          Object.keys(decryptFilter.allLocation.selectedValue[0].originValue)
            .length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[0].origin = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[1].destination.length > 0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[1].destinationValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[1].destination = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[2].pickup.length > 0 &&
          Object.keys(decryptFilter.allLocation.selectedValue[2].pickupValue)
            .length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[2].pickup = [];
        }
        if (
          decryptFilter.allLocation.selectedAccount[3].finalDelivery.length >
          0 &&
          Object.keys(
            decryptFilter.allLocation.selectedValue[3].finalDeliveryValue
          ).length <= 0
        ) {
          decryptFilter.allLocation.selectedAccount[3].finalDelivery = [];
        }
        setTimeout(() => {
          this.customFilterComponent.loadURLFilters(decryptFilter);
        }, 500);
        setTimeout(() => {
          getPatameter = this.prepareApiBodyParams();
          this.filterCheckTemp = getPatameter.bodyParam;
        }, 500);
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
      }, 300);
    } else if (event.type == 'view' && userSettings[0]?.isDefaultFilter) {
      this.defaultFilterSelectedValue = -1;
      this.customFilterComponent.clearAllFilters();
      this.customFilterComponent.clearMobileFilters();
      setTimeout(() => {
        let decryptFilter = JSON.parse(event.userFilterRow.jsonFilter);
        this.loadURLFilters(decryptFilter);
      }, 300);
    } else if (
      event.type == 'update' &&
      this.currentUserData.userType === 'systemadmin'
    ) {
      if (this.combinedSelectedFiltersArray.length > 0) { // commented not to reset while going edit mode
        // this.combinedSelectedFiltersArray = [];
        // this.defaultFilterSelectedValue = 0;
        // this.customFilterComponent.clearAllFilters();
        // this.customFilterComponent.clearMobileFilters();
        // this.clearAllFilterValue('');
      }
      setTimeout(() => {
        getPatameter = this.prepareApiBodyParams();
        this.filterCheckTemp = [];
        this.myFilterEdit = {
          savedValue: event.userFilterRow,
          isEditTrue: true,
        };
        setTimeout(() => {
          this.customFilterComponent.setUpdateFilterName();
        }, 200);
      }, 300);
    }
  }

  loadURLFilters(filterURl: any) {
    let filterObj = {
      advancedFilter: filterURl?.advancedFilter,
      status: filterURl.statusFilter,
      container: filterURl.containerType ? filterURl.containerType : {},
      transport: filterURl.transportType,
      exception: filterURl.exceptionType,
      origin: filterURl.allLocation?.selectedValue[0]?.originValue,
      destination: filterURl.allLocation?.selectedValue[1]?.destinationValue,
      dateRange: filterURl.dateRange,
      shipper: filterURl.shipper,
      consignee: filterURl.consignee,
      controllingCustomer: filterURl.controllingCustomer
        ? filterURl.controllingCustomer
        : {
          selectedValue: [],
          selectedAccount: [],
        },
      pickUp: filterURl.allLocation?.selectedValue[2]?.pickupValue,
      finalDelivery:
        filterURl.allLocation?.selectedValue[3]?.finalDeliveryValue,
      allLocation: filterURl.allLocation,
      origincountry: filterURl.allLocation?.selectedValue[4]?.originCountryValue
        ? filterURl.allLocation?.selectedValue[4]?.originCountryValue
        : [],
      destinationcountry: filterURl.allLocation?.selectedValue[5]
        ?.destinationCountryValue
        ? filterURl.allLocation?.selectedValue[5]?.destinationCountryValue
        : [],
    };
    let defaultFilter = {
      advancedFilter: filterURl?.advancedFilter,
      status: filterObj.status,
      exception: filterObj.exception,
      transport: filterObj.transport,
      container: filterObj.container,
      origin: filterObj.origin,
      originCountry: filterObj.origincountry,
      destination: filterObj.destination,
      destinationCountry: filterObj.destinationcountry,
      dateRange: filterObj.dateRange.selectedValue,
      mainfilter: filterURl.mainFilter,
      watchFilter: filterURl.watchFilter,
      shipmentId: filterURl.shipmentId,
      consignee: filterObj.consignee.selectedValue,
      controllingCustomer: filterObj.controllingCustomer?.selectedValue,
      shipper: filterObj.shipper.selectedValue,
      pickUp: filterObj.pickUp,
      finalDelivery: filterObj.finalDelivery,
      shipperAccount: filterObj.shipper.selectedAccount,
      consigneeAccount: filterObj.consignee.selectedAccount,
      controllingCustomerAccount:
        filterObj.controllingCustomer?.selectedAccount,
      originAccount: filterURl.allLocation?.selectedAccount[0]?.origin,
      originCountryAccount: filterURl.allLocation?.selectedAccount[4]
        ?.originCountry
        ? filterURl.allLocation?.selectedAccount[4]?.originCountry
        : [],
      destinationAccount:
        filterURl.allLocation?.selectedAccount[1]?.destination,
      destinationCountryAccount: filterURl.allLocation?.selectedAccount[5]
        ?.destinationCountry
        ? filterURl.allLocation?.selectedAccount[5]?.destinationCountry
        : [],
      pickUpAccount: filterURl.allLocation?.selectedAccount[2]?.pickup,
      finalDeliveryAccount:
        filterURl.allLocation?.selectedAccount[3]?.finalDelivery,
    };
    this.getAllURLFilter(defaultFilter);
  }

  getUserFilters(event: any) {
    if (event.message && event.type != 'delete') {
      this.isShowMessage = true;
      this.showMessage = event.message;
    } else if (event.message && event.type == 'delete') {
      this.isShowMessageDel = true;
      this.showMessageDel = event.message;
    }

    this.shipmentService
      .getUserFilter(
        this.currentUserData.emailAddress,
        this.currentAccountData.accountId,
        this.currentAccountData.isGroup
      )
      .subscribe((res: any) => {
        this.savedFiltersList = [];
        this.savedFiltersList = this.sortByFilterNameName(res.result);
        this.myFilterEdit = '';
        if (event.type == 'save' || event.type == 'update'
          || event.type == 'filter-added-from-systemFilter') {
          let elementPos = res.result.filter(
            (x: any) => x.filterName == event.filterName
          );
          this.savedFilterRow = elementPos;
          this.systemFilterRow = '';
        }
        else {
          this.savedFilterRow = '';
          this.systemFilterRow = '';
        }
      });
    this.clearToaster();
  }

  sortByFilterNameName(collection: any) {
    if (collection?.length > 0) {
      return collection.sort((a: any, b: any) =>
        a?.filterName.localeCompare(b?.filterName)
      );
    }
    else {
      return [];
    }
  }

  sortByOrder(collection: any) {
    if (collection?.length > 0) {
      return collection.sort((a: any, b: any) => {
        // Invade null and empty string, Convert string to number and compare
        let numA = (a?.displayOrder !== null) ? parseInt(a.displayOrder, 10) : 100;
        let numB = (b?.displayOrder !== null) ? parseInt(b.displayOrder, 10) : 100;

        if (numA < numB) return -1;
        if (numA > numB) return 1;

        return 0;
      });
    }
    else {
      return [];
    }
  }

  getSystemFilters(event: any) {
    this.hasDefaultFilterDefined = false;
    if (event.message && event.type != 'delete') {
      this.isShowMessage = true;
      this.showMessage = event.message;
    } else if (event.message && event.type == 'delete') {
      this.isShowMessageDel = true;
      this.showMessageDel = event.message;
    }

    this.shipmentService
      .getSystemFilter(
        this.currentUserData.emailAddress,
        this.currentAccountData.accountId,
        this.currentAccountData.isGroup
      )
      .subscribe((res: any) => {
        if (
          this.systemFilterDefault &&
          !this.systemFilterDefault?.isDefaultFilter &&
          !this.systemFilterDefault?.isDisplayFilterSection
        ) {
          res?.result[0]?.filterusers.forEach((x: any) => {
            if (x.userEmail === this.currentUserData.emailAddress) {
              x.isDefaultFilter = true;
            }
          });
        }
        this.systemFiltersList = this.sortByOrder(res.result);
        this.myFilterEdit = '';
        if (event.type == 'save' || event.type == 'update') {
          let elementPos = res.result.filter(
            (x: any) => x.filterName == event.filterName
          );
          this.systemFilterRow = elementPos;
          this.savedFilterRow = '';
          this.defaultFilterSelectedValue = -1;
        } else if (
          this.currentUserData.userType == 'user' &&
          event.type == 'start'
        ) {
          if (
            this.currentAccountData.isGroup &&
            this.systemFilterDefault &&
            !this.systemFilterDefault?.isDisplayFilterSection
          ) {
            setTimeout(() => {
              res?.result[0]?.filterusers.forEach((x: any) => {
                if (x.userEmail === this.currentUserData.emailAddress) {
                  x.isDefaultFilter = true;
                }
              });
              this.systemFilterRow = [res?.result[0]];
              this.systemFilterFetch({
                userFilterRow: res?.result[0],
                type: 'view',
              });
            }, 200);
          }
          if (
            this.systemFilterDefault &&
            !this.systemFilterDefault?.isDefaultFilter &&
            !this.systemFilterDefault?.isDisplayFilterSection
          ) {
            setTimeout(() => {
              this.systemFilterRow = [res?.result[0]];
              this.systemFilterFetch({
                userFilterRow: res?.result[0],
                type: 'view',
              });
            }, 200);
          } else if (
            this.systemFilterDefault &&
            this.systemFilterDefault?.isDefaultFilter &&
            !this.currentAccountData.isGroup
          ) {
            let alreadySelected = false;
            res?.result?.forEach((x: any) => {
              x.filterusers.forEach((el: any) => {
                if (
                  el.userEmail == this.currentUserData.emailAddress &&
                  el.isDefaultFilter && !alreadySelected
                ) {
                  this.systemFilterRow = [x];
                  this.hasDefaultFilterDefined = true;
                  alreadySelected = true;
                }
              });
            });
          } else {
            this.defaultFilterSelectedValue = 0;
            this.hasDefaultFilterDefined = false;
          }
        } else {
          this.systemFilterRow = '';
          this.savedFilterRow = '';
        }
      });

    this.clearToaster();
  }

  getSystemFilterDefault() {
    this.shipmentService
      .getSystemFilterDetails(
        this.currentUserData.emailAddress,
        this.currentAccountData.accountId
      )
      .subscribe((res: any) => {
        this.systemFilterDefault = res.result;
      });
  }

  clearToaster() {
    setTimeout(() => {
      this.isShowMessage = false;
      this.showMessage = '';
      this.isShowMessageDel = false;
      this.showMessageDel = '';
    }, 5000);
  }

  customFilterApiLoader(event: any) {
    this.isProgressShow = event;
  }

  undoDeleteUserFilter(event: any) {
    if (event) {
      setTimeout(() => {
        this.customFilterComponent.undoFilterChanges();
        this.isShowMessageDel = false;
        this.showMessageDel = '';
      }, 200);
    }
  }

  selectedListShipment(shipmentId: any) {
    this.appInsightsService.logPageView(
      AI_PageName.ShipmentDetail,
      window.location.href,
      {
        'Shipment Id': shipmentId,
      }
    );
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.shipmentService
      .getShipmentsDetails(accountId, emailId, shipmentId, isGroup)
      .subscribe({
        next: (getDetails: any) => {
          this.isProgressShow = false;
          this.getShipmentDetails = getDetails.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
        },
      });
  }

  singleShareApply(urlFilter: any) {
    this.singleShipment(urlFilter.shipmentId, urlFilter);
    setTimeout(() => {
      this.popupToggle = true;
    }, 0);
    this.urlShipmentId = urlFilter.shipmentId;
  }

  singleShipment(shipmentId: any, urlFilter: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let isParent = this.currentAccountData.isParent;
    this.shipmentService
      .getShipmentsDetails(accountId, emailId, shipmentId, isGroup)
      .subscribe({
        next: (getDetails: any) => {
          if (getDetails.result && getDetails.result.length > 0) {
            this.isProgressShow = false;
            this.getShipmentDetails = getDetails.result[0];
            this.selectedShipmentLen = Object.keys(
              this.getShipmentDetails
            ).length;
          } else {
            let bodyParam = {
              offset: this.page,
              fetch: this.limit,
              search_text: '',
              parameters: [
                {
                  name: 'shipmentNumber',
                  value: shipmentId,
                },
              ],
              sort_options: [
                {
                  column_name: this.defaultSortSelectedColumn,
                  direction: this.defaultSortDirection,
                },
              ],
              filter_options: [],
            };
            this.shipmentService
              .publicShare(accountId, emailId, bodyParam, isGroup, isParent)
              .subscribe((res: any) => {
                this.isProgressShow = false;
                setTimeout(() => {
                  this.getShipmentDetails = res.result[0];
                  this.selectedShipmentLen = Object.keys(
                    this.getShipmentDetails
                  ).length;
                  if (!res.result[0].isFullViewAccess) {
                    this.publicShareFilter = urlFilter;
                  }
                }, 100);
              });
          }
        },
      });
  }

  sharePopUpfeatureHide() {
    this.singleShipmentShare = false;
    this.detailShipmentShare = false;
    this.popupToggleList = false;
  }

  eDocfeatureHide() {
    this.edocUploadTigger = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  detailShare(event: any) {
    this.detailShipmentShare = true;
    this.popupToggleList = true;

    this.prepareShareUrl(event);
  }

  mainFilterValueCheck() {
    if (this.saveFilterJson.mainFilter.length <= 0) {
      if (Object.keys(this.saveFilterJson.statusFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 1,
          filterDisplayName: 'Status',
          type: 'statuscustom',
          index: 1,
        });
      }
      if (Object.keys(this.saveFilterJson.transportType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 0,
          filterDisplayName: 'Transport Type',
          type: 'transport',
          index: 0,
        });
      }
      if (this.saveFilterJson.shipper.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 2,
          filterDisplayName: 'Shipper',
          type: 'shipper',
          index: 2,
        });
      }
      if (this.saveFilterJson.consignee.selectedAccount.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 3,
          filterDisplayName: 'Consignee',
          type: 'consignee',
          index: 3,
        });
      }
      if (
        this.saveFilterJson.allLocation.selectedAccount[0].origin.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[1].destination.length >
        0 ||
        this.saveFilterJson.allLocation.selectedAccount[2].pickup.length > 0 ||
        this.saveFilterJson.allLocation.selectedAccount[3].finalDelivery
          .length > 0
      ) {
        this.saveFilterJson.mainFilter.push({
          id: 4,
          filterDisplayName: 'Location',
          type: 'location',
          index: 4,
        });
      }
      if (this.saveFilterJson.dateRange.selectedDateRange.length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 5,
          filterDisplayName: 'Date Range',
          type: '',
          index: 5,
        });
      }

      if (Object.keys(this.saveFilterJson.exceptionType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 6,
          filterDisplayName: 'Exception Type',
          type: 'exception',
          index: 6,
        });
      }
      if (Object.keys(this.saveFilterJson.containerType).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 9,
          filterDisplayName: 'Container Type',
          type: 'containertype',
          index: 9,
        });
      }
      if (Object.keys(this.saveFilterJson.watchFilter).length > 0) {
        this.saveFilterJson.mainFilter.push({
          id: 7,
          filterDisplayName: 'Watchlist',
          type: '',
          index: 7,
        });
      }
    }
  }

  removeAllAppliedFilters() {
    this.myFilterEdit = { // added for removing savebtn visibility
      savedValue: '',
      isEditTrue: false,
    };

    if (this.customFilterComponent) {
      this.customFilterComponent.clearAllFilters();
    }

    this.statusFilterSelectedArray = [];
    this.defaultFilterArray = [];
    this.transportTypeFilterSelectedArray = [];
    this.containerTypeFilterSelectedArray = [];
    this.exceptionTypeFilterSelectedArray = [];
    this.originFilterSelectedArray = [];
    this.shipperFilterSelectedArray = [];
    this.consigneeFilterSelectedArray = [];
    this.ControllingCustomerFilterSelectedArray = [];
    this.finalDeliveryFilterSelectedArray = [];
    this.originCountryFilterSelectedArray = [];
    this.destinationCountryFilterSelectedArray = [];
    this.dateRangeFilterSelectedArray = [];
    this.watchListFilterSelectedArray = [];
    this.advancedFilterValue = [];
    this.parameters = [];
  }

  globalQuickSearch(value: any) {
    this.removeAllAppliedFilters();
    let decryGlobalSearch = JSON.parse(atob(value));

    if (decryGlobalSearch.statusFilter.length > 0) {
      this.location.replaceState('/shipments');
      if (this.statusFilterSelectedArray.length > 0) {
        this.setCloseButtonTimeout('quick');
      }
      this.setUrlFilters(decryGlobalSearch, false);
      setTimeout(() => {
        let statusManual: any[] = [];
        statusManual.push({
          column_name: 'statuscustom',
          operator: '=',
          value: decryGlobalSearch.statusFilter[0].value,
        });
        let statusSelect = {
          type: 'statuscustom',
          selectedValue: statusManual,
        };
        this.onSelectedCustomFilter(statusSelect);
        setTimeout(() => {
          this.customFilterComponent.saveAsButtonVisibility();
        }, 0);
      }, 1000);
      this.dataSharingService.quickSearch.next(null);
    }
  }

  publicShareLimitedApiUser(filters: any, encryptedFilter: any) {
    if (filters.type == 'Detail') {
      let getPatameter = this.prepareApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'shipmentNumber',
            value: filters.shipmentId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultSortSelectedColumn,
            direction: this.defaultSortDirection,
          },
        ],
        filter_options: [],
      };
      this.shipmentService
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.getShipmentDetails = res.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
          this.filterService
            .getData(
              environment.base_api_desktop_url +
              'useraccount?userEmailAddresss=' +
              this.currentUserData.emailAddress
            )
            .subscribe((response) => {
              this.isUserHaveAcc = response.result
                .map(function (x: any) {
                  return x.oH_Code;
                })
                .indexOf(this.getShipmentDetails.accountId);
              if (this.isUserHaveAcc >= 0) {
                let accountDetail = {
                  aliasName: this.getShipmentDetails.account_alias_name,
                  oH_Code: this.getShipmentDetails.accountId,
                  byGroup: false,
                  isControllingCustomer: false,
                  shipment: {
                    isVisible:
                      this.getShipmentDetails?.account_shipment?.isVisible,
                  },
                  booking: {
                    isVisible:
                      this.getShipmentDetails?.account_booking?.isVisible,
                  },
                  customs: {
                    isVisible:
                      this.getShipmentDetails?.account_customs?.isVisible,
                  },
                };
                const isSwitchAccount = this.switchAccount.switchAccount(
                  accountDetail,
                  'shareUrlShipment',
                  encryptedFilter
                );
                if (!isSwitchAccount) {
                  if (filters.type == 'All' || filters.type == 'Detail') {
                    this.urlFilterCall(filters);
                  }
                }
              } else {
                if (filters.type == 'Detail') {
                  this.urlFilterCall(filters);
                }
              }
            });
        });
    } else if (filters.type == 'All') {
      let groupCount: any = localStorage.getItem('groupTotalCount') || 0;
      let totalGroup: number;
      if (parseInt(groupCount) > 0) {
        totalGroup = parseInt(groupCount);
      } else {
        totalGroup = 0;
      }
      if (totalGroup > 0) {
        this.groupSwitching(filters, encryptedFilter);
      } else {
        this.userService
          .getUserDetail(this.currentUserData.emailAddress)
          .subscribe((accountRes: any) => {
            let accountDetail = {
              aliasName: accountRes.result.accountUserRole.alias_name,
              oH_Code: accountRes.result.accountUserRole.accountId,
              byGroup: false,
              isControllingCustomer: false,
              shipment: { isVisible: false },
              booking: { isVisible: false },
              customs: { isVisible: false },
            };

            this.isUserHaveAcc = accountRes.result;
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlShipment',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              this.urlFilterCall(filters);
            }
          });
      }
    }
  }

  publicShareLimitedApiAdmin(filters: any, encryptedFilter: any) {
    if (filters.type == 'Detail') {
      let getPatameter = this.prepareApiBodyParams();
      let bodyParam = {
        offset: this.page,
        fetch: this.limit,
        search_text: '',
        parameters: [
          {
            name: 'shipmentNumber',
            value: filters.shipmentId,
          },
        ],
        sort_options: [
          {
            column_name: this.defaultSortSelectedColumn,
            direction: this.defaultSortDirection,
          },
        ],
        filter_options: [],
      };
      this.shipmentService
        .publicShare(
          filters.accountDetail.oH_Code,
          getPatameter.emailId,
          bodyParam,
          filters.accountDetail.byGroup,
          filters.accountDetail.isControllingCustomer
        )
        .subscribe((res: any) => {
          this.getShipmentDetails = res.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
          if (res.result.length > 0) {
            let accountDetail = {
              aliasName: this.getShipmentDetails.account_alias_name,
              oH_Code: this.getShipmentDetails.accountId,
              byGroup: false,
              isControllingCustomer: false,
              shipment: {
                isVisible: this.getShipmentDetails?.account_shipment?.isVisible,
              },
              booking: {
                isVisible: this.getShipmentDetails?.account_booking?.isVisible,
              },
              customs: {
                isVisible: this.getShipmentDetails?.account_customs?.isVisible,
              },
            };
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlShipment',
              encryptedFilter
            );
            setTimeout(() => {
              if (!isSwitchAccount) {
                this.urlFilterCall(filters);
              }
            }, 100);
          }
        });
    } else if (filters.type == 'All') {
      let groupCount: any = localStorage.getItem('groupTotalCount') || 0;
      let totalGroup: number;
      if (parseInt(groupCount) > 0) {
        totalGroup = parseInt(groupCount);
      } else {
        totalGroup = 0;
      }
      if (totalGroup > 0) {
        this.groupSwitching(filters, encryptedFilter);
      } else {
        this.filterService
          .getAutoCompleteSearchRecords('', 0, 1)
          .subscribe((accountRes: any) => {
            let accountDetail = {
              aliasName: accountRes.result[0].aliasName,
              oH_Code: accountRes.result[0].oH_Code,
              byGroup: false,
              isControllingCustomer: false,
              shipment: {
                isVisible: accountRes?.result[0]?.shipment?.isVisible,
              },
              booking: {
                isVisible: accountRes?.result[0]?.booking?.isVisible,
              },
              customs: {
                isVisible: accountRes?.result[0]?.custom?.isVisible,
              },
            };
            this.isUserHaveAcc = accountRes.result;
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              'shareUrlShipment',
              encryptedFilter
            );
            if (!isSwitchAccount) {
              this.urlFilterCall(filters);
            }
          });
      }
    }
  }

  publicShareLimitedApi(filters: any) {
    let getPatameter = this.prepareApiBodyParams();
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: filters.shipmentId,
        },
      ],
      sort_options: [
        {
          column_name: this.defaultSortSelectedColumn,
          direction: this.defaultSortDirection,
        },
      ],
      filter_options: [],
    };
    this.shipmentService
      .publicShare(
        filters.accountDetail.oH_Code,
        getPatameter.emailId,
        bodyParam,
        filters.accountDetail.byGroup,
        filters.accountDetail.isControllingCustomer
      )
      .subscribe((res: any) => {
        this.getShipmentDetails = res.result[0];
        this.selectedShipmentLen = Object.keys(this.getShipmentDetails).length;
      });
  }

  encryptData(encryptedFilter: any) {
    if (encryptedFilter) {
      var decryUrlFilter = JSON.parse(atob(encryptedFilter));
      if (decryUrlFilter.type == 'Single' && this.currentUserData) {
        this.shipmentService.openActionTab = decryUrlFilter?.isActionFlag;
        this.isDetailFullView = true;
        this.location.replaceState('/shipments');
        if (this.currentUserData.userType == 'user') {
          this.switchAccountUser(decryUrlFilter, encryptedFilter);
        } else if (this.currentUserData.userType == 'systemadmin') {
          this.switchAccountAdmin(decryUrlFilter, encryptedFilter);
        }
        this.singleShareApply(decryUrlFilter);
      } else if (decryUrlFilter.type == 'All' && this.currentUserData) {
        this.shipmentService
          .getShareFilter(decryUrlFilter.id)
          .subscribe((res: any) => {
            let decryptFilter = JSON.parse(res.result.jsonFilter);
            if (decryptFilter.accountDetail.byGroup) {
              this.isProgressShow = true;
            }
            if (decryptFilter?.statusFilter[0]?.value?.length === 1) {
              this.defaultFilterSelectedValue =
                decryptFilter?.statusFilter[0]?.value;
            }
            this.location.replaceState('/shipments');
            this.setUrlFilters(decryptFilter, encryptedFilter);
          });
      } else if (
        (decryUrlFilter.type == 'Detail' &&
          decryUrlFilter.type != 'Single' &&
          decryUrlFilter.type != 'viewAll' &&
          decryUrlFilter.type != 'All') ||
        this.currentUserData
      ) {
        if (decryUrlFilter.type == 'Detail') {
          this.isDetailFullView = true;
        }
        this.location.replaceState('/shipments');
        this.publicShareEnrcyptedData = encryptedFilter;
        this.setUrlFilters(decryUrlFilter, encryptedFilter);
      } else {
        this.router.navigate(['/login/log-in'], {
          queryParams: { returnUrl: '/shipments/' + encryptedFilter },
        });
      }
    } else if (decryUrlFilter.type == 'viewAll' && this.currentUserData) {
      this.setUrlFilters(decryUrlFilter, encryptedFilter);
    }
  }

  public apiCancel() {
    if (this.apiCall != null) {
      this.apiCall.unsubscribe();
      this.shipmentService.stopRequest.next();
    }
  }

  async setGroupUrlFilterCriteria(filters: any) {
    let searchText = '';
    let offset = 0;
    let accountID = this.currentAccountData.accountId;
    let userEmail = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let fetch = 1000;
    if (filters.allLocation.selectedAccount[0].origin.length > 0) {
      await this.shipmentService
        .getOriginFilter(
          searchText,
          offset,
          'origin',
          accountID,
          userEmail,
          isGroup,
          fetch,
          'rL_PortName'
        )
        .toPromise()
        .then((res: any) => {
          let originFinalVal: any[] = [];
          let originValueConvert: any[] = [];
          let originValueFinalConvert = '';
          let origin = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[0].origin.some(
              (o2: any) =>
                o1.rL_RN_NKCountryCode === o2.rL_RN_NKCountryCode &&
                o1.rL_Code === o2.rL_Code
            )
          );
          let originSpliedVal =
            filters.allLocation.selectedValue[0].originValue.value.split('|');
          originSpliedVal.forEach((ele: any) => {
            let tempOrg = ele.split(',');
            originFinalVal.push({
              rL_RN_NKCountryCode: tempOrg[0],
              rL_Code: tempOrg[1],
            });
          });
          let originFilter = originFinalVal.filter((val: any) =>
            res.result.some(
              (acc: any) =>
                acc.rL_RN_NKCountryCode === val.rL_RN_NKCountryCode &&
                acc.rL_Code === val.rL_Code
            )
          );
          originFilter.forEach((e: any) => {
            originValueConvert.push(e.rL_RN_NKCountryCode + ',' + e.rL_Code);
            originValueFinalConvert = originValueConvert
              .map((x) => x)
              .join('|');
          });
          if (originValueFinalConvert.length > 0) {
            filters.allLocation.selectedValue[0].originValue.value =
              originValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[0].originValue = [];
          }

          filters.allLocation.selectedAccount[0].origin = origin;
        });
    }
    if (filters.allLocation.selectedAccount[1].destination.length > 0) {
      await this.shipmentService
        .getOriginFilter(
          searchText,
          offset,
          'destination',
          accountID,
          userEmail,
          isGroup,
          fetch,
          'rL_PortName'
        )
        .toPromise()
        .then((res: any) => {
          let destinationFinalVal: any[] = [];
          let destinationValueConvert: any[] = [];
          let destinationValueFinalConvert = '';
          let destination = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[1].destination.some(
              (o2: any) =>
                o1.rL_RN_NKCountryCode === o2.rL_RN_NKCountryCode &&
                o1.rL_Code === o2.rL_Code
            )
          );
          let destinationSpliedVal =
            filters.allLocation.selectedValue[1].destinationValue.value.split(
              '|'
            );
          destinationSpliedVal.forEach((ele: any) => {
            let tempDest = ele.split(',');
            destinationFinalVal.push({
              rL_RN_NKCountryCode: tempDest[0],
              rL_Code: tempDest[1],
            });
          });
          let destinationFilter = destinationFinalVal.filter((val: any) =>
            res.result.some(
              (acc: any) =>
                acc.rL_RN_NKCountryCode === val.rL_RN_NKCountryCode &&
                acc.rL_Code === val.rL_Code
            )
          );
          destinationFilter.forEach((e: any) => {
            destinationValueConvert.push(
              e.rL_RN_NKCountryCode + ',' + e.rL_Code
            );
            destinationValueFinalConvert = destinationValueConvert
              .map((x) => x)
              .join('|');
          });
          if (destinationValueFinalConvert.length > 0) {
            filters.allLocation.selectedValue[1].destinationValue.value =
              destinationValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[1].destinationValue = [];
          }
          filters.allLocation.selectedAccount[1].destination = destination;
        });
    }
    if (filters.allLocation.selectedAccount[2].pickup.length > 0) {
      await this.shipmentService
        .getPickupFilter(
          searchText,
          offset,
          accountID,
          userEmail,
          isGroup,
          fetch
        )
        .toPromise()
        .then((res: any) => {
          let pickupFinalVal: any[] = [];
          let pickupValueConvert: any[] = [];
          let pickupValueFinalConvert = '';
          let pickup = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[2].pickup.some(
              (o2: any) =>
                o1.source_party_id === o2.source_party_id &&
                o1.city === o2.city &&
                o1.countryCode === o2.countryCode
            )
          );
          let pickupSpliedVal =
            filters.allLocation.selectedValue[2].pickupValue.value.split('|');
          pickupSpliedVal.forEach((ele: any) => {
            let tempPick = ele.split(',');
            pickupFinalVal.push({
              source_party_id: tempPick[0],
              city: tempPick[1],
              countryCode: tempPick[2],
            });
          });
          let pickupFilter = pickupFinalVal.filter((val: any) =>
            res.result.some(
              (acc: any) =>
                acc.source_party_id === val.source_party_id &&
                acc.city === val.city &&
                acc.countryCode === val.countryCode
            )
          );
          pickupFilter.forEach((e: any) => {
            pickupValueConvert.push(
              e.source_party_id + ',' + e.city + ',' + e.countryCode
            );
            pickupValueFinalConvert = pickupValueConvert
              .map((x) => x)
              .join('|');
          });
          if (pickupValueFinalConvert.length > 0) {
            filters.allLocation.selectedValue[2].pickupValue.value =
              pickupValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[2].pickupValue = [];
          }
          filters.allLocation.selectedAccount[2].pickup = pickup;
        });
    }
    if (filters.allLocation.selectedAccount[3].finalDelivery.length > 0) {
      await this.shipmentService
        .getFinalDeliveryFilter(
          searchText,
          offset,
          accountID,
          userEmail,
          isGroup,
          fetch
        )
        .toPromise()
        .then((res: any) => {
          let finalDeliveryFinalVal: any[] = [];
          let finalDeliveryValueConvert: any[] = [];
          let finalDeliveryValueFinalConvert = '';
          let finalDelivery = res.result.filter((o1: any) =>
            filters.allLocation.selectedAccount[3].finalDelivery.some(
              (o2: any) =>
                o1.source_party_id === o2.source_party_id &&
                o1.city === o2.city &&
                o1.countryCode === o2.countryCode
            )
          );
          let finalDeliverySpliedVal =
            filters.allLocation.selectedValue[3].finalDeliveryValue.value.split(
              '|'
            );
          finalDeliverySpliedVal.forEach((ele: any) => {
            let tempFinalDeli = ele.split(',');
            finalDeliveryFinalVal.push({
              source_party_id: tempFinalDeli[0],
              city: tempFinalDeli[1],
              countryCode: tempFinalDeli[2],
            });
          });
          let finalDeliveryFilter = finalDeliveryFinalVal.filter((val: any) =>
            res.result.some(
              (acc: any) =>
                acc.source_party_id === val.source_party_id &&
                acc.city === val.city &&
                acc.countryCode === val.countryCode
            )
          );
          finalDeliveryFilter.forEach((e: any) => {
            finalDeliveryValueConvert.push(
              e.source_party_id + ',' + e.city + ',' + e.countryCode
            );
            finalDeliveryValueFinalConvert = finalDeliveryValueConvert
              .map((x) => x)
              .join('|');
          });
          if (finalDeliveryValueFinalConvert) {
            filters.allLocation.selectedValue[3].finalDeliveryValue.value =
              finalDeliveryValueFinalConvert;
          } else {
            filters.allLocation.selectedValue[3].finalDeliveryValue = [];
          }
          filters.allLocation.selectedAccount[3].finalDelivery = finalDelivery;
        });
    }

    if (filters.consignee.selectedAccount.length > 0) {
      await this.shipmentService
        .getConsigneeFilter(searchText, 0, accountID, userEmail, isGroup, fetch)
        .toPromise()
        .then((res: any) => {
          let consignee = res.result.filter((o1: any) =>
            filters.consignee.selectedAccount.some(
              (o2: any) =>
                o1.name === o2.name && o1.target_party_id === o2.target_party_id
            )
          );
          let consigneeFilter = filters.consignee.selectedValue.value
            .split(',')
            .filter((val: any) =>
              res.result.some((acc: any) => acc.target_party_id === val)
            );
          filters.consignee.selectedAccount = consignee;
          if (consigneeFilter.toString().length > 0) {
            filters.consignee.selectedValue.value = consigneeFilter.toString();
          } else {
            filters.consignee.selectedValue = [];
          }
        });
    }
    if (filters.shipper.selectedAccount.length > 0) {
      await this.shipmentService
        .getShipperFilter(searchText, 0, accountID, userEmail, isGroup, fetch)
        .toPromise()
        .then((res: any) => {
          let shipper = res.result.filter((o1: any) =>
            filters.shipper.selectedAccount.some(
              (o2: any) =>
                o1.name === o2.name && o1.target_party_id === o2.target_party_id
            )
          );
          let shipperFilter = filters.shipper.selectedValue.value
            .split(',')
            .filter((val: any) =>
              res.result.some((acc: any) => acc.target_party_id === val)
            );
          filters.shipper.selectedAccount = shipper;
          if (shipperFilter.toString().length > 0) {
            filters.shipper.selectedValue.value = shipperFilter.toString();
          } else {
            filters.shipper.selectedValue = [];
          }
        });
    }
    this.urlFilterSort(filters);
    setTimeout(() => {
      this.customFilterComponent.loadURLFilters(filters);
    }, 500);
  }

  groupSwitching(filters: any, encryptedFilter: any) {
    this.myGroupService
      .getGroupName(this.currentUserData.emailAddress)
      .subscribe((res: any) => {
        this.isUserHaveAcc = res.result
          .map(function (x: any) {
            return x.groupName.trim().toLowerCase();
          })
          .indexOf(filters.accountDetail.aliasName.trim().toLowerCase());
        let switchGroup = res.result.filter(
          (x: any) =>
            x.groupName.trim().toLowerCase() ==
            filters.accountDetail.aliasName.trim().toLowerCase()
        );

        if (this.isUserHaveAcc >= 0) {
          let accountDetail2 = {
            aliasName: switchGroup[0].groupName,
            oH_Code: switchGroup[0].id,
            byGroup: true,
            isControllingCustomer: false,
            shipment: { isVisible: false },
            booking: { isVisible: false },
            customs: { isVisible: false },
          };
          const isSwitchAccount = this.switchAccount.switchAccount(
            accountDetail2,
            'shareUrlShipment',
            encryptedFilter
          );
          if (!isSwitchAccount) {
            this.setGroupUrlFilterCriteria(filters);
          }
        } else {
          let accountDetail1 = {
            aliasName: res.result[0].groupName,
            oH_Code: res.result[0].id,
            byGroup: true,
            isControllingCustomer: false,
            shipment: { isVisible: false },
            booking: { isVisible: false },
            customs: { isVisible: false },
          };
          this.isUserHaveAcc = res.result;
          const isSwitchAccount = this.switchAccount.switchAccount(
            accountDetail1,
            'shareUrlShipment',
            encryptedFilter
          );
          if (!isSwitchAccount) {
            this.setGroupUrlFilterCriteria(filters);
          }
        }
      });
  }

  publicSearchFn(filters: any) {
    if (filters) {
      this.dataSharingService.notLoggedIn.next(true);
      this.isDetailFullView = true;
      this.popupToggle = true;
      this.urlShipmentId = filters.shipmentId;
      this.getShipmentDetails = filters.shipment;
      this.publicShareFilter = filters;
      let filter = {
        type: 'Detail',
        shipmentId: filters.shipmentId,
        accountDetail: {
          aliasName: filters.accountDetail.aliasName,
          oH_Code: filters.accountDetail.oH_Code,
          byGroup: false,
          shipment: { isVisible: false },
          booking: { isVisible: false },
          customs: { isVisible: false },
          isControllingCustomer: false,
        },
        subType: 'publicSearch',
      };
      this.publicShareFilter = filter;
      let encry = btoa(JSON.stringify(filter));
      this.publicShareEnrcyptedData = encry;
      this.selectedShipmentLen = Object.keys(this.getShipmentDetails).length;
    }
  }

  onRefreshDetailFromDetail(event: any) {
    this.isProgressShow = true;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (event.value == 'LOAD DETAILS') {
      if (this.currentAccountData && event.access) {
        let accountId = this.currentAccountData.accountId;
        let emailId = this.currentUserData.emailAddress
          ? this.currentUserData.emailAddress
          : '';
        let isGroup = this.currentAccountData.isGroup;

        this.shipmentService
          .getShipmentsDetails(accountId, emailId, event.shipmentId, isGroup)
          .subscribe({
            next: (getDetails: any) => {
              this.isProgressShow = false;
              this.dataSharingService.refreshShipmentDetailsInHeader.next(
                false
              );
              this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
                true
              );
              this.getShipmentDetails = getDetails.result[0];
            },
          });
      } else if (
        this.publicShareFilter.type === 'Detail' &&
        this.publicShareFilter.subType === 'publicSearch'
      ) {
        this.shipmentPublicSearchShare('refresh');
      } else {
        this.refreshPublicView();
      }
    } else {
      this.refreshPublicView();
    }
  }

  refreshPublicView() {
    let getPatameter = this.prepareApiBodyParams();
    let bodyParam = {
      offset: this.page,
      fetch: this.limit,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: this.publicShareFilter.shipmentId,
        },
      ],
      sort_options: [
        {
          column_name: this.defaultSortSelectedColumn,
          direction: this.defaultSortDirection,
        },
      ],
      filter_options: [],
    };
    let oH_Code = this.publicShareFilter?.accountDetail?.oH_Code
      ? this.publicShareFilter?.accountDetail?.oH_Code
      : this.currentAccountData?.accountId
        ? this.currentAccountData?.accountId
        : '';
    let byGroup = this.publicShareFilter?.accountDetail?.byGroup
      ? this.publicShareFilter?.accountDetail?.byGroup
      : this.currentAccountData?.isGroup
        ? this.currentAccountData?.isGroup
        : false;
    let isControllingCustomer = this.publicShareFilter?.accountDetail
      ?.isControllingCustomer
      ? this.publicShareFilter?.accountDetail?.isControllingCustomer
      : this.currentAccountData?.isParent
        ? this.currentAccountData?.isParent
        : false;
    this.shipmentService
      .publicShare(
        oH_Code,
        getPatameter.emailId,
        bodyParam,
        byGroup,
        isControllingCustomer
      )
      .subscribe((res: any) => {
        this.isProgressShow = false;
        this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
        this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
          true
        );
        setTimeout(() => {
          this.getShipmentDetails = res.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
        }, 0);
      });
  }

  shipmentPublicSearchShare(from: any) {
    if (this.publicShareFilter) {
      this.shipmentService
        .shipmentPublicSearch(this.publicShareFilter.shipmentId)
        .subscribe((x) => {
          if (x.result) {
            if (from === 'refresh') {
              this.dataSharingService.refreshShipmentDetailsInHeader.next(
                false
              );
              this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
                true
              );
            }
            this.isProgressShow = false;
            this.urlShipmentId = this.publicShareFilter.shipmentId;
            this.popupToggle = true;
            this.getShipmentDetails = x.result;
            this.selectedShipmentLen = Object.keys(
              this.getShipmentDetails
            ).length;
          }
        });
    }
  }

  trackAIPageView(isPublicView: boolean) {
    this.appInsightsService.logPageView(
      isPublicView ? AI_PageName.ShipmentsPublicView : AI_PageName.Shipment,
      window.location.href
    );
  }

  navigateToFaq() {
    this.router.navigate([]).then((result) => {
      window.open('/faqs', '_blank');
    });
  }

  setUrlDefaultFilter(e: any) {
    if (e.mainfilter.length > 0) {
      this.mainFilterSelectedArray.push(e.mainfilter[0]);
    }
  }

  successToastClose() {
    this.isShowMessage = false;
    this.showMessage = '';
    this.isShowMessageDel = false;
    this.showMessageDel = '';
  }

  clearMySavedSystem() {
    this.systemFilterRow = '';
    this.savedFilterRow = '';
    // this.defaultFilterSelectedValue = -1;
  }

  setStandardOrTableView(event: any) {
    this.clearData();
    this.isTableView = event;
    this.loadAllShipments();
  }

  setPickUpAndDeliveryToggle(event: any) {
    this.isPickupAndDelivery = event;
  }

  showAndAutoClearToasterMessage(event: any) {
    this.isShowMessage = true;
    this.showMessage = event?.message;
    let timeout = event?.timeout ? event.timeout : 3000;
    setTimeout(() => {
      this.isShowMessage = false;
      this.showMessage = '';
      this.isShowMessageDel = false;
      this.showMessageDel = '';
    }, timeout);
  }

  enableClearAllButton() {
    this.customFilterComponent.showClearAllButton = true;
  }

  disableClearAllButton() {
    this.customFilterComponent.showClearAllButton = false;
  }

  navigationType: string = "";
  callFilterWarningPopup(event: any) {
    if (event.type == "adv") {
      this.ShipmentListComponent.showFilterSwitchWarningPopup = true;
      this.ShipmentListComponent.filterSwitchHeaderMessage = "Custom Filters";
      this.ShipmentListComponent.filterSwitchWarningMessage = "Using Custom Filters will reset the applied Advanced Filters. Would you like to Proceed?"
      if (this.shipmentService.touchedCustomDropdown) {
        this.customFilterComponent.openCustomDropdownButton();
      }
    }
    else if (event.type == "custom") {
      this.ShipmentListComponent.showFilterSwitchWarningPopup = true;
      this.ShipmentListComponent.filterSwitchHeaderMessage = "Advanced Filters";
      this.ShipmentListComponent.filterSwitchWarningMessage = "Using Advanced Filters will reset the applied Custom Filters. Would you like to Proceed?"
    }
    this.navigationType = event.type;
  }

  openCustomDropDown() {
    setTimeout(() => {
      this.customFilterComponent.openCustomDropdownButton()
    }, 300)
  }

  openTransportRibbonMode() {
    setTimeout(() => {
      this.customFilterComponent.showModeDropDown = true;
    }, 300)
  }

  openWatchListRibbonMode() {
    setTimeout(() => {
      this.customFilterComponent.toggleWatchList()
    }, 300)
  }

  navigateToSelectedFilter() {
    if (this.navigationType == "adv") {
      if (this.shipmentService.touchedCustomDropdown) {
        this.openCustomDropDown();
        this.customFilterComponent.clearAllRibbonFilters(true);
      }
      else if (this.shipmentService.touchedTransportRibbonMode) {
        this.openTransportRibbonMode();
        this.customFilterComponent.clearAllRibbonFilters(true);
      }
      else if (this.shipmentService.touchedWatchlistRibbonMode) {
        this.openWatchListRibbonMode();
        this.customFilterComponent.clearAllRibbonFilters(true);
      }
      else {
        setTimeout(() => {
          this.shipmentService.advancedFilterSavedRecently = true;
          this.customFilterComponent.loadURLFilters(this.shipmentService.recentFilterUrl);
        }, 500);
      }
    }
    else if (this.navigationType == "custom") {
      this.customFilterComponent.showAdvancedFilterPopUp = true;
      this.customFilterComponent.clearAllRibbonFilters(true);
    }
  }

  denyProceedingFilterOption() {
    if (this.captureFilterName?.length > 0) {
      this.customFilterComponent.advFiltername = this.captureFilterName[this.captureFilterName?.length - 1];
    }
  }

  checkForDefaultOption(event: any) {  // written for getting default filter
    //if (this.mainFilterSelectedArray?.length == 0 && event) {
    if (this.currentUserData && this.currentUserData?.userType == "user") {
      this.getSystemFilters({ message: '', type: 'start' });
      return;
    }
    //}
  }

}
