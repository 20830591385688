import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TableViewService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public stopRequest: Subject<void> = new Subject<void>();
  public closeActionFieldForm: Subject<boolean> = new Subject<boolean>();
  public openActionFieldForm: Subject<boolean> = new Subject<boolean>();
  public isProgress: Subject<boolean> = new Subject<boolean>();
  public isCopied: Subject<any> = new Subject<any>();
  public formFieldChanges: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public formFieldChangesPopup: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public closeWindow = new EventEmitter<boolean>();
  public dropDownControlValues: any;
  public dropDownFormValid: boolean;
  public selectedActionFieldItem: any;
  public sectionBasedItem: any;
  public sectionBasedItemAtCargoWise: any;
  public formChanged: boolean = false;
  public duplicateErrorMsg: any = '';
  public lastHighlightedRow: any = '';

  constructor(private http: HttpClient) {}

  getTableViewConfiguration(param: any): Observable<any> {
    return this.http
      .post<any[]>(environment.base_api_desktop_url + 'TableView/Search', param)
      .pipe(catchError(this.handelError));
  }

   getJsonOrDfd(
    searchText: any,
    email: string,
    isArray: boolean,
    json: string,
    filter_options: any,
    accountId?:any,
    offset?: number,
    fetch?: number
  ) {
    let sort: any = [];
    if (json === 'false') {
      sort = [{ column_name: 'fieldName', direction: 'asc' }];
    }
    let param = {
      offset: offset ? offset : 0,
      fetch: fetch ? fetch : 10,
      search_text: searchText,
      isGlobalSearch: true,
      accountId:accountId,
      parameters: [
        {
          name: 'jsonElement',
          value: json,
        },
      ],
      sort_options: sort,
      filter_options: filter_options,
    };
     if (json == 'false' || json === 'false') {
      param.parameters.push({
        name: 'oH_Code',
        value: accountId,
      })
    }
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'WorkFlow/GetJSONorDataDefinition?userEmail=' +
        email + '&isArray=' + isArray,
        param
      )
      .pipe(catchError(this.handelError));
  }

  closePopUpWindow() {
    this.closeWindow.emit(true);
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }

  saveTableViewConfiguration(orgCode: any, params: any): Observable<any> {
    return this.http
      .put<any>(
        environment.base_api_desktop_url + 'TableView/' + orgCode,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }
}
