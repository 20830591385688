<div id="home1" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade show active">
  <div id="bookingDetailTab" class="shipment-detail ob-5 mb-5">
    <div class="full-src custom-alert-msg" *ngIf="
        selectedCustom?.customstatus == 'Filed' ||
        selectedCustom?.customstatus == 'In Progress' ||
        selectedCustom?.customstatus == 'Released' ||
        (selectedCustom?.customs_declarations[0]?.customs_type != 'EXP' &&
          (selectedCustom?.customException == 'On Hold' ||
            selectedCustom?.customException == 'Delayed'))
      ">
      <span [innerHTML]="selectedCustom | customDetailClearanceNotificationText"></span>
    </div>
    <h3 class="update-sec">CUSTOMS PROGRESS</h3>
    <div class="full-rap-set">
      <div class="full-src">
        <app-customs-details-progressbar [selectedCustom]="selectedCustom"></app-customs-details-progressbar>
      </div>
      <div class="full-src setup-top">
        <h3 class="update-sec">ADDITIONAL DETAILS</h3>
        <div class="logs add-details-custom">
          <div id="sectionmilestones" class="card change-icon add-border" *ngIf="
              selectedCustom?.isFullViewAccess &&
              selectedCustom?.milestones?.length > 0
            ">
            <div class="card-header" (click)="addClassFor('#sectionmilestones')">
              <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne" class="btn btn-link">
                  <a class="btn-header-link">Milestones</a>
                </button>
              </h5>
            </div>
            <div id="collapseOne" aria-labelledby="sectionmilestones" class="collapse show">
              <div class="card-body container-color-bg">
                <div class="mobile-view row">
                  <table class="table table-sortable document-sec mb-0" aria-labelledby="document-table"
                    id="account-table">
                    <caption class="d-none"></caption>
                    <thead class="border-0">
                      <tr style="height: 46px">
                        <th [appSort]="selectedCustom?.milestones" id="event_code" class="desc sort fix-w"
                          data-order="asc" data-name="event_code" data-parent="#account-table">
                          Event Code
                        </th>
                        <th [appSort]="selectedCustom?.milestones" id="event_name" class="asc sort" data-order="asc"
                          data-name="event_name" data-parent="#account-table">
                          Event Description
                        </th>
                        <th [appSort]="selectedCustom?.milestones" id="estimated_date"
                          class="asc sort mobile-only fix-w" data-order="asc" data-name="estimated_date"
                          data-parent="#account-table">
                          Estimated Date
                        </th>
                        <th [appSort]="selectedCustom?.milestones" id="actual_date" class="desc factive sort fix-w"
                          data-order="asc" data-name="actual_date" data-parent="#account-table">
                          Actual Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="height: 46px" *ngFor="
                          let file of selectedCustom?.milestones;
                          let i = index
                        ">
                        <td [title]="file?.event_code">
                          <span class="add-middle-dots">{{
                            file.event_code
                            }}</span>
                        </td>
                        <td [title]="file?.event_name">
                          <span class="trim-set-mile"><span>{{ file.event_name }}</span></span>
                        </td>
                        <td>{{ getDateFormated(file.estimated_date) }}</td>
                        <td>{{ getDateFormated(file.actual_date) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="sectionCustomDetails" class="card change-icon add-border">
            <div class="card-header" (click)="addClassFor('#sectionCustomDetails')">
              <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                  aria-controls="collapseTwo" class="btn btn-link">
                  <a class="btn-header-link">Customs Details</a>
                </button>
              </h5>
            </div>
            <div id="collapseTwo" aria-labelledby="sectionCustomDetails" class="collapse show">
              <div class="card-body container-color-bg">
                <div class="mobile-view row">
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedCustom.isFullViewAccess">
                    <h5>Supplier</h5>
                    <p>
                      {{
                      selectedCustom?.supplier?.name
                      ? selectedCustom?.supplier?.name
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedCustom?.isFullViewAccess">
                    <ng-container *ngIf="
                        selectedCustom?.customs_declarations[0]
                          ?.customs_type !== 'EXP'
                      ">
                      <h5>Importer of Records</h5>
                      <p>
                        {{
                        selectedCustom?.importer_of_record?.name
                        ? selectedCustom?.importer_of_record?.name
                        : "---"
                        }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="
                        selectedCustom?.customs_declarations[0]?.customs_type ==
                        'EXP'
                      ">
                      <h5>Importer</h5>
                      <p>
                        {{
                        selectedCustom?.importer?.name
                        ? selectedCustom?.importer?.name
                        : "---"
                        }}
                      </p>
                    </ng-container>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedCustom?.isFullViewAccess">
                    <h5>Consignee</h5>
                    <p>
                      {{
                      selectedCustom?.ultimate_consignee?.name
                      ? selectedCustom?.ultimate_consignee?.name
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6">
                    <h5>Carrier</h5>
                    <p>
                      {{
                      selectedCustom?.carrier?.name
                      ? selectedCustom?.carrier?.name
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="
                      selectedCustom?.customs_declarations[0]?.customs_type !==
                        'EXP' && selectedCustom.isFullViewAccess
                    ">
                    <h5>Manufacturer</h5>
                    <p>
                      {{
                      selectedCustom?.manufacturer?.name
                      ? selectedCustom?.manufacturer?.name
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedCustom.isFullViewAccess">
                    <h5>Shipper</h5>
                    <p>
                      {{
                      selectedCustom?.consignor?.name
                      ? selectedCustom?.consignor?.name
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedCustom?.isFullViewAccess">
                    <h5>Customs Type</h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]?.customs_type
                      ? selectedCustom?.customs_declarations[0].customs_type
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="
                      selectedCustom?.isFullViewAccess &&
                      selectedCustom?.customs_declarations[0]?.customs_type ===
                        'EXP'
                    ">
                    <h5>ITN Number</h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]
                      ?.declaration_number
                      ? selectedCustom?.customs_declarations[0]
                      .declaration_number
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="
                      selectedCustom?.isFullViewAccess &&
                      selectedCustom?.customs_declarations[0]?.customs_type !==
                        'EXP'
                    ">
                    <h5>Entry Number</h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]
                      ?.declaration_number
                      ? selectedCustom?.customs_declarations[0]
                      .declaration_number
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
                      selectedCustom?.isFullViewAccess &&
                      selectedCustom?.customs_declarations[0]?.customs_type !==
                        'EXP'
                    ">
                    <h5>IT Number</h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]
                      ?.us_customs_declaration?.it_number
                      ? selectedCustom?.customs_declarations[0]
                      ?.us_customs_declaration.it_number
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="selectedCustom?.isFullViewAccess">
                    <h5 *ngIf="
                        selectedCustom?.customs_declarations[0]
                          ?.customs_type !== 'EXP'
                      ">
                      Entry Filed
                    </h5>
                    <h5 *ngIf="
                        selectedCustom?.customs_declarations[0]
                          ?.customs_type === 'EXP'
                      ">
                      Filed Date
                    </h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]?.filed_date
                      ? getDateFormated(
                      selectedCustom?.customs_declarations[0].filed_date
                      )
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
                      selectedCustom?.isFullViewAccess &&
                      selectedCustom?.customs_declarations[0]?.customs_type !==
                        'EXP'
                    ">
                    <h5>Entry Released</h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]?.release_date
                      ? getDateFormated(
                      selectedCustom?.customs_declarations[0]
                      .release_date
                      )
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
                      selectedCustom?.isFullViewAccess &&
                      selectedCustom?.customs_declarations[0]?.customs_type !==
                        'EXP'
                    ">
                    <h5>IT Date</h5>
                    <p>
                      {{
                      selectedCustom?.customs_declarations[0]
                      ?.us_customs_declaration?.it_date
                      ? getDateFormated(
                      selectedCustom?.customs_declarations[0]
                      ?.us_customs_declaration?.it_date
                      )
                      : "---"
                      }}
                    </p>
                  </div>
                  <ng-container *ngIf="selectedCustom?.customstatus !== 'FTZ'">
                    <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
                        selectedCustom?.isFullViewAccess &&
                        selectedCustom?.customs_declarations[0]?.customs_type ==
                          'EXP'
                      ">
                      <h5>Customs Cleared</h5>
                      <p>
                        {{
                        selectedCustom?.customs_declarations[0]?.release_date
                        ? getDateFormated(
                        selectedCustom?.customs_declarations[0]
                        .release_date
                        )
                        : "---"
                        }}
                      </p>
                    </div>

                    <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
                        selectedCustom?.isFullViewAccess &&
                        selectedCustom?.customs_declarations[0]?.customs_type ==
                          'EXP'
                      ">
                      <h5>Country of Export</h5>
                      <p>
                        {{
                        selectedCustom?.customs_declarations[0]
                        ?.export_country_code
                        ? selectedCustom?.customs_declarations[0]
                        ?.export_country_code
                        : "---"
                        }}
                      </p>
                    </div>
                  </ng-container>

                  <div class="col-sm-6 col-md-4 line-set col-6">
                    <h5>Master Bill of Lading</h5>
                    <p>
                      {{
                      selectedCustom?.master_bill
                      ? selectedCustom?.master_bill
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6">
                    <h5>House Bill of Lading</h5>
                    <p>
                      {{
                      checkHBL(selectedCustom) !== null
                      ? checkHBL(selectedCustom)
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
                      selectedCustom?.isFullViewAccess &&
                      selectedCustom?.customs_declarations[0]?.customs_type !==
                        'EXP'
                    ">
                    <h5>Participating Gov. Agencies</h5>
                    <div *ngIf="
                        selectedCustom?.customs_declarations[0]
                          ?.customs_agency_statuses?.length > 0
                      ">
                      <span *ngFor="
                          let cas of selectedCustom?.customs_declarations[0]
                            ?.customs_agency_statuses
                        ">
                        <p>
                          {{ cas?.agency_code }}
                          <ng-container *ngIf="cas?.agency_code && cas?.status_message">-
                          </ng-container>
                          {{ cas?.status_message }}
                          <ng-container *ngIf="cas?.status_date && cas?.status_message">-
                          </ng-container>
                          {{ getDateFormated(cas?.status_date) }}
                        </p>
                      </span>
                    </div>
                    <p *ngIf="
                        selectedCustom?.customs_declarations[0]
                          ?.customs_agency_statuses?.length < 1
                      ">
                      ---
                    </p>
                  </div>
                  <div [hidden]="true" class="col-12 mb-2" *ngIf="showCustomField">
                    <h4 class="add-new-hdg">Addtional Custom Details</h4>
                    <div class="row">
                      <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngFor="let cf of customFieldList">
                        <h5>{{ cf.code }}</h5>
                        <p>
                          {{
                          cf.code == "SuitSupply Error Codes"
                          ? cf?.value + " - " + cf?.error_description
                          : cf?.value
                          ? cf.value
                          : "---"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="sectionShipmentDetails" class="card change-icon add-border" *ngIf="selectedCustom?.isFullViewAccess">
            <div class="card-header" (click)="addClassFor('#sectionShipmentDetails')">
              <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapsethree" aria-expanded="true"
                  aria-controls="collapsethree" class="btn btn-link">
                  <a class="btn-header-link">Shipment Details </a>
                </button>
              </h5>
            </div>
            <div id="collapsethree" aria-labelledby="sectionShipmentDetails" class="collapse show">
              <div class="card-body container-color-bg">
                <div class="row">
                  <div class="col-md-6">
                    <h5>Goods Description</h5>
                    <p class="displayset">
                      {{
                      selectedCustom?.description_of_goods
                      ? selectedCustom?.description_of_goods
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <h5 *ngIf="containers">Containers</h5>
                    <p class="inline-set" *ngIf="containers">
                      <span [innerText]="
                          readMoreContainers
                            ? (containers | slice : 0 : 65)
                            : containers
                        "></span>
                      <span class="displayset set-top read-more" *ngIf="containers && readMoreContainers">
                        <span> ...</span>
                        <a href="javascript:;" (click)="readMoreContainers = false">More</a>
                      </span>
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Origin</h5>
                    <p>
                      {{ shipmentDetailOriginCountryCity(selectedCustom) }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Origin ETD</h5>
                    <p>
                      {{
                      selectedCustom?.departure_estimated
                      ? getDateFormated(selectedCustom?.departure_estimated)
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Destination</h5>
                    <p>
                      {{ shipmentDetailDestinationCountryCity(selectedCustom) }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Destination ETA</h5>
                    <p>
                      {{
                      selectedCustom?.arrival_port_estimated
                      ? getDateFormated(
                      selectedCustom?.arrival_port_estimated
                      )
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Total Weight</h5>
                    <p *ngIf="
                        selectedCustom?.gross_weight_kgs &&
                        selectedCustom?.gross_weight_kgs > 0
                      ">
                      {{ selectedCustom?.gross_weight_kgs }}&nbsp;KGS
                    </p>
                    <p *ngIf="
                        (!selectedCustom?.gross_weight_kgs ||
                          selectedCustom?.gross_weight_kgs <= 0) &&
                        selectedCustom?.gross_weight_lbs &&
                        selectedCustom?.gross_weight_lbs > 0
                      ">
                      {{ selectedCustom?.gross_weight_lbs }}&nbsp;LBS
                    </p>
                    <p *ngIf="
                        ((!selectedCustom?.gross_weight_kgs ||
                          selectedCustom?.gross_weight_kgs <= 0) &&
                          !selectedCustom?.gross_weight_lbs) ||
                        selectedCustom?.gross_weight_lbs <= 0
                      ">
                      ---
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Volume</h5>
                    <p *ngIf="
                        selectedCustom?.volume_cbms &&
                        selectedCustom?.volume_cbms >= 0
                      ">
                      {{ selectedCustom?.volume_cbms }}&nbsp;M<sup>3</sup>
                    </p>
                    <p *ngIf="
                        (!selectedCustom?.volume_cbms ||
                          selectedCustom?.volume_cbms < 0) &&
                        selectedCustom?.volume_cf &&
                        selectedCustom?.volume_cf >= 0
                      ">
                      {{ selectedCustom?.volume_cf }}&nbsp;CF
                    </p>
                    <p *ngIf="
                        (!selectedCustom?.volume_cbms ||
                          selectedCustom?.volume_cbms < 0) &&
                        (!selectedCustom?.volume_cf ||
                          selectedCustom?.volume_cf < 0)
                      ">
                      ---
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Packs</h5>
                    <p>
                      {{
                      selectedCustom?.number_of_packages
                      ? selectedCustom?.number_of_packages
                      : "---"
                      }}
                      {{ selectedCustom?.package_type_code }}
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                    <h5>Incoterm</h5>
                    <p>
                      {{
                      selectedCustom?.inco_terms_description
                      ? selectedCustom?.inco_terms_description
                      : "---"
                      }}
                    </p>
                  </div>
                  <div class="col-12 mb-2 new-pipe-design" *ngIf="showCustomField" [innerHTML]="
                      customFieldList | customFieldRenderPipe : 'Shipment'
                    "></div>
                </div>
              </div>
            </div>
          </div>
          <div id="sectionNotes" class="card change-icon add-border"
            *ngIf="displayNotes && selectedCustom.isFullViewAccess">
            <div class="card-header" (click)="addClassFor('#sectionNotes')">
              <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
                  aria-controls="collapseFour" class="btn btn-link">
                  <a class="btn-header-link">Notes </a>
                </button>
              </h5>
            </div>
            <div id="collapseFour" aria-labelledby="sectionNotes" class="collapse show">
              <div class="card-body container-color-bg">
                <div class="row">
                  <div class="col-md-12">
                    <p class="shipNoteFontNormal" [innerText]="
                        readMoreNotes
                          ? (displayNotes | slice : 0 : 180)
                          : displayNotes
                      "></p>
                    <P class="displayset">
                      <span *ngIf="readMoreNotes"> ...</span>
                      <a href="javascript:;" *ngIf="readMoreNotes" (click)="readMoreNotes = false">More</a>
                    </P>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="sectionReferences" class="card change-icon add-border" *ngIf="
              selectedCustom?.isFullViewAccess &&
              (selectedCustom?.shipper_reference?.length > 0 ||
                doAdditionalReferencesExist(selectedCustom?.native_reference_numbers))
            ">
            <div class="card-header" (click)="addClassFor('#sectionReferences')">
              <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true"
                  aria-controls="collapseFive" class="btn btn-link">
                  <a class="btn-header-link">References </a>
                </button>
              </h5>
            </div>
            <div id="collapseFive" aria-labelledby="sectionReferences" class="collapse show">
              <div class="card-body container-color-bg">
                <div class="row">
                  <div class="col-md-12">
                    <ng-container *ngIf="
                        selectedCustom?.shipper_reference &&
                        selectedCustom?.shipper_reference?.length > 0
                      ">
                      <h5 class="displayset">Job References</h5>
                      <p class="displayset" [innerHTML]="
                        getCustomReferenceNumbers(
                        selectedCustom?.shipper_reference,
                        refLengthCheck
                        )"></p>
                    </ng-container>
                  </div>
                  <div class="col-md-12" *ngIf="
                      selectedCustom?.native_reference_numbers &&
                      selectedCustom?.native_reference_numbers?.length > 0
                    ">
                    <div [innerHTML]="
                        selectedCustom?.native_reference_numbers
                          | additionalReferenceRenderPipe
                      "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>