import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import { CustomsService } from '../../services/customs.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AI_TabsName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
@Component({
  selector: 'app-customs-document-tab',
  templateUrl: './customs-document-tab.component.html',
  styleUrls: ['./customs-document-tab.component.scss'],
})
export class CustomsDocumentTabComponent implements OnInit {
  @Input() selectedCustom: any = [];
  @Input() publicShareVisibility = false;
  //EDOC_LIMIT: number = 20;
  //FILENAME_LIMIT: number = 15;
  isProgressShow = false;
  mimeType: any[] = [];
  closeToastMsg = true;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private CustomsService: CustomsService,
    private currentSessionUserData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService
  ) {}

  ngOnInit(): void {
    this.mimeType = CommonUtils.getMimeFileTypes();
    if (this.selectedCustom?.attachments) {
      this.selectedCustom.attachments.sort((one: any, two: any) =>
        one.created_at > two.created_at ? -1 : 1
      );
    }
    this.getScreenSize();
    this.trackAIDocumentTabView();
  }

  trimEdocText(code: any, desc: any) {
    return CommonUtils.trimEdocText(code, desc);
  }

  getYearFormattedDate(date: any) {
    return CommonUtils.formatDateMMMDDYYYY(date);
  }

  downloadFile(data: any, fileType: any, actionType: any, fileNames: any) {
    CommonUtils.downloadBlobFile(
      data,
      fileType,
      actionType,
      fileNames,
      this.mimeType
    );
    this.isProgressShow = false;
  }

  download(
    filepath: any,
    type: any,
    action: any,
    fileNames: any,
    edocType: any
  ) {
    let eventName =
      action == 'preview'
        ? AI_CommonEvents.DocumentPreview.toString()
        : AI_CommonEvents.DocumentDownload.toString();

    this.appInsightsService.trackAIFileDownloadEvent(
      eventName,
      this.selectedCustom?.forwarder_reference,
      AI_ModulesName.Custom,
      AI_TabsName.DocumentTab,
      fileNames,
      edocType
    );
    let fPath = filepath.replace(/\\/g, '\\\\');
    this.CustomsService.downloadFile(fPath).subscribe(
      (res) => {
        if (res?.result !== '') {
          this.downloadFile(res, type, action, fileNames);
        }
      },
      (error) => console.log('Error in download documents:', error)
    );
  }

  closeInfo() {
    this.closeToastMsg = false;
  }

  getFileName(fileName: any) {
    const classFullScreen = $('#shipHeader').hasClass('fixed-fullwidth');
    let file: any = fileName.split(/\.(?=[^\.]+$)/);
    if (!classFullScreen) {
      if (this.scrWidth > 400 && file[0].length > 26) {
        return CommonUtils.getDocumentFileName(file[0], 26, file[1]);
      } else if (file[0].length > 12 && this.scrWidth <= 400) {
        return CommonUtils.getDocumentFileName(file[0], 12, file[1]);
      } else {
        return fileName;
      }
    } else {
      return fileName;
    }
  }

  trackAIDocumentTabView() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.DocumentTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
  }
}
