import { Pipe, PipeTransform } from '@angular/core';
import { ProgressBarType } from './shipment.enums';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipmentDetailProgressBar',
})
export class ShipmentDetailProgressBarPipe implements PipeTransform {
  transform(data: any): unknown {
    let htmlString = '<ul class="timeline">';
    htmlString += CommonUtils.getShipmentListingProgressBar(
      data,
      false,
      '',
      ProgressBarType.ShipmentDetailPage,
      null
    );

    return htmlString;
  }
}
