import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MyGroupService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  saveCreateGroup(
    email: any,
    groupName: any,
    accounts: any,
    editGroup: any,
    groupId: any
  ) {
    let request: any = {
      userEmail: email,
      groupName: groupName,
      accountPermissions: accounts,
    };

    if (editGroup) {
      request.partitionId = email;
      request.id = groupId;
    }
    if (editGroup) {
      return this.http
        .put<[]>(
          environment.base_api_desktop_url + 'user/Group?isEditGroup=true',
          request
        )
        .pipe(catchError(this.handelError));
    } else {
      return this.http
        .post<[]>(environment.base_api_desktop_url + 'user/Group', request)
        .pipe(catchError(this.handelError));
    }
  }

  autoSuggestionForUser(searchText: any, email: any) {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: searchText,
      parameters: [{ name: 'userEmail', value: email }],
      sort_options: [{ column_name: 'aliasName', direction: 'asc' }],
      filter_options: [{ column_name: 'status', operator: '=', value: '1' }],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'user/account',
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getGroupName(userEmail: any) {
    let request = {
      offset: 0,
      fetch: 1000,
      search_text: '',
      parameters: [
        {
          name: 'byGroupId',
          value: false,
        },
        {
          name: 'userEmail',
          value: userEmail,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'user/group/' + userEmail,
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getGroupList(userEmail: any, groupId: any) {
    let request = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: [
        {
          name: 'byGroupId',
          value: true,
        },
        {
          name: 'userGroupId',
          value: groupId,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'user/group/' + userEmail,
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  deleteGroup(email: any, groupId: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userEmail: email,
        id: groupId,
      },
    };

    return this.http
      .delete<[]>(environment.base_api_desktop_url + 'user/Group', options)
      .pipe(catchError(this.handelError));
  }

  assignDefault(userEmail: any, grpId: any, isDefaultVal: any) {
    let request = {
      userEmail: userEmail,
      id: grpId,
      isDefault: isDefaultVal,
    };

    return this.http
      .put<[]>(
        environment.base_api_desktop_url + 'user/Group?isEditGroup=false',
        request
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
}
