import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'trimEdocText'
})
export class TrimEdocTextPipe implements PipeTransform {

  transform(code: any, desc: any): unknown {
    return CommonUtils.trimEdocText(code, desc);
  }

}
