import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
declare var $: any;
import { CustomsUtils } from '../../common/customs-utils';

@Component({
  selector: 'app-all-location-filter',
  templateUrl: './all-location-filter.component.html',
  styleUrls: ['./all-location-filter.component.scss'],
})
export class AllLocationFilterComponent implements OnInit, OnChanges {
  locationFilter: any[] = [
    { status: 'Loading Port', val: 0 },
    { status: 'Discharge Port', val: 1 },
    { status: 'Port of Clearance', val: 2 },
    { status: 'Shipment Origin', val: 3 },
    { status: 'Shipment Destination', val: 4 },
  ];
  locationFilterValue = 'Loading Port';
  selectedMobileValue = 'Loading Port';
  lP = 'Loading Port';
  dP = 'Discharge Port';
  pOC = 'Port of Clearance';
  sO = 'Shipment Origin';
  sD = 'Shipment Destination';
  searchText: string = '';
  selectedFilterText: string = 'Location: ';
  DISPLAY_TEXT_COUNT: number = 15;
  DEFAULT_DISPLAY_TEXT: string = 'Location: ';
  DEFAULT_DISPLAY_TEXT_MULTIPLE: string = 'Location: Multiple';
  accountDropdownToggle: boolean = false;
  locationToggleMobile: boolean = false;
  @Output() alllocationFilter = new EventEmitter();
  @Output() emitOffsetLoc = new EventEmitter();
  @Output() locationFilterValueEmit = new EventEmitter();
  @Input() offsetLoadingPort: any;
  @Input() offsetDischargePort: any;
  @Input() offsetPortofClearance: any;
  @Input() offsetShipmentOrigin: any;
  @Input() offsetShipmentDestination: any;
  @Input() allLocationList: any;
  @Output() locationFilterSearch = new EventEmitter();
  @Output() locationFilterClear = new EventEmitter();
  @Input() listData: any = [];
  @Input() isProgress: any;
  @Input() resentList: any;
  @Input() allLocationReselected: any = [];
  @Output() closeSelectedFilterControl = new EventEmitter();
  loadingPortFilterSelectedList: any[] = [];
  dischargePortFilterSelectedList: any[] = [];
  pocFilterSelectedList: any[] = [];
  shipmentDestinationFilterSelectedList: any[] = [];
  shipmentOriginFilterSelectedList: any[] = [];
  loadingPortRecentList: any[] = [];
  dischargePortRecentList: any[] = [];
  portOfClearanceRecentList: any[] = [];
  shipmentOriginRecentList: any[] = [];
  shipmentDestinationRecentList: any[] = [];
  concatString = '';
  concatArray: any[] = [];
  selectedLocationFilterParameter: any;
  messageText: any[] = [];
  mutipleSelectedList: any[] = [];
  scrHeight: any;
  scrWidth: any;
  isMobileView = false;
  selectLocationDebounce = new Subject();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 767 ? true : false;
  }

  constructor(private appInsightsService: AppInsightsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resentList.length > 0) {
      this.loadingPortRecentList = this.resentList[0].recentLoadingList;
      this.dischargePortRecentList = this.resentList[1].recentDischargeList;
      this.portOfClearanceRecentList =
        this.resentList[2].recentPortOfClearanceList;
      this.shipmentOriginRecentList =
        this.resentList[3].recentShipmentOriginList;
      this.shipmentDestinationRecentList =
        this.resentList[4].recentShipmentDestinationList;
    }

    if (this.allLocationReselected?.length > 0) {
      this.assignReselectedData(this.allLocationReselected);
    }
    if (
      this.isMobileView &&
      Object.keys(this.listData).length > 0 &&
      this.listData?.filterArray.length > 0
    ) {
      this.assignReselectedData(this.listData?.filterArray);
    }
  }

  allLocationDefaultCustomfilterM(value: any) {
    this.locationFilterValue = value;
    this.selectedMobileValue = value;
  }

  ngOnInit(): void {
    this.loadInitialData();
  }

  loadInitialData() {
    this.scrWidth = window.innerWidth;
    this.isMobileView = this.scrWidth <= 767 ? true : false;
    this.saveFilterSelection();
    if (
      this.isMobileView &&
      Object.keys(this.listData).length > 0 &&
      this.listData?.filterArray.length > 0
    ) {
      this.assignReselectedData(this.listData?.filterArray);
    }
    let root = this;
    $(document).ready(function () {
      if (root.listData?.autoOpen) {
        root.accountDropdownToggle = true;
      }
    });

    $(document).on('mouseup', function (e: any) {
      if (
        e.target.id == 'accountSelect' ||
        e.originalEvent.path[1].id == 'btnGroupAddon' ||
        e.target.id == 'btnGroupAddon' ||
        e.target.id == 'profileSec' ||
        e.target.id == 'globalSearchIconDesktop'
      ) {
        root.accountDropdownToggle = false;
      }
    });

    this.selectLocationDebounce.pipe(debounceTime(1000)).subscribe((e) => {
      this.selectActivelocationFilter(e);
    });
  }

  selectActivelocationFilter(filterValue: any) {
    this.searchText = '';
    this.locationFilterValue = filterValue.status;
    this.selectedMobileValue = filterValue.status;
    this.locationFilterValueEmit.emit(this.locationFilterValue);
    this.locationToggleMobile = false;
    $('.scroll-sec').scrollTop(0);
  }

  assignReselectedData(data: any) {
    this.loadingPortFilterSelectedList = data[0]?.loadingPort;
    this.dischargePortFilterSelectedList = data[1]?.DischargePort;
    this.shipmentOriginFilterSelectedList = data[2]?.shipmentOrigin;
    this.shipmentDestinationFilterSelectedList = data[4]?.shipmentDestination;
    this.pocFilterSelectedList = data[4]?.portOfClearance;
  }

  onScroll(searchText: any) {
    this.checkFilterOffset(searchText);
  }

  checkFilterOffset(searchText: any) {
    let offsetRecords = 30;

    if (this.isMobileView) {
      offsetRecords = 10;
    }
    if (this.locationFilterValue == 'Loading Port') {
      this.offsetLoadingPort += offsetRecords;
      this.loadScrollValues(this.offsetLoadingPort, 'Loading Port', searchText);
    } else if (this.locationFilterValue == 'Discharge Port') {
      this.offsetDischargePort += offsetRecords;
      this.loadScrollValues(
        this.offsetDischargePort,
        'Discharge Port',
        searchText
      );
    } else if (this.locationFilterValue == 'Port of Clearance') {
      this.offsetPortofClearance += offsetRecords;
      this.loadScrollValues(
        this.offsetPortofClearance,
        'Port of Clearance',
        searchText
      );
    } else if (this.locationFilterValue == 'Shipment Origin') {
      this.offsetShipmentOrigin += offsetRecords;
      this.loadScrollValues(
        this.offsetShipmentOrigin,
        'Shipment Origin',
        searchText
      );
    } else if (this.locationFilterValue == 'Shipment Destination') {
      this.offsetShipmentDestination += offsetRecords;
      this.loadScrollValues(
        this.offsetShipmentDestination,
        'Shipment Destination',
        searchText
      );
    }
  }

  loadScrollValues(offsetValue: any, filterName: any, searchText: any) {
    this.emitOffsetLoc.emit({
      offset: offsetValue,
      type: filterName,
      value: searchText,
    });
  }

  statusTextBoxHandler() {
    this.accountDropdownToggle = !this.accountDropdownToggle;
  }

  mobileLocationTextBoxHandler() {
    this.locationToggleMobile = !this.locationToggleMobile;
  }

  CloseOut() {
    this.accountDropdownToggle = false;
  }

  getSearchLocationList(searchText: any) {
    this.allLocationList = [];
    if (this.locationFilterValue == 'Loading Port') {
      this.offsetLoadingPort = 0;
      this.loadScrollValues(this.offsetLoadingPort, 'Loading Port', searchText);
    } else if (this.locationFilterValue == 'Discharge Port') {
      this.offsetDischargePort = 0;
      this.loadScrollValues(
        this.offsetDischargePort,
        'Discharge Port',
        searchText
      );
    } else if (this.locationFilterValue == 'Port of Clearance') {
      this.offsetPortofClearance = 0;
      this.loadScrollValues(
        this.offsetPortofClearance,
        'Port of Clearance',
        searchText
      );
    } else if (this.locationFilterValue == 'Shipment Origin') {
      this.offsetShipmentOrigin = 0;
      this.loadScrollValues(
        this.offsetShipmentOrigin,
        'Shipment Origin',
        searchText
      );
    } else if (this.locationFilterValue == 'Shipment Destination') {
      this.offsetShipmentDestination = 0;
      this.loadScrollValues(
        this.offsetShipmentDestination,
        'Shipment Destination',
        searchText
      );
    }
    if (searchText.length > 2) {
      this.locationFilterSearch.emit({
        value: searchText,
        type: this.locationFilterValue,
      });
    }
  }

  clearData(data: any) {
    this.locationFilterClear.emit({
      value: data,
      type: this.locationFilterValue,
    });
  }

  onSearchHandler(value: any) {
    this.locationFilterSearch.emit({
      value: value,
      type: this.locationFilterValue,
    });
  }

  locationFilterSelected(selectedRow: any, index: any) {
    if (!selectedRow.index) {
      selectedRow.index = index;
    }
    switch (this.locationFilterValue) {
      case this.lP:
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected(this.lP);
        this.isDuplicate(selectedRow, index, this.lP);
        break;
      case this.dP:
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected(this.dP);
        this.isDuplicate(selectedRow, index, this.dP);
        break;
      case this.pOC:
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected(this.pOC);
        this.isDuplicate(selectedRow, index, this.pOC);

        break;
      case this.sO:
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected(this.sO);
        this.isDuplicate(selectedRow, index, this.sO);
        break;
      case this.sD:
        this.concatArray = [];
        this.concatString = '';
        this.mutipleSelected(this.sD);
        this.isDuplicate(selectedRow, index, this.sD);
        break;
      default:
        break;
    }
  }

  isDuplicate(entry: any, index: any, type: any) {
    if (type == 'Loading Port') {
      if (entry.countryName) {
        let loadingPort = this.loadingPortFilterSelectedList.some(
          (x: any) =>
            entry.countryName == x.countryName &&
            entry.portName == x.portName &&
            entry.unlocode == x.unlocode
        );
        if (!loadingPort) {
          this.loadingPortFilterSelectedList.push(entry);
          this.setSelectedParameter(
            this.loadingPortFilterSelectedList,
            'loading'
          );
        }
      }
    } else if (type == 'Discharge Port') {
      if (entry.countryName) {
        let discharge = this.dischargePortFilterSelectedList.some(
          (manuplated: any) =>
            entry.countryName == manuplated.countryName &&
            entry.portName == manuplated.portName &&
            entry.unlocode == manuplated.unlocode
        );

        if (!discharge) {
          this.dischargePortFilterSelectedList.push(entry);
          this.setSelectedParameter(
            this.dischargePortFilterSelectedList,
            'discharge'
          );
        }
      }
    } else if (type == 'Shipment Origin') {
      if (entry.countryName) {
        let shipmentOrigin = this.shipmentOriginFilterSelectedList.some(
          (manuplated2: any) =>
            entry.countryName == manuplated2.countryName &&
            entry.portName == manuplated2.portName &&
            entry.unlocode == manuplated2.unlocode
        );
        if (!shipmentOrigin) {
          this.shipmentOriginFilterSelectedList.push(entry);
          this.setSelectedParameter(
            this.shipmentOriginFilterSelectedList,
            'origin'
          );
        }
      }
    } else if (type == 'Shipment Destination') {
      if (entry.countryName) {
        let shipmentDestination =
          this.shipmentDestinationFilterSelectedList.some(
            (manuplated3: any) =>
              entry.countryName == manuplated3.countryName &&
              entry.portName == manuplated3.portName &&
              entry.unlocode == manuplated3.unlocode
          );
        if (!shipmentDestination) {
          this.shipmentDestinationFilterSelectedList.push(entry);
          this.setSelectedParameter(
            this.shipmentDestinationFilterSelectedList,
            'destination'
          );
        }
      }
    } else if (type == 'Port of Clearance') {
      if (entry.port_of_clearance_description) {
        let poc = this.pocFilterSelectedList.some(
          (manuplated4: any) =>
            entry.port_of_clearance_description ==
              manuplated4.port_of_clearance_description &&
            entry.country_code == manuplated4.country_code
        );
        if (!poc) {
          this.pocFilterSelectedList.push(entry);
          this.setSelectedParameter(
            this.pocFilterSelectedList,
            'portofclearance'
          );
        }
      }
    }
  }

  mutipleSelected(type: any) {
    let elementPos = this.mutipleSelectedList
      .map(function (value: any) {
        return value;
      })
      .indexOf(type);

    if (elementPos < 0) {
      this.mutipleSelectedList.push(type);
    }
  }

  mutipleRemove(type: any) {
    let elementPos = this.mutipleSelectedList
      .map(function (value: any) {
        return value;
      })
      .indexOf(type);

    if (elementPos >= 0) {
      this.mutipleSelectedList.splice(elementPos, 1);
    }
  }

  checkDuplictateList(selectedRow: any, list: any) {
    let elementPos: any;
    if (!this.checkSelectedType()) {
      elementPos = list
        .map(function (value: any) {
          return value.port_of_clearance_description;
        })
        .indexOf(selectedRow.port_of_clearance_description);
    } else {
      elementPos = list
        .map(function (value: any) {
          return value.rL_Code;
        })
        .indexOf(selectedRow.rL_Code);
    }

    return elementPos;
  }

  setSelectedParameter(list: any, colName: any) {
    list.forEach((el: any) => {
      if (!this.checkSelectedType()) {
        this.concatArray.push(
          el.port_of_clearance_description.trim() + ':' + el.country_code
        );
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(
          el.port_of_clearance_description.trim() + ':' + el.country_code
        );
      } else {
        this.concatArray.push(el.countryCode + ',' + el.unlocode);
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.rL_Code);
      }
    });

    this.selectedLocationFilterParameter = {
      column_name: colName,
      operater: '=',
      value: this.concatString,
    };
    this.alllocationFilter.emit({
      selectedValue: this.selectedLocationFilterParameter,
      type: this.locationFilterValue,
      account: list,
      clearFrom: '',
      removing: false,
    });
    if (this.mutipleSelectedList.length > 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT_MULTIPLE;
    } else if (this.mutipleSelectedList.length < 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT;
    } else {
      this.truncateDisplayText(this.messageText, list);
    }
  }

  checkCustomsType(show: any) {
    if (show.customs_type) {
      if (show.customs_type == 'EXP') {
        return ':' + show.customs_type + ':' + show.export_country_code;
      } else {
        return ':' + show.customs_type + ':' + show.import_country_code;
      }
    } else {
      return '';
    }
  }

  truncateDisplayText(item: any, data: any) {
    if (!item || data?.length <= 0) {
      this.selectedFilterText = this.mutipleSelectedList[0] + '- ';
    }

    if (item?.length > 0) {
      this.selectedFilterText =
        'Location: ' +
        this.mutipleSelectedList[0] +
        '- ' +
        item.join(', ').slice(0, this.DISPLAY_TEXT_COUNT);
      if (this.selectedFilterText.length > this.DISPLAY_TEXT_COUNT) {
        this.selectedFilterText =
          this.selectedFilterText.substring(0, this.DISPLAY_TEXT_COUNT) + '...';
      }
    }
  }

  checkSelectedType() {
    let value: any;
    if (
      this.locationFilterValue == 'Loading Port' ||
      this.locationFilterValue == 'Discharge Port' ||
      this.locationFilterValue == 'Shipment Origin' ||
      this.locationFilterValue == 'Shipment Destination'
    ) {
      value = true;
    } else if (this.locationFilterValue == 'Port of Clearance') {
      value = false;
    }
    return value;
  }

  checkSelectedTypeRemoved(locationFilterValue: any) {
    let value: any;
    if (
      locationFilterValue == 'Loading Port' ||
      locationFilterValue == 'Discharge Port' ||
      locationFilterValue == 'Shipment Origin' ||
      locationFilterValue == 'Shipment Destination'
    ) {
      value = true;
    } else if (locationFilterValue == 'Port of Clearance') {
      value = false;
    }
    return value;
  }

  childSelectedText(index: any, selectedChildRow: any, type: any) {
    this.concatArray = [];
    this.concatString = '';
    switch (type) {
      case 'Loading Port':
        this.setUnselectedParameter(
          this.loadingPortFilterSelectedList,
          index,
          type,
          'loading'
        );
        break;
      case 'Discharge Port':
        this.setUnselectedParameter(
          this.dischargePortFilterSelectedList,
          index,
          type,
          'destination'
        );
        break;
      case 'Port of Clearance':
        this.setUnselectedParameter(
          this.pocFilterSelectedList,
          index,
          type,
          'portofclearance'
        );
        break;
      case 'Shipment Origin':
        this.setUnselectedParameter(
          this.shipmentOriginFilterSelectedList,
          index,
          type,
          'origin'
        );
        break;
      case 'Shipment Destination':
        this.setUnselectedParameter(
          this.shipmentDestinationFilterSelectedList,
          index,
          type,
          'destination'
        );
        break;
      default:
        break;
    }
  }

  setUnselectedParameter(
    childSelectedList: any,
    index: any,
    type: any,
    colName: string
  ) {
    childSelectedList.splice(index, 1);
    childSelectedList.forEach((el: any) => {
      if (!this.checkSelectedTypeRemoved(type)) {
        this.concatArray.push(
          el.port_of_clearance_description.trim() + ':' + el.country_code
        );
        this.concatString = this.concatArray.map((x) => x).join('|');
      } else {
        this.concatArray.push(el.countryCode + ',' + el.unlocode);
        this.concatString = this.concatArray.map((x) => x).join('|');
      }
    });

    this.selectedLocationFilterParameter = {
      column_name: colName,
      operater: '=',
      value: this.concatString,
    };
    this.alllocationFilter.emit({
      selectedValue:
        this.concatString.length > 0
          ? this.selectedLocationFilterParameter
          : [],
      type: type,
      account: childSelectedList,
      clearFrom: '',
      removing: true,
    });

    this.pillDisplayTextRemove(index, type, childSelectedList);
  }

  pillDisplayTextRemove(index: any, type: any, childSelectedList: any) {
    this.messageText.splice(index, 1);
    switch (type) {
      case 'Loading Port':
        if (this.loadingPortFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Discharge Port':
        if (this.dischargePortFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Port of Clearance':
        if (this.pocFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Shipment Origin':
        if (this.shipmentOriginFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
      case 'Shipment Destination':
        if (this.shipmentDestinationFilterSelectedList.length < 1) {
          this.mutipleRemove(type);
        }
        break;
    }
    if (this.mutipleSelectedList.length > 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT_MULTIPLE;
    } else if (this.mutipleSelectedList.length == 0) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT;
    } else {
      this.truncateDisplayText(this.messageText, childSelectedList);
    }
  }

  clearFilters(filterIndex: any, filterId: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.LocationFilterCleared.toString(),
      { [AI_CustomProps.ModuleName]: AI_ModulesName.Custom }
    );

    this.closeSelectedFilterControl.emit({
      type: 'allLocation',
      index: filterIndex,
      id: filterId,
      eventType: 'closeBtn',
      closeFrom: '',
    });

    this.allLocationList = [];
  }

  saveFilterSelection() {
    if (
      Object.keys(this.listData).length > 0 &&
      this.listData?.filterArray.length > 0
    ) {
      this.loadingPortFilterSelectedList =
        this.listData?.filterArray[0].loadingPort;
      this.dischargePortFilterSelectedList =
        this.listData?.filterArray[1].dischargePort;
      this.shipmentOriginFilterSelectedList =
        this.listData?.filterArray[2].shipmentOrigin;
      this.shipmentDestinationFilterSelectedList =
        this.listData?.filterArray[3].shipmentDestination;
      this.pocFilterSelectedList =
        this.listData?.filterArray[4].portOfClearance;
      this.listData.filterArray?.forEach((val: any) => {
        if (val.loadingPort?.length > 0) {
          this.mutipleSelectedList.push('Loading Port');
          this.saveFilterPillText(val.loadingPort, 'Loading Port');
        } else if (val.dischargePort?.length > 0) {
          this.mutipleSelectedList.push('Discharge Port');
          this.saveFilterPillText(val.dischargePort, 'Discharge Port');
        } else if (val.shipmentOrigin?.length > 0) {
          this.mutipleSelectedList.push('Shipment Origin');
          this.saveFilterPillText(val.shipmentOrigin, 'Shipment Origin');
        } else if (val.shipmentDestination?.length > 0) {
          this.mutipleSelectedList.push('Shipment Destination');
          this.saveFilterPillText(
            val.shipmentDestination,
            'Shipment Destination'
          );
        } else if (val.portOfClearance?.length > 0) {
          this.mutipleSelectedList.push('Port of Clearance');
          this.saveFilterPillText(val.portOfClearance, 'Port of Clearance');
        }
      });
      this.locationFilterValueEmit.emit('Loading Port');
    }
  }

  saveFilterPillText(list: any, type: any) {
    list.forEach((el: any) => {
      if (!this.checkSelectedType()) {
        this.concatArray.push(el.port_of_clearance_description.trim());
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.port_of_clearance_description);
      } else {
        this.concatArray.push(el.countryCode + ',' + el.unlocode);
        this.concatString = this.concatArray.map((x) => x).join('|');
        this.messageText.push(el.rL_Code);
      }
    });

    if (this.mutipleSelectedList.length > 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT_MULTIPLE;
    } else if (this.mutipleSelectedList.length < 1) {
      this.selectedFilterText = this.DEFAULT_DISPLAY_TEXT;
    } else {
      this.truncateDisplayText(this.messageText, list);
    }
  }

  manipulateData(evnt: any) {
    let message = CustomsUtils.convertCityStateAsUpperCaseForLoc(evnt, '');
    return message;
  }

  checkCountryCode(show: any) {
    //   if (show.customs_type) {
    //     if (show.customs_type == 'EXP') {
    //       if (show.export_country_code) {
    //           return ","+show.export_country_code
    //       } else {
    //         return ''
    //        }

    //     } else {
    //       if (show.import_country_code) {
    //         return ","+show.import_country_code
    //       } else {
    //         return ''
    //       }

    //   }
    //   } else {
    //     return ''
    //   }

    if (show.country_code) {
      return ', ' + show.country_code;
    } else {
      return '';
    }
  }
}
