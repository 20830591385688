import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFieldRenderPipe',
})
export class CustomFieldRenderPipe implements PipeTransform {
  transform(list: any, ...args: any[]): any {
    if (!list || list?.length <= 0) {
      return '';
    }
    // To show only customized data
    list = list.filter((x: any) => x.data_source == 'CustomizedField');
    let htmlString = '';
    htmlString +=
      '<h4 class="add-new-hdg">Addtional ' + args[0] + ' Details</h4>';
    htmlString +=
      '<div class="col-sm-12 line-set col-6 mob-top p-0 new-design">';
    list.forEach((cf: any) => {
      let displayHeading = cf.display_name ? cf.display_name : cf.code;
      let displayValue =
        cf.code == 'SuitSupply Error Codes'
          ? cf?.value +
            (cf?.error_description ? ' - ' + cf?.error_description : '')
          : cf?.value
          ? cf.value
          : '---';
      htmlString += '<div class="rap1"><h5>' + displayHeading + '</h5>';
      htmlString += '<p>' + displayValue + '</p></div>';
    });

    htmlString += '</div>';
    return htmlString;
  }
}
