<div id="pop-up2" *ngIf="popupToggle" class="dimScreen">
  <div *ngIf="popupToggle" class="shipment-details" id="shipmentFull" #shipmentFull>
    <div class="overall-scroll-mobtab">
      <button class="close" type="button" (click)="featureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
      </button>
      <div class="body-section">
        <!-- Booking Header Component start -->
        <app-booking-details-header [selectedBooking]="selectedBooking" [urlShare]="urlShare"
          (prepareUrlShare)="share($event)" (reloadBookingListFromHeader)="reloadWatchListBooking($event)"
          (refreshBookingDetailsFromHeader)="onReloadDetails($event)" (addFullScreen)='addFullScreen($event)'
          [publicShareEnrcyptedData]="publicShareEnrcyptedData">
        </app-booking-details-header>
        <!-- Booking Header Component end -->
        <div class="col-lg-12 p-0">
          <div class="tab-content shipment-tab-topmargin" id="myTabContent">
            <div class="tab-pane fade show active" id="home1" role="tabpanel" aria-labelledby="home-tab">
              <!-- Booking detail tab Component start -->
              <app-booking-detail-tab [selectedBooking]="selectedBooking"></app-booking-detail-tab>
              <!-- Booking detail tab Component end -->
            </div>
            <div class="tab-pane fade overall-scroll" id="profile1" *ngIf="selectedBooking?.isFullViewAccess"
              role="tabpanel" aria-labelledby="profile-tab">
              <!-- Booking Detail Tab Component start -->
              <app-booking-document-tab [selectedBooking]="selectedBooking"
                [publicShareEnrcyptedData]="publicShareEnrcyptedData">
              </app-booking-document-tab>
              <!-- Booking Detail Tab Component end -->
            </div>
            <div class="tab-pane fade" id="action-tab-bookings" role="tabpanel" aria-labelledby="action-tab">
              <app-action-detail-tab [selectedBooking]="selectedBooking"
              (closePopUp)="popUpClose()"
              (loadBookingList)="loadBookingListById($event)"
              [currentUserData]="currentAccountData"></app-action-detail-tab>
            </div>
            <div class="tab-pane fade overall-scroll" id="logs1" role="tabpanel" aria-labelledby="logs-tab"
              *ngIf="selectedBooking.isFullViewAccess">
              <app-change-logs [selectedShipment]="selectedBooking" [type]="'bookings'"
                (showViewReference)="loadFullReferenceNum($event)"></app-change-logs>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>