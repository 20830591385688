import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortDirective } from './directive/sort.directive';
import { FilterPipe } from './pipe/filter.pipe';
import { SessionStorageService } from './services/session-storage.service';
import { AuthenticationService } from './services/auth.service';
import { LoginService } from './services/login.service';
import { ProgressbarComponent } from './component/progressbar/progressbar.component';
import { HighlightSearch } from './pipes/highlight.directive';
import { CurrentSessionDataService } from './services/current-session-data.service';
import { SuccessToastComponent } from './component/success-toast/success-toast.component';
import { TagInputModule } from 'ngx-chips-v1';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvitePopupComponent } from './component/invite-popup/invite-popup.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { UploadDocumentComponent } from './component/upload-document/upload-document.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ToastWithTickComponent } from './component/toast-with-tick/toast-with-tick.component';
import { OnlineStatusModule } from 'ngx-online-status';
import { ChangeLogsComponent } from './component/change-logs/change-logs.component';
import { ShipmentServies } from '../features/shipments/services/shipment.service';
import { SafePipe } from './pipe/dom-sanitizer.pipe';

import { CommonDefaultFilterComponent } from './component/common-default-filter/common-default-filter.component';
import { CommonDropdownFiltersComponent } from './component/common-dropdown-filters/common-dropdown-filters.component';
import { CommonShipperTypeFilterComponent } from './component/common-shipper-type-filter/common-shipper-type-filter.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomDetailClearanceNotificationTextPipe } from './pipe/custom-detail-clearance-notification-text.pipe';

import { AdditionalReferenceRenderPipe } from './pipe/additional-reference-render.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomFieldRenderPipe } from './pipe/custom-field-render.pipe';
import { UpdatedCreatedOnPipe } from './pipe/updated-created-on.pipe';
import { ModuleNameDisplayPipe } from './pipe/module-name-display.pipe.';
import { FilterByConditionPipe } from './pipe/filter-by-condition.pipe';
import { FindKeyByValuePipe } from './pipe/find-key-by-value.pipe';
import { DateFormatPipe } from '../common/date-format.pipe';
import { StatusPipe } from '../common/status.pipe';
import { ShipmentReferenceNumberPipe } from '../common/shipment-reference-number.pipe';
import { StatusHintsPipe } from '../common/status-hints.pipe';
import { ShipmentProgressBarPipe } from '../common/shipment-progress-bar.pipe';
import { DestinationLocationPipe } from '../common/destination-location.pipe';
import { DepartureLocationPipe } from '../common/departure-location.pipe';
import { ShipFromLocationPipe } from '../common/ship-from-location.pipe';
import { ShipToLocationPipe } from '../common/ship-to-location.pipe';
import { ExceptionClassPipe } from '../common/exception-class.pipe';
import { ShipmentDetailProgressBarPipe } from '../common/shipment-detail-progress-bar.pipe';
import { DateTimeTextPipe } from './pipe/date-time-text.pipe';
import { StatusIconComponent } from './component/status-icon/status-icon.component';
import { PickupDeliveryPortCountryPipe } from '../common/pickup-delivery-port-country.pipe';
import { EstimatedDeliveryPipe } from '../common/estimated-delivery.pipe';
import { FileNamePipe } from '../common/file-name.pipe';
import { TrimEdocTextPipe } from '../common/trim-edoc-text.pipe';
import { YearFormatPipe } from '../common/year-formate.pipe';
import { ShipmentContainerProgressBarPipe } from '../common/shipment-container-progress-bar.pipe';
import { OiaReferenceNumberPipe } from '../common/oia-reference-number.pipe';
import { SealdataPipe } from '../common/sealdata.pipe';
import { BookingReferenceNumberPipe } from '../common/booking-reference-number.pipe';
import { ConvertToTitlecasePipe } from '../common/convert-to-titlecase.pipe';
import { StatusDataPipe } from '../common/status-data.pipe';
import { TrimmedAddressDataPipe } from '../common/trimmed-address-data.pipe';
import { BookedDatePipe } from '../common/booked-date.pipe';
import { CityStateCountryPipe } from '../common/city-state-country.pipe';
import { PortDetailsPipe } from '../common/port-details.pipe';
import { ShipFromAddressPipe } from '../common/ship-from-address.pipe';
import { ShipToDetailsPipe } from '../common/ship-to-details.pipe';
import { DateFormatMmddyyyyPipe } from '../common/date-format-mmddyyyy.pipe';
import { VesselVoyageTextPipe } from '../common/vessel-voyage-text.pipe';
import { BookingExceptionClassPipe } from '../common/booking-exception-class.pipe';
import { CustomExceptionClassPipe } from '../common/custom-exception-class.pipe';
import { MblSetPipe } from '../common/mbl-set.pipe';
import { CheckSFilePipe } from '../common/check-s-file.pipe';
import { CheckTransportModePipe } from '../common/check-transport-mode.pipe';
import { CustomReferenceNumberPipe } from '../common/custom-reference-number.pipe';
import { EntryportExportportPipe } from '../common/entryport-exportport.pipe';
import { TrasportModeByFileTypePipe } from '../common/trasport-mode-by-file-type.pipe';

@NgModule({
  declarations: [
    SortDirective,
    FilterPipe,
    DateFormatPipe,
    StatusPipe,
    BookingReferenceNumberPipe,
    ConvertToTitlecasePipe,
    StatusDataPipe,
    BookedDatePipe,
    TrimmedAddressDataPipe,
    CustomExceptionClassPipe,
    BookingExceptionClassPipe,
    MblSetPipe,
    CheckTransportModePipe,
    CheckSFilePipe,
    PortDetailsPipe,
    EntryportExportportPipe,
    CustomReferenceNumberPipe,
    CityStateCountryPipe,
    ShipFromAddressPipe,
    ShipToDetailsPipe,
    VesselVoyageTextPipe,
    DateFormatMmddyyyyPipe,
    ShipmentReferenceNumberPipe,
    StatusHintsPipe,
    ShipmentProgressBarPipe,
    ShipmentDetailProgressBarPipe,
    ShipmentContainerProgressBarPipe,
    SealdataPipe,
    OiaReferenceNumberPipe,
    DestinationLocationPipe,
    DepartureLocationPipe,
    ShipFromLocationPipe,
    ShipToLocationPipe,
    PickupDeliveryPortCountryPipe,
    EstimatedDeliveryPipe,
    FileNamePipe,
    TrimEdocTextPipe,
    YearFormatPipe,
    ExceptionClassPipe,
    ProgressbarComponent,
    HighlightSearch,
    SuccessToastComponent,
    ClickOutsideDirective,
    InvitePopupComponent,
    UploadDocumentComponent,
    ToastWithTickComponent,
    ToastWithTickComponent,
    ChangeLogsComponent,
    CommonDefaultFilterComponent,
    CommonDropdownFiltersComponent,
    CommonShipperTypeFilterComponent,
    SafePipe,
    CustomDetailClearanceNotificationTextPipe,
    AdditionalReferenceRenderPipe,
    CustomFieldRenderPipe,
    UpdatedCreatedOnPipe,
    ModuleNameDisplayPipe,
    FilterByConditionPipe,
    FindKeyByValuePipe,
    DateTimeTextPipe,
    StatusIconComponent,
    TrasportModeByFileTypePipe,
  ],
  imports: [
    CommonModule,
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    OnlineStatusModule,
    InfiniteScrollModule,
    DragDropModule,
  ],
  providers: [
    SessionStorageService,
    AuthenticationService,
    LoginService,
    CurrentSessionDataService,
    ShipmentServies,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
  ],
  exports: [
    SortDirective,
    FilterPipe,
    DateFormatPipe,
    StatusPipe,
    BookingReferenceNumberPipe,
    ConvertToTitlecasePipe,
    StatusDataPipe,
    TrimmedAddressDataPipe,
    CustomExceptionClassPipe,
    BookingExceptionClassPipe,
    MblSetPipe,
    CheckTransportModePipe,
    CheckSFilePipe,
    PortDetailsPipe,
    EntryportExportportPipe,
    CustomReferenceNumberPipe,
    CityStateCountryPipe,
    ShipFromAddressPipe,
    ShipToDetailsPipe,
    VesselVoyageTextPipe,
    DateFormatMmddyyyyPipe,
    BookedDatePipe,
    ShipmentReferenceNumberPipe,
    StatusHintsPipe,
    ShipmentProgressBarPipe,
    ShipmentDetailProgressBarPipe,
    ShipmentContainerProgressBarPipe,
    SealdataPipe,
    OiaReferenceNumberPipe,
    DestinationLocationPipe,
    DepartureLocationPipe,
    ShipFromLocationPipe,
    ShipToLocationPipe,
    PickupDeliveryPortCountryPipe,
    EstimatedDeliveryPipe,
    FileNamePipe,
    TrimEdocTextPipe,
    YearFormatPipe,
    ExceptionClassPipe,
    ProgressbarComponent,
    HighlightSearch,
    SuccessToastComponent,
    InvitePopupComponent,
    ClickOutsideDirective,
    UploadDocumentComponent,
    ToastWithTickComponent,
    ChangeLogsComponent,
    CommonDefaultFilterComponent,
    CommonDropdownFiltersComponent,
    CommonShipperTypeFilterComponent,
    SafePipe,
    CustomDetailClearanceNotificationTextPipe,
    AdditionalReferenceRenderPipe,
    CustomFieldRenderPipe,
    UpdatedCreatedOnPipe,
    ModuleNameDisplayPipe,
    FilterByConditionPipe,
    FindKeyByValuePipe,
    DateTimeTextPipe,
    StatusIconComponent,
    TrasportModeByFileTypePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
