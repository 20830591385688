import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-customs-details-tab',
  templateUrl: './customs-details-tab.component.html',
  styleUrls: ['./customs-details-tab.component.scss'],
})
export class CustomsDetailsTabComponent implements OnInit, OnChanges {
  @Input() selectedCustom: any = [];
  readMoreNotes: boolean = false;
  readMoreContainers: boolean = false;
  replaceRegex = '/^s+|s+$/g';
  displayNotes: string = '';
  refLengthCheck: number = 0;
  scrHeight: any;
  scrWidth: any;
  shipmentRefreshIcon: boolean = false;
  containers: any;
  showCustomField: boolean = false;
  customFieldList: any[] = [];

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(private appInsightsService: AppInsightsService) { }

  ngOnInit(): void {
    // To remove all the newline from marks and numbers & Notes.

    //find first client visible job ntes and display
    this.displayNotes = '';
    if (this.selectedCustom && this.selectedCustom.notes) {
      let clientVisibleNote = this.selectedCustom.notes.find(
        (i: any) =>
          i.note_type.toString().toLowerCase() === 'client visible job notes'
      );
      if (clientVisibleNote && clientVisibleNote.body) {
        this.displayNotes = clientVisibleNote.body;
      }
      this.displayNotes = this.displayNotes.replace(this.replaceRegex, '');
      this.readMoreNotes = this.displayNotes.length > 180;
    }
    if (this.selectedCustom?.containers?.length > 0) {
      this.containers = this.selectedCustom?.containers
        ?.map((t: any) => t.container_number + ' (' + t.size_code + ')')
        .join(', ');
      this.containers = this.containers.replace(this.replaceRegex, '');
      this.readMoreContainers = this.containers.length > 65;
    }
    this.sortMileStonesByDescription();
  }

  ngOnChanges(): void {
    this.trackAICustomsTabView();
    this.loadCustomField();
    this.sortMileStonesByDescription();
  }

  sortMileStonesByDescription() {
    CommonUtils.sortMileStonesByDescription(this.selectedCustom);
  }

  getCustomReferenceNumbers(message: any, limit: number) {
    if (limit > 0 && message.length > limit) {
      return message.substring(0, limit - 3) + '...';
    }
    return message ? message : '---';
  }

  loadCustomField() {
    this.customFieldList = [];
    this.showCustomField = false;
    if (this.selectedCustom && this.selectedCustom?.native_reference_numbers) {
      this.customFieldList =
        this.selectedCustom.native_reference_numbers?.filter(
          (x: any) => x.data_source == 'CustomizedField'
        );
      this.showCustomField = this.customFieldList.length > 0;
    }
  }

  refLengthCal() {
    setTimeout(() => {
      const classFullScreen = $('#shipmentFull').hasClass('shipmet-fullwidth');
      if (this.scrWidth >= 1100 && !classFullScreen) {
        this.refLengthCheck = 82;
      } else if (this.scrWidth > 1100 && classFullScreen) {
        this.refLengthCheck = 180;
      } else if (this.scrWidth < 1100 && classFullScreen) {
        this.refLengthCheck = 90;
      } else {
        this.refLengthCheck = 72;
      }
    }, 10);
  }

  getDateFormated(date: any) {
    let message = '---';
    if (date) {
      message = CommonUtils.formatDateMMMDDYYYY(date);
    }
    return message;
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }

  checkSFile(id: any) {
    if (id.charAt(0).toLowerCase() === 's') {
      return true;
    } else {
      return false;
    }
  }

  checkHBL(data: any) {
    if (this.checkSFile(data.forwarder_reference)) {
      return data.customs_declarations[0]?.house_bill;
    } else {
      return data.house_bill;
    }
  }

  shipmentDetailOriginCountryCity(data: any) {
    let message = '';
    let origin = this.getOriginCountry(data);
    if (origin) {
      message += origin;
    }
    return message;
  }

  getOriginCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.origin_port) {
      message += data.origin_port.portName
        ? CommonUtils.toTitleCase(data.origin_port.portName) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.origin_port.country_name,
        data.origin_port.stateCode
      );

      message += data.origin_port.countryCode
        ? data.origin_port.countryCode.toUpperCase()
        : '';
    }
    return CommonUtils.removeLastComma(message);
  }

  shipmentDetailDestinationCountryCity(data: any) {
    let message = '';
    let destination = this.getDestinationCountry(data);
    if (destination) {
      message += destination;
    }
    return message;
  }

  getDestinationCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.final_destination_port) {
      message += data.final_destination_port.portName
        ? CommonUtils.toTitleCase(data.final_destination_port.portName) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.final_destination_port.country_name,
        data.final_destination_port.stateCode
      );

      message += data.final_destination_port.countryCode
        ? data.final_destination_port.countryCode.toUpperCase()
        : '';
    }
    return CommonUtils.removeLastComma(message);
  }

  trackAICustomsTabView() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedCustom?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
  }

  doAdditionalReferencesExist(nativeReferenceNumbers: any[]) {
    return CommonUtils.doAdditionalReferencesExist(nativeReferenceNumbers);
  }
}
