<!-- For User-->
<div *ngIf="!isAdmin" class="nav-desktop-only mobileviewcompanydrop">
    <div #toggleButton class="input-group" (click)="textBoxClick($event)">
        <input type="text" class="form-control" name="search" [(ngModel)]="searchText"
            (keyup.backspace)="clearFilter($event,searchText)" [value]="selectedFilterValue" autocomplete="off"
            #searchInput placeholder="Select Company" aria-label="Input group example"
            aria-describedby="btnGroupAddon" />
        <div class="input-group-prepend" *ngIf="!inputClickToggle">
            <div class="input-group-text btn" id="btnGroupAddon">
                <img alt="arrow-down" src="assets/img/blue-arrow-down.svg" />
            </div>
        </div>
        <div class="input-group-prepend" *ngIf="inputClickToggle">
            <div class="input-group-text btn" id="btnGroupAddon">

                <img alt="arrow-up" src="assets/img/blue-arrow-up.svg" />
            </div>
        </div>
    </div>
</div>

<div *ngIf="inputClickToggle && !isAdmin" id="userToggle" class="searchFilter_box searchFilter_box1 update-design"
    #menu>
    <div class="rap-section">
        <div class="rap section1" *ngIf="groupCount > 0">
            <div class="heading-set-title" (click)="openGroup()">
                My Groups
                <img alt="arrow-down"
                    [src]="openGroupToggle?'assets/img/blue-arrow-up.svg':'assets/img/blue-arrow-down.svg'" />
            </div>
            <div *ngIf="openGroupToggle">
                <ul *ngIf="groupsList.length > 0" class="drop-level scroll-sec1">
                    <li *ngFor="let group of groupsList ; let indexOfelement = index">
                        <span
                            (click)="selectedParentCompany(group.groupName,false,indexOfelement,group)">{{group.groupName}}</span>
                    </li>
                </ul>
                <div class="pl-3" *ngIf="groupsList.length == 0">No Result Found</div>
            </div>

        </div>
        <div class="rap-sec section2">
            <div *ngIf="groupCount > 0" class="heading-set-title" (click)="openAccountToggle()">
                All Accounts
                <img alt="arrow-down"
                    [src]="openAccountsToggle?'assets/img/blue-arrow-up.svg':'assets/img/blue-arrow-down.svg'" />
            </div>
            <ul *ngIf="openAccountsToggle" class="companies_list scroll-sec2" style="padding: 0;">
                <ng-container *ngIf="userCompaniesList | filterSearch: searchText as result">
                    <div *ngFor="let show of result; let indexOfelement = index"
                        [ngClass]="{'display':displayChild[indexOfelement] === false ? 'none' : '' }">
                        <div [ngStyle]="{'background':displayChild[indexOfelement] === false ? '#eaf3f5' : '' }"
                            [ngClass]="{'selected_parent_list': (selectedParentList === indexOfelement)&& (selectedFilterValue.toLowerCase()===show.aliasName.toLowerCase())}"
                            class="account-name-main">
                            <div class="account-name"
                                (click)="selectedParentCompany(show.aliasName,show.childAccounts,indexOfelement,show)">
                                {{show.aliasName}}</div>
                            <div class="open-close-arrow">
                                <img *ngIf="
                                    show.childAccounts?.length &&
                                    displayChild[indexOfelement] === false
                                  " alt="CopyImage" src="assets/img/up-select.svg"
                                    (click)="openChildList(indexOfelement)" />
                                <img *ngIf="
                                    show.childAccounts?.length &&
                                    displayChild[indexOfelement] === true
                                  " alt="CopyImage" src="assets/img/down-select.svg"
                                    (click)="openChildList(indexOfelement)" />
                            </div>
                        </div>
                        <div class="child-scroll">
                            <ng-container *ngIf="show.childAccounts?.length!==null && show.childAccounts?.length>0 ">
                                <div class="child-user"
                                    *ngFor="let childList of show.childAccounts | filterSearch:searchText ;let i=index;"
                                    [ngStyle]="{'display':displayChild[indexOfelement] === true ? 'none' : '' }"
                                    [ngClass]="{'hide-child':displayChild[indexOfelement]===true, 'display-child':displayChild[indexOfelement]===false}">
                                    <div
                                        (click)="selectedChildCompany(childList.aliasName,i,childList,show.isControllingCustomer?show.isControllingCustomer:show.childAccounts.length>0?true:false)">
                                        {{childList.aliasName}}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="pl-3" *ngIf="result.length == 0">No Result Found</div>
                </ng-container>
            </ul>
        </div>
    </div>
</div>


<!-- For Admin-->

<div *ngIf="isAdmin" class="nav-desktop-only mobileviewcompanydrop">
    <div #toggleButton (click)="textBoxClick($event)" class="input-group">
        <input id="accountSelect" type="text" class="form-control" name="search" [(ngModel)]="searchText"
            (keyup.backspace)="clearFilter($event,searchText)" [value]="selectedFilterValue" autocomplete="off"
            #searchInput placeholder="Select Company" aria-label="Input group example"
            aria-describedby="btnGroupAddon" />
        <div class="input-group-prepend" *ngIf="!inputClickToggle">
            <div class="input-group-text btn" id="btnGroupAddon">
                <img alt="arrow-down" src="assets/img/blue-arrow-down.svg" />
            </div>
        </div>
        <div class="input-group-prepend" *ngIf="inputClickToggle">
            <div class="input-group-text btn" id="btnGroupAddon">
                <img alt="arrow-up" src="assets/img/blue-arrow-up.svg" />
            </div>
        </div>
    </div>
</div>

<div id="userToggle" *ngIf="inputClickToggle && isAdmin" class="searchFilter_box searchFilter_box1 update-design" #menu>
    <div class="rap-section">
        <div class="rap section1" *ngIf="groupCount > 0">
            <div class="heading-set-title" (click)="openGroup()">
                My Groups
                <img alt="arrow-down"
                    [src]="openGroupToggle?'assets/img/blue-arrow-up.svg':'assets/img/blue-arrow-down.svg'" />
            </div>
            <div *ngIf="openGroupToggle">
                <ul *ngIf="groupsList.length > 0" class="scroll-sec1">
                    <li *ngFor="let group of groupsList ; let indexOfelement = index">
                        <span
                            (click)="selectedParentCompany(group.groupName,false,indexOfelement,group)">{{group.groupName}}</span>
                    </li>
                </ul>
                <div class="pl-3" *ngIf="groupsList.length == 0">No Result Found</div>
            </div>
        </div>
        <div class="rap-sec section2">
            <div *ngIf="groupCount > 0" (click)="openAccountToggle()" class="heading-set-title">
                All Accounts
                <img alt="arrow-down"
                    [src]="openAccountsToggle?'assets/img/blue-arrow-up.svg':'assets/img/blue-arrow-down.svg'" />
            </div>
            <ul *ngIf="openAccountsToggle" class="companies_list scroll-sec2" infinite-scroll
                [infiniteScrollDistance]="2" [scrollWindow]="false" [infiniteScrollThrottle]="50"
                (scrolled)="onScroll($event,searchText)" style="padding: 0;">
                <ng-container>
                    <div *ngFor="let show of adminuserCompaniesList; let indexOfelement = index"
                        [ngClass]="{'display':displayChild[indexOfelement] === false ? 'none' : '' }">
                        <div [ngStyle]="{'background':displayChild[indexOfelement] === false ? '#eaf3f5' : '' }"
                            [ngClass]="{'selected_parent_list': (selectedParentList === indexOfelement)&& (selectedFilterValue.toLowerCase()===show.aliasName.toLowerCase())}"
                            class="account-name-main">
                            <div class="account-name"
                                (click)="selectedParentCompany(show.aliasName,show.accountRelationships,indexOfelement,show)">
                                {{show.aliasName}}</div>
                            <div class="open-close-arrow">
                                <img (click)="openChildList(indexOfelement)" *ngIf="
                                    show.accountRelationships?.length &&
                                    displayChild[indexOfelement] === false
                                  " alt="CopyImage" src="assets/img/up-select.svg" srcset="
                                    assets/img/up-arrow@2x.png 2x,
                                    assets/img/up-arrow@3x.png 3x
                                  " />
                                <img (click)="openChildList(indexOfelement)" *ngIf="
                                    show.accountRelationships?.length &&
                                    displayChild[indexOfelement] === true
                                  " alt="CopyImage" src="assets/img/down-select.svg" srcset="
                                    assets/img/down-arrow-plain@2x.png 2x,
                                    assets/img/down-arrow-plain@3x.png 3x
                                  " />
                            </div>
                        </div>

                        <div class="child-scroll">
                            <ng-container
                                *ngIf="show.accountRelationships?.length!==null && show.accountRelationships?.length>0 ">
                                <div *ngFor="let childList of show.accountRelationships;let i=index;"
                                    [ngStyle]="{'display':displayChild[indexOfelement] === true ? 'none' : '' }"
                                    [ngClass]="{'hide-child':displayChild[indexOfelement]===true, 'display-child':displayChild[indexOfelement]===false}">
                                    <div
                                        (click)="selectedChildCompany(childList.aliasName,i,childList,show.isControllingCustomer?show.isControllingCustomer:show.accountRelationships.length>0?true:false)">
                                        {{childList.aliasName}}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="pl-3" *ngIf="adminuserCompaniesList.length == 0">No Result Found</div>
                </ng-container>
            </ul>
        </div>
    </div>
</div>