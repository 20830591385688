import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomsComponent } from './container/customs/customs.component';
import { CustomsRoutingModule } from './customs-routing.module';
import { CustomsListComponent } from './component/customs-list/customs-list.component';
import { CustomsCustomFiltersComponent } from './component/customs-custom-filters/customs-custom-filters.component';
import { CustomsService } from './services/customs.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomTypePipe } from './common/custom-type.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomProgressBarPipe } from './common/custom-progress-bar.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DaterangeFilterComponent } from './component/daterange-filter/daterange-filter.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AllLocationFilterComponent } from './component/customs-location-filter/all-location-filter.component';
import { CustomsDetailsComponent } from './component/customs-details/customs-details.component';
import { CustomsDetailsHeaderComponent } from './component/customs-details-header/customs-details-header.component';
import { CustomsDetailsTabComponent } from './component/customs-details-tab/customs-details-tab.component';
import { CustomsDocumentTabComponent } from './component/customs-document-tab/customs-document-tab.component';
import { CustomsInvoicesTabComponent } from './component/customs-invoices-tab/customs-invoices-tab.component';
import { CustomsPgaTabComponent } from './component/customs-pga-tab/customs-pga-tab.component';
import { CustomsDetailsProgressbarComponent } from './component/customs-details-progressbar/customs-details-progressbar.component';
import { CustomDetailProgressBarPipe } from './common/custom-detail-page-progress-bar';
import { CustomDateTimePipe } from './common/custom-date-time.pipe';
import { CustomHblPipe } from './common/custom-hbl.pipe';
import { SucessToastComponent } from './common/sucess-toast/sucess-toast.component';
import { ActionDetailTabComponent } from './component/action-detail-tab/action-detail-tab.component';

@NgModule({
  declarations: [
    CustomsComponent,
    CustomsListComponent,
    CustomsCustomFiltersComponent,
    CustomTypePipe,
    CustomProgressBarPipe,
    CustomDetailProgressBarPipe,
    DaterangeFilterComponent,
    AllLocationFilterComponent,
    CustomsDetailsComponent,
    CustomsDetailsHeaderComponent,
    CustomsDetailsTabComponent,
    CustomsDocumentTabComponent,
    CustomsInvoicesTabComponent,
    CustomsPgaTabComponent,
    CustomsDetailsProgressbarComponent,
    CustomDateTimePipe,
    CustomHblPipe,
    SucessToastComponent,
    ActionDetailTabComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomsRoutingModule,
    InfiniteScrollModule,
    SharedModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  exports: [
    CustomsDetailsProgressbarComponent,
    CustomsPgaTabComponent,
    CustomsListComponent,
    CustomsComponent,
    CustomsCustomFiltersComponent,
    CustomsDetailsComponent,
  ],
  providers: [CustomsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomsModule { }
