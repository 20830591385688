import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CustomProps,
  AI_PageName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-global-search-list',
  styleUrls: ['./global-search-list.component.scss'],
  templateUrl: './global-search-list.component.html',
})
export class GlobalSearchListComponent {
  @Input() shipmentAutoSuggestedRecord: any[] = [];
  @Input() bookingAutoSuggestedRecord: any[] = [];
  @Input() customsAutoSuggestedRecord: any[] = [];
  @Input() totalShipmentCount: number = 0;
  @Input() totalBookingCount: number = 0;
  @Input() totalCustomsCount: number = 0;
  @Input() searchedText: any = '';
  @Output() clearSearchTextBox = new EventEmitter<any>();
  @Output() clearSearchResult = new EventEmitter<any>();
  shipmentlist: any[] = [];
  togglePickupDeliveryLegs: boolean = false;
  currentAccountData: any;
  isShipmentIsVisible: false;
  isBookingIsVisible: false;
  isCustomsIsVisible: false;
  @Input() shipmentRoute: number = 1;
  @Input() bookingRoute: number = 2;
  @Input() customsRoute: number = 3;

  constructor(
    private router: Router,
    private sessionData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService
  ) {
    this.currentAccountData = this.sessionData.getCurrentAccountData();
    this.isShipmentIsVisible = this.currentAccountData.shipmentIsVisible;
    this.isBookingIsVisible = this.currentAccountData.bookingIsVisible;
    this.isCustomsIsVisible = this.currentAccountData.customsIsVisible;
  }

  getShipmentReferenceNumbers(order: any, limit: number) {
    return CommonUtils.getShipmentReferenceNumbers(order, limit);
  }

  getExceptionClass(status: any, exception: any) {
    return CommonUtils.getExceptionClass(status, exception);
  }

  getProgressBarDetail(data: any): string {
    return CommonUtils.getShipmentListingProgressBar(
      data,
      this.togglePickupDeliveryLegs,
      this.searchedText
    );
  }

  getStatusHint(data: any) {
    return CommonUtils.getStatusHint(data);
  }

  selectedStatus(selectedVal: any) {
    this.clearSearchTextBox.emit('');
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: {},
        selectedAccount: [],
      },
      destination: {
        selectedValue: {},
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: {},
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: {},
        selectedAccount: [],
      },
      consignee: {
        selectedValue: {},
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: {} },
          { destinationValue: {} },
          { pickupValue: {} },
          { finalDeliveryValue: {} },
        ],
      },
      sorting: [],
      accountId: '',
      shipmentId: selectedVal,
      type: 'Single',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    const url = environment.localUrl + '/shipments/' + encry;
    window.open(url, '_blank');
  }

  resultPage() {
    let val = this.searchedText;
    this.clearSearchTextBox.emit('');
    this.clearSearchResult.emit('');
    if (val.length > 2) {
      this.trackAISearchClickEvent(val);
      this.recentSearchSave(val);
      let filterVal = JSON.stringify({ val: val, from: 'Shipment' });
      this.router.navigate(['/shipments/view/search-result'], {
        queryParams: { filterParam: filterVal },
        skipLocationChange: true,
      });
    }
  }

  recentSearchSave(val: any) {
    if (val.length > 2) {
      let getLocalStorageValue = localStorage.getItem('globalRecentSearch');
      let parserRecentSearch =
        getLocalStorageValue !== null ? JSON.parse(getLocalStorageValue) : '';

      let selectedSearchValue: any[] = [];
      if (parserRecentSearch) {
        let elementPosDestination = parserRecentSearch
          .map(function (x: any) {
            return x;
          })
          .indexOf(val);
        if (elementPosDestination < 0) {
          selectedSearchValue = [].concat(val, parserRecentSearch);
        } else {
          selectedSearchValue = [].concat(parserRecentSearch);
        }
      } else {
        selectedSearchValue = [].concat(val);
      }

      let finalRecentList = selectedSearchValue.slice(0, 10);
      localStorage.setItem(
        'globalRecentSearch',
        JSON.stringify(finalRecentList)
      );
    }
  }

  bookingViewAllEmit(e: any) {
    let val = this.searchedText;
    this.clearSearchTextBox.emit('');
    this.clearSearchResult.emit('');
    this.recentSearchSave(val);
  }

  customsViewAllEmit(e: any) {
    let val = this.searchedText;
    this.clearSearchTextBox.emit('');
    this.clearSearchResult.emit('');
    this.recentSearchSave(val);
  }

  globalSearchCloseset() {
    this.shipmentAutoSuggestedRecord = [];
    this.bookingAutoSuggestedRecord = [];
    this.customsAutoSuggestedRecord = [];
    this.totalShipmentCount = 0;
    this.totalBookingCount = 0;
    this.totalCustomsCount = 0;
    this.clearSearchResult.emit('');
  }

  trackAISearchClickEvent(searchText: any) {
    this.appInsightsService.logEvent(AI_PageName.GlobalSearch, {
      [AI_CustomProps.ModuleName]: 'Shipment Global Search',
      [AI_CustomProps.SearchText]: searchText,
    });
  }
}
