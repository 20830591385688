import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';

@Injectable()
export class ShipmentServies {
  currentAccountData: any;
  currentUserData: any;
  isParent: boolean;
  isActionFieldChanged: boolean;
  openActionTab: boolean = false;
  openDocumentTab: boolean = false;
  openLogsTab: boolean = false;
  openDetailsTab: boolean = false;
  openCustomTab: boolean = false;
  openContainerTab: boolean = false;
  actionFieldExist: boolean = false;
  switchTabInitiated: boolean = false;
  backupLastFilterUrl: any;
  backupOfAdvFilterCurrentData: any;
  advancedFilterSavedRecently: boolean;
  recentFilterUrl: any;
  touchedCustomDropdown: boolean = false;
  touchedTransportRibbonMode: boolean = false;
  touchedWatchlistRibbonMode: boolean = false;
  isFullScreenTabEnabled: boolean = false;
  isBodyParam: any = [];
  public activeShipmentDetailTab: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  constructor(
    private http: HttpClient,
    public currentUserDataService: CurrentSessionDataService
  ) {
    this.currentAccountData =
      this.currentUserDataService.getCurrentAccountData();
    this.currentUserData = this.currentUserDataService.getCurrentUserData();
    this.isParent = this.currentAccountData.isParent;
  }
  public stopRequest: Subject<void> = new Subject<void>();
  public openLogTab: Subject<boolean> = new Subject<boolean>();

  // TODO: to be refactored
  public filteredOption: any;
  public searchedText: any;
  getAllShipments(
    accountId: string,
    emailId: any,
    bodyParam: any,
    isGroup: boolean
  ): Observable<any> {
    this.isBodyParam = bodyParam;
    this.filteredOption = bodyParam.filter_options;
    this.searchedText = bodyParam.search_text;
    this.getCurrentSessionData();
    let parent = this.currentAccountData.isParent;
    let url =
      environment.base_api_desktop_url +
      'Shipment?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;

    return this.http
      .post<any[]>(url, bodyParam)
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getShipmentDetails(
    accountId: string,
    emailId: any,
    bodyParam: any,
    isGroup: boolean,
    module: number,
  ): Observable<any> {
    let url =
      environment.base_api_desktop_url +
      'Shipment/ids?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent +
      '&module=' +
      module;
    return this.http
      .post<any[]>(url, bodyParam)
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  locationFilter(accountId: string, emailId: any, isGroup: boolean): Observable<any> {

    this.searchedText = this.isBodyParam.search_text;
    let bodyParam = this.isBodyParam;
    bodyParam.filter_options = [];
    bodyParam.advanceFilterOptions = [];
    bodyParam.parameters.push({
      name: 'favUserEmail',
      value: emailId,
    });

    this.getCurrentSessionData();
    let url = environment.base_api_desktop_url + 'Shipment/LocationFilter?accountId=' + accountId + '&userEmail=' + emailId + '&isGroup=' + isGroup + '&isParent=' + this.isParent;
    return this.http.post<any[]>(url, bodyParam).pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }


  // TODO: to be refactored
  getShipmentsDetails(
    accountId: string,
    emailId: any,
    shipmentId: any,
    isGroup: boolean
  ): Observable<any> {
    this.getCurrentSessionData();
    let params = {
      offset: 0,
      fetch: 10,
      search_text: '',
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      advanceFilterOptions: [],
      parameters: [
        {
          name: 'shipmentNumber',
          value: shipmentId,
        },
      ],
      sort_options: [
        {
          column_name: 'first_leg_departure_actual',
          direction: 'desc',
        },
      ],
      filter_options: [],
    };

    let url =
      environment.base_api_desktop_url +
      'Shipment?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;

    return this.http
      .post<any[]>(url, params)
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  downloadFile(filePath: any): Observable<any> {
    const headers: Object = {
      responseType: 'Blob',
    };

    let bodyParam = {
      parameters: [
        {
          name: filePath,
        },
      ],
    };
    let url = environment.base_api_desktop_url + 'Download/Attachment';

    return this.http
      .post<any[]>(url, bodyParam, headers)
      .pipe(catchError(this.handelError));
  }

  getOriginFilter(
    searchText: any,
    offset: any,
    value: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any,
    sortByColumn: string
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'source',
          value: value,
        },
      ],
      sort_options: [
        {
          column_name: sortByColumn ? sortByColumn : 'RL_Code',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'Shipment/Unlococode?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  prepareExcelDowload(
    accountId: string,
    emailId: any,
    bodyParam: any,
    isGroup: any
  ): Observable<any> {
    this.getCurrentSessionData();
    let url = '';
    if (bodyParam.isTableView) {
      url =
        environment.base_api_desktop_url +
        'Shipment/TableViewExcelExport?accountId=' +
        accountId +
        '&userEmail=' +
        emailId +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent;
    } else {
      url =
        environment.base_api_desktop_url +
        'Shipment/ExcelExport?accountId=' +
        accountId +
        '&userEmail=' +
        emailId +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent;
    }
    const headers: Object = {
      responseType: 'Blob',
    };

    return this.http
      .post<any[]>(url, bodyParam, headers)
      .pipe(catchError(this.handelError));
  }

  addRemoveWatchList(shipmentID: any, id: any, state: any) {
    let params = {
      forwarder_reference: shipmentID,
      favorite: [{ userId: id }],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'Shipment/WatchList/' + state,
        params
      )
      .pipe(catchError(this.handelError));
  }

  // TODO: to be refactored
  getShipmentStatusCount(
    accountId: any,
    email: any,
    searchText: any,
    isGroup: any
  ): Observable<any> {
    this.getCurrentSessionData();
    let params = {
      offset: 0,
      fetch: 10,
      search_text: searchText,
      isGlobalSearch: true,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      advanceFilterOptions: [],
      parameters: [
        {
          name: 'GroupByStatus',
          value: '1',
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    let url =
      environment.base_api_desktop_url +
      'GlobalSearch/group?accountId=' +
      accountId +
      '&userEmail=' +
      email +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;

    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  updateShipment(params: any): Observable<any> {
    let url = environment.base_api_desktop_url + 'ShipmentImport';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  skipTour(email: any, params: any): Observable<any> {
    let url =
      environment.base_api_desktop_url + 'user/guidedtour?userEmail=' + email;
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }

  saveUserFilter(
    userEmail: any,
    filterName: any,
    jsonArray: any,
    accountId: any,
    groupId: any,
    isGroup: boolean
  ) {
    let jsonFilter: any = {};
    if (typeof jsonArray == "string") {
      jsonFilter = JSON.parse(jsonArray);
    }
    else {
      jsonFilter = jsonArray;
    }
    let params = {
      userEmail: userEmail,
      filterName: filterName,
      jsonFilterActual: jsonFilter,
      jsonFilter:
        typeof jsonArray == 'object' ? JSON.stringify(jsonArray) : jsonArray,
      accountId: !isGroup ? accountId : '',
      filterType: 'shipping',
      groupId: isGroup ? groupId : '',
    };
    let url = environment.base_api_desktop_url + 'Shipment/filter';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  saveSystemFilter(
    userEmail: any,
    filterName: any,
    jsonArray: any,
    accountId: any,
    users: any,
    isGroup: boolean
  ) {
    let jsonFilter: any = {};
    if (typeof jsonArray == "string") {
      jsonFilter = JSON.parse(jsonArray);
    }
    else {
      jsonFilter = jsonArray;
    }

    let params = {
      userEmail: userEmail,
      filterName: filterName,
      jsonFilterActual: jsonFilter,
      jsonFilter:
        typeof jsonArray == 'object' ? JSON.stringify(jsonArray) : jsonArray,
      accountId: !isGroup ? accountId : '',
      groupId: isGroup ? accountId : '',
      filterType: 'shipment',
      isSystemDefined: true,
      createdBy: this.currentUserData.emailAddress,
      modifiedBy: null,
      filterusers: users,
    };
    let url = environment.base_api_desktop_url + 'Shipment/Systemfilter';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  updateUserFilter(data: any, jsonArray: any, filterName: any, accountId: any, isGroup: boolean) {
    let params = {
      id: data.id,
      userEmail: data.userEmail,
      filterName: filterName,
      jsonFilter: jsonArray,
      accountId: !isGroup ? accountId : '',
      filterType: 'shipping',
      groupId: isGroup ? accountId : '',
    };
    let url = environment.base_api_desktop_url + 'Shipment/filter';
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }

  updateSystemFilter(
    data: any,
    jsonArray: any,
    filterName: any,
    accountId: any,
    users: any,
    isSystemDefined: boolean,
    isGroup: boolean
  ) {
    let jsonFilter: any = {};
    if (typeof jsonArray == "string") {
      jsonFilter = JSON.parse(jsonArray);
    }
    else {
      jsonFilter = jsonArray;
    }
    let params = {
      id: data.id,
      userEmail: data.userEmail,
      filterName: filterName,
      jsonFilterActual: jsonFilter,
      jsonFilter: jsonArray,
      accountId: !isGroup ? accountId : '',
      groupId: isGroup ? accountId : '',
      filterType: 'shipment',
      isSystemDefined: isSystemDefined,
      filterusers: users,
      createdBy: data.createdBy,
      modifiedBy: this.currentUserData.emailAddress,
      displayOrder: data.displayOrder,
    };
    let url = environment.base_api_desktop_url + 'Shipment/Systemfilter';
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }

  getUserFilter(userEmail: any, accountIdParam: any, isGroup: boolean) {
    let accountId = !isGroup ? accountIdParam : '';
    let groupId = isGroup ? accountIdParam : '';
    let url =
      environment.base_api_desktop_url +
      'Shipment/filter/usershipment?userEmail=' +
      userEmail +
      '&accountId=' +
      accountId +
      '&filterType=shipping&groupId=' +
      groupId;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  getSystemFilter(userEmail: any, accountId: any, isGroup: boolean) {
    let accId = !isGroup ? accountId : "";
    let groupId = isGroup ? accountId : "";
    let url =
      environment.base_api_desktop_url +
      'Shipment/Systemfilter?userEmail=' +
      userEmail +
      '&accountId=' +
      accId +
      '&filterType=shipment' +
      '&groupId=' + groupId;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  deleteUserFilter(userEmail: any, id: any) {
    let url = environment.base_api_desktop_url + 'Shipment/filter';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id,
        userEmail: userEmail,
        partitionId: userEmail,
        filterType: 'shipping',
      },
    };

    return this.http
      .delete<any[]>(url, options)
      .pipe(catchError(this.handelError));
  }

  deleteSystemFilter(userEmail: any, id: any) {
    let url = environment.base_api_desktop_url + 'Shipment/Systemfilter';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id,
        filterType: 'shipment',
      },
    };

    return this.http
      .delete<any[]>(url, options)
      .pipe(catchError(this.handelError));
  }

  handelError(error: any) {
    return throwError(
      {
        status: error.status,
        statusText: error.statusText,
      } || 'Server Error'
    );
  }

  publicShare(
    accountId: any,
    userEmail: any,
    params: any,
    isGroup: boolean,
    isParent: boolean
  ) {
    let url = '';
    if (userEmail) {
      url =
        environment.base_api_publicShare_url +
        'Shipment/limited?accountId=' +
        accountId +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        isParent;
    } else if (accountId && userEmail) {
      url =
        environment.base_api_publicShare_url +
        'Shipment/limited?accountId=' +
        accountId +
        '&userEmail=""&isGroup=' +
        isGroup +
        '&isParent=' +
        isParent;
    } else {
      url =
        environment.base_api_publicShare_url +
        'Shipment/limited?isGroup=' +
        isGroup +
        '&isParent=' +
        isParent;
    }
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  getConsigneeFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
      ],
      sort_options: [
        {
          column_name: 'consignee.name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'Shipment/Consignee?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getControllingCustomer(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      isGlobalSearch: false,
      parameters: [],
      sort_options: [
        {
          column_name: 'name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'Shipment/ControllingCustomer?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getShipperFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
      ],
      sort_options: [
        {
          column_name: 'shipper.name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'Shipment/Shipper?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getPickupFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
        {
          name: 'ship_from',
          value: 'ship_from',
        },
      ],
      sort_options: [
        {
          column_name: 'name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'Shipment/PickupDelivery?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getFinalDeliveryFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'favUserEmail',
          value: userEmail,
        },
        {
          name: 'ship_to',
          value: 'ship_to',
        },
      ],
      sort_options: [
        {
          column_name: 'name',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        'Shipment/PickupDelivery?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getCountryFilter(
    searchText: any,
    offset: any,
    accountID: any,
    userEmail: any,
    isGroup: boolean,
    fetch: any,
    value: any,
    sortByColumn: string
  ) {
    this.getCurrentSessionData();
    let params = {
      offset: offset,
      fetch: fetch,
      search_text: searchText,
      parameters: [
        {
          name: 'source',
          value: value,
        },
      ],
      sort_options: [
        {
          column_name: sortByColumn ? sortByColumn : 'RL_Code',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
        // 'Shipment/Unlococode?accountId=PETFACMUJ&userEmail=Arjunan.kannan%40photon.com&isGroup=false&isParent=false',
        // params
        'Shipment/Unlococode?accountId=' +
        accountID +
        '&userEmail=' +
        userEmail +
        '&isGroup=' +
        isGroup +
        '&isParent=' +
        this.isParent,
        params
      )
      .pipe(catchError(this.handelError));
  }

  getGlobalSearchResult(
    accountId: string,
    emailId: any,
    bodyParam: any,
    isGroup: boolean
  ): Observable<any> {
    this.getCurrentSessionData();
    let url =
      environment.base_api_desktop_url +
      'Shipment?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      this.isParent;

    return this.http
      .post<any[]>(url, bodyParam)
      .pipe(catchError(this.handelError));
  }

  saveShareFilter(
    userEmail: any,
    filterName: any,
    jsonArray: any,
    accountId: any
  ) {
    let jsonFilter: any = {};
    if (typeof jsonArray == "string") {
      jsonFilter = JSON.parse(jsonArray);
    }
    else {
      jsonFilter = jsonArray;
    }
    let params = {
      userEmail: userEmail,
      filterName: filterName,
      jsonFilterActual: jsonFilter,
      jsonFilter:
        typeof jsonArray == 'object' ? JSON.stringify(jsonArray) : jsonArray,
      accountId: accountId,
      filterType: 'shipping',
    };
    let url = environment.base_api_desktop_url + 'SharedUrl';
    return this.http
      .post<any[]>(url, params)
      .pipe(catchError(this.handelError));
  }

  getShareFilter(guid: any) {
    let url = environment.base_api_desktop_url + 'SharedUrl?guid=' + guid;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  getAccountData(parentAccountOhCode: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: '',
        },
        {
          name: 'parentAccountOhCode',
          value: parentAccountOhCode,
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: null,
    };
    return this.http
      .post<any[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getLogsCount(shipmentId: any, email: any): Observable<any> {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'Shipment/ChangeLogCountByCat?shipmentNumber=' +
        shipmentId +
        '&userEmail=' +
        email
      )
      .pipe(catchError(this.handelError));
  }

  shipmentLogs(shipmentId: any) {
    let url =
      environment.base_api_desktop_url +
      'Shipment/ChangeLog?shipmentNumber=' +
      shipmentId;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  shipmentPublicSearch(shimpmentNumber: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'shipmentNumber',
          value: shimpmentNumber,
        },
      ],
    };
    return this.http
      .post<any[]>(
        environment.base_api_publicShare_url + 'Shipment/public_Search',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getCurrentSessionData() {
    this.currentAccountData =
      this.currentUserDataService.getCurrentAccountData();
    this.isParent = this.currentAccountData.isParent
      ? this.currentAccountData.isParent
      : false;
  }

  shipmentPublicSearchUrl(shimpmentNumber: any, email: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'shipmentNumber',
          value: shimpmentNumber,
        },
      ],
    };
    let url = '';
    if (email) {
      url =
        environment.base_api_publicShare_url +
        'Shipment/Search?userEmail=' +
        email;
    } else {
      url = environment.base_api_publicShare_url + 'Shipment/Search';
    }
    return this.http
      .post<any[]>(url, request)
      .pipe(catchError(this.handelError));
  }

  accountUsers(accountId: any, searchText: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1000,
      searchText: searchText,
      parameters: [
        {
          name: 'accountId',
          value: accountId,
        },
      ],
      sort_options: [
        {
          column_name: 'firstName',
          direction: 'asc',
        },
        {
          column_name: 'lastName',
          direction: 'asc',
        },
      ],
      filterOptions: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    let url = environment.base_api_desktop_url + 'accountUser';

    return this.http
      .post<any[]>(url, request)
      .pipe(catchError(this.handelError));
  }

  updateSortSysFilter(data: any) {
    let url = environment.base_api_desktop_url + 'Shipment/SystemfilterOrder';
    return this.http.put<any[]>(url, data).pipe(catchError(this.handelError));
  }

  getSystemFilterDetails(userEmail: any, accountId: any) {
    let url =
      environment.base_api_desktop_url +
      'Shipment/Systemfilter/defaultuser?userEmail=' +
      userEmail +
      '&accountId=' +
      accountId +
      '&filterType=shipment';
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  AdvancedFilter(
    searchText: any,
    accountId: any // searchText: any, // offset: any, // accountID: any, // userEmail: any, // isGroup: boolean, // fetch: any
  ) {
    // this.getCurrentSessionData();
    let params = {
      offset: 0,
      fetch: 0,
      search_text: searchText,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'accountId',
          value: accountId,
        },
        {
          name: 'includeSystemDefined',
          value: false,
        },
      ],
      sort_options: [
        {
          column_name: 'displayName',
          direction: 'asc',
        },
      ],
      filter_options: [],
    };

    return this.http.post<any>(
      environment.base_api_desktop_url + 'TableView/Search',
      params
    );
  }
  // end of service code

  shipmentUpdateStatus(shipmentId: any): Observable<any> {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'ShipmentUpdateStatus?isBooking=false',
        shipmentId
      )
      .pipe(catchError(this.handelError));
  }
}
