import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipmentProgressBar',
})
export class ShipmentProgressBarPipe implements PipeTransform {
  transform(
    data: any,
    togglePickupDeliveryLegs: boolean,
    searchText: string
  ): unknown {
    return CommonUtils.getShipmentListingProgressBar(
      data,
      togglePickupDeliveryLegs,
      searchText
    );
  }
}
