import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-shipment-not-found-page',
  templateUrl: './shipment-not-found-page.component.html',
  styleUrls: ['./shipment-not-found-page.component.scss'],
})
export class ShipmentNotFoundPageComponent implements OnChanges {
  searchText: any;
  @Output() searchedText = new EventEmitter<any>();
  @Input() urlSearchedText = '';
  showText = 'Shipment';

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.urlSearchedText &&
      this.urlSearchedText.charAt(0).toLowerCase() === 'b'
    ) {
      this.showText = 'Customs';
    } else {
      this.showText = 'Shipment';
    }
  }

  emitSearchedText() {
    this.searchedText.emit(this.searchText.trim());
  }
}
