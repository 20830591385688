<!-- modal-popup -->
<div id="pop-up2" *ngIf="popupToggle" class="dimScreen">
  <div *ngIf="popupToggle" class="shipment-details" id="shipmentFull" #shipmentFull>
    <div class="overall-scroll-mobtab">
      <button class="close" type="button" *ngIf="!isShipmentDetailFullScreen" (click)="featureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
      </button>
      <div class="body-section">
        <app-shipment-header [selectedShipment]="selectedShipment" [publicShareVisibility]="publicShareVisibility"
          [publicShareType]="publicShareType" (reloadShipmentListFromHeader)="onReloadList($event)"
          (refreshShipmentDetailsFromHeader)="onReloadDetails($event)" [urlShare]="urlShare"
          (prepareUrlShare)="share($event)" [publicShareEnrcyptedData]="publicShareEnrcyptedData"
          [refreshFalse]="refreshFalse" [actionFieldRecords]="actionFieldRecords">
        </app-shipment-header>

        <div class="col-lg-12 p-0">
          <div class="tab-content shipment-tab-topmargin" id="myTabContent">

            <div class="tab-pane fade show active" id="shipment-tab" role="tabpanel"
              aria-labelledby="details-shipment-tab">
              <app-shipment-detail-tab [selectedShipment]="selectedShipment"
                [publicShareVisibility]="publicShareVisibility" [publicShareType]="publicShareType">
              </app-shipment-detail-tab>
            </div>
            <div class="tab-pane fade overall-scroll" *ngIf="selectedShipment.isFullViewAccess" id="profile"
              role="tabpanel" aria-labelledby="profile-tab">
              <app-shipment-documents-tab [selectedShipment]="selectedShipment"
                [publicShareVisibility]="publicShareVisibility">
              </app-shipment-documents-tab>
            </div>
            <div *ngIf="
                selectedShipment?.containers &&
                selectedShipment?.containers?.length > 0
              " class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
              <app-shipment-containers-tab [selectedShipment]="selectedShipment">
              </app-shipment-containers-tab>
            </div>
            <div class="tab-pane fade" id="child" role="tabpanel" aria-labelledby="child-tab" *ngIf="
                selectedShipment?.customs_declarations &&
                selectedShipment?.customs_declarations?.length > 0
              ">
              <app-shipment-customs-tab [selectedShipment]="selectedShipment"
                (setCustomDetails)="loadCustomDetails($event)"></app-shipment-customs-tab>
            </div>
            <div class="tab-pane fade" id="action" role="tabpanel" aria-labelledby="action-tab">
              <app-action-detail-tab [selectedShipment]="selectedShipment"
              (closePopUp)="popUpClose()"
              [currentUserData]="currentAccountData"
              (loadShipmentList)="loadShipmentListById($event)"></app-action-detail-tab>
            </div>

            <div class="tab-pane fade" *ngIf="selectedShipment.isFullViewAccess" id="logs" role="tabpanel"
              aria-labelledby="logs-tab">
              <app-change-logs [selectedShipment]="selectedShipment" [type]="'shipments'"
                (showViewReference)="loadFullReferenceNum($event)"></app-change-logs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>