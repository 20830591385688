import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './shared/services/login.service';
import { UserManagmentService } from './features/admin/users/services/usermanagment.service';
import { CurrentSessionDataService } from './shared/services/current-session-data.service';
import { DataSharingService } from './shared/services/data-sharing.service';
import { MyGroupService } from './features/profile/my-groups/services/my-group.service';
import { NotificationPreferenceService } from './features/profile/notification-preferences/services/notificaion-preference.service';
import { forkJoin } from 'rxjs';
import { WorkflowService } from './features/admin/workflow/services/workflow.service';
import { DataFieldService } from './features/admin/datafield/services/datafield.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loginDisplay = false;
  currentRole: any;
  userEmail: any;
  currentUserData = this.userSessionData.getCurrentUserData();
  currentAccountData = this.userSessionData.getCurrentAccountData();
  userAccountCount: any;
  groupTotalCount: any;
  errorMessage = '';
  isError = false;
  currentUser = this.userSessionData.getCurrentUserData();

  constructor(
    public http: HttpClient,
    public loginService: LoginService,
    public userService: UserManagmentService,
    public userSessionData: CurrentSessionDataService,
    public sharingData: DataSharingService,
    private myGroupService: MyGroupService,
    private notificationPreferenceService: NotificationPreferenceService,
    private dataFieldService: DataFieldService,
    private workflowService: WorkflowService
  ) {
    this.loginDisplay =
      localStorage.getItem('userStatus') == '1' ? true : false;

    window.onunload = function () {
      sessionStorage.removeItem('resentOrgin');
      sessionStorage.removeItem('resentDestination');
      sessionStorage.removeItem('resentConsignee');
      sessionStorage.removeItem('resentShipper');
      sessionStorage.removeItem('recentPickup');
      sessionStorage.removeItem('recentFinalDelivery');
      sessionStorage.removeItem('resentControlling');
      sessionStorage.removeItem('recentOriginCountry');
      sessionStorage.removeItem('recentDestinationCountry');

      sessionStorage.removeItem('BookingRecentOrgin');
      sessionStorage.removeItem('BookingRecentDestination');
      sessionStorage.removeItem('BookingRecentShipper');
      sessionStorage.removeItem('BookingRecentPickup');
      sessionStorage.removeItem('BookingRecentFinalDelivery');

      sessionStorage.removeItem('customsRecentImporterOfRecord');
      sessionStorage.removeItem('customsRecentShipper');
      sessionStorage.removeItem('customsRecentSupplier');
      sessionStorage.removeItem('customsRecentConsignee');
      sessionStorage.removeItem('customsRecentImporter');
      sessionStorage.removeItem('CustomsRecentLoadingPort');
      sessionStorage.removeItem('CustomsRecentDischargePort');
      sessionStorage.removeItem('CustomsRecentPortOfClearance');
      sessionStorage.removeItem('CustomsRecentShipmentOrigin');
      sessionStorage.removeItem('CustomsRecentShipmentDestination');

      sessionStorage.removeItem('recentAccount');

      localStorage.removeItem('guidedTourHeader');

      sessionStorage.removeItem('notificationOrigin');
      sessionStorage.removeItem('notificationDestination');
    };

    if (this.loginDisplay) {
      this.userEmail = this.currentUserData.emailAddress;
      this.currentRole = this.currentUserData.userType;
      this.getUserAccounts();
      this.getGroupNames();
    }
  }

  ngOnInit(): void {
    if (this.loginDisplay) {
      this.getUserImage();
    }
    this.sharingData.errorToasterMsg.subscribe((value) => {
      this.errorMessage = value;
      setTimeout(() => {
        this.closeToasterMsg();
      }, 20000);
    });
    this.sharingData.isErrorToaster.subscribe((value) => {
      this.isError = value;
    });

    if (this.currentRole?.toLowerCase() == "systemadmin") {
      this.cacheAllLookUpValues();
    }
  }

  getUserAccounts() {
    this.myGroupService
      .autoSuggestionForUser('', this.userEmail)
      .subscribe((res: any) => {
        this.userAccountCount = res.total_count;
        localStorage.setItem('userCount', this.userAccountCount);
      });
  }

  getGroupNames() {
    this.myGroupService.getGroupName(this.userEmail).subscribe((res: any) => {
      this.groupTotalCount = res.total_count;
      localStorage.setItem('groupTotalCount', this.groupTotalCount);
    });
  }

  getUserImage() {
    this.userService
      .getUserDetail(this.currentUser.emailAddress)
      .subscribe((res) => {
        let setUser = this.currentUser;
        setUser.imagecontent = res.result.user.imagecontent;
        this.sharingData.imageGetting.next(res.result.user.imagecontent);
        localStorage.setItem(
          'loggedInUser',
          window.btoa(unescape(encodeURIComponent(JSON.stringify(setUser))))
        );
      });
  }

  logout() {
    this.loginService.logout();
  }

  closeToasterMsg() {
    this.sharingData.isErrorToaster.next(false);
    this.sharingData.errorToasterMsg.next('');
  }

  cacheAllLookUpValues() {
    setTimeout(() => {
      const combinedGetMethodAPICall = forkJoin([
        this.notificationPreferenceService.getContainerTypesList(),
        this.notificationPreferenceService.getTransportTypeList(),
        this.dataFieldService.getTypeValueWithType(1),
        this.dataFieldService.getTypeValueWithType(2),
        this.dataFieldService.getTypeValueWithType(3),
        this.dataFieldService.getTypeValueWithType(4),
        this.dataFieldService.getTypeValueWithType(5),
        this.dataFieldService.getTypeValueWithType(6),
        this.dataFieldService.getTypeValueWithType(7),
        this.dataFieldService.getTypeValueWithType(8),
        this.workflowService.getTypeValueWithType(1),
        this.workflowService.getTypeValueWithType(2),
        this.workflowService.getTypeValueWithType(3),
        this.workflowService.getTypeValueWithType(4),
        this.workflowService.getTypeValueWithType(5),
        this.workflowService.getTypeValueWithType(6),
        this.workflowService.getTypeValueWithType(7),
        this.workflowService.getTypeValueWithType(8)]);
      combinedGetMethodAPICall.subscribe();
      this.cacheJsonCollection(); // Post method
    }, 5000)

  }

  cacheJsonCollection() {
    this.dataFieldService.getJsonOrDfd('', this.userEmail, true, 'true', [
      {
        "column_name": "module",
        "value": "shipments,bookings,customs"
      }
    ], true).subscribe();
  }
}
