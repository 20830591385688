<div class="container-fluid">
  <app-progressbar *ngIf="isProgressShow"></app-progressbar>
  <div *ngIf="!dashboardWatchlist && !dashboardCustom" class="row">
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="bookings-total" [ngClass]="{ 'guided-back-drop': isToggleShow }">
        <span class="number-set">{{ totalCustomsCount }}</span>&nbsp;Customs
      </h3>
    </div>
  </div>
  <div class="row" class="no-shipment-wrap" *ngIf="!globalSearchPopup">
    <div *ngIf="
        customsListData.length <= 0 && !isProgressShow && !dashboardWatchlist
      " class="text-center mt-5 pt-5 mobile-set-center dashboardShipmentWrapper">
      <p class="title-set mt-5 pt-5 new-set">No Customs Found</p>
      <span class="detail-set">We found no customs that match filter criteria.</span>
    </div>
    <div *ngIf="customsListData.length <= 0 && isProgressShow" class="text-center mt-5 pt-5">
      <p class="title-set mt-5">Loading Customs</p>
    </div>
    <div *ngIf="
        customsListData.length <= 0 && dashboardWatchlist && !isProgressShow
      " class="text-center mt-5 pt-5 height-set move-top mobile-set-center">
      <p class="mt-5 pt-5 title-set">Start building your customs watchlist!</p>
      <p class="detail-set">
        To keep an eye on high-priority customs, add a customs to the watchlist
        using the action menu on the far right of the customs or from the
        customs details screen.
      </p>
    </div>
  </div>
  <div
    [ngClass]="{'guided-back-drop' : isCustomsSecHide,'guided-visible-sec':isCustomsSecShow,'fileUpload-visible-list':isListComponentShow}"
    class="infiniteScroll custom-list-ctr" id="customs-sec-id" infiniteScroll [infiniteScrollDistance]="0.5"
    [infiniteScrollThrottle]="50" [fromRoot]="true" [scrollWindow]="false" id="custom-sec-id"
    (scrolled)="onScroll($event)" [infiniteScrollDisabled]="isInfiniteDisabled" (scroll)="scrollHandler($event)">
    <div class="shiping-list-card row" id="row-{{ data.forwarder_reference }}" *ngFor="
        let data of customsListData;
        trackBy: trackById;
        let i = index;
        let first = first;
        let last = last
      " [ngClass]="{
        first: first,
        last: last,
        'admin-context-menu-4row':
          sessionUserRole == 'systemadmin' &&
          !checkSFile(data.forwarder_reference),
        'admin-context-menu-5row':
          sessionUserRole == 'systemadmin' &&
          checkSFile(data.forwarder_reference),
        'user-context-menu-3row':
          sessionUserRole == 'user' && checkSFile(data.forwarder_reference),
        'user-context-menu-2row':
          sessionUserRole == 'user' && !checkSFile(data.forwarder_reference)
      }">
      <div id="{{ data.forwarder_reference }}" class="rap-sec" (click)="selectedListCustom(data.forwarder_reference)">
      </div>
      <div class="col-8 col-lg-4 col-xs-7 p-0 bashboard-icon medium-screen-ctrl">
        <div class="status-img">
          <!-- Status icon render with exception color start-->
          <app-status-icon [transport_mode]="data | trasportModeByFileType" [status]="data?.customstatus"
            [exception]="data?.customException"></app-status-icon>
          <!-- Status icon render with exception color end-->

          <div class="status-contant">
            <div class="single-row">
              <span class="shipment-no" [innerHTML]="data.forwarder_reference | highlight : searchText"></span>
              <span class="pga-exists" *ngIf="
                  data.customs_declarations[0]?.customs_agency_statuses
                    ?.length > 0 &&
                  data.customs_declarations[0]?.customs_type !== 'EXP' &&
                  data.customs_declarations[0]?.customs_type !== 'OUT' &&
                  data.customs_declarations[0]?.customs_type !== 'IPT'
                ">PGA Exists</span>
              <span class="mobile-remove" *ngIf="
                  data.customs_declarations[0]?.customs_agency_statuses
                    ?.length > 0 &&
                  data.customs_declarations[0]?.customs_type !== 'EXP' &&
                  data.customs_declarations[0]?.customs_type !== 'OUT' &&
                  data.customs_declarations[0]?.customs_type !== 'IPT' &&
                  data.shipper_reference &&
                  !dashboardWatchlist &&
                  !dashboardCustom
                ">
                -
              </span>
              <span class="shipment-ref-no" *ngIf="
                  data.shipper_reference &&
                  !dashboardWatchlist &&
                  !dashboardCustom
                ">
                <span class="text-truncate-ref" [innerHTML]="
                    'Ref: ' + data.shipper_reference | highlight : searchText
                  ">
                </span>
              </span>
              <span *ngIf="
                  data.favoriteflag && !dashboardWatchlist && !dashboardCustom
                ">
                <img class="view-eye" src="../../../../../assets/img/shipments/eye-list.svg" alt="" />
              </span>
            </div>
            <span *ngIf="dashboardCustom" class="d-block shipment-pickup-txt">
              <span *ngIf="data?.lading_port" [innerHTML]="
                  data.lading_port?.portName
                    | trimmedAddressData
                      : data.lading_port?.stateCode
                      : data.lading_port?.countryCode
                    | highlight : searchText
                "></span><span *ngIf="data?.lading_port && data?.arrival_port">&nbsp;-&nbsp;</span><span
                *ngIf="data?.arrival_port" [innerHTML]="
                  data.arrival_port?.portName
                    | trimmedAddressData
                      : data.arrival_port?.stateCode
                      : data.arrival_port?.countryCode
                    | highlight : searchText
                "></span>
            </span>
            <span *ngIf="!dashboardCustom" class="d-block shipment-pickup-txt">
              <span *ngIf="data.customs_declarations[0]?.customs_type" class="imp" [innerHTML]="
                  data.customs_declarations[0]?.customs_type
                    | customType
                    | highlight : searchText
                "></span>
              <ng-container *ngIf="
                  data.supplier?.name &&
                  data.customs_declarations[0]?.customs_type
                ">
                -
              </ng-container>
              <span *ngIf="data.supplier?.name" [innerHTML]="data.supplier?.name | highlight : searchText">{{
                data.supplier?.name }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col col-lg-1 col-xs-5 text-set-right dashboard-state">
        <div class="status-button">
          <button class="btn btn-primary booking-btn {{
              data.customstatus | customExceptionClass : data.customException
            }} {{ data.customstatus | titlecase }}">
            {{ data.customstatus | titlecase }}
          </button>
        </div>
      </div>
      <div class="col col-lg-2 col-xs-12 custom-mbl-number">
        <p>
          <span [innerHTML]="data | mblSet | highlight : searchText"></span>
        </p>
        <p>
          <span [innerHTML]="data | customHbl | highlight : searchText"></span>
        </p>
      </div>
      <div
        class="col-12 col-lg-3 col-xs-12 d-flex align-items-start center-progress pr-0 booking-road-map customs-road-map medium-screen-ctrl customs-list-icns">
        <div class="road-maps" (click)="selectedListCustom(data.forwarder_reference)"
          [innerHTML]="data | customProgressBar :searchText"></div>
        <div class="progress-icon-ctr">
          <span class="progress-icons">
            <img src="assets/img/document.svg" alt="document" (click)="navigateToDocumentTab(data)" />
            <div class="action-field-count">
              {{ data?.documentCount ? data.documentCount : 0 }}
            </div>
            <div class="doc-common-hover">
              <span>Document</span>
            </div>
          </span>
          <span class="progress-icons" *ngIf="isActionField"
            (click)="selectedListCustom(data.forwarder_reference, true)">
            <img src="assets/img/action-field-icon.svg" alt="action field" />
            <div class="action-field-count">{{ data?.action_fields ? data.action_fields.length : 0 }}
              <div class="doc-common-hover action-fld-ctrl">
                <span>Action Field</span>
              </div>
            </div>
          </span>
          <span class="progress-icons" (click)="share($event, data.forwarder_reference, 'Detail', data)">
            <img src="assets/img/share.svg" alt="" />
            <div class="doc-common-hover shre-ctrl">
              <span>Share</span>
            </div>
          </span>
        </div>
      </div>

      <div class="col-12 col-lg-2 col-xs-12 text-center remove-global-search pl-0">
        <button *ngIf="checkSFile(data.forwarder_reference)" (click)="viewShipment(data.forwarder_reference)"
          class="view-shipment">
          View shipment
        </button>

        <div class="dropdown-section">
          <div class="dropdown">
            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              class="dropdownMenuButton">
              <img src="../../../../../../assets/img/dot-menu.svg" alt="viewshipments" />
            </button>
            <div aria-labelledby="dropdownMenuButton" class="dropdown-menu active-menu-bg" style="left: -70px">
              <a *ngIf="!data.favoriteflag" class="dropdown-item"
                (click)="addWatchList('Add', data?.forwarder_reference)">Watchlist</a>

              <a *ngIf="data.favoriteflag" class="dropdown-item" class="{{
                  data.favoriteflag ? 'checkedClass' : 'uncheckedClass'
                }}" (click)="addWatchList('Remove', data?.forwarder_reference)"><img class="image-okay"
                  src="../../assets/img/active-data.svg" alt="" />Watchlist</a>
              <a class="dropdown-item" *ngIf="dashboardCustom" (click)="
                  share($event, data.forwarder_reference, 'Detail', data)
                ">Share</a>
              <a class="dropdown-item" *ngIf="checkSFile(data.forwarder_reference)"
                (click)="viewShipment(data.forwarder_reference)">View Shipment</a>
              <a class="dropdown-item" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithoutDocuments &&
                  !calculateDiff(data.reImportRequestCompletedWithoutDocuments)
                " (click)="updateShipement(data.forwarder_reference, false)">Update Customs</a>
              <a class="dropdown-item updating-shipment" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  data.reImportRequestPendingWithoutDocuments
                ">
                <div class="refresh-icon"></div>
                <span class="updating-text">Updating Customs</span>
              </a>
              <a class="dropdown-item checkedClass" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithoutDocuments &&
                  calculateDiff(data.reImportRequestCompletedWithoutDocuments)
                " (click)="updateShipement(data.forwarder_reference, false)">
                <img class="image-okay" src="../../assets/img/active-data.svg" alt="" />Updated Customs
              </a>

              <a class="dropdown-item" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithDocuments &&
                  !calculateDiff(data.reImportRequestCompletedWithDocuments)
                " (click)="updateShipement(data.forwarder_reference, true)">Update with Documents
              </a>
              <a class="dropdown-item updating-shipment" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  data.reImportRequestPendingWithDocuments
                ">
                <div class="refresh-icon"></div>
                <span class="updating-text"> Updating with Documents</span>
              </a>
              <a class="dropdown-item checkedClass" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithDocuments &&
                  calculateDiff(data.reImportRequestCompletedWithDocuments)
                " (click)="updateShipement(data.forwarder_reference, true)"><img class="image-okay"
                  src="../../assets/img/active-data.svg" alt="" />Updated with Documents</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="pop-up3" *ngIf="popupToggleList" class="dimScreen">
    <div *ngIf="popupToggleList" [ngStyle]="{
        position: dashboardCustom || dashboardWatchlist ? 'fixed' : ''
      }" class="add-user-popup">
      <button class="close" type="button" (click)="sharePopUpfeatureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
      </button>
      <div class="body-section fix-size">
        <h3>Share <span>Customs</span></h3>
        <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" id="tabDetail">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../../../../../assets/img/share/url-img.svg" alt="" />
              URL
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div class="discription">
              <p class="text-des" *ngIf="detailShipmentShare">
                For users with a Connect account and access to this company,
                they will be able to see the full customs details. Otherwise,
                they will be able to see a public URL with only customs details.
              </p>
              <div class="copy-section">
                <input type="text" id="shareUrl" [value]="urlShare" readonly />
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-customs-details *ngIf="getCustomDetails" [urlCustomsId]="sharecustomsIdToChild" [popupToggle]="popupToggle"
  [selectedCustom]="getCustomDetails" (detailPopClose)="featureHide()"
  (onRefreshDetailsCustoms)="onRefreshDetailFromDetail($event)"
  (onDetailPopupCloseHandler)="onDetailPopupCloseHandler($event)" (prepareUrlShare)="detailShare($event)"
  [ngClass]="{ 'guided-visible-sec': isGuidedTourCustoms }" (loadCustomList)="loadCustomListById($event)">
</app-customs-details>

<div *ngIf="edocUploadTigger" id="edoc-upload-popup" class="dimScreen1">
  <div class="cancel-edocument">
    <button (click)="hideEdocUploadPopup()" class="close" type="button">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
    </button>
    <div class="body-section fix-size">
      <h3>Are you sure you want to exit?</h3>
      <div class="discription">
        Documents are in the process of being uploaded. Exiting may result in
        unsaved changes. Are you sure you want to exit application?
      </div>
    </div>
    <div class="button-setup text-right">
      <button (click)="exitEdocPopup()" class="edit-permissons mr-2">
        Exit page
      </button>
      <button (click)="hideEdocUploadPopup()" class="add-type">
        Stay on page
      </button>
    </div>
  </div>
</div>

<!-- Action Field changes modal start -->
<div tabindex="-1" role="dialog" [hidden]="!showActionFieldCancelModal" id="changes-action-popup"
  data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true"
  style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="removed-action-popup move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" />
          </span>
          <button type="button" class="close delete-close-icon"
            (click)="showActionFieldCancelModal = !showActionFieldCancelModal">
            <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Changes Will Not Be Saved</h3>
            <div class="discription">Are you sure you want to proceed?</div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="
                  showActionFieldCancelModal = !showActionFieldCancelModal
                ">
                GO BACK
              </button>
              <button class="add-type" (click)="switchTab()">PROECEED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Action Field Changes modal end -->