import { Pipe, PipeTransform } from '@angular/core';
import { ProgressBarType } from './shipment.enums';
import { CommonUtils } from './utils';

@Pipe({
  name: 'destinationLocation',
})
export class DestinationLocationPipe implements PipeTransform {
  transform(data: any): unknown {

    let displayText = '<div class="rap">';
    //if (data?.shipment_legs) {
    let shipment_legs = CommonUtils.sortShipmentLegsBySequenceNumber(data);

    let max_leg = shipment_legs != null ? data.shipment_legs[data.shipment_legs?.length - 1] : {};
    displayText +=
      '<span class="font-size-set">' +
      CommonUtils.getPortAndCountryToolTipText(
        CommonUtils.toTitleCase(max_leg?.arrival_port?.portName),
        '',
        max_leg?.arrival_port?.country_name,
        max_leg?.arrival_port?.country_name,
        ''
      ) +
      '</span>';
    displayText += CommonUtils.getRoutingToolTipText(
      'ATA',
      'ETA',
      max_leg?.arrival_actual,
      max_leg?.arrival_estimated,
      ProgressBarType.ShipmentListingPage
    );
    //}
    displayText += '</div>';
    return displayText;
  }
}
