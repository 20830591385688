import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickSearchComponent } from '../components/quick-search/quick-search.component';
import { GlobalSearchListComponent } from '../components/global-search-list/global-search-list.component';
import { HomeRoutingModule } from '../global-search.routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookingGlobalSearchComponent } from '../components/booking-global-search/booking-global-search.component';
import { BookingModule } from '../../booking/booking.module';
import { CustomsModule } from '../../customs/customs.module';
import { CustomsGlobalSearchComponent } from '../components/customs-global-search/customs-global-search.component';

@NgModule({
  declarations: [
    QuickSearchComponent,
    GlobalSearchListComponent,
    BookingGlobalSearchComponent,
    CustomsGlobalSearchComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    BookingModule,
    CustomsModule,
  ],
  exports: [QuickSearchComponent, GlobalSearchListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GlobalSearchModule {}
