<div class="pga-setup" *ngFor="let file of pgaCategory; let i= index">
    <div id="header-{{file.category}}" class="card" [ngClass]="i == 0 ? 'change-icon add-border' : ''">
        <div class="card-header" (click)="addClassFor('#header-' + file.category)">
            <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" [attr.data-target]="'#body-' + file.category"
                    [attr.aria-expanded]="i == 0 ? 'true' : 'false'" aria-controls="collapseOne" class="btn btn-link">
                    <a class="btn-header-link">{{file?.category}}<strong>{{file?.items?.length}}</strong></a>
                </button>
            </h5>
        </div>
        <div id="body-{{ file.category }}" class="collapse" [ngClass]="i == 0 ? 'collapse show' : 'collapse'"
            aria-labelledby="sectionFDA">
            <div class="card-body container-color-bg m-0">
                <div class="tableFixHead pga-changes">
                    <table class="table table-sortable pga-sec" aria-labelledby="document-table" id="pga-table">
                        <caption class="d-none"></caption>
                        <thead>
                            <tr style="height: 46px">
                                <th [appSort]="file?.items" id="pga{{i}}" class="asc sort w-set-two" data-order="asc"
                                    data-name="pga_identifier" data-parent="#pga-table">
                                    PGA
                                </th>
                                <th [appSort]="file?.items" id="program{{i}}" class="asc sort w-set-two"
                                    data-order="asc" data-name="pga_program_code" data-parent="#pga-table">
                                    Program
                                </th>
                                <th [appSort]="file?.items" id="date{{i}}" class="asc factive sort w-set-four"
                                    data-order="desc" data-name="disposition_date" data-parent="#pga-table">
                                    Date
                                </th>
                                <th [appSort]="file?.items" id="desc{{i}}" class="asc sort w-set-five" data-order="desc"
                                    data-name="disposition_code_description" data-parent="#pga-table">
                                    Line Status Desc.
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="height: 46px" *ngFor="let item of file?.items; let i = index">
                                <td class="text-left">
                                    {{item.pga_identifier? item.pga_identifier:'---'}}
                                </td>
                                <td class="text-left">
                                    {{item.pga_program_code? item.pga_program_code:'---'}}
                                </td>
                                <td class="text-left">
                                    {{item.disposition_date | customDateTime}}
                                </td>
                                <td class="text-left pl-2">
                                    {{item.disposition_code_description? item.disposition_code_description:'---'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>