import { Component, OnInit } from '@angular/core';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { BroadCastService } from '../../admin/broadcast/services/broadcast.service';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isGuidedTourHeaderHide: boolean;
  isGuidedTourHeaderShow: boolean;
  currentAccountData: any;
  currentUserData: any;
  broadCastData: any = '';
  close = false;

  constructor(
    private dataSharingService: DataSharingService,
    private broadCastService: BroadCastService,
    private sessionData: CurrentSessionDataService
  ) {
    this.currentAccountData = this.sessionData.getCurrentAccountData();
    this.currentUserData = this.sessionData.getCurrentUserData();
    this.dataSharingService.isGuidedTourHeaderHide.subscribe((value) => {
      this.isGuidedTourHeaderHide = value;
    });

    this.dataSharingService.isGuidedTourHeaderShow.subscribe((value) => {
      this.isGuidedTourHeaderShow = value;
    });

    this.dataSharingService.updateBroadcastMessage.subscribe((value: any) => {
      if (value) {
        this.getBroadCastSettings();
      }
    });
  }

  ngOnInit(): void {
    if (this.currentUserData) {
      this.getBroadCastSettings();
    }
  }

  getBroadCastSettings() {
    this.broadCastService.getBroadCastSettings(false).subscribe((res) => {
      this.broadCastData = res.result;
    });
  }

  closeMessage(value: any) {
    this.close = value;
  }
}
