import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentSessionDataService {
  getCurrentAccountData() {
    let userJson = localStorage.getItem('loggedInUser');
    let featureJson = sessionStorage.getItem('userFeatureList');
    var feature: any = '';
    if (featureJson) {
      feature = featureJson !== null ? JSON.parse(featureJson) : '';
    } else {
      feature =
        userJson !== null
          ? JSON.parse(decodeURIComponent(escape(window.atob(userJson))))
          : '';
    }
    return feature;
  }

  getCurrentUserData() {
    let userJson = localStorage.getItem('loggedInUser');
    return userJson !== null
      ? JSON.parse(decodeURIComponent(escape(window.atob(userJson))))
      : '';
  }
}
