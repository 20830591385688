import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DetailTabName } from 'src/app/common/shipment.enums';
import { CommonUtils } from 'src/app/common/utils';
import { ShipmentServies } from 'src/app/features/shipments/services/shipment.service';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { environment } from 'src/environments/environment';
import { BookingServies } from '../../services/booking.service';
declare var $: any;

@Component({
  selector: 'app-booking-details-header',
  templateUrl: './booking-details-header.component.html',
  styleUrls: ['./booking-details-header.component.scss'],
})
export class BookingDetailsHeaderComponent implements OnChanges, OnInit {
  @Input() selectedBooking: any = [];
  @Input() urlShare: any = [];
  @Output() reloadBookingListFromHeader = new EventEmitter<any>();
  @Output() refreshBookingDetailsFromHeader = new EventEmitter<any>();
  @Output() addFullScreen = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Input() publicShareEnrcyptedData: any = '';
  isProgressShow = false;
  showToastMessage = false;
  isShowMessageFileuploadSec = false;
  message = '';
  watchListToggle: boolean;
  fullScreenStatus: boolean = false;
  isShowMessage = false;
  showMessage: any = '';
  logsTabCount = 0;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  popupToggleDetail = false;
  shipperName: string = '---';
  consigneeName: string = '---';
  scrHeight: any;
  scrWidth: any;
  refLengthCheck: number = 0;
  shipmentRefreshIcon: boolean = false;
  bookingRefreshToast = false;
  hasActionField = this.currentAccountData.isBookingActionField;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  /* istanbul ignore next */
  constructor(
    private bookingService: BookingServies,
    private router: Router,
    private currentSessionUserData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService,
    public dataSharingService: DataSharingService,
    private fileService: FileUploadService,
    private shipmentService: ShipmentServies
  ) {
    this.errorMessage();

    this.fileService.showToastEdocRefresh.subscribe((value) => {
      if (value?.type == 'bookings') {
        let root = this;
        this.isShowMessageFileuploadSec = value.msgShow;
        this.fileService.showToastEdocRefresh.next({});
        setTimeout(function () {
          root.isShowMessageFileuploadSec = false;
        }, 3000);
      }
    });

    this.dataSharingService.refreshShipmentDetailsInHeader.subscribe(
      (value: any) => {
        if (value) {
          this.shipmentRefreshIcon = true;
        } else {
          this.shipmentRefreshIcon = false;
        }
      }
    );

    this.dataSharingService.refreshShipmentDetailsInHeaderToaster.subscribe(
      (value: any) => {
        if (value) {
          this.bookingRefreshToast = true;
          this.isShowMessage = false;
          this.showToastMessage = false;
          setTimeout(() => {
            this.bookingRefreshToast = false;
            this.errorMessage();
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              false
            );
          }, 4000);
        } else {
          this.bookingRefreshToast = false;
        }
      }
    );
  }

  ngOnInit(): void {
    this.loadInitialPageData();
    let selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab == 'Action') {
      this.openActionField();
    }
    else if(selectedTab == 'document/bookings'){
      this.openDocumentTab();
    }
    localStorage.removeItem('selectedTab');
  }

  ngOnChanges(): void {
    this.setCurrentTab(1); // Default is DETAIL tab to be opened
    this.watchListToggle = this.selectedBooking?.favoriteflag;
    let { shipperName, consigneeName } = CommonUtils.getShipperConsigneeName(
      this.selectedBooking
    );
    this.shipperName = shipperName;
    this.consigneeName = consigneeName;
    if (this.currentAccountData) {
      this.getLogsCount();
    }
    // setTimeout(() => {
    //   this.loadActionFieldsDetails();
    // }, 20);
  }

  // getBookingReferenceNumbers(order: any, limit: number) {
  //   let result = CommonUtils.getShipmentReferenceNumbers(order, limit);
  //   return result ? result : false;
  // }

  // getPickupAndDeliveryPortCountry(data: any) {
  //   let message = '';
  //   let pickup = this.getPickupPortCountry(data);
  //   let delivery = this.getDeliveryPortCountry(data);
  //   if (pickup && delivery) {
  //     message += pickup + ' - ' + delivery;
  //   } else if (pickup) {
  //     message += pickup;
  //   } else if (delivery) {
  //     message += delivery;
  //   }
  //   return message;
  // }

  getPickupPortCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.ship_from) {
      message += data.ship_from.city
        ? CommonUtils.toTitleCase(data.ship_from.city) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.ship_from.country_name,
        data.ship_from.state
      );

      message += data.ship_from.country_name
        ? CommonUtils.toTitleCase(data.ship_from.country_name)
        : '';
    } else {
      if (!data.shipment_legs || data.shipment_legs?.length <= 0)
        return message;

      let min_leg = CommonUtils.getFirstShipmentLeg(data);
      if (min_leg && min_leg.lading_port) {
        message =
          CommonUtils.toTitleCase(min_leg.lading_port.portName) +
          ', ' +
          CommonUtils.toTitleCase(min_leg.lading_port.country_name);
      }
    }

    return CommonUtils.removeLastComma(message);
  }

  getDeliveryPortCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.ship_to) {
      message += data.ship_to.city
        ? CommonUtils.toTitleCase(data.ship_to.city) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.ship_to.country_name,
        data.ship_to.state
      );

      message += data.ship_to.country_name
        ? CommonUtils.toTitleCase(data.ship_to.country_name)
        : '';
    } else {
      if (!data.shipment_legs || data.shipment_legs?.length <= 0)
        return message;

      let max_leg = CommonUtils.getLastShipmentLeg(data);
      if (max_leg && max_leg.arrival_port) {
        message =
          CommonUtils.toTitleCase(max_leg.arrival_port.portName) +
          ', ' +
          CommonUtils.toTitleCase(max_leg.arrival_port.country_name);
      }
    }

    return CommonUtils.removeLastComma(message);
  }

  // getBookedDete(data: any) {
  //   let message = '---';
  //   if (data?.booking_confirmed_actual) {
  //     message = CommonUtils.formatDateMMMDDYYYY(data?.booking_confirmed_actual);
  //   }
  //   return message;
  // }

  addWatchList(action: any, bookingId: any) {
    if (action == 'Add') {
      this.appInsightsService.trackAIWatchListEvent(
        AI_CommonEvents.WatchListAdded.toString(),
        bookingId,
        AI_ModulesName.Booking
      );
    }
    if (action == 'Remove') {
      this.appInsightsService.trackAIWatchListEvent(
        AI_CommonEvents.WatchListRemoved.toString(),
        bookingId,
        AI_ModulesName.Booking
      );
    }

    let emailId = this.currentUserData.emailAddress;
    this.isProgressShow = true;

    this.bookingService
      .addRemoveWatchList(bookingId, emailId, action)
      .subscribe({
        next: (addRemoveWatch: any) => {
          this.showToastMessage = true;
          this.message = addRemoveWatch.result;
          this.isProgressShow = false;
          if (action == 'Add') {
            this.watchListToggle = true;
          } else if (action == 'Remove') {
            this.watchListToggle = false;
          }
          this.reloadBookingListFromHeader.emit(bookingId);
          this.hideToast();
        },
      });
  }

  getLogsCount() {
    this.bookingService
      .getLogsCount(
        this.selectedBooking.forwarder_reference,
        this.currentUserData.emailAddress
      )
      .subscribe((res) => {
        if (res) {
          this.logsTabCount = res.recordCount;
        }
      });
  }

  hideToast() {
    let root = this;
    setTimeout(function () {
      root.showToastMessage = false;
      root.isProgressShow = false;
    }, 3000);
  }

  /* istanbul ignore next */
  viewShipment(bookingId: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.ViewShipment.toString(), {
      [AI_CustomProps.BookingId]: bookingId,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });
    let selectedFilters: any;
    this.sessionData();
    if (this.currentAccountData && this.currentAccountData.shipmentIsVisible) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        watchFilter: [],
        exceptionType: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: bookingId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    } else if (
      this.currentAccountData &&
      !this.currentAccountData.shipmentIsVisible
    ) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: bookingId,
        type: 'Single',
        module: 'Shipment',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/dashboard/' + encry;
      window.open(url, '_blank');
    } else {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));

      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountDetail: {
          aliasName: decryUrlFilter.accountDetail.aliasName,
          oH_Code: decryUrlFilter.accountDetail.oH_Code,
          byGroup: decryUrlFilter.accountDetail.byGroup,
          isControllingCustomer:
            decryUrlFilter.accountDetail.isControllingCustomer,
        },
        shipmentId: bookingId,
        type: 'Detail',
        module: 'Shipments',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    }
  }

  /* istanbul ignore next */
  share(bookingId: any, type: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ShareSingleItem.toString(),
      {
        [AI_CustomProps.BookingId]: bookingId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );

    this.popupToggleDetail = true;
    let accountId = '';
    let aliasName = '';
    let isGroup = false;
    let subType = '';
    let isParent = false;
    if (this.publicShareEnrcyptedData) {
      var decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      subType = decryUrlFilter.subType;
    }
    if (this.currentAccountData) {
      if (decryUrlFilter) {
        if (
          !decryUrlFilter.isFullViewAccess &&
          decryUrlFilter.type !== 'viewAll'
        ) {
          accountId = decryUrlFilter.accountDetail.oH_Code;
          aliasName = decryUrlFilter.accountDetail.aliasName;
          isGroup = decryUrlFilter.accountDetail.byGroup;
          isParent = decryUrlFilter.accountDetail.isControllingCustomer;
        } else {
          accountId = this.currentAccountData.accountId;
          aliasName = this.currentAccountData.accountAliasName;
          isGroup = this.currentAccountData.isGroup;
          isParent = this.currentAccountData.isParent;
        }
      } else {
        accountId = this.currentAccountData.accountId;
        aliasName = this.currentAccountData.accountAliasName;
        isGroup = this.currentAccountData.isGroup;
        isParent = this.currentAccountData.isParent;
      }
    } else {
      accountId = decryUrlFilter?.accountDetail?.oH_Code;
      aliasName = decryUrlFilter?.accountDetail?.aliasName;
      isGroup = decryUrlFilter?.accountDetail?.byGroup;
      isParent = decryUrlFilter?.accountDetail?.isControllingCustomer;
    }

    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailPageRefreshed.toString(),
      {
        [AI_CustomProps.ShipmentId]: bookingId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
        [AI_CustomProps.AccountName]: aliasName,
        [AI_CustomProps.IsGroup]: isGroup ? 'True' : 'False',
        [AI_CustomProps.EncryptedId]: this.selectedBooking?.encryptedId,
      }
    );

    this.prepareUrlShare.emit({
      type: type,
      bookingId: bookingId,
      accountId: accountId ? accountId : '',
      aliasName: aliasName ? aliasName : '',
      isGroup: isGroup,
      popUp: true,
      subType: subType,
      isParent: isParent ? isParent : false,
      shipment: { encryptedId: this.selectedBooking?.encryptedId },
    });
  }

  detailShare(event: any) {
    this.prepareUrlShare.emit(event);
  }

  sharePopUpfeatureHide() {
    this.popupToggleDetail = false;
  }

  // getBookingExceptionClass(status: any, exception: any) {
  //   return CommonUtils.getBookingExceptionClass(status, exception);
  // }

  getStatusHint(data: any) {
    return CommonUtils.getStatusHint(data);
  }

  openBookingsFullScreen(event: any, bookingId: any, type: any) {
    this.appInsightsService.trackAIFullScreenViewedEvent(
      bookingId,
      AI_ModulesName.Booking
    );

    if (type == 'list') {
      let selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        watchFilter: [],
        origin: {
          selectedValue: [],
          selectedAccount: [],
        },
        destination: {
          selectedValue: [],
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: [],
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: [],
          selectedAccount: [],
        },

        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: [] },
            { destinationValue: [] },
            { pickupValue: [] },
            { finalDeliveryValue: [] },
          ],
        },
        sorting: [],
        accountId: '',
        bookingId: bookingId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/bookings/' + encry;
      window.open(url, '_blank');
    } else {
      this.fullScreenStatus = true;
      this.addFullScreen.emit(true);
    }
  }

  redirectToLogin() {
    const url = '/bookings/' + this.publicShareEnrcyptedData;
    this.router.navigate(['/login/log-in'], {
      queryParams: { returnUrl: url },
      skipLocationChange: true,
    });
  }

  closeToasterMsg() {
    this.showToastMessage = false;
    this.message = '';
    this.isShowMessage = false;
    this.showMessage = '';
  }

  closeRefreshToast() {
    this.bookingRefreshToast = false;
  }

  closeToasterFromFileUpload() {
    this.isShowMessageFileuploadSec = false;
  }

  /* istanbul ignore next */
  gotoRefSection() {
    $('#navCenter > li> button').removeClass('active');
    $('#home-tab').addClass('active');
    $('#myTabContent > div').removeClass('show active');
    $('#home1').addClass('show active');
    const scrollDiv = window.document.getElementById('bookingRefSec');
    if (scrollDiv != null) {
      scrollDiv.scrollIntoView();
    }
  }

  detailTabScrollTop() {
    const scrollDiv = window.document.getElementById('bookingDetailTab');
    if (scrollDiv != null) {
      setTimeout(() => {
        scrollDiv.scrollIntoView();
      }, 10);
    }
  }
  /* istanbul ignore next */
  refLengthCal() {
    setTimeout(() => {
      const classFullScreen = $('#shipmentFull').hasClass('shipmet-fullwidth');
      if (this.scrWidth >= 1100 && !classFullScreen) {
        this.refLengthCheck = 82;
      } else if (this.scrWidth > 1100 && classFullScreen) {
        this.refLengthCheck = 180;
      } else if (this.scrWidth < 1100 && classFullScreen) {
        this.refLengthCheck = 90;
      } else {
        this.refLengthCheck = 72;
      }
    }, 10);
  }

  loadInitialPageData() {
    this.getScreenSize();
    this.refLengthCal();
    this.closeOKButton();
  }

  /* istanbul ignore next */
  closeOKButton() {
    $('.no-close').on('click', function (e: any) {
      e.stopPropagation();
    });
  }

  refreshBookingDetails() {
    if (this.currentAccountData && this.selectedBooking?.isFullViewAccess) {
      this.getLogsCount();
    }
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailPageRefreshed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedBooking?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
    this.refreshBookingDetailsFromHeader.emit({
      bookingId: this.selectedBooking?.forwarder_reference,
      access: this.selectedBooking?.isFullViewAccess,
    });
  }

  errorMessage() {
    if (!this.currentUserData) {
      this.isShowMessage = true;
      this.showMessage =
        'To see full booking details, please login by clicking here.';
    }
    setTimeout(() => {
      if (this.currentUserData && !this.selectedBooking?.isFullViewAccess) {
        this.showToastMessage = true;
        this.message = 'You are viewing a shared booking with limited access.';
      }
    }, 0);
  }

  sessionData() {
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
  }

  setCurrentTab(tabIndex: number = 1) {
    switch (tabIndex) {
      case 2:
        this.bookingService.activeBookingDetailTab.next(
          DetailTabName.DocumentTab
        );
        break;
      case 3:
        this.bookingService.activeBookingDetailTab.next(
          DetailTabName.ActionTab
        );
        break;
      case 6:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.LogsTab
        );
        break;
      default:
        this.bookingService.activeBookingDetailTab.next(
          DetailTabName.DetailTab
        );
        break;
    }
  }
  loadActionFieldsDetails() {
    if (this.bookingService.openActionTab) {
      this.openActionField();
    }
  }

  openActionField() {
    this.isProgressShow = true;
    setTimeout(() => {
      $('#action-tab').tab('show');
      this.isProgressShow = false;
    }, 0);
  }

  openDocumentTab() {
    this.isProgressShow = true;
    setTimeout(() => {
      $('#profile-tab').tab('show');
      this.isProgressShow = false;
    }, 0);
  }
}
