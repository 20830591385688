import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
declare var $: any;
import {
  CdkDragDrop,
  CDK_DRAG_CONFIG,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ShipmentServies } from 'src/app/features/shipments/services/shipment.service';
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 2,
  zIndex: 10000,
};
@Component({
  selector: 'app-common-default-filter',
  templateUrl: './common-default-filter.component.html',
  styleUrls: ['./common-default-filter.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class CommonDefaultFilterComponent implements OnChanges, OnInit {
  @Input() defaultFilterSelectedValue: any;
  @Input() reportSelectedValue: any;
  @Input() savedFilters: any;
  @Input() systemFilters: any;
  @Input() defaultFiltersList: any = [];
  @Input() reportList: any = [];
  @Input() systemFilterDefault: any;
  @Output() onDefaultFilterSelectedHandler: any = new EventEmitter<any>();
  @Output() onReportSelectedHandler: any = new EventEmitter<any>();
  @Output() guidedTourStartManually = new EventEmitter<any>();
  @Output() userFilterFetch = new EventEmitter<any>();
  @Output() systemFilterFetch = new EventEmitter<any>();
  @Input() savedUserFilterRowId: any;
  @Input() savedSystemFilterRowId: any;
  @Input() currentPage: any;
  tempCheck: any = '';
  tempCheckSysFilter: any = '';
  selectedUserFilterRow: any;
  selectedSystemFilterRow: any;
  clickUserFilter = new Subject();
  clickSystemFilter = new Subject();
  clickReportFilter = new Subject();

  currentUser = this.currentUserService.getCurrentUserData();
  clickDefaultFilter = new Subject();
  defaultFilterShow: boolean = false;
  defaultFilterHide: boolean = false;
  savedFilterShow: boolean = false;
  savedFilterHide: boolean = false;
  defaultCompHide: boolean = false;
  defaultCompShow: boolean = false;
  isDefaultFilterActive: boolean = true;
  isMysavedFilterActive: boolean = false;
  isSytemFilterActive: boolean = false;

  currentUserRole = '';
  constructor(
    private currentUserService: CurrentSessionDataService,
    private appInsightsService: AppInsightsService,
    private dataSharingService: DataSharingService,
    private shipmentService: ShipmentServies
  ) {
    this.dataSharingService.isGuidedTourDefaultFilterHide.subscribe((value) => {
      this.defaultCompHide = value;
    });
    this.dataSharingService.isGuidedTourDefaultFilterShow.subscribe((value) => {
      this.defaultCompShow = value;
    });
    this.dataSharingService.bookingDefaultFilterListHide.subscribe((value) => {
      this.defaultFilterHide = value;
    });
    this.dataSharingService.bookingDefaultFilterListShow.subscribe((value) => {
      this.defaultFilterShow = value;
    });
    this.dataSharingService.bookingSavedFilterListHide.subscribe((value) => {
      this.savedFilterHide = value;
    });
    this.dataSharingService.bookingSavedFilterListShow.subscribe((value) => {
      this.savedFilterShow = value;
    });
    this.currentUserRole = this.currentUser.userType;
  }

  ngOnInit(): void {
    this.loadInitialPageFilterSetting();
    $(document).ready(function () {
      $('#left-slide-icon').click(function () {
        var hidden = $('.filter-nav');
        if (hidden.hasClass('visible')) {
          hidden.animate({ left: '-190px' }, 'slow').removeClass('visible');
        } else {
          hidden.animate({ left: '' }, 'slow').addClass('visible');
          $('#mySavedSec').removeClass('display-sec');
          $('#defaultFilterSec').removeClass('display-sec');
          $('#systemFilterSec').removeClass('display-sec');
          $('#saveSec').removeClass('filter-icon-active');
          $('#sysDefSec').removeClass('filter-icon-active');
          $('#defSec').removeClass('filter-icon-active');
        }
      });
    });
  }

  isFilterActive(value: number) {
    if (value === 1) {
      this.isDefaultFilterActive = true;
      this.isSytemFilterActive = false;
      this.isMysavedFilterActive = false;
    } else if (value === 2) {
      this.isDefaultFilterActive = false;
      this.isSytemFilterActive = true;
      this.isMysavedFilterActive = false;
    } else if (value === 3) {
      this.isDefaultFilterActive = false;
      this.isSytemFilterActive = false;
      this.isMysavedFilterActive = true;
    }
  }

  loadInitialPageFilterSetting() {
    this.clickDefaultFilter.pipe(debounceTime(900)).subscribe((e: any) => {
      this.isFilterActive(1);
      this.onDefaultFilterSelectionHandler(e);
    });

    this.clickUserFilter.pipe(debounceTime(900)).subscribe((e: any) => {
      this.isFilterActive(3);
      this.selectedUserFilter(e);
    });
    this.clickSystemFilter.pipe(debounceTime(900)).subscribe((e: any) => {
      this.isFilterActive(2);
      this.selectedSystemFilter(e);
    });
    this.clickReportFilter.pipe(debounceTime(900)).subscribe((e: any) => {
      this.onReportSelectionHandler(e);
    });
  }
  defaultFilterSecShow = false;
  ngOnChanges(): void {
    if (this.defaultFilterSelectedValue >= 0) {
      this.selectedUserFilterRow = '';
      this.selectedSystemFilterRow = '';
    }
    if (this.tempCheck == '') {
      this.tempCheck = this.savedUserFilterRowId?.length;
    }
    if (this.tempCheckSysFilter == '') {
      this.tempCheckSysFilter = this.savedSystemFilterRowId?.length;
    }
    if (
      this.savedUserFilterRowId?.length > 0 &&
      this.savedFilters?.length > 0 &&
      JSON.stringify(this.tempCheck) !=
        JSON.stringify(this.savedUserFilterRowId)
    ) {
      this.selectedUserFilter(this.savedUserFilterRowId[0]);
      this.tempCheck = '';
      this.savedUserFilterRowId = '';
    }
    if (
      this.savedSystemFilterRowId?.length > 0 &&
      this.systemFilters?.length > 0 &&
      JSON.stringify(this.tempCheckSysFilter) !=
        JSON.stringify(this.savedSystemFilterRowId)
    ) {
      this.selectedSystemFilter(this.savedSystemFilterRowId[0]);
      this.tempCheckSysFilter = '';
      this.savedSystemFilterRowId = '';
    }
    if (this.systemFilters?.length > 0) {
      if (this.currentUserRole === 'user') {
        if (this.systemFilterDefault?.isDisplayFilterSection) {
          this.defaultFilterSecShow = true;
        } else {
          this.defaultFilterSecShow = false;
        }
      } else {
        this.defaultFilterSecShow = true;
      }
    } else {
      this.defaultFilterSecShow = true;
    }
    if (this.selectedUserFilterRow) {
      this.isFilterActive(3);
    } else if (this.selectedSystemFilterRow) {
      this.isFilterActive(2);
    } else if (this.defaultFilterSelectedValue >= 0) {
      this.isFilterActive(1);
    }
  }

  onDefaultFilterSelectionHandler(filterMenu: any) {
    this.defaultFilterSelectedValue = filterMenu.value;
    this.appInsightsService.logEvent(
      AI_CommonEvents.DefaultFilterApplied.toString(),
      {
        [AI_CustomProps.StatusTypes]: filterMenu?.name,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
    this.selectedUserFilterRow = '';
    this.selectedSystemFilterRow = '';
    this.dataSharingService.clearShipmentSearchTextBox.next(true);
    this.onDefaultFilterSelectedHandler.emit(filterMenu);
  }

  onReportSelectionHandler(filterMenu: any) {
    this.selectedUserFilterRow = '';
    this.selectedSystemFilterRow = '';
    this.reportSelectedValue = filterMenu.id;
    this.dataSharingService.clearShipmentSearchTextBox.next(true);
    this.onReportSelectedHandler.emit(filterMenu);
  }

  guidedTourManually() {
    this.appInsightsService.trackAIGuidedTour(AI_ModulesName.Shipment);
    this.guidedTourStartManually.emit(true);
  }

  selectedUserFilter(userFilterRow: any) {
    this.appInsightsService.logEvent('My Saved Filter Applied', {
      'Module Type': userFilterRow?.filterType,
      'Filter Name': userFilterRow?.filterName,
      Filters: userFilterRow?.jsonFilter,
    });
    this.dataSharingService.clearShipmentSearchTextBox.next(true);
    this.selectedUserFilterRow = userFilterRow.id;
    this.selectedSystemFilterRow = '';
    this.userFilterFetch.emit({ userFilterRow: userFilterRow, type: 'view' });
  }

  selectedSystemFilter(systemFilterRow: any) {
    this.appInsightsService.logEvent('System Filter Applied', {
      'Module Type': systemFilterRow?.filterType,
      'Filter Name': systemFilterRow?.filterName,
      Filters: systemFilterRow?.jsonFilter,
    });
    this.dataSharingService.clearShipmentSearchTextBox.next(true);
    this.selectedUserFilterRow = '';
    this.selectedSystemFilterRow = systemFilterRow.id;
    // this.defaultFilterSelectedValue = -1;
    this.systemFilterFetch.emit({
      userFilterRow: systemFilterRow,
      type: 'view',
    });
  }

  editUserFilter(userFilterRow: any) {
    // commented not to reset while going edit mode
    //this.selectedUserFilterRow = '';
    //this.selectedSystemFilterRow = '';
    console.log('edit user filter', userFilterRow);
    this.userFilterFetch.emit({ userFilterRow: userFilterRow, type: 'update' });
  }

  editSystemFilter(userFilterRow: any) {
    // commented not to reset while going edit mode
    //this.selectedUserFilterRow = '';
    //this.selectedSystemFilterRow = '';
    this.systemFilterFetch.emit({
      userFilterRow: userFilterRow,
      type: 'update',
    });
  }

  showGuidedTourIcon() {
    if (this.currentPage === 'Shipments') {
      return true;
    } else {
      return false;
    }
  }
  showDefaultFilter(eve: any, type: string) {
    //$('#accordionPanelsStayOpenExample > div> div').removeClass('display-sec');
    // $('#accordionPanelsStayOpenExample > div> div').removeClass('icon-active');
    switch (type) {
      case 'default':
        $('#mySavedSec').removeClass('display-sec');
        $('#systemFilterSec').removeClass('display-sec');
        $('#saveSec').removeClass('filter-icon-active');
        $('#sysDefSec').removeClass('filter-icon-active');
        if ($('#defaultFilterSec').hasClass('display-sec')) {
          $('#defaultFilterSec').removeClass('display-sec');
          $('#' + eve.target.id).removeClass('filter-icon-active');
        } else {
          $('#defaultFilterSec').addClass('display-sec');
          $('#' + eve.target.id).addClass('filter-icon-active');
        }
        break;
      case 'mySaved':
        $('#defaultFilterSec').removeClass('display-sec');
        $('#systemFilterSec').removeClass('display-sec');
        $('#saveSec').removeClass('filter-icon-active');
        $('#sysDefSec').removeClass('filter-icon-active');
        if ($('#mySavedSec').hasClass('display-sec')) {
          $('#mySavedSec').removeClass('display-sec');
          $('#' + eve.target.id).removeClass('filter-icon-active');
        } else {
          $('#mySavedSec').addClass('display-sec');
          $('#' + eve.target.id).addClass('filter-icon-active');
        }
        break;

      case 'systemFilter':
        $('#defaultFilterSec').removeClass('display-sec');
        $('#mySavedSec').removeClass('display-sec');
        $('#saveSec').removeClass('filter-icon-active');
        $('#defSec').removeClass('filter-icon-active');
        if ($('#systemFilterSec').hasClass('display-sec')) {
          $('#systemFilterSec').removeClass('display-sec');
          $('#' + eve.target.id).removeClass('filter-icon-active');
        } else {
          $('#systemFilterSec').addClass('display-sec');
          $('#' + eve.target.id).addClass('filter-icon-active');
        }
        break;
    }
  }

  isDragging = false;
  drop(event: any) {
    moveItemInArray(
      this.systemFilters,
      event.previousIndex,
      event.currentIndex
    );
  }

  onDragged() {
    this.isDragging = true;
  }

  onDragReleased() {
    setTimeout(() => {
      this.systemFilters.forEach((ele: any, index: number) => {
        ele.displayOrder = index + 1;
      });
      this.shipmentService
        .updateSortSysFilter(this.systemFilters)
        .subscribe((res) => {});
    }, 400);

    this.isDragging = false;
  }
}
