import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/features/header/services/filter.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from './current-session-data.service';
import { DataSharingService } from './data-sharing.service';

@Injectable({
  providedIn: 'root',
})
export class SwitchAccountService {
  isAdmin =
    this.currentSessionData.getCurrentUserData().userType == 'systemadmin'
      ? true
      : false;

  constructor(
    public filterservice: FilterService,
    public currentSessionData: CurrentSessionDataService,
    public router: Router,
    private appInsightService: AppInsightsService,
    private dataSharingService: DataSharingService
  ) { }

  prepareSwitchAccountParams(
    email: any,
    ohCode: any,
    byGroup: any,
    isControllingCustomer: any
  ) {
    let request = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: [
        {
          name: 'userEmail',
          value: email,
        },
        {
          name: 'accountId',
          value: ohCode,
        },
        {
          name: 'byGroupId',
          value: byGroup,
        },
        {
          name: 'isParent',
          value: false,
        },
      ],
      sort_options: [],
      filter_options: [],
    };

    if (isControllingCustomer) {
      request.parameters = [
        {
          name: 'userEmail',
          value: email,
        },
        {
          name: 'accountId',
          value: ohCode,
        },
        {
          name: 'byGroupId',
          value: byGroup,
        },
        {
          name: 'isParent',
          value: true,
        },
      ];
    }

    return request;
  }

  switchAccount(selectedParentRow: any, triggerFrom: any, filterUrl: any) {
    let feature: any = this.currentSessionData.getCurrentAccountData();
    let selectedOH_code = selectedParentRow.oH_Code
      ? selectedParentRow.oH_Code
      : selectedParentRow.child_OH_Code ? selectedParentRow.child_OH_Code : feature.accountId;
    if (
      feature.accountId !== selectedOH_code ||
      selectedParentRow.isControllingCustomer !== feature.isParent
    ) {
      this.filterservice
        .getSwitchAccount(
          this.prepareSwitchAccountParams(
            this.currentSessionData.getCurrentUserData().emailAddress,
            selectedOH_code,
            selectedParentRow.byGroup,
            selectedParentRow.isControllingCustomer
          )
        )
        .subscribe({
          next: (switchPrivilege: any) => {
            localStorage.removeItem('loggedInUser');
            localStorage.removeItem('globalRecentSearch');
            sessionStorage.removeItem('userFeatureList');
            let userDetails = switchPrivilege.result.user;
            let userRole = switchPrivilege.result.accountUserRole;
            let user = {
              fullName: userDetails.fullName,
              firstName: userDetails.firstName,
              lastName: userDetails.lastName,
              companyName: userDetails.companyName,
              emailAddress: userDetails.emailAddress,
              oIAContactName: userDetails.oIAContactName,
              oIAContactEmail: userDetails.oIAContactEmail,
              roleId: userRole?.roleId,
              userType: userDetails.userType,
              accountId: '',
              accountAliasName: '',
              featureList: userRole?.features ? userRole.features : [],
              profilePic: userDetails?.profilePicture,
              imagecontent: userDetails?.imagecontent,
              guidedTour: userDetails.guidedTour,
              joinedDate: userDetails.joinedDate,
              mobile: userDetails.mobile,
            };
            this.setUserFeature(
              user,
              userRole,
              selectedParentRow,
              triggerFrom,
              filterUrl,
              switchPrivilege.result
            );
          },
        });
      return true;
    } else {
      return false;
    }
  }

  setUserFeature(
    user: any,
    userRole: any,
    selectedParentRow: any,
    triggerFrom: any,
    filterUrl: any,
    res: any
  ) {
    if (this.isAdmin) {
      let selectedOH_code = selectedParentRow.oH_Code
        ? selectedParentRow.oH_Code
        : selectedParentRow.child_OH_Code;
      // let shipmentFeatureArray = selectedParentRow.shipment;
      // let bookingFeatureArray = selectedParentRow.booking;
      // let customsFeatureArray = selectedParentRow.customs
      //   ? selectedParentRow.customs
      //   : selectedParentRow.custom;
      let customFeatureList: any = [
        { featureName: 'Dashboard', isVisible: true },
        { featureName: 'MyProfile', isVisible: true },
        { featureName: 'Admin' },
      ];
      let customUserFeature: any = {
        accountId: selectedOH_code,
        accountAliasName: selectedParentRow.aliasName,
      };
      userRole?.features.forEach((e: any) => {
        if (e.featureName.trim() == 'Shipments') {
          customUserFeature['shipmentIsVisible'] = e.isVisible;
          user.shipmentIsVisible = e.isVisible;
          customFeatureList.push({
            featureName: 'Shipments',
            isVisible: e.isVisible,
          });
        } else if (e.featureName.trim() == 'Bookings') {
          customUserFeature['bookingIsVisible'] = e.isVisible;
          user.bookingIsVisible = e.isVisible;
          customFeatureList.push({
            featureName: 'Bookings',
            isVisible: e.isVisible,
          });
        } else if (e.featureName.trim() == 'Customs') {
          customUserFeature['customsIsVisible'] = e.isVisible;
          user.customsIsVisible = e.isVisible;
          customFeatureList.push({
            featureName: 'Customs',
            isVisible: e.isVisible,
          });
        } else if (e.featureName.trim() == 'Reports') {
          customUserFeature['reportIsVisible'] = e.isVisible;
          user.reportIsVisible = e.isVisible;
          customFeatureList.push({
            featureName: 'Report',
            isVisible: e.isVisible,
          });
        }
      });

      user['accountAliasName'] = selectedParentRow.aliasName;
      user['accountId'] = selectedOH_code;
      user['featureList'] = customFeatureList;
      user.isGroup = res.byGroup;
      user.isParent = res.isControllingCustomer;
      user.isBookingActionField = res.isBookingActionField;
      user.isCustomActionField = res.isCustomActionField;
      user.isShipmentActionField = res.isShipmentActionField;
      customUserFeature['featureList'] = customFeatureList;
      customUserFeature['isGroup'] = res.byGroup;
      customUserFeature['isParent'] = res.isControllingCustomer;
      customUserFeature['isBookingActionField'] = res.isBookingActionField;
      customUserFeature['isCustomActionField'] = res.isCustomActionField;
      customUserFeature['isShipmentActionField'] = res.isShipmentActionField;
      setTimeout(() => {
        sessionStorage.setItem(
          'userFeatureList',
          JSON.stringify(customUserFeature)
        );
      }, 0);
    } else {
      let userFeature: any = {
        accountId: userRole?.accountId,
        accountAliasName: userRole?.aliasName,
        featureList: userRole?.features ? userRole.features : [],
        isGroup: res.byGroup,
        isParent: res.isControllingCustomer,
        isBookingActionField: res.isBookingActionField,
        isCustomActionField: res.isCustomActionField,
        isShipmentActionField: res.isShipmentActionField,
      };
      userRole?.features.forEach((e: any) => {
        if (e.featureName.trim() == 'Shipments') {
          user.shipmentIsVisible = e.isVisible;
          userFeature.shipmentIsVisible = e.isVisible;
        } else if (e.featureName.trim() == 'Bookings') {
          user.bookingIsVisible = e.isVisible;
          userFeature.bookingIsVisible = e.isVisible;
        } else if (e.featureName.trim() == 'Customs') {
          user.customsIsVisible = e.isVisible;
          userFeature.customsIsVisible = e.isVisible;
        } else if (e.featureName.trim() == 'Reports') {
          user.reportIsVisible = e.isVisible;
          userFeature.reportIsVisible = e.isVisible;
        }
      });
      user.isGroup = res.byGroup;
      user.isParent = selectedParentRow.isControllingCustomer;
      user.isBookingActionField = res.isBookingActionField;
      user.isCustomActionField = res.isCustomActionField;
      user.isShipmentActionField = res.isShipmentActionField;
      sessionStorage.setItem('userFeatureList', JSON.stringify(userFeature));
    }
    setTimeout(() => {
      localStorage.setItem(
        'loggedInUser',
        window.btoa(unescape(encodeURIComponent(JSON.stringify(user))))
      );
    }, 1000);

    this.appInsightService.setUserAndAccountId(
      user?.emailAddress,
      user?.accountId
    );
    this.appInsightService.logEvent('User Account Changed');

    let root = this;

    setTimeout(function () {
      root.reloadCurrentRoute(filterUrl, triggerFrom);
    }, 1000);
  }

  reloadCurrentRoute(filterUrl: any, triggerFrom: any) {
    if (filterUrl && triggerFrom == 'shareUrlShipment') {
      this.dataSharingService.publicShareSwitchAcc.next(true);
      const currentUrl = `/shipments/${filterUrl}`;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    } else if (filterUrl && triggerFrom == 'shareUrlBooking') {
      this.dataSharingService.publicShareSwitchAcc.next(true);
      const currentUrl = `/bookings/${filterUrl}`;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    } else if (filterUrl && triggerFrom == 'shareUrlCustom') {
      this.dataSharingService.publicShareSwitchAcc.next(true);
      const currentUrl = `/customs/${filterUrl}`;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    } else {
      this.reloadPage();
    }
  }

  reloadPage() {
    location.reload();
  }
}
