import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mblSet'
})
export class MblSetPipe implements PipeTransform {

  transform(data: any): unknown {
    return data.master_bill ? 'MBL: ' + data.master_bill : 'MBL: ---';
  }

}
