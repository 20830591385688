<div *ngIf="isShowMessage" class="newuser-toast-bg full-ship-toast shipment-full-ship-toast shipment-login">
  <div class="text-center contain-bg">
    <span (click)="redirectToLogin()" class="newuser_link">{{
      showMessage
      }}</span>

    <span class="close-button" (click)="closeToasterMsg()">
      <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231">
        <g data-name="Group 2379">
          <path data-name="Path 6681"
            d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
            transform="translate(.288 .287)" style="fill: #01457c; stroke: #01457c; stroke-width: 0.5px" />
        </g>
      </svg>
    </span>
  </div>
</div>
<div class="header-fix" id="shipHeader" [ngClass]="[
    fullScreenStatus ? 'fixed-fullwidth' : 'fixed-halfwidth',
    selectedShipment?.purchase_orders?.length > 0 ? 'full-set' : 'half-set'
  ]">
  <div *ngIf="showToastMessage" class="newuser-toast-bg add-remove-toast toast-limited-acess">
    <div class="text-center contain-bg">
      <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
        {{ message }}
      </span>
      <span class="close-button" (click)="closeToasterMsg()"><img src="../../assets/img/close_whitebg_remove.svg"
          alt="" /></span>
    </div>
  </div>
  <div *ngIf="shipmentRefreshToast" class="newuser-toast-bg add-remove-toast toast-limited-acess page-refreshed">
    <div class="text-center contain-bg">
      <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
        Page is Refreshed
      </span>
      <span class="close-button" (click)="closeRefreshToast()"><img src="../../assets/img/close_whitebg_remove.svg"
          alt="" /></span>
    </div>
  </div>
  <div *ngIf="isShowMessageFileuploadSec" class="newuser-toast-bg newuser-toast-bg-top">
    <div class="text-center contain-bg">
      <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
        Document Categories Successfully Updated
      </span>
      <span class="close-button" (click)="closeToasterFromFileUpload()"><img
          src="../../assets/img/close_whitebg_remove.svg" alt="" /></span>
    </div>
  </div>
  <div class="head-sec set-one">
    <div class="left-sec">
      <div class="trans-status">
        <div class="new-rap-set image-one">
          <!-- Status icon render with exception color start-->
          <app-status-icon [transport_mode]="selectedShipment?.transport_mode" [status]="selectedShipment?.status"
            [exception]="selectedShipment?.exception"></app-status-icon>
          <!-- Status icon render with exception color end-->
        </div>
        <div class="new-rap-set">
          <div class="detail-shipment">
            <div class="ship-number-head">
              <div class="ship-number-one">
                <h3 class="id-number pb-0 pr-0">
                  {{ selectedShipment?.forwarder_reference }}
                </h3>
              </div>
              <div class="ship-number-detail">
                <span class="header-point">•</span>
                <span class="container-sec">{{ selectedShipment?.containerization_type }}
                  <span class="container-sec p-0" *ngIf="selectedShipment?.containers">
                    •
                    <span *ngIf="selectedShipment?.containers?.length <= 1">
                      {{ selectedShipment?.containers?.length }} Container</span>
                    <span *ngIf="selectedShipment?.containers?.length > 1">
                      {{ selectedShipment?.containers?.length }}
                      Containers</span></span>
                </span>
                <a (click)="
                    openShipmentFullScreen(
                      $event,
                      selectedShipment?.forwarder_reference,
                      'list'
                    )
                  " class="pointer first-icon">
                  <img src="../../../../../assets/img/shipments/open-new.svg" alt="" class="mr-1" />
                </a>
              </div>
            </div>
          </div>
          <div class="select-up" *ngIf="
              selectedShipment?.transport_mode?.toUpperCase() !== 'SEA' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'RAIL' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'RAI' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'AIR' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'ROAD' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'ROA' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'TRK' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'COURIER' &&
              selectedShipment?.transport_mode?.toUpperCase() !== 'COU'
            " class="unknown"></div>
          {{ selectedShipment | statusHints }}
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-sm-6 text-right update-state-mobile">
      <div class="image-container">
        <button class="mr-3 btn btn-primary shipment-btn {{
            selectedShipment?.status
              | exceptionClass : selectedShipment?.exception
          }}">
          {{
          selectedShipment?.status === null
          ? "NULL"
          : selectedShipment?.status
          }}
        </button>
        <span class="mobile-head-set">
          <div class="dropdown added-set">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="../../assets/img/actionmenu-header.png" alt="action-menu" />
            </button>
            <div class="dropdown-menu no-close" aria-labelledby="dropdownMenuButton">
              <a *ngIf="!watchListToggle && selectedShipment?.isFullViewAccess" href="javascript:void(0)"
                class="dropdown-item" (click)="
                  addWatchList('Add', selectedShipment?.forwarder_reference)
                "><span>Watchlist</span></a>
              <a *ngIf="watchListToggle && selectedShipment?.isFullViewAccess" href="javascript:void(0)"
                class="dropdown-item checkedClass" (click)="
                  addWatchList('Remove', selectedShipment?.forwarder_reference)
                "><img class="image-okay" src="../../assets/img/active-data.svg" alt="" /><span>Watchlist</span></a>
              <a class="dropdown-item"
                (click)="share(selectedShipment?.forwarder_reference, 'Detail')"><span>Share</span></a>
              <a (click)="refreshShipmentDetails()" *ngIf="!refreshFalse" class="dropdown-item">
                <div *ngIf="shipmentRefreshIcon" class="refresh-icon"></div>
                <span [ngClass]="{ 'refreshing-set': shipmentRefreshIcon }">{{
                  shipmentRefreshIcon ? "Refreshing" : "Refresh"
                  }}</span>
              </a>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="head-sec set-two">
    <div class="left-sec">
      <div class="detail-shipment details-set-one">
        <p style="width: 500px" *ngIf="selectedShipment?.isFullViewAccess">
          <strong>Shipper:</strong>&nbsp;
          {{
          selectedShipment?.consignor?.name
          ? selectedShipment?.consignor?.name
          : "---"
          }}
        </p>
        <p class="fixed-w p-0" [ngClass]="{
            'no-reference': selectedShipment?.purchase_orders?.length <= 0
          }" *ngIf="selectedShipment?.isFullViewAccess">
          <strong>Consignee:</strong>
          <span class="pl-2">{{
            selectedShipment?.consignee?.name
            ? selectedShipment?.consignee?.name
            : "---"
            }}</span>
        </p>
        <p class="ref-width m-0" *ngIf="
            selectedShipment?.purchase_orders?.length > 0 &&
            selectedShipment?.isFullViewAccess
          " [ngClass]="{
            'add-sm-bad':
              (
                selectedShipment?.purchase_orders
                | shipmentReferenceNumberPipe : 0
              ).length < 39,
            'no-reference': selectedShipment?.purchase_orders?.length <= 0
          }">
          <span class="data-ref" [innerHtml]="selectedShipment?.purchase_orders
            | shipmentReferenceNumberPipe : refLengthCheck">
          </span>
          <a href="javascript:void(0)" class="show-more" *ngIf="
              (
                selectedShipment?.purchase_orders
                | shipmentReferenceNumberPipe : 0
              ).length > refLengthCheck
            " (click)="gotoRefSection()">More</a>
        </p>
      </div>
    </div>
    <div class="right-sec">
      <div class="detail-shipment update-top-space" [ngClass]="{
          'ref-change': selectedShipment?.purchase_orders?.length <= 0
        }">
        <h3 class="location pr-0 pb-0" *ngIf="selectedShipment | pickupDeliveryPortCountry">
          <strong>{{ selectedShipment | pickupDeliveryPortCountry }}</strong>
        </h3>
        <p class="p-0" *ngIf="(selectedShipment | estimatedDelivery) !== ''">
          <strong>Estimated Delivery:</strong>
          <span class="pl-2">{{ selectedShipment | estimatedDelivery }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <ul class="nav nav-tabs tab-nav" id="myTab" role="tablist" id="navCenter" #navCenter [ngClass]="[
        selectedShipment?.purchase_orders?.length <= 0
          ? 'ref-change'
          : 'ref-number'
      ]">
      <li class="nav-item" role="presentation" (click)="detailTabScrollTop()">
        <button class="nav-link active" id="details-shipment-tab" data-bs-toggle="tab" data-bs-target="#shipment-tab"
          type="button" role="tab" aria-controls="shipment-tab" aria-selected="true" (click)="setCurrentTab(1)">
          Details
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="selectedShipment?.isFullViewAccess" (click)="tabSelected('docs')">
        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
          role="tab" aria-controls="profile" aria-selected="false" (click)="setCurrentTab(2)">
          Documents
          <strong>{{ selectedShipment?.attachments?.length || 0 }}</strong>
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="
          selectedShipment?.containers &&
          selectedShipment?.containers?.length > 0
        ">
        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button"
          role="tab" aria-controls="contact" aria-selected="false" (click)="setCurrentTab(4)">
          Containers
          <strong>{{ selectedShipment?.containers?.length || 0 }}</strong>
        </button>
      </li>
      <li class="nav-item" role="presentation" (click)="tabSelected('customs')">
        <button class="nav-link" id="child-tab" data-bs-toggle="tab" data-bs-target="#child" type="button" role="tab"
          aria-controls="child" aria-selected="false" *ngIf="
            selectedShipment?.customs_declarations &&
            selectedShipment?.customs_declarations?.length > 0
          " (click)="setCurrentTab(5)">
          Customs
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="isActionField && selectedShipment?.isFullViewAccess"
        (click)="tabSelected('actions')">
        <button class="nav-link" id="action-tab" data-bs-toggle="tab" data-bs-target="#action" type="button" role="tab"
          aria-controls="action" aria-selected="false" (click)="setCurrentTab(3)">
          Actions
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="selectedShipment?.isFullViewAccess && showLogsTab"
        (click)="tabSelected('logs')">
        <button #logTabBtn class="nav-link" id="logs-tab" data-bs-toggle="tab" data-bs-target="#logs" type="button"
          role="tab" aria-controls="logs" aria-selected="false" (click)="setCurrentTab(6)">
          Logs
        </button>
      </li>
    </ul>
  </div>
</div>
<app-progressbar *ngIf="isProgressShow"></app-progressbar>