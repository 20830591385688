import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AI_TabsName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { ShipmentServies } from '../../services/shipment.service';
@Component({
  selector: 'app-shipment-documents-tab',
  templateUrl: './shipment-documents-tab.component.html',
  styleUrls: ['./shipment-documents-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDocumentsTabComponent {
  @Input() selectedShipment: any = [];
  @Input() publicShareVisibility = false;
  //EDOC_LIMIT: number = 20;
  //FILENAME_LIMIT: number = 15;
  isProgressShow = false;
  mimeType: any[] = [];
  currentAccountData: any;
  currentUserData: any;
  scrHeight: any;
  scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private shipmentService: ShipmentServies,
    private appInsightsService: AppInsightsService,
    private currentSessionUserData: CurrentSessionDataService
  ) {
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
  }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  loadInitialPageData() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.DocumentTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
    this.mimeType = CommonUtils.getMimeFileTypes();
    if (this.selectedShipment?.attachments) {
      this.selectedShipment?.attachments.sort((one: any, two: any) =>
        one.created_at > two.created_at ? -1 : 1
      );
    }
    this.getScreenSize();
  }

  downloadFile(data: any, fileType: any, actionType: any, fileNames: any) {
    CommonUtils.downloadBlobFile(
      data,
      fileType,
      actionType,
      fileNames,
      this.mimeType
    );
    this.isProgressShow = false;
  }

  download(
    filepath: any,
    type: any,
    action: any,
    fileNames: any,
    edocType: any
  ) {
    let eventName =
      action == 'preview'
        ? AI_CommonEvents.DocumentPreview.toString()
        : AI_CommonEvents.DocumentDownload.toString();

    this.appInsightsService.trackAIFileDownloadEvent(
      eventName,
      this.selectedShipment?.forwarder_reference,
      AI_ModulesName.Shipment,
      AI_TabsName.DocumentTab,
      fileNames,
      edocType
    );

    let fPath = filepath.replace(/\\/g, '\\\\');
    this.shipmentService.downloadFile(fPath).subscribe(
      (res) => {
        if (res?.result !== '') {
          this.downloadFile(res, type, action, fileNames);
        }
      },
      (error) => console.log('Error in download documents:', error)
    );
  }
}
