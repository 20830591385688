import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipFromAddress'
})
export class ShipFromAddressPipe implements PipeTransform {

  transform(data: any): unknown {
    return CommonUtils.getShipFromAddress(data);
  }

}
