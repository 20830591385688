import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentSessionDataService } from '../services/current-session-data.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(
    public currentUserDataService: CurrentSessionDataService,
    private router: Router
  ) {}

  isCanActiveAdminUser = false;
  isCanActiveUser = false;
  isSessisonAdminUser: any = '';
  userJson: any;
  userList: any;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.userList = this.currentUserDataService.getCurrentUserData();

    if (localStorage.getItem('userStatus') == '1' && this.userList) {
      this.isSessisonAdminUser =
        this.userList.userType == 'systemadmin' ? 'Admin' : '';
      if (
        route.data.roles &&
        route.data.roles.indexOf(this.isSessisonAdminUser) === -1
      ) {
        this.router.navigate(['/dashboard']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/login/log-in'], {
      queryParams: { returnUrl: state.url },
      skipLocationChange: true,
    });
    return false;
  }
}
