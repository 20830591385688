<div class="col-md-12">
    <div class="field-reference" *ngIf="(!isDateRangeShow && !showTimeRange)" [class.order-border]="isDateRangeShow">
        <div class="field-ctr">
            <input type="text" [ngClass]="{'is-invalid-border':hasError && isConditionValueRequired}"
                [(ngModel)]="conditionValue" required placeholder="Enter Value" [disabled]="!isConditionValueRequired"
                (keyup)="debounceValueText.next($event)" /><span class="invalid" [hidden]="!showInvalidSymbol">!</span>
            <div class="is-invalid" *ngIf="hasError" [hidden]="true">
                <small>Please Enter Value</small>
            </div>
        </div>
    </div>
    <div class="col-sm-12 rap-set-one data-soures" *ngIf="isDateRangeShow" clickOutside
        (clickOutside)="dateOutsideClick()">
        <div class="rap">
            <div class="select-sec cal-icon"
                [ngClass]="{'arrowdown' : showCutOffDropdown,'is-invalid-border':hasError && isConditionValueRequired }"
                (click)="cutOffDropdownToggle()" [class.disbale-div]="!isConditionValueRequired"
                [class.add-border]="showCutOffDropdown">
                <span class="date-select">{{conditionValue | dateTimeText : conditionCompare
                    }}</span>
                <div class="tool-set" *ngIf="conditionValue.length > 30">{{conditionValue |
                    dateTimeText :
                    conditionCompare }}
                </div>
            </div>
            <div class="show-sec" *ngIf="showCutOffDropdown">
                <ul class="custom-drop-menu-sec">
                    <div class="accordion" id="accordionExampleset">
                        <div class="card">
                            <div class="card-head" id="headingTwoShipments">
                                <h2 id="accordion-shipments" class="mb-0 collapsed" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                                    <span class="range-selection-area">
                                        Predefined Date
                                    </span>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExampleset">
                                <li class="list-sec" *ngFor="let displayDate of filterDateRange;let i= index"
                                    (click)="onSelectedCutOffRange(displayDate,i)"
                                    [ngClass]="{'selected-bg' : (selectedListDate === i)}">
                                    <span class="range-selection-area">
                                        <h4>{{displayDate.range}}</h4>
                                        <p>{{displayDate.uiFromDate}} -
                                            {{displayDate.uiToday}}</p>
                                    </span>
                                </li>
                            </div>
                        </div>
                    </div>


                    <li class="remove-click">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-head" id="headingOneShipments"
                                    [ngClass]="{'selected-bg' : selectedCustomDate}" (click)="selectDefaultDate()">
                                    <h2 id="accordion-shipments" class="mb-0 collapsed" data-toggle="collapse"
                                        data-target="#collapseOne" (click)="selectDateNGBDate(dps)" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        <span class="range-selection-area">
                                            <h4>Custom Date</h4>
                                        </span>
                                    </h2>
                                </div>
                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p *ngIf="uiDateFrom" class="fix-date-d">{{conditionValue.replace(",", " to ")
                                            | dateTimeText :
                                            conditionCompare}}
                                        </p>
                                        <p class="choose-date" *ngIf="!uiDateFrom">Select Start/End Dates</p>
                                        <ngb-datepicker #dps [(ngModel)]="modelDate" (navigate)="date = $event.next"
                                            (dateSelect)="onDateSelection($event)" [displayMonths]="1"
                                            outsideDays="hidden" ngDefaultControl [dayTemplate]="t">
                                        </ngb-datepicker>
                                        <ng-template #t let-date let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused"
                                                [class.range]="isRange(date)"
                                                [class.faded]="isHovered(date) || isInside(date)">
                                                {{ date.day
                                                }}
                                            </span>
                                        </ng-template>
                                        <div *ngIf="isDateRangeShow && showTimeRange" class="br-set">
                                            <ngb-timepicker class="f1-set" [(ngModel)]="fromTime"
                                                [meridian]="true"></ngb-timepicker>
                                            <span class="time-to"
                                                *ngIf="conditionCompare?.trim()?.toLowerCase()=='between'">to</span>
                                            <ngb-timepicker [(ngModel)]="toTime" [meridian]="true"
                                                *ngIf="conditionCompare?.trim()?.toLowerCase()=='between'"></ngb-timepicker>
                                        </div>
                                        <div class="is-invalid" *ngIf="isDateRangeShow && showTimeRange">
                                            <small
                                                *ngIf="showDateValidationErrorMsg && (!showTimeValidationErrorMsg)">Please
                                                Select Date</small>
                                            <small
                                                *ngIf="showTimeValidationErrorMsg && (!showDateValidationErrorMsg)">Please
                                                Select Time</small>
                                            <small
                                                *ngIf="showTimeValidationErrorMsg && showDateValidationErrorMsg">Please
                                                Select Date
                                                and Time</small>
                                        </div>
                                        <div class="col-12 text-right text-center">
                                            <button class="btn btn-primary add-btn mt-2 mb-2" id="select-btn1"
                                                (click)="validateDateRange(); $event.preventDefault()">SELECT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="is-invalid" *ngIf="isDateRangeShow && isConditionValueRequired" [hidden]="true">
            <small>Please Select Value</small>
        </div>
    </div>

    <!--Start of Time Range Dropdown-->
    <div class="field-module workflow-cond1-last" id="timeRangeDropdown-{{conditionIndex}}"
        *ngIf="(!isDateRangeShow) && showTimeRange">
        <div class="field-ctr">
            <div class="btn-group dropdown" clickOutside (clickOutside)="showTimeRangeDropDown=false"
                [class.disbale-div]="!isConditionValueRequired">
                <button [class.is-invalid-border]="isTimeEmpty || hasError && isConditionValueRequired"
                    (click)="showTimeDropDown()" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="true" class="btn dropdown-toggle custom-menu custom-arow"
                    (blur)="validateDateAndTime()">
                    <span class="settings-filter">
                        {{conditionValue | dateTimeText : conditionCompare }}</span>
                </button>
                <div style="display: block" class="dropdown-menu" [hidden]="!showTimeRangeDropDown">
                    <div class="br-set">
                        <ngb-timepicker class="f1-set" [(ngModel)]="fromTime" [meridian]="true"></ngb-timepicker>
                        <span class="time-to" *ngIf="conditionCompare?.trim()?.toLowerCase()=='between'">to</span>
                        <ngb-timepicker [(ngModel)]="toTime" [meridian]="true"
                            [hidden]="conditionCompare?.trim()?.toLowerCase()!='between'"></ngb-timepicker>
                    </div>
                    <div class="is-invalid" *ngIf="showTimeValidationErrorMsg">
                        <small>Please Select Time.</small>
                    </div>
                    <div class="col-12 text-right text-center">
                        <button class="btn btn-primary add-btn mt-2 mb-3" id="select-btn2"
                            (click)="validateTimeRange(); $event.preventDefault()">SELECT</button>
                    </div>
                </div>
            </div>
            <div class="is-invalid" *ngIf="isTimeEmpty" [hidden]="true">
                <small>Please Select a Value.</small>
            </div>
        </div>
    </div>
    <!--End of Time Range Dropdown-->

</div>