<div class="oia-content-wrap">
    <div class="oia-inner-wrap">
        <app-progressbar [hidden]="!isLoading"></app-progressbar>
        <app-shipment-detail *ngIf="selectedShipmentLen > 0" [urlShipmentId]="urlShipmentId"
            (prepareUrlShare)="detailShare($event)" [selectedShipment]="getShipmentDetails" [refreshFalse]="true"
            [popupToggle]="popupToggle">
        </app-shipment-detail>
        <p class="text-center mt-5 pt-5 title-set" *ngIf="selectedShipmentLen < 1 && !isLoading">No data found</p>
        <p class="text-center mt-5 pt-5 title-set" *ngIf="isLoading">Loading Shipment</p>
        <div id="pop-up3" *ngIf="popupToggleList" class="dimScreen">
            <div *ngIf="popupToggleList" class="add-user-popup">
                <button class="close" type="button" (click)="sharePopUpfeatureHide()">
                    <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
                </button>
                <div class="body-section fix-size">
                    <h3>Share Shipment</h3>
                    <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" id="tabDetail">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                                type="button" role="tab" aria-controls="home" aria-selected="true">
                                <img src="../../../../../assets/img/share/url-img.svg" alt="" />
                                URL
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
                            aria-labelledby="home-tab">
                            <div class="discription">
                                <p class="text-des">For users with a Connect account and access to
                                    this
                                    company,
                                    they will be able to see the full shipment details.
                                    Otherwise, they will be able to see a public URL with only shipment, container
                                    progress
                                    and
                                    customs
                                    information.</p>
                                <div class="copy-section">
                                    <input type="text" id="shareUrl" [value]="url" readonly />
                                    <button class="add-type" (click)="copyLink()">COPY LINK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>