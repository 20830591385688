export interface AccountFilterListTypeParams {
  search_text: string;
  parameters: [];
  sort_options: [];
  filter_options: [];
}

export class AccountFilterListTypeParams
  implements AccountFilterListTypeParams
{
  offset: number = 0;
  fetch: number = 10;
  search_text: string = '';
  parameters: [] = [];
  sort_options: [] = [];
  filter_options: [] = [];

  constructor(
    offset: number,
    fetch: number,
    search_text?: string,
    parameters?: [],
    sort_options?: [],
    filter_options?: []
  ) {
    this.offset = offset;
    this.fetch = fetch;
    this.search_text = search_text ? search_text : '';
    this.parameters = parameters ? parameters : [];
    this.sort_options = sort_options ? sort_options : [];
    this.filter_options = filter_options ? filter_options : [];
  }
}

export interface ActivateDeactivateTypeParams {
  emailAddress: any;
  status: any;
}

export class ActivateDeactivateTypeParams
  implements ActivateDeactivateTypeParams
{
  emailAddress: any;
  status: any;
  environment: any;

  constructor(emailAddress: any, status: any, environment: any) {
    this.emailAddress = emailAddress;
    this.status = status;
    this.environment = environment;
  }
}

export class UserListTypeParams {
  offset: number = 0;
  fetch: number = 100;

  constructor(offset: number, fetch: number) {
    this.offset = offset;
    this.fetch = fetch;
  }
}

export interface UserListParametersValue {
  name: string;
  value: string;
}
export class UserManagListParameters implements UserListParametersValue {
  name: string = '';
  value: string = '';
  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

export interface UserListSortValue {
  column_name: string;
  direction: string;
}
export class UserManagListSort implements UserListSortValue {
  column_name: string = '';
  direction: string = '';
  constructor(column_name: string, direction: string) {
    this.column_name = column_name;
    this.direction = direction;
  }
}

export interface UserTypeParams {
  oH_Code: number;
  accountusers: number;
  searchText: string;
  parameters: UserManagListParameters[];
  sort_options: UserManagListSort[];
  filter_options: UserFilterOption[];
}

export class UserTypeParams implements UserTypeParams {
  offset: number = 0;
  fetch: number = 100;
  search_text: string = '';
  parameters: UserManagListParameters[] = [];
  sort_options: UserManagListSort[] = [];
  filter_options: UserFilterOption[] = [];

  constructor(
    offset: number,
    fetch: number,
    search_text?: string,
    parameters?: UserManagListParameters[],
    sort_options?: UserManagListSort[],
    filter_options?: UserFilterOption[]
  ) {
    this.offset = offset;
    this.fetch = fetch;
    this.search_text = search_text ? search_text : '';
    this.parameters = parameters ? parameters : [];
    this.sort_options = sort_options ? sort_options : [];
    this.filter_options = filter_options ? filter_options : [];
  }
}
export class UserFilterOption {
  column_name: string = '';
  operator: string = '';
  value: string = '';
  constructor(column_name: string, operator: string, value: string) {
    this.column_name = column_name;
    this.operator = operator;
    this.value = value;
  }
}
export interface assignDefaultParams {
  userEmail: string;
  OH_Code: string;
  isDefaultAccount: string;
}

export class assignDefaultParams implements assignDefaultParams {
  userEmail: string = '';
  OH_Code: string = '';
  isDefaultAccount: string = '';

  constructor(userEmail: string, OH_Code: string, isDefaultAccount: string) {
    this.userEmail = userEmail ? userEmail : '';
    this.OH_Code = OH_Code ? OH_Code : '';
    this.isDefaultAccount = isDefaultAccount ? isDefaultAccount : '';
  }
}

export interface AddUserAccountTypeParams {
  userEmail: string;
  OH_Code: string;
  roleId: string;
  isDefaultAccount: boolean;
}

export class AddUserAccountTypeParams implements AddUserAccountTypeParams {
  userEmail: string = '';
  OH_Code: string = '';
  roleId: string = '';
  isDefaultAccount: boolean;

  constructor(
    userEmail: string,
    OH_Code: string,
    roleId: string,
    isDefaultAccount: boolean
  ) {
    this.userEmail = userEmail;
    this.OH_Code = OH_Code;
    this.roleId = roleId;
    this.isDefaultAccount = isDefaultAccount;
  }
}
