import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  public isGuidedTourHeaderHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourHeaderShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourShipment: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourShipmentSecHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourShipmentSecShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourCustomFilterCompHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourCustomFilterCompShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourDefaultFilterHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourDefaultFilterShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourSearchTextBoxHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourSearchTextBoxShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourCustomFilterHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourCustomFilterShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourSortHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourSortShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourShareShipmentHide: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourShareShipmentShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGuidedTourToggleShow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public imageGetting: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public afterGroupDeleteReload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isErrorToaster: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public errorToasterMsg: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public afterNameChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public quickSearch: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public clearShipmentSearchTextBox: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public notLoggedIn: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public eDocUploadTrigger: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public eDocUploadCancelTrigger: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public isLoginProgress: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public bookingDefaultFilterListHide: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public bookingDefaultFilterListShow: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public bookingSavedFilterListHide: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public bookingSavedFilterListShow: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public updateBroadcastMessage: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public unsavedDataBroadcast: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public publicShareSwitchAcc: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public refreshShipmentDetailsInHeader: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public refreshShipmentDetailsInHeaderToaster: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public defaultMenuHighlightService: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
}
