import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { ShipmentServies } from '../../services/shipment.service';

@Component({
  selector: 'app-shipment-detail-tab',
  templateUrl: './shipment-detail-tab.component.html',
  styleUrls: ['./shipment-detail-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailTabComponent implements OnInit {
  @Input() selectedShipment: any = [];
  @Input() publicShareVisibility = false;
  @Input() publicShareType: any = '';
  readMoreNotes: boolean = false;
  readMoreMarksNumbers: boolean = false;
  replaceRegex = '/^s+|s+$/g';
  displayNotes: string = '';
  closeIntransitToast = true;
  changeLogCategory = new BehaviorSubject<any>([]);
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  showCustomField: boolean = false;
  customFieldList: any[] = [];
  // MRN_ACCOUNTS: string[] = ['THEELEFRA', 'HELHANBON', 'SUITSUAMS'];
  // hasMrnField: boolean = false;

  constructor(
    private appInsightsService: AppInsightsService,
    private shipmentService: ShipmentServies,
    private currentSessionUserData: CurrentSessionDataService
  ) { }

  ngOnInit(): void {
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
    if (this.currentUserData) {
      this.getLogsCount();
    }
    this.sortMileStonesByActualDate();
  }

  // getMrnStatus() {
  //   this.hasMrnField = this.MRN_ACCOUNTS.some(
  //     (a) => a == this.currentUserData?.accountId
  //   );
  // }

  loadLogsTab() {
    this.shipmentService.openLogTab.next(true);
  }

  ngOnChanges(): void {
    this.closeIntransitToast = true;
    this.loadMarksAndNumbers();
    this.loadDisplayNotes();
    this.loadCustomField();
    this.sortMileStonesByActualDate();
    // this.getMrnStatus();
  }

  loadMarksAndNumbers() {
    // To remove all the newline from marks and numbers & Notes.
    if (this.selectedShipment && this.selectedShipment.marks_and_numbers) {
      this.selectedShipment.marks_and_numbers =
        this.selectedShipment.marks_and_numbers.replace(this.replaceRegex, '');
      this.readMoreMarksNumbers =
        this.selectedShipment.marks_and_numbers.length > 60;
    }
  }

  loadDisplayNotes() {
    //find first client visible job ntes and display
    this.displayNotes = '';
    if (this.selectedShipment && this.selectedShipment.notes) {
      let clientVisibleNote = this.selectedShipment.notes.find(
        (i: any) =>
          i.note_type?.toString().toLowerCase() === 'client visible job notes'
      );
      if (clientVisibleNote && clientVisibleNote.body) {
        this.displayNotes = clientVisibleNote.body;
      }
      this.displayNotes = this.displayNotes.replace(this.replaceRegex, '');
      this.readMoreNotes = this.displayNotes.length > 180;
    }
  }

  loadCustomField() {
    this.customFieldList = [];
    this.showCustomField = false;
    if (
      this.selectedShipment &&
      this.selectedShipment?.native_reference_numbers
    ) {
      this.customFieldList =
        this.selectedShipment.native_reference_numbers?.filter(
          (x: any) => x.data_source == 'CustomizedField'
        );
      this.showCustomField = this.customFieldList.length > 0;
    }
  }

  sortMileStonesByActualDate() {
    CommonUtils.sortMileStonesByActualDate(this.selectedShipment);
  }

  getLogsCount() {
    this.shipmentService
      .getLogsCount(
        this.selectedShipment.forwarder_reference,
        this.currentUserData.emailAddress
      )
      .subscribe((res) => {
        if (res) {
          let shipmentProgressCount = res.result.filter(function (el: any) {
            return el.changeLogCategory === 1;
          });
          this.changeLogCategory.next(shipmentProgressCount);
        }
      });
  }

  closeIntransitToastFun() {
    this.closeIntransitToast = false;
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }

  doAdditionalReferencesExist(nativeReferenceNumbers: any[]) {
    return CommonUtils.doAdditionalReferencesExist(nativeReferenceNumbers);
  }
}
