import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { NavigationEnd, Router } from '@angular/router';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { SwitchAccountService } from 'src/app/shared/services/switch-account.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { environment } from 'src/environments/environment';
declare var $: any;
import { Location } from '@angular/common';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements AfterViewInit, OnInit {
  loading: boolean = false;

  private searchTerms = new Subject<string>();
  values = '';
  searchText: any;
  selectedFilterValue: any = '';
  selectedFilterValueParent = '';
  displayChild: any = [];
  childLoop: any = '';
  userCompaniesList: any = '';
  adminuserCompaniesList: any[] = [];
  selectedChild: any = '';
  selectedParentIndex: number = 0;
  selectedParentList = -1;
  inputClickToggle: boolean = false;
  inside = false;
  userJson: any;
  userList: any;
  userAccStore: any[] = [];
  recordList = 0;
  isAdmin = false;
  currentSearchText = '';
  currentAccountData: any;
  currentUserData: any;
  groupsList: any[] = [];
  groupCount: any = '';
  openGroupToggle = true;
  checkUnsavedBroadcastMsg = false;
  openAccountsToggle = true;
  @ViewChild('toggleButton') toggleButton: ElementRef | undefined;
  @ViewChild('menu') menu: ElementRef | undefined;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @Output()
  isSessionCheck = new EventEmitter();

  constructor(
    private filterservice: FilterService,
    private router: Router,
    private sessionData: CurrentSessionDataService,
    private switchAccountService: SwitchAccountService,
    private dataSharingService: DataSharingService,
    private location: Location
  ) {
    this.currentAccountData = this.sessionData.getCurrentAccountData();
    this.currentUserData = this.sessionData.getCurrentUserData();
    this.isAdmin =
      this.currentUserData.userType == 'systemadmin' ? true : false;

    this.dataSharingService.afterGroupDeleteReload.subscribe((res: boolean) => {
      if (res) {
        this.currentAccountData = this.sessionData.getCurrentAccountData();
        this.currentUserData = this.sessionData.getCurrentUserData();
        this.recordList = 0;
        this.adminuserCompaniesList = [];
        this.loadAccounts();
        this.loadGroups();
        setTimeout(() => {
          this.selectedFilterValue =
            this.sessionData.getCurrentAccountData().accountAliasName;
        }, 0);
      }
    });

    this.dataSharingService.unsavedDataBroadcast.subscribe((res) => {
      this.checkUnsavedBroadcastMsg = res;
    });
    this.dataSharingService.publicShareSwitchAcc.subscribe((response) => {
      if (response) {
        this.currentAccountData = this.sessionData.getCurrentAccountData();
        this.currentUserData = this.sessionData.getCurrentUserData();
        this.recordList = 0;
        this.adminuserCompaniesList = [];
        this.loadAccounts();
        this.loadGroups();
        setTimeout(() => {
          this.selectedFilterValue =
            this.sessionData.getCurrentAccountData().accountAliasName;
        }, 0);
      }
    });
  }

  ngOnInit(): void {
    if (this.currentUserData) {
      this.loadAccounts();
      this.loadGroups();
    }
    this.checkCurrentUrl();
    let root = this;
    $(document).mouseup(function (e: any) {
      let container = $('#userToggle');
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        root.inputClickToggle = false;
      }
    });
    setTimeout(() => {
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(
          map((event: any) => {
            return event.target.value;
          }),
          filter((res) => res.length > 2),
          debounceTime(800),
          distinctUntilChanged()
        )
        .subscribe((text: string) => {
          this.searchAdminAccount(text);
        });
    }, 0);
  }

  loadAccounts() {
    if (!this.isAdmin) {
      this.loadUserAccount();
    } else {
      this.getAdminUserAcc();
    }
  }

  loadGroups() {
    this.filterservice
      .getGroups(this.currentUserData.emailAddress)
      .subscribe((res: any) => {
        if (res.statusCode == 'OC200') {
          this.groupsList = res.result;
          this.groupCount = res.total_count;
        }
      });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.selectedFilterValue = this.currentAccountData.accountAliasName;
    });
  }

  search(term: string) {
    this.searchTerms.next(term);
  }

  selectedParentCompany(
    parentVal: any,
    childVal: any,
    indexVal: number,
    selectedParentRow: any
  ) {
    if (childVal == null) {
      this.selectedFilterValue = parentVal;
      this.selectedParentList = indexVal;
    }
    this.selectedParentIndex = indexVal;
    this.selectedChild = -1;
    if (selectedParentRow.groupName) {
      selectedParentRow.oH_Code = selectedParentRow.id;
      selectedParentRow.aliasName = selectedParentRow.groupName;
      selectedParentRow.byGroup = true;
      selectedParentRow.isControllingCustomer = false;
    } else {
      selectedParentRow.byGroup = false;
      if (!selectedParentRow.isControllingCustomer) {
        if (childVal && childVal.length > 0) {
          selectedParentRow.isControllingCustomer = true;
        } else {
          selectedParentRow.isControllingCustomer = false;
        }
      }
    }
    selectedParentRow.checkControllingCustomer =
      selectedParentRow.isControllingCustomer;
    if (this.currentUrl) {
      this.location.replaceState('/dashboard');
    }
    this.switchAccount(selectedParentRow);
  }

  openChildList(indexVal: number) {
    this.displayChild[indexVal] = !this.displayChild[indexVal];
  }

  switchAccount(selectedParentRow: any) {
    let switchAcc = this.switchAccountService.switchAccount(
      selectedParentRow,
      'switchAcc',
      false
    );
    if (!switchAcc) {
      this.inputClickToggle = false;
    }
  }

  selectedChildCompany(
    val: any,
    highlightIndex: any,
    selectedChildRow: any,
    isControllingCustomer: any
  ) {
    this.selectedChild = highlightIndex;
    this.selectedFilterValue = val;
    this.selectedParentList = -1;
    selectedChildRow.byGroup = false;
    selectedChildRow.isControllingCustomer = false;
    selectedChildRow.checkControllingCustomer = isControllingCustomer;
    if (this.currentUrl) {
      this.location.replaceState('/dashboard');
    }
    this.switchAccount(selectedChildRow);
  }

  loadUserAccount(): void {
    this.getUserAcc();
  }

  getUserAcc() {
    this.filterservice
      .getData(
        environment.base_api_desktop_url +
          'useraccount?userEmailAddresss=' +
          this.currentUserData.emailAddress
      )
      .subscribe((userData) => {
        this.userCompaniesList = userData.result;
        this.userCompaniesList.forEach((ele: any) => {
          this.displayChild.push(true);
        });
      });
  }

  getAdminUserAcc() {
    this.filterservice
      .getAdminControllingAndNonControllingCustomerList(this.recordList, 20)
      .subscribe((res: any) => {
        if (this.recordList >= 20) {
          this.adminuserCompaniesList = [
            ...this.adminuserCompaniesList,
            ...res.result,
          ];
        } else {
          this.adminuserCompaniesList = res.result;
        }
        res.result.forEach((ele: any) => {
          this.displayChild.push(true);
        });
      });
  }

  searchAdminAccount(event: any) {
    let isAdminCoverted: string = String(this.isAdmin);
    this.filterservice
      .searchAccounts(
        event,
        0,
        30,
        this.currentUserData.emailAddress,
        isAdminCoverted
      )
      .subscribe((response) => {
        if (this.isAdmin) {
          this.adminuserCompaniesList = response.accounts.result
            ? response.accounts.result
            : [];
          this.groupsList = response.groups.result
            ? response.groups.result
            : [];
        } else {
          this.userCompaniesList = response.accounts.result
            ? response.accounts.result
            : [];
          this.groupsList = response.groups.result
            ? response.groups.result
            : [];
        }
      });
  }

  clearFilter(e: any, searchText: any) {
    if (!searchText) {
      this.adminuserCompaniesList = [];
      this.groupsList = [];
      this.displayChild = [];
      this.recordList = 0;
      this.loadGroups();
      if (this.isAdmin) {
        this.getAdminUserAcc();
      } else {
        this.getUserAcc();
      }
    }
  }

  onKey(event: any) {
    this.values = event.target.value;
  }

  textBoxClick(event: any) {
    if (!this.checkUnsavedBroadcastMsg) {
      this.inputClickToggle = !this.inputClickToggle;
      $('.searchFilter_box').toggle();
      $('.toggle-icon').toggleClass('arrow-rotate');
      $('#profileListDropdown').removeClass('show');
      $('#dropdownUser1').removeClass('show');
      $('#globalSearch').addClass('new-design-set');
      $('.quick-search-wrap').addClass('new-design-set');
      this.sessionCheck();
    }
  }

  onScroll(e: any, searchText: any) {
    if (!this.searchText) {
      this.recordList += 20;
      this.getAdminUserAcc();
    }
  }

  openGroup() {
    this.openGroupToggle = !this.openGroupToggle;
  }

  openAccountToggle() {
    this.openAccountsToggle = !this.openAccountsToggle;
  }

  sessionCheck() {
    if (localStorage.getItem('userStatus') != '1') {
      this.isSessionCheck.emit(false);
    }
  }
  currentUrl = true;
  checkCurrentUrl() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/faqs':
          case '/login':
          case '/login/log-in':
          case '/login/sign-up':
          case '/login/thanks-for-register':
          case '/login/account-deactivated':
          case '/login/account-pending':
          case '/login/account-not-found':
          case '/login/register':
          case '/dashboard':
          case '/admin':
          case '/admin/accounts':
          case '/admin/users':
          case '/admin/edoc':
          case '/admin/broadcast':
          case '/admin/datafield':
          case '/admin/workflow':
          case '/admin/actionfield':
          case '/shipments':
          case '/bookings':
          case '/profile':
          case '/profile/myprofile':
          case '/profile/mygroup':
          case '/profile/notificationpreference':
          case '/reports':
          case '/customs':
            this.currentUrl = false;
            break;
        }
      }
    });
  }
}
