export enum ProgressBarLegType {
  PickUpLeg = 1,
  RoutingLeg,
  DeliveryLeg,
  ContainerPickupLeg,
  ContainerDeliveryLeg,
}

export enum ProgressBarType {
  ShipmentListingPage = 1,
  ShipmentDetailPage,
  ShipmentContainerTab,
}

export class ProgressBar {
  portToolTipText: string;
  statusToolTipText: string;
  progressBarType: ProgressBarLegType;
  isTogglePickupDeliveryLegs: boolean;
  fillPercentage: number;
  isCurrentLeg: boolean;
  status: string;
}

export enum UserTableViewPreference {
  Standard = 'standard',
  Table = 'table',
}

export enum DetailTabName {
  DetailTab = 1,
  DocumentTab = 2,
  ActionTab = 3,
  ContainerTab = 4,
  CustomTab = 5,
  LogsTab = 6,
  Invoice = 7,
  PGA = 8,
}
