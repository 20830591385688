import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'statusData'
})
export class StatusDataPipe implements PipeTransform {

  transform(statueName: any, displayDate: any): unknown {
    let message = '';
    if (!statueName || !displayDate) return message;

    message += statueName + ': ';
    message += displayDate ? CommonUtils.formatDateMMMDD(displayDate) : '---';
    return message;
  }

}
