import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AI_TabsName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';
import { BookingServies } from '../../services/booking.service';

@Component({
  selector: 'app-booking-document-tab',
  templateUrl: './booking-document-tab.component.html',
  styleUrls: ['./booking-document-tab.component.scss'],
})
export class BookingDocumentTabComponent implements OnInit {
  @Input() selectedBooking: any = [];
  @Input() publicShareVisibility = false;
  @Input() publicShareEnrcyptedData: any;
  //EDOC_LIMIT: number = 20;
  //FILENAME_LIMIT: number = 15;
  isProgressShow = false;
  mimeType: any[] = [];
  closeToastMsg = true;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private bookingService: BookingServies,
    private appInsightsService: AppInsightsService,
    private currentSessionUserData: CurrentSessionDataService
  ) {}

  ngOnInit(): void {
    this.appInsightsService.logEvent(
      AI_CommonEvents.DocumentTabViewed.toString(),
      {
        [AI_CustomProps.BookingId]: this.selectedBooking?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );

    this.mimeType = CommonUtils.getMimeFileTypes();
    if (this.selectedBooking?.attachments) {
      this.selectedBooking.attachments.sort((one: any, two: any) =>
        one.created_at > two.created_at ? -1 : 1
      );
    }
    this.getScreenSize();
  }

  downloadFile(data: any, fileType: any, actionType: any, fileNames: any) {
    CommonUtils.downloadBlobFile(
      data,
      fileType,
      actionType,
      fileNames,
      this.mimeType
    );
    this.isProgressShow = false;
  }

  download(
    filepath: any,
    type: any,
    action: any,
    fileNames: any,
    edocType: any
  ) {
    let eventName =
      action == 'preview'
        ? AI_CommonEvents.DocumentPreview.toString()
        : AI_CommonEvents.DocumentDownload.toString();

    this.appInsightsService.trackAIFileDownloadEvent(
      eventName,
      this.selectedBooking?.forwarder_reference,
      AI_ModulesName.Booking,
      AI_TabsName.DocumentTab,
      fileNames,
      edocType
    );

    let fPath = filepath.replace(/\\/g, '\\\\');
    this.bookingService.downloadFile(fPath).subscribe(
      (res) => {
        if (res?.result !== '') {
          this.downloadFile(res, type, action, fileNames);
        }
      },
      (error) => console.log('Error in download documents:', error)
    );
  }

  closeInfo() {
    this.closeToastMsg = false;
  }

  viewShipment() {
    this.appInsightsService.logEvent(AI_CommonEvents.ViewShipment.toString(), {
      [AI_CustomProps.BookingId]: this.selectedBooking?.forwarder_reference,
      [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
    });
    let selectedFilters: any;
    if (this.currentAccountData && this.currentAccountData.shipmentIsVisible) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: this.selectedBooking.forwarder_reference,
        type: 'Single',
      };
      var encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    } else if (
      this.currentAccountData &&
      !this.currentAccountData.shipmentIsVisible
    ) {
      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: this.selectedBooking.forwarder_reference,
        type: 'Single',
        module: 'Shipment',
      };
      var encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/dashboard/' + encry;
      window.open(url, '_blank');
    } else {
      var decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));

      selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountDetail: {
          aliasName: decryUrlFilter.accountDetail.aliasName,
          oH_Code: decryUrlFilter.accountDetail.oH_Code,
          byGroup: decryUrlFilter.accountDetail.byGroup,
          isControllingCustomer:
            decryUrlFilter.accountDetail.isControllingCustomer,
        },
        shipmentId: decryUrlFilter.bookingId,
        type: 'Detail',
        module: 'Shipments',
      };
      var encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    }
  }
}
