import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oiaReferenceNumber'
})
export class OiaReferenceNumberPipe implements PipeTransform {

  transform(container: any): unknown {
    return container?.oiaReference
      ? container.oiaReference.sort().join(', ')
      : '---';
  }

}
