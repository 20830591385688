<ul class="nav nav-tabs new-one" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
      aria-selected="true">ckeditor</a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

    <ckeditor [config]="Config" [editor]="Editor" (change)="onChange($event)" [(ngModel)]="editorContent"></ckeditor>

    <div [innerHTML]="editorContent | safe:'html' "></div>
  </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">


  </div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">


  </div>
</div>