import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { UserManagmentService } from 'src/app/features/admin/users/services/usermanagment.service';
import { FilterService } from 'src/app/features/header/services/filter.service';
import {
  AI_AccountEvents,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { environment } from 'src/environments/environment';
import { CurrentSessionDataService } from '../services/current-session-data.service';
import { DataSharingService } from '../services/data-sharing.service';
import { SwitchAccountService } from '../services/switch-account.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    public dataSharingService: DataSharingService,
    private appInsightsService: AppInsightsService,
    private filterService: FilterService,
    private currentSessionData: CurrentSessionDataService,
    private userManagmentService: UserManagmentService,
    private switchAccount: SwitchAccountService
  ) {
    this.currentUser =
      this.currentSessionData.getCurrentUserData().emailAddress;
  }

  currentUser: any;
  /* istanbul ignore next */
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.statusCode == 404) {
            this.appInsightsService.logEvent('User Token', {
              Url: req.urlWithParams,
              Token: `Bearer ${localStorage.getItem('authToken')}`,
            });
            const result = evt.body?.result;
            if (
              result.toString().includes('does not have persmission to see')
            ) {
              console.log('Error ', result);
              return;
            }

            if (
              this.currentSessionData.getCurrentUserData().userType ==
              'systemadmin'
            ) {
              this.filterService
                .getAutoCompleteSearchRecords('', 0, 1)
                .subscribe((accountRes: any) => {
                  this.switchAccFn(accountRes.result[0]);
                });
            } else if (
              this.currentSessionData.getCurrentUserData().userType == 'user'
            ) {
              this.filterService
                .getData(
                  environment.base_api_desktop_url +
                  'useraccount?userEmailAddresss=' +
                  this.currentSessionData.getCurrentUserData().emailAddress
                )
                .subscribe((userData) => {
                  let listAccounts = userData.result;
                  if (listAccounts.length >= 1) {
                    this.switchAccFn(listAccounts[0]);
                  } else {
                    this.appInsightsService.logEvent(
                      AI_AccountEvents.AccountDeActivated
                    );
                    this.dataSharingService.isLoginProgress.next(false);
                    localStorage.clear();
                    sessionStorage.clear();
                    localStorage.setItem('userEmail', this.currentUser);
                    localStorage.setItem('userStatus', '0');
                    window.location.href =
                      environment.localUrl + '/login/account-deactivated';
                  }
                });
            }
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            this.appInsightsService.logError(err);
          } catch (e) { }
        }
        return of(err);
      })
    );
  }

  switchAccFn(accountRes: any) {
    let accountDetail = {
      aliasName: accountRes.aliasName,
      oH_Code: accountRes.oH_Code,
      byGroup: false,
      shipment: {
        isVisible: accountRes.shipment ? accountRes.shipment.isVisible : false,
      },
      booking: {
        isVisible: accountRes.booking ? accountRes.booking.isVisible : false,
      },
      customs: {
        isVisible: accountRes.customs ? accountRes.customs.isVisible : false,
      },
    };
    this.switchAccount.switchAccount(accountDetail, '', '');
  }
}
