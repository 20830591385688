<div class="container-fluid" id="shimpmentListComp">
  <app-progressbar *ngIf="isProgressShow"></app-progressbar>
  <div class="row" *ngIf="isShowToggle && currentSessionUser">
    <div [ngClass]="{ 'guided-back-drop': isShipmentToggleShow }" *ngIf="!dashboardShipment && !dashboardWatchlist"
      id="shipmentToggle" class="d-flex align-items-center justify-content-between">
      <h3 class="shipment-total">
        <span class="number-set">{{ totalShipmentCount }}</span> Shipments
      </h3>
    </div>
  </div>

  <div class="std-cntr" [class.two-icon-cntr]="!isActionField" [class.scroll-empty]="shipmentData?.length <= 0">
    <ng-container id="standard-view" *ngIf="!isTableView">
      <div class="doc-common-hover-exception action-fld-ctrl doc-first-row doc-std" [hidden]="!showDocHintText">
        <span>Document</span>
      </div>
      <div class="doc-common-hover-exception action-fld-ctrl act-first-row act-std" [hidden]="!showActionHintText">
        <span>Action Field</span>
      </div>
      <div class="doc-common-hover-exception shr-first-row shr-std" [hidden]="!showShareHintText">
        <span>Share</span>
      </div>
    </ng-container>
    <!----standard view start-->
    <ng-container *ngIf="!isTableView">
      <!-- Record not found start -->
      <ng-container [ngTemplateOutlet]="recordNotFound"
        [ngTemplateOutletContext]="{ viewType: isTableView }"></ng-container>
      <!-- Record not found end -->
      <div [ngClass]="{
        'guided-back-drop': isShipmentSecHide,
        'guided-visible-sec': isShipmentSecShow,
        'fileUpload-visible-list': isListComponentShow
      }" class="infiniteScroll select-last-shipment shipment-list-ctr" infiniteScroll [infiniteScrollDistance]="0.5"
        [infiniteScrollThrottle]="50" [fromRoot]="true" [scrollWindow]="false" (scrolled)="onScroll($event)"
        [infiniteScrollDisabled]="isInfiniteDisabled" id="shipment-sec-id" (scroll)="scrollHandler($event)">
        <div class="shiping-list-card row justify-content-center align-items-center"
          id="row-{{ data.forwarder_reference }}" *ngFor="
          let data of shipmentData;
          let i = index;
          let first = first;
          let last = last; trackBy:trackById
        " [ngClass]="{
          first: first,
          last: last,
          'admin-section': sessionUserRole == 'systemadmin && last',
          'admin-context-menu': sessionUserRole == 'systemadmin',
          'user-context-menu': sessionUserRole == 'user'
        }">
          <div class="rap-sec" id="{{ data.forwarder_reference }}"
            (click)="selectedListShipment(data.forwarder_reference)"></div>
          <div class="col col-lg-3 col-xs-7 pl-0">
            <div class="status-img">
              <!-- Status icon render with exception color start-->
              <app-status-icon [transport_mode]="data?.transport_mode" [status]="data?.status"
                [exception]="data?.exception"></app-status-icon>
              <!-- Status icon render with exception color end-->
              <div class="status-contant">
                <div class="single-row">
                  <span class="shipment-no" [innerHTML]="
                    data.forwarder_reference | highlight : searchText
                  "></span>
                  <span *ngIf="!dashboardShipment && !dashboardWatchlist" class="shipment-ref-no">
                    <span *ngIf="data.purchase_orders?.length > 0" class="text-truncate-ref"
                      [innerHTML]="data.purchase_orders | shipmentReferenceNumberPipe : 15 | highlight : searchText">
                    </span>
                  </span>
                  <span *ngIf="
                    data.favoriteflag &&
                    !dashboardShipment &&
                    !dashboardWatchlist
                  ">
                    <img class="view-eye" src="../../../../../assets/img/shipments/eye-list.svg" alt="" />
                  </span>
                </div>
                <span class="d-block shipment-pickup-txt" [title]="data | statusHints"
                  [innerHTML]="data | statusHints | highlight : searchText"></span>
              </div>
            </div>
          </div>

          <div class="col col-lg-2 col-xs-5 text-set-right">
            <div class="status-button">
              <button class="btn btn-primary shipment-btn"
                class="{{ data?.status | exceptionClass : data?.exception }}">
                {{ data.status | titlecase }}
              </button>
            </div>
          </div>

          <div class="col-12 col-lg-7 col-xs-12 d-flex align-items-start center-progress pr-0 ship-list-icns">
            <div class="progress-bar-section col" #progressBarSection
              [innerHTML]="data | shipmentProgressBar : togglePickupDeliveryLegs : searchText"
              (click)="selectedListShipment(data.forwarder_reference)"></div>

            <div class="progress-icon-ctr">
              <span class="progress-icons" (mouseenter)="showDocHintText=i==0?true:false"
                (mouseleave)="showDocHintText=false">
                <img src="assets/img/document.svg" alt="document" (click)="navigateToDocumentTab(data)" />
                <div class="action-field-count">
                  {{ data?.documentCount ? data.documentCount : 0 }}
                </div>
                <div class="doc-common-hover" *ngIf="i!=0">
                  <span>Document</span>
                </div>
              </span>
              <span class="progress-icons" (click)="selectedListShipment(data.forwarder_reference, true)"
                *ngIf="isActionField" (mouseenter)="showActionHintText=i==0?true:false"
                (mouseleave)="showActionHintText=false">
                <img src="assets/img/action-field-icon.svg" alt="action field" />
                <div class="action-field-count">
                  {{ data?.action_fields ? data.action_fields.length : 0 }}
                </div>
                <div class="doc-common-hover action-fld-ctrl" *ngIf="i!=0">
                  <span>Action Field</span>
                </div>
              </span>
              <span class="progress-icons" (click)="share($event, data.forwarder_reference, 'Detail', data)"
                (mouseenter)="showShareHintText=i==0?true:false" (mouseleave)="showShareHintText=false">
                <img src="assets/img/share.svg" alt="share icon" />
                <div class="doc-common-hover shre-ctrl" *ngIf="i!=0">
                  <span>Share</span>
                </div>
              </span>
            </div>

            <div class="dropdown-section">
              <div class="dropdown dot-menu-ctr dot-menu-cntrl">
                <button type="button" class="dropdownMenuButton" id="dropdownMenuButton-{{ data.forwarder_reference }}"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="../../../../../../assets/img/dot-menu.svg" alt="viewshipments" />
                  <div class="dot-menu-hover">
                    <span>More</span>
                  </div>
                </button>
                <div class="dropdown-menu active-menu-bg" aria-labelledby="dropdownMenuButton" style="left: -70px">
                  <a *ngIf="!data.favoriteflag" class="dropdown-item"
                    (click)="addWatchList('Add', data?.forwarder_reference)">Watchlist</a>
                  <a *ngIf="data.favoriteflag" class="dropdown-item" class="{{
                    data.favoriteflag ? 'checkedClass' : 'uncheckedClass'
                  }}" (click)="addWatchList('Remove', data?.forwarder_reference)"><img class="image-okay"
                      src="../../assets/img/active-data.svg" alt="" />Watchlist</a>

                  <a class="dropdown-item" *ngIf="
                    sessionUserRole == 'systemadmin' &&
                    !data?.reImportRequestPendingWithoutDocuments &&
                    !calculateDiff(
                      data.reImportRequestCompletedWithoutDocuments
                    )
                  " (click)="updateShipement(data.forwarder_reference, false)">Update Shipment</a>
                  <a class="dropdown-item updating-shipment" *ngIf="
                    sessionUserRole == 'systemadmin' &&
                    data?.reImportRequestPendingWithoutDocuments
                      && data?.reImportRequestCompletedWithoutDocuments!='errored'
                  ">
                    <div class="refresh-icon"></div>
                    <span class="updating-text">Updating Shipment</span>
                  </a>
                  <a class="dropdown-item checkedClass" *ngIf="
                    sessionUserRole == 'systemadmin' &&
                    !data?.reImportRequestPendingWithoutDocuments &&
                    calculateDiff(
                      data?.reImportRequestCompletedWithoutDocuments
                    )
                  " (click)="updateShipement(data.forwarder_reference, false)">
                    <img class="image-okay" src="../../assets/img/active-data.svg" alt="" />Updated Shipment
                  </a>
                  <a class="dropdown-item checkedClass" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  data?.reImportRequestCompletedWithoutDocuments=='errored'">
                    <img class="image-okay" src="../../assets/img/alert-triangle.svg" alt="" />Error Update Shipment
                  </a>
                  <a class="dropdown-item" *ngIf="
                    sessionUserRole == 'systemadmin' &&
                    !data.reImportRequestPendingWithDocuments &&
                    !calculateDiff(data?.reImportRequestCompletedWithDocuments)
                  " (click)="updateShipement(data.forwarder_reference, true)">Update with Documents
                  </a>
                  <a class="dropdown-item updating-shipment" *ngIf="
                    sessionUserRole == 'systemadmin' &&
                    data.reImportRequestPendingWithDocuments
                    && data?.reImportRequestCompletedWithDocuments!='errored'
                  ">
                    <div class="refresh-icon"></div>
                    <span class="updating-text"> Updating with Documents</span>
                  </a>
                  <a class="dropdown-item checkedClass" *ngIf="
                    sessionUserRole == 'systemadmin' &&
                    !data.reImportRequestPendingWithDocuments &&
                    calculateDiff(data?.reImportRequestCompletedWithDocuments)
                  " (click)="updateShipement(data.forwarder_reference, true)"><img class="image-okay"
                      src="../../assets/img/active-data.svg" alt="" />Updated with Documents</a>
                  <a class="dropdown-item checkedClass" *ngIf="
                            sessionUserRole == 'systemadmin' &&
                             data?.reImportRequestCompletedWithDocuments=='errored'"><img class="image-okay"
                      src="../../assets/img/alert-triangle.svg" alt="" />Error Update with
                    Documents</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!----standard view end-->
  <!------>
  <!------>
  <!------>
  <!------>
  <!------>
  <ng-container *ngIf="isTableView && tableViewColumnHeaderList.length <= 0">
    <div class="text-center mt-5 pt-5 mobile-set-center dashboardShipmentWrapper">
      <p class="title-set mt-5 pt-5">
        No column mapping found for this account or group selected
      </p>
    </div>
  </ng-container>

  <!----table view start-->
  <div class="table-cntr" [class.two-icon-cntr]="!isActionField">
    <ng-container id="table-view" *ngIf="isTableView">
      <div class="doc-common-hover-exception action-fld-ctrl doc-first-row" [hidden]="!showDocHintText">
        <span>Document</span>
      </div>
      <div class="doc-common-hover-exception action-fld-ctrl act-first-row" [hidden]="!showActionHintText">
        <span>Action Field</span>
      </div>
      <div class="doc-common-hover-exception shr-first-row" [hidden]="!showShareHintText">
        <span>Share</span>
      </div>
    </ng-container>

    <ng-container *ngIf="isTableView && tableViewColumnHeaderList?.length > 0">
      <div class="table-view-overall-ctr">
        <div class="table-view-ctr-last-th"></div>
        <div class="table-view-ctr tableFixHead" [ngClass]="{'is-filter-available':isCustomFilterAdded}" infiniteScroll
          [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="50" (scrolled)="onScroll($event)"
          [scrollWindow]="false" [fromRoot]="true" [infiniteScrollDisabled]="isInfiniteDisabled">
          <table class="table-view-ctr-cntrl">
            <caption class="d-none"></caption>
            <thead>
              <!--To render table view actual header Details start-->
              <tr clickOutside (clickOutside)="closeHeaderDropDown()">
                <th class="shipment-table-btn {{ header.displayName?.replace(' ', 'dynamic_') }}-column" *ngFor="
                    let header of tableViewColumnHeaderList;
                    let index = index
                  " (mousedown)="onMouseDown($event, index)">
                  <span class="ui-column-resizer"></span>
                  <span class="header-sort {{ header.displayName?.replace(' ', 'dynamic_') }}-column">
                    <button class="gridHeader"
                      [class.menu-active]="headerDropdownPopupMenuIndex == index || selectedColumnIndexList.includes(index)"
                      (click)="headerDropdownPopupMenuClick(index)">
                      {{ header.displayName }}
                    </button>
                    <div class="menu-dropdown-ctr" [hidden]="headerDropdownPopupMenuIndex != index">
                      <div class="menu-dropdown">
                        <div class="filtersearchBox" [hidden]="!header?.isSearch">
                          <input type="text" placeholder="Search by {{ header.displayName }}" class="input-set"
                            [(ngModel)]="searchColumnInput[index]" (keyup)="columnSearchDebounceText.next([
                                header.displayName,
                                header?.fields,
                                header.elementType?.displayText,
                                index
                              ])" />
                          <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-0"
                            class="new-close" [hidden]="searchColumnInput[index]==''" (click)="clearText(
                            header.displayName,
                            header?.fields,
                            header.elementType?.displayText,
                            index)">
                          <img src=" ../../assets/img/search.svg" alt="Search" class="search-icon" (click)="
                          headerDropdownPopupMenuSearch(
                            header.displayName,
                            header?.fields,
                            header.elementType?.displayText,
                            index
                          )
                        " />
                        </div>
                        <div class="sort-by-name" style="cursor: pointer;"
                          (click)="headerDropdownPopupSortDirectionClick( header?.fields?header?.fields[0]?.value:'', header.elementType?.displayText, header.displayName)">
                          <img src="/../../assets/img/sortby.svg" alt="sort by" />
                          Sort by <span
                            *ngIf="columnSortSelectedField==header.displayName">{{headerDropdownPopupSortDirection}}</span>
                        </div>
                      </div>
                    </div>
                  </span>
                </th>
                <th class="rm-background">
                  <button class="gridHeader">docs</button>
                </th>
              </tr>
              <!--To render table view actual header Details end-->
            </thead>
            <tbody id="shipment-table-view-sec">
              <!-- Record not found start -->
              <tr *ngIf="shipmentData?.length <= 0">
                <td colspan="12" class="no-record-fnd">
                  <ng-container [ngTemplateOutlet]="recordNotFound"
                    [ngTemplateOutletContext]="{ viewType: isTableView }"></ng-container>
                </td>
              </tr>
              <!-- Record not found end -->
              <tr id="tv-row-{{ data.forwarder_reference }}" *ngFor="
                  let data of shipmentData;
                  let i = index;
                  let first = first;
                  let last = last; trackBy:trackById
                " [ngClass]="{
                  first: first,
                  last: last,
                  'admin-section': sessionUserRole == 'systemadmin && last',
                  'admin-context-menu': sessionUserRole == 'systemadmin',
                  'user-context-menu': sessionUserRole == 'user'
                }">

                <td *ngFor="let header of tableViewColumnHeaderList" [ngSwitch]="header.displayName | lowercase"
                  class="{{ header.displayName?.replace(' ', 'dynamic_')}}-column">
                  <span class="rap-header-click" (click)="selectedListShipment(data.forwarder_reference)"></span>
                  <!--To render Shipment ID, Watchlist, Container Details-->
                  <div *ngSwitchCase="'shipment'" class="shipment-tble-col">
                    <div class="table-column-frozen-div first-shipment-no">
                      <!--To render Transport mode, Shipment Details-->
                      <div class="shipment-no-ctr status-img pl-0"
                        (click)="selectedListShipment(data.forwarder_reference)">
                        <!-- Status icon render with exception color start-->
                        <app-status-icon [transport_mode]="data?.transport_mode" [status]="data?.status"
                          [exception]="data?.exception"></app-status-icon>
                        <!-- Status icon render with exception color end-->
                        <span class="shipment-no pl-1" [innerHTML]="
                            data.forwarder_reference | highlight : searchText
                          "></span>
                      </div>
                      <!--To render Watchlist Details-->
                      <span *ngIf="
                          data.favoriteflag &&
                          !dashboardShipment &&
                          !dashboardWatchlist
                        " (click)="addWatchList('Remove', data?.forwarder_reference)">
                        <div class="ship-hover-text">
                          <img class="view-eye icon-cntrl" src="../../../../../assets/img/icon-eye-visible.svg"
                            alt="" />
                          <span class="show-text">Remove from Watchlist</span>
                        </div>
                      </span>
                      <span *ngIf="
                          !data.favoriteflag ||
                          dashboardShipment ||
                          dashboardWatchlist
                        " (click)="addWatchList('Add', data?.forwarder_reference)">
                        <div class="ship-hover-text">
                          <img class="view-eye icon-cntrl" src="../../../../../assets/img/icons-eye-visible-disable.svg"
                            alt="" />
                          <span class="show-text">Add to Watchlist</span>
                        </div>
                      </span>
                      <!--To render Container Details-->
                      <span class="container-sec"><span (click)="selectedListShipment(data.forwarder_reference)">{{
                          data?.containerization_type }}</span>
                        <span class="container-sec p-0 shipment-fcl-ctr" *ngIf="data?.containers">
                          •&nbsp;{{ data?.containers?.length }}
                          <span><img class="view-eye icon-cntrl" src="../../../../../assets/img/pickup-location.svg"
                              alt="" (click)="containerPopupClick(i)" />
                          </span>
                          <div *ngIf="containerDisplayPopupIndex == i" class="container-popup">
                            <span (click)="containerPopupClick(-1)" class="close-button-container">
                              <img src="../../assets/img/close-sm.png" alt="close-icon" />
                            </span>
                            <span class="title">
                              Containers
                              <span class="count">{{
                                data?.containers?.length
                                }}</span>
                            </span>
                            <span class="conut-set" [innerHTML]="data | containerListPipe : i"></span>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                  <!--To render Status Details-->
                  <div *ngSwitchCase="'status'" class="status-tble-col">
                    <div (click)="selectedListShipment(data.forwarder_reference)">
                      <div class="status-button">
                        <button class="btn btn-primary shipment-btn"
                          class="{{data?.status | exceptionClass : data?.exception}}">
                          {{ data.status | titlecase }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--To render Destination Location Details-->
                  <div *ngSwitchCase="'destination location'" (click)="selectedListShipment(data.forwarder_reference)"
                    class="des-loc-col">
                    <div [innerHTML]="data | destinationLocation"></div>
                  </div>
                  <!--To render Pickup Location Details-->
                  <div *ngSwitchCase="'pickup location'" (click)="selectedListShipment(data.forwarder_reference)"
                    class="pick-loc-col">
                    <div [innerHTML]="data | shipFromLocation"></div>
                  </div>
                  <!--To render Progress Bar Details-->
                  <div *ngSwitchCase="'progress'" (click)="selectedListShipment(data.forwarder_reference)"
                    class="progress-bar-table-view progress-col">
                    <div class="d-flex align-items-start center-progress pr-0 shiping-list-card">
                      <div class="progress-bar-section col" #progressBarSection
                        [innerHTML]="data | shipmentProgressBar : togglePickupDeliveryLegs : searchText">
                      </div>
                    </div>
                  </div>
                  <!--To render Departure Location Details-->
                  <div *ngSwitchCase="'departure location'" (click)="selectedListShipment(data.forwarder_reference)"
                    class="dep-loc-col">
                    <div [innerHTML]="data | departureLocation"></div>
                  </div>
                  <!--To render Delivery Location Details-->
                  <div *ngSwitchCase="'delivery location'" (click)="selectedListShipment(data.forwarder_reference)"
                    class="del-loc-col">
                    <div [innerHTML]="data | shipToLocation"></div>
                  </div>
                  <!--To redner JSON, DFD, Action field data here Details-->
                  <div *ngSwitchDefault class="default-col" [class.col-outline]="header?.isdisplayoutline"
                    (click)="selectedListShipment(data.forwarder_reference)">
                    <div class="rap">
                      <span>
                        <p>
                          {{
                          data
                          | findKeyByValue
                          : header?.elementType?.displayText
                          : header?.fields?header?.fields[0]?.value:''
                          }}
                        </p>
                      </span>
                    </div>
                  </div>
                </td>
                <!--To redner Edoc Details-->
                <td>
                  <div class="progress-icon-ctr" class="edoc-col"
                    [class.remove-context-menu]="sessionUserRole != 'systemadmin'">
                    <span class="progress-icons" (mouseenter)="showDocHintText=i==0?true:false"
                      (mouseleave)="showDocHintText=false"><img src="assets/img/document.svg" alt="document"
                        (click)="navigateToDocumentTab(data)">
                      <div class="action-field-count"> {{ data?.documentCount ? data.documentCount : 0 }} </div>
                      <div class="doc-common-hover" *ngIf="i!=0">
                        <span>Document</span>
                      </div>
                    </span>
                    <span class="progress-icons actn-fld-icn"
                      (click)="selectedListShipment(data.forwarder_reference, true)" *ngIf="isActionField"
                      (mouseenter)="showActionHintText=i==0?true:false" (mouseleave)="showActionHintText=false">
                      <img src="assets/img/action-field-icon.svg" alt="action field" />
                      <div class="action-field-count">
                        {{ data?.action_fields ? data.action_fields.length : 0 }}
                        <div class="doc-common-hover action-fld-ctrl" *ngIf="i!=0">
                          <span>Action Field</span>
                        </div>
                      </div>
                    </span>
                    <span class="progress-icons progress-share" (click)="
                        share($event, data.forwarder_reference, 'Detail', data)
                      " (mouseenter)="showShareHintText=i==0?true:false" (mouseleave)="showShareHintText=false">
                      <img src="assets/img/share.svg" alt="share icon" />
                      <div class="doc-common-hover shre-ctrl" *ngIf="i!=0">
                        <span>Share</span>
                      </div>
                    </span>
                    <div class="table-column-frozen-div dropdown-section">
                      <div class="shipment-no- tble-dropdown dropdown">
                        <button type="button" class="dropdownMenuButton"
                          id="dropdownMenuButton-{{ data.forwarder_reference }}" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false" *ngIf="sessionUserRole == 'systemadmin'">
                          <img src="../../../../../../assets/img/dot-menu.svg" alt="viewshipments" />
                        </button>
                        <div class="dot-menu-hover">
                          <span>More</span>
                        </div>
                        <div class="dropdown-menu active-menu-bg" aria-labelledby="dropdownMenuButton"
                          style="left: -70px">
                          <a class="dropdown-item" *ngIf="
                              sessionUserRole == 'systemadmin' &&
                              !data?.reImportRequestPendingWithoutDocuments &&
                              !calculateDiff(
                                data?.reImportRequestCompletedWithoutDocuments
                              )
                            " (click)="
                              updateShipement(data.forwarder_reference, false)
                            ">Update Shipment</a>
                          <a class="dropdown-item updating-shipment" *ngIf="
                              sessionUserRole == 'systemadmin' &&
                              data?.reImportRequestPendingWithoutDocuments
                              && data?.reImportRequestCompletedWithoutDocuments!='errored'
                            ">
                            <div class="refresh-icon"></div>
                            <span class="updating-text">Updating Shipment</span>
                          </a>
                          <a class="dropdown-item checkedClass" *ngIf="
                              sessionUserRole == 'systemadmin' &&
                              !data?.reImportRequestPendingWithoutDocuments &&
                              calculateDiff(
                                data?.reImportRequestCompletedWithoutDocuments
                              )
                            " (click)="
                              updateShipement(data.forwarder_reference, false)
                            ">
                            <img class="image-okay" src="../../assets/img/active-data.svg" alt="" />Updated Shipment
                          </a>
                          <a class="dropdown-item checkedClass" *ngIf="
                          sessionUserRole == 'systemadmin' &&
                          data?.reImportRequestCompletedWithoutDocuments=='errored'">
                            <img class="image-okay" src="../../assets/img/alert-triangle.svg" alt="" />Error Update
                            Shipment
                          </a>
                          <a class="dropdown-item" *ngIf="
                              sessionUserRole == 'systemadmin' &&
                              !data.reImportRequestPendingWithDocuments &&
                              !calculateDiff(
                                data?.reImportRequestCompletedWithDocuments
                              )
                            " (click)="
                              updateShipement(data.forwarder_reference, true)
                            ">Update with Documents
                          </a>
                          <a class="dropdown-item updating-shipment" *ngIf="
                              sessionUserRole == 'systemadmin' &&
                              data.reImportRequestPendingWithDocuments
                              && data?.reImportRequestCompletedWithDocuments!='errored'
                            ">
                            <div class="refresh-icon"></div>
                            <span class="updating-text">
                              Updating with Documents</span>
                          </a>
                          <a class="dropdown-item checkedClass" *ngIf="
                              sessionUserRole == 'systemadmin' &&
                              !data.reImportRequestPendingWithDocuments &&
                              calculateDiff(
                                data?.reImportRequestCompletedWithDocuments
                              )
                            " (click)="
                              updateShipement(data.forwarder_reference, true)
                            "><img class="image-okay" src="../../assets/img/active-data.svg" alt="" />Updated with
                            Documents</a>
                          <a class="dropdown-item checkedClass" *ngIf="
                            sessionUserRole == 'systemadmin' &&
                             data?.reImportRequestCompletedWithDocuments=='errored'"><img class="image-okay"
                              src="../../assets/img/alert-triangle.svg" alt="" />Error Update with
                            Documents</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <!--To render table view actual data Details end-->
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>

  <!----table view end-->
  <!------>
  <!----Share popup start-->
  <div id="pop-up3" *ngIf="popupToggleList" class="dimScreen">
    <div *ngIf="popupToggleList" [ngStyle]="{
        position: dashboardShipment || dashboardWatchlist ? 'fixed' : ''
      }" class="add-user-popup">
      <button class="close" type="button" (click)="sharePopUpfeatureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
      </button>
      <div class="body-section fix-size">
        <h3>Share <span>Shipment</span></h3>
        <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" id="tabDetail">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../../../../../assets/img/share/url-img.svg" alt="" />
              URL
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div class="discription">
              <p class="text-des" *ngIf="detailShipmentShare">
                For users with a Connect account and access to this company,
                they will be able to see the full shipment details. Otherwise,
                they will be able to see a public URL with only shipment,
                container progress and customs information.
              </p>
              <div class="copy-section">
                <input type="text" id="shareUrl" [value]="urlShare" readonly />
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!----Share popup end-->
</div>
<!------>
<!----Shipment Detail popup start-->
<div>
  <app-shipment-detail [ngClass]="{ 'guided-visible-sec': isGuidedTourShipment }" id="shipmentDetail"
    *ngIf="getShipmentDetails" [urlShare]="urlShare" [urlShipmentId]="shareShipmentIdToChild"
    (prepareUrlShare)="detailShare($event)" [selectedShipment]="getShipmentDetails" [popupToggle]="popupToggle"
    (onDetailPopupCloseHandler)="onDetailPopupCloseHandler($event)"
    (onRefreshDetails)="onRefreshDetailFromDetail($event)" [publicShareVisibility]="publicShareVisibility"
    [publicShareType]="publicShareType" [publicShareEnrcyptedData]="publicShareEnrcyptedData"
    [actionFieldRecords]="actionFieldRecords" (loadShipmentList)="loadShipmentListById($event)">
  </app-shipment-detail>
</div>
<!----Shipment Detail popup end-->
<!------>
<!----Edoc upload Exit popup start-->
<div *ngIf="edocUploadTigger" id="edoc-upload-popup" class="dimScreen1">
  <div class="cancel-edocument">
    <button (click)="hideEdocUploadPopup()" class="close" type="button">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="close-icon" />
    </button>
    <div class="body-section fix-size">
      <h3>Are you sure you want to exit?</h3>
      <div class="discription">
        Documents are in the process of being uploaded. Exiting may result in
        unsaved changes. Are you sure you want to exit application?
      </div>
    </div>
    <div class="button-setup text-right">
      <button (click)="exitEdocPopup()" class="edit-permissons mr-2">
        Exit page
      </button>
      <button (click)="hideEdocUploadPopup()" class="add-type">
        Stay on page
      </button>
    </div>
  </div>
</div>
<!----Edoc upload Exit popup End-->
<!------>
<!-- Action Field changes modal start -->
<div tabindex="-1" role="dialog" [hidden]="!showActionFieldCancelModal" id="changes-action-popup"
  data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true"
  style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="removed-action-popup move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" />
          </span>
          <button type="button" class="close delete-close-icon"
            (click)="showActionFieldCancelModal = !showActionFieldCancelModal">
            <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Changes Will Not Be Saved</h3>
            <div class="discription">
              Are you sure you'd like to cancel? Any changes made will not be
              saved.
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="
                  showActionFieldCancelModal = !showActionFieldCancelModal
                ">
                GO BACK
              </button>
              <button class="add-type" (click)="switchTab()">PROCEED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Action Field Changes modal end -->

<!-- advance-filter-set-popup modal start -->
<div tabindex="-1" role="dialog" id="advance-filter-set-popup" data-bs-backdrop="static" data-bs-keyboard="false"
  class="modal fade bd-example-modal-lg show" aria-modal="true" style="display: block"
  [hidden]="!showFilterSwitchWarningPopup">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="removed-action-popup move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/filter-blue.svg" alt="alert" class="filter-overall" />
          </span>
          <button type="button" class="close delete-close-icon" (click)="denyProceedingNewFilter()">
            <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3 class="mb-0 pl-0 pb-0">{{filterSwitchHeaderMessage}}</h3>
            <div class="discription">
              {{filterSwitchWarningMessage}}
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="denyProceedingNewFilter()">
                GO BACK
              </button>
              <button class="add-type" id="filter-proceed-btn" (click)="clearAllFilters()">PROCEED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- advance-filter-set-popup modal end -->

<!-- No Shipments Found start ---->
<ng-template #recordNotFound let-userviewType="viewType">
  <div class="row" class="no-shipment-wrap" *ngIf="currentSessionUser">
    <div *ngIf="
        shipmentData.length <= 0 && !dashboardWatchlist && !loadingInProgress
      " class="text-center mt-5 pt-5 mobile-set-center dashboardShipmentWrapper">
      <p class="title-set mt-5 pt-5">No Shipments Found</p>
      <span class="detail-set">We found no shipments that match filter criteria.</span>
    </div>
    <div *ngIf="shipmentData.length <= 0 && loadingInProgress" class="text-center mt-5 pt-5 table-view-ctrl-load">
      <p class="title-set mt-5">Loading Shipments</p>
    </div>
    <div *ngIf="
        shipmentData.length <= 0 && dashboardWatchlist && !loadingInProgress
      " class="text-center mt-5 pt-5 height-set move-top mobile-set-center">
      <p class="mt-5 pt-5 title-set">Start building your shipment watchlist!</p>
      <p class="detail-set">
        To keep an eye on high-priority shipments, add a shipment to the
        watchlist using the action menu on the far right of the shipment or from
        the shipment details screen.
      </p>
    </div>
  </div>
</ng-template>
<!-- No Shipments Found end ---->