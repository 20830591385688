import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'pickupDeliveryPortCountry'
})
export class PickupDeliveryPortCountryPipe implements PipeTransform {

  transform(data: any): unknown {
    let message = '';
    let pickup = this.getPickupPortCountry(data);
    let delivery = this.getDeliveryPortCountry(data);

    if (pickup && delivery) {
      message += pickup + ' - ' + delivery;
    } else if (pickup) {
      message += pickup;
    } else if (delivery) {
      message += delivery;
    }
    return message;

  }
  getPickupPortCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.ship_from) {
      message += data.ship_from.city
        ? CommonUtils.toTitleCase(data.ship_from.city) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.ship_from.country_name,
        data.ship_from.state
      );

      message += data.ship_from.country_name
        ? CommonUtils.toTitleCase(data.ship_from.country_name)
        : '';
    } else {
      if (!data.shipment_legs || data.shipment_legs?.length <= 0)
        return message;

      let min_leg = CommonUtils.getFirstShipmentLeg(data);
      if (min_leg && min_leg.lading_port) {
        message =
          CommonUtils.toTitleCase(min_leg.lading_port.portName) +
          ', ' +
          CommonUtils.toTitleCase(min_leg.lading_port.country_name);
      }
    }

    return CommonUtils.removeLastComma(message);
  }
  getDeliveryPortCountry(data: any) {
    let message = '';
    if (!data) return message;
    if (data.ship_to) {
      message += data.ship_to.city
        ? CommonUtils.toTitleCase(data.ship_to.city) + ', '
        : '';

      message += CommonUtils.getUSCanadaStateText(
        data.ship_to.country_name,
        data.ship_to.state
      );

      message += data.ship_to.country_name
        ? CommonUtils.toTitleCase(data.ship_to.country_name)
        : '';
    } else {
      if (!data.shipment_legs || data.shipment_legs?.length <= 0)
        return message;

      let max_leg = CommonUtils.getLastShipmentLeg(data);
      if (max_leg && max_leg.arrival_port) {
        message =
          CommonUtils.toTitleCase(max_leg.arrival_port.portName) +
          ', ' +
          CommonUtils.toTitleCase(max_leg.arrival_port.country_name);
      }
    }

    return CommonUtils.removeLastComma(message);
  }
}
