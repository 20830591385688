import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShipmentServies } from '../../services/shipment.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';
import { SwitchAccountService } from 'src/app/shared/services/switch-account.service';
@Component({
  selector: 'app-shipment-search',
  templateUrl: './shipment-search.component.html',
  styleUrls: ['./shipment-search.component.scss'],
})
export class ShipmentSearchComponent implements OnInit {
  urlShipmentId = '';
  selectedShipmentLen: number = 0;
  url = '';
  popupToggleList = false;
  isProgressShow = false;
  publicShareFilter: any;
  popupToggle = false;
  isShipmentFullview = false;
  getShipmentDetails: any = [];
  copyText: any = '';
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  isLoading = false;

  constructor(
    private shipmentService: ShipmentServies,
    private activatedRoute: ActivatedRoute,
    private currentSessionUserData: CurrentSessionDataService,
    private switchAccount: SwitchAccountService
  ) {}

  ngOnInit(): void {
    if (this.currentUserData) {
      // this.router.navigate(['/dashboard']);
    }
    let shipmentId = this.activatedRoute.snapshot.params['searchParam'];
    if (shipmentId) {
      this.urlShipmentId = shipmentId;
      this.publicShareShipment(shipmentId);
    }
  }

  detailShare(event: any) {
    this.url = environment.localUrl + '/shipments/search/' + this.urlShipmentId;
    this.popupToggleList = true;
  }

  publicShareShipment(shipmentId: any) {
    this.isLoading = true;
    let accountId = '';
    let emailId = '';
    let isGroup = false;
    let isParent = false;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: shipmentId,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    this.shipmentService
      .publicShare(accountId, emailId, bodyParam, isGroup, isParent)
      .subscribe((res: any) => {
        this.isLoading = false;
        //  if (!this.currentUserData) {
        this.popupToggle = true;
        setTimeout(() => {
          this.getShipmentDetails = res.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
        }, 0);
      });
  }

  sharePopUpfeatureHide() {
    this.popupToggleList = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  switchAccountAdmin(shipment: any) {
    if (Object.keys(shipment).length > 0) {
      let accountDetail = {
        aliasName: this.getShipmentDetails.account_alias_name,
        oH_Code: this.getShipmentDetails.accountId,
        byGroup: false,
        isControllingCustomer: false,
        shipment: {
          isVisible: this.getShipmentDetails?.account_shipment?.isVisible,
        },
        booking: {
          isVisible: this.getShipmentDetails?.account_booking?.isVisible,
        },
        customs: {
          isVisible: this.getShipmentDetails?.account_customs?.isVisible,
        },
      };
      const isSwitchAccount = this.switchAccount.switchAccount(
        accountDetail,
        'searchUrlShipment',
        this.urlShipmentId
      );
      if (!isSwitchAccount) {
        this.selectedListShipment(this.urlShipmentId);
        setTimeout(() => {
          this.popupToggle = true;
        }, 300);
      }
    }
  }

  selectedListShipment(shipmentId: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.shipmentService
      .getShipmentsDetails(accountId, emailId, shipmentId, isGroup)
      .subscribe({
        next: (getDetails: any) => {
          this.isProgressShow = false;
          this.getShipmentDetails = getDetails.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
        },
      });
  }
}
