import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public stopRequest: Subject<void> = new Subject<void>();
  public closeReportFieldForm: Subject<boolean> = new Subject<boolean>();
  public openReportFieldForm: Subject<boolean> = new Subject<boolean>();
  public isProgress: Subject<boolean> = new Subject<boolean>();
  public isCopied: Subject<any> = new Subject<any>();
  public formFieldChanges: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public formFieldChangesPopup: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public closeWindow = new EventEmitter<boolean>();
  public dropDownControlValues: any;
  public dropDownFormValid: boolean;
  public selectedReportFieldItem: any;
  public sectionBasedItem: any;
  public sectionBasedItemAtCargoWise: any;
  selectedReportItem: any[] = [];
  formChanged: boolean = false;

  constructor(private http: HttpClient) { }

  closePopUpWindow() {
    this.closeWindow.emit(true);
  }

  report(workspaceId: any, reportId: any, userEmail: any, orgCode: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'Report?workspaceId=' +
        workspaceId +
        '&reportId=' +
        reportId +
        '&userEmail=' +
        userEmail +
        '&accountId=' +
        orgCode,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  allReports(params: any): Observable<any> {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'Report/Reports',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getAllAccounts(searchObj: any, offset: any): Observable<any> {
    let request = {
      offset: offset,
      fetch: 30,
      search_text: searchObj,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<any>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getReportsNew(orgCode: any, searchText: any): Observable<any> {
    let param = {
      offset: 0,
      fetch: 20,
      search_text: searchText?.trim(),
      isGlobalSearch: false,
      parameters: [],
      sort_options: [
        {
          column_name: 'reportName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'account',
          operater: '=',
          value: orgCode,
        },
      ],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'Report/GetReportsNew',
        param,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getReportNew(reportId: any, orgCode: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'Report/GetReportNew?reportId=' +
        reportId +
        '&accountId=' +
        orgCode,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getReportByid(reportId: any): Observable<any> {
    return this.http
      .get<any>(
        environment.base_api_desktop_url +
        'Report/ReportByid?id=' +
        reportId,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  createReport(params: any) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'Report/Create',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  updateReport(userEmail: any, params: any) {
    return this.http
      .put<any[]>(
        environment.base_api_desktop_url +
        'Report/Update?userEmail=' +
        userEmail,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  deleteReport(reportId: any, params: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url + '/Report/Delete?id=' + reportId,
        options
      )
      .pipe(catchError(this.handelError));
  }

  getReportByAccount(orgCode: string, searchText: any) {
    let params = {
      offset: 0,
      fetch: 10000,
      search_text: searchText?.trim(),
      isGlobalSearch: false,
      parameters: [],
      sort_options: [
        {
          column_name: 'reportName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'account',
          operater: '=',
          value: orgCode,
        },
      ],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'Report/Organization/GetReportByAccount?orgCode=' +
        orgCode,
        params
      )
      .pipe(catchError(this.handelError));
  }

  deleteOrganization(orgCode: any, reportId: any, params: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'Report/Organization/Delete?orgCode=' +
        orgCode +
        '&reportId=' +
        reportId,
        options
      )
      .pipe(catchError(this.handelError));
  }

  updateOrganization(orgCode: any, params: any) {
    return this.http
      .put<any[]>(
        environment.base_api_desktop_url +
        'Report/Organization/Update?orgCode=' +
        orgCode,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  userReport(userEmail: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url + 'Report/User?userEmail=' + userEmail,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
}
