import { Pipe, PipeTransform } from '@angular/core';
import { ProgressBarType } from './shipment.enums';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipFromLocation',
})
export class ShipFromLocationPipe implements PipeTransform {
  transform(data: any): unknown {

    let start_leg_text = '<div class="rap">';
    let searchText = '';
    start_leg_text +=
      '<span class="font-size-set">' +
      CommonUtils.getPortAndCountryToolTipText(
        CommonUtils.toTitleCase(data?.ship_from?.city),
        data?.ship_from?.state,
        data?.ship_from?.country_name,
        data?.ship_from?.country_name,
        searchText
      ) +
      '</span>';
    start_leg_text +=
      '<p class="m-0">' +
      CommonUtils.getStatusToolTipText(
        'Picked up',
        'Est Pickup',
        data?.origin_pickup_actual,
        data?.origin_pickup_estimated,
        ProgressBarType.ShipmentListingPage
      ) +
      '</p>';
    start_leg_text += '</div>';
    return start_leg_text; //CommonUtils.getShipToDetails(data);
  }
}
