import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonUtils } from 'src/app/common/utils';
import * as JSZip from 'jszip';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { environment } from 'src/environments/environment';
import { CurrentSessionDataService } from './current-session-data.service';
import { DataSharingService } from './data-sharing.service';

const pako = require('pako');
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  public fileUploadPercentage: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  public reloadShipment: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public reloadBookings: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public reloadCustoms: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public showToastEdocRefresh: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );
  filesUploadProperties: any = [];
  currentUserData: any;
  restrictedFileType: any = [];
  currentAccountData: any;
  fileMaxSize: number = 26214400;
  isFileUploadCancelled = false;
  completedTestArray: any[] = [];
  connectivityStatus: OnlineStatusType = 1;
  /* istanbul ignore next */
  constructor(
    private httpClient: HttpClient,
    private dataSharing: DataSharingService,
    private currentSessionUserData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService,
    private onlineStatusService: OnlineStatusService
  ) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // use status
      this.connectivityStatus = status;
      if (this.connectivityStatus == 0) {
        this.filesUploadProperties.filter((el: any) => {
          if (el.Percentage < 100) {
            let errorMessage = 'Unable to upload due to network error.';
            this.sendFileErrorRetry(
              el.fileId,
              el.filename,
              el.totalSize,
              errorMessage,
              el.selectedEdoc,
              el.shipmentId,
              el.fileList,
              el.type
            );
          }
        });
      }
    });
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
    this.dataSharing.eDocUploadCancelTrigger.subscribe((res: any) => {
      if (res) {
        this.isFileUploadCancelled = true;
        setTimeout(() => {
          this.dataSharing.eDocUploadTrigger.next({
            popup: false,
            reload: false,
            detailPopup: true,
          });
        }, 1000);
        res.forEach((element: any) => {
          this.cancelledEdoc(element.fileId).subscribe();
        });
      }
    });
    if (this.currentUserData) {
      this.getFileTypes().subscribe((res: any) => {
        this.restrictedFileType = res?.result;
      });
    }
  }

  fileResricted(fileType: any) {
    return this.restrictedFileType.some((x: any) => fileType == x);
  }

  /* istanbul ignore next */
  public fileUpload(
    file: any,
    type: any,
    shipmentId: any,
    eDocType: any,
    retryFileId: any
  ) {
    let id: any;
    return new Promise((resolve, reject) => {
      file.forEach((splitFiles: any) => {
        let root = this;
        let chunks = this.createChunks(splitFiles);
        let total = splitFiles.size;
        const chunkPercentage = (total / chunks.length / total) * 100;

        if (chunks.length > 0) {
          if (retryFileId) {
            id = retryFileId;
          } else {
            id = CommonUtils.generateGUID();
          }
          root.uploadFile(
            id,
            splitFiles,
            chunks,
            0,
            chunkPercentage,
            resolve,
            reject,
            type,
            shipmentId,
            eDocType,
            file
          );
        }
      });
    });
  }
  /* istanbul ignore next */
  createChunks(splitFiles: any) {
    let root = this;
    let offset = 0;
    let length = 0;
    let total = splitFiles.size;
    if (total > 512000 && total <= 5242880) {
      length = 512000 > total ? Math.round(total * 512) : 512000;
    } else if (total > 5242881) {
      length = 768000 > total ? Math.round(total * 750) : 768000;
    } else if (total <= 512000) {
      length = 512000;
    }

    let chunks: any = [];

    while (offset < total) {
      if (offset + length > total) {
        length = total - offset;
      }
      chunks.push({
        offset,
        length,
        method: root.getUploadMethod(offset, length, total),
      });
      offset += length;
    }

    return chunks;
  }
  /* istanbul ignore next */
  uploadFileChunk(data: any) {
    return new Promise((resolve, reject) => {
      const headers = {
        Accept: 'application/json; odata=verbose',
        'Content-Type': 'application/json',
      };
      this.executePost(
        environment.base_api_desktop_url + 'AddeDocChunks',
        data,
        headers
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  private arrayBufferToBase64String(buffer: ArrayBuffer) {
    let binaryString = '';
    let bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binaryString += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binaryString);
  }

  formatBytes(bytes: any, decimals: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  /* istanbul ignore next */
  async uploadFile(
    id: any,
    splitFiles: any,
    chunks: any,
    index: any,
    chunkPercentage: any,
    resolve: any,
    reject: any,
    type: any,
    shipmentId: any,

    eDocType: any,
    fileList: any
  ) {
    let chunk = splitFiles.slice(
      chunks[index].offset,
      chunks[index].offset + chunks[index].length
    );
    let completedSize = this.formatBytes(
      chunks[index].offset + chunks[index].length,
      0
    );
    let totalSize = this.formatBytes(splitFiles.size, 0);
    let fileExtension = splitFiles.name.split('.').pop().toLowerCase();
    let deflated;
    let fileReader = new FileReader();
    let This = this;
    fileReader.readAsArrayBuffer(chunk);
    let readEventHandler = function (evt: any) {
      chunk = evt.target.result;
      deflated = btoa(pako.gzip(chunk, { to: 'string' }));
      const chunkId = CommonUtils.generateGUID();
      let params: any = {
        id: chunkId,
        correlationId: id,
        numberOfChunks: chunks.length,
        chunkIndex: index + 1,
        fileName: splitFiles.name,
        fileType: eDocType.code,
        fileTypeDescription: eDocType.description,
        shipmentNumber: shipmentId,
        type: type,
        content: deflated,
        OC3_User: {
          email: This.currentUserData.emailAddress,
          firstName: This.currentUserData.firstName,
          lastName: This.currentUserData.lastName,
          phone: This.currentUserData.mobile,
          username:
            This.currentUserData.firstName +
            ' ' +
            This.currentUserData.lastName,
        },
      };
      if (type == 'customs') {
        params.type = 'shipment';
      }

      if (!This.filesUploadProperties.find((v: any) => v.fileId === id)) {
        This.filesUploadProperties.push({
          Percentage: 0,
          filename: splitFiles.name,
          fileId: id,
          fileOrder: This.filesUploadProperties.length,
          completedSize: This.formatBytes(0, 0) + ' of ' + totalSize,
          eDocTypes: eDocType.codeWithDesc,
          fileList: splitFiles,
          shipmentId: shipmentId,
          type: type,
          selectedEdoc: eDocType,
          totalSize: totalSize,
        });
        if (!This.isFileUploadCancelled) {
          This.fileUploadPercentage.next(This.filesUploadProperties);
          localStorage.setItem(
            'fileUploadStatus',
            JSON.stringify(This.filesUploadProperties)
          );
        }
      }
      This.uploadFileChunk(params)
        .then((value: any) => {
          if (
            splitFiles.size <= This.fileMaxSize &&
            !This.fileResricted('.' + fileExtension) &&
            !value.hasError &&
            value.statusCode == 'OC200' &&
            This.connectivityStatus == 1
          ) {
            const isFinished = index === chunks.length - 1;
            index += 1;
            let percentageComplete = isFinished
              ? 100
              : Math.round(index * chunkPercentage);

            if (!This.filesUploadProperties.find((v: any) => v.fileId === id)) {
              This.filesUploadProperties.push({
                Percentage: percentageComplete,
                filename: splitFiles.name,
                fileId: id,
                fileOrder: This.filesUploadProperties.length,
                completedSize: completedSize + ' of ' + totalSize,
                eDocTypes: eDocType.codeWithDesc,
                fileList: splitFiles,
                shipmentId: shipmentId,
                type: type,
                selectedEdoc: eDocType,
                totalSize: totalSize,
              });
            } else {
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).Percentage = percentageComplete;
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).completedSize = completedSize + ' of ' + totalSize;
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).fileList = splitFiles;
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).shipmentId = shipmentId;
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).type = type;
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).selectedEdoc = eDocType;
              This.filesUploadProperties.find(
                (v: any) => v.fileId === id
              ).totalSize = totalSize;
            }

            if (percentageComplete == 100) {
              This.trackAIFileUploadEvent(
                type,
                shipmentId,
                splitFiles.name,
                eDocType,
                totalSize,
                fileExtension
              );

              This.completedTestArray.push(id);
              setTimeout(() => {
                This.progressCompletedCheck(fileList, type, shipmentId);
              }, 100);
            }

            if (!This.isFileUploadCancelled) {
              This.fileUploadPercentage.next(This.filesUploadProperties);
              localStorage.setItem(
                'fileUploadStatus',
                JSON.stringify(This.filesUploadProperties)
              );
            }

            if (index < chunks.length) {
              This.uploadFile(
                id,
                splitFiles,
                chunks,
                index,
                chunkPercentage,
                resolve,
                reject,
                type,
                shipmentId,

                eDocType,
                fileList
              );
            } else {
              resolve(value);
            }
          } else {
            let errorMessage = '';

            This.completedTestArray.push(id);
            if (splitFiles.size > This.fileMaxSize) {
              errorMessage =
                'Unable to upload. File size is bigger than the maximum 25MB allowed.';
              This.sendFileErrorRetry(
                id,
                splitFiles.name,
                totalSize,
                errorMessage,
                eDocType,
                shipmentId,
                splitFiles,
                type
              );
            } else if (This.fileResricted('.' + fileExtension)) {
              errorMessage = 'Unable to upload. File type not support.';
              This.sendFileErrorRetry(
                id,
                splitFiles.name,
                totalSize,
                errorMessage,
                eDocType,
                shipmentId,
                splitFiles,
                type
              );
            } else if (value.hasError) {
              if (value.comments == 'User cancelled the file upload') {
                This.destroyUploadComponent();
              } else {
                errorMessage = 'Unable to upload due to network error.';
                This.sendFileErrorRetry(
                  id,
                  splitFiles.name,
                  totalSize,
                  errorMessage,
                  eDocType,
                  shipmentId,
                  splitFiles,
                  type
                );
              }
            } else if (This.connectivityStatus == 0) {
              This.filesUploadProperties.filter((el: any) => {
                if (el.Percentage < 100) {
                  This.sendFileErrorRetry(
                    el.fileId,
                    el.filename,
                    el.totalSize,
                    'Unable to upload due to network error.',
                    el.selectedEdoc,
                    el.shipmentId,
                    el.fileList,
                    el.type
                  );
                }
              });
            } else if (
              value.statusCode == '500' &&
              value.result.toLowerCase().includes('Virus Found'.toLowerCase())
            ) {
              errorMessage = 'Unable to upload.Corrupted file.';
              This.sendFileErrorRetry(
                id,
                splitFiles.name,
                totalSize,
                errorMessage,
                eDocType,
                shipmentId,
                splitFiles,
                type
              );
            } else {
              errorMessage = 'Unable to upload. Unknown error.';
              This.sendFileErrorRetry(
                id,
                splitFiles.name,
                totalSize,
                errorMessage,
                eDocType,
                shipmentId,
                splitFiles,
                type
              );
            }
          }
        })
        .catch((err) => {
          reject(err);
        });
    };
    fileReader.onload = readEventHandler;
  }

  progressCompletedCheck(fileList: any, type: any, shipmentId: any) {
    if (this.completedTestArray.length == fileList.length) {
      if (type == 'shipment') {
        this.reloadShipment.next({ reload: true, shipmentId: shipmentId });
      } else if (type == 'booking') {
        this.reloadBookings.next({ reload: true, shipmentId: shipmentId });
      } else if (type == 'customs') {
        this.reloadCustoms.next({ reload: true, shipmentId: shipmentId });
      }
      this.completedTestArray = [];
    }
  }

  checkIsshipments(types: any) {
    let data = false;
    if (types == 'shipment') {
      data = true;
    }
    return data;
  }

  destroyUploadComponent() {
    this.filesUploadProperties = [];
    this.fileUploadPercentage.next([]);
    localStorage.removeItem('fileUploadStatus');
    this.reloadShipment.next({});
    this.reloadBookings.next({});
  }

  sendFileErrorRetry(
    id: any,
    fileName: any,
    totalSize: any,
    errorMessage: any,
    eDocType: any,
    shipmentId: any,
    fileList: any,
    type: any
  ) {
    if (!this.filesUploadProperties.find((v: any) => v.fileId === id)) {
      this.filesUploadProperties.push({
        Percentage: 0,
        filename: fileName,
        fileId: id,
        fileOrder: this.filesUploadProperties.length,
        completedSize: this.formatBytes(0, 0) + ' of ' + totalSize,
        Error: errorMessage,
        eDocTypes: eDocType.codeWithDesc,
        fileList: fileList,
        shipmentId: shipmentId,
        type: type,
        selectedEdoc: eDocType,
      });
    } else {
      this.filesUploadProperties.find(
        (v: any) => v.fileId === id
      ).Percentage = 0;
      this.filesUploadProperties.find(
        (v: any) => v.fileId === id
      ).completedSize = this.formatBytes(0, 0) + ' of ' + totalSize;
      this.filesUploadProperties.find((v: any) => v.fileId === id).Error =
        errorMessage;
      this.filesUploadProperties.find((v: any) => v.fileId === id).fileList =
        fileList;
      this.filesUploadProperties.find((v: any) => v.fileId === id).shipmentId =
        shipmentId;
      this.filesUploadProperties.find((v: any) => v.fileId === id).type = type;
      this.filesUploadProperties.find(
        (v: any) => v.fileId === id
      ).selectedEdoc = eDocType;
    }

    this.fileUploadPercentage.next(this.filesUploadProperties);
    localStorage.setItem(
      'fileUploadStatus',
      JSON.stringify(this.filesUploadProperties)
    );
  }

  getUploadMethod(offset: any, length: any, total: any) {
    if (offset + length + 1 > total) {
      return 'finishupload';
    } else if (offset === 0) {
      return 'startupload';
    } else if (offset < total) {
      return 'continueupload';
    }
    return null;
  }

  convertFileToBlobChunks(result: any, chunkInfo: any) {
    return result.slice(chunkInfo.offset, chunkInfo.offset + chunkInfo.length);
  }
  /* istanbul ignore next */
  async executePost(url: any, data: any, requestHeaders: any) {
    const res = await this.httpClient
      .post(url, data, requestHeaders)
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        return err.error;
      });

    return this.parseRetSingle(res);
  }

  parseRetSingle(res: any) {
    if (res) {
      if (this.isFileUploadCancelled) {
        return {
          hasError: true,
          comments: 'User cancelled the file upload',
        };
      }
      if (this.connectivityStatus == 0) {
        return {
          hasError: true,
          comments: 'No internet',
        };
      }
      if (res?.hasOwnProperty('d')) {
        return res.d;
      } else if (res?.hasOwnProperty('error')) {
        const obj: any = res.error;
        obj.hasError = true;
        return obj;
      } else {
        return res;
      }
    } else {
      return {
        hasError: true,
        comments: 'Check the response in network trace',
      };
    }
  }

  getEdocTypes(val: any, url: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1000,
      search_text: val,
      sort_options: [{ column_name: 'code', direction: 'asc' }],
      filter_options: null,
    };
    return this.httpClient
      .post(url, request)
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }

  getFileTypes(): Observable<any> {
    let url = environment.base_api_desktop_url + 'GetRestrictedEDocFileTypes';
    return this.httpClient.get(url).pipe(catchError(this.handelError));
  }
  /* istanbul ignore next */
  cancelledEdoc(fileId: any): Observable<any> {
    let url = environment.base_api_desktop_url + 'DeleteeDocChunks';
    let request = {
      correlationId: fileId,
    };
    return this.httpClient
      .post(url, request)
      .pipe(catchError(this.handelError));
  }

  retryUpload(fileID: any) {
    let fileCheckArray: any = this.filesUploadProperties.filter(
      (value: any) => value.fileId == fileID
    );
    let fileCheck = fileCheckArray[0];
    let tempFileArray = [fileCheck.fileList];
    this.fileUpload(
      tempFileArray,
      fileCheck.type,
      fileCheck.shipmentId,
      fileCheck.selectedEdoc,
      fileCheck.fileId
    );
  }

  trackAIFileUploadEvent(
    type: any,
    shipmentId: any,
    fileName: any,
    eDocType: any,
    totalSize: any,
    fileExtension: any
  ) {
    let moduleName: AI_ModulesName =
      type == 'bookings' ? AI_ModulesName.Booking : AI_ModulesName.Shipment;

    this.appInsightsService.logEvent(AI_CommonEvents.EdocFileUploaded, {
      [AI_CustomProps.ModuleName]: CommonUtils.titleCase(type ? type : ''),
      [AI_CustomProps.ShipmentId]: shipmentId,
      [AI_CustomProps.ModuleName]: moduleName,
      [AI_CustomProps.FileName]: fileName,
      [AI_CustomProps.EDocType]: eDocType.code,
      [AI_CustomProps.FileSize]: totalSize,
      [AI_CustomProps.FileType]: fileExtension,
    });
  }
}
