import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'portDetails',
})
export class PortDetailsPipe implements PipeTransform {
  transform(port: any): unknown {
    if (!port) return '---';
    let htmlString = '';
    if (port.portName) {
      htmlString += this.getTitleCase(port.portName);
    }
    if (port.portName && port.country_name) {
      htmlString += ', ';
    }
    if (port.country_name) {
      htmlString += this.getTitleCase(port.country_name);
    }
    if (port.unlocode) {
      htmlString += ' - ' + port.unlocode;
    }
    return htmlString ? htmlString : '---';
  }

  getTitleCase(text: string) {
    return CommonUtils.toTitleCase(text);
  }
}
