import { Pipe, PipeTransform } from '@angular/core';
import { ProgressBarType } from './shipment.enums';
import { CommonUtils } from './utils';

@Pipe({
  name: 'shipToLocation',
})
export class ShipToLocationPipe implements PipeTransform {
  transform(data: any): unknown {
    let start_leg_text = '<div class="rap">';
    let searchText = '';
    start_leg_text +=
      '<span class="font-size-set">' +
      CommonUtils.getPortAndCountryToolTipText(
        CommonUtils.toTitleCase(data?.ship_to?.city),
        data?.ship_to?.state,
        data?.ship_to?.country_name,
        data?.ship_to?.country_name,
        searchText
      ) +
      '</span>';
    start_leg_text +=
      '<p class="m-0">' +
      CommonUtils.getStatusToolTipText(
        'Delivered',
        'Est Delivery',
        data?.delivery_actual,
        data?.delivery_estimated,
        ProgressBarType.ShipmentListingPage
      ) +
      '</p>';
    start_leg_text += '</div>';

    return start_leg_text;
  }
}
