<div *ngIf="transport_mode?.toUpperCase() === 'AIR'" class="air" [ngClass]="status | exceptionClass : exception : module">
    <ng-container [ngTemplateOutlet]="airStatusImage"></ng-container>
</div>
<div *ngIf="transport_mode?.toUpperCase() == 'SEA'" class="ship" [ngClass]="status | exceptionClass : exception: module">
    <ng-container [ngTemplateOutlet]="seaStatusImage"></ng-container>
</div>
<div *ngIf="transport_mode?.toUpperCase() === 'ROAD' ||
                transport_mode?.toUpperCase() === 'ROA' ||
                transport_mode?.toUpperCase() === 'TRK'
              " class="trick" [ngClass]="status | exceptionClass : exception: module">
    <ng-container [ngTemplateOutlet]="roadStatusImage"></ng-container>
</div>
<div *ngIf="transport_mode?.toUpperCase() === 'RAIL' ||
                transport_mode?.toUpperCase() === 'RAI'
              " class="train" [ngClass]="status | exceptionClass : exception: module">
    <ng-container [ngTemplateOutlet]="railStatusImage"></ng-container>
</div>
<div *ngIf="transport_mode?.toUpperCase() == 'COURIER' ||
                transport_mode?.toUpperCase() == 'COU'
              " class="couirer" [ngClass]="status | exceptionClass : exception: module">
    <ng-container [ngTemplateOutlet]="courierStatusImage"></ng-container>
</div>
<div *ngIf="transport_mode?.toUpperCase() !== 'SEA' &&
                transport_mode?.toUpperCase() !== 'RAIL' &&
                transport_mode?.toUpperCase() !== 'RAI' &&
                transport_mode?.toUpperCase() !== 'AIR' &&
                transport_mode?.toUpperCase() !== 'ROAD' &&
                transport_mode?.toUpperCase() !== 'ROA' &&
                transport_mode?.toUpperCase() !== 'TRK' &&
                transport_mode?.toUpperCase() !== 'COURIER' &&
                transport_mode?.toUpperCase() !== 'COU'
              " class="unknown" [ngClass]="status | exceptionClass : exception: module">
    <span class="empty-set"></span>
</div>

<!-- Status SVG image start -->
<ng-template #airStatusImage>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <path class="bg" fill="#ff4040" d="M17.5 0A17.5 17.5 0 1 1 0 17.5 17.5 17.5 0 0 1 17.5 0z" />
        <path class="object" fill="#ffffff"
            d="M28.68 38.223a4.1 4.1 0 0 0-3.938-2.967h-4.326l-5.6-6.795a.975.975 0 0 0-.749-.361h-2.831a.3.3 0 0 0-.277.444l2.94 6.711H7.575a.8.8 0 0 1-.5-.194L4.857 32.87a.548.548 0 0 0-.388-.166H2.916a.567.567 0 0 0-.555.693l1 3.994a3.772 3.772 0 0 0 3.659 2.856h20.107a1.613 1.613 0 0 0 1.553-2.024z"
            transform="translate(1.958 -16.673)" />
    </svg>
</ng-template>

<ng-template #seaStatusImage>
    <svg xmlns="http://www.w3.org/2000/svg" id="Component_136" width="35" height="35" viewBox="0 0 35 35">
        <defs>
            <style>
                .cls-2 {
                    fill: #01457c;
                }
            </style>
        </defs>
        <path id="Path_38127" fill="#eaf3f5" d="M17.5 0A17.5 17.5 0 1 1 0 17.5 17.5 17.5 0 0 1 17.5 0z" />
        <g id="Group_8716" transform="translate(5.853 9.964)">
            <path id="Path_2713"
                d="M38.665 26.543a.514.514 0 0 0-.476-.543h-2.857a.513.513 0 0 0-.476.543v1.627h-2.38a.513.513 0 0 0-.476.543v4.912h6.665z"
                class="cls-2" transform="translate(-24.174 -22.668)" />
            <path id="Path_2714"
                d="M9.713 16.761a.477.477 0 0 0-.476-.476h-.476v-1.9a.476.476 0 1 0-.952 0v1.9H5.9v-3.809a.476.476 0 1 0-.952 0v3.808h-.472a.477.477 0 0 0-.476.477v5.262h5.713z"
                class="cls-2" transform="translate(-3.047 -12)" />
            <path id="Path_2715"
                d="M23.218 42.219a.485.485 0 0 0-.41-.219h-4.853a.494.494 0 0 0-.344.139l-1.8 1.765H.484a.487.487 0 0 0-.416.233.467.467 0 0 0-.012.469L2.421 49h17.82l3.007-6.32a.472.472 0 0 0-.03-.458z"
                class="cls-2" transform="translate(0.002 -33.925)" />
        </g>
    </svg>
</ng-template>

<ng-template #roadStatusImage>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <path class="bg" fill="#eaf3f5" d="M17.5 0A17.5 17.5 0 1 1 0 17.5 17.5 17.5 0 0 1 17.5 0z" />
        <path class="object"
            d="M2.133 37.243l.168-.012v-7.1a.38.38 0 0 1 .357-.4H17.95a.385.385 0 0 1 .368.4v6.8h.85v-5.79a.561.561 0 0 1 .556-.564h.6a.56.56 0 0 1 .555.564v.923h1.611a.6.6 0 0 1 .535.4c.006.014.528 1.867.528 1.867l1.418.3a.4.4 0 0 1 .343.38v2.232l.17.008a.255.255 0 0 1-.013.508H24.5a1.372 1.372 0 0 1-2.741 0h-3.532a1.4 1.4 0 0 1-2.647.554 1.4 1.4 0 0 1-2.646-.554h-4.9a1.4 1.4 0 0 1-2.647.554 1.4 1.4 0 0 1-2.647-.554h-.59a.255.255 0 0 1-.017-.508z"
            transform="translate(3.689 -16.906)" />
    </svg>
</ng-template>

<ng-template #railStatusImage>
    <svg xmlns="http://www.w3.org/2000/svg" id="Component_137" width="35" height="35" viewBox="0 0 35 35">
        <defs>
            <style>
                .cls-2 {
                    fill: #01457c;
                }
            </style>
        </defs>
        <path id="Path_38127" fill="#eaf3f5" d="M17.5 0A17.5 17.5 0 1 1 0 17.5 17.5 17.5 0 0 1 17.5 0z" />
        <g id="noun_Train_3261394" transform="translate(5.753 11.244)">
            <g id="Group_8815" transform="translate(0)">
                <path id="Path_10916" d="M54.176 49.3a1.65 1.65 0 0 0 1.61-1.3H52.6a1.618 1.618 0 0 0 1.576 1.3z"
                    class="cls-2" transform="translate(-34.382 -36.788)" />
                <path id="Path_10917"
                    d="M25.244 25.359l-1.281-2.614a7.915 7.915 0 0 0-7.048-4.545H2.356a.4.4 0 0 0-.356.379v5.833a1.2 1.2 0 0 1 .356-.076h11.533a3.2 3.2 0 0 1 2.848 1.856l1.032 2.5h5.589a2.154 2.154 0 0 0 1.851-1.1 2.471 2.471 0 0 0 .035-2.233zM13 23.238l-1.032-2.727h9.291a7.49 7.49 0 0 1 2.065 2.576l.071.114H13z"
                    class="cls-2" transform="translate(-2 -18.2)" />
                <path id="Path_10918" d="M43.576 49.3a1.65 1.65 0 0 0 1.61-1.3H42a1.618 1.618 0 0 0 1.576 1.3z"
                    class="cls-2" transform="translate(-27.674 -36.788)" />
                <path id="Path_10919" d="M28.176 49.3a1.65 1.65 0 0 0 1.61-1.3H26.6a1.618 1.618 0 0 0 1.576 1.3z"
                    class="cls-2" transform="translate(-17.789 -36.788)" />
                <path id="Path_10920" d="M17.61 49.3a1.65 1.65 0 0 0 1.61-1.3H16a1.686 1.686 0 0 0 1.61 1.3z"
                    class="cls-2" transform="translate(-10.985 -36.788)" />
                <path id="Path_10921" d="M7.01 49.3A1.65 1.65 0 0 0 8.62 48H5.4a1.686 1.686 0 0 0 1.61 1.3z"
                    class="cls-2" transform="translate(-4.235 -36.788)" />
                <path id="Path_10922"
                    d="M16.252 39.583a.311.311 0 0 0 0-.343l-.72-1.44a2.376 2.376 0 0 0-2.124-1.3H2.343a.368.368 0 0 0-.343.343v2.569a.343.343 0 0 0 .343.343h13.635a.349.349 0 0 0 .274-.172z"
                    class="cls-2" transform="translate(-2 -29.469)" />
            </g>
        </g>
    </svg>
</ng-template>

<ng-template #courierStatusImage>
    <svg xmlns="http://www.w3.org/2000/svg" id="Courier_Blue" width="35" height="35" viewBox="0 0 35 35">
        <path id="Path_38127" d="M17.5 0A17.5 17.5 0 1 1 0 17.5 17.5 17.5 0 0 1 17.5 0z" class="cls-1" />
        <g id="Group_24080" transform="translate(-84.647 -475.292)">
            <path id="Path_38207"
                d="M148.485 260.441v6.026a.524.524 0 0 1-.328.535q-3.962 1.988-7.917 3.991a.543.543 0 0 1-.522.008q-4.314-2.016-8.634-4.02a.464.464 0 0 1-.3-.48q.007-6.059 0-12.117a.464.464 0 0 1 .3-.481q4.32-2 8.634-4.02a.543.543 0 0 1 .522.006q3.965 2.007 7.936 4a.509.509 0 0 1 .31.523c-.005 2.012-.001 4.021-.001 6.029zm-.681-5.612c-.073.033-.112.049-.149.067q-3.6 1.813-7.2 3.623c-.143.071-.141.169-.141.293v11.362c.082-.036.139-.059.194-.087q3.561-1.8 7.123-3.588a.277.277 0 0 0 .171-.3v-11.131zm-16.324-.022c0 .075-.01.122-.01.169v11.262a.223.223 0 0 0 .156.236q3.915 1.817 7.828 3.64c.055.026.113.045.177.071 0-.05.008-.078.008-.106v-11.326c0-.141-.068-.183-.169-.229l-2.16-1-.738-.343V262.738c0 .405-.206.541-.577.381q-1.075-.462-2.148-.926c-.3-.128-.337-.187-.337-.517 0-1.766-.022-3.533.014-5.3a.778.778 0 0 0-.581-.9c-.495-.176-.962-.437-1.463-.669zm5.546 1.823c.948.441 1.855.859 2.756 1.288a.4.4 0 0 0 .392-.013q3.482-1.762 6.969-3.518c.067-.034.131-.073.206-.115a.376.376 0 0 0-.058-.052c-.968-.489-1.935-.981-2.908-1.461a.341.341 0 0 0-.269.023q-2.734 1.475-5.462 2.962zm-2.638-1.228c.579.269 1.121.514 1.656.775a.394.394 0 0 0 .41-.014q2.782-1.52 5.571-3.028l1.5-.818c-.514-.26-1-.5-1.484-.754a.334.334 0 0 0-.35 0q-2.483 1.311-4.972 2.611zm-2.442-1.138c.566.264 1.083.508 1.6.743a.235.235 0 0 0 .171-.032q3.307-1.73 6.611-3.464l.777-.411c-.316-.16-.606-.291-.88-.45a.488.488 0 0 0-.507-.011c-1.621.764-3.248 1.516-4.872 2.272zm3.948 8.06c0-1.81 0-3.581-.006-5.353a.259.259 0 0 0-.137-.174c-.439-.214-.884-.416-1.327-.622-.069-.032-.139-.06-.208-.09a.183.183 0 0 0-.016.038v5.358a.188.188 0 0 0 .087.142c.519.233 1.046.458 1.607.7z"
                class="cls-1" transform="translate(-37.139 232.472)" />
            <path id="Path_38208"
                d="M305.943 337.248V348.404a.272.272 0 0 1-.169.291q-3.526 1.754-7.046 3.52c-.055.027-.112.049-.192.085v-.227-10.918c0-.121 0-.217.139-.287q3.563-1.771 7.12-3.554c.037-.014.075-.034.148-.066z"
                class="cls-2" transform="translate(-195.271 150.362)" />
            <path id="Path_38209"
                d="M142.78 336.855c.5.227.958.481 1.449.653a.763.763 0 0 1 .575.883c-.036 1.732-.014 3.465-.014 5.2 0 .324.039.381.333.507l2.125.908c.368.157.571.024.571-.374v-5.452l.73.336 2.137.984c.1.046.167.087.167.225q-.008 5.555 0 11.11c0 .028 0 .056-.008.1-.064-.025-.121-.045-.176-.07l-7.743-3.571a.218.218 0 0 1-.155-.232q.006-5.523 0-11.047c-.001-.04.005-.087.009-.16z"
                class="cls-2" transform="translate(-48.447 150.733)" />
            <path id="Path_38210"
                d="M240.611 304.588l1.609-.87q2.7-1.455 5.4-2.905a.339.339 0 0 1 .266-.022c.962.471 1.918.953 2.876 1.433a.371.371 0 0 1 .057.051c-.075.042-.138.08-.2.113l-6.893 3.451a.393.393 0 0 1-.388.013c-.892-.422-1.789-.831-2.727-1.264z"
                class="cls-2" transform="translate(-140.6 184.383)" />
            <path id="Path_38211"
                d="M194.179 283.223l2.3-1.2 4.918-2.561a.333.333 0 0 1 .346 0c.477.247.959.484 1.468.74-.511.277-1 .541-1.482.8q-2.756 1.484-5.511 2.97a.392.392 0 0 1-.405.013c-.525-.257-1.061-.498-1.634-.762z"
                class="cls-2" transform="translate(-96.889 204.543)" />
            <path id="Path_38212"
                d="M151.2 266.581l2.872-1.327c1.607-.742 3.216-1.48 4.82-2.229a.486.486 0 0 1 .5.011c.271.155.558.285.871.441l-.769.4-6.54 3.4a.235.235 0 0 1-.169.031c-.517-.228-1.028-.468-1.585-.727z"
                class="cls-2" transform="translate(-56.403 220.068)" />
            <path id="Path_38213"
                d="M192.471 365.288c-.555-.238-1.075-.459-1.593-.687a.184.184 0 0 1-.087-.139v-5.255a.18.18 0 0 1 .016-.037c.069.029.138.057.206.088.439.2.878.4 1.313.61a.254.254 0 0 1 .136.171c.01 1.736.009 3.474.009 5.249z"
                class="cls-2" transform="translate(-93.731 129.438)" />
        </g>
    </svg>
</ng-template>
<!-- Status SVG image end-->