import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Account } from 'src/app/models/admin/account';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';

@Injectable()
export class FilterService {
  constructor(
    private http: HttpClient,
    private currentSessionUserData: CurrentSessionDataService
  ) { }

  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  getData(url: any): Observable<any> {
    return this.http.get<any>(url).pipe(catchError(this.handelError));
  }

  getSwitchAccount(params: any): Observable<any> {
    // let request = {
    //   offset: 0,
    //   fetch: 10,
    //   search_text: '',
    //   parameters: [
    //     {
    //       name: 'userEmail',
    //       value: email,
    //     },
    //     {
    //       name: 'accountId',
    //       value: ohCode,
    //     },
    //     {
    //       name: 'byGroupId',
    //       value: byGroup,
    //     },
    //   ],
    //   sort_options: [],
    //   filter_options: [],
    // };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'useraccount/privilege',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: any) {
    return throwError(
      {
        status: error.status,
        statusText: error.statusText,
      } || 'Server Error'
    );
  }

  getAdminControllingAndNonControllingCustomerList(
    offset: number,
    fetch: number
  ): Observable<any> {
    let request = {
      offset: offset,
      fetch: fetch,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [{ column_name: 'status', operator: '=', value: '1' }],
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url + 'accountSystemAdmin',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getAutoCompleteSearchRecords(
    val: any,
    offset: any,
    fetch: number
  ): Observable<any> {
    let request = {
      offset: offset,
      fetch: fetch,
      search_text: val,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [{ column_name: 'status', operator: '=', value: '1' }],
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  searchAccounts(
    val: any,
    offset: any,
    fetch: number,
    emailId: any,
    isAdmin: any
  ): Observable<any> {
    let request = {
      offset: offset,
      fetch: fetch,
      search_text: val,
      parameters: [
        {
          name: 'admin',
          value: isAdmin,
        },
      ],
      filter_options: [{ column_name: 'status', operator: '=', value: '1' }],
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url + 'user/search?userEmail=' + emailId,
        request
      )
      .pipe(catchError(this.handelError));
  }

  // TODO: to be refactored
  shipmentAutoSuggesion(val: any): Observable<any> {
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let isParent = this.currentAccountData.isParent;
    let url =
      environment.base_api_desktop_url +
      'GlobalSearch/list?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&isParent=' +
      isParent;
    let request = {
      offset: 0,
      fetch: 3,
      search_text: val,
      isGlobalSearch: true,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      advanceFilterOptions: [],
      parameters: [],
      sort_options: [],
      filter_options: [],
    };
    return this.http
      .post<Account[]>(url, request)
      .pipe(catchError(this.handelError));
  }

  getGroups(email: any) {
    let url =
      environment.base_api_desktop_url + 'GetGroupNames?userEmail=' + email;
    let request = {
      offset: 0,
      fetch: 50,
    };
    return this.http.post<any>(url, request).pipe(catchError(this.handelError));
  }
}
