<div class="shipment-not-found-page-ctr">
    <div class="shipment-not-found-page-inner">
        <h1>Shipment Quick Search</h1>
        <div class="alert-msg-shipment">
            <img src="assets/img/alert-triangle.svg" alt="" />
            <h5>{{showText}} not found.</h5>
            <p>Please check entered Shipment Number is valid and created from 2021 or later</p>
        </div>

        <div class="shipment-search-ctr">
            <label>Enter a shipment number<span class="red">*</span></label>
            <div class="ng-autocomplete">
                <div class="input-container">
                    <input type="text" aria-autocomplete="list" role="combobox" autocomplete="off"
                        placeholder="Search by Shipment Number. Eg: S01234567 or B00123456"
                        aria-label="Search by shipment number" ng-reflect-model="" ngdefaultcontrol=""
                        class="ng-pristine ng-valid ng-touched" [(ngModel)]="searchText"
                        (keyup.enter)="emitSearchedText()">
                </div>
                <img src="assets/img/Iconfeather-search.png" alt="" (click)="emitSearchedText()" class="search">
            </div>
        </div>
    </div>
    <div class="footer-img-ctr">
        <img src="assets/img/footer-img-bg.svg" alt="" />
    </div>
</div>