import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
  transform(date: any, args?: any): any {
    if (!date) return '---';
    if (!args) return date;

    if (args == 'MMMDDYYYY') {
      return CommonUtils.formatDateMMMDDYYYY(date);
    }
    return date;
  }
}
