import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';

@Pipe({
  name: 'additionalReferenceRenderPipe',
})
export class AdditionalReferenceRenderPipe implements PipeTransform {
  transform(list: any, ...args: any[]): any {
    if (!list || list?.length <= 0) {
      return '';
    }
    let htmlString = '';
    let hash = Object.create(null);
    let grouped: any[] = [];

    // To show only non-customized data
    list = list.filter((x: any) => x.data_source !== 'CustomizedField');
    list
      .sort((a: any, b: any) => (a.code < b.code ? -1 : 1))
      .sort((a: any, b: any) => (a.description < b.description ? -1 : 1));

    list.forEach(function (o: any) {
      let key = ['code', 'description']
        .map(function (k) {
          return o[k];
        })
        .join(' : ');

      if (!hash[key]) {
        hash[key] = { code: o.code, description: o.description, display: '' };
        grouped.push(hash[key]);
      }
      ['value'].forEach(function (k) {
        hash[key]['display'] += o['value'];
        if (o['value_context']) {
          hash[key]['display'] += ' (' + o['value_context'] + ') ';
        }
        hash[key]['display'] += ', ';
      });
    });

    grouped.forEach(function (o) {
      htmlString +=
        '<div class="pb-2 display-change-line">' +
        o['code'] +
        ' - ' +
        o['description'] +
        ': ' +
        CommonUtils.removeLastComma(o['display']) +
        '</div>';
    });

    if (htmlString.trim().length > 0) {
      htmlString =
        '<h5 class="displayset d-block">Additional References</h5>' +
        htmlString;
    }
    return htmlString;
  }
}
