import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import { BookingServies } from 'src/app/features/booking/services/booking.service';
import { ShipmentServies } from 'src/app/features/shipments/services/shipment.service';
import { CustomsService } from 'src/app/features/customs/services/customs.service';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_TabsName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { ChangeLogService } from '../../services/change-log.service';

import { CurrentSessionDataService } from '../../services/current-session-data.service';

@Component({
  selector: 'app-change-logs',
  templateUrl: './change-logs.component.html',
  styleUrls: ['./change-logs.component.scss'],
})
export class ChangeLogsComponent implements OnInit, OnChanges {
  @Input() selectedShipment: any;
  @Input() type: any;
  @Output() showViewReference = new EventEmitter<any>(false);
  shipmentProgress: any[] = [];
  mimeType: any[] = [];
  logsDataShipmentProgress: any[] = [];
  logsDataShipmentDetails: any[] = [];
  logsDataShipmentDocuments: any[] = [];
  logsDataShipmentContainer: any[] = [];
  logsDataActionFieldContainer: any[] = [];
  isProgressShow = false;
  currentUserData: any;
  progressCount = false;
  detailsCount = false;
  documentsCount = false;
  containerCount = false;
  actionFieldCount = false;
  categoryTypes: any = '';
  canLoadTabData: boolean = false;

  constructor(
    private service: ChangeLogService,
    private shipmentServies: ShipmentServies,
    private bookingService: BookingServies,
    private customService: CustomsService,
    private appInsightsService: AppInsightsService,
    private currentSessionUserData: CurrentSessionDataService
  ) {
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
  }

  ngOnInit(): void {
    this.shipmentServies.activeShipmentDetailTab.subscribe((value) => {
      this.canLoadTabData = value == 6;
      //console.log('type = ', this.type);
      if (this.canLoadTabData) {
        this.loadInitialData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.canLoadTabData) {
      this.loadInitialData();
    }
  }

  loadInitialData() {
    this.trackAITabClickEvent();
    this.mimeType = CommonUtils.getMimeFileTypes();
    if (this.currentSessionUserData) {
      this.getLogsCount();
    }
  }

  getLogsCount() {
    if (this.type == 'shipments') {
      this.shipmentServies
        .getLogsCount(
          this.selectedShipment.forwarder_reference,
          this.currentUserData.emailAddress
        )
        .subscribe((res) => {
          if (res) {
            this.categoryTypes = res.result;
            this.showAccordion(res);
          }
        });
    } else if (this.type == 'bookings') {
      this.bookingService
        .getLogsCount(
          this.selectedShipment?.forwarder_reference,
          this.currentUserData.emailAddress
        )
        .subscribe((value) => {
          if (value) {
            this.categoryTypes = value.result;
            this.showAccordion(value);
          }
        });
    } else if (this.type == 'customs') {
      this.customService
        .getLogsCount(
          this.selectedShipment?.forwarder_reference,
          this.currentUserData.emailAddress
        )
        .subscribe((value) => {
          if (value) {
            this.categoryTypes = value.result;
            this.showAccordion(value);
          }
        });
    }
  }

  showAccordion(res: any) {
    this.progressCount = false;
    this.detailsCount = false;
    this.documentsCount = false;
    this.containerCount = false;
    this.actionFieldCount = false;
    if (res.result.length > 0) {
      res.result.forEach((element: any) => {
        switch (element.changeLogCategory) {
          case 1:
          case 5:
            this.progressCount = true;
            break;
          case 2:
          case 6:
          case 7:
            this.detailsCount = true;
            break;
          case 3:
          case 8:
            this.documentsCount = true;
            break;
          case 4:
            this.containerCount = true;
            break;
          case 16:
            this.actionFieldCount = true;
            break;
        }
      });
    } else {
      this.moveTab();
    }
  }

  moveTab() {
    if (this.type == 'shipments') {
      // $('#navCenter > li> button').removeClass('active');
      $('#home-tab').addClass('active');
      //$('#myTabContent > div').removeClass('show active');
      $('#home').addClass('show active');
    } else if (this.type == 'bookings') {
      //$('#navCenter > li> button').removeClass('active');
      //$('#home-tab').addClass('active');
      //$('#myTabContent > div').removeClass('show active');
      //$('#home1').addClass('show active');
    } else if (this.type == 'customs') {
      //$('#navCenter > li> button').removeClass('active');
      //$('#home-tab').addClass('active');
      //$('#myTabContent > div').removeClass('show active');
      //$('#customDetail').addClass('show active');
    }
  }

  getChangeLogs(category: any, sort: any) {
    let url = '';
    if (this.type == 'shipments') {
      url = 'Shipment/ChangeLog?shipmentNumber=';
    } else if (this.type == 'bookings') {
      url = 'Booking/ChangeLog?shipmentNumber=';
    } else if (this.type == 'customs') {
      url = 'Customs/changeLog?customsNumber=';
    }
    this.isProgressShow = true;
    this.service
      .getChangeLogs(
        this.selectedShipment?.forwarder_reference,
        category,
        sort,
        url,
        this.currentUserData.emailAddress
      )
      .subscribe((logs: any) => {
        if (logs.result) {
          switch (category) {
            case '1':
            case '5':
              this.logsDataShipmentProgress = logs.result;
              break;
            case '2':
            case '6':
            case '7':
              this.logsDataShipmentDetails = logs.result;
              break;
            case '3':
            case '8':
              this.logsDataShipmentDocuments = logs.result;
              break;
            case '4':
              this.logsDataShipmentContainer = logs.result;
              break;
            case '16':
              this.logsDataActionFieldContainer = logs.result;
              break;
          }

          this.isProgressShow = false;
        }
      });
  }

  progressMenu(
    shipmentCategory: any,
    bookingCategory: any,
    customCategory: any
  ) {
    let sort = [
      {
        column_name: 'createDateTime',
        direction: 'desc',
      },
    ];
    if (this.type == 'shipments') {
      this.getChangeLogs(shipmentCategory, sort);
    } else if (this.type == 'bookings') {
      this.getChangeLogs(bookingCategory, sort);
    } else if (this.type == 'customs') {
      this.getChangeLogs(customCategory, sort);
    }
  }

  loadActionFieldLogs() {
    let sortColumn = [
      {
        column_name: 'createDateTime',
        direction: 'desc',
      },
    ];
    this.getActionFieldChangeLogs(sortColumn);
  }

  sortFunction(
    event: any,
    shipmentCategory: any,
    bookingCategory: any,
    customCategory: any
  ) {
    $('.sort-first').hide();
    $('.factive').removeClass('factive');
    const id = $('#' + event.target.id);
    const order = id.attr('data-order');
    const column = id.attr('data-name');
    let sortColumn: any[] = [];
    if (order === 'desc') {
      id.attr('data-order', 'asc');
      id.removeClass();
      id.addClass('asc sort factive');
    } else {
      id.attr('data-order', 'desc');
      id.removeClass();
      id.addClass('desc sort factive');
    }

    sortColumn.push({
      column_name: column,
      direction: order,
    });

    // ActionField Sorting = 16
    if (shipmentCategory == 16) {
      this.getActionFieldChangeLogs(sortColumn);
    } else {
      if (this.type == 'shipments') {
        this.getChangeLogs(shipmentCategory, sortColumn);
      } else if (this.type == 'bookings') {
        this.getChangeLogs(bookingCategory, sortColumn);
      } else if (this.type == 'customs') {
        this.getChangeLogs(customCategory, sortColumn);
      }
    }
  }

  dateFormat(value: any) {
    let arr = value.split('T');
    return arr[0];
  }

  timeFormat(value: any) {
    let arr = value.split('T');
    let splitHM = arr[1].split(':');
    return splitHM[0] + ':' + splitHM[1];
  }

  downloadFile(data: any, fileType: any, actionType: any, fileNames: any) {
    CommonUtils.downloadBlobFile(
      data,
      fileType,
      actionType,
      fileNames,
      this.mimeType
    );
  }

  docDownload(
    filepath: any,
    type: any,
    action: any,
    fileNames: any,
    edocType: any
  ) {
    this.trackAIFileDownloadEvent(this.type, fileNames, edocType);

    let fPath = filepath.replace(/\\/g, '\\\\');
    this.shipmentServies.downloadFile(fPath).subscribe(
      (res) => {
        if (res?.result !== '') {
          this.downloadFile(res, type, action, fileNames);
        }
      },
      (error) => console.log('Error in download documents:', error)
    );
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
      $('#accordion > div').removeClass('change-icon add-border');
    } else {
      $('#accordion > div').removeClass('change-icon add-border');
      $(event).addClass('change-icon add-border');
    }
  }

  trackAITabClickEvent() {
    if (!this.type) return;

    this.appInsightsService.logEvent(AI_CommonEvents.LogTabViewed.toString(), {
      [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
      [AI_CustomProps.ModuleName]: this.appInsightsService.getAIModuleName(
        this.type
      ),
    });
  }

  trackAIFileDownloadEvent(moduleType: any, fileNames: any, edocType: any) {
    this.appInsightsService.trackAIFileDownloadEvent(
      AI_CommonEvents.DocumentDownload.toString(),
      this.selectedShipment?.forwarder_reference,
      this.appInsightsService.getAIModuleName(moduleType),
      AI_TabsName.LogTab,
      fileNames,
      edocType
    );
  }

  viewReference() {
    this.showViewReference.emit(true);
  }

  headerNameset() {
    if (this.type == 'shipments') {
      return 'Shipment Details';
    } else if (this.type == 'bookings') {
      return 'Booking Details';
    } else if (this.type == 'customs') {
      return 'Customs Details';
    }
    return '';
  }

  getActionFieldChangeLogs(sort: any) {
    let url = 'ActionField/GetActionFieldLogs';
    let param = {
      offset: 0,
      fetch: 100,
      search_text: '',
      isGlobalSearch: false,
      parameters: [
        {
          name: 'module',
          value: this.type,
        },
        {
          name: 'shipmentNumber',
          value: this.selectedShipment?.forwarder_reference,
        },
      ],
      sort_options: sort,
      filter_options: [],
    };
    this.isProgressShow = true;
    this.service.getActionFieldChangeLogs(url, param).subscribe((logs: any) => {
      if (logs.result) {
        this.logsDataActionFieldContainer = logs.result ? logs.result : [];
        this.isProgressShow = false;
      }
    });
  }
}
