<div class="rap-desktop">
    <div id="allLocationButton" [ngClass]="{'borderSurrounding':accountDropdownToggle}"
        class="btn filter-selct-btn position-relative w100 custom-arow ml-1" style="padding-top: 6px"
        id="dropdownMenuButton" (click)="statusTextBoxHandler()">
        {{selectedFilterText}}
        <span><img src="../../../../../assets/img/down_arrow-lg.svg" alt=""
                [ngClass]="{'rotate-arrow':accountDropdownToggle}" /></span>
        <span><img src="../../../../../assets/img/close-sm.png" class="close-sm" alt=""
                (click)="clearFilters(listData.index, listData.id)" /></span>
    </div>
</div>
<div *ngIf="accountDropdownToggle" class="rap-sec desktop"
    class="{{ pickupFilterSelectedList.length > 0 || orginFilterSelectedList.length > 0 || destinationFilterSelectedList.length > 0 || finalDeliveryFilterSelectedList.length > 0 || originCountySelectedList.length > 0 || destinationCountySelectedList.length > 0  ? 'add-selection' : 'remove-selection'}}">
    <div (click)="CloseOut()" class="all-location-backdrop"></div>
    <div class="filterRange-wrap-bookings">
        <div class="flter-dateRange" id="dateRangeDropdown">
            <div class="date-range">
                <ul>
                    <li [ngClass]="{'active-class': locationFilterValue== filter.status}"
                        *ngFor="let filter of locationFilter" (click)="selectLocationDebounce.next(filter)">
                        {{ filter.status }}
                    </li>
                </ul>
            </div>
            <div class="nav-conainer add-border">
                <ng-container>
                    <app-progressbar *ngIf="isProgress "></app-progressbar>
                </ng-container>
                <div class="accountStatusFilterDropdown">
                    <ul class="statusList">
                        <div class="search-area">
                            <input type="text" class="form-control" style="height: 28px !important;" name="search"
                                placeholder="{{locationFilterValue=='Origin Country' || locationFilterValue=='Destination Country' ? 'Search for Country':'Search for City'}}"
                                [(ngModel)]="searchText" (keyup)="getSearchLocationList(searchText)" autocomplete="off"
                                #searchTextbox />
                            <span class="right-set-search">
                                <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" class="mr-1"
                                    *ngIf="searchText.length >0"
                                    (click)="searchTextbox.value='';searchText = '';getSearchLocationList('');">
                                <img class="search-set" src="assets/img/Iconfeather-search.png" alt="search-set" />
                            </span>
                        </div>
                        <div class="scroll-sec" infiniteScroll [infiniteScrollDistance]="2"
                            [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll(searchText)">
                            <ng-container>
                                <div *ngIf="locationFilterValue=='Pickup' && pickupRecentList.length > 0 ">
                                    <h6 class="mb-1">Recent Locations</h6>
                                    <div *ngFor="let show of pickupRecentList; let indexOfelement = index"
                                        class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                        <div class="con-set">
                                            <span class="move-right data-up">
                                                <span class="list-data">{{show.name | titlecase}}</span>

                                            </span>
                                            <span class="move-left">
                                                <span class="list-data">
                                                    {{show.city | titlecase}},
                                                    <ng-container
                                                        *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                                        {{show.state | uppercase}},</ng-container>
                                                    {{show.countryName | titlecase}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="locationFilterValue=='Origin' && orginRecentList.length > 0 ">
                                    <h6 class="mb-1">Recent Locations</h6>
                                    <div *ngFor="let show of orginRecentList; let indexOfelement = index"
                                        class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                        <div class="con-set">
                                            <span class="move-right data-up">
                                                <span class="list-data">{{ show.rL_PortName }}<ng-container
                                                        *ngIf="show.rL_PortName">,
                                                    </ng-container> {{ show.country
                                                    }}</span>
                                            </span>
                                            <span class="move-left">
                                                <span class="move-left">{{ show.rL_Code }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="locationFilterValue=='Destination' && destinationRecentList.length > 0">
                                    <h6 class="mb-1">Recent Locations</h6>
                                    <div *ngFor="let show of destinationRecentList; let indexOfelement = index"
                                        class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                        <div class="con-set">
                                            <span class="move-right data-up">
                                                <span class="list-data">{{ show.rL_PortName }}<ng-container
                                                        *ngIf="show.rL_PortName">,
                                                    </ng-container> {{ show.country
                                                    }}</span>
                                            </span>
                                            <span class="move-left">
                                                <span class="move-left">{{ show.rL_Code }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="locationFilterValue=='Final Delivery' && finalDeliveryRecentList.length > 0">
                                    <h6 class="mb-1">Recent Locations</h6>
                                    <div *ngFor="let show of finalDeliveryRecentList; let indexOfelement = index"
                                        class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                        <div class="con-set">
                                            <span class="move-right data-up">
                                                <span class="list-data">{{show.name | titlecase}}</span>
                                            </span>
                                            <span class="move-left">
                                                <span class="list-data">
                                                    {{show.city | titlecase}},
                                                    <ng-container
                                                        *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                                        {{show.state | uppercase}},</ng-container>
                                                    {{show.countryName | titlecase}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="locationFilterValue=='Origin Country' && originCountyList.length > 0">
                                    <h6 class="mb-1">Recent Locations</h6>
                                    <div *ngFor="let show of originCountyList; let indexOfelement = index"
                                        class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                        <div class="con-set">
                                            <span class="move-right data-up">
                                                <span class="list-data">
                                                    {{show.country}}
                                                </span>
                                            </span>
                                            <span class="move-left">
                                                {{show.rL_RN_NKCountryCode}}
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                <div
                                    *ngIf="locationFilterValue=='Destination Country' && destinationCountyList.length > 0">
                                    <h6 class="mb-1">Recent Locations</h6>
                                    <div *ngFor="let show of destinationCountyList; let indexOfelement = index"
                                        class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                        <div class="con-set">
                                            <span class="move-right data-up">
                                                <span class="list-data">
                                                    {{show.country}}
                                                </span>
                                            </span>
                                            <span class="move-left">
                                                {{show.rL_RN_NKCountryCode}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container>
                                <h6 class="mb-1 mt-2">All Locations</h6>
                                <div>
                                    <div class="status-text"
                                        *ngFor="let show of allLocationList; let indexOfelement = index"
                                        (click)="locationFilterSelected(show,indexOfelement)">
                                        <ng-container *ngIf="allLocationList.length > 0">
                                            <div class="con-set"
                                                *ngIf="locationFilterValue=='Pickup'  || locationFilterValue=='Final Delivery'">
                                                <span class="move-right">
                                                    <span class="list-data">{{show.name | titlecase}}</span>
                                                </span>
                                                <span class="move-left">
                                                    <span class="list-data">
                                                        {{show.city | titlecase}}<ng-container *ngIf="show.city">,
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                                            {{show.state | uppercase}},</ng-container>
                                                        {{show.countryName | titlecase}}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="con-set"
                                                *ngIf="locationFilterValue=='Origin'|| locationFilterValue=='Destination' ">
                                                <span class="move-right">
                                                    <span class="list-data">{{show.rL_PortName}}<ng-container
                                                            *ngIf="show.rL_PortName">,</ng-container>
                                                        {{show.country}}</span>
                                                </span>
                                                <span class="move-left">{{show.rL_Code}}</span>
                                            </div>
                                            <div class="con-set"
                                                *ngIf="locationFilterValue=='Destination Country' || locationFilterValue=='Origin Country' ">
                                                <span class="move-right">
                                                    <span class="list-data">
                                                        {{show.country}}
                                                    </span>
                                                </span>
                                                <span class="move-left">
                                                    {{show.rL_RN_NKCountryCode}}
                                                </span>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="font-w" *ngIf="allLocationList.length <= 0 && !isProgress">
                                        No Records Found
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="nav-conainer padding-set new-pad"
                *ngIf="pickupFilterSelectedList.length > 0 || orginFilterSelectedList.length > 0 || destinationFilterSelectedList.length > 0 || finalDeliveryFilterSelectedList.length > 0 || originCountySelectedList.length > 0 || destinationCountySelectedList.length > 0">
                <div class="accountStatusFilterDropdown">
                    <ul class="statusList scroll-set-sec">
                        <div *ngIf="pickupFilterSelectedList.length>0" class="status-list below-desktop">
                            <h6 class="mb-1 mt-3">Pickup Location Filters</h6>
                            <div class="con-set make-one"
                                *ngFor="let show of pickupFilterSelectedList; let indexOfelement = index">
                                <span class="move-right">
                                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt=""
                                            (click)="childSelectedText(indexOfelement,show,'Pickup')" />
                                        <span class="set-one">{{show.name | titlecase}}</span>
                                    </span>
                                </span>
                                <span class="move-left">
                                    <span class="list-data">
                                        {{show.city | titlecase}},
                                        <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                            {{show.state | uppercase}},</ng-container>
                                        {{show.countryName | titlecase}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="orginFilterSelectedList.length>0" class="status-list">
                            <h6 class="mb-1 mt-3">Origin Location Filters</h6>
                            <div class="con-set"
                                *ngFor="let show of orginFilterSelectedList; let indexOfelement = index">
                                <span class="move-right">
                                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt=""
                                            (click)="childSelectedText(indexOfelement,show,'Origin')" />
                                        <span class="set-one">{{ show?.rL_PortName }}<ng-container
                                                *ngIf="show.rL_PortName">,
                                            </ng-container>&nbsp;{{show?.country}}</span>
                                    </span>
                                </span>

                                <span class="move-left">
                                    {{
                                    show?.rL_Code
                                    }}</span>
                            </div>
                        </div>
                        <div *ngIf="destinationFilterSelectedList.length>0" class="status-list">
                            <h6 class="mb-1 mt-3">Destination Location Filters</h6>
                            <div class="con-set"
                                *ngFor="let show of destinationFilterSelectedList; let indexOfelement = index">
                                <span class="move-right">
                                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt=""
                                            (click)="childSelectedText(indexOfelement,show,'Destination')" />
                                        <span class="set-one">{{ show?.rL_PortName }}<ng-container
                                                *ngIf="show.rL_PortName">,
                                            </ng-container>&nbsp;{{show?.country}}</span>
                                    </span>
                                </span>

                                <span class="move-left">
                                    {{
                                    show?.rL_Code
                                    }}</span>
                            </div>
                        </div>
                        <div *ngIf="finalDeliveryFilterSelectedList.length>0" class="status-list">
                            <h6 class="mb-1 mt-3">Final Delivery Filters</h6>
                            <div class="con-set make-one"
                                *ngFor="let show of finalDeliveryFilterSelectedList; let indexOfelement = index">

                                <span class="move-right">
                                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt=""
                                            (click)="childSelectedText(indexOfelement,show,'Final Delivery')" />
                                        <span class="set-one">{{show.name | titlecase}}</span>
                                    </span>
                                </span>
                                <span class="move-left">
                                    <span class="list-data">
                                        {{show.city | titlecase}},
                                        <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                            {{show.state | uppercase}},</ng-container>
                                        {{show.countryName | titlecase}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="originCountySelectedList.length>0" class="status-list">
                            <h6 class="mb-1 mt-3">Origin Country Filters</h6>
                            <div class="con-set"
                                *ngFor="let show of originCountySelectedList; let indexOfelement = index">
                                <span class="move-right" style="width: 80%">
                                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt=""
                                            (click)="childSelectedText(indexOfelement,show,'Origin Country')" />
                                        <span class="set-one">{{show?.country}} </span>
                                    </span>
                                </span>
                                <span class="move-left mt-0 set-left-one">{{show?.rL_RN_NKCountryCode}}</span>
                            </div>
                        </div>
                        <div *ngIf="destinationCountySelectedList.length>0" class="status-list">
                            <h6 class="mb-1 mt-3">Destination Country Filters</h6>
                            <div class="con-set"
                                *ngFor="let show of destinationCountySelectedList; let indexOfelement = index">
                                <span class="move-right" style="width: 80%">
                                    <span class="close-div"><img src="../../../../../../assets/img/close-sm.png" alt=""
                                            (click)="childSelectedText(indexOfelement,show,'Destination Country')" />
                                        <span class="set-one">{{show?.country}}</span>
                                    </span>
                                </span>

                                <span class="move-left mt-0 set-left-one">{{show?.rL_RN_NKCountryCode}}</span>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="rap-sec mobile">
    <div class="accordion-body origin-Location">
        <div class="p-2">
            <div class="pickup mt-3" *ngIf="pickupFilterSelectedList.length>0">
                <h5>pickup Location Filters</h5>
                <div *ngFor="let show of pickupFilterSelectedList; let indexOfelement = index" class="status-list">
                    <div class="ruler">
                        <div class="d-flex align-items-center justify-content-between ">
                            <span class="close-div "><img src="../../../../../../assets/img/close-sm.png"
                                    style="width:15px;" alt=""
                                    (click)="childSelectedText(indexOfelement,show,'Pickup')" />
                                {{show.name | titlecase}}
                            </span>
                            <span class="move-left">
                                {{show.city | titlecase}},
                                <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                    {{show.state | uppercase}},</ng-container>
                                {{show.countryName | titlecase}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="origin mt-3" *ngIf="orginFilterSelectedList.length>0">
                <h5>Origin Location Filters</h5>
                <div *ngFor="let show of orginFilterSelectedList; let indexOfelement = index" class="status-list">
                    <div class="ruler">
                        <div class="d-flex align-items-center justify-content-between ">
                            <span class="close-div "><img src="../../../../../../assets/img/close-sm.png"
                                    style="width:15px;" alt=""
                                    (click)="childSelectedText(indexOfelement,show,'Origin')" />
                                {{ show?.rL_PortName }}<ng-container *ngIf="show.rL_PortName">,
                                </ng-container> {{show?.country}}
                            </span>
                            <span class="move-left">{{
                                show?.rL_Code
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="origin mt-3" *ngIf="destinationFilterSelectedList.length>0">
                <h5>Destination Location Filters</h5>
                <div *ngFor="let show of destinationFilterSelectedList; let indexOfelement = index" class="status-list">
                    <div class="ruler">
                        <div class="d-flex align-items-center justify-content-between ">
                            <span class="close-div "><img src="../../../../../../assets/img/close-sm.png"
                                    style="width:15px;" alt=""
                                    (click)="childSelectedText(indexOfelement,show,'Destination')" />
                                {{ show?.rL_PortName }}<ng-container *ngIf="show.rL_PortName">,
                                </ng-container> {{show?.country}}
                            </span>
                            <span class="move-left">{{
                                show?.rL_Code
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pickup mt-3" *ngIf="finalDeliveryFilterSelectedList.length>0">
                <h5>Final Delivery Filters</h5>
                <div *ngFor="let show of finalDeliveryFilterSelectedList; let indexOfelement = index"
                    class="status-list">
                    <div class="ruler">
                        <div class="d-flex align-items-center justify-content-between ">
                            <span class="close-div "><img src="../../../../../../assets/img/close-sm.png"
                                    style="width:15px;" alt=""
                                    (click)="childSelectedText(indexOfelement,show,'Final Delivery')" />
                                {{show.name | titlecase}}
                            </span>
                            <span class="move-left">
                                {{show.city | titlecase}},
                                <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                    {{show.state | uppercase}},</ng-container>
                                {{show.countryName | titlecase}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filter-by mt-2">
                <h5>Location Filter By</h5>
                <div class="select-box mr-0">
                    <div class="inbox-set">
                        <input type="text" readonly class="form-control" (click)="mobileLocationTextBoxHandler()"
                            name="search" autocomplete="off" [(ngModel)]="selectedMobileValue" />
                        <div class="arrow-img-main">
                            <img *ngIf="!locationToggleMobile" alt="CopyImage" src="assets/img/down_arrow-lg.svg" />
                            <img *ngIf="locationToggleMobile" alt="CopyImage" src="assets/img/up_arrow_lg.svg" />
                        </div>
                    </div>
                    <div class="accountStatusFilterDropdown up-mobile"
                        [style.visibility]="locationToggleMobile ? 'visible' : 'hidden'">
                        <ul class="statusList mobile-set-level">
                            <li *ngFor="let filter of locationFilter" (click)="selectActivelocationFilter(filter)">
                                {{ filter.status }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="statusList p-0">
                    <div class="search-area input-group mt-0 mb-3">
                        <input type="text" class="form-control border-right-0 search-area" name="search"
                            autocomplete="off" placeholder="Search for City" [(ngModel)]="searchText"
                            (keyup)="getSearchLocationList(searchText)" (keyup.enter)="onSearchHandler(searchText)"
                            autocomplete="off" />
                        <div class="input-group-append">
                            <div class="search-bg input-group-text">
                                <img src="../../../../../assets/img/search-sm.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="location-innerscroll" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll(searchText)">
                    <ng-container>
                        <div *ngIf="locationFilterValue=='Pickup' && pickupRecentList.length > 0 " class="pl-2">
                            <h5 class="mb-1">Recent Locations</h5>
                            <div *ngFor="let show of pickupRecentList; let indexOfelement = index" class="status-text"
                                (click)="locationFilterSelected(show,indexOfelement)">
                                <div>
                                    <span class="move-right">
                                        {{show.name | titlecase}}
                                    </span>
                                    <span class="move-left">
                                        {{show.city | titlecase}},
                                        <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                            {{show.state | uppercase}},</ng-container>
                                        {{show.countryName | titlecase}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="locationFilterValue=='Origin' && orginRecentList.length > 0 " class="pl-2">
                            <h5 class="mb-1">Recent Locations</h5>
                            <div *ngFor="let show of orginRecentList; let indexOfelement = index" class="status-text"
                                (click)="locationFilterSelected(show,indexOfelement)">
                                <div>
                                    <span class="move-right">
                                        {{ show.rL_PortName }}<ng-container *ngIf="show.rL_PortName">,
                                        </ng-container> {{ show.country }}
                                    </span>
                                    <span class="move-left">
                                        {{ show.rL_Code }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="locationFilterValue=='Destination' && destinationRecentList.length > 0"
                            class="pl-2">
                            <h5 class="mb-1">Recent Locations</h5>
                            <div *ngFor="let show of destinationRecentList; let indexOfelement = index"
                                class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                <div>
                                    <span class="move-right">
                                        {{ show.rL_PortName }}<ng-container *ngIf="show.rL_PortName">,
                                        </ng-container> {{ show.country }}
                                    </span>
                                    <span class="move-left">
                                        {{ show.rL_Code }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="locationFilterValue=='Final Delivery' && finalDeliveryRecentList.length > 0 "
                            class="pl-2">
                            <h5 class="mb-1">Recent Locations</h5>
                            <div *ngFor="let show of finalDeliveryRecentList; let indexOfelement = index"
                                class="status-text" (click)="locationFilterSelected(show,indexOfelement)">
                                <div>
                                    <span class="move-right">
                                        {{show.name | titlecase}}
                                    </span>
                                    <span class="move-left">
                                        {{show.city | titlecase}},
                                        <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                            {{show.state | uppercase}},</ng-container>
                                        {{show.countryName | titlecase}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container>
                        <h5 class="mb-1 mt-3 pl-2">All Locations</h5>
                        <div class="pl-2">
                            <div class="status-text" *ngFor="let show of allLocationList; let indexOfelement = index"
                                (click)="locationFilterSelected(show,indexOfelement)">
                                <ng-container *ngIf="allLocationList.length > 0">
                                    <div *ngIf="locationFilterValue=='Pickup'|| locationFilterValue=='Final Delivery' ">
                                        <span class="move-right">
                                            <span class="list-data">{{show.name | titlecase}}</span>
                                        </span>
                                        <span class="move-left">
                                            <span class="list-data">
                                                {{show.city | titlecase}}<ng-container *ngIf="show.city">,
                                                </ng-container>
                                                <ng-container *ngIf="show.countryCode=='US' || show.countryCode=='CA'">
                                                    {{show.state | uppercase}},</ng-container>
                                                {{show.countryName | titlecase}}
                                            </span>
                                        </span>
                                    </div>
                                    <div *ngIf="locationFilterValue=='Origin'|| locationFilterValue=='Destination' ">
                                        <span class="move-right">
                                            <span class="list-data">{{show.rL_PortName}}<ng-container
                                                    *ngIf="show.rL_PortName">, </ng-container></span>
                                            <span class="list-data">{{show.country}}</span>
                                        </span>
                                        <span class="move-left">{{show.rL_Code}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container>
                                <app-progressbar *ngIf="isProgress"></app-progressbar>
                            </ng-container>
                            <div class="font-w" *ngIf="allLocationList.length <= 0 && !isProgress">
                                No Records Found
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>