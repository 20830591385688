import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { environment } from 'src/environments/environment';
import { CurrentSessionDataService } from '../shared/services/current-session-data.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonUtils } from '../common/utils';
@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: environment.appInsights.instrumentationKey,
    enableCorsCorrelation: true,
  };
  private currentUserData: any;
  private currentAccountData: any;

  constructor(
    private currentSessionUserData: CurrentSessionDataService,
    private deviceService: DeviceDetectorService
  ) {
    if (!AppInsights.config) {
      if (AppInsights.downloadAndSetup) {
        AppInsights.downloadAndSetup(this.config);
      }
    }
  }

  public logPageView(
    name: AI_PageName,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    if (
      !this.isValidAppInsightPageView(name) ||
      !this.isValidInstrumentationKey()
    )
      return;

    AppInsights.trackPageView(
      name,
      url,
      this.AddGlobalProperties(properties),
      measurements,
      duration
    );
  }

  public logEvent(
    name: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    if (!this.isValidInstrumentationKey()) return;
    AppInsights.trackEvent(
      name,
      this.AddGlobalProperties(properties),
      measurements
    );
  }

  public logTrace(
    message: string,
    properties?: { [key: string]: string },
    severityLevel?: AI.SeverityLevel
  ) {
    if (!this.isValidInstrumentationKey()) return;
    AppInsights.trackTrace(
      message,
      this.AddGlobalProperties(properties),
      severityLevel
    );
  }

  public logError(
    error: Error,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    if (!this.isValidInstrumentationKey()) return;
    AppInsights.trackException(
      error,
      '',
      this.AddGlobalProperties(properties),
      measurements
    );
  }

  private isValidAppInsightPageView(pageName: AI_PageName) {
    let userData = this.currentSessionUserData.getCurrentUserData();
    let accountData = this.currentSessionUserData.getCurrentAccountData();

    return (
      pageName === AI_PageName.Login ||
      pageName === AI_PageName.Register ||
      pageName === AI_PageName.ThankYouRegistration ||
      pageName === AI_PageName.ActivationPending ||
      pageName === AI_PageName.AccountDeactivated ||
      pageName === AI_PageName.ShipmentsPublicView ||
      pageName === AI_PageName.BookingsPublicView ||
      pageName === AI_PageName.CustomsPublicView ||
      pageName === AI_PageName.Faq ||
      (pageName && userData && accountData)
    );
  }

  private AddGlobalProperties(properties?: { [key: string]: string }): {
    [key: string]: string;
  } {
    if (!properties) {
      properties = {};
    }
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();

    if (this.currentAccountData && this.currentUserData) {
      if (this.currentAccountData?.isGroup) {
        properties = {
          ...properties,
          [AI_CustomProps.UserGroupName]:
            this.currentAccountData?.accountAliasName,
        };
      } else {
        properties = {
          ...properties,
          [AI_CustomProps.AccountName]:
            this.currentAccountData?.accountAliasName,
          [AI_CustomProps.OrganizationCode]: this.currentAccountData?.accountId,
        };
      }

      properties = {
        ...properties,
        [AI_CustomProps.IsGroup]: this.currentAccountData?.isGroup,
        [AI_CustomProps.UserEmail]: this.currentUserData?.emailAddress,
        [AI_CustomProps.UserJoinedDate]: this.fomatDateMMDDYY(
          this.currentUserData?.joinedDate
        ),
        [AI_CustomProps.UserRole]: this.getUserRole(
          this.currentUserData?.userType
        ),
        [AI_CustomProps.UserType]: this.getUserType(
          this.currentUserData?.emailAddress
        ),
      };
    }

    const realWidth = Math.round(window.screen.width * window.devicePixelRatio);
    const realHeight = Math.round(
      window.screen.height * window.devicePixelRatio
    );
    properties = {
      ...properties,
      [AI_CustomProps.EnvironmentName]: window.location.hostname,
      [AI_CustomProps.BrowserResolution]:
        window.innerWidth + ' x ' + window.innerHeight,
      [AI_CustomProps.ScreenResolution]: realWidth + ' x ' + realHeight,
    };

    // Capture device type, Orientation & user agent details
    const deviceInfo = this.deviceService.getDeviceInfo();
    if (deviceInfo) {
      properties = {
        ...properties,
        [AI_CustomProps.DeviceType]: this.getDeviceType(),
        [AI_CustomProps.DeviceOrientation]: CommonUtils.toTitleCase(
          deviceInfo.orientation
        ),
      };
    }
    return properties;
  }

  fomatDateMMDDYY(stringDate: string) {
    if (!stringDate) return '';
    let dt = new Date(stringDate);
    let month = dt.getUTCMonth() + 1; //months from 1-12
    let day = dt.getUTCDate();
    let year = dt.getUTCFullYear();
    return month + '/' + day + '/' + year;
  }

  setAuthenticatedUserId(userId: string): void {
    AppInsights.setAuthenticatedUserContext(userId);
  }

  setUserAndAccountId(userId: string, accountId: string) {
    AppInsights.setAuthenticatedUserContext(userId, accountId, true);
  }

  clearUserId() {
    AppInsights.clearAuthenticatedUserContext();
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private getUserRole(role: any) {
    switch (role) {
      case 'user':
        return AI_UserRole.User.toString();
      case 'systemadmin':
        return AI_UserRole.SystemAdmin.toString();
    }
    return AI_UserRole.Unknown.toString();
  }

  private getUserType(emailAddress: string) {
    if (!emailAddress) return '';

    let userType = emailAddress.split('@').pop();
    if (!userType) return '';

    switch (userType) {
      case 'oiaglobal.com':
        return AI_UserType.OiaGlobal.toString();
      case 'outlook.com':
      case 'gmail.com':
        return AI_UserType.Miscellaneous.toString();
      case 'photon.in':
      case 'photon.com':
        return AI_UserType.Development.toString();
    }
    return AI_UserType.Customers.toString();
  }

  private getDeviceType() {
    //For more detail refer to https://koderlabs.github.io/ngx-device-detector/demo/
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();

    if (isMobile) {
      return 'Mobile';
    }
    if (isTablet) {
      return 'Tablet';
    }

    if (isDesktopDevice) {
      return 'Web';
    }
    return 'Unknown';
  }

  trackAIFileDownloadEvent(
    eventName: any,
    shipmentId: any,
    moduleName: AI_ModulesName,
    tabName: AI_TabsName,
    fileName: any,
    edocType: any
  ) {
    let params = {};
    if (moduleName == AI_ModulesName.Shipment) {
      params = {
        [AI_CustomProps.ShipmentId]: shipmentId,
      };
    } else {
      params = {
        [AI_CustomProps.BookingId]: shipmentId,
      };
    }

    params = {
      ...params,
      [AI_CustomProps.ModuleName]: moduleName,
      [AI_CustomProps.TabName]: tabName,
      [AI_CustomProps.FileName]: fileName,
      [AI_CustomProps.EDocType]: edocType,
    };
    this.logEvent(eventName, params);
  }

  getAIModuleName(moduleType: any) {
    return CommonUtils.toTitleCase(moduleType) == 'Shipments'
      ? AI_ModulesName.Shipment
      : AI_ModulesName.Booking;
  }

  trackAIFullScreenViewedEvent(shipmentId: any, moduleName: AI_ModulesName) {
    let params = {};
    if (moduleName == AI_ModulesName.Shipment)
      params = { [AI_CustomProps.ShipmentId]: shipmentId };
    else params = { [AI_CustomProps.BookingId]: shipmentId };

    this.logEvent(AI_CommonEvents.FullScreenViewed.toString(), {
      ...params,
      [AI_CustomProps.ModuleName]: moduleName,
    });
  }

  trackAIWatchListEvent(
    eventName: any,
    shipmentId: any,
    moduleName: AI_ModulesName
  ) {
    let params = {};
    if (moduleName == AI_ModulesName.Shipment)
      params = { [AI_CustomProps.ShipmentId]: shipmentId };
    else params = { [AI_CustomProps.BookingId]: shipmentId };

    this.logEvent(eventName, {
      ...params,
      [AI_CustomProps.ModuleName]: moduleName,
    });
  }

  trackAIGuidedTour(moduleName: AI_ModulesName) {
    this.logEvent(AI_CommonEvents.GuidedTourViewed.toString(), {
      [AI_CustomProps.ModuleName]: moduleName,
    });
  }

  isValidInstrumentationKey() {
    return this.config.instrumentationKey !== '#{instrumentationKey}#';
  }

  // end of AppinsightsService
}

export enum AI_PageName {
  Login = 'Login Page',
  Register = 'Register Page',
  ThankYouRegistration = 'Thank you registration',
  ActivationPending = 'Activation pending',
  AccountDeactivated = 'Account Deactivated',
  NotFound = 'Not Found',

  Home = 'Home Page',
  Dashboard = 'Dashboard Page',

  Account = 'Account Management Page',
  AccountDetail = 'Account Detail Page',
  User = 'User Management Page',
  UserDetail = 'User Detail Page',
  Edoc = 'Edoc Management Page',
  Broadcast = 'Broadcast Page',

  ShipmentsPublicView = 'Shipments Public View',
  BookingsPublicView = 'Bookings Public View',
  CustomsPublicView = 'Customs Public View',

  Shipment = 'Shipment List Page',
  ShipmentDetail = 'Shipment Detail Page',
  Booking = 'Booking List Page',
  BookingDetail = 'Booking Detail Page',
  ViewAllShipment = 'Global Search Result Page',
  Custom = 'Custom List Page',
  CustomDetail = 'Custom Detail Page',

  MyGroup = 'My Group Page',
  MyProfile = 'My Profile Page',
  NotificationPreference = 'Notification Preference',

  Faq = 'FAQ Page',
  Report = 'Report Page',

  GlobalSearch = 'Global Search',

  ReportManagement = 'Admin Report Management Page Visited',
  ActionFieldManagement = 'Admin Action Field Management Page Visited',
  WorkflowManagement = 'Admin Workflow Management Page Visited',
  DataFieldManagement = 'Admin DataField Management Page Visited',
  TemplateManagement = 'Admin Template Management Page Visited',
}

export enum AI_TemplateManagementEvents {
  Added = 'Template Management New Template Added',
  Updated = 'Template Management Existing Template Updated',
  Deleted = 'Template Management Existing Template Deleted',
  FilterApplied = 'Template Management List Screen Filter Applied'
}

export enum AI_DataFieldManagementEvents {
  Added = 'DataField Management New DataField Added',
  Updated = 'DataField Management Existing DataField Updated',
  Deleted = 'DataField Management Existing DataField Deleted',
  FilterApplied = 'DataField Management List Screen Filter Applied'
}

export enum AI_UserRole {
  User = 'User',
  SystemAdmin = 'System Admin',
  Unknown = 'Unknown',
}

export enum AI_UserType {
  OiaGlobal = 'OIA Global',
  Miscellaneous = 'Miscellaneous',
  Development = 'Development',
  Customers = 'Customers',
}

export enum AI_ProfileEvents {
  UserInvited = 'User Invited',
  UserProfileEdited = 'User Profile Updated',
  UserDefaultAccountOrGroupChanged = 'User Default Account/Group Changed',
  UserProfileImageAdded = 'User Profile Image Added',
  UserProfileImageRemoved = 'User Profile Image Removed',
  UserGroupCreated = 'User Group Created',
  UserGroupModified = 'User Group Modified',
  UserGroupRemoved = 'User Group Removed',
}

export enum AI_AccountEvents {
  AccountActivated = 'Account Activated',
  AccountDeActivated = 'Account Deactivated',
  AccountAliasNameUpdated = 'Account Alias Name Updated',
  UserActive = 'User Active',
  UserInactive = 'User Inactive',
  UserPending = 'User Pending',
  UserAddedIntoAnAccount = 'User Added Into An Account',
  UserRemovedFromAnAccount = 'User Removed From An Account',
  EdocAdded = 'Edoc Added',
  EdocRemoved = 'Edoc Removed',
  EdocPermissionUpdated = 'Edoc Permission Updated',
  SettingUpdated = 'Account Setting',
}

export enum AI_UserEvents {
  UserRoleChanged = 'User Role Changed',
  UserSystemAdminRoleRemoved = 'User System Admin Role Removed',
  UserActivated = 'User Activated',
  UserAccountRemoved = 'User Account Removed',
  UserDefaultAccountMapped = 'User Default Account Mapped',
  UserDeleted = 'User Deleted',
  UserDeactivated = 'User Deactivated',
  UserInactivated = 'User Inactived',
  UserPending = 'User Pending',
  UserDetailUpdated = 'User Detail Updated',
  UserReportModuleSettingUpdated = 'User Report Module Setting Updated',
}

export enum AI_CommonEvents {
  PublicSearch = 'Public Search',
  BroadcastLinkClicked = 'Broadcast URL Clicked',
  BroadcastMessageEdited = 'Broadcast Message Edited',

  ExcelExported = 'Excel Exported',
  DocumentDownload = 'Document Downloaded',
  DocumentPreview = 'Document Previewed',

  ReportDownload = 'Report Download',
  ReportPreview = 'Report Preview',

  DetailTabViewed = 'Detail Tab Viewed',
  UserTabViewed = 'User Tab Viewed',
  EdocsTabViewed = 'Edocs Tab Viewed',
  SettingsTabViewed = 'Settings Tab Viewed',
  CodesTabViewed = 'Codes Tab Viewed',
  TableViewTabViewed = 'TableView Tab Viewed',
  DocumentTabViewed = 'Document Tab Viewed',
  ContainerTabViewed = 'Container Tab Viewed',
  CustomsTabViewed = 'Customs Tab Viewed',
  LogTabViewed = 'Log Tab Viewed',
  ActionTabViewed = 'Action Tab Viewed',
  InvoiceTabViewed = 'Invoice Tab Viewed',
  PGATabViewed = 'PGA Tab Viewed',
  ActionFieldSubmitted = 'Action Field Submitted',

  SharedAll = 'All Data Shared',
  ShareSingleItem = 'Single Data Shared',
  FullScreenViewed = 'Full Screen Viewed',
  WatchListAdded = 'Watch List Added',
  WatchListRemoved = 'Watch List Removed',
  EdocFileUploaded = 'Edoc File Uploaded',

  MySavedFilterAdded = 'My Saved Filter Added',
  SystemDefinedFilterAdded = 'System Defined Filter Added',
  MySavedFilterEdited = 'My Saved Filter Edited',
  MySavedFilterDeleted = 'My Saved Filter Deleted',

  SeachData = 'Data Searched',
  SortData = 'Data Sorted',

  DefaultFilterApplied = 'Default Filter Applied',
  WatchListFilterApplied = 'Watch List Filter Applied',
  WatchListFilterCleared = 'Watch List Filter Cleared',
  StatusFilterApplied = 'Status Filter Applied',
  StatusFilterCleared = 'Status Filter Cleared',
  OrganizationFilterApplied = 'Organization Filter Applied',
  OrganizationFilterCleared = 'Organization Filter Cleared',
  DateRangeFilterApplied = 'Date Range Filter Applied',
  DateRangeFilterItemCleared = 'Date Range Filter Item Cleared',
  DateRangeFilterCleared = 'Date Range Filter Cleared',
  TransportFilterApplied = 'Transport Filter Applied',
  TransportFilterCleared = 'Transport Filter Cleared',
  ExceptionFilterApplied = 'Exception Filter Applied',
  ExceptionFilterCleared = 'Exception Filter Cleared',
  //LocationFilterApplied = 'Location Filter Applied',
  LocationFilterItemApplied = 'Location Filter Item Applied',
  LocationFilterItemRemoved = 'Location Filter Item Removed',
  LocationFilterCleared = 'Location Filter Cleared',
  //ShipperFilterApplied = 'Shipper Filter Applied',
  ShipperFilterItemApplied = 'Shipper Filter Item Applied',
  ShipperFilterItemRemoved = 'Shipper Filter Item Removed',
  ShipperFilterCleared = 'Shipper Filter Cleared',
  //ConsigneeFilterApplied = 'Consignee Filter Applied',
  ConsigneeFilterItemApplied = 'Consignee Filter Item Applied',
  ConsigneeFilterItemRemoved = 'Consignee Filter Item Removed',
  ConsigneeFilterCleared = 'Consignee Filter Cleared',

  JsonFileUpdated = 'Json File Updated',
  JsonFileUpdatedWithDocument = 'Json File Updated With Document',

  ViewShipment = 'View Shipment',
  GuidedTourViewed = 'Guided Tour Viewed',
  FaqLinkViewed = 'FAQ Link Viewed',
  DetailPageRefreshed = 'Detail Page Refreshed',

  //PGAFilterApplied = 'PGA Filter Applied',
  //PGAFilterRemoved = 'PGA Filter Removed',
  //ImporterFilterApplied = 'Importer Filter Applied',
  //ImporterFilterRemoved = 'Importer Filter Removed',
  //SupplierFilterApplied = 'Supplier Filter Applied',
  //SupplierFilterRemoved = 'Supplier Filter Removed',
  AccountFilterApplied = 'Account Filter Applied',
  RoleFilterApplied = 'Role Filter Applied',
  ReportModuleCreated = 'Report Module Created',
  AddNewReport = 'Add New Report',
  RemoveReport = 'Remove Report',

  AccountActionTabSectionNameEdited = 'Account Action Tab SectionName Edited',
  AccountActionTabNewSectionAdded = 'Account Action Tab New Section Added',
  AccountActionTabSectionRemoved = 'Account Action Tab Section Removed',
  AccountActionTabNewActionFieldAdded = 'Account Action Tab New Action Field Added',
  AccountActionTabActionFieldRemoved = 'Account Action Tab Action Field Removed',
  AccountActionWidthChanged = 'Display in FullWidth/Columns changed',
  AccountActionFieldSaved = 'Account Action Field Saved',

  DisplayAllMilestoneSelected = 'Display all milestone selected',
  DisplayAllMilestoneRemoved = 'Display all milestone removed',
  NewMileStoneAdded = 'New Milestone Added',
  MileStoneUpdated = 'Milestone Updated',
  MileStoneDeleted = 'Milestone Deleted',
  NewReferenceAdded = 'New Reference Added',
  ReferenceUpdated = 'Reference Updated',
  ReferenceDeleted = 'Reference Deleted',
  NewCustomFieldAdded = 'New CustomField Added',
  CustomFieldUpdated = 'CustomField Updated',
  CustomFieldDeleted = 'CustomField Deleted',

  AccountTableViewSettingSaved = 'Table View Account Setting Saved'


}

export enum AI_CustomProps {
  AccountName = 'Account Name',
  OrganizationCode = 'Organization Code',
  IsGroup = 'Is Group',
  UserEmail = 'User Email',
  UserJoinedDate = 'User Joined Date',
  UserType = 'User Type',
  EnvironmentName = 'Environment Name',
  BrowserResolution = 'Browser Resolution',
  ScreenResolution = 'Screen Resolution',
  DeviceType = 'Device Type',
  DeviceOrientation = 'Device Orientation',
  EncryptedId = 'Encrypted Id',

  OrgCode = 'Org Code',
  AccountAliasName = 'Account Alias Name',
  UserAccountStatus = 'User Account Status',

  EmailId = 'Email Id',
  AddedOrgCode = 'Added Org Code',
  AddedEmailId = 'Added Email Id',
  RemovedOrgCode = 'Removed Org Code',
  RemovedEmailId = 'Removed Email Id',

  EdocTypeId = 'Edoc Type Id',
  HasViewPermission = 'Has View Permission',
  HasUploadPermission = 'Has Upload Permission',
  AccountStatus = 'Account Status',
  AccountActivatedBy = 'Account Activated By',
  UserRole = 'User Role',
  IsDefaultAccount = 'Is Default Account',

  FirstName = 'First Name',
  LastName = 'Last Name',
  Address = 'Address',
  WorkPhone = 'Work Phone',
  Mobile = 'Mobile',
  OIAContactName = 'OIA Contact Name',
  OIAContactEmail = 'OIA Contact Email',
  IsUserReportModuleEnabled = 'IsUserReportModuleEnabled',

  SortBy = 'Sort By',
  SortOrder = 'Sort Order',

  IsWatchList = 'Is Watch List',
  SearchText = 'Search Text',
  FilterName = 'Filter Name',
  StatusTypes = 'Status Types',
  BookingId = 'Booking Id',

  ReportId = 'Report Id',
  ReportName = 'Report Name',
  WorkspaceId = 'Workspace Id',
  ReportUrl = 'Report URL',

  FileName = 'File Name',
  FileSize = 'File Size',
  FileType = 'File Type',
  EDocType = 'EDoc Type',
  DateType = 'Date Type',
  DateRange = 'Date Range',

  TransportTypes = 'Transport Types',
  ExceptionTypes = 'Exception Types',

  Name = 'Name',
  TargetPartyId = 'Target Party Id',
  ShipmentId = 'Shipment Id',
  ModuleName = 'Module Name',
  TabName = 'Tab Name',
  SearchResult = 'Search Result',

  UserGroupName = 'User Group Name',
  GroupCreatedBy = 'Group Created By',
  GroupOrganizationCode = 'Group Organization Code',

  IsShipment = 'Is Shipment',
  IsBooking = 'Is Booking',
  IsCustom = 'Is Custom',

  LocationType = 'Location Type',
  PortName = 'Port Name',
  UserInvitedList = 'User Invited List',

  BroadcastMessage = 'Broadcast Message',
  BroadcastMessageStartDateTime = 'Broadcast Message Start Date Time',
  BroadcastMessageEndDateTime = 'Broadcast Message End Date Time',
  BroadcastMessageLinkText = 'Broadcast Message Link Text',
  BroadcastMessageLinkURL = 'Broadcast Message Link URL',
  BroadcastMessageDisplayColor = 'Broadcast Message Display Color',
  BroadcastMessageDisplayFont = 'Broadcast Message Display Font',

  FaqQuestionNumber = 'FAQ Question Number',
  FaqUniqueKey = 'FAQ Unique Key',
  FaqUrlVisted = 'FAQ URL Visited',

  ShareData = 'Share Data',
  StatusName = 'Status Name',
  RolesName = 'Role Name',
}

export enum AI_ModulesName {
  Shipment = 'Shipment',
  Booking = 'Booking',
  Custom = 'Custom',
}

export enum AI_TabsName {
  DetailTab = 'Detail',
  DocumentTab = 'Document',
  ContainerTab = 'Container',
  CustomTab = 'Custom',
  LogTab = 'Log',
  InvoiceTab = 'Invoice',
}

export enum AI_ActionName {
  FileDownload = 'Download',
  FilePreview = 'Preview',
}
