import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Account } from '../model/account.model';
import { environment } from 'src/environments/environment';
@Injectable()
export class AccountServies {
  constructor(private http: HttpClient) { }

  public stopRequest: Subject<void> = new Subject<void>();

  getData(url: any): Observable<any> {
    return this.http.get<Account[]>(url).pipe(catchError(this.handelError));
  }

  getAllAccounts(
    offset: any,
    recordList: any,
    filterParams: any
  ): Observable<any> {
    let parameters = [
      {
        name: 'accountType',
        value: 'ParentAccount',
      },
      {
        name: 'parentAccountOhCode',
        value: '',
      },
    ];
    let sortParameter = [
      {
        column_name: 'aliasName',
        direction: 'asc',
      },
    ];
    let searchText = '';
    if (filterParams.autocompleteRecord) {
      parameters = [
        {
          name: 'accountType',
          value: '',
        },
        {
          name: 'parentAccountOhCode',
          value: filterParams.autocompleteRecord,
        },
      ];
    } else if (filterParams.autocompleteButton) {
      parameters = [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ];
      searchText = filterParams.autocompleteButton;
    }
    if (filterParams.sortFilter.length > 0) {
      sortParameter = filterParams.sortFilter;
    }
    let request = {
      offset: offset,
      fetch: recordList,
      search_text: searchText,
      parameters: parameters,
      sort_options: sortParameter,
      filter_options: filterParams.searchfilters,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getChildAccountData(parentAccountOhCode: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: 'ChildAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: parentAccountOhCode,
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: null,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getAccountDetails(): Observable<any> {
    return this.http.get<Account[]>('url').pipe(catchError(this.handelError));
  }
  ///v1/desktop / ActionField / GetActionFieldsByAccount
  getActionFieldsByAccount(searchText: any, account: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 20,
      search_text: searchText,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'account',
          value: account,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url +
        'ActionField/GetActionFieldsByAccount',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getAccountAutoComplete(val: any, statusFilter: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 50,
      search_text: val,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: statusFilter,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getAutoCompleteFilterdRecord(filter: any) {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: '',
        },
        {
          name: 'parentAccountOhCode',
          value: filter,
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: null,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  saveAliasName(value: any, ohCode: any) {
    let request = {
      oH_Code: ohCode,
      aliasName: value,
    };
    let url = 'account/updatealaisname';
    return this.http
      .post(environment.base_api_desktop_url + url.trim(), request)
      .pipe(catchError(this.handelError));
  }

  getSectionDetailsByOrgCode(ohCode: any) {
    let request = {};
    let url = 'ActionField/GetSectionDetailsByOrgCode?orgCode=' + ohCode;
    return this.http
      .post(environment.base_api_desktop_url + url.trim(), request)
      .pipe(catchError(this.handelError));
  }

  getAutoCompleteSearchButtonRecords(val: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: val,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: null,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getSingleAccountData(val: any) {
    this.getAutoCompleteFilterdRecord(val);
  }

  getAccountDetailHeader(): Observable<any> {
    return this.http
      .get<Account[]>(
        environment.base_api_desktop_url +
        'useraccount?userEmailAddresss=ramasubramani.t@photon.in'
      )
      .pipe(catchError(this.handelError));
  }

  getStatusFilterResult(filter: any) {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: filter,
    };

    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getSortedRecords(val: any) {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: val,
      filter_options: null,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  activateAccount(param: any) {
    return this.http
      .post(environment.base_api_desktop_url + 'account/activate', param)
      .pipe(catchError(this.handelError));
  }

  deactivateAccount(param: any) {
    return this.http
      .post(environment.base_api_desktop_url + 'account/deactivate', param)
      .pipe(catchError(this.handelError));
  }

  shipmentFilterUpdate(param: any) {
    return this.http
      .post(environment.base_api_desktop_url + 'account/update', param)
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }
}
