<div class="container-fluid">
  <app-progressbar *ngIf="isProgressShow"></app-progressbar>
  <div *ngIf="!dashboardShipment && !dashboardWatchlist" class="row">
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="bookings-total" [ngClass]="{ 'guided-back-drop': isToggleShow }">
        <span class="number-set">{{ totalBookingCount }}</span>&nbsp;Bookings
      </h3>
    </div>
  </div>
  <div class="row" class="no-shipment-wrap" *ngIf="!globalSearchPopup">
    <div *ngIf="
        bookingMasterData.length <= 0 && !dashboardWatchlist && !isProgressShow
      " class="text-center mt-5 pt-5 mobile-set-center dashboardShipmentWrapper">
      <p class="title-set mt-5 pt-5 new-set">No Bookings Found</p>
      <span class="detail-set">We found no bookings that match filter criteria.</span>
    </div>
    <div *ngIf="bookingMasterData.length <= 0 && isProgressShow" class="text-center mt-5 pt-5">
      <p class="title-set mt-5">Loading Bookings</p>
    </div>
    <div *ngIf="
        bookingMasterData.length <= 0 && dashboardWatchlist && !isProgressShow
      " class="text-center mt-5 pt-5 height-set move-top mobile-set-center">
      <p class="mt-5 pt-5 title-set">Start building your booking watchlist!</p>
      <p class="detail-set">
        To keep an eye on high-priority bookings, add a booking to the watchlist
        using the action menu on the far right of the booking or from the
        booking details screen.
      </p>
    </div>
  </div>
  <div [ngClass]="{'guided-back-drop' : isBookingSecHide,'guided-visible-sec':isBookingSecShow}"
    class="infiniteScroll booking-list-ctr" infiniteScroll [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="50"
    [fromRoot]="true" [scrollWindow]="false" id="shipment-sec-id" (scrolled)="onScroll($event)"
    [infiniteScrollDisabled]="isInfiniteDisabled" (scroll)="scrollHandler($event)">
    <div class="shiping-list-card row" id="row-{{ data.forwarder_reference }}" *ngFor="
        let data of bookingMasterData;
        trackBy: trackById;
        let i = index;
        let first = first;
        let last = last
      " [ngClass]="{
        first: first,
        last: last,
        'admin-section': sessionUserRole == 'systemadmin && last',
        'canceled-context-menu': data.status == 'Canceled',
        'admin-context-menu': sessionUserRole == 'systemadmin',
        'user-context-menu': sessionUserRole == 'user'
      }">
      <div id="{{ data.forwarder_reference }}" class="rap-sec" (click)="selectedListBooking(data.forwarder_reference)">
      </div>
      <div class="col-8 col-lg-3 col-xs-7 p-0 bashboard-icon">
        <div class="status-img">
          <!-- Status icon render with exception color start-->
          <app-status-icon [transport_mode]="data?.transport_mode" [status]="data?.status" [exception]="data?.exception"
            [module]="'bookings'"></app-status-icon>
          <!-- Status icon render with exception color end-->

          <div class="status-contant">
            <div class="single-row">
              <span class="shipment-no" [innerHTML]="data.forwarder_reference | highlight : searchText"></span>
              <span *ngIf="!dashboardShipment && !dashboardWatchlist" class="shipment-ref-no">
                <span class="text-truncate-ref" *ngIf="data.purchase_orders?.length > 0" [innerHTML]="
                    data.purchase_orders
                      | bookingReferenceNumber : 30
                      | highlight : searchText
                  ">
                </span>
              </span>
              <span *ngIf="
                  data.favoriteflag && !dashboardShipment && !dashboardWatchlist
                ">
                <img class="view-eye" src="../../../../../assets/img/shipments/eye-list.svg" alt="" />
              </span>
            </div>
            <span *ngIf="!dashboardShipment" class="d-block shipment-pickup-txt" [innerHTML]="
                data.shipper?.name
                  | convertToTitlecase : data.booking_reference
                  | highlight : searchText
              ">
            </span>
            <span *ngIf="dashboardShipment" class="d-block shipment-pickup-txt">
              <span *ngIf="data?.ship_from" [innerHTML]="
                  data.ship_from?.city
                    | trimmedAddressData
                      : data.ship_from?.state
                      : data.ship_from?.country
                    | highlight : searchText
                "></span><span *ngIf="data?.ship_from && data?.ship_to">&nbsp;-&nbsp;</span><span *ngIf="data?.ship_to"
                [innerHTML]="
                  data.ship_to?.city
                    | trimmedAddressData
                      : data.ship_to?.state
                      : data.ship_to?.country
                    | highlight : searchText
                "></span>
            </span>
          </div>
        </div>
      </div>

      <div class="col col-lg-2 col-xs-5 text-set-right dashboard-state">
        <div class="status-button">
          <button class="btn btn-primary booking-btn" class="{{ data.status | exceptionClass : data.exception:'bookings' }} {{
              data.status | titlecase
            }}">
            {{ data.status | titlecase }}
          </button>
        </div>
      </div>

      <div
        class="col-12 col-lg-5 col-xs-12 d-flex align-items-start center-progress pr-0 booking-road-map booking-list-icns">
        <div class="road-maps move-set-right">
          <ul class="timeline">
            <li>
              <div class="rap">
                <h6 class="title" [innerHTML]="
                    data.ship_from?.city
                      | trimmedAddressData
                        : data.ship_from?.state
                        : data.ship_from?.country
                      | highlight : searchText
                  "></h6>
                <p class="country" [innerHTML]="
                    'Est Pickup'
                      | statusData : data.origin_pickup_estimated
                      | highlight : searchText
                  "></p>
              </div>
            </li>
            <li>
              <div class="rap">
                <h6 class="title" [innerHTML]="
                    data.origin_port?.portName
                      | trimmedAddressData
                        : data.origin_port?.stateName
                        : data.origin_port?.countryCode
                      | highlight : searchText
                  "></h6>
                <p class="country" [innerHTML]="
                    'ETD'
                      | statusData : data.departure_estimated
                      | highlight : searchText
                  "></p>
              </div>
            </li>
            <li>
              <div class="rap">
                <h6 class="title" [innerHTML]="
                    data.final_destination_port?.portName
                      | trimmedAddressData
                        : data.final_destination_port?.stateName
                        : data.final_destination_port?.countryCode
                      | highlight : searchText
                  "></h6>
                <p class="country" [innerHTML]="
                    'ETA'
                      | statusData : data.arrival_port_estimated
                      | highlight : searchText
                  "></p>
              </div>
            </li>
            <li>
              <div class="rap">
                <h6 class="title" [innerHTML]="
                    data.ship_to?.city
                      | trimmedAddressData
                        : data.ship_to?.state
                        : data.ship_to?.country
                      | highlight : searchText
                  "></h6>
                <p class="country" [innerHTML]="
                    'Est Delivery'
                      | statusData : data.delivery_estimated
                      | highlight : searchText
                  "></p>
              </div>
            </li>
          </ul>
        </div>
        <div class="progress-icon-ctr">
          <span class="progress-icons">
            <img src="assets/img/document.svg" alt="document" (click)="navigateToDocumentTab(data)" />
            <div class="action-field-count">
              {{ data?.documentCount ? data.documentCount : 0 }}
            </div>
            <div class="doc-common-hover">
              <span>Document</span>
            </div>
          </span>
          <span class="progress-icons" *ngIf="hasActionField"
            (click)="selectedListBooking(data.forwarder_reference, true)">
            <img src="assets/img/action-field-icon.svg" alt="action field" />
            <div class="action-field-count">{{ data?.action_fields ? data.action_fields.length : 0 }}
              <div class="doc-common-hover action-fld-ctrl">
                <span>Action Field</span>
              </div>
            </div>
          </span>
          <span class="progress-icons" (click)="share($event, data.forwarder_reference, 'Detail', data)">
            <img src="assets/img/share.svg" alt="" />
            <div class="doc-common-hover shre-ctrl">
              <span>Share</span>
            </div>
          </span>
        </div>
      </div>

      <div class="col-12 col-lg-2 col-xs-12 text-center remove-global-search pl-0 pr-0">
        <button class="view-shipment" *ngIf="data.status == 'Converted'"
          (click)="viewShipment(data?.forwarder_reference)">
          View shipment
        </button>
        <div class="dropdown-section">
          <div class="dropdown">
            <button type="button" class="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <img src="../../../../../../assets/img/three-dots.png" alt="viewshipments" />
            </button>
            <div class="dropdown-menu active-menu-bg" aria-labelledby="dropdownMenuButton" style="left: -70px">
              <a *ngIf="!data.favoriteflag" class="dropdown-item"
                (click)="addWatchList('Add', data?.forwarder_reference)">Watchlist</a>
              <a *ngIf="data.favoriteflag" class="dropdown-item" class="{{
                  data.favoriteflag ? 'checkedClass' : 'uncheckedClass'
                }}" (click)="addWatchList('Remove', data?.forwarder_reference)"><img class="image-okay"
                  src="../../assets/img/active-data.svg" alt="" />Watchlist</a>

              <a class="dropdown-item" *ngIf="data.status == 'Converted'"
                (click)="viewShipment(data?.forwarder_reference)">View Shipment</a>
              <a class="dropdown-item" *ngIf="dashboardShipment" (click)="
                  share($event, data.forwarder_reference, 'Detail', data)
                ">Share</a>
              <a class="dropdown-item" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithoutDocuments &&
                  !calculateDiff(
                    data.reImportRequestCompletedWithoutDocuments
                  ) &&
                  data.status != 'Canceled' &&
                  data.status != 'Converted'
                " (click)="updateBookings(data.forwarder_reference, false)">Update Booking</a>
              <a class="dropdown-item updating-shipment" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  data.reImportRequestPendingWithoutDocuments &&
                  data.status != 'Canceled' &&
                  data.status != 'Converted'
                ">
                <div class="refresh-icon"></div>
                <span class="updating-text">Updating Booking</span>
              </a>
              <a class="dropdown-item checkedClass" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithoutDocuments &&
                  calculateDiff(
                    data.reImportRequestCompletedWithoutDocuments
                  ) &&
                  data.status != 'Canceled' &&
                  data.status != 'Converted'
                " (click)="updateBookings(data.forwarder_reference, false)">
                <img class="image-okay" src="../../assets/img/active-data.svg" alt="" />Updated Booking
              </a>

              <a class="dropdown-item" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithDocuments &&
                  !calculateDiff(data.reImportRequestCompletedWithDocuments) &&
                  data.status != 'Canceled' &&
                  data.status != 'Converted'
                " (click)="updateBookings(data.forwarder_reference, true)">Update with Documents
              </a>
              <a class="dropdown-item updating-shipment" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  data.reImportRequestPendingWithDocuments &&
                  data.status != 'Canceled' &&
                  data.status != 'Converted'
                ">
                <div class="refresh-icon"></div>
                <span class="updating-text"> Updating with Documents</span>
              </a>
              <a class="dropdown-item checkedClass" *ngIf="
                  sessionUserRole == 'systemadmin' &&
                  !data.reImportRequestPendingWithDocuments &&
                  calculateDiff(data.reImportRequestCompletedWithDocuments) &&
                  data.status != 'Canceled' &&
                  data.status != 'Converted'
                " (click)="updateBookings(data.forwarder_reference, true)"><img class="image-okay"
                  src="../../assets/img/active-data.svg" alt="" />Updated with Documents</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="align-items-center justify-content-xl-end shipment-list-filter">
  <div id="bookingSharePopup" *ngIf="bookingSharePopup" class="dimScreen">
    <div *ngIf="bookingSharePopup" class="add-user-popup">
      <button class="close" type="button" (click)="closeUserFilterPopUp()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
      </button>
      <div class="body-section">
        <h3>Share <span>Booking</span></h3>
        <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" id="tabDetail">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../../../../../assets/img/share/url-img.svg" alt="" />
              URL
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div class="discription">
              <p class="text-des">
                For users with a Connect account and access to this company,
                they will be able to see the full booking details. Otherwise,
                they will be able to see a public URL with only booking details.
              </p>
              <div class="copy-section">
                <input type="text" id="shareUrl" [value]="urlShare" readonly />
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-booking-details [urlBookingId]="shareBookingIdToChild" [popupToggle]="popupToggle"
  [selectedBooking]="getBookingDetails" (detailPopClose)="closeTabModel()" (prepareUrlShare)="detailShare($event)"
  (onDetailPopupCloseHandler)="onDetailPopupCloseHandler($event)" (onRefreshDetails)="onRefreshDetailFromDetail($event)"
  [urlShare]="urlShare" [ngClass]="{ 'guided-visible-sec': isGuidedTourBooking }"
  (loadBookingList)="loadBookingListById($event)">
</app-booking-details>
<div *ngIf="edocUploadTigger" id="edoc-upload-popup" class="dimScreen1">
  <div class="cancel-edocument">
    <button (click)="hideEdocUploadPopup()" class="close" type="button">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="" />
    </button>
    <div class="body-section fix-size">
      <h3>Are you sure you want to exit?</h3>
      <div class="discription">
        Documents are in the process of being uploaded. Exiting may result in
        unsaved changes. Are you sure you want to exit application?
      </div>
    </div>
    <div class="button-setup text-right">
      <button (click)="exitEdocPopup()" class="edit-permissons mr-2">
        Exit page
      </button>
      <button (click)="hideEdocUploadPopup()" class="add-type">
        Stay on page
      </button>
    </div>
  </div>
</div>

<!-- Action Field changes modal start -->
<div tabindex="-1" role="dialog" [hidden]="!showActionFieldCancelModal" id="changes-action-popup"
  data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true"
  style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="removed-action-popup move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" />
          </span>
          <button type="button" class="close delete-close-icon"
            (click)="showActionFieldCancelModal = !showActionFieldCancelModal">
            <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Changes Will Not Be Saved</h3>
            <div class="discription">Are you sure you want to proceed?</div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="
                  showActionFieldCancelModal = !showActionFieldCancelModal
                ">
                GO BACK
              </button>
              <button class="add-type" (click)="switchTab()">PROCEED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Action Field Changes modal end -->