import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import { CustomsUtils } from './customs-utils';

@Pipe({
  name: 'customDetailProgressBar',
})
export class CustomDetailProgressBarPipe implements PipeTransform {
  transform(customData: any, ...args: any[]): any {
    if (!customData) {
      return '';
    }

    let htmlString = '';
    let table: any[] = [];
    // Testing data
    // customData.loading_actual = '2022-11-24T08:44:27Z';
    // customData.customs_declarations[0].filed_date = '';
    // customData.customs_declarations[0].import_date = '';
    // customData.customs_declarations[0].declaration_date = '';
    // customData.customs_declarations[0].declaration_date_estimated = '';
    // customData.customs_declarations[0].release_date = '';
    // customData.delivery_actual = '';
    // customData.delivery_estimated = '';

    if (CustomsUtils.isCustomTypeExport(customData)) {
      table.push(this.getCustomsClearedData(customData));
      table.push(this.getPortOfExportData(customData));
      table.push(this.getLoadingPortData(customData));
      table.push(this.getDischargePortData(customData));
      table.push(this.getDeliverToData(customData));
    } else {
      table.push(this.getLoadingPortData(customData));
      table.push(this.getEntryPreparedData(customData));
      table.push(this.getEntryFiledData(customData));
      table.push(this.getDischargePortData(customData));
      table.push(this.getEntryPortData(customData));
      table.push(this.getEntryReleasedData(customData));
      table.push(this.getDeliverToData(customData));
    }
    table = this.fillProgressBarColorNew(table);
    htmlString += this.generateProgressbarNew(table);

    return htmlString;
  }

  private getCustomsClearedData(data: any) {
    return {
      title: '',
      subTitle: 'Customs Cleared',
      address: '',
      firstPairKey: 'Cleared Date:',
      firstPairValue: this.getDateFormatted(
        data?.customs_declarations[0]?.release_date
      ),
      secondPairKey: '',
      secondPairValue: '',
      firstDate: data?.customs_declarations[0]?.release_date,
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(
        data?.customs_declarations[0]?.release_date
      ),
      temp: '',
      fillPercentage: '',
    };
  }

  private getPortOfExportData(data: any) {
    return {
      title: 'Port of Export',
      subTitle: this.getEntryPortExportDetail(
        data.customs_declarations[0]?.port_of_clearance_description,
        data.customs_declarations[0]?.export_country_code
      ),
      address: '',
      firstPairKey: 'Departure Date:',
      firstPairValue: this.getDateFormatted(data?.loading_actual),
      secondPairKey: '',
      secondPairValue: '',
      firstDate: data?.loading_actual,
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(data?.loading_actual),
      temp: '',
      fillPercentage: '',
    };
  }

  private getLoadingPortData(data: any) {
    return {
      title: 'Loading Port',
      subTitle: this.getCityStateCountryFromPort(data?.lading_port),
      address: '',
      firstPairKey: 'Departure Date:',
      firstPairValue: this.getDateFormatted(data?.loading_actual),
      secondPairKey: 'Vessel/Voyage:',
      secondPairValue: this.getVesselVoyageText(
        data?.voyage_number,
        data?.vessel_name
      ),
      firstDate: data?.loading_actual,
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(data?.loading_actual),
      temp: '',
      fillPercentage: '',
    };
  }

  private getEntryPreparedData(data: any) {
    return {
      title: '',
      subTitle: 'Entry Prepared',
      address: '',
      firstPairKey: '',
      firstPairValue: '',
      secondPairKey: '',
      secondPairValue: '',
      firstDate: '',
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(''),
      temp: '',
      fillPercentage: '',
    };
  }

  private getEntryFiledData(data: any) {
    return {
      title: '',
      subTitle: 'Entry Filed',
      address: '',
      firstPairKey: 'Filed Date:',
      firstPairValue: this.getDateFormatted(
        data?.customs_declarations[0]?.filed_date
      ),
      secondPairKey: '',
      secondPairValue: '',
      firstDate: data?.customs_declarations[0]?.filed_date,
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(
        data?.customs_declarations[0]?.filed_date
      ),
      temp: '',
      fillPercentage: '',
    };
  }

  private getEntryPortData(data: any) {
    return {
      title: '',
      subTitle: 'Entry Port',
      address: this.getEntryPortAddress(data),
      firstPairKey: 'Arrival Date:',
      firstPairValue: this.getDateFormatted(
        data?.customs_declarations[0]?.declaration_date
      ),
      secondPairKey: 'Estimated Entry Date:',
      secondPairValue: this.getDateFormatted(
        data?.customs_declarations[0]?.declaration_date_estimated
      ),
      firstDate: data?.customs_declarations[0]?.declaration_date,
      secondDate: data?.customs_declarations[0]?.declaration_date_estimated,
      actualFillPercentage: this.getActualFillPercentage(
        data?.customs_declarations[0]?.declaration_date
      ),
      temp: '',
      fillPercentage: '',
    };
  }

  private getEntryReleasedData(data: any) {
    return {
      title: '',
      subTitle: 'Entry Released',
      address: '',
      firstPairKey: 'Released Date:',
      firstPairValue: this.getDateFormatted(
        data?.customs_declarations[0]?.release_date
      ),
      secondPairKey: '',
      secondPairValue: '',
      firstDate: data?.customs_declarations[0]?.release_date,
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(
        data?.customs_declarations[0]?.release_date
      ),
      temp: '',
      fillPercentage: '',
    };
  }

  private getDischargePortData(data: any) {
    return {
      title: 'Discharge Port',
      subTitle: this.getCityStateCountryFromPort(data?.arrival_port),
      address: '',
      firstPairKey: 'Arrival Date:',
      firstPairValue: this.getDateFormatted(
        data?.customs_declarations[0]?.import_date
      ),
      secondPairKey: '',
      secondPairValue: '',
      firstDate: data?.customs_declarations[0]?.import_date,
      secondDate: '',
      actualFillPercentage: this.getActualFillPercentage(
        data?.customs_declarations[0]?.import_date
      ),
      temp: '',
      fillPercentage: '',
    };
  }

  private getDeliverToData(data: any) {
    return {
      title: 'Deliver To',
      subTitle: this.getCityStateCountryFromPort(data?.ship_to),
      address: this.getShipToDetails(data),
      firstPairKey: 'Estimated Delivery:',
      firstPairValue: this.getDateFormatted(data?.delivery_estimated),
      secondPairKey: 'Actual Delivery:',
      secondPairValue: this.getDateFormatted(data?.delivery_actual),
      firstDate: data?.delivery_actual, //To validate, swapped it
      secondDate: data?.delivery_estimated, //To validate, swapped it
      // actualFillPercentage: data?.delivery_actual
      //   ? this.getActualFillPercentage(data?.delivery_actual)
      //   : this.getActualFillPercentage(data?.delivery_estimated),
      actualFillPercentage: this.getActualFillPercentage(data?.delivery_actual),
      temp: '',
      fillPercentage: '',
    };
  }

  private getShipToDetails(data: any) {
    let message = '';
    let publicViewClass = data?.isFullViewAccess ? '' : 'd-none';
    if (data?.ship_to) {
      message += '<p class="address-sec ' + publicViewClass + '">';
      message += data.ship_to.name ? data.ship_to.name + ',  ' : '';
      message += '</p><p class="address-sec ' + publicViewClass + '">';
      message += data.ship_to.address_1
        ? CommonUtils.toTitleCase(data.ship_to.address_1) + ', '
        : '';
      message += data.ship_to.address_2
        ? CommonUtils.toTitleCase(data.ship_to.address_2) + ', '
        : '';
      message += data.ship_to.city
        ? CommonUtils.toTitleCase(data.ship_to.city) + ', '
        : '';
      message += CommonUtils.getUSCanadaStateText(
        data?.ship_to?.country_name,
        data?.ship_to?.state
      );
      message += data.ship_to.country ? data.ship_to.country + ', ' : '';
      message += data.ship_to.postal_code ? data.ship_to.postal_code : '';
      message = CommonUtils.removeLastComma(message);
      message += '</p>';
    }
    return message;
  }

  private getVesselVoyageText(voyage_number: any, vessel_name: any) {
    let htmlString = '---';
    if (!voyage_number && !vessel_name) return htmlString;

    if (voyage_number && vessel_name) {
      htmlString = vessel_name + ' / ' + voyage_number;
    } else if (vessel_name) {
      htmlString = vessel_name;
    } else {
      htmlString = voyage_number;
    }
    return htmlString;
  }

  private getTitleCase(text: string) {
    return CommonUtils.toTitleCase(text);
  }

  private getEntryPortExportDetail(
    port_of_clearance_description: any,
    country_code: any
  ) {
    let htmlString = '';
    if (!port_of_clearance_description && !country_code) return htmlString;

    if (port_of_clearance_description) {
      htmlString += this.getTitleCase(port_of_clearance_description);
    }

    if (port_of_clearance_description && country_code) {
      htmlString += ', ';
    }

    if (country_code) {
      htmlString += this.getTitleCase(country_code);
    }
    return htmlString;
  }

  private getDateFormatted(date: any) {
    return CommonUtils.getYearFormattedDate(date);
  }

  private getCityStateCountryFromPort(port: any) {
    let htmlString = '';
    if (!port) return htmlString;

    if (port.portName) {
      htmlString += this.getTitleCase(port.portName);
    }

    if (port.portName && port.country_name) {
      htmlString += ', ';
    }

    // Add state code when US or Canada
    if (
      port.stateCode &&
      port.country_name &&
      (port.country_name.toLowerCase() == 'united states' ||
        port.country_name.toLowerCase() == 'canada')
    ) {
      htmlString += port.stateCode + ', ';
    }
    if (port.country_name) {
      htmlString += this.getTitleCase(port.country_name);
    }
    return htmlString;
  }

  private generateProgressbar(table: any): string {
    let htmlString = '';
    let length = table.length;
    if (length <= 0) return htmlString;
    let hasEightyPercent = false;
    for (let i = 0; i < length; i++) {
      let liCssClass = 'progtrckr-done blue end-point hundredpercent ';
      if (table[i].fillPercentage == '80') {
        liCssClass =
          i == length - 1
            ? 'progtrckr-todo blue eightypercent '
            : 'progtrckr-done blue eightypercent ';
        hasEightyPercent = true;
      } else if (table[i].fillPercentage == '0') {
        liCssClass = 'progtrckr-todo zeropercent ';
      }
      if (hasEightyPercent) {
        liCssClass += 'no-circle ';
      }
      htmlString += this.generateDisplayLiItem(table, i, liCssClass);
    }
    return '<ul class="timeline">' + htmlString + '</ul>';
  }

  private getKeyValuePairRenderText(table: any, i: number) {
    let htmlString = '';
    if (
      (table[i].firstPairKey && table[i].firstPairValue) ||
      (table[i].secondPairKey && table[i].secondPairValue)
    ) {
      htmlString += '<div class="update-change">';
      if (table[i].firstPairKey && table[i].firstPairValue) {
        htmlString += '<span class="display-sec">';
        htmlString +=
          '<h6 class="d-inline-block pr-1">' + table[i].firstPairKey + '</h6>';
        htmlString += table[i].firstPairValue;
        htmlString += '</span>';
      }
      if (table[i].secondPairKey && table[i].secondPairValue) {
        htmlString += '<span class="display-sec">';
        htmlString +=
          '<h6 class="d-inline-block pr-1">' + table[i].secondPairKey + '</h6>';
        htmlString += table[i].secondPairValue;
        htmlString += '</span>';
      }
      htmlString += '</div>';
    }
    return htmlString;
  }

  private fillProgressBarColor(table: any): any {
    for (let i = table.length - 1; i >= 0; i--) {
      let isFilled = this.fillPercentageBasedOnDateValue(table, i);
      if (isFilled) {
        this.fillUpperLowerTablePercentage(table, i);
        break;
      }
    }
    return table;
  }

  private fillPercentageBasedOnDateValue(table: any, i: number): boolean {
    let isFilled = false;
    if (!table[i].firstDate && !table[i].secondDate) {
      table[i].fillPercentage = '0';
      table[i].actualFillPercentage = '0';
      isFilled = false;
    } else if (!table[i].firstDate) {
      table[i].fillPercentage = '0';
      table[i].actualFillPercentage = '0';
      isFilled = false;
    }
    //else if (
    //   (table[i].firstDate && CommonUtils.isFutureDate(table[i].firstDate)) ||
    //   (table[i].secondDate && CommonUtils.isFutureDate(table[i].secondDate))
    // )
    else if (
      table[i].firstDate &&
      CommonUtils.isFutureDate(table[i].firstDate)
    ) {
      table[i].fillPercentage = '80';
      table[i].actualFillPercentage = '80';
      isFilled = true;
    } else {
      table[i].fillPercentage = '100';
      table[i].actualFillPercentage = '100';
      isFilled = true;
    }
    return isFilled;
  }

  private fillUpperLowerTablePercentage(table: any, i: number) {
    // make 0 for all upper values
    if (i <= table.length - 1) {
      for (let j = i + 1; j <= table.length - 1; j++) {
        table[j].fillPercentage = '0';
      }
    }
    // make 100 for all lower values
    for (let k = 0; k < i; k++) {
      table[k].fillPercentage = '100';
    }
  }

  private generateDisplayLiItem(
    table: any,
    i: number,
    liCssClass: string
  ): string {
    let htmlString = '<li class="' + liCssClass + '"><div class="rap">';
    if (table[i].title) {
      htmlString += '<h6 class="title">' + table[i].title + '</h6>';
    }
    if (table[i].subTitle) {
      htmlString += '<p class="country">' + table[i].subTitle + '</p>';
    }
    if (table[i].address) {
      htmlString +=
        '<ng-container><p>' + table[i].address + '</p></ng-container>';
    }
    htmlString += this.getKeyValuePairRenderText(table, i);

    htmlString +=
      '</div><div class="dynamic-progress progtrckr-done blue end-point"></div></li>';
    return htmlString;
  }

  private getEntryPortAddress(data: any) {
    let message = '';
    if (!CustomsUtils.isCustomTypeExport(data)) {
      message += data?.customs_declarations[0]?.port_of_clearance_description
        ? CommonUtils.removeLastComma(
            CustomsUtils.convertCityStateAsUpperCase(
              data?.customs_declarations[0]?.port_of_clearance_description,
              ''
            )
          )
        : '';
      message +=
        data?.customs_declarations[0]?.port_of_clearance_description &&
        data?.customs_declarations[0]?.import_country_code
          ? ', '
          : '';
      message += data?.customs_declarations[0]?.import_country_code
        ? data?.customs_declarations[0]?.import_country_code
        : '';
    }
    return CommonUtils.removeLastComma(message);
  }

  private getActualFillPercentage(date: any) {
    if (!date || date.length <= 10) return '0';
    if (CommonUtils.isFutureDate(date)) return '80';
    return '100';
  }

  private fillProgressBarColorNew(table: any): any {
    let isCompleted = false;
    // Use temp and change the way of progress bar display
    for (let i = table.length - 1; i >= 0; i--) {
      table[i].temp = table[i].actualFillPercentage;
    }
    for (let i = table.length - 1; i >= 0; i--) {
      if (isCompleted) {
        break;
      }
      if (!isCompleted && table[i].temp == '100') {
        for (let j = i; j >= 0; j--) {
          table[j].fillPercentage = '100';
        }
        isCompleted = true;
      }

      if (!isCompleted && table[i].temp == '0') {
        table[i].fillPercentage = '0';
      }
      if (!isCompleted && table[i].temp == '80') {
        if (i > 0) {
          switch (table[i - 1].temp) {
            case '80':
              table[i].fillPercentage = '0';
              table[i - 1].temp = 80;
              table[i].temp = 80;
              break;
            case '100':
              table[i].fillPercentage = '80';
              break;
            case '0':
              table[i].fillPercentage = '0';
              table[i - 1].temp = 0;
              break;
          }
        }
      }
    }
    //console.table(table);
    return table;
  }

  private generateProgressbarNew(table: any): string {
    let htmlString = '';
    let length = table.length;
    if (length <= 0) return htmlString;

    for (let i = 0; i < length; i++) {
      let liCssClass =
        table[i].fillPercentage == '100'
          ? 'progtrckr-done end-point '
          : 'progtrckr-todo ';
      if (i < length - 1 && table[i + 1].fillPercentage == '100') {
        liCssClass = 'progtrckr-done blue end-point hundredpercent ';
      }
      if (i < length - 1 && table[i + 1].fillPercentage == '80') {
        liCssClass = 'progtrckr-done blue eightypercent no-circle ';
      }
      if (i < length - 1 && table[i + 1].fillPercentage == '0') {
        liCssClass =
          i >= 0 && table[i].fillPercentage == '100'
            ? 'progtrckr-todo zeropercent blue end-point '
            : 'progtrckr-todo zeropercent ';
      }
      htmlString += this.generateDisplayLiItem(table, i, liCssClass);
    }
    return '<ul class="timeline">' + htmlString + '</ul>';
  }
}
