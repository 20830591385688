import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  public cancelFormEvent = new EventEmitter<boolean>();
  public closeWindow = new EventEmitter<boolean>();
  public closeWorkflowForm: Subject<boolean> = new Subject<boolean>();
  public openWorkflowForm: Subject<boolean> = new Subject<boolean>();
  public stopRequest: Subject<void> = new Subject<void>();
  public eDOcTypeParam: any = new BehaviorSubject([]);
  public selectedModuleFilter: any = new BehaviorSubject(
    'shipments,bookings,customs'
  );
  public hasAutoSubscription = new BehaviorSubject<boolean>(true);
  public dateRangeFieldActive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  eDocTypeParameters: any;
  selectedJson: any;
  lastSavedCases: any[] = [];
  defaultEmailConfiguration: any = {};
  selectedEmailList: any[] = [];
  controlType: number;
  controlTypeName: string;
  emailTemplateFilterOption: any = [];

  constructor(private http: HttpClient) { }

  closePopUpWindow() {
    this.closeWindow.emit(true);
  }

  confirmCancellingCreateForm() {
    this.cancelFormEvent.emit(true);
  }

  validateTime(value: string) {
    let time: any = value?.split(",");
    if ((time[0] !== "" && time[1] !== "") || (time?.length !== 1)) {
      return false;
    }
    else {
      return true;
    }
  }

  validateBothDateAndTime(value: string) {
    let pattern = /^(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z,){1,}(\d{2}\/\d{2}\/\d{4}T\d{2}:\d{2}Z)$/i;
    return pattern.test(value)
  }

  validateBothDateRangeAndTime(value: string) {
    let pattern=/^[A-Za-z0-9\s]+T\d{2}:\d{2}Z,T\d{2}:\d{2}Z$/i;
    return pattern.test(value);
  }

  validateOnlyDate(value: string) {
    let pattern = /^(\d{2}\/\d{2}\/\d{4},){1,}(\d{2}\/\d{2}\/\d{4})$/i;
    return pattern.test(value);
  }

  validateOnlyTime(value: any) {
    //let pattern=/^(\d{2}:\d{2},){1,}(\d{2}:\d{2})$/;
    value = value.replaceAll(" ", "");
    let pattern = /^T\d{2}:\d{2}Z,T\d{2}:\d{2}Z$/;
    return pattern.test(value)
  }

  getWorkflowById(workflowId: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'WorkFlow/GetWorkFlowById?id=' +
        workflowId,
        {},
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getWorkflowList(params: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'WorkFlow/Get',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getModuleList() {
    return this.getDropDownList('Module');
  }

  getWhenToExecuteList() {
    return this.getDropDownList('When to Execute');
  }

  getEmailList(filterOption: any): Observable<any> {
    filterOption.push({
      "column_name": "global",
      "operater": "=",
      "value": true
    })
    this.emailTemplateFilterOption = filterOption;
    let params = {
      offset: 0,
      fetch: 10,
      search_text: '',
      isGlobalSearch: true,
      parameters: [],
      sort_options: [],
      filter_options: filterOption,
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'GetEmailTemplate',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getFrequencyList() {
    return this.getDropDownList('Frequency');
  }

  getWeekDaysList() {
    return this.getDropDownList('Days');
  }

  getEDocList() {
    return 0; ///workFlow/document
  }

  getIntervalList() {
    return this.getDropDownList('Interval');
  }

  getEDocType(params: any, isGlobal: boolean) {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'workFlow/document?Global=' +
        isGlobal,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  suggestUser(value: any): Observable<any> {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'userinfo',
        value,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getDropDownList(dropDownFilterTypeName: string): Observable<any> {
    let params = {
      offset: 0,
      fetch: 1000,
      search_text: '',
      isGlobalSearch: true,
      parameters: [
        {
          name: 'string',
          value: dropDownFilterTypeName,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'GetDropDownList',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  createWorkflow(params: any) {
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'WorkFlow/Create',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  updateWorkflow(params: any) {
    return this.http
      .put<any[]>(
        environment.base_api_desktop_url + 'WorkFlow/Update',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  deleteWorkflow(params: any, userEmail: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'WorkFlow/DeleteWorkFlow?userEmail=' +
        userEmail,
        options
      )
      .pipe(catchError(this.handelError));
  }

  getAllAccounts(searchText: any, offset: any): Observable<any> {
    let request = {
      offset: offset,
      fetch: 30,
      search_text: searchText,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<any>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  getWorkflowSubscriptionList(workflowId: string, param: any) {
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'Subscriptions/Get?workFlowId=' +
        workflowId,
        param
      )
      .pipe(catchError(this.handelError));
  }

  getWorkflowLogList(workflowId: string, param: any) {
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'WorkFlow/GetWorkFlowLogs?workFlowId=' +
        workflowId,
        param
      )
      .pipe(catchError(this.handelError));
  }

  getJsonOrDfd(
    searchText: any,
    email: string,
    isArray: boolean,
    json: string,
    filter_options: any,
    accountId?:any,
    offset?: number,
    fetch?: number
  ) {
    let sort: any = [];
    if (json === 'false') {
      sort = [{ column_name: 'fieldName', direction: 'asc' }];
    }
    let param = {
      offset: offset ? offset : 0,
      fetch: fetch ? fetch : 10,
      search_text: searchText,
      isGlobalSearch: true,
      accountId:accountId,
      parameters: [
        {
          name: 'jsonElement',
          value: json,
        },
      ],
      sort_options: sort,
      filter_options: filter_options,
    };
    return this.http
      .post<any>(
        environment.base_api_desktop_url +
        'WorkFlow/GetJSONorDataDefinition?userEmail=' +
        email + '&isArray=' + isArray,
        param
      )
      .pipe(catchError(this.handelError));
  }

  getTypeValueWithType(controlType: any) {
    return this.http
      .get<any[]>(
        environment.base_api_desktop_url +
        'DataDefinition/GetControlType?controlType=' + controlType + '&isWorkFlow=true',
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }

  resetCase(json: any) {
    this.selectedJson = JSON.stringify(json);
  }

  returnResetCase() {
    return this.selectedJson;
  }

  returnDefaultEmailConfiguration() {
    return this.defaultEmailConfiguration;
  }

  returnSelectedEmailList() {
    return this.selectedEmailList;
  }

  getPreviewlist(params: any, lastUpdated?: boolean, order?: any) {
    let url = "";
    if (lastUpdated) {
      url = environment.base_api_desktop_url + 'WorkFlow/Preview?columnName=lastlog&order=' + order;
    }
    else {
      url = environment.base_api_desktop_url + 'WorkFlow/Preview';
    }
    return this.http
      .post<any[]>(
        url,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  unSubscribeUserWorkflow(
    params: any,
    subscriptionId: any,
    userEmailId: any,
    accountId: any,
    updatedBy: any
  ) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'Subscriptions/SoftDeleteUnSubscription?autosubscriptionId=' +
        subscriptionId +
        '&userMailId=' +
        userEmailId +
        '&account=' +
        accountId +
        '&updatedBy=' +
        updatedBy,
        options
      )
      .pipe(catchError(this.handelError));
  }

  subscribeUserWorkflow(
    params: any,
    subscriptionId: any,
    userEmailId: any,
    accountId: any,
    updatedBy: any
  ) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };

    return this.http
      .delete<any>(
        environment.base_api_desktop_url +
        'Subscriptions/SoftDeleteSubscription?autosubscriptionId=' +
        subscriptionId +
        '&userMailId=' +
        userEmailId +
        '&account=' +
        accountId +
        '&updatedBy=' +
        updatedBy,
        options
      )
      .pipe(catchError(this.handelError));
  }

  getEmailTemplate(offset: number, fetch: number) {
    let param = {
      offset: offset,
      fetch: fetch,
      search_text: '',
      isGlobalSearch: true,
      parameters: [],
      sort_options: [],
      filter_options: this.emailTemplateFilterOption,
    };
    return this.http
      .post<any>(environment.base_api_desktop_url + 'GetEmailTemplate', param)
      .pipe(catchError(this.handelError));
  }
}
