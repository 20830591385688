<div id="accordion" class="logs">
  <div *ngIf="progressCount" id="shipment-log-one" class="card">
    <div class="card-header" (click)="progressMenu('1', '5', ''); addClassFor('#shipment-log-one')">
      <h5 class="mb-0">
        <button id="shipment-btn-pr" class="btn btn-link collapsed" data-toggle="collapse" data-target="#shipmentLogOne"
          aria-expanded="false" aria-controls="shipmentLogOne">
          <a class="btn-header-link">{{
            type == "shipments" ? "Shipment Progress" : "Booking Progress"
            }}</a>
        </button>
      </h5>
    </div>

    <div id="shipmentLogOne" class="collapse" aria-labelledby="shipment-log-one">
      <div class="card-body">
        <div *ngIf="logsDataShipmentProgress.length > 0" class="tableFixHead add-changes max-h" id="scroll-table-added">
          <table class="table log-table-border table-sortable document-sec" aria-labelledby="document-table"
            id="log-table">
            <caption class="d-none"></caption>
            <thead>
              <tr style="height: 46px">
                <th id="data-field-h1" class="asc sort" data-order="asc" data-name="dataField"
                  (click)="sortFunction($event, '1', '5', '')" data-parent="#log-table">
                  Data Field
                </th>
                <th id="description-h1" class="asc sort mobile-hidden" data-order="asc" data-name="description"
                  (click)="sortFunction($event, '1', '5', '')" data-parent="#log-table">
                  Description
                </th>
                <th id="time-date-h1" class="asc factive sort" data-order="asc" data-name="createDateTime"
                  (click)="sortFunction($event, '1', '5', '')" data-parent="#log-table">
                  Date and Time (UTC)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let value of logsDataShipmentProgress; let i = index">
                <td>
                  {{ value.dataField }}
                </td>
                <td class="mobile-hidden">
                  {{ value.description }}
                </td>
                <td>
                  {{ dateFormat(value.createDateTime) | date : "MMM d, y" }} -
                  {{ timeFormat(value.createDateTime) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="logsDataShipmentProgress.length == 0 && !isProgressShow" class="text-center">
          No records found
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="detailsCount" id="headingsetnew" class="card">
    <div class="card-header" (click)="progressMenu('2', '6', '7'); addClassFor('#headingsetnew')">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsesetnew"
          aria-expanded="false" aria-controls="collapsesetnew">
          <a class="btn-header-link">{{ headerNameset() }}</a>
        </button>
      </h5>
    </div>
    <div id="collapsesetnew" class="collapse" aria-labelledby="headingsetnew">
      <div class="card-body">
        <div *ngIf="logsDataShipmentDetails.length > 0" class="tableFixHead add-changes max-h" id="scroll-table-added">
          <table class="table log-table-border table-sortable document-sec" aria-labelledby="document-table"
            id="log-table">
            <caption class="d-none"></caption>
            <thead>
              <tr style="height: 46px">
                <th id="data-field-h2" class="asc sort" data-order="asc" data-name="dataField"
                  (click)="sortFunction($event, '2', '6', '7')" data-parent="#log-table">
                  Data Field
                </th>
                <th id="description-h2" class="asc sort mobile-hidden" data-order="asc" data-name="description"
                  (click)="sortFunction($event, '2', '6', '7')" data-parent="#log-table">
                  Description
                </th>
                <th id="time-date-h2" class="asc factive sort" data-order="asc" data-name="createDateTime"
                  (click)="sortFunction($event, '2', '6', '7')" data-parent="#log-table">
                  Date and Time (UTC)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let value of logsDataShipmentDetails; let i = index">
                <td>
                  {{ value.dataField }}
                </td>
                <td *ngIf="
                    value.description !==
                    'One or more reference numbers have changed'
                  " class="mobile-hidden">
                  {{ value.description }}
                </td>
                <td *ngIf="
                    value.description ===
                    'One or more reference numbers have changed'
                  ">
                  {{ value.description }}.<a href="javascript:void(0)" class="reff-link-text"
                    (click)="viewReference()">Click here to view reference details.</a>
                </td>
                <td>
                  {{ dateFormat(value.createDateTime) | date : "MMM d, y" }} -
                  {{ timeFormat(value.createDateTime) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="logsDataShipmentDetails.length == 0 && !isProgressShow" class="text-center mt-2">
          No records found
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="documentsCount" id="headingThree" class="card">
    <div class="card-header" (click)="progressMenu('3', '3', '8'); addClassFor('#headingThree')">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
          aria-controls="collapseThree">
          <a class="btn-header-link">Documents</a>
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
      <div class="card-body">
        <div *ngIf="logsDataShipmentDocuments.length > 0" class="tableFixHead add-changes max-h"
          id="scroll-table-added">
          <table class="table log-table-border table-sortable document-sec" aria-labelledby="document-table"
            id="log-table">
            <caption class="d-none"></caption>
            <thead>
              <tr style="height: 46px">
                <th id="data-field-h3" class="asc sort" data-order="asc" data-name="userId"
                  (click)="sortFunction($event, '3', '3', '8')" data-parent="#log-table">
                  User
                </th>
                <th id="description-h3" class="asc sort mobile-hidden" data-order="asc" data-name="description"
                  (click)="sortFunction($event, '3', '3', '8')" data-parent="#log-table">
                  Description
                </th>
                <th id="source" class="asc sort mobile-hidden" data-order="asc" data-name="source"
                  (click)="sortFunction($event, '3', '3', '8')" data-parent="#log-table">
                  Source
                </th>
                <th id="time-date-h3" class="asc factive sort" data-order="asc" data-name="createDateTime"
                  (click)="sortFunction($event, '3', '3', '8')" data-parent="#log-table">
                  Date and Time (UTC)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let value of logsDataShipmentDocuments; let i = index">
                <td>
                  {{ value.userId ? value.userId : "---" }}
                </td>
                <td class="mobile-hidden">
                  {{ value.description }}
                  <div *ngIf="
                      value.changeLogAttachment &&
                      this.currentUserData.userType == 'systemadmin'
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" class="mr-2">
                      <g data-name="Group 5689">
                        <path data-name="Path 10877"
                          d="M8.345 0a.6.6 0 0 0-.587.6v10.49L4.005 7.338a.6.6 0 1 0-.843.844l4.77 4.77.007.006.018.017.027.025.016.01.028.02.021.013.025.014.021.01.024.011.027.009.021.007.027.007.025.005h.204l.041-.007.032-.007.027-.007.04-.012.026-.009.035-.015.049-.024.036-.022.025-.017.039-.031.028-.025 4.77-4.77a.6.6 0 0 0-.416-1.024h-.017a.6.6 0 0 0-.41.18L8.95 11.092V.6a.6.6 0 0 0-.6-.6h-.005zM.6 15.51H.594a.6.6 0 0 0 0 1.193h15.519a.6.6 0 1 0 0-1.193H.6z"
                          transform="translate(7.147 6.649)" style="fill: #01457c" />
                      </g>
                    </svg>
                    <span (click)="
                        docDownload(
                          value.changeLogAttachment?.newPath,
                          value.changeLogAttachment?.fileName.split('.').pop(),
                          'download',
                          value.changeLogAttachment?.fileName,
                          value.changeLogAttachment?.fileTypeCode
                        )
                      " class="download-set">Click here to download file</span>
                  </div>
                </td>
                <td>
                  {{ value.source }}
                </td>
                <td>
                  {{ dateFormat(value.createDateTime) | date : "MMM d, y" }} -
                  {{ timeFormat(value.createDateTime) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="logsDataShipmentDocuments.length == 0 && !isProgressShow" class="text-center">
          No records found
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="containerCount" id="headingFour" class="card">
    <div class="card-header" (click)="progressMenu('4', '', ''); addClassFor('#headingFour')">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
          aria-controls="collapseFour">
          <a class="btn-header-link">Containers</a>
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
      <div class="card-body">
        <div *ngIf="logsDataShipmentContainer.length > 0" class="tableFixHead add-changes max-h"
          id="scroll-table-added">
          <table class="table log-table-border table-sortable document-sec" aria-labelledby="document-table"
            id="log-table">
            <caption class="d-none"></caption>
            <thead>
              <tr style="height: 46px">
                <th id="data-field-h4" class="asc sort" data-order="asc" data-name="dataField"
                  (click)="sortFunction($event, '4', '', '')" data-parent="#log-table">
                  Data Field
                </th>
                <th id="description-h4" class="asc sort mobile-hidden" data-order="asc" data-name="description"
                  (click)="sortFunction($event, '4', '', '')" data-parent="#log-table">
                  Description
                </th>
                <th id="time-date-h4" class="asc factive sort" data-order="asc" data-name="createDateTime"
                  (click)="sortFunction($event, '4', '', '')" data-parent="#log-table">
                  Date and Time (UTC)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let value of logsDataShipmentContainer; let i = index">
                <td>
                  {{ value.dataField }}
                </td>
                <td class="mobile-hidden">
                  {{ value.description }}
                </td>
                <td>
                  {{ dateFormat(value.createDateTime) | date : "MMM d, y" }} -
                  {{ timeFormat(value.createDateTime) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="logsDataShipmentContainer.length == 0 && !isProgressShow" class="text-center">
          No records found
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="actionFieldCount" id="headinglogset" class="card heading-desc-brk">
    <div class="card-header" (click)="loadActionFieldLogs(); addClassFor('#headinglogset')">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapselogset"
          aria-expanded="false" aria-controls="collapselogset">
          <a class="btn-header-link">Action Fields</a>
        </button>
      </h5>
    </div>
    <div id="collapselogset" class="collapse" aria-labelledby="headinglogset">
      <div class="card-body">
        <div *ngIf="logsDataActionFieldContainer.length > 0" class="tableFixHead add-changes max-h"
          id="scroll-table-added">
          <table class="table log-table-border table-sortable document-sec" aria-labelledby="document-table"
            id="log-table">
            <caption class="d-none"></caption>
            <thead>
              <tr style="height: 46px">
                <th id="data-field-h4" class="asc sort" data-order="asc" data-name="dataField"
                  (click)="sortFunction($event, '16', '', '')" data-parent="#log-table">
                  Data Field
                </th>
                <th id="description-h4" class="asc sort mobile-hidden" data-order="asc" data-name="description"
                  (click)="sortFunction($event, '16', '', '')" data-parent="#log-table">
                  Description
                </th>
                <th id="data-field-h4" class="asc sort mobile-hidden" data-order="asc" data-name="userId"
                  (click)="sortFunction($event, '16', '', '')" data-parent="#log-table">
                  User
                </th>
                <th id="time-date-h4" class="asc factive sort" data-order="asc" data-name="createDateTime"
                  (click)="sortFunction($event, '16', '', '')" data-parent="#log-table">
                  Date and Time (UTC)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let value of logsDataActionFieldContainer; let i = index">
                <td>
                  {{ value.dataField }}
                </td>
                <td class="mobile-hidden">
                  {{ value.description }}
                </td>
                <td class="mobile-hidden">
                  {{ value.userId ? value.userId : "---" }}
                </td>
                <td>
                  {{ dateFormat(value.createDateTime) | date : "MMM d, y" }} -
                  {{ timeFormat(value.createDateTime) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="logsDataActionFieldContainer.length == 0 && !isProgressShow" class="text-center">
          No records found
        </div>
      </div>
    </div>
  </div>
</div>