import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils'
import { CustomsUtils } from '../../features/customs/common/customs-utils';

@Pipe({
  name: 'customDetailClearanceNotificationText',
})
export class CustomDetailClearanceNotificationTextPipe
  implements PipeTransform
{
  transform(customData: any, ...args: any[]): any {
    if (!customData) {
      return '';
    }
    let isExport = CustomsUtils.isCustomTypeExport(customData);
    let htmlString = '';
    let message = '';
    let cssClassName = 'blue-clr';

    switch (customData.customstatus) {
      case 'Filed':
      case 'In Progress':
        htmlString =
          '<img src="../../../../../assets/img/ok-blue-icon.svg" alt="ok" />&nbsp;';
        message =
          'Customs Filed on ' +
          CommonUtils.getYearFormattedDate(
            customData.customs_declarations[0]?.filed_date
          );
        break;
      case 'Released':
        htmlString =
          '<img src="../../../../../assets/img/ok-blue-icon.svg" alt="ok" />&nbsp;';
        message = isExport ? 'Customs cleared on ' : 'Customs Released on ';
        message += CommonUtils.getYearFormattedDate(
          customData.customs_declarations[0]?.release_date
        );
        break;
    }

    if (!isExport) {
      switch (customData.customException) {
        case 'On Hold':
          htmlString =
            '<img src="../../../../../assets/img/ok-red-icon.svg" alt="ok" />&nbsp;';
          message = 'Customs under Exam';
          cssClassName = 'red-clr';
          break;
        case 'Delayed':
          switch (
            customData.customs_declarations[0]?.us_customs_declaration
              ?.cargo_release_status
          ) {
            case 'RVW':
              message = 'Customs under CBP Review';
              break;
            case 'DOC':
              message = 'Documents requested';
              break;
            default:
              message = '';
          }

          if (message) {
            htmlString =
              '<img src="../../../../../assets/img/ok-orange-icon.svg" alt="ok" />&nbsp;';
            cssClassName = 'orange-clr';
          }
          break;
      }
    }
    if (message) {
      htmlString += '<span class="' + cssClassName + '">' + message + '</span>';
    } else {
      htmlString = '';
    }
    return htmlString;
  }
}
