import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ActivateDeactivateTypeParams,
  AddUserAccountTypeParams,
  assignDefaultParams,
  UserFilterOption,
  UserListTypeParams,
  UserManagListParameters,
  UserManagListSort,
  UserTypeParams,
} from '../model/usermanagment.model';
import { Account } from 'src/app/models/admin/account';

@Injectable()
export class UserManagmentService {
  constructor(private http: HttpClient) { }

  public stopRequest: Subject<void> = new Subject<void>();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  activateDeactivateUser(userEmail: any, status: any): Observable<any> {
    const param = new ActivateDeactivateTypeParams(
      userEmail,
      status,
      environment.angular_web_url
    );
    return this.http
      .put<any>(
        environment.base_api_desktop_url + 'user/status',
        param,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  userManagmentList(): Observable<any> {
    const params = new UserListTypeParams(0, 100);
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'userinfo',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getUserDetail(email: any): Observable<any> {
    return this.http
      .get<any>(environment.base_api_desktop_url + 'user?userEmail=' + email)
      .pipe(catchError(this.handelError));
  }

  getUserManagmentAccounts(email: any): Observable<any> {
    const userListParam = new UserManagListParameters('userEmail', email);
    const userListSorting = new UserManagListSort('aliasName', 'asc');
    const parameters = new UserFilterOption('status', '=', '1');
    const params = new UserTypeParams(
      0,
      1000,
      '',
      [userListParam],
      [userListSorting],
      [parameters]
    );
    return this.http
      .post<any>(
        environment.base_api_desktop_url + 'user/account',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  getAllUsers(params: any) {
    let searchText = '';
    let parameters: any[] = [];
    let sortParameter = [];
    if (params.autocompleteRecord) {
      parameters = [
        {
          name: 'userEmail',
          value: params.autocompleteRecord,
        },
        {
          name: 'isDefaultUser',
          value: '2',
        },
      ];
    } else if (params.autocompleteButton) {
      parameters = [
        {
          name: 'isDefaultUser',
          value: '2',
        },
      ];
      searchText = params.autocompleteButton;
    } else {
      parameters = [
        {
          name: 'isDefaultUser',
          value: '2',
        },
      ];
    }

    if (params.sortFilter.length > 0) {
      sortParameter = params.sortFilter;
    }
    let request = {
      offset: params.offset,
      fetch: params.recordList,
      search_text: searchText,
      parameters: parameters,
      sort_options: sortParameter,
      filter_options: params.searchfilters,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'userinfo', request)
      .pipe(takeUntil(this.stopRequest), catchError(this.handelError));
  }

  getAllAccounts(val: any, offsetVal: any): Observable<any> {
    let request = {
      offset: offsetVal,
      fetch: 20,
      search_text: val,
      parameters: [
        {
          name: 'accountType',
          value: 'ParentAccount',
        },
        {
          name: 'parentAccountOhCode',
          value: '',
        },
      ],
      sort_options: [
        {
          column_name: 'aliasName',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  handelError(error: { message: any }) {
    return throwError(error.message || 'Server Error');
  }

  getUserByAccount(accountList: any) {
    let params = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: accountList,
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: null,
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url + 'SuggestionAccountUser',
        params
      )
      .pipe(catchError(this.handelError));
  }

  getAutoSuggessionFieldValue(suggestedValue: any) {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: suggestedValue,
      parameters: [],
      sort_options: [],
      filter_options: [],
    };

    return this.http
      .post<[]>(environment.base_api_desktop_url + 'userinfo', request)
      .pipe(catchError(this.handelError));
  }

  removerAccount(userEmail: any, ohCode: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        operation: 'removeuser',
        parameter: {
          name: 'user',
          value: {
            userEmail: userEmail,
            accounts: [
              {
                oH_FullName: 'string',
                oH_Code: ohCode,
              },
            ],
          },
        },
      },
    };

    return this.http
      .delete<any>(environment.base_api_desktop_url + 'useraccount', options)
      .pipe(catchError(this.handelError));
  }

  userAssignRole(email: any, ohCode: any, isDefault: any): Observable<any> {
    const params = new assignDefaultParams(email, ohCode, isDefault);
    return this.http
      .put<any>(
        environment.base_api_desktop_url + 'useraccount',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  editUserDetail(email: any, bodyParam: any): Observable<any> {
    return this.http
      .put<any>(
        environment.base_api_desktop_url + 'user',
        bodyParam,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  autoSuggestedRecords(email: any) {
    let request = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: [
        {
          name: 'userEmail',
          value: email,
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: null,
    };
    return this.http
      .post<[]>(
        environment.base_api_desktop_url + 'Suggestion​/user​/account',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getAccountAutoComplete(val: any, email: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 10,
      search_text: val,
      parameters: [{ name: 'userEmail', value: email }],
      sort_options: [{ column_name: 'oH_Code', direction: 'asc' }],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url + 'Suggestion/user/account',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getAccountAutoCompleteInfiniteScroll(val: any, email: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 100,
      search_text: val,
      parameters: [{ name: 'userEmail', value: email }],
      sort_options: [{ column_name: 'oH_Code', direction: 'asc' }],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url + 'Suggestion/user/account',
        request
      )
      .pipe(catchError(this.handelError));
  }

  getAccountAutoCompleteInfiniteScrollForNP(
    val: any,
    email: any,
    userType: any
  ): Observable<any> {
    let isUser = userType == "user" ? true : false;
    let request = {
      offset: 0,
      fetch: 100,
      search_text: val,
      parameters: [{ name: 'userEmail', value: email }],
      sort_options: [{ column_name: 'oH_Code', direction: 'asc' }],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<Account[]>(
        environment.base_api_desktop_url +
        'Suggestion/user/account?ischeckUserAccount=' + isUser,
        request
      )
      .pipe(catchError(this.handelError));
  }

  getSingleAccount(val: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 10,
      search_text: '',
      parameters: [
        {
          name: 'accountType',
          value: '',
        },
        {
          name: 'parentAccountOhCode',
          value: val,
        },
      ],
      sort_options: [
        {
          column_name: 'oH_Code',
          direction: 'asc',
        },
      ],
      filter_options: [
        {
          column_name: 'status',
          operator: '=',
          value: '1',
        },
      ],
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + 'Account', request)
      .pipe(catchError(this.handelError));
  }

  addUserAccount(
    email: any,
    ohCode: any,
    role: any,
    isDefaultAccount: any
  ): Observable<any> {
    const params = new AddUserAccountTypeParams(
      email,
      ohCode,
      role,
      isDefaultAccount
    );
    return this.http
      .put<any>(
        environment.base_api_desktop_url + 'useraccount',
        params,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  deleteUser(userEmail: any, status: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        emailAddress: userEmail,
        status: status,
      },
    };

    return this.http
      .delete<any>(environment.base_api_desktop_url + 'user', options)
      .pipe(catchError(this.handelError));
  }

  systemAdminRights(userEmail: any, url: any): Observable<any> {
    let request = {
      emailAddress: userEmail,
    };
    return this.http
      .post<Account[]>(environment.base_api_desktop_url + url, request)
      .pipe(catchError(this.handelError));
  }

  getUserRole(ohCode: any): Observable<any> {
    return this.http
      .get<Account[]>(
        environment.base_api_desktop_url +
        'account/userrole?accountId=' +
        ohCode
      )
      .pipe(catchError(this.handelError));
  }

  inviteUsers(email: any, inviteEmail: any, isFrom: any): Observable<any> {
    let request = {
      invited: email,
      users: inviteEmail,
      systemadmin: isFrom,
      environment: environment.angular_web_url,
    };
    return this.http.post<Account[]>(
      environment.base_api_desktop_url + 'user/invite',
      request
    );
  }

  removeUserProfileImg(bodyParam: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: bodyParam,
    };

    return this.http
      .delete<any>(environment.base_api_desktop_url + 'user', options)
      .pipe(catchError(this.handelError));
  }
}
