import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CommonUtils } from 'src/app/common/utils';
import { FilterService } from 'src/app/features/header/services/filter.service';
import {
  AppInsightsService,
  AI_PageName,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';
import { ShipmentServies } from '../../services/shipment.service';
declare var $: any;

/**
 * View All Shipments Component Test
 * Covered - 81.29% on July 24, 2023
 */

@Component({
  selector: 'app-view-all-shipments',
  templateUrl: './view-all-shipments.component.html',
  styleUrls: ['./view-all-shipments.component.scss'],
})
export class ViewAllShipmentsComponent implements OnInit, OnChanges {
  isProgressShow: boolean;
  shipmentSearchText: string = '';
  shipmentData: any[] = [];
  urlShipmentId: string;
  selectedShipment: any;
  popupToggle: boolean;
  offset: number = 0;
  pickedUpScheduled: any[] = [];
  pickedUp: any[] = [];
  scheduled: any[] = [];
  inTransit: any[] = [];
  arrived: any[] = [];
  deliveryScheduled: any[] = [];
  delivered: any[] = [];
  pending: any[] = [];
  converted: any[] = [];
  cancelled: any[] = [];
  archived: any[] = [];
  prepared: any[] = [];
  filed: any[] = [];
  inProgress: any[] = [];
  released: any[] = [];
  archivedCustoms: any[] = [];
  isProgressPickedUpScheduled = false;
  isProgressPickedUp = false;
  isProgressScheduled = false;
  isProgressInTransit = false;
  isProgressArrived = false;
  isProgressDeliveryScheduled = false;
  isProgressDelivered = false;
  isProgressPending = false;
  isProgressConverted = false;
  isProgressCancelled = false;
  isProgressArchived = false;
  isProgressPrepared = false;
  isProgressFiled = false;
  isProgressInProgress = false;
  isProgressReleased = false;
  isProgressArchivedCustoms = false;
  pickedUpScheduledCount: number = 0;
  pickedUpScheduledShipmentsId: any = [];
  pickedUpCount: number = 0;
  pickedUpShipmentsId: any = []
  scheduledCount: number = 0;
  scheduledShipmentsId: any = [];
  inTransitCount: number = 0;
  inTransitShipmentsId: any = [];
  arrivedCount: number = 0;
  arrivedShipmentsId: any = [];
  deliveryScheduledCount: number = 0;
  deliveryScheduledShipmentsId: any = [];
  deliveredCount: number = 0;
  deliveredShipmentsId: any = [];
  pendingCount: number = 0;
  pendingBookingsId: any = [];
  convertedCount: number = 0;
  convertedBookingsId: any = [];
  cancelledCount: number = 0;
  cancelledBookingsId: any = [];
  archivedCount: number = 0;
  archivedBookingsId: any = [];
  preparedCount: number = 0;
  preparedCustomsId: any = [];
  filedCount: number = 0;
  filedCustomsId: any = [];
  inProgressCount: number = 0;
  inProgressCustomsId: any = [];
  releasedCount: number = 0;
  releaseCustomsId: any = [];
  archivedCustomsCount: number = 0;
  archivedCustomsId: any = [];
  currentStatus: any;
  currentStatusCount: number = 0;
  currentType: any;
  @Input() urlShare = '';
  popupToggleList: boolean;
  copyText: any = '';
  toastMessage = 'test';
  tabPickupSchedule: boolean = false;
  clickEvent = new Subject();
  totalShipmentCount: number = 0;
  totalBookingCount: number = 0;
  totalCustomsCount: number = 0;
  tabPickup: boolean = false;
  tabSchedule: boolean = false;
  tabTransit: boolean = false;
  tabArrived: boolean = false;
  tabDeliverySchedule: boolean = false;
  tabDelivery: boolean = false;
  tabPending: boolean = false;
  tabConverted: boolean = false;
  tabCancelled: boolean = false;
  tabArchived: boolean = false;
  tabPrepared: boolean = false;
  tabFiled: boolean = false;
  tabInProgress: boolean = false;
  tabReleased: boolean = false;
  tabArchivedCustoms: boolean = false;
  url = '';
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  isShipmentIsVisible = false;
  isBookingIsVisible = false;
  isCustomsIsVisible = false;
  isShipmentTab = false;
  isBookingTab = false;
  isCustomsTab = false;
  apiCall: any = null;
  trackOfApiCalls: any = [];
  @ViewChild('quicksearchBtn', { static: false }) quicksearchBtn: ElementRef;

  constructor(
    private shipmentService: ShipmentServies,
    public filterService: FilterService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private currentSessionUserData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService
  ) {
    this.isShipmentIsVisible = this.currentAccountData.shipmentIsVisible;
    this.isBookingIsVisible = this.currentAccountData.bookingIsVisible;
    this.isCustomsIsVisible = this.currentAccountData.customsIsVisible;
  }

  checkDuplicateApiCalls(event: any) {
    if (event.count == 0) {
      return false;
    }
    return !this.trackOfApiCalls.some((e: any) => e.id == event.id);
  }

  ngOnInit(): void {
    this.clickEvent.pipe(debounceTime(300)).subscribe((e: any) => {
      if (this.checkDuplicateApiCalls(e)) {
        this.loadGroupSearchData(e.status, e.type)
        this.trackOfApiCalls.push(e);
      }
    });
    this.showPageTitle();
    this.trackAIPageView();
    this.fnActiveRoute();
    $('.collapse').on('click', function (e: any) {
      e.stopPropagation();
    });
    this.searchAutocomplete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.shipmentSearchText = params.filterParam;
      this.autoSuggestShipment(this.shipmentSearchText);
    });
  }

  showPageTitle() {
    CommonUtils.setPageTitle(this.titleService, 'Global Search Results');
  }

  trackAIPageView() {
    this.appInsightsService.logPageView(
      AI_PageName.ViewAllShipment,
      window.location.href
    );
  }

  fnActiveRoute() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.filterParam) {
        let parsed: any = JSON.parse(params.filterParam);
        this.shipmentSearchText = parsed.val;
        this.autoSuggestShipment(this.shipmentSearchText);
        if (parsed.from == 'Shipment') {
          this.isShipmentTab = true;
          this.isBookingTab = false;
          this.isCustomsTab = false;
        } else if (parsed.from == 'Booking' && this.isBookingIsVisible) {
          this.isShipmentTab = false;
          this.isBookingTab = true;
          this.isCustomsTab = false;
        } else if (parsed.from == 'Customs' && this.isCustomsIsVisible) {
          this.isShipmentTab = false;
          this.isBookingTab = false;
          this.isCustomsTab = true;
        } else {
          if (this.isShipmentIsVisible) {
            this.isShipmentTab = true;
            this.isBookingTab = false;
            this.isCustomsTab = false;
          } else if (this.isBookingIsVisible) {
            this.isShipmentTab = false;
            this.isBookingTab = true;
            this.isCustomsTab = false;
          } else {
            this.isShipmentTab = false;
            this.isBookingTab = false;
            this.isCustomsTab = true;
          }
        }
      }
    });
  }

  searchAutocomplete() {
    if (this.currentUserData) {
      setTimeout(() => {
        fromEvent(this.quicksearchBtn.nativeElement, 'keyup')
          .pipe(
            map((event: any) => {
              return event.target.value;
            }),
            debounceTime(800)
          )
          .subscribe((text: string) => {
            this.autoSuggestShipment(text);
          });
      }, 0);
    }
  }


  getShipmentIDs(statusCode: any): any {
    switch (statusCode) {
      case 1:
        return [this.pickedUpScheduled, this.pickedUpScheduledShipmentsId];
      case 2:
        return [this.pickedUp, this.pickedUpShipmentsId];
      case 3:
        return [this.scheduled, this.scheduledShipmentsId];
      case 4:
        return [this.inTransit, this.inTransitShipmentsId];
      case 5:
        return [this.arrived, this.arrivedShipmentsId];
      case 6:
        return [this.deliveryScheduled, this.deliveryScheduledShipmentsId];
      case 7:
        return [this.delivered, this.deliveredShipmentsId];
    }
  }

  getBookingsIDs(statusCode: any): any {
    switch (statusCode) {
      case 1:
        return [this.pending, this.pendingBookingsId];
      case 2:
        return [this.converted, this.convertedBookingsId];
      case 3:
        return [this.cancelled, this.cancelledBookingsId];
      case 4:
        return [this.archived, this.archivedBookingsId];
    }
  }

  getCustomsIDs(statusCode: any): any {
    switch (statusCode) {
      case 1:
        return [this.prepared, this.preparedCustomsId];
      case 2:
        return [this.filed, this.filedCustomsId];
      case 3:
        return [this.inProgress, this.inProgressCustomsId];
      case 4:
        return [this.released, this.releaseCustomsId];
      case 5:
        return [this.archivedCustoms, this.archivedCustomsId];
    }
  }

  getSelectedTabData(statusCode: any, type: any) {

    if (type == Cargo.shipments) {
      return this.getShipmentIDs(parseInt(statusCode))
    }
    else if (type == Cargo.bookings) {
      return this.getBookingsIDs(parseInt(statusCode))
    }
    else if (type == Cargo.customs) {
      return this.getCustomsIDs(parseInt(statusCode))
    }
    else {
      return null;
    }
  }

  getOffsetIds(statusCode: any, type: any) {
    let [loadedData, completeList] = this.getSelectedTabData(statusCode, type);
    loadedData = loadedData.map((e: any) => e.id);
    return completeList.filter((e: any) => !loadedData.includes(e)).slice(0, 10);
  }

  bodyParam: any = [];
  loadGroupSearchData(statusCode: any, type: any) {
    let lastSentParam = this.bodyParam;
    this.bodyParam = this.getOffsetIds(statusCode, type);
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.currentStatus = statusCode;
    this.currentType = type;
    if (this.bodyParam?.length > 0 && JSON.stringify(lastSentParam) !=
      JSON.stringify(this.bodyParam)) { // validating empty and redudant calls
      this.isProgressShow = true;
      this.progressStatus(statusCode, true, type);
      let module = type == Cargo.shipments ? 1 : type == Cargo.bookings ? 2 : 3;
      this.shipmentService.getShipmentDetails(accountId, emailId, this.bodyParam, isGroup, module)
        .subscribe((response: any) => {
          this.loadData(statusCode, response.result, type);
          this.progressStatus(statusCode, false, type);
          this.isProgressShow = false;
        })
    }
  }

  loadData(status: any, res: any, type: any) {
    if (this.isShipmentIsVisible && type === 'shipment') {
      this.shipmentDataList(status, res);
    } else if (this.isBookingIsVisible && type === 'booking') {
      this.bookingDataList(status, res);
    } else if (this.isCustomsIsVisible && type === 'customs') {
      this.customsDataList(status, res);
    }
  }

  shipmentDataList(status: number, res: any) {
    if (status == 1) {
      this.pickedUpScheduled = [...this.pickedUpScheduled, ...res];
    } else if (status == 2) {
      this.pickedUp = [...this.pickedUp, ...res];
    } else if (status == 3) {
      this.scheduled = [...this.scheduled, ...res];
    } else if (status == 4) {
      this.inTransit = [...this.inTransit, ...res];
    } else if (status == 5) {
      this.arrived = [...this.arrived, ...res];
    } else if (status == 6) {
      this.deliveryScheduled = [...this.deliveryScheduled, ...res];
    } else if (status == 7) {
      this.delivered = [...this.delivered, ...res];
    }
  }

  bookingDataList(status: number, res: any) {
    if (status == 1) {
      this.pending = [...this.pending, ...res];
    } else if (status == 2) {
      this.converted = [...this.converted, ...res];
    } else if (status == 3) {
      this.cancelled = [...this.cancelled, ...res];
    } else if (status == 4) {
      this.archived = [...this.archived, ...res];
    }
  }

  customsDataList(status: number, res: any) {
    if (status == 1) {
      this.prepared = [...this.prepared, ...res];
    } else if (status == 2) {
      this.filed = [...this.filed, ...res];
    } else if (status == 3) {
      this.inProgress = [...this.inProgress, ...res];
    } else if (status == 4) {
      this.released = [...this.released, ...res];
    } else if (status == 5) {
      this.archivedCustoms = [...this.archivedCustoms, ...res];
    }
  }

  clearParticularArray(status: number, type: any) {
    if (type === 'shipment') {
      this.clearShipmentLists(status);
    } else if (type === 'booking') {
      this.clearBookingLists(status);
    } else if (type === 'customs') {
      this.clearCustomsLists(status);
    }
  }

  clearShipmentLists(status: number) {
    if (status === 1) {
      this.pickedUpScheduled = [];
    } else if (status === 2) {
      this.pickedUp = [];
    } else if (status === 3) {
      this.scheduled = [];
    } else if (status === 4) {
      this.inTransit = [];
    } else if (status === 5) {
      this.arrived = [];
    } else if (status === 6) {
      this.deliveryScheduled = [];
    } else if (status === 7) {
      this.delivered = [];
    }
  }

  clearBookingLists(status: number) {
    if (status === 1) {
      this.pending = [];
    } else if (status === 2) {
      this.converted = [];
    } else if (status === 3) {
      this.cancelled = [];
    } else if (status === 4) {
      this.archived = [];
    }
  }

  clearCustomsLists(status: number) {
    if (status === 1) {
      this.prepared = [];
    } else if (status === 2) {
      this.filed = [];
    } else if (status === 3) {
      this.inProgress = [];
    } else if (status === 4) {
      this.released = [];
    } else if (status === 5) {
      this.archivedCustoms = [];
    }
  }

  // TODO: to be refactored
  prepareApiBodyParams(status: any, offset: number) {
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    let filterOption = [
      {
        column_name: 'status',
        operater: '=',
        value: status?.toString(),
      },
    ];

    let bodyParam = {
      offset: offset,
      fetch: 10,
      isGlobalSearch: true,
      search_text: this.shipmentSearchText,
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      advanceFilterOptions: [],
      parameters: [],
      sort_options: [],
      filter_options: filterOption,
    };
    return { bodyParam, accountId, emailId, isGroup };
  }

  featureHide() {
    this.popupToggle = false;
  }

  onDetailPopupCloseHandler(event: any) {
    this.popupToggle = event;
  }

  share(event: any, shipmentId: any, type: any) {
    this.popupToggleList = true;
  }

  sharePopUpfeatureHide() {
    this.popupToggleList = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  autoSuggestShipment(val: any) {
    $('.collapse').removeClass('show');
    this.clearArray();
    this.setFalseAccordions();
    let accountId = this.currentAccountData.accountId;
    let userEmail = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    if (val?.length > 2) {
      this.isProgressShow = true;
      this.clearTrack();
      this.shipmentService
        .getShipmentStatusCount(accountId, userEmail, val, isGroup)
        .subscribe(
          (res) => {
            this.initialCount();
            if (res.result != null || res.result) {
              if (res.result.shipment != null) {
                this.totalShipmentCount = res.result.shipment.totalCount;
                if (res.result.shipment.statusRecords != null) {
                  this.autoSuggesionShipmentCount(res);
                }
              }
              if (res.result.booking != null) {
                this.totalBookingCount = res.result.booking.totalCount;
                if (res.result.booking.statusRecords != null) {
                  this.autoSuggesionBookingCount(res);
                }
              }
              if (res.result.custom != null) {
                this.totalCustomsCount = res.result.custom.totalCount;
                if (res.result.custom.statusRecords != null) {
                  this.autoSuggesionCustomsCount(res);
                }
              }
            }
            this.isProgressShow = false;
          },
          (err) => {
            this.isProgressShow = false;
          }
        );
    } else if (val?.length <= 2 && this.isShipmentIsVisible) {
      this.initialCount();
    }
  }

  autoSuggesionShipmentCount(res: any) {
    res.result.shipment.statusRecords.forEach((ele: any) => {
      switch (ele.status) {
        case 'Pickup Scheduled':
          this.pickedUpScheduledCount = ele.shipmentcount;
          this.pickedUpScheduledShipmentsId = ele.shipmentIds;
          break;
        case 'Picked up':
          this.pickedUpCount = ele.shipmentcount;
          this.pickedUpShipmentsId = ele.shipmentIds;
          break;
        case 'Scheduled':
          this.scheduledCount = ele.shipmentcount;
          this.scheduledShipmentsId = ele.shipmentIds;
          break;
        case 'In Transit':
          this.inTransitCount = ele.shipmentcount;
          this.inTransitShipmentsId = ele.shipmentIds;
          break;
        case 'Arrived':
          this.arrivedCount = ele.shipmentcount;
          this.arrivedShipmentsId = ele.shipmentIds;
          break;
        case 'Delivery Scheduled':
          this.deliveryScheduledCount = ele.shipmentcount;
          this.deliveryScheduledShipmentsId = ele.shipmentIds;
          break;
        case 'Delivered':
          this.deliveredCount = ele.shipmentcount;
          this.deliveredShipmentsId = ele.shipmentIds;
          break;
      }
    });
  }

  autoSuggesionBookingCount(res: any) {
    res.result.booking.statusRecords.forEach((ele: any) => {
      switch (ele.status) {
        case 'Pending':
          this.pendingCount = ele.shipmentcount;
          this.pendingBookingsId = ele.shipmentIds;
          break;
        case 'Converted':
          this.convertedCount = ele.shipmentcount;
          this.convertedBookingsId = ele.shipmentIds;
          break;
        case 'Canceled':
          this.cancelledCount = ele.shipmentcount;
          this.cancelledBookingsId = ele.shipmentIds;
          break;
        case 'Archived':
          this.archivedCount = ele.shipmentcount;
          this.archivedBookingsId = ele.shipmentIds;
          break;
      }
    });
  }

  autoSuggesionCustomsCount(res: any) {
    res.result.custom.statusRecords.forEach((ele: any) => {
      switch (ele.status) {
        case 'Prepared':
          this.preparedCount = ele.shipmentcount;
          this.preparedCustomsId = ele.shipmentIds;
          break;
        case 'Filed':
          this.filedCount = ele.shipmentcount;
          this.filedCustomsId = ele.shipmentIds;
          break;
        case 'In Progress':
          this.inProgressCount = ele.shipmentcount;
          this.inProgressCustomsId = ele.shipmentIds;
          break;
        case 'Released':
          this.releasedCount = ele.shipmentcount;
          this.releaseCustomsId = ele.shipmentIds;
          break;
        case 'Archived':
          this.archivedCustomsCount = ele.shipmentcount;
          this.archivedCustomsId = ele.shipmentIds;
          break;
      }
    });
  }

  initialCount() {
    this.pickedUpScheduledCount = 0;
    this.pickedUpCount = 0;
    this.scheduledCount = 0;
    this.inTransitCount = 0;
    this.arrivedCount = 0;
    this.deliveryScheduledCount = 0;
    this.deliveredCount = 0;
    this.totalShipmentCount = 0;
    this.totalBookingCount = 0;
    this.totalCustomsCount = 0;
    this.pendingCount = 0;
    this.convertedCount = 0;
    this.cancelledCount = 0;
    this.archivedCount = 0;
    this.preparedCount = 0;
    this.filedCount = 0;
    this.inProgressCount = 0;
    this.releasedCount = 0;
    this.archivedCustomsCount = 0;
  }

  setOffset(e: any) {
    if (e.type === 'shipment') {
      this.shipmentOffset(e);
    } else if (e.type === 'booking') {
      this.bookingOffset(e);
    } else if (e.type === 'customs') {
      this.customsOffset(e);
    }
  }

  shipmentOffset(e: any) {
    this.loadGroupSearchData(e.id, e.type);
  }

  bookingOffset(e: any) {
    this.loadGroupSearchData(e.id, e.type);
  }

  customsOffset(e: any) {
    this.loadGroupSearchData(e.id, e.type);
  }

  clearArray() {
    this.pickedUpScheduled = [];
    this.pickedUp = [];
    this.scheduled = [];
    this.inTransit = [];
    this.arrived = [];
    this.deliveryScheduled = [];
    this.delivered = [];
    this.pending = [];
    this.converted = [];
    this.cancelled = [];
    this.archived = [];
    this.prepared = [];
    this.filed = [];
    this.inProgress = [];
    this.released = [];
    this.archivedCustoms = [];
  }

  setFalseAccordions() {
    this.tabPickupSchedule = false;
    this.tabPickup = false;
    this.tabSchedule = false;
    this.tabTransit = false;
    this.tabArrived = false;
    this.tabDeliverySchedule = false;
    this.tabDelivery = false;
    this.tabPending = false;
    this.tabConverted = false;
    this.tabCancelled = false;
    this.tabArchived = false;
    this.tabPrepared = false;
    this.tabFiled = false;
    this.tabInProgress = false;
    this.tabReleased = false;
    this.tabArchivedCustoms = false;
  }

  pickupScheduleAccordian() {
    this.tabPickupSchedule = !this.tabPickupSchedule;
  }

  pickupAccordian() {
    this.tabPickup = !this.tabPickup;
  }

  scheduleAccordian() {
    this.tabSchedule = !this.tabSchedule;
  }

  inTransitAccordian() {
    this.tabTransit = !this.tabTransit;
  }

  arrivedAccordian() {
    this.tabArrived = !this.tabArrived;
  }

  deliveryScheduedAccordian() {
    this.tabDeliverySchedule = !this.tabDeliverySchedule;
  }

  deliveryAccordian() {
    this.tabDelivery = !this.tabDelivery;
  }

  pendingAccordian() {
    this.tabPending = !this.tabPending;
  }

  convertedAccordian() {
    this.tabConverted = !this.tabConverted;
  }

  cancelledAccordian() {
    this.tabCancelled = !this.tabCancelled;
  }

  archivedAccordian() {
    this.tabArchived = !this.tabArchived;
  }

  preparedAccordian() {
    this.tabPrepared = !this.tabPrepared;
  }

  filedAccordian() {
    this.tabFiled = !this.tabFiled;
  }

  inProgressAccordian() {
    this.tabInProgress = !this.tabInProgress;
  }

  releasedAccordian() {
    this.tabReleased = !this.tabReleased;
  }

  archivedCustomsAccordian() {
    this.tabArchivedCustoms = !this.tabArchivedCustoms;
  }

  prepareShipmentShare(event: any) {
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: {},
        selectedAccount: [],
      },
      destination: {
        selectedValue: {},
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: {},
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: {},
        selectedAccount: [],
      },
      consignee: {
        selectedValue: {},
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: {} },
          { destinationValue: {} },
          { pickupValue: {} },
          { finalDeliveryValue: {} },
        ],
      },
      sorting: [],
      accountDetail: {
        aliasName: this.currentAccountData.accountAliasName,
        oH_Code: this.currentAccountData.accountId,
        byGroup: this.currentAccountData.isGroup,
        shipment: { isVisible: this.currentAccountData.shipmentIsVisible },
        booking: { isVisible: this.currentAccountData.bookingIsVisible },
        customs: { isVisible: this.currentAccountData.customsIsVisible },
        isControllingCustomer: this.currentAccountData.isParent,
      },
      shipmentId: event.shipmentId,
      type: 'Detail',
      subType: '',
      module: 'Shipment',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    //this.url = environment.localUrl + '/shipments/' + encry;
    //this.url = environment.localUrl + '/' + event.shipmentId;
    this.url = environment.localUrl + '/' + event?.shipment?.encryptedId;
  }

  prepareShareUrlBooking(event: any) {
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      watchFilter: [],
      origin: {
        selectedValue: [],
        selectedAccount: [],
      },
      destination: {
        selectedValue: [],
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },

      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: [] },
          { destinationValue: [] },
          { pickupValue: [] },
          { finalDeliveryValue: [] },
        ],
      },
      sorting: [],
      accountDetail: {
        aliasName: this.currentAccountData.accountAliasName,
        oH_Code: this.currentAccountData.accountId,
        byGroup: this.currentAccountData.isGroup,
        shipment: { isVisible: this.currentAccountData.shipmentIsVisible },
        booking: { isVisible: this.currentAccountData.bookingIsVisible },
        customs: { isVisible: this.currentAccountData.customsIsVisible },
        isControllingCustomer: this.currentAccountData.isParent,
      },
      bookingId: event.bookingId,
      type: 'Detail',
      module: 'Booking',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    //this.url = environment.localUrl + '/bookings/' + encry;
    //this.url = environment.localUrl + '/' + event.bookingId;
    this.url = environment.localUrl + '/' + event?.shipment?.encryptedId;
  }

  prepareShareUrlCustoms(event: any) {
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      customsType: [],
      watchFilter: [],
      exceptionType: [],
      pgaFilter: [],
      dateRange: {
        selectedValue: [],
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: [],
        selectedAccount: [],
      },
      consignee: {
        selectedValue: [],
        selectedAccount: [],
      },
      importer: {
        selectedValue: [],
        selectedAccount: [],
      },
      importerOfRecord: {
        selectedValue: [],
        selectedAccount: [],
      },
      supplier: {
        selectedValue: [],
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { loadingPort: [] },
          { dischargePort: [] },
          { shipmentOrigin: [] },
          { shipmentDestination: [] },
          { portOfClearance: [] },
        ],
        selectedValue: [
          { loadingPortValue: [] },
          { dischargePortValue: [] },
          { shipmentOriginValue: [] },
          { shipmentDestinationValue: [] },
          { portOfClearanceValue: [] },
        ],
      },
      sorting: [],
      accountDetail: {
        aliasName: this.currentAccountData.accountAliasName,
        oH_Code: this.currentAccountData.accountId,
        byGroup: this.currentAccountData.isGroup,
        shipment: { isVisible: this.currentAccountData.shipmentIsVisible },
        booking: { isVisible: this.currentAccountData.bookingIsVisible },
        customs: { isVisible: this.currentAccountData.customsIsVisible },
        isControllingCustomer: this.currentAccountData.isParent,
      },
      customsId: event.customsId,
      type: 'Detail',
      module: 'Customs',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    //this.url = environment.localUrl + '/customs/' + encry;
    //this.url = environment.localUrl + '/' + event.customsId;
    this.url = environment.localUrl + '/' + event?.shipment?.encryptedId;
  }

  showTab(type: any) {
    if (type == 'shipment') {
      this.isShipmentTab = true;
      this.isBookingTab = false;
      this.isCustomsTab = false;
    } else if (type == 'booking') {
      this.isShipmentTab = false;
      this.isBookingTab = true;
      this.isCustomsTab = false;
    } else if (type == 'customs') {
      this.isShipmentTab = false;
      this.isBookingTab = false;
      this.isCustomsTab = true;
    }
  }

  public apiCancel() {
    if (this.apiCall != null) {
      this.apiCall.unsubscribe();
      this.shipmentService.stopRequest.next();
    }
  }

  progressStatus(status: any, progress: boolean, type: any) {
    if (this.isShipmentIsVisible && type == Cargo.shipments) {
      this.progressStatusShipment(status, progress);
    } else if (this.isBookingIsVisible && type == Cargo.bookings) {
      this.progressStatusBooking(status, progress);
    } else if (this.isCustomsIsVisible && type == Cargo.customs) {
      this.progressStatusCustoms(status, progress);
    }
  }

  progressStatusShipment(status: any, progress: any) {
    if (status == 1) {
      this.isProgressPickedUpScheduled = progress;
    } else if (status == 2) {
      this.isProgressPickedUp = progress;
    } else if (status == 3) {
      this.isProgressScheduled = progress;
    } else if (status == 4) {
      this.isProgressInTransit = progress;
    } else if (status == 5) {
      this.isProgressArrived = progress;
    } else if (status == 6) {
      this.isProgressDeliveryScheduled = progress;
    } else if (status == 7) {
      this.isProgressDelivered = progress;
    }
  }

  progressStatusBooking(status: any, progress: any) {
    if (status == 1) {
      this.isProgressPending = progress;
    } else if (status == 2) {
      this.isProgressConverted = progress;
    } else if (status == 3) {
      this.isProgressCancelled = progress;
    } else if (status == 4) {
      this.isProgressArchived = progress;
    }
  }

  progressStatusCustoms(status: any, progress: any) {
    if (status == 1) {
      this.isProgressPrepared = progress;
    } else if (status == 2) {
      this.isProgressFiled = progress;
    } else if (status == 3) {
      this.isProgressInProgress = progress;
    } else if (status == 4) {
      this.isProgressReleased = progress;
    } else if (status == 5) {
      this.isProgressArchivedCustoms = progress;
    }
  }

  clearSearch() {
    this.shipmentSearchText = '';
    this.initialCount();
    this.clearTrack();
  }

  clearTrack() {
    this.trackOfApiCalls = [];
  }

}

enum Cargo {
  shipments = "shipment",
  bookings = "booking",
  customs = "customs",
}
