import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-shipment-customs-tab',
  templateUrl: './shipment-customs-tab.component.html',
  styleUrls: ['./shipment-customs-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentCustomsTabComponent implements OnInit {
  @Input() selectedShipment: any = [];
  @Output() setCustomDetails: any = new EventEmitter<any>();

  readMoreNotes: boolean = false;
  readMoreBookRef: boolean = false;
  defaultDisplayText: string = '---';

  constructor(private appInsightsService: AppInsightsService) {}

  ngOnInit(): void {
    this.appInsightsService.logEvent(
      AI_CommonEvents.CustomsTabViewed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
  }

  ngOnChanges(): void {
    this.readMoreBookRef =
      this.selectedShipment?.booking_reference?.length > 60;
    if (this.selectedShipment?.notes && this.selectedShipment?.notes[0]) {
      this.readMoreNotes = this.selectedShipment.notes[0].body?.length > 180;
    }
  }

  getGrossWeight(selectedShipment: any) {
    let message = '';
    if (selectedShipment?.gross_weight_kgs) {
      message = selectedShipment.gross_weight_kgs + ' Kgs';
    } else if (selectedShipment?.gross_weight_lbs) {
      message = selectedShipment.gross_weight_lbs + ' Lbs';
    } else {
      message = this.defaultDisplayText;
    }
    return message;
  }

  getYearFormattedDate(date: any) {
    return CommonUtils.formatDateMMMDDYYYY(date);
  }

  getDateFormated(date: any) {
    var message = '---';
    if (date) {
      message = CommonUtils.formatDateMMMDDYYYY(date);
    }
    return message;
  }

  checkSFile(id: any) {
    if (id.charAt(0).toLowerCase() === 's') {
      return true;
    } else {
      return false;
    }
  }

  checkHBL(data: any) {
    if (this.checkSFile(data.forwarder_reference)) {
      return data.customs_declarations[0]?.house_bill;
    } else {
      return data.house_bill;
    }
  }

  openCustomsDetails() {
    this.setCustomDetails.emit({
      customsId: this.selectedShipment?.forwarder_reference,
      type: 'list',
    });
  }
}
