<div class="container-fluid" [ngClass]="{
    'guided-back-drop': isCustomFilterSecHide,
    'guided-visible-sec': isCustomFilterSecShow
  }">
  <div class="row d-flex align-items-start mobiletop-pad new-ship-custom-filters">
    <div class="col-lg-9 col-md-8 shipment-flex-wrap">
      <div class="shipment-search-wdth">
        <div class="shipment-title-mobile">Shipments</div>
        <div class="d-flex position-relative" id="shipmentSearchBox" [ngClass]="{
            'guided-back-drop': isSearchBoxHide,
            'guided-visible-sec': isSearchBoxShow
          }">
          <input type="text" [(ngModel)]="shipmentSearchText" class="form-control search-input"
            placeholder="Search by shipment details" (keyup.backspace)="clearSearchText(shipmentSearchText)"
            (keyup.enter)="clickEvent.next($event)" (blur)="clickEvent.next($event)" #searchTextbox />
          <span class="form-control-feedback search-icon">
            <img src="../../assets/img/close_whitebg_remove.svg" alt="close-icon" *ngIf="shipmentSearchText.length >0"
              (click)="searchTextbox.value='';clearSearchText(searchTextbox.value)">
            <img src="../../assets/img/search.svg" alt="Search" (click)="clickEvent.next($event)" /></span>
        </div>
      </div>
      <div class="filter-overflow">
        <button #customDropdownBtn class="btn btn-group filter-btn" (click)="openMobileFilter()" type="button"
          [ngClass]="{
            'guided-back-drop': isFilterHide,
            'guided-visible-sec button-click': isFilterShow
          }" id="MainDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="filter-btn-text">Custom Filter</span>
          <div class="tool-set">
            <span>Click to create and save custom filters for quick access</span>
          </div>
        </button>
        <div id="dropdownMenuButton" class="dropdown-menu mobile-hide" aria-labelledby="dropdownMenuButton">
          <ul class="custom-drop-menu">
            <li class="dropdown-item" *ngFor="let filter of filterItem; let index = index">
              <input [(ngModel)]="filter.checked" class="test-selection" type="checkbox" name="filter-{{ filter.filt }}"
                value="{{ filter.val }}" id="MainFilterId-{{ filter.val }}" (change)="
                  isFilterSelected(
                    filter.checked,
                    filter.val,
                    filter.filt,
                    filter.col_name,
                    index
                  )
                " />
              <label class="selection-list" for="MainFilterId-{{ filter.val }}">
                <span class="header-set">{{ filter.filt }}</span>
              </label>
            </li>
          </ul>
        </div>

        <div class="static-menu-filter" *ngIf="guidedTourFilter">
          <ul class="custom-drop-menu newset">
            <li class="dropdown-item" *ngFor="let filter of filterItemset">
              <input type="checkbox" />
              <label class="selection-list">
                <span class="header-set">{{ filter.filt }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <!-- mobile filter design -->
      <ng-container *ngIf="isMobileViewParent">
        <div class="mobile-filter-container" *ngIf="mobileFilterContainer">
          <div class="filter-header">
            <div class="close-filter" (click)="closeFilter()">
              <img src="../../assets/img/close-popup.svg" alt="closepopup" />
            </div>
            <span>Filters</span>
            <span (click)="clearAllFilter()">Clear All</span>
          </div>
          <div class="accordion filter-accordian">
            <div class="accordion accordion-flush" id="Savefilter01">
              <div class="accordion-item">
                <h2 class="accordion-header" id="Savefilter01">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Savefilter" aria-expanded="false" aria-controls="flush-collapseThree">
                    My Saved Filters
                  </button>
                </h2>
                <div id="Savefilter" class="accordion-collapse collapse" aria-labelledby="Savefilter01"
                  data-bs-parent="#Savefilter01">
                  <div class="accordion-body new-set">
                    <ul class="custom-drop-menu mb-0 pb-4" *ngIf="savedFilters.length > 0">
                      <li class="dropdown-item add-new-set" *ngFor="let userFilter of savedFilters; let i = index">
                        <span class="rap-radio">
                          <input type="radio" name="userFilter" (change)="selectedUserFilter(userFilter)"
                            id="userFilter{{ i }}" value="{{ userFilter.id }}" [checked]="
                              mobileSelectedFilter == userFilter.id
                                ? true
                                : false
                            " />
                          <label for="userFilter{{ i }}">{{
                            userFilter.filterName
                            }}</label>
                        </span>
                      </li>
                    </ul>
                    <ul *ngIf="savedFilters.length < 1">
                      <li class="dropdown-item">No Filters</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" [ngClass]="{ activeFilters: statusFilterValue }"
                    type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" id="mobileStatusFilter"
                    aria-expanded="false" aria-controls="flush-collapseOne" (click)="
                      filterIsOpen(
                        '#mobileStatusFilter',
                        true,
                        1,
                        'Status',
                        'statuscustom',
                        1
                      )
                    ">
                    Status
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                  data-bs-parent="#filterAccordian">
                  <ng-container>
                    <app-common-dropdown-filters [filterType]="mobileStatusFilter"
                      (closeSelectedFilterControl)="closeCustomFilters($event)"
                      (selectedFiltersArray)="getSelectedFilterArray($event)">
                    </app-common-dropdown-filters>
                  </ng-container>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" id="mobileExceptionFilter" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
                    aria-controls="flush-collapseTwo" [ngClass]="{ activeFilters: exceptionFilterValue }" (click)="
                      filterIsOpen(
                        '#mobileExceptionFilter',
                        true,
                        6,
                        'Exception Type',
                        'exception',
                        6
                      )
                    ">
                    Exception Type
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#filterAccordian">
                  <app-common-dropdown-filters [filterType]="mobileExceptionFilter"
                    (closeSelectedFilterControl)="closeCustomFilters($event)"
                    (selectedFiltersArray)="getSelectedFilterArray($event)">
                  </app-common-dropdown-filters>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#DateRange" [ngClass]="{ activeFilters: dateFilterValue }" aria-expanded="false"
                    aria-controls="flush-collapseThree">
                    Date Range
                  </button>
                </h2>
                <div id="DateRange" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <app-daterange-filter [filterType]="mobileDateRangeFilter"
                    [selectedDateRangeRender]="selectedDateRangeRender"
                    (closeSelectedFilterControl)="closeCustomFilters($event)" (dateRangeFilter)="getDateRange($event)">
                  </app-daterange-filter>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button id="mobileTransportFilter" class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false"
                    aria-controls="flush-collapseThree" [ngClass]="{ activeFilters: transportFilterValue }" (click)="
                      filterIsOpen(
                        '#mobileTransportFilter',
                        true,
                        0,
                        'Transport Type',
                        'transport',
                        0
                      )
                    ">
                    Transport Type
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <ng-container>
                    <app-common-dropdown-filters [filterType]="mobileTransportFilter"
                      (closeSelectedFilterControl)="closeCustomFilters($event)"
                      (selectedFiltersArray)="getSelectedFilterArray($event)">
                    </app-common-dropdown-filters>
                  </ng-container>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordianLocation">
                  <button id="mobileLocationFilter" class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false"
                    aria-controls="flush-collapseTen" [ngClass]="{ activeFilters: locationFilterValue }" (click)="
                      filterIsOpen(
                        '#mobileLocationFilter',
                        true,
                        4,
                        'Location Type',
                        'location',
                        4
                      )
                    ">
                    Location
                  </button>
                </h2>
                <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordianLocation">
                  <app-all-location-filter (emitOffsetLoc)="offsetLocation($event)" [listData]="mobileAllLocationFilter"
                    (closeSelectedFilterControl)="closeCustomFilters($event)" [resentList]="resentAllLocationList"
                    (alllocationFilter)="locationSelectedFilter($event)"
                    (locationFilterSearch)="getLocationList($event)"
                    (locationFilterValueEmit)="loadLocationFilter($event)" [isProgress]="isProgressLocation"
                    [allLocationReselected]="allLocationReselectedList"
                    (locationFilterClear)="clearFilterSearch($event)" [offsetOrigin]="offsetOrgin"
                    [offsetDestination]="offsetDestination" [offsetPickup]="offsetPickup"
                    [offsetDelivery]="offsetFinalDelivery" [allLocationList]="allLocationList">
                  </app-all-location-filter>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button id="mobileShipperFilter" class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#mobileShipperFilterId"
                    [ngClass]="{ activeFilters: shipperFilterValue }" (click)="
                      filterIsOpen(
                        '#mobileShipperFilter',
                        true,
                        2,
                        'Shipper',
                        'shipper',
                        2
                      )
                    " aria-expanded="false" aria-controls="flush-collapseThree">
                    Shipper
                  </button>
                </h2>
                <div id="mobileShipperFilterId" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <app-common-shipper-type-filter (locationFilterSearch)="getLocationList($event)"
                    [mobileFilterReselectValue]="mobileShipperFilterReselect" (emitOffsetLoc)="offsetLocation($event)"
                    [locationList]="shipperList" [resentList]="resentListShipper"
                    (locationFilterClear)="clearFilterSearch($event)" (locationFilter)="locationSelectedFilter($event)"
                    (closeSelectedFilterControl)="closeCustomFilters($event)" [listData]="mobileShipperFilter"
                    [offset]="getFilterOffset(2)" [isProgress]="isProgressLocation"></app-common-shipper-type-filter>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button id="mobileConsigneeFilter" class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" [ngClass]="{ activeFilters: consigneeFilterValue }"
                    data-bs-target="#mobileConsigneeId" (click)="
                      filterIsOpen(
                        '#mobileConsigneeFilter',
                        true,
                        3,
                        'Consignee',
                        'consignee',
                        3
                      )
                    " aria-expanded="false" aria-controls="flush-collapseThree">
                    Consignee
                  </button>
                </h2>
                <div id="mobileConsigneeId" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <app-common-shipper-type-filter (locationFilterSearch)="getLocationList($event)"
                    (emitOffsetLoc)="offsetLocation($event)" [locationList]="consigneeList"
                    [mobileFilterReselectValue]="mobileConsigneeFilterReselect" [resentList]="resentListConsignee"
                    (locationFilterClear)="clearFilterSearch($event)" (locationFilter)="locationSelectedFilter($event)"
                    (closeSelectedFilterControl)="closeCustomFilters($event)" [listData]="mobileConsigneeFilter"
                    [offset]="getFilterOffset(3)" [isProgress]="isProgressLocation">
                  </app-common-shipper-type-filter>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="filterAccordian">
                  <button class="accordion-button collapsed" type="button" [value]="watchlistFilter"
                    data-bs-toggle="collapse" [ngClass]="{ activeFilters: favoritesFilterValue }"
                    data-bs-target="#Watchlist" aria-expanded="false" aria-controls="flush-collapseThree">
                    Watchlist
                  </button>
                </h2>
                <div id="Watchlist" class="accordion-collapse collapse" aria-labelledby="filterAccordian"
                  data-bs-parent="#filterAccordian">
                  <div class="accordion-body new-set">
                    <ul class="custom-drop-menu">
                      <li class="dropdown-item">
                        <input type="checkbox" id="checkList" [checked]="checkWatchlist"
                          (change)="watchlistSelected($event)" />
                        <label class="selection-list" for="checkList">
                          <span class="header-set">Watchlist</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="showall-filter">
              <button class="btn btn-yellow" (click)="closeFilter()">
                SHOW
                <span *ngIf="paramCheck?.filter_options?.length < 1">ALL</span><span
                  *ngIf="paramCheck?.filter_options?.length > 0">{{
                  resultCount
                  }}</span>
                SHIPMENTS
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- mobile filter design end -->
      <div class="custom-results-ctr">
        <ng-container *ngIf="!isMobileViewParent">
          <div class="d-inline-block mt-2 mr-2" *ngFor="let row of selectedMainFilter">
            <ng-container *ngIf="row.id == 0 || row.id == 1 || row.id == 6 || row.id == 9">
              <app-common-dropdown-filters [filterType]="row"
                (closeSelectedFilterControl)="closeCustomFilters($event, true)"
                (selectedFiltersArray)="getSelectedFilterArray($event)">
              </app-common-dropdown-filters>
            </ng-container>

            <ng-container *ngIf="row.id == 5">
              <app-daterange-filter [filterType]="row" (closeSelectedFilterControl)="closeCustomFilters($event, true)"
                (dateRangeFilter)="getDateRange($event)"></app-daterange-filter>
            </ng-container>

            <ng-container *ngIf="row.id == 2 || row.id == 3 || row.id == 8">
              <app-common-shipper-type-filter (locationFilterSearch)="getLocationList($event)"
                (emitOffsetLoc)="offsetLocation($event)" [locationList]="currentShipperTypeFilter(row.id)"
                [resentList]="currentShippertTypeFilerRecent(row.id)" (locationFilterClear)="clearFilterSearch($event)"
                (locationFilter)="locationSelectedFilter($event)"
                (closeSelectedFilterControl)="closeCustomFilters($event, true)" [listData]="row"
                [offset]="getFilterOffset(row.id)" [isProgress]="isProgressLocation">
              </app-common-shipper-type-filter>
            </ng-container>
            <ng-container *ngIf="row.id == 4">
              <app-all-location-filter (emitOffsetLoc)="offsetLocation($event)" [listData]="row"
                [isProgress]="isProgressLocation" (closeSelectedFilterControl)="closeCustomFilters($event, true)"
                [resentList]='resentAllLocationList' (alllocationFilter)="locationSelectedFilter($event)"
                (locationFilterSearch)="getLocationList($event)" (locationFilterValueEmit)='loadLocationFilter($event)'
                (locationFilterClear)="clearFilterSearch($event)" [offsetOrigin]="offsetOrgin"
                [offsetDestination]="offsetDestination" [offsetPickup]="offsetPickup"
                [offsetDelivery]="offsetFinalDelivery" [allLocationList]='allLocationList'></app-all-location-filter>
            </ng-container>
            <ng-container *ngIf="row.id == 7">
              <div class="inbox-set m-0">
                <input type="text" readonly class="form-control border-blue" name="search" [value]="watchlistFilter"
                  autocomplete="off" />
                <span class="update-fix"><img src="../../../../../assets/img/close-sm.png" class="close-sm" alt=""
                    (click)="closeWatchFilter(true)" /></span>
              </div>
            </ng-container>

            <div id="watchListId"></div>
          </div>
        </ng-container>
        <span class="rap-save">
          <button type="button" class="btn-save-as" *ngIf="isShowSaveBtn"
            (click)="saveButtonClicked(isfilterEdit?.savedValue?.filterType, false)">
            Save
          </button>
          <button type="button" class="btn-save-as" *ngIf="isShowSaveAsBtn" (click)="openSaveFilterPopUp()">
            Save As
          </button>
        </span>
      </div>
    </div>

    <div class="col-lg-3 col-md-4 col-12 d-lg-flex justify-content-lg-end p-0" id="sortRapper">
      <!-- Advanced Filter Button -->

      <div id="adv-open-popup-btn" class="pointer advance-filters remove-mobile-display" [ngClass]="{      
          'advance-filters-selected': advancedFilterAdded?.length > 0 || isAdvanceFilterSelected
        }" data-toggle="modal" (click)="openAdvanceFilterModal()">
        Advanced Filter
      </div>
      <div class="sortby-wrap" style="display: none">
        <span id="sortSection" [ngClass]="{
            'guided-back-drop': isSortHide,
            'guided-visible-sec': isSortShow
          }">
          <div class="child">Sort By:</div>
          <div class="btn-group dropdown child filterDropdown">
            <button type="button" id="sortButtonId" class="btn shipment-drop-btn dropdown-toggle mx-2 statusdrop"
              data-toggle="dropdown" aria-expanded="false">
              <span class="shipment-drop-text">
                <img *ngIf="defaultSortDirection === 'desc'" alt="desc"
                  src="../../../../../assets/img/shipments/shipment-sort-icon.svg" class="mr-1" />
                <img *ngIf="defaultSortDirection === 'asc'" alt="asc"
                  src="../../../../../assets/img/shipments/shipment-sort-icon.svg" class="mr-1 update" />
                {{ defaultSortSelectedColumn }}</span>
            </button>
            <div class="dropdown-menu restrict-close" aria-labelledby="dropdownMenuButton">
              <ul class="custom-drop-menu">
                <li class="dropdown-item pointer" [ngClass]="{
                    active: defaultSortSelectedValue == sortOption.id
                  }" *ngFor="let sortOption of sortOptions" (click)="clickSortOption.next(sortOption)">
                  <span class="shipment-drop-text">
                    <img *ngIf="
                        defaultSortSelectedValue == sortOption.id &&
                        defaultSortDirection === 'desc'
                      " alt="desc" src="../../../../../assets/img/shipments/shipment-sort-icon.svg" class="mr-1" />
                    <img *ngIf="
                        defaultSortSelectedValue == sortOption.id &&
                        defaultSortDirection === 'asc'
                      " alt="asc" src="../../../../../assets/img/shipments/shipment-sort-icon.svg"
                      class="mr-1 update" />
                    <span *ngIf="defaultSortSelectedValue != sortOption.id"></span>{{ sortOption.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </span>
        <div class="upload-btn child" (click)="share($event)" id="shareSection" [ngClass]="{
            'guided-back-drop': isShareHide,
            'guided-visible-sec': isShareShow
          }">
          <img src="../../assets/img/upload.svg" class="upload-icon" alt="upload" />
        </div>
      </div>
    </div>
    <app-advanced-filter-tab [tableViewColumnHeaderList]="tableViewColumnHeaderList"
      [showAdvancedFilterPopUp]="showAdvancedFilterPopUp" [advancedFilterAdded]="advancedFilterAdded"
      (advancedFilter)="getAdvancedFilterParam($event)" (callSaveAsEmitter)="openSaveAdvFilterPopUp($event)"
      (popupCommon)="popupShow($event)" [isfilterEdit]="isfilterEdit" (callSaveEmitter)="callOnAdvFilterUpdate($event)"
      [showAdvSaveMessage]="showAdvSaveMessage" clickOutside
      (clickOutside)="closeAdvFilterForm($event)"></app-advanced-filter-tab>
  </div>
  <div class="clear-filter pointer" *ngIf="showClearAllButton" (click)="clearAllRibbonFilters(true)">
    {{advancedFilterAdded?.length > 0? advFiltername.trim():'Clear Filters'}}
    <img src="../../assets/img/clear-filter.svg" alt="clear-filter" class="ml-1" />
  </div>
  <div class="new-shipment-top-row-ctr-cntrl remove-mobile-display">
    <div class="new-shipment-top-row-ctr">
      <div class="new-shipment-top-row">
        <div class="new-shipment-top-menu" clickOutside (clickOutside)="offModeDropDown()">
          <div class="new-shipment-top-menu-inner" [class.selected-toggleList]="filterModeRibbonValue?.length > 0"
            [class.menu-active]="showModeDropDown == true" (click)="toggleTransportRibbonMode()">
            <img src="../../../../../assets/img/mode.svg" alt="mode icon" />
            <span>Mode</span>
            <div class="ship-hover-text" [class.incre-width]="this.filterModeRibbonText.length > 3">
              <span class="show-text">{{
                displayModeTooltipText == "" ? "Show All" : "Filtered by :"
                }}
                <i>{{ displayModeTooltipText }}</i>
              </span>
            </div>
          </div>
          <div class="menu-dropdown-ctr" *ngIf="showModeDropDown">
            <div class="menu-dropdown">
              <div class="menu-dropdown-hdg">
                <div class="menu-dropdown-hdg-innr">
                  <img src="../../../../../assets/img/filter-mode.svg" alt="filter" />
                  <span>Filter by</span>
                </div>
              </div>
              <ul>
                <li (click)="filterMode(1, 'Sea')" [class.selected-mode]="filterModeRibbonValue.includes(1)">
                  <input type="checkbox" [checked]="filterModeRibbonValue.includes(1)" />
                  <label class="m-0 pt-1">
                    <img src="../../../../../assets/img/sea.svg" alt="sea" />
                    <span>Sea</span>
                  </label>
                </li>
                <li (click)="filterMode(2, 'Air')" [class.selected-mode]="filterModeRibbonValue.includes(2)">
                  <input type="checkbox" [checked]="filterModeRibbonValue.includes(2)" />
                  <label class="m-0 pt-1">
                    <img src="../../../../../assets/img/air.svg" alt="air" />
                    <span>Air</span>
                  </label>
                </li>
                <li (click)="filterMode(3, 'Road')" [class.selected-mode]="filterModeRibbonValue.includes(3)">
                  <input type="checkbox" [checked]="filterModeRibbonValue.includes(3)" />
                  <label class="m-0 pt-1">
                    <img src="../../../../../assets/img/road.svg" alt="road" />
                    <span>Road</span>
                  </label>
                </li>
                <li (click)="filterMode(4, 'Rail')" [class.selected-mode]="filterModeRibbonValue.includes(4)">
                  <input type="checkbox" [checked]="filterModeRibbonValue.includes(4)" />
                  <label class="m-0 pt-1">
                    <img src="../../../../../assets/img/train.svg" alt="train" />
                    <span>Rail</span>
                  </label>
                </li>
                <li (click)="filterMode(5, 'Courier')" [class.selected-mode]="filterModeRibbonValue.includes(5)">
                  <input type="checkbox" [checked]="filterModeRibbonValue.includes(5)" />
                  <label class="m-0 pt-1">
                    <img src="../../../../../assets/img/courier.svg" alt="courier" />
                    <span>Courier</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="new-shipment-top-menu" (click)="toggleWatchList()">
          <div class="new-shipment-top-menu-inner" [class.selected-toggleList]="showWatchList">
            <img src="../../../../../assets/img/watchlist.svg" alt="watchlist icon" />
            <span>Watchlist</span>
            <div class="ship-hover-text wa">
              <span class="show-text">{{
                showWatchList ? "Show All Shipments" : "Show Watchlist Shipments"
                }}</span>
            </div>
          </div>
        </div>
        <div class="new-shipment-top-menu" clickOutside (clickOutside)="showSortDropDown = false">
          <div class="new-shipment-top-menu-inner" [class.menu-active]="showSortDropDown == true"
            (click)="showSortDropDown = !showSortDropDown">
            <img src="../../../../../assets/img/sortby.svg" alt="sort icon" style="width: 22px" />
            <span>Sort</span>
            <div class="ship-hover-text">
              <span class="show-text">{{
                displaySortTooltipText == "" ? "Show All" : "Sorted by :"
                }}
                <i>{{ displaySortTooltipText }}</i></span>
            </div>
          </div>
          <div class="menu-dropdown-ctr" *ngIf="showSortDropDown">
            <div class="menu-dropdown">
              <div class="menu-dropdown-hdg">
                <div class="menu-dropdown-hdg-innr">
                  <img src="../../../../../assets/img/sort.svg" alt="sort" />
                  <span>Sort by</span>
                </div>
              </div>
              <ul class="sort-ctrl">
                <li class="dropdown-item pointer" [ngClass]="{
                    'sort-active': defaultSortSelectedValue == sortOption.id
                  }" *ngFor="let sortOption of sortOptions" (click)="clickSortOption.next(sortOption)">
                  <span class="shipment-drop-text">
                    <img *ngIf="
                        defaultSortSelectedValue == sortOption.id &&
                        defaultSortDirection === 'desc'
                      " alt="desc" src="../../../../../assets/img/sort-onclick-list.svg" class="mr-1" />
                    <img *ngIf="
                        defaultSortSelectedValue == sortOption.id &&
                        defaultSortDirection === 'asc'
                      " alt="asc" src="../../../../../assets/img/sort-onclick-list.svg" class="mr-1 update" />
                    <span *ngIf="defaultSortSelectedValue != sortOption.id"></span>{{ sortOption.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="new-shipment-top-menu" (click)="togglePickupAndDelivery()">
          <div class="new-shipment-top-menu-inner" [class.selected-toggleList]="showPickupAndDelivery">
            <img src="../../../../../assets/img/pickup-delivery.svg" alt="pickup and delivery icon" />
            <span>Pickup & Delivery</span>
            <div class="ship-hover-text pick-up-ctrl">
              <span class="show-text">{{
                showPickupAndDelivery ? "Show Pickup & Delivery":"Hide Pickup & Delivery"
                }}</span>
            </div>
          </div>
        </div>
        <div class="new-shipment-top-menu" clickOutside (clickOutside)="showShareDropDown = false">
          <div class="new-shipment-top-menu-inner" [class.menu-active]="showShareDropDown == true"
            (click)="showShareDropDown = !showShareDropDown; newShare($event)">
            <img src="../../../../../assets/img/share.svg" alt="share icon" />
            <span>Share</span>
          </div>
          <div class="menu-dropdown-ctr share-new-popover" *ngIf="showShareDropDown">
            <div class="menu-dropdown">
              <div class="head-info">
                <img src="../../assets/img/new-share-link.svg" class="url-img" alt="share" />
                <p>
                  Anyone with a Connect account and access to this company will
                  be able to view this list.
                </p>
              </div>
              <div class="footer">
                <textarea id="shareUrl" readonly>{{ urlShare }}</textarea>
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
        </div>
        <div class="new-shipment-top-menu" clickOutside (clickOutside)="showDownloadDropDown = false">
          <div class="new-shipment-top-menu-inner" [class.menu-active]="showDownloadDropDown == true"
            (click)="showDownloadDropDown = !showDownloadDropDown">
            <img src="../../../../../assets/img/download.svg" alt="download icon" />
            <span>Download</span>
          </div>
          <div class="menu-dropdown-ctr share-new-popover download-set" *ngIf="showDownloadDropDown">
            <div class="menu-dropdown">
              <div class="head-info">
                <img src="../../../../../assets/img/download.svg" class="download-image" alt="share" />
                <p class="m-0">
                  Download all shipments with the current filters that are
                  applied to the list.
                </p>
              </div>
              <div class="footer">
                <button class="add-type download-btn" (click)="excelExport('download')">
                  DOWNLOAD AS EXCEL
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="new-shipment-top-menu">
          <div class="switches-container">
            <input type="radio" (click)="changeTableOrStandardView(false)" id="switchStandard" [checked]="!isTableView"
              name="switch-view" />
            <input type="radio" (click)="changeTableOrStandardView(true)" id="switchTable" [checked]="isTableView"
              name="switch-view" />
            <label for="switchStandard">Standard</label>
            <label for="switchTable">Table</label>
            <div class="switch-wrapper">
              <div class="switch">
                <div>Standard</div>
                <div>Table</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="pop-up4" *ngIf="createUserFilterPopUp" class="dimScreen" [ngClass]="{
    'sys-defined-admin': currentUserRole == 'systemadmin',
    'sys-defined-user': currentUserRole == 'user'
  }">
  <div class="add-user-popup new-filter" [class.add-group-sec]="isGroup">
    <button class="close" type="button" (click)="cancelPopuCheck()">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
    </button>
    <div class="body-section fix-size new-fltr-name-ctr">
      <h3>New Filter Name</h3>
      <div class="content-sec">
        <p *ngIf="myFilterError && myFilterName.length > 0" class="error-set red-set">
          A filter with this name already exists.
        </p>
        <p *ngIf="myFilterNameEmpty && myFilterName.length < 1" class="error-set red-set">
          Please enter the filter name.
        </p>
        <p *ngIf="myFilterName.length > 29" class="error-set red-set">
          Filter name reached maximum 30 characters allowed.
        </p>
        <label>Filter Name<span class="red-set">*</span></label>
        <input type="text" [(ngModel)]="myFilterName" placeholder="Enter Filter Name" class="form-control"
          maxlength="30" (keyup.enter)="saveMyFilter()" />
        <ng-container *ngIf="currentUserRole === 'systemadmin'">
          <!-- && !isGroup-->
          <div class="checkbox-ctr chck-ctrl-action pt-3">
            <div class="chck-bx-ctr" style="top: 0">
              <input type="checkbox" id="filterSystemDefinedSave" [(ngModel)]="filterSystemDefined"
                class="con-base-element ng-touched" (ngModelChange)="showIsSysDefinedPopupFn()" /><label
                for="filterSystemDefinedSave" class="chck-box-lbl"><span class="header-set">System
                  Defined</span></label>
            </div>
          </div>
          <div class="col-lg-12 form-group pl-0 search-container" *ngIf="filterSystemDefined && !isGroup">
            <label>Add Users:</label>
            <div class="ng-autocomplete">
              <ng-autocomplete #auto [data]="userAutoComplete" [searchKeyword]="keyword"
                placeholder="Start typing user's name" (selected)="selectEvent($event)" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" [(ngModel)]="searchFilter" (inputCleared)="blurEvent($event)"
                (inputChanged)="autoCompleteOnKeyUp(searchFilter)" (keyup.enter)="clickEvent.next(searchFilter)"
                [debounceTime]="600" class="custom-style" id="accountAutoComplete" ngDefaultControl>
              </ng-autocomplete>
              <img class="search" src="assets/img/Icon-feather-search.svg" (click)="clickEvent.next(searchFilter)"
                alt="feather" />
              <ng-template #itemTemplate let-item>
                <a href="javascript:void(0)">
                  <span [innerHTML]="item.nameWithEmail"></span>
                </a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div *ngIf="searchFilter.length > 3 && !autoCompleteInProgress" [innerHTML]="notFound">No item found!
                </div>
              </ng-template>
            </div>
          </div>
          <h5 class="shipment-total" *ngIf="filterSystemDefined && !isGroup">
            <span class="number-set">{{ selectedUser.length }}</span> Users
          </h5>
          <div class="table-action-field-cntr-ctrl">
            <div class="hint-msg" [ngClass]="{ 'dfs-section': displayFilterHintActive }">
              <span class="hint-msg-text">Allows user to access more filter conditions outside the
                restricted filter. Do not tick if you want to restrict the
                filter access.</span>
            </div>
            <!-- dfs-section -->
            <div class="hint-msg" [ngClass]="{ 'cdc-section': dataConsolidationHintActive }">
              <span class="hint-msg-text">Data will be restricted in Dashboards and Groups. Tick if you
                want to restrict to limited data a user can see.</span>
            </div>
            <!-- cdc-section -->
            <div class="table-action-field-cntr">
              <table class="table-action-field ng-invalid ng-dirty ng-touched table-sortable"
                *ngIf="selectedUser.length > 0 && filterSystemDefined" id="createSysFilter"
                aria-describedby="table-action-field">
                <caption class="d-none"></caption>
                <thead>
                  <tr>
                    <th id="userNameEditCreate" class="desc sort" data-order="asc" data-name="firstNamelastName"
                      (click)="
                        sortFormArray(
                          FControl,
                          'firstNamelastName',
                          '#createSysFilter',
                          'userNameEditCreate'
                        )
                      " style="width: 25%">
                      User Name
                    </th>
                    <th id="userEmailCreate" class="desc sort" data-order="asc" data-name="userEmail" (click)="
                        sortFormArray(
                          FControl,
                          'userEmail',
                          '#createSysFilter',
                          'userEmailCreate'
                        )
                      " style="width: 30%">
                      User Email
                    </th>
                    <th id="isDisplayFilterSectionCreate" class="dfs-section-ctr desc sort"
                      (mouseover)="isFilterMouseOver(1)" (mouseout)="isFilterMouseOut(1)" data-order="asc"
                      data-name="isDisplayFilterSection" (click)="
                        sortFormArray(
                          FControl,
                          'isDisplayFilterSection',
                          '#createSysFilter',
                          'isDisplayFilterSectionCreate'
                        )
                      " style="width: 12%">
                      Display Filter Section
                    </th>
                    <th id="isDefaultFilterCreate" class="desc sort" data-order="asc" data-name="isDefaultFilter"
                      (click)="
                        sortFormArray(
                          FControl,
                          'isDefaultFilter',
                          '#createSysFilter',
                          'isDefaultFilterCreate'
                        )
                      " style="width: 12%">
                      Is Default Filter
                    </th>
                    <th id="isConsiderForDataConsolidationCreat" class="cdc-section-ctr desc sort" data-order="asc"
                      data-name="isConsiderForDataConsolidation" (mouseover)="isFilterMouseOver(2)"
                      (mouseout)="isFilterMouseOut(2)" (click)="
                        sortFormArray(
                          FControl,
                          'isConsiderForDataConsolidation',
                          '#createSysFilter',
                          'isConsiderForDataConsolidationCreat'
                        )
                      " style="width: 12%">
                      Consider for Data Consolidation
                    </th>
                    <th style="width: 4%"></th>
                  </tr>
                </thead>
                <tbody [formGroup]="form">
                  <tr formArrayName="users" *ngFor="
                      let item of getControls;
                      let i = index;
                      let first = first;
                      let last = last
                    ">
                    <ng-container [formGroupName]="i">
                      <td>
                        <label>{{ item.value.firstNamelastName }}</label>
                        <input type="hidden" class="from-control" formControlName="firstNamelastName" />
                      </td>
                      <td>
                        <label>{{ item.value.userEmail }}</label>
                        <input type="hidden" class="from-control" formControlName="userEmail" />
                      </td>
                      <td>
                        <div class="checkbox-ctr chck-ctrl-action">
                          <div class="chck-bx-ctr">
                            <input type="checkbox" id="isDisplayFilterSection{{ i }}"
                              formControlName="isDisplayFilterSection" class="form-control" /><label
                              for="isDisplayFilterSection{{ i }}" class="chck-box-lbl"></label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="checkbox-ctr chck-ctrl-action">
                          <div class="chck-bx-ctr">
                            <input type="checkbox" id="isDefaultFilter{{ i }}" formControlName="isDefaultFilter"
                              class="form-control" /><label for="isDefaultFilter{{ i }}" class="chck-box-lbl"></label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="checkbox-ctr chck-ctrl-action">
                          <div class="chck-bx-ctr">
                            <input type="checkbox" id="isConsiderForDataConsolidation{{ i }}"
                              formControlName="isConsiderForDataConsolidation" class="form-control" /><label
                              for="isConsiderForDataConsolidation{{ i }}" class="chck-box-lbl"></label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="remove-icon" (click)="showUserDeletePoup(i)">
                          <img _ngcontent-rtu-c233="" src="../../assets/img/drag-minus.svg" alt="minus"
                            class="close-icon" />
                        </span>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="footer-btn-ctr">
        <div class="button-setup text-right">
          <button type="button" class="field-btn cancel-field mr-2" (click)="cancelPopuCheck()">
            CANCEL
          </button>
          <button type="submit" class="field-btn add-field" id="shipmentSaveBtn" (click)="saveMyFilter(form?.value)">
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="pop-up5" *ngIf="isShowEditPopup" class="dimScreen" [ngClass]="{
    'sys-defined-admin': currentUserRole == 'systemadmin',
    'sys-defined-user': currentUserRole == 'user'
  }">
  <div class="add-user-popup new-filter" [class.add-group-sec]="isGroup">
    <button class="close" type="button" (click)="cancelPopuCheck()">
      <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
    </button>
    <div class="body-section fix-size new-fltr-name-ctr">
      <h3>Edit Filter</h3>
      <div class="content-sec">
        <label>Filter Name<span class="red-set">*</span></label>
        <input type="text" [(ngModel)]="myEditFilterName" (keyup.enter)="updateUserFilter()" placeholder="Enter Name"
          class="form-control" maxlength="30" (keyup)="saveButtonDisabled()" />
        <p *ngIf="myFilterError && myEditFilterName.length > 0" class="error-set red-set">
          A filter with this name already exists.
        </p>
        <p *ngIf="myFilterNameEmpty && myEditFilterName.length < 1" class="error-set red-set">
          Please enter the filter name.
        </p>
        <p *ngIf="myEditFilterName.length > 29" class="error-set red-set">
          Filter name reached maximum 30 characters allowed.
        </p>

        <ng-container *ngIf="currentUserRole === 'systemadmin'" class="pt-2">
          <!--&& !isGroup-->
          <div class="checkbox-ctr chck-ctrl-action mt-3">
            <div class="chck-bx-ctr">
              <input type="checkbox" id="filterSystemDefinedEdit" [(ngModel)]="filterSystemDefined"
                class="con-base-element ng-touched" (ngModelChange)="
                  showIsSysDefinedPopupFn(); saveButtonVisible = false
                " /><label for="filterSystemDefinedEdit" class="chck-box-lbl"><span class="header-set">System
                  Defined</span></label>
            </div>
          </div>
          <div class="col-lg-12 form-group pl-0 search-container" *ngIf="filterSystemDefined && !isGroup">
            <label>Add Users:</label>
            <div class="ng-autocomplete">
              <ng-autocomplete #auto [data]="userAutoComplete" [searchKeyword]="keyword"
                placeholder="Search by name or email" (selected)="selectEvent($event)" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" [(ngModel)]="searchFilter" (inputCleared)="blurEvent($event)"
                (inputChanged)="autoCompleteOnKeyUp(searchFilter)" (keyup.enter)="clickEvent.next(searchFilter)"
                [debounceTime]="600" class="custom-style" id="accountAutoComplete" ngDefaultControl>
              </ng-autocomplete>
              <img class="search" src="assets/img/Icon-feather-search.svg" (click)="clickEvent.next(searchFilter)"
                alt="feather" />
              <ng-template #itemTemplate let-item>
                <a href="javascript:void(0)">
                  <span [innerHTML]="item.nameWithEmail"></span>
                </a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div *ngIf="searchFilter.length > 3 && !autoCompleteInProgress" [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <h5 class="shipment-total" *ngIf="filterSystemDefined && !isGroup">
            <span class="number-set">{{ selectedUser.length }}</span> Users
          </h5>
          <div class="table-action-field-cntr">
            <table class="table-action-field ng-invalid ng-dirty ng-touched table-sortable"
              *ngIf="selectedUser.length > 0 && filterSystemDefined" id="editSysFilter"
              aria-describedby="table-users-field">
              <caption class="d-none"></caption>
              <thead>
                <tr>
                  <th id="userNameEdit" class="desc sort" data-order="asc" data-name="firstNamelastName" (click)="
                      sortFormArray(
                        FControl,
                        'firstNamelastName',
                        '#editSysFilter',
                        'userNameEdit'
                      )
                    ">
                    User Name
                  </th>
                  <th id="userEmail" class="desc sort" data-order="asc" data-name="userEmail" (click)="
                      sortFormArray(
                        FControl,
                        'userEmail',
                        '#editSysFilter',
                        'userEmail'
                      )
                    ">
                    User Email
                  </th>
                  <th id="isDisplayFilterSection" class="desc sort" data-order="asc" data-name="isDisplayFilterSection"
                    (click)="
                      sortFormArray(
                        FControl,
                        'isDisplayFilterSection',
                        '#editSysFilter',
                        'isDisplayFilterSection'
                      )
                    ">
                    Display Filter Section
                  </th>
                  <th id="isDefaultFilter" class="desc sort" data-order="asc" data-name="isDefaultFilter" (click)="
                      sortFormArray(
                        FControl,
                        'isDefaultFilter',
                        '#editSysFilter',
                        'isDefaultFilter'
                      )
                    ">
                    Is Default Filter
                  </th>
                  <th id="isConsiderForDataConsolidation" class="desc sort" data-order="asc"
                    data-name="isConsiderForDataConsolidation" (click)="
                      sortFormArray(
                        FControl,
                        'isConsiderForDataConsolidation',
                        '#editSysFilter',
                        'isConsiderForDataConsolidation'
                      )
                    ">
                    Consider for Data Consolidation
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody [formGroup]="form">
                <tr formArrayName="users" *ngFor="
                    let item of getControls;
                    let i = index;
                    let first = first;
                    let last = last
                  ">
                  <ng-container [formGroupName]="i">
                    <td>
                      <label>{{ item.value.firstNamelastName }}</label>
                      <input type="hidden" class="from-control" formControlName="firstNamelastName" />
                    </td>
                    <td>
                      <label>{{ item.value.userEmail }}</label>
                      <input type="hidden" class="from-control" formControlName="userEmail" />
                    </td>
                    <td>
                      <div class="checkbox-ctr chck-ctrl-action">
                        <div class="chck-bx-ctr">
                          <input type="checkbox" id="isDisplayFilterSection{{ i }}"
                            formControlName="isDisplayFilterSection" class="form-control" /><label
                            for="isDisplayFilterSection{{ i }}" class="chck-box-lbl"></label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="checkbox-ctr chck-ctrl-action">
                        <div class="chck-bx-ctr">
                          <input type="checkbox" id="isDefaultFilter{{ i }}" formControlName="isDefaultFilter"
                            class="form-control" /><label for="isDefaultFilter{{ i }}" class="chck-box-lbl"></label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="checkbox-ctr chck-ctrl-action">
                        <div class="chck-bx-ctr">
                          <input type="checkbox" id="isConsiderForDataConsolidation{{ i }}"
                            formControlName="isConsiderForDataConsolidation" class="form-control" /><label
                            for="isConsiderForDataConsolidation{{ i }}" class="chck-box-lbl"></label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span class="remove-icon" (click)="showUserDeletePoup(i)">
                        <img _ngcontent-rtu-c233="" src="../../assets/img/drag-minus.svg" alt="minus"
                          class="close-icon" />
                      </span>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
      <div class="footer-btn-ctr">
        <div class="preview-reset-ctr">
          <a class="preview-btn delete-icon" (click)="showIsDeletePopup = false"><img
              src="../../../../../../assets/img/delete.svg" alt="delete"
              class="upload-icon delete" /><span>Delete</span></a>
        </div>
        <div class="button-setup text-right">
          <button type="button" class="field-btn cancel-field mr-2" (click)="cancelPopuCheck()">
            CANCEL
          </button>
          <button type="submit" [class.cursor-disabled]="saveButtonVisible" [disabled]="saveButtonVisible"
            class="field-btn add-field" id="shipmentSaveBtn" (click)="updateUserFilter(form?.value)">
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="align-items-center justify-content-xl-end shipment-list-filter">
  <div id="pop-up2" *ngIf="popupToggle" class="dimScreen global-share-modal">
    <div *ngIf="popupToggle" class="add-user-popup">
      <button class="close" type="button" (click)="featureHide()">
        <img src="../../assets/img/close-sm.png" class="close-icon" alt="close" />
      </button>
      <div class="body-section">
        <h3>Share <span>Shipment</span></h3>
        <ul class="nav nav-tabs tab-nav justify-content-center" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" id="tabDetail">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../../../../../assets/img/share/url-img.svg" alt="url" />
              URL
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
              role="tab" aria-controls="profile" aria-selected="false">
              <img src="../../../../../assets/img/share/download-img.svg" alt="download" />
              Download
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active justify-content-center" id="home" role="tabpanel"
            aria-labelledby="home-tab">
            <div class="discription">
              <p class="text-des">
                Anyone with a Connect account and access to this company will be
                able to view this list.
              </p>
              <div class="copy-section">
                <input type="text" id="shareUrl" [value]="urlShare" readonly />
                <button class="add-type" (click)="copyLink()">COPY LINK</button>
              </div>
            </div>
          </div>
          <div class="tab-pane fade justify-content-center" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="discription">
              <p>
                Download all shipments with the current filters that are applied
                to the list.
              </p>
              <button class="add-type mobile-set" (click)="excelExport('download')">
                Download AS Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div tabindex="-1" role="dialog" [hidden]="showCancelPopup" id="remove-Module-popup" data-bs-backdrop="static"
  data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true" style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="add-user-popup copy-section move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" class="add-copy" />
          </span>
          <button type="button" data-dismiss="modal" class="close delete-close-icon mt-0"
            (click)="showCancelPopup = true">
            <img src="../../../../../../assets/img/modal-close-icon.svg" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Are you sure you'd like to cancel?</h3>
            <div class="discription">Any changes made will not be saved.</div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="showCancelPopup = true">
                GO BACK</button><button class="add-type" (click)="confirmCancelPopup()">
                YES, CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div tabindex="-1" role="dialog" [hidden]="showIsSysDefinedPopup" id="remove-Module-popup" data-bs-backdrop="static"
  data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true" style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="add-user-popup copy-section move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" class="add-copy" />
          </span>
          <button type="button" data-dismiss="modal" class="close delete-close-icon mt-0"
            (click)="onCancelingSystemDefined()">
            <img src="../../../../../../assets/img/modal-close-icon.svg" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Are you sure you'd like to proceed?</h3>
            <div class="discription">
              By unchecking System Defined, users will be removed and will no
              longer have access to this filter.
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="onCancelingSystemDefined()">
                CANCEL</button><button class="add-type" (click)="onConfirmingSysDefined()">
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div tabindex="-1" role="dialog" [hidden]="showIsUserRemovePopup" id="remove-Module-popup" data-bs-backdrop="static"
  data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true" style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="add-user-popup copy-section move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" class="add-copy" />
          </span>
          <button type="button" data-dismiss="modal" class="close delete-close-icon mt-0"
            (click)="onCancelingUserDelete()">
            <img src="../../../../../../assets/img/modal-close-icon.svg" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Remove user?</h3>
            <div class="discription">
              User will no longer access this filter.
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="onCancelingUserDelete()">
                CANCEL</button><button class="add-type" (click)="removeFromList()">
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div tabindex="-1" role="dialog" [hidden]="showIsDeletePopup" id="remove-Module-popup" data-bs-backdrop="static"
  data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true" style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="add-user-popup copy-section move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="assets/img/delete.svg" alt="delete" class="add-copy" />
          </span>
          <button type="button" data-dismiss="modal" class="close delete-close-icon mt-0"
            (click)="showIsDeletePopup = true">
            <img src="../../../../../../assets/img/modal-close-icon.svg" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3>Remove Filter?</h3>
            <div class="discription">
              User will no longer access this filter<span
                *ngIf="isfilterEdit?.savedValue?.filterType === 'shipment'">for all users.</span>
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="showIsDeletePopup = true">
                CANCEL</button><button class="add-type" (click)="deleteFilter()">
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Advanced Filter Reset start -->
<div tabindex="-1" role="dialog" [hidden]="!showAdvFilterResetPopup" id="advanced-filter-reset-popup"
  data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true"
  aria-hidden="true">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="removed-action-popup move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/reset.svg" alt="alert" />
          </span>
          <button type="button" class="close delete-close-icon" data-dismiss="modal">
            <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon"
              (click)="closeAdvFilterReset($event)" />
          </button>
          <div class="body-section confirm-removal">
            <h3 class="pl-0 m-0">Are you sure you want to reset?</h3>
            <div class="discription m-0">
              Any customized filters will be reset and will not be applied.
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" data-dismiss="modal" (click)="closeAdvFilterReset($event)">
                GO BACK
              </button>
              <button class="add-type" (click)="resetAdvFilter()">
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Advanced Filter Reset end -->

<!-- Advanced Filter Confirm modal Changes  -->
<div tabindex="-1" role="dialog" [hidden]="!showAdvancedFilterCancelModal" id="advancedFilterConfirmationPopup"
  data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade bd-example-modal-lg show" aria-modal="true"
  style="display: block">
  <div role="document" class="modal-dialog modal-dialog-centered modal-lg make-it-center">
    <div class="modal-content" style="border: none">
      <div class="removed-action-popup move-height popup-center">
        <div class="confirm-element-usage">
          <span class="alert-icon">
            <img src="../../../../../../assets/img/alert-icon.svg" alt="alert" />
          </span>
          <button type="button" class="close delete-close-icon" (click)="
              showAdvancedFilterCancelModal = !showAdvancedFilterCancelModal
            ">
            <img src="../../../../../../assets/img/close-sm.png" alt="close" class="close-icon" />
          </button>
          <div class="body-section confirm-removal">
            <h3 class="p-0">Changes Will Not Be Saved</h3>
            <div class="discription">
              Are you sure you'd like to cancel? Any changes made will not be
              saved.
            </div>
            <div class="button-setup">
              <button class="edit-permissons mr-2" (click)="
                  showAdvancedFilterCancelModal = !showAdvancedFilterCancelModal
                ">
                GO BACK
              </button>
              <button class="add-type" (click)="closeAdvancedFilterOnConfirmation()">
                PROCEED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Advanced Filter Confirm modal Changes  -->