import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  transform(fileName: any, scrWidth: number): unknown {
    const classFullScreen = $('#shipHeader').hasClass('fixed-fullwidth');
    let file: any = fileName.split(/\.(?=[^\.]+$)/);
    if (!classFullScreen) {
      if (scrWidth > 400 && file[0].length > 26) {
        return CommonUtils.getDocumentFileName(file[0], 26, file[1]);
      } else if (file[0].length > 12 && scrWidth <= 400) {
        return CommonUtils.getDocumentFileName(file[0], 12, file[1]);
      } else {
        return fileName;
      }
    } else {
      return fileName;
    }
  }

}
