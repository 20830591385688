import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sucess-toast',
  templateUrl: './sucess-toast.component.html',
  styleUrls: ['./sucess-toast.component.scss'],
})
export class SucessToastComponent {
  @Input() message: any;
  @Output() undoDelete = new EventEmitter<any>();
  @Output() triggerToastClose = new EventEmitter<any>();
  callUndoFn() {
    this.undoDelete.emit(true);
  }
  close() {
    this.triggerToastClose.next(true);
  }
}
