import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'cityStateCountry'
})
export class CityStateCountryPipe implements PipeTransform {

  transform(address: any): unknown {
    let htmlString = '';
    if (!address) return htmlString;

    if (address.city) {
      htmlString += this.getTitleCase(address.city);
    }

    if (address.city && address.country_name) {
      htmlString += ', ';
    }

    // Add state code when US or Canada
    if (
      address.state &&
      address.country_name &&
      (address.country_name.toLowerCase() == 'united states' ||
        address.country_name.toLowerCase() == 'canada')
    ) {
      htmlString += address.state + ', ';
    }
    if (address.country_name) {
      htmlString += this.getTitleCase(address.country_name);
    }
    return htmlString;
  }
  getTitleCase(text: string) {
    return CommonUtils.toTitleCase(text);
  }
}
