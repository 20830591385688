<div class="col-12 contain-top shipment-listall-wrap" id="globalsearch-result">
  <h2 class="mt-3">Search Results</h2>
  <div class="container pb-5 mb-5">
    <app-progressbar [hidden]="!isProgressShow"></app-progressbar>
    <div class="col-12 col-sm-12 col-md-10">
      <div class="input-container search-container">
        <input type="search" [(ngModel)]="shipmentSearchText" autocomplete="off" #quicksearchBtn
          name="shipmentSearchText" placeholder="Search" />
        <img src="../../assets/img/close_whitebg_remove.svg" id="search-results-clear" alt="close-icon" class="mr-0"
          *ngIf="shipmentSearchText.length >0" (click)="clearSearch()">
        <img src="../../assets/img/Icon-feather-search.svg" alt="" />
      </div>
    </div>
    <div class="col-12">
      <div class="container mobile-set">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" *ngIf="isShipmentIsVisible" (click)="showTab('shipment')">
            <a class="nav-link" [ngClass]="{ active: isShipmentTab }" id="shipmentResult">

              <h3 class="shipment-total change-set">
                Shipments<span class="number-set"> - {{ totalShipmentCount }}</span>
              </h3>
            </a>
          </li>
          <li class="nav-item" *ngIf="isBookingIsVisible" (click)="showTab('booking')">
            <a class=" nav-link" [ngClass]="{ active: isBookingTab }" id="BookingResult">
              <h3 class="shipment-total change-set">
                Bookings<span class="number-set"> - {{totalBookingCount}}</span>
              </h3>
            </a>
          </li>
          <li class="nav-item" *ngIf="isCustomsIsVisible" (click)="showTab('customs')">
            <a class=" nav-link" [ngClass]="{ active: isCustomsTab }" id="customsResult">
              <h3 class="shipment-total change-set">
                Customs<span class="number-set"> - {{totalCustomsCount}}</span>
              </h3>
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="search-result-shipments" role="tabpanel"
            *ngIf="isShipmentIsVisible && isShipmentTab">
            <div class="accordion" id="faq">
              <div class="card">
                <div class="card-header" id="faqhead1"
                  (click)="clickEvent.next({'status':1, 'count':pickedUpScheduledCount,'offset': 0,'id':'#faqhead1','type':'shipment'})">
                  <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq1"
                    aria-expanded="false" aria-controls="faq1">Pickup Scheduled
                    <span class="count">{{ pickedUpScheduledCount }}</span></a>
                </div>
                <div id="faq1" class="collapse" aria-labelledby="faqhead1" data-parent="#accordion">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressPickedUpScheduled && pickedUpScheduledCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressPickedUpScheduled && currentStatus == 1 && currentType=='shipment' && pickedUpScheduled.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="pickedUpScheduled.length > 0" [shipmentResult]="true"
                      [shipmentData]="pickedUpScheduled" [isShowToggle]="false" [currentStatus]="1"
                      [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                      [urlShare]="url" (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="faqhead2"
                  (click)="clickEvent.next({'status':2, 'count':pickedUpCount,'offset': 0,'id':'#faqhead2','type':'shipment'})">
                  <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq2"
                    aria-expanded="false" aria-controls="faq2">Picked Up<span class="count">{{ pickedUpCount
                      }}</span></a>
                </div>

                <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#accordion">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressPickedUp && pickedUpCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressPickedUp && currentStatus == 2 && currentType=='shipment' && pickedUp.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="pickedUp.length > 0" [shipmentResult]="true" [isShowToggle]="false"
                      [currentStatus]="2" (emitOffset)="setOffset($event)" [searchText]="shipmentSearchText"
                      [shipmentData]="pickedUp" [type]="'searchScreen'" [urlShare]="url"
                      (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="faqhead3"
                  (click)="clickEvent.next({'status':3, 'count':scheduledCount,'offset': 0,'id':'#faqhead3','type':'shipment'})">
                  <a a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq3"
                    aria-expanded="false" aria-controls="faq3">Scheduled <span class="count">{{ scheduledCount
                      }}</span></a>
                </div>


                <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#accordion">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressScheduled && scheduledCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressScheduled && currentStatus == 3 && currentType=='shipment' && scheduled.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="scheduled.length > 0" [shipmentResult]="true" [isShowToggle]="false"
                      [currentStatus]="3" (emitOffset)="setOffset($event)" [searchText]="shipmentSearchText"
                      [shipmentData]="scheduled" [type]="'searchScreen'" [urlShare]="url"
                      (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="faqhead4"
                  (click)="clickEvent.next({'status':4, 'count':inTransitCount,'offset': 0,'id':'#faqhead4','type':'shipment'})">
                  <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq4"
                    aria-expanded="false" aria-controls="faq4">In Transit <span class="count">{{ inTransitCount
                      }}</span></a>
                </div>

                <div id="faq4" class="collapse" aria-labelledby="faqhead4" data-parent="#accordion">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressInTransit && inTransitCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressInTransit && currentStatus == 4 && currentType=='shipment' && inTransit.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="inTransit.length > 0" [shipmentResult]="true" [isShowToggle]="false"
                      [currentStatus]="4" (emitOffset)="setOffset($event)" [searchText]="shipmentSearchText"
                      [shipmentData]="inTransit" [type]="'searchScreen'" [urlShare]="url"
                      (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="faqhead5"
                  (click)="clickEvent.next({'status':5, 'count':arrivedCount,'offset': 0,'id':'#faqhead5','type':'shipment'})">
                  <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq5"
                    aria-expanded="false" aria-controls="faq5">Arrived <span class="count">{{ arrivedCount }}</span></a>
                </div>

                <div id="faq5" class="collapse" data-parent="#accordion" aria-labelledby="faqhead5">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressArrived && arrivedCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressArrived && currentStatus == 5 && currentType=='shipment' && arrived.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="arrived.length > 0" [shipmentResult]="true" [isShowToggle]="false"
                      [currentStatus]="5" [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)"
                      [shipmentData]="arrived" [type]="'searchScreen'" [urlShare]="url"
                      (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="faqhead6"
                  (click)="clickEvent.next({'status':6, 'count':deliveryScheduledCount,'offset': 0,'id':'#faqhead6','type':'shipment'})">
                  <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq6"
                    aria-expanded="false" aria-controls="faq6">Delivery Scheduled
                    <span class="count">{{ deliveryScheduledCount }}</span></a>
                </div>

                <div id="faq6" class="collapse" data-parent="#accordion" aria-labelledby="faqhead6">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressDeliveryScheduled && deliveryScheduledCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressDeliveryScheduled && currentStatus == 6 && currentType=='shipment' && deliveryScheduled.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="deliveryScheduled.length > 0" [shipmentResult]="true"
                      [isShowToggle]="false" (emitOffset)="setOffset($event)" [shipmentData]="deliveryScheduled"
                      [searchText]="shipmentSearchText" [currentStatus]="6" [type]="'searchScreen'" [urlShare]="url"
                      (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
              <div class="card mb-5">
                <div (click)="deliveryAccordian()">
                  <div class="card-header" id="faqhead7"
                    (click)="clickEvent.next({'status':7, 'count':deliveredCount,'offset': 0,'id':'#faqhead7','type':'shipment'})">
                    <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq7"
                      aria-expanded="false" aria-controls="faq7">Delivered <span class="count">{{ deliveredCount
                        }}</span></a>
                  </div>
                </div>


                <div id="faq7" class="collapse" data-parent="#accordion" aria-labelledby="faqhead7">
                  <div class="card-body">
                    <div class="no-data-found" *ngIf="!isProgressDelivered && deliveredCount == 0">
                      <p>No Shipments Found</p>
                    </div>
                    <div class="no-data-found"
                      *ngIf="isProgressDelivered && currentStatus == 7 && currentType=='shipment' && delivered.length < 1">
                      <p>Loading Shipments</p>
                    </div>
                    <app-shipment-list *ngIf="delivered.length > 0" [shipmentResult]="true" [isShowToggle]="false"
                      (emitOffset)="setOffset($event)" [currentStatus]="7" [searchText]="shipmentSearchText"
                      [shipmentData]="delivered" [type]="'searchScreen'" [urlShare]="url"
                      (prepareUrlShare)="prepareShipmentShare($event)">
                    </app-shipment-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade show active" id="search-result-bookings" role="tabpanel"
            aria-labelledby="profile-tab" *ngIf="isBookingIsVisible && isBookingTab">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active">
                <div class="accordion" id="faq">
                  <div class="card">
                    <div class="card-header" id="faqhead8"
                      (click)="clickEvent.next({'status':1, 'count':pendingCount,'offset': 0,'id':'#faqhead8','type':'booking'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq8"
                        aria-expanded="false" aria-controls="faq8">Pending
                        <span class="count">{{ pendingCount }}</span></a>
                    </div>
                    <div id="faq8" class="collapse" data-parent="#accordion" aria-labelledby="faqhead8">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressPending && pendingCount == 0">
                          <p>No Booking Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressPending && currentStatus == 1 && currentType =='booking' && pending.length < 1">
                          <p>Loading Bookings</p>
                        </div>
                        <app-booking-list *ngIf="pending.length > 0" [currentStatus]="1"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlBooking($event)"
                          [bookingMasterData]="pending">
                        </app-booking-list>

                      </div>
                    </div>
                  </div>
                  <div class="card" id="faqhead9">
                    <div class="card-header"
                      (click)="clickEvent.next({'status':2, 'count':convertedCount,'offset': 0,'id':'#faqhead9','type':'booking'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq9"
                        aria-expanded="false" aria-controls="faq9">Converted
                        <span class="count">{{ convertedCount }}</span></a>
                    </div>

                    <div id="faq9" class="collapse" data-parent="#accordion" aria-labelledby="faqhead9">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressConverted && convertedCount == 0">
                          <p>No Booking Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressConverted && currentStatus == 2 && currentType == 'booking' && converted.length < 1">
                          <p>Loading Bookings</p>
                        </div>
                        <app-booking-list *ngIf="converted.length > 0" [currentStatus]="2"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlBooking($event)"
                          [bookingMasterData]="converted">
                        </app-booking-list>
                      </div>
                    </div>
                  </div>

                  <div class="card" id="faqhead10">
                    <div class="card-header"
                      (click)="clickEvent.next({'status':3, 'count':cancelledCount,'offset': 0,'id':'#faqhead10','type':'booking'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq10"
                        aria-expanded="false" aria-controls="faq10">Canceled
                        <span class="count">{{ cancelledCount }}</span></a>
                    </div>

                    <div id="faq10" class="collapse" data-parent="#accordion" aria-labelledby="faqhead10">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressCancelled && cancelledCount == 0">
                          <p>No Booking Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressCancelled && currentStatus == 3 && currentType == 'booking' && cancelled.length < 1">
                          <p>Loading Bookings</p>
                        </div>
                        <app-booking-list *ngIf="cancelled.length > 0" [currentStatus]="3"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlBooking($event)"
                          [bookingMasterData]="cancelled">
                        </app-booking-list>
                      </div>
                    </div>
                  </div>

                  <div class="card" id="faqhead11">
                    <div class="card-header"
                      (click)="clickEvent.next({'status':4, 'count':archivedCount,'offset': 0,'id':'#faqhead11','type':'booking'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq11"
                        aria-expanded="false" aria-controls="faq11">Archived
                        <span class="count">{{ archivedCount }}</span></a>
                    </div>

                    <div id="faq11" class="collapse" data-parent="#accordion" aria-labelledby="faqhead11">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressArchived && archivedCount == 0">
                          <p>No Booking Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressArchived && currentStatus == 4 && currentType == 'booking' && archived.length < 1">
                          <p>Loading Bookings</p>
                        </div>
                        <app-booking-list *ngIf="archived.length > 0" [currentStatus]="4"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlBooking($event)"
                          [bookingMasterData]="archived">
                        </app-booking-list>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade show active" id="search-result-customs" role="tabpanel"
            aria-labelledby="profile-tab" *ngIf="isCustomsIsVisible && isCustomsTab">
            <div class="tab-content" id="myTabContent3">
              <div class="tab-pane fade show active">
                <div class="accordion" id="faq">
                  <div class="card">
                    <div class="card-header" id="faqhead12"
                      (click)="clickEvent.next({'status':1, 'count':preparedCount,'offset': 0,'id':'#faqhead12','type':'customs'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq12"
                        aria-expanded="false" aria-controls="faq12">Prepared
                        <span class="count">{{ preparedCount }}</span></a>
                    </div>
                    <div id="faq12" class="collapse" data-parent="#accordion" aria-labelledby="faqhead12">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressPrepared && preparedCount === 0">
                          <p>No Customs Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressPrepared && currentStatus == 1 && currentType =='customs' && prepared.length < 1">
                          <p>Loading Customs</p>
                        </div>
                        <app-customs-list *ngIf="prepared.length > 0" [currentStatus]="1"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlCustoms($event)"
                          [customsListData]="prepared">
                        </app-customs-list>

                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead13"
                      (click)="clickEvent.next({'status':2, 'count':filedCount,'offset': 0,'id':'#faqhead13','type':'customs'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq13"
                        aria-expanded="false" aria-controls="faq13">Filed
                        <span class="count">{{ filedCount }}</span></a>
                    </div>

                    <div id="faq13" class="collapse" data-parent="#accordion" aria-labelledby="faqhead13">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressFiled && filedCount === 0">
                          <p>No Customs Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressFiled && currentStatus == 2 && currentType == 'customs' && filed.length < 1">
                          <p>Loading Customs</p>
                        </div>
                        <app-customs-list *ngIf="filed.length > 0" [currentStatus]="2" [searchText]="shipmentSearchText"
                          (emitOffset)="setOffset($event)" [type]="'searchScreen'" [urlShare]="url"
                          (prepareUrlShare)="prepareShareUrlCustoms($event)" [customsListData]="filed">
                        </app-customs-list>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="faqhead14"
                      (click)="clickEvent.next({'status':3, 'count':inProgressCount,'offset': 0,'id':'#faqhead14','type':'customs'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq14"
                        aria-expanded="false" aria-controls="faq14">In Progress
                        <span class="count">{{ inProgressCount }}</span></a>
                    </div>

                    <div id="faq14" class="collapse" data-parent="#accordion" aria-labelledby="faqhead14">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressInProgress && inProgressCount === 0">
                          <p>No Customs Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressInProgress && currentStatus == 3 && currentType == 'customs' && inProgress.length < 1">
                          <p>Loading Customs</p>
                        </div>
                        <app-customs-list *ngIf="inProgress.length > 0" [currentStatus]="3"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlCustoms($event)"
                          [customsListData]="inProgress">
                        </app-customs-list>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="faqhead15"
                      (click)="clickEvent.next({'status':4, 'count':releasedCount,'offset': 0,'id':'#faqhead15','type':'customs'})">
                      <a a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq15"
                        aria-expanded="false" aria-controls="faq15">Released
                        <span class="count">{{ releasedCount }}</span></a>
                    </div>

                    <div id="faq15" class="collapse" data-parent="#accordion" aria-labelledby="faqhead15">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressReleased && releasedCount == 0">
                          <p>No Customs Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressReleased && currentStatus == 4 && currentType == 'customs' && released.length < 1">
                          <p>Loading Customs</p>
                        </div>
                        <app-customs-list *ngIf="released.length > 0" [currentStatus]="4"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlCustoms($event)"
                          [customsListData]="released">
                        </app-customs-list>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="faqhead16"
                      (click)="clickEvent.next({'status':5, 'count':archivedCustomsCount,'offset': 0,'id':'#faqhead16','type':'customs'})">
                      <a class="btn btn-link btn-header-link" data-toggle="collapse" data-target="#faq16"
                        aria-expanded="false" aria-controls="faq16">Archived
                        <span class="count">{{ archivedCustomsCount }}</span></a>
                    </div>

                    <div id="faq16" class="collapse" data-parent="#accordion" aria-labelledby="faqhead16">
                      <div class="card-body">
                        <div class="no-data-found" *ngIf="!isProgressArchivedCustoms && archivedCustomsCount == 0">
                          <p>No Customs Found</p>
                        </div>
                        <div class="no-data-found"
                          *ngIf="isProgressArchivedCustoms && currentStatus == 5 && currentType == 'customs' && archivedCustoms.length < 1">
                          <p>Loading Customs</p>
                        </div>
                        <app-customs-list *ngIf="archivedCustoms.length > 0" [currentStatus]="5"
                          [searchText]="shipmentSearchText" (emitOffset)="setOffset($event)" [type]="'searchScreen'"
                          [urlShare]="url" (prepareUrlShare)="prepareShareUrlCustoms($event)"
                          [customsListData]="archivedCustoms">
                        </app-customs-list>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>