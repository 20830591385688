import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'customDateTime'
})
export class CustomDateTimePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let dateConvert = new Date(value);
    let utcDate = new Date(dateConvert.getUTCFullYear(),
      dateConvert.getUTCMonth(),
      dateConvert.getUTCDate(),
      dateConvert.getUTCHours(),
      dateConvert.getUTCMinutes());
    return super.transform(utcDate, "MMM d, y - H:mm");
  }
}
