import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  OnInit
} from '@angular/core';
import { ShipmentServies } from 'src/app/features/shipments/services/shipment.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dashboard-shipment-list',
  templateUrl: './dashboard-shipment-list.component.html',
})
export class DashboardShipmentListComponent implements OnInit {
  isProgressShow = false;
  totalShipmentCount: number = 0;
  sortColoumnName: any = 'first_leg_departure_actual';
  sortDirection: any = 'desc';
  shipmentData: any = [];
  url = '';
  page = 0;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  @Input() viewAllShipment: any;
  @Output() emitRefreshList = new EventEmitter();

  // @ViewChild(ShipmentListComponent)
  // child!: ShipmentListComponent;

  constructor(
    private shipmentService: ShipmentServies,
    private currentSessionUserData: CurrentSessionDataService
  ) {
    this.getScreenSize();
  }
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    //this.shipmentData = [];
    setTimeout(() => {
      if (this.currentUserData) {
        //this.getAllShipments(); // TODO: to be fixed for mobile and tabular view
      }
    }, 100);
  }

  ngOnInit() {
    this.getAllShipments();
  }

  onSortChanges(values: any) {
    this.sortColoumnName = values.sort_column;
    this.sortDirection = values.sort_direction;
    this.clearData();
    this.getAllShipments();
  }
  // TODO: to be refactored
  getAllShipments() {
    let accountId = this.currentAccountData?.accountId;
    let emailId = this.currentUserData?.emailAddress;
    let isGroup = this.currentAccountData?.isGroup;
    let fetch = 0;
    if (this.scrWidth <= 1200) {
      fetch = 4;
    } else {
      fetch = 6;
    }
    let bodyParam = {
      offset: 0,
      fetch: fetch,
      search_text: '',
      isParentAccount: false,
      accountId: !isGroup ? accountId : '',
      groupID: isGroup ? accountId : '',
      advanceFilterOptions: [],
      parameters: [],
      sort_options: [
        { column_name: this.sortColoumnName, direction: this.sortDirection },
      ],
      filter_options: [
        {
          column_name: 'favorites',
          operater: '=',
          value: false,
        },
      ],
    };

    this.isProgressShow = true;
    this.shipmentService
      .getAllShipments(accountId, emailId, bodyParam, isGroup)
      .subscribe((res) => {
        this.totalShipmentCount = res?.total_count;
        if (res?.result?.includes('does not have persmission')) {
          this.shipmentData = [];
        } else {
          this.shipmentData = res?.result;
        }
        // Loader end
        this.isProgressShow = false;
      });
  }

  getListingOffset(event: any) {
    this.page = event;
    this.getAllShipments();
  }

  clearData() {
    this.page = 0;
    this.shipmentData = [];
  }

  reloadShipmentList(event: any) {
    this.clearData();
    this.getAllShipments();
    this.emitRefreshList.emit('shipment');
  }

  prepareDashboardShipment(event: any) {
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: {},
        selectedAccount: [],
      },
      destination: {
        selectedValue: {},
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: {},
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: {},
        selectedAccount: [],
      },
      consignee: {
        selectedValue: {},
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: {} },
          { destinationValue: {} },
          { pickupValue: {} },
          { finalDeliveryValue: {} },
        ],
      },
      sorting: [],
      accountDetail: {
        aliasName: this.currentAccountData?.accountAliasName,
        oH_Code: this.currentAccountData?.accountId,
        byGroup: this.currentAccountData?.isGroup,
        shipment: { isVisible: this.currentAccountData?.shipmentIsVisible },
        booking: { isVisible: this.currentAccountData?.bookingIsVisible },
        customs: { isVisible: this.currentAccountData?.customsIsVisible },
        isControllingCustomer: this.currentAccountData.isParent,
      },
      shipmentId: event.shipmentId,
      type: 'Detail',
      subType: '',
      module: 'Shipment',
    };
    let encry = btoa(JSON.stringify(selectedFilters));
    // this.url = environment.localUrl + '/shipments/' + encry;
    //this.url = environment.localUrl + '/' + event.shipmentId;
    this.url = environment.localUrl + '/' + event.shipment?.encryptedId;
  }
}
