import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountServies } from 'src/app/features/admin/accounts/services/account.service';
import { TableViewService } from 'src/app/features/admin/accounts/services/table-view.service';
import { ActionFieldService } from 'src/app/features/admin/actionfield/services/action-field.service';
import { ReportsService } from 'src/app/features/admin/reports/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationAuthGuard implements CanActivate {
  constructor(private reportService:ReportsService,
    private actionFieldService:ActionFieldService,
    private tableviewService:TableViewService) {}
  
    canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (
        this.reportService.formChanged ||
        this.actionFieldService.formChanged ||
        this.tableviewService.formChanged
      ) {
        let link:any=route?.routeConfig?.path?.toString();
        localStorage.setItem('lastClickedNavigationLink', link);
        return false;
        }
      else {
      return true;
    }
  
}
}

