<app-upload-document [type]="'shipment'" [shipmentId]="selectedShipment?.forwarder_reference"></app-upload-document>
<div class="no-data" *ngIf="!selectedShipment?.attachments">
  <img src="../../../../../assets/img/no-document.svg" alt="no document" />
  <p class="mt-3">No Available Documents</p>
</div>
<div class="tableFixHead add-changes mt-4 pt-2" *ngIf="selectedShipment?.attachments">
  <app-progressbar *ngIf="isProgressShow"></app-progressbar>
  <table class="table table-sortable document-sec" aria-labelledby="document-table" id="account-table">
    <caption class="d-none"></caption>
    <thead class="border-0">
      <tr style="height: 46px">
        <th [appSort]="selectedShipment?.attachments" id="file_name" class="asc sort" data-order="asc"
          data-name="file_name" data-parent="#account-table">
          File Name
        </th>
        <th [appSort]="selectedShipment?.attachments" id="file_type_code" class="asc sort" data-order="asc"
          data-name="file_type_code" data-parent="#account-table">
          Description
        </th>
        <th [appSort]="selectedShipment?.attachments" id="Uploaded" class="asc factive sort mobile-only"
          data-order="asc" data-name="created_at" data-parent="#account-table">
          Uploaded On
        </th>
        <th [appSort]="selectedShipment?.attachments" id="media_type" class="asc sort mobile-only" data-order="asc"
          data-name="file_type" data-parent="#account-table">
          Type
        </th>
        <th [appSort]="selectedShipment?.attachments" id="file_size" class="asc sort text-left mobile-only"
          data-order="asc" data-name="file_size_bytes" data-parent="#account-table">
          Size
        </th>
        <th id="parentAccountCity" class="text-center" style="cursor: default">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 46px" *ngFor="let file of selectedShipment?.attachments; let i = index">
        <td [title]="file?.file_name">
          <span class="add-middle-dots">{{
            file?.file_name | fileName : scrWidth
            }}</span>
        </td>
        <td [title]="file?.file_type_code + ' - ' + file?.file_type_description" class="p-0">
          <span class="trim-set">{{
            file?.file_type_code | trimEdocText : file?.file_type_description
            }}</span>
        </td>
        <td class="mobile-only">
          {{ file?.created_at | yearFormat }}
        </td>
        <td class="mobile-only">
          {{ file.file_type }}
        </td>
        <td class="text-left mobile-only p-0">
          {{ file?.file_size }}
        </td>
        <td class="text-center p-0">
          <img alt="preview" (click)="
              download(
                file?.filePath,
                file?.file_name.split('.').pop(),
                'preview',
                file?.file_name,
                file?.file_type_code
              )
            " src="../../../../../assets/img/copy-popup.svg" class="mr-2 mouse-pointer" />
          <img src="../../../../../assets/img/download-popup.svg" alt="download" class="mouse-pointer" (click)="
              download(
                file?.filePath,
                file?.file_name.split('.').pop(),
                'download',
                file?.file_name,
                file?.file_type_code
              )
            " />
        </td>
      </tr>
    </tbody>
  </table>
</div>