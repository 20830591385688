import {
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { FilterService } from '../../services/filter.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { event } from 'jquery';
import { CommonValidation } from 'src/app/shared/common/common-validation';
@Component({
  selector: 'app-header',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.scss'],
})
export class AppheaderComponent implements OnInit {
  userName: any;
  profileImgUrl = ' ';
  profileImgUrl2x = ' ';
  userEmail: any;
  userRole: any;
  isAdmin = false;
  showDashboard = false;
  searchExpand = false;
  showShipment = false;
  showBooking = false;
  showProfile = false;
  showCustoms = false;
  showReport = false;
  isQuickSearch: boolean = false;
  isGlobalSearch: boolean = false;
  shipmentSearchText = '';
  elementRef: any;
  isMobileSearch: boolean = false;
  currentAccountData: any;
  currentUserData: any;
  opened: boolean = false;
  isShipmentIsVisible: false;
  isBookingIsVisible: false;
  isCustomsIsVisible: false;
  @ViewChild('quicksearch') quicksearch: ElementRef;
  @ViewChild('searchIcon') searchIcon: ElementRef;
  @ViewChild('quicksearchBtn', { static: false }) quicksearchBtn: ElementRef;
  isDashboardSelected: boolean = false;
  isShipmentSelected: boolean = false;
  isBookingsSelected: boolean = false;
  isCustomsSelected: boolean = false;
  isReportSelected: boolean = false;
  scrHeight: any;
  scrWidth: any;
  isMobileViewParent = false;
  shipmentRoute: number = 1;
  bookingRoute: number = 2;
  customsRoute: number = 3;
  globalSearchInput = new Subject();
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768 ? true : false;
  }

  microSoftAccount: any;
  shipmentAutoSuggestedRecord: any[] = [];
  bookingAutoSuggestedRecord: any[] = [];
  customsAutoSuggestedRecord: any[] = [];
  totalShipmentCount = 0;
  totalBookingCount = 0;
  totalCustomsCount = 0;
  loginDisplay = false;
  notLoggedIn = false;
  checkUnsavedBroadcastMsg = false;
  showNPLink: boolean = false;
  public loginService: LoginService;
  public filterService: FilterService;
  private renderer: Renderer2;
  private router: Router;
  private sessionData: CurrentSessionDataService;
  private shareData: DataSharingService;
  private appInsightService: AppInsightsService;
  private dataSharingService: DataSharingService;

  /* istanbul ignore next */
  constructor(private injector: Injector) {
    this.loginService = injector.get<LoginService>(LoginService);
    this.filterService = injector.get<FilterService>(FilterService);
    this.renderer = injector.get<Renderer2>(Renderer2);
    this.router = injector.get<Router>(Router);
    this.sessionData = injector.get<CurrentSessionDataService>(
      CurrentSessionDataService
    );
    this.shareData = injector.get<DataSharingService>(DataSharingService);
    this.appInsightService =
      injector.get<AppInsightsService>(AppInsightsService);
    this.dataSharingService =
      injector.get<DataSharingService>(DataSharingService);
    this.scrWidth = window.innerWidth;
    this.isMobileViewParent = this.scrWidth <= 768 ? true : false;
    this.loginDisplay =
      localStorage.getItem('userStatus') == '1' ? true : false;

    this.intialDataSetupInConstructor();

    this.dataSharingService.unsavedDataBroadcast.subscribe((res) => {
      this.checkUnsavedBroadcastMsg = res;
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        sessionStorage.setItem('previousUrl', this.previousUrl);
      });
  }

  previousUrl = '';
  currentUrl = '';
  /* istanbul ignore next */
  intialDataSetupInConstructor() {
    this.setSessionUserAccountData();
    if (this.currentUserData) {
      this.renderer.listen('document', 'click', (e: Event) => {
        if (
          !this.quicksearchBtn.nativeElement.contains(e.target) &&
          !this.quicksearch?.nativeElement?.contains(e.target) &&
          !this.searchIcon.nativeElement.contains(e.target)
        ) {
          if (!this.isMobileViewParent) {
            this.isQuickSearch = false;
            this.isGlobalSearch = false;
            this.searchExpand = false;
            this.shipmentSearchText = '';
          }
        }
      });

      this.dataSharingService.afterNameChange.subscribe((res: boolean) => {
        if (res) {
          this.currentUserData = this.sessionData.getCurrentUserData();
          this.userName =
            this.currentUserData.firstName +
            ' ' +
            this.currentUserData.lastName;
        }
      });
    }
    this.dataSharingService.publicShareSwitchAcc.subscribe((res) => {
      if (res) {
        this.currentAccountData = this.sessionData.getCurrentAccountData();
        this.currentUserData = this.sessionData.getCurrentUserData();
        this.getTopMenuSelection();
        this.getUser();
      }
    });
    this.reorderSearchList();
  }

  setSessionUserAccountData() {
    this.currentAccountData = this.sessionData.getCurrentAccountData();
    this.currentUserData = this.sessionData.getCurrentUserData();
    this.isShipmentIsVisible = this.currentAccountData.shipmentIsVisible;
    this.isBookingIsVisible = this.currentAccountData.bookingIsVisible;
    this.isCustomsIsVisible = this.currentAccountData.customsIsVisible;
    this.isAdmin =
      this.currentUserData.userType == 'systemadmin' ? true : false;
  }
  /* istanbul ignore next */
  getTopMenuSelection() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.navBarSelection(event);
        let urlSplit = event.url.split('/');
        let decryUrlFilter: any = '';
        if (
          event.url.indexOf('shipments') != -1 &&
          event.url.indexOf('returnUrl') == -1 &&
          event.url.indexOf('search-result') == -1
        ) {
          this.highlightMenuInShareShipments(urlSplit, decryUrlFilter);
        } else if (
          event.url.indexOf('bookings') != -1 &&
          event.url.indexOf('returnUrl') == -1 &&
          event.url.indexOf('search-result') == -1
        ) {
          this.highlightMenuInShareBookings(urlSplit, decryUrlFilter);
        } else if (
          event.url.indexOf('customs') != -1 &&
          event.url.indexOf('returnUrl') == -1 &&
          event.url.indexOf('search-result') == -1
        ) {
          this.highlightMenuInShareCustoms(urlSplit, decryUrlFilter);
        } else if (
          event.url.indexOf('dashboard') != -1 &&
          event.url.indexOf('returnUrl') == -1 &&
          event.url.indexOf('search-result') == -1
        ) {
          this.highlightMenuInShareDashboard(urlSplit, decryUrlFilter);
        } else {
          this.notLoggedIn = false;
        }
      }
    });
  }
  /* istanbul ignore next */
  highlightMenuInShareShipments(urlSplit: any, decryUrlFilter: any) {
    if (urlSplit.length > 2 && urlSplit[2] !== 'search') {
      decryUrlFilter = JSON.parse(atob(urlSplit[2]));
    }
    if (!this.currentUserData) {
      this.notLoggedIn = true;
    }
    if (this.userSession()) {
      this.HighlightTopMenuSelection(false, true, false, false, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
    }
  }
  /* istanbul ignore next */
  highlightMenuInShareBookings(urlSplit: any, decryUrlFilter: any) {
    if (urlSplit.length > 2) {
      decryUrlFilter = JSON.parse(atob(urlSplit[2]));
    }

    if (decryUrlFilter.type == 'Detail' && !this.currentUserData) {
      this.notLoggedIn = true;
    }
    if (this.userSession()) {
      this.HighlightTopMenuSelection(false, false, true, false, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
    }
  }
  /* istanbul ignore next */
  highlightMenuInShareCustoms(urlSplit: any, decryUrlFilter: any) {
    if (urlSplit.length > 2) {
      decryUrlFilter = JSON.parse(atob(urlSplit[2]));
    }

    if (decryUrlFilter.type == 'Detail' && !this.currentUserData) {
      this.notLoggedIn = true;
    }
    if (this.userSession()) {
      this.HighlightTopMenuSelection(false, false, false, true, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
    }
  }
  /* istanbul ignore next */
  highlightMenuInShareDashboard(urlSplit: any, decryUrlFilter: any) {
    if (urlSplit.length > 2) {
      decryUrlFilter = JSON.parse(atob(urlSplit[2]));
    }
    if (this.userSession()) {
      this.HighlightTopMenuSelection(true, false, false, false, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
    }
  }
  /* istanbul ignore next */
  navBarSelection(event: any) {
    switch (event.url.toLocaleLowerCase()) {
      case '/faq':
        this.FaqMenuSelected();
        break;
      case '/shipments':
        if (this.userSession()) {
          this.ShipmentMenuSelected();
        }
        break;
      case '/dashboard':
        this.DashboardMenuSelected();
        break;
      case '/bookings':
        if (this.userSession()) {
          this.BookingMenuSelected();
        }
        break;
      case '/customs':
        if (this.userSession()) {
          this.CustomsMenuSelected();
        }
        break;
      case '/reports':
        if (this.userSession()) {
          this.reportMenuSelected();
        }
        break;
      default:
        this.FaqMenuSelected();
    }
  }
  /* istanbul ignore next */
  DashboardMenuSelected() {
    if (!this.checkUnsavedBroadcastMsg) {
      this.broadCastSessioncheck();
      this.HighlightTopMenuSelection(true, false, false, false, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
      if (!this.userSession()) {
        this.sessionCheck();
      }
      this.router.navigate(['/dashboard']);
    }
  }
  /* istanbul ignore next */
  ShipmentMenuSelected() {
    if (!this.checkUnsavedBroadcastMsg) {
      this.HighlightTopMenuSelection(false, true, false, false, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
      this.broadCastSessioncheck();
      if (!this.userSession()) {
        this.sessionCheck();
      }
      this.router.navigate(['/shipments']);
    }
  }
  /* istanbul ignore next */
  BookingMenuSelected() {
    if (!this.checkUnsavedBroadcastMsg) {
      this.broadCastSessioncheck();
      this.HighlightTopMenuSelection(false, false, true, false, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
      if (!this.userSession()) {
        this.sessionCheck();
      }
      this.router.navigate(['/bookings']);
    }
  }
  /* istanbul ignore next */
  CustomsMenuSelected() {
    if (!this.checkUnsavedBroadcastMsg) {
      this.broadCastSessioncheck();
      this.HighlightTopMenuSelection(false, false, false, true, false);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
      if (!this.userSession()) {
        this.sessionCheck();
      }
      this.router.navigate(['/customs']);
    }
  }
  /* istanbul ignore next */
  reportMenuSelected() {
    if (!this.checkUnsavedBroadcastMsg) {
      this.broadCastSessioncheck();
      this.HighlightTopMenuSelection(false, false, false, false, true);
      $('#mobileMenu').attr('aria-expanded', 'false');
      $('#main_nav').removeClass('show');
      if (!this.userSession()) {
        this.sessionCheck();
      }
      this.router.navigate(['/reports']);
    }
  }
  /* istanbul ignore next */
  logoClick() {
    if (!this.checkUnsavedBroadcastMsg) {
      this.router.navigate(['/dashboard']);
    }
  }
  /* istanbul ignore next */
  FaqMenuSelected() {
    this.HighlightTopMenuSelection(false, false, false, false, false);
  }
  /* istanbul ignore next */
  HighlightTopMenuSelection(
    isDashboard: boolean,
    isShipment: boolean,
    isBooking: boolean,
    isCustoms: boolean,
    isReport: boolean
  ) {
    this.isDashboardSelected = isDashboard;
    this.isShipmentSelected = isShipment;
    this.isBookingsSelected = isBooking;
    this.isCustomsSelected = isCustoms;
    this.isReportSelected = isReport;
  }

  ngOnInit(): void {
    //this.loadInitialPageData();
    this.getTopMenuSelection();
    this.getUser();
    this.searchGlobalSearchList();
    this.loadDataSharingService();

  }

  loadDataSharingService() {
    this.dataSharingService.notLoggedIn.subscribe((x) => {
      if (x) {
        this.notLoggedIn = true;
      } else {
        this.notLoggedIn = false;
      }
    });
  }

  searchGlobalSearchList() {
    this.globalSearchInput.pipe(debounceTime(700)).subscribe((e: any) => {
      if (e[1].key !== 'Enter' && e[1].keyCode != 13) {
        this.autoSuggestShipment(e[0]);
      }
    })
  }

  /* istanbul ignore next */
  /*loadInitialPageData() {
    this.getTopMenuSelection();
    this.getUser();
    if (this.currentUserData) {
      setTimeout(() => {
        fromEvent(this.quicksearchBtn.nativeElement, 'keyup')
          .pipe(
            map((event: any) => {
              return event;
            }),
            debounceTime(800),
          )
          .subscribe((event: any) => {
            if (event.key !== 'Enter' && event.keyCode != 13) {
              this.autoSuggestShipment(event.target.value);
            }
          });
      }, 0);
    }
    this.dataSharingService.notLoggedIn.subscribe((x) => {
      if (x) {
        this.notLoggedIn = true;
      } else {
        this.notLoggedIn = false;
      }
    });
  }*/
  /* istanbul ignore next */
  getUser() {
    this.userName =
      this.currentUserData.firstName + ' ' + this.currentUserData.lastName;
    this.userEmail = this.currentUserData.emailAddress;
    this.showNPLink = CommonValidation.canAccessNP(this.userEmail);
    this.shareData.imageGetting.subscribe((res) => {
      if (res) {
        this.profileImgUrl = 'data:image/jpg;base64,' + res;
        this.profileImgUrl2x = 'data:image/jpg;base64,' + res;
      } else if (!res && this.currentUserData.imagecontent) {
        this.profileImgUrl =
          'data:image/jpg;base64,' + this.currentUserData.imagecontent;
        this.profileImgUrl2x =
          'data:image/jpg;base64,' + this.currentUserData.imagecontent;
      } else {
        this.profileImgUrl = 'assets/img/profile-image-holder.png';
        this.profileImgUrl2x = 'assets/img/profile-image-holder@2x.png 2x';
      }
    });

    if (
      this.currentAccountData &&
      this.currentAccountData.featureList.length > 0
    ) {
      this.currentAccountData.featureList.forEach((value: any) => {
        switch (value.featureName.trim()) {
          case 'Dashboard':
            this.showDashboard = value?.isVisible;
            break;
          case 'Shipments':
            this.showShipment = value?.isVisible;
            break;
          case 'Bookings':
            this.showBooking = value?.isVisible;
            break;
          case 'Customs':
            this.showCustoms = value?.isVisible;
            break;
          case 'MyProfile':
            this.showProfile = value?.isVisible;
            break;
          case 'Report':
            this.showReport = value?.isVisible;
            break;
          case 'Reports':
            this.showReport = value?.isVisible;
            break;
        }
      });
    }
  }
  /* istanbul ignore next */
  logout(): void {
    this.appInsightService.logEvent('User Logout');
    this.appInsightService.clearUserId();
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = environment.localUrl + '/login/log-in';
  }
  /* istanbul ignore next */
  quickSearch() {
    if (!this.isMobileViewParent) {
      if (this.shipmentSearchText.length < 1) {
        this.isGlobalSearch = false;
        this.isQuickSearch = true;
      } else if (this.shipmentSearchText.length > 0) {
        this.isQuickSearch = false;
      } else {
        this.isQuickSearch = true;
        this.isGlobalSearch = false;
      }
    } else {
      this.isQuickSearch = true;
      this.isGlobalSearch = false;
    }
  }

  closeQuickSearchModal(e: any) {
    this.isQuickSearch = e;
  }

  globalSearchList(event: any) {
    if (event.target.value) {
      this.isGlobalSearch = true;
      this.isQuickSearch = false;
    } else {
      this.isGlobalSearch = false;
    }
  }

  autoSuggestShipment(val: any) {
    this.shipmentAutoSuggestedRecord = [];
    this.bookingAutoSuggestedRecord = [];
    this.customsAutoSuggestedRecord = [];
    this.totalShipmentCount = 0;
    this.totalBookingCount = 0;
    this.totalCustomsCount = 0;
    this.globalSearchAutoSuggestion(val);
  }

  globalSearchAutoSuggestion(val: any) {
    if (val.length > 2) {
      this.filterService.shipmentAutoSuggesion(val.trim()).subscribe((res) => {
        if (res.result != null || res.result) {
          if (res.result.shipment != null) {
            this.shipmentAutoSuggestedRecord = res.result.shipment.records;
            this.totalShipmentCount = res.result.shipment.totalCount;
          }
          if (res.result.booking != null) {
            this.bookingAutoSuggestedRecord = res.result.booking.records;
            this.totalBookingCount = res.result.booking.totalCount;
          }
          if (res.result.custom != null) {
            this.customsAutoSuggestedRecord = res.result.custom.records;
            this.totalCustomsCount = res.result.custom.totalCount;
          }
        }
      });
    }
  }
  /* istanbul ignore next */
  recentSearchSave(val: any) {
    if (val.length > 2) {
      var getLocalStorageValue = localStorage.getItem('globalRecentSearch');
      var parserRecentSearch =
        getLocalStorageValue !== null ? JSON.parse(getLocalStorageValue) : '';
      var selectedSearchValue: any[] = [];
      if (parserRecentSearch) {
        var elementPosDestination = parserRecentSearch
          .map(function (x: any) {
            return x;
          })
          .indexOf(val);
        if (elementPosDestination < 0) {
          selectedSearchValue = [].concat(val, parserRecentSearch);
        } else {
          selectedSearchValue = [].concat(parserRecentSearch);
        }
      } else {
        selectedSearchValue = [].concat(val);
      }

      var finalRecentList = selectedSearchValue.slice(0, 10);
      localStorage.setItem(
        'globalRecentSearch',
        JSON.stringify(finalRecentList)
      );
      setTimeout(() => {
        this.resultPage(val);
      }, 100);
    }
  }

  clearGlobalSearchList(val: any) {
    if (val.length < 1) {
      this.shipmentAutoSuggestedRecord = [];
      this.totalShipmentCount = 0;
    }
  }

  clearSearchTextBox(event: any) {
    this.shipmentSearchText = '';
  }

  clearSearchResult(event: any) {
    this.isGlobalSearch = false;
  }
  /* istanbul ignore next */
  resultPage(val: any) {
    if (val.length > 2) {
      this.shipmentSearchText = '';
      this.isGlobalSearch = false;
      let filterVal = JSON.stringify({ val: val, from: '' });
      this.router.navigate(['/shipments/view/search-result'], {
        queryParams: { filterParam: filterVal },
        skipLocationChange: true,
      });
    }
  }

  onSearchExpand(event: any) {
    if (!this.checkUnsavedBroadcastMsg) {
      this.isMobileSearch = true;

      if (event) {
        this.searchExpand = true;
      }
    }
  }

  closeSearch() {
    this.isMobileSearch = false;
    this.isQuickSearch = false;
    this.isGlobalSearch = false;
  }
  /* istanbul ignore next */
  sessionCheck() {
    if (localStorage.getItem('userStatus') != '1') {
      this.loginDisplay = false;
      window.location.href = environment.localUrl + '/login/log-in';
    }
  }

  broadCastSessioncheck() {
    if (localStorage.getItem('userStatus') == '1') {
      this.dataSharingService.updateBroadcastMessage.next(true);
    }
  }

  userSession() {
    if (localStorage.getItem('userStatus') == '1') {
      return true;
    } else {
      return false;
    }
  }

  reorderSearchList() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.globalSearchSelection(event);
      }
    });
  }

  globalSearchSelection(event: any) {
    let fields = event.url.split('?');
    let fieldsSplit = fields[0].split('/');
    let name = fieldsSplit[0] ? fieldsSplit[0] : fieldsSplit[1];
    switch (name.toLocaleLowerCase()) {
      case 'bookings':
        this.shipmentRoute = 2;
        this.bookingRoute = 1;
        this.customsRoute = 3;
        break;
      case 'customs':
        this.shipmentRoute = 2;
        this.bookingRoute = 3;
        this.customsRoute = 1;
        break;
      default:
        this.shipmentRoute = 1;
        this.bookingRoute = 2;
        this.customsRoute = 3;
    }
  }

  closeDropDown() {
    $('#profileListDropdown').removeClass('show');
    $('#dropdownUser1').attr('aria-expanded', 'false');
    $('#dropdownUser1').removeClass('show');
  }

  clearGlobalSearch() {
    this.shipmentSearchText = '';
  }
  onPaste(event: any) {
    console.log('fired = ', event.target.value, ', ', this.shipmentSearchText);
    this.searchExpand = true;
  }
}
