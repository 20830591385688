import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { DetailTabName } from 'src/app/common/shipment.enums';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { environment } from 'src/environments/environment';

import { ShipmentServies } from '../../services/shipment.service';
import { ActionDetailTabComponent } from '../action-detail-tab/action-detail-tab.component';
declare var $: any;
@Component({
  selector: 'app-shipment-header',
  templateUrl: './shipment-header.component.html',
  styleUrls: ['./shipment-header.component.scss'],
})
export class ShipmentHeaderComponent implements OnChanges, OnInit {
  @Input() selectedShipment: any = [];
  @Input() actionFieldRecords: any = [];
  @Output() prepareUrlShare = new EventEmitter<any>();
  @Output() reloadShipmentList = new EventEmitter<any>();
  @Output() reloadShipmentListFromHeader = new EventEmitter<any>();
  @Output() refreshShipmentDetailsFromHeader = new EventEmitter<any>();
  @Input() urlShare: any = '';
  @Input() publicShareVisibility = false;
  @Input() publicShareType: any = '';
  @Input() publicShareEnrcyptedData: any = '';
  @Input() refreshFalse: boolean = false;
  readMoreNotes: boolean = false;
  readMoreBookRef: boolean = false;
  popupToggleList = false;
  popupToggleDetail = false;
  copyText: any = '';
  isProgressShow = false;
  toggleAdd = false;
  toggleRemove = false;
  showToastMessage = false;
  message = '';
  logsTabCount = 0;
  watchListToggle: boolean;
  fullScreenStatus: boolean = false;
  isShowMessage = false;
  showMessage: any = '';
  isShowMessageFileuploadSec = false;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  shipperName: string = '---';
  consigneeName: string = '---';
  scrHeight: any;
  scrWidth: any;
  refLengthCheck: number = 0;
  shipmentRefreshIcon: boolean = false;
  shipmentRefreshToast = false;
  isActionField: boolean = false;
  @ViewChild(ActionDetailTabComponent)
  actionFieldComponent: ActionDetailTabComponent;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private shipmentService: ShipmentServies,
    private router: Router,
    private currentSessionUserData: CurrentSessionDataService,
    private appInsightsService: AppInsightsService,
    public dataSharingService: DataSharingService,
    private fileService: FileUploadService
  ) {
    this.isActionField = this.currentAccountData.isShipmentActionField;
    this.shipmentService.actionFieldExist = this.isActionField;
    this.errorMessage();
    this.fileService.showToastEdocRefresh.subscribe((value) => {
      if (value.type == 'shipment') {
        let root = this;
        this.isShowMessageFileuploadSec = value.msgShow;
        this.fileService.showToastEdocRefresh.next({});
        setTimeout(function () {
          root.isShowMessageFileuploadSec = false;
        }, 3000);
      }
    });

    this.dataSharingService.refreshShipmentDetailsInHeader.subscribe(
      (value: any) => {
        if (value) {
          this.shipmentRefreshIcon = true;
        } else {
          this.shipmentRefreshIcon = false;
        }
      }
    );
    this.dataSharingService.refreshShipmentDetailsInHeaderToaster.subscribe(
      (value: any) => {
        if (value) {
          this.shipmentRefreshToast = true;
          this.isShowMessage = false;
          this.showToastMessage = false;
          setTimeout(() => {
            this.shipmentRefreshToast = false;
            this.errorMessage();
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              false
            );
          }, 4000);
        } else {
          this.shipmentRefreshToast = false;
        }
      }
    );
  }

  ngOnInit(): void {
    let selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab == 'document') {
      this.openSelectedTab('docs');
    }

    localStorage.removeItem('selectedTab');

    this.getScreenSize();
    this.refLengthCal();

    $('.no-close').on('click', function (e: any) {
      e.stopPropagation();
    });
    this.showLogTab();
  }

  ngOnChanges(): void {
    this.setCurrentTab(1); // 1 for detail tab
    this.readMoreBookRef =
      this.selectedShipment?.booking_reference?.length > 60;
    if (this.selectedShipment?.notes) {
      this.readMoreNotes = this.selectedShipment.notes[0].body?.length > 180;
    }
    this.watchListToggle = this.selectedShipment?.favoriteflag;

    let { shipperName, consigneeName } = CommonUtils.getShipperConsigneeName(
      this.selectedShipment
    );
    this.shipperName = shipperName;
    this.consigneeName = consigneeName;
    if (this.currentAccountData) {
      this.getLogsCount();
    }
    setTimeout(() => {
      this.loadActionFieldsDetails();
    }, 20);
  }

  setCurrentTab(tabIndex: number = 1) {
    switch (tabIndex) {
      case 2:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.DocumentTab
        );
        break;
      case 3:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.ActionTab
        );
        break;
      case 4:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.ContainerTab
        );
        break;
      case 5:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.CustomTab
        );
        break;
      case 6:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.LogsTab
        );
        break;
      default:
        this.shipmentService.activeShipmentDetailTab.next(
          DetailTabName.DetailTab
        );
        break;
    }
  }

  share(shipmentId: any, type: any) {
    this.appInsightsService.logEvent(
      AI_CommonEvents.ShareSingleItem.toString(),
      {
        [AI_CustomProps.ShipmentId]: shipmentId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Shipment,
      }
    );
    this.popupToggleDetail = true;
    let accountId = '';
    let aliasName = '';
    let isGroup = false;
    let isParent = false;
    let subType = '';
    if (this.publicShareEnrcyptedData) {
      var decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      subType = decryUrlFilter.subType;
    }
    if (this.currentAccountData) {
      if (decryUrlFilter) {
        if (
          !decryUrlFilter?.isFullViewAccess &&
          decryUrlFilter.type !== 'viewAll'
        ) {
          accountId = decryUrlFilter.accountDetail.oH_Code;
          aliasName = decryUrlFilter.accountDetail.aliasName;
          isGroup = decryUrlFilter.accountDetail.byGroup;
          isParent = decryUrlFilter.accountDetail.isControllingCustomer;
        } else {
          accountId = this.currentAccountData.accountId;
          aliasName = this.currentAccountData.accountAliasName;
          isGroup = this.currentAccountData.isGroup;
          isParent = this.currentAccountData.isParent;
        }
      } else {
        accountId = this.currentAccountData.accountId;
        aliasName = this.currentAccountData.accountAliasName;
        isGroup = this.currentAccountData.isGroup;
        isParent = this.currentAccountData.isParent;
      }
    } else {
      if (decryUrlFilter) {
        accountId = decryUrlFilter.accountDetail.oH_Code;
        aliasName = decryUrlFilter.accountDetail.aliasName;
        isGroup = decryUrlFilter.accountDetail.byGroup;
        isParent = decryUrlFilter.accountDetail.isControllingCustomer;
      }
    }
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailPageRefreshed.toString(),
      {
        [AI_CustomProps.ShipmentId]: shipmentId,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
        [AI_CustomProps.AccountName]: aliasName,
        [AI_CustomProps.IsGroup]: isGroup ? 'True' : 'False',
        [AI_CustomProps.EncryptedId]: this.selectedShipment?.encryptedId,
      }
    );
    this.prepareUrlShare.emit({
      type: type,
      shipmentId: shipmentId,
      accountId: accountId ? accountId : '',
      aliasName: aliasName ? aliasName : '',
      isGroup: isGroup ? isGroup : false,
      popUp: true,
      subType: subType,
      isParent: isParent,
      shipment: { encryptedId: this.selectedShipment?.encryptedId },
    });
  }

  detailShare(event: any) {
    this.prepareUrlShare.emit(event);
  }

  showLogsTab: boolean = false;
  getLogsCount() {
    this.shipmentService
      .getLogsCount(
        this.selectedShipment.forwarder_reference,
        this.currentUserData.emailAddress
      )
      .subscribe((res) => {
        if (res && res.result) {
          let results = res.result;
          //let fResults=results.filter((e:any) =>e.changeLogCategory!==16) // 16 is actionFields Logs
          this.showLogsTab = results?.length > 0;
          //this.logsTabCount = res.recordCount;  // using object before action fields
        }
      });
  }

  sharePopUpfeatureHide() {
    this.popupToggleDetail = false;
  }

  getGrossWeight(selectedShipment: any) {
    let message = '';
    if (selectedShipment?.gross_weight_kgs) {
      message = selectedShipment?.gross_weight_kgs + ' kgs';
    } else if (selectedShipment?.gross_weight_lbs) {
      message = selectedShipment?.gross_weight_lbs + ' lbs';
    } else {
      message = '---';
    }
    return message;
  }

  getStatusHint(data: any) {
    return CommonUtils.getStatusHint(data);
  }

  addWatchList(action: any, shipmentId: any) {
    let eventName =
      action == 'Add'
        ? AI_CommonEvents.WatchListAdded.toString()
        : AI_CommonEvents.WatchListRemoved.toString();

    this.appInsightsService.trackAIWatchListEvent(
      eventName,
      shipmentId,
      AI_ModulesName.Shipment
    );

    let emailId = this.currentUserData.emailAddress;
    this.isProgressShow = true;

    this.shipmentService
      .addRemoveWatchList(shipmentId, emailId, action)
      .subscribe({
        next: (addRemoveWatch: any) => {
          this.showToastMessage = true;
          this.message = addRemoveWatch.result;
          this.isProgressShow = false;
          if (action == 'Add') {
            this.watchListToggle = true;
          } else if (action == 'Remove') {
            this.watchListToggle = false;
          }
          this.reloadShipmentListFromHeader.emit(shipmentId);
          this.hideToast();
        },
      });
  }

  hideToast() {
    let root = this;
    setTimeout(function () {
      root.showToastMessage = false;
      root.isProgressShow = false;
    }, 3000);
  }

  getShipmentReferenceNumbers(order: any, limit: number) {
    return CommonUtils.getShipmentReferenceNumbers(order, limit);
  }

  openShipmentFullScreen(event: any, shipmentId: any, type: any) {
    if (type == 'list') {
      this.appInsightsService.trackAIFullScreenViewedEvent(
        shipmentId,
        AI_ModulesName.Shipment
      );

      let selectedFilters = {
        mainFilter: [],
        statusFilter: [],
        transportType: [],
        exceptionType: [],
        watchFilter: [],
        containerType: [],
        origin: {
          selectedValue: {},
          selectedAccount: [],
        },
        destination: {
          selectedValue: {},
          selectedAccount: [],
        },
        dateRange: {
          selectedValue: {},
          selectedDateRange: [],
        },
        shipper: {
          selectedValue: {},
          selectedAccount: [],
        },
        consignee: {
          selectedValue: {},
          selectedAccount: [],
        },
        allLocation: {
          selectedAccount: [
            { origin: [] },
            { destination: [] },
            { pickup: [] },
            { finalDelivery: [] },
          ],
          selectedValue: [
            { originValue: {} },
            { destinationValue: {} },
            { pickupValue: {} },
            { finalDeliveryValue: {} },
          ],
        },
        sorting: [],
        accountId: '',
        shipmentId: shipmentId,
        type: 'Single',
      };
      let encry = btoa(JSON.stringify(selectedFilters));
      const url = environment.localUrl + '/shipments/' + encry;
      window.open(url, '_blank');
    } else {
      this.fullScreenStatus = true;
      $('#shipmentFull').addClass('shipmet-fullwidth shimpent-removeicon');
      $('#navCenter').addClass('justify-content-center');
      $('.dimScreen').addClass('shipment-fullwidth');
    }
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  redirectToLogin() {
    this.dataSharingService.notLoggedIn.next(false);
    if (this.publicShareEnrcyptedData) {
      let decryUrlFilter = JSON.parse(atob(this.publicShareEnrcyptedData));
      if (decryUrlFilter?.subType == 'publicSearch') {
        this.dataSharingService.quickSearch.next('');
        this.router.navigate(['/login/log-in']);
      } else {
        const url = '/shipments/' + this.publicShareEnrcyptedData;
        this.router.navigate(['/login/log-in'], {
          queryParams: { returnUrl: url },
          skipLocationChange: true,
        });
      }
    } else {
      this.dataSharingService.quickSearch.next('');
      this.router.navigate(['/login/log-in']);
    }
  }

  closeToasterMsg() {
    this.showToastMessage = false;
    this.message = '';
    this.isShowMessage = false;
    this.showMessage = '';
  }

  closeRefreshToast() {
    this.shipmentRefreshToast = false;
  }

  closeToasterFromFileUpload() {
    this.isShowMessageFileuploadSec = false;
  }

  gotoRefSection() {
    $('#navCenter > li> button').removeClass('active');
    $('#details-shipment-tab').addClass('active');
    // $('#myTabContent > div').removeClass('show active');
    $('#shipment-tab').addClass('show active');
    const scrollDiv = window.document.getElementById('shipmentRefSec');
    if (scrollDiv != null) {
      scrollDiv.scrollIntoView();
    }
  }

  detailTabScrollTop() {
    $('#details-shipment-tab').addClass('active');
    const scrollDiv = window.document.getElementById('shipmentDetailTab');
    if (scrollDiv != null) {
      setTimeout(() => {
        scrollDiv.scrollIntoView();
      }, 10);
    }
    this.tabSelected('details');
  }

  refLengthCal() {
    setTimeout(() => {
      const classFullScreen = $('#shipHeader').hasClass('fixed-fullwidth');
      const classhalfScreen = $('#shipHeader').hasClass('fixed-halfwidth');
      if (this.scrWidth >= 1100 && classhalfScreen) {
        this.refLengthCheck = 74;
      } else if (this.scrWidth > 1100 && classFullScreen) {
        this.refLengthCheck = 155;
      } else if (this.scrWidth < 1100 && classFullScreen) {
        this.refLengthCheck = 90;
      } else {
        this.refLengthCheck = 72;
      }
    }, 10);
  }

  refreshShipmentDetails() {
    if (this.currentAccountData && this.selectedShipment?.isFullViewAccess) {
      this.getLogsCount();
    }
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailPageRefreshed.toString(),
      {
        [AI_CustomProps.ShipmentId]: this.selectedShipment?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Custom,
      }
    );
    this.refreshShipmentDetailsFromHeader.emit({
      shipmentId: this.selectedShipment?.forwarder_reference,
      access: this.selectedShipment?.isFullViewAccess,
    });
  }

  errorMessage() {
    if (!this.currentUserData) {
      this.isShowMessage = true;
      this.showMessage =
        'To see full shipment details, please login by clicking here.';
    }
    setTimeout(() => {
      if (this.currentUserData && !this.selectedShipment?.isFullViewAccess) {
        this.showToastMessage = true;
        this.message = 'You are viewing a shared shipment with limited access.';
      }
    }, 0);
  }

  actionFieldRecordsCount: any = [];
  loadActionFieldsDetails() {
    if (this.shipmentService.openActionTab) {
      this.openSelectedTab('actions');
    } else if (this.shipmentService.openDocumentTab) {
      this.openSelectedTab('docs');
    } else if (this.shipmentService.openDetailsTab) {
      this.openSelectedTab('details');
    } else if (this.shipmentService.openContainerTab) {
      this.openSelectedTab('containers');
    } else if (this.shipmentService.openCustomTab) {
      this.openSelectedTab('customs');
    } else if (this.shipmentService.openLogsTab) {
      this.openSelectedTab('logs');
    }
  }

  openSelectedTab(selectedTab: any) {
    this.isProgressShow = true;
    setTimeout(() => {
      if (selectedTab == "details") {
        $('#details-shipment-tab').tab('show');
      }
      else if (selectedTab == "docs") {
        $('#profile-tab').tab('show');
      }
      else if (selectedTab == "containers") {
        $('#contact-tab').tab('show');
      }
      else if (selectedTab == "customs") {
        $('#child-tab').tab('show');
      }
      else if (selectedTab == "actions") {
        $('#action-tab').tab('show');
      }
      else if (selectedTab == "logs") {
        $('#logs-tab').tab('show');
      }
      this.isProgressShow = false;
    }, 0);
  }


  tabSelected(selectedTab: any) {
    if (selectedTab == "details") {
      this.flagSelectedTabonOrder([true, false, false, false, false, false]);
    }
    else if (selectedTab == "docs") {
      this.flagSelectedTabonOrder([false, true, false, false, false, false]);
    }
    else if (selectedTab == "containers") {
      this.flagSelectedTabonOrder([false, false, true, false, false, false]);
    }
    else if (selectedTab == "customs") {
      this.flagSelectedTabonOrder([false, false, false, true, false, false]);
    }
    else if (selectedTab == "actions") {
      this.flagSelectedTabonOrder([false, false, false, false, true, false]);
    }
    else if (selectedTab == "logs") {
      this.flagSelectedTabonOrder([false, false, false, false, false, true]);
    }
  }

  flagSelectedTabonOrder(set: any) {
    this.shipmentService.openDetailsTab = set[0];
    this.shipmentService.openDocumentTab = set[1];
    this.shipmentService.openContainerTab = set[2];
    this.shipmentService.openCustomTab = set[3];
    this.shipmentService.openActionTab = set[4];
    this.shipmentService.openLogsTab = set[5];
  }

  @ViewChild('logTabBtn') logTabBtn: ElementRef
  showLogTab() {
    this.shipmentService.openLogTab.subscribe((e: any) => {
      if (e) {
        this.logTabBtn.nativeElement.click();
      }
    })
  }

}
