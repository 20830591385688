<div class="shipment-customs-tab mt-5">
  <div class="col-md-12 pr-0 view-custom-details">
    <h4 class="custom-title-message" *ngIf="
        selectedShipment?.customstatus == 'Filed' ||
        selectedShipment?.customstatus == 'In Progress' ||
        selectedShipment?.customstatus == 'Released' ||
        (selectedShipment?.customs_declarations[0]?.customs_type != 'EXP' &&
          (selectedShipment?.customException == 'On Hold' ||
            selectedShipment?.customException == 'Delayed'))
      ">
      <span [innerHTML]="selectedShipment | customDetailClearanceNotificationText"></span>
    </h4>
    <div class="view-shipment-btn-ctr">
      <button class="view-shipment" (click)="openCustomsDetails()">
        VIEW CUSTOMS DETAILS
      </button>
    </div>
    <div class="container-color-bg mt-4">
      <h4>DETAILS</h4>
      <div class="mobile-view row">
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedShipment?.isFullViewAccess">
          <h5>Supplier</h5>
          <p>
            {{
            selectedShipment?.supplier?.name
            ? selectedShipment?.supplier?.name
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedShipment?.isFullViewAccess">
          <h5>Importer of Records</h5>
          <p>
            {{
            selectedShipment?.importer?.name
            ? selectedShipment?.importer?.name
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedShipment?.isFullViewAccess">
          <h5>Consignee</h5>
          <p>
            {{
            selectedShipment?.ultimate_consignee?.name
            ? selectedShipment?.ultimate_consignee?.name
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6">
          <h5>Carrier</h5>
          <p>
            {{
            selectedShipment?.carrier?.name
            ? selectedShipment?.carrier?.name
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>Manufacturer</h5>
          <p>
            {{
            selectedShipment?.manufacturer?.name
            ? selectedShipment?.manufacturer?.name
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedShipment?.isFullViewAccess">
          <h5>Shipper</h5>
          <p>
            {{
            selectedShipment?.consignor?.name
            ? selectedShipment?.consignor.name
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="selectedShipment?.isFullViewAccess">
          <h5>Customs Type</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.customs_type
            ? selectedShipment?.customs_declarations[0].customs_type
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type === 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>ITN Number</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.declaration_number
            ? selectedShipment?.customs_declarations[0].declaration_number
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>Entry Number</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.declaration_number
            ? selectedShipment?.customs_declarations[0].declaration_number
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>IT Number</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.us_customs_declaration
            ?.it_number
            ? selectedShipment?.customs_declarations[0]
            ?.us_customs_declaration.it_number
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>Entry Filed</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.filed_date
            ? getDateFormated(
            selectedShipment?.customs_declarations[0].filed_date
            )
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>Entry Released</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.release_date
            ? getDateFormated(
            selectedShipment?.customs_declarations[0].release_date
            )
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>IT Date</h5>
          <p>
            {{
            selectedShipment?.customs_declarations[0]?.us_customs_declaration
            ?.it_date
            ? getDateFormated(
            selectedShipment?.customs_declarations[0]
            ?.us_customs_declaration?.it_date
            )
            : "---"
            }}
          </p>
        </div>
        <ng-container *ngIf="selectedShipment?.customstatus !== 'FTZ'">
          <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
              selectedShipment?.customs_declarations[0]?.customs_type !==
                'EXP' && selectedShipment?.isFullViewAccess
            ">
            <h5>Customs Cleared</h5>
            <p>
              {{
              selectedShipment?.customs_declarations[0]?.release_date
              ? getDateFormated(
              selectedShipment?.customs_declarations[0].release_date
              )
              : "---"
              }}
            </p>
          </div>

          <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
              selectedShipment?.customs_declarations[0]?.customs_type !==
                'EXP' && selectedShipment?.isFullViewAccess
            ">
            <h5>Country of Export</h5>
            <p>
              {{
              selectedShipment?.customs_declarations[0]?.export_country_code
              ? selectedShipment?.customs_declarations[0]
              ?.export_country_code
              : "---"
              }}
            </p>
          </div>
        </ng-container>

        <div class="col-sm-6 col-md-4 line-set col-6">
          <h5>Master Bill of Lading</h5>
          <p>
            {{
            selectedShipment?.master_bill
            ? selectedShipment?.master_bill
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6">
          <h5>House Bill of Lading</h5>
          <p>
            {{
            checkHBL(selectedShipment) !== null
            ? checkHBL(selectedShipment)
            : "---"
            }}
          </p>
        </div>
        <div class="col-sm-6 col-md-4 line-set col-6 mob-top" *ngIf="
            selectedShipment?.customs_declarations[0]?.customs_type !== 'EXP' &&
            selectedShipment?.isFullViewAccess
          ">
          <h5>Participating Gov. Agencies</h5>
          <div *ngIf="
              selectedShipment?.customs_declarations[0]?.customs_agency_statuses
                ?.length > 0
            ">
            <span *ngFor="
                let cas of selectedShipment?.customs_declarations[0]
                  ?.customs_agency_statuses
              ">
              <p>
                {{ cas?.agency_code }}
                <ng-container *ngIf="cas?.agency_code && cas?.status_message">-</ng-container>
                {{ cas?.status_message }}
                <ng-container *ngIf="cas?.status_date && cas?.status_message">-</ng-container>
                {{ getDateFormated(cas?.status_date) }}
              </p>
            </span>
          </div>
          <p *ngIf="
              selectedShipment?.customs_declarations[0]?.customs_agency_statuses
                ?.length < 1
            ">
            ---
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="shipment-detail view-custom-layout-change">
    <h3 class="update-sec">CUSTOMS PROGRESS</h3>
    <app-customs-details-progressbar [selectedCustom]="selectedShipment"></app-customs-details-progressbar>
  </div>
  <div class="pga-details-set" *ngIf="selectedShipment.isFullViewAccess">
    <h3 class="update-sec" *ngIf="
                  selectedShipment.customs_declarations[0]?.customs_agency_statuses
                    ?.length > 0 && selectedShipment.customs_declarations[0]?.customs_type !== 'EXP'
                    && selectedShipment.customs_declarations[0]?.customs_type !== 'OUT'
                    && selectedShipment.customs_declarations[0]?.customs_type !== 'IPT' && 
                    selectedShipment?.commercial_invoices?.length >0">PGA DETAILS</h3>
    <app-customs-pga-tab [selectedCustom]="selectedShipment"></app-customs-pga-tab>
  </div>
</div>