import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';

@Pipe({
  name: 'containerListPipe',
})
export class ContainerListPipe implements PipeTransform {
  transform(data: any, index: number): string {
    let returnHtml = '';
    let displayText = '';
    if (data?.containers) {
      data.containers.forEach((e: any) => {
        displayText +=
          '<div>' +
          e.container_number +
          '(' +
          e.size_code +
          '), DEL: ' +
          CommonUtils.formatDateMMMDDYYYY(e.arrival_delivery_actual) +
          '</div>';
      });
      returnHtml +=
        '<button class="more-btn-set" data-toggle="modal" data-target="#exampleModal-' +
        index +
        '"><img class="view-eye icon-cntrl" src="../../../../../assets/img/pickup-location.svg" alt=""/> </button>';
      returnHtml +=
        '<div class="new-more-btn modal fade drap-info-msg" id="exampleModal-' +
        index +
        '" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">';
      returnHtml += '<div class="modal-dialog" role="document">';
      returnHtml += '<div class="modal-content">';
      returnHtml +=
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close">';
      returnHtml +=
        '<span aria-hidden="true"><img src="../../assets/img/close-icon.svg"></span>';
      returnHtml += '</button>';
      returnHtml += '<div class="modal-body drap-info-msg-inner">';
      returnHtml += displayText;
      returnHtml += '</div>';
      returnHtml += '</div>';
      returnHtml += '</div>';
      returnHtml += '</div>';
    }
    return displayText; //returnHtml;
  }
}
