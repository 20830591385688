import {
  CdkDragDrop,
  CDK_DRAG_CONFIG,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Component,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { ShipmentServies } from '../../services/shipment.service';
import { DataFieldService } from 'src/app/features/admin/datafield/services/datafield.service';

declare var $: any;

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 1000000,
};
@Component({
  selector: 'app-advanced-filter-tab',
  templateUrl: './advanced-filter-tab.component.html',
  styleUrls: ['./advanced-filter-tab.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class AdvancedFilterTabComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() advancedFilterAdded: any = [];
  @Input() showAdvancedFilterPopUp: boolean = false;
  @Output() advancedFilter = new EventEmitter<any>();
  @Output() callSaveAsEmitter = new EventEmitter<any>();
  @Output() callSaveEmitter = new EventEmitter<any>();
  @Output() popupCommon: EventEmitter<any> = new EventEmitter();
  @Input() showAdvSaveMessage: boolean = false;

  displayMessage: string = 'Filter has been saved successfully';
  advancedFilterForm: FormGroup;
  tableViewConfiguredList: any = [];
  tempTableViewConfiguredList: any = [];
  operatorList: any = [];
  tempOperatorList: any = [];
  connectedTo: any = [];
  currentAccDetails = this.accountSessionData.getCurrentAccountData();
  filterOpttions: any[] = [];
  sortOption: any = 'displayOrder';
  logicalOperatorList: any = ['and', 'or'];
  searchOperator: string = '';
  disableSave: boolean = false;
  disableSaveAs: boolean = false;
  lastSavedRecord: any = [];
  hasChanges: boolean = false;
  isConditionValueRequired: boolean = false;
  currentSessionAccount: any;
  isGroup: boolean = false;
  @Input() tableViewColumnHeaderList: any;
  @Input() isfilterEdit: any;
  defaultOperatorList: any = [];

  constructor(
    private fb: FormBuilder,
    private accountSessionData: CurrentSessionDataService,
    private currentUserDataService: CurrentSessionDataService,
    private shipmentService: ShipmentServies,
    private dataFieldService: DataFieldService
  ) {
    this.currentSessionAccount =
      this.currentUserDataService.getCurrentAccountData();
    this.isGroup = this.currentSessionAccount.isGroup;
  }

  ngOnInit(): void {
    this.loadProperties();
    this.loadConnectedList();
    this.AddCondition(0);
    //this.detectOutsideClickEvent();
    this.loadDefaultOperatorList();
  }

  ngOnChanges(changes: SimpleChanges): void {

    for (const propName in changes) {
      if (propName == 'advancedFilterAdded') {
        this.renderUIForExistingData();
      }
      if (propName == 'tableViewColumnHeaderList') {
        this.loadTableViewConfigurationList();
      }
      if(propName == "showAdvSaveMessage"){
        if(this.showAdvSaveMessage){
        this.successToast(true);
        }
        else{
          this.successToast(false);
        }
      }
    }
  }

  ngOnDestroy(): void {
   // this.onReset(); // commented due to avoid redudancy in shipment call while component destroying
  }

  loadDefaultOperatorList() {
    this.dataFieldService.getTypeValueWithType(2).subscribe((res: any) => {
      let operatorList = res?.result;
      this.defaultOperatorList = operatorList;
    });
  }

  /*detectOutsideClickEvent() {
    $(document).on('mouseup', (e: any) => {
      alert("calling"+  e.target?.id )
      if (
        e.originalEvent?.delegateTarget?.id != 'advancedfilter' &&
        e.target?.id != 'advancedfilter'
      ) {
        //
      } else {
        this.closeAdvFilterForm();
      }
    });
  }*/

  extractProperties(item: any) {
    let newItem = [...JSON.parse(JSON.stringify(item))];
    newItem?.forEach((e: any) => {
      e.order = 0;
      e.conditions?.forEach((f: any) => {
        (f.order = 0),
          (f.hasColumnError = false),
          (f.hasOperatorError = false),
          (f.hasValueError = false),
          (f.isConditionValueRequired = false);
      });
    });
    return newItem;
  }

  compareCurrentAndPreviousValues() {
    let currentData = this.extractProperties(
      this.advancedFilterForm.value.groups
    );
    this.shipmentService.backupOfAdvFilterCurrentData = currentData;
    let lastSavedData = this.extractProperties(this.lastSavedRecord);
    if (JSON.stringify(currentData) === JSON.stringify(lastSavedData)) {
      return true;
    } else {
      return false;
    }
  }

  detectSaveChanges() {
    setTimeout(() => {
      let noChanges = this.compareCurrentAndPreviousValues();
      this.disableSave = noChanges;
      this.disableSaveAs = noChanges;
      this.hasChanges = !noChanges;
      this.shipmentService.advancedFilterSavedRecently = noChanges;
    }, 0);
  }

  renderUIForExistingData() {
    this.loadProperties();
    if (this.advancedFilterAdded?.length > 0) {
      this.bindAdvanceFilterInEditFlow(this.advancedFilterAdded);
      this.detectSaveChanges();
    } else {
      this.AddCondition(0);
      this.disableSave = true;
      this.disableSaveAs = true;
    }
    this.lastSavedRecord = this.advancedFilterForm.value.groups;
  }

  addNewGroups(): FormGroup {
    return this.fb.group({
      order: 0,
      logicaloperator: ['and', Validators.required],
      conditions: this.fb.array([]),
    });
  }

  addNewConditions() {
    return this.fb.group({
      order: 0,
      logicaloperator: ['and', Validators.required],
      columnDisplayName: '', // added for FE purpose
      dataType: '', // added for FE purpose
      column_name: ['', Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required],
      elementType: '',
      controlTypeName: 0,
      id:'', /*...added recently for id's...*/
      hasColumnError: false /*...added for FE purpose...*/,
      hasOperatorError: false /*...added for FE purpose...*/,
      hasValueError: false /*...added for FE purpose...*/,
      isConditionValueRequired: true /*...added for FE purpose...*/,
      hasValueControlError: false /*...added for FE purpose...*/,
      operatorList: [] /*...added for FE purpose...*/,
      valueControlErrorMessage: '' /*...added for FE purpose...*/,
      searchField:'' /*...added for FE purpose...*/,
    });
  }

  loadProperties() {
    this.advancedFilterForm = this.fb.group({
      groups: this.fb.array([]),
    });
  }

  groupField(): FormArray {
    return this.advancedFilterForm?.get('groups') as FormArray;
  }

  conditionsField(groupIndex: number): FormArray {
    return this.groupField()?.at(groupIndex)?.get('conditions') as FormArray;
  }

  AddGroups() {
    this.groupField().push(this.addNewGroups());
    let groupCount = this.groupField().length;
    this.conditionsField(groupCount - 1).push(this.addNewConditions());
  }

  AddCondition(groupIndex: number) {
    if (this.groupField().value?.length == 0) {
      this.AddGroups();
    } else {
      this.conditionsField(groupIndex).push(this.addNewConditions());
    }
  }

  filterField(searchText: string) {
    this.tableViewConfiguredList = this.tempTableViewConfiguredList;
    if (searchText?.length > 0) {
      this.tableViewConfiguredList =
        this.tableViewConfiguredList?.filter((e: any) =>
          e.displayName?.toLowerCase()?.includes(searchText.toLowerCase())
        )?.length == 0
          ? ['No records found']
          : this.tableViewConfiguredList?.filter((e: any) =>
          e.displayName?.toLowerCase()?.includes(searchText.toLowerCase())
          );
    }
  }

  clearSearch(event:any, groupIndex:number, conditionIndex:number){
    this.conditionsField(groupIndex).controls[conditionIndex].patchValue({
      searchField: "",
    });
    this.tableViewConfiguredList = this.tempTableViewConfiguredList;
    event.stopPropagation();
    $('#dropdownMenuButtons-open-'+groupIndex+'-'+conditionIndex).dropdown('toggle');
  }

  filterOperator(searchText: string) {
    if (searchText?.length > 0) {
      this.operatorList = this.operatorList?.filter((e: any) =>
        e.conditionCompare.toLowerCase().includes(searchText)
      );
    } else {
      this.operatorList = this.tempOperatorList;
    }
  }

  getTableViewConfigurationListParam() {
    return {
      offset: 0,
      fetch: 1000,
      search_text: '',
      isGlobalSearch: false,
      parameters: [
        {
          name: this.isGroup ? 'groupId' : 'accountId',
          value: this.currentAccDetails.accountId,
        },
      ],
      sort_options: [
        {
          column_name: this.sortOption,
          direction: 'asc',
        },
      ],
      filter_options: this.filterOpttions,
    };
  }

  loadTableViewConfigurationList() {
    this.tableViewConfiguredList = [];
    this.tableViewConfiguredList = this.tableViewColumnHeaderList
      ? this.tableViewColumnHeaderList
        .filter((e: any) => e.elementType?.displayText != 'System Defined')
        .sort((a: any, b: any) =>
          a?.displayName.localeCompare(b?.displayName)
        )
      : [];
    this.tempTableViewConfiguredList = this.tableViewConfiguredList.sort(
      (a: any, b: any) => a?.displayName.localeCompare(b?.displayName)
    );
  }

  loadOperatorList(operatorList: any) {
    if (operatorList?.length == 0) {
      return this.defaultOperatorList;
    } else {
      return operatorList;
      /***this.tempOperatorList = operatorList;***/
    }
  }

  logicalOperatorSelection(
    selectedOperator: any,
    groupIndex: number,
    conditionIndex?: number
  ) {
    if (conditionIndex == -1 || groupIndex == -1) {
      return;
    }
    if (conditionIndex || conditionIndex == 0) {
      this.conditionsField(groupIndex).controls[conditionIndex].patchValue({
        logicaloperator: selectedOperator,
      });
    } else {
      this.groupField().at(groupIndex).patchValue({
        logicaloperator: selectedOperator,
      });
    }

    this.callAdvancedSearchApi({
      type: 'advancedFilter',
      selectedValue: this.advancedFilterRequirdProperties(
        this.advancedFilterForm.value?.groups
      ),
    });
    this.detectSaveChanges();
  }

  validateInitialOperator() {
    this.advancedFilterForm?.value?.groups?.forEach((e: any, i: number) => {
      if (i == 0) {
        this.groupField().at(i).patchValue({
          logicaloperator: 'and',
        });
      }
      e?.conditions?.forEach((f: any, j: number) => {
        if (j == 0) {
          this.conditionsField(i).controls[j].patchValue({
            logicaloperator: 'and',
          });
        }
      });
    });
    return this.advancedFilterForm?.value?.groups;
  }

  onFieldSelection(list: any, groupIndex: number, conditionIndex: number) {
    this.conditionsField(groupIndex).controls[conditionIndex].patchValue({
      column_name: list?.fields[0]?.value,
      columnDisplayName: list?.displayName,
      dataType: list?.dataType ? list?.dataType : 'String', // TODO:
      elementType: list?.elementType?.displayText,
      id:list?.id,
      operator: '',
      value: '',
      hasColumnError: false,
      hasOperatorError: false,
      hasValueError: false,
      operatorList: this.loadOperatorList(list?.operators),
    });
    //this.operatorList = [];
    this.detectSaveChanges();
  }

  onOperatorSelection(
    operator: any,
    groupIndex: number,
    conditionIndex: number
  ) {
    this.conditionsField(groupIndex).controls[conditionIndex].patchValue({
      operator: operator?.conditionCompare,
      controlTypeName: operator?.controlType,
      value: '',
      hasOperatorError: false,
      hasValueError: false,
      isConditionValueRequired: this.validateConditionValue(
        operator?.conditionCompare
      ),
    });

    if (!this.validateConditionValue(operator?.conditionCompare)) {
      this.callAdvancedSearchApi({
        type: 'advancedFilter',
        selectedValue: this.advancedFilterRequirdProperties(
          this.advancedFilterForm.value?.groups
        ),
      });
    }
    this.detectSaveChanges();
  }

  validateConditionValue(operatorText: String) {
    if (
      operatorText !== 'Any Change' &&
      operatorText !== 'Is Null/Is Blank' &&
      operatorText !== 'Not Null/Not Blank' &&
      operatorText !== 'Has Date (not Null/not Blank)' &&
      operatorText !== 'Has no Date (Is Null/Is Blank)' &&
      operatorText !== 'Has Date (not Null / not Blank)' &&
      operatorText !== 'Has no Date (Is Null / Is Blank)'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkOnIsGroupEmpty(value: any, length: number) {
    if (
      value.conditions[0]?.column_name == '' &&
      value.conditions[0]?.operator == '' &&
      value.conditions[0]?.value == '' &&
      length == 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  deleteGroup(groupIndex: number) {
    if (
      this.checkOnIsGroupEmpty(
        this.groupField().at(groupIndex).value,
        this.groupField()?.length
      )
    ) {
      return;
    }

    this.groupField().removeAt(groupIndex);
    this.callAdvancedSearchApi({
      type: 'advancedFilter',
      selectedValue: this.advancedFilterRequirdProperties(
        this.advancedFilterForm.value?.groups
      ),
    });
    if (this.groupField()?.controls?.length == 0) {
      this.resetToDefaultState();
      this.popupCommon.emit('resetted-completely');
    }
    this.detectSaveChanges();
  }

  deleteCondition(groupIndex: number, conditionIndex: number) {
    if (
      this.checkOnIsGroupEmpty(
        this.groupField().at(groupIndex).value,
        this.groupField()?.length
      )
    ) {
      return;
    }
    this.conditionsField(groupIndex).removeAt(conditionIndex);
    if (this.conditionsField(groupIndex).length == 0) {
      this.deleteGroup(groupIndex);
    }

    if (this.conditionsField(groupIndex)?.length >= 0) {
      this.callAdvancedSearchApi({
        type: 'advancedFilter',
        selectedValue: this.advancedFilterRequirdProperties(
          this.advancedFilterForm.value?.groups
        ),
      });
    }
    this.detectSaveChanges();
  }

  loadConnectedList() {
    for (let i = 0; i <= 15; i++) {
      this.connectedTo.push('cdk-adv-list-' + i);
    }
  }

  changeConditionLogicalOperator(e: any) { }

  onDropGroup(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.advancedFilterForm.controls.groups.value,
        event.previousIndex,
        event.currentIndex
      );
      moveItemInArray(
        this.groupField().controls,
        event.previousIndex,
        event.currentIndex
      );
    }
    //this.validateInitialOperator();
    this.detectSaveChanges();
  }

  onDynamicChildDrop(event: CdkDragDrop<any[]>, groupIndex: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.conditionsField(groupIndex).value,
        event.previousIndex,
        event.currentIndex
      );
      moveItemInArray(
        this.conditionsField(groupIndex).controls,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      let groupId = event.previousContainer.id;
      let previousGroupIndex: number = parseInt(groupId[groupId.length - 1]);
      transferArrayItem(
        this.conditionsField(previousGroupIndex).value,
        this.conditionsField(groupIndex).value,
        event.previousIndex,
        event.currentIndex
      );
      transferArrayItem(
        this.conditionsField(previousGroupIndex).controls,
        this.conditionsField(groupIndex).controls,
        event.previousIndex,
        event.currentIndex
      );
      this.removeGroupOnConditionTransfer(previousGroupIndex, groupIndex);
    }
    // this.validateInitialOperator();
    this.detectSaveChanges();
  }

  removeGroupOnConditionTransfer(previousIndex: number, currentIndex: number) {
    if (this.conditionsField(previousIndex)?.value?.length == 0) {
      this.deleteGroup(previousIndex);
    } else if (this.conditionsField(currentIndex)?.value?.length == 0) {
      this.deleteGroup(currentIndex);
    }
  }

  callAdvancedSearchApi(bodyParam: any) {
    if (!this.validateFields()) {
      this.advancedFilter.emit(bodyParam);
    }
  }

  bindValueControl(event: any) {
    let selectedOperator =
      this.advancedFilterForm?.value?.groups[event[1]].conditions[event[2]]
        .operator;

    this.conditionsField(event[1]).controls[event[2]].patchValue({
      value:
        selectedOperator.toLowerCase().trim() == 'between'
          ? event[0]
          : event[0].split(',')[0],
      hasValueControlError: event[3],
      valueControlErrorMessage: event[4],
    });
    this.callAdvancedSearchApi({
      type: 'advancedFilter',
      selectedValue: this.advancedFilterRequirdProperties(
        this.advancedFilterForm.value?.groups
      ),
    });
    this.detectSaveChanges();
  }

  validateFields() {
    let hasError = false;
    if (this.advancedFilterForm?.value?.groups?.length > 0) {
      this.advancedFilterForm.value.groups.forEach((e: any, i: number) => {
        if (e?.conditions?.length > 0) {
          e.conditions.forEach((f: any, j: number) => {
            if (
              f?.column_name?.trim() == '' ||
              f?.operator?.trim() == '' ||
              (f.value?.trim() == '' && this.validateConditionValue(f.operator.trim())) ||
              f.hasValueControlError
            ) {
              hasError = true;
            }
            this.conditionsField(i).controls[j].patchValue({
              hasColumnError: f.column_name?.trim() == '' ? true : false,
              hasOperatorError: f.operator?.trim() == '' ? true : false,
              hasValueError:
                (f.value?.trim() == '' && this.validateConditionValue(f.operator.trim())) ||
                  f.hasValueControlError
                  ? true
                  : false,
              order: j + 1,
            });
          }),
            this.groupField()
              .at(i)
              .patchValue({
                order: i + 1,
              });
        }
      });
      return hasError;
    } else {
      return true;
    }
  }

  advancedFilterRequirdProperties(items: any) {
    const actualItems = [...JSON.parse(JSON.stringify(items))];
    actualItems?.forEach((e: any, i: number) => {
      e.order = i;
      e?.conditions?.forEach((f: any, j: number) => {
        f.order = j;
        if (f && f.hasOwnProperty('hasColumnError')) {
          delete f.hasColumnError;
        }
        if (f && f.hasOwnProperty('hasOperatorError')) {
          delete f.hasOperatorError;
        }
        if (f && f.hasOwnProperty('hasValueError')) {
          delete f.hasValueError;
        }
        if (f && f.hasOwnProperty('isConditionValueRequired')) {
          delete f.isConditionValueRequired;
        }
        if (f && f.hasOwnProperty('operatorList')) {
          delete f.operatorList;
        }
        if (f && f.hasOwnProperty('hasValueControlError')) {
          delete f.hasValueControlError;
        }
        if (f && f.hasOwnProperty('valueControlErrorMessage')) {
          delete f.valueControlErrorMessage;
        }
        if(f && f.hasOwnProperty('searchField')){
          delete f.searchField;
        }
      });
    });
    return actualItems;
  }

  saveAs() {
    /** validation for all fields **/
    if (!this.validateFields()) {
      /** make request **/
      this.callSaveAsEmitter.emit(
        this.advancedFilterRequirdProperties(
          this.advancedFilterForm.value?.groups
        )
      );
    }
  }

  save(event: any) {
    if (!this.validateFields()) {
      this.shipmentService.advancedFilterSavedRecently = true;
      this.callSaveEmitter.emit(
        [event, this.advancedFilterRequirdProperties(
          this.advancedFilterForm.value?.groups
        ), true]); // true = indicate that we are calling advance filter save.
      this.disableSave = true;
    }
  }

  resetAdvFilterForm() {
    this.popupCommon.emit('reset');
  }

  closeAdvFilterForm() {
    this.popupCommon.emit('close');
  }

  resetToDefaultState() {
    this.advancedFilterForm.reset();
    this.loadProperties();
    this.AddGroups();
  }

  onReset() {
    if (this.advancedFilterAdded.length <= 0) {
      /** reset to default one group and one condition state **/
      this.resetToDefaultState();
      this.popupCommon.emit('resetted-completely');
    } else {
      this.renderUIForExistingData(); /** load existing/saved data state **/
      this.callAdvancedSearchApi({
        type: 'advancedFilter',
        selectedValue: this.advancedFilterRequirdProperties(
          this.advancedFilterForm.value?.groups
        ),
      });
    }
    this.detectSaveChanges();
  }

  filterOperatorList(columnName: any) {
    let tableViewList = this.tableViewConfiguredList?.filter(
      (e: any) =>
        e.fields[0].value?.trim().toLowerCase() ==
        columnName?.trim().toLowerCase()
    );
    if (tableViewList?.length > 0) {
      return tableViewList[0].operators;
    } else {
      return this.defaultOperatorList;
    }
  }

  patchOperatorList(list: any) {
    list?.forEach((e: any) => {
      e.conditions?.forEach((f: any) => {
        f.operatorList = this.filterOperatorList(f.column_name);
      });
    });
    return list;
  }

  bindAdvanceFilterInEditFlow(list: any) {
    let sectionBaseList = this.patchOperatorList(list);
    // sort group by order id
    sectionBaseList?.sort((a: any, b: any) => a.order - b.order);
    //sort conditions by order id
    sectionBaseList?.forEach((e: any, i: number) => {
      e.conditions.sort((a: any, b: any) => a.order - b.order);
    });
    sectionBaseList?.forEach((e: any, i: number) => {
      this.AddGroups();
      this.groupField().controls[i]?.patchValue({
        order: e.order,
        logicaloperator: e.logicaloperator,
      });
      e.conditions.forEach((f: any, j: number) => {
        if (j > 0) {
          this.addDynamicAdvFilterField(i);
        }
      });
      this.groupField().controls[i]?.patchValue({
        conditions: e.conditions,
      });
    });
  }

  addDynamicAdvFilterField(groupIndex: number) {
    this.conditionsField(groupIndex).push(this.addNewConditions());
  }

  successToast(show:boolean) {
    this.showAdvSaveMessage = show;
  }
}
