import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonUtils } from 'src/app/common/utils';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AI_ModulesName,
  AppInsightsService,
} from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-booking-detail-tab',
  templateUrl: './booking-detail-tab.component.html',
  styleUrls: ['./booking-detail-tab.component.scss'],
})
export class BookingDetailTabComponent implements OnInit, OnChanges {
  @Input() selectedBooking: any = [];
  readMoreNotes: boolean = false;
  readMoreMarksNumbers: boolean = false;
  replaceRegex = '/^s+|s+$/g';
  displayNotes: string = '';
  showCustomField: boolean = false;
  customFieldList: any[] = [];

  constructor(private appInsightsService: AppInsightsService) { }

  ngOnInit(): void {
    this.loadInitialPageData();
  }

  loadInitialPageData() {
    this.appInsightsService.logEvent(
      AI_CommonEvents.DetailTabViewed.toString(),
      {
        [AI_CustomProps.BookingId]: this.selectedBooking?.forwarder_reference,
        [AI_CustomProps.ModuleName]: AI_ModulesName.Booking,
      }
    );
    this.sortMileStonesByActualDate();
  }

  ngOnChanges(): void {
    this.loadMarksAndNumbers();
    this.loadDisplayNotes();
    this.loadCustomField();
    this.sortMileStonesByActualDate();
  }

  loadMarksAndNumbers() {
    // To remove all the newline from marks and numbers & Notes.
    if (this.selectedBooking && this.selectedBooking.marks_and_numbers) {
      this.selectedBooking.marks_and_numbers =
        this.selectedBooking.marks_and_numbers.replace(this.replaceRegex, '');

      this.readMoreMarksNumbers =
        this.selectedBooking.marks_and_numbers.length > 60;
    }
  }

  loadDisplayNotes() {
    //find first client visible job ntes and display
    this.displayNotes = '';
    if (this.selectedBooking && this.selectedBooking.notes) {
      let clientVisibleNote = this.selectedBooking.notes.find(
        (i: any) =>
          i.note_type.toString().toLowerCase() === 'client visible job notes'
      );
      if (clientVisibleNote && clientVisibleNote.body) {
        this.displayNotes = clientVisibleNote.body;
      }
      this.displayNotes = this.displayNotes.replace(this.replaceRegex, '');
      this.readMoreNotes = this.displayNotes.length > 180;
    }
  }

  loadCustomField() {
    this.customFieldList = [];
    this.showCustomField = false;
    if (
      this.selectedBooking &&
      this.selectedBooking?.native_reference_numbers
    ) {
      this.customFieldList =
        this.selectedBooking.native_reference_numbers?.filter(
          (x: any) => x.data_source == 'CustomizedField'
        );
      this.showCustomField = this.customFieldList.length > 0;
    }
  }

  sortMileStonesByActualDate() {
    CommonUtils.sortMileStonesByActualDate(this.selectedBooking);
  }

  // getTitleCase(text: string) {
  //   return CommonUtils.toTitleCase(text);
  // }

  // getShipToDetails(data: any) {
  //   return CommonUtils.getShipToDetails(data);
  // }

  // getShipFromAddress(data: any) {
  //   return CommonUtils.getShipFromAddress(data);
  // }

  // getDateFormatted(date: any) {
  //   return CommonUtils.getYearFormattedDate(date);
  // }

  getDateForatMMMDDYYYY(date: any) {
    return CommonUtils.formatDateMMMDDYYYY(date);
  }

  // getBookingReferenceNumbers(order: any, limit: number) {
  //   let result = CommonUtils.getShipmentReferenceNumbers(order, limit);
  //   return result ? result : '---';
  // }

  // getVesselVoyageText(voyage_number: any, vessel_name: any) {
  //   let htmlString = '---';
  //   if (!voyage_number && !vessel_name) return htmlString;

  //   if (voyage_number && vessel_name) {
  //     htmlString = vessel_name + ' / ' + voyage_number;
  //   } else if (vessel_name) {
  //     htmlString = vessel_name;
  //   } else {
  //     htmlString = voyage_number;
  //   }
  //   return htmlString;
  // }

  // getCityStateCountry(address: any) {
  //   let htmlString = '';
  //   if (!address) return htmlString;

  //   if (address.city) {
  //     htmlString += this.getTitleCase(address.city);
  //   }

  //   if (address.city && address.country_name) {
  //     htmlString += ', ';
  //   }

  //   // Add state code when US or Canada
  //   if (
  //     address.state &&
  //     address.country_name &&
  //     (address.country_name.toLowerCase() == 'united states' ||
  //       address.country_name.toLowerCase() == 'canada')
  //   ) {
  //     htmlString += address.state + ', ';
  //   }
  //   if (address.country_name) {
  //     htmlString += this.getTitleCase(address.country_name);
  //   }
  //   return htmlString;
  // }

  // getPortDetail(port: any) {
  //   let htmlString = '';
  //   if (!port) return htmlString;
  //   if (port.portName) {
  //     htmlString += this.getTitleCase(port.portName);
  //   }
  //   if (port.portName && port.country_name) {
  //     htmlString += ', ';
  //   }
  //   if (port.country_name) {
  //     htmlString += this.getTitleCase(port.country_name);
  //   }
  //   if (port.unlocode) {
  //     htmlString += ' - ' + port.unlocode;
  //   }
  //   return htmlString;
  // }

  // getDateFormated(date: any) {
  //   let message = '---';
  //   if (date) {
  //     message = CommonUtils.formatDateMMMDDYYYY(date);
  //   }
  //   return message;
  // }
  /* istanbul ignore next */
  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }

  doAdditionalReferencesExist(nativeReferenceNumbers: any[]) {
    return CommonUtils.doAdditionalReferencesExist(nativeReferenceNumbers);
  }
}
