<app-progressbar *ngIf="isProgressShow"></app-progressbar>
<ng-container *ngIf="watchListSwitch=='Shipments'">
    <app-shipment-list id="split-shipment" [shipmentData]="shipmentData" [totalShipmentCount]="totalShipmentCount"
        [urlShare]="url" [isShowToggle]="true" [loadingInProgress]="isProgressShow" [dashboardWatchlist]="true"
        (reloadShipmentList)="reloadWatchList('shipment')" (prepareUrlShare)="prepareDashboardShipment($event)">
    </app-shipment-list>
</ng-container>
<ng-container *ngIf="watchListSwitch=='Bookings'">
    <app-booking-list [bookingMasterData]="bookingMasterData" [totalBookingCount]="totalBookingCount"
        [isProgressShow]='isProgressShow' [dashboardWatchlist]='true' (reloadBookingList)="reloadWatchList('booking')"
        (prepareUrlShare)="prepareDashboardBooking($event)" [urlShare]="url">
    </app-booking-list>
</ng-container>
<ng-container *ngIf="watchListSwitch =='Customs'">
    <app-customs-list [customsListData]="customsData" [totalCustomsCount]="totalCustomsCount"
        [isProgressShow]='isProgressShow' [dashboardWatchlist]='true' (reloadCustomsList)="reloadWatchList('customs')"
        (prepareUrlShare)="prepareDashboardCustoms($event)" [urlShare]="url">
    </app-customs-list>
</ng-container>