import { CommonUtils } from 'src/app/common/utils';

export class CustomsUtils {
  static getInlineAddressForNode(data: any, nodeType: number): string {
    if (!this.hasValidCustomDeclarionData(data)) {
      return '';
    }

    let city = '';
    let state = '';
    let country = '';
    if (this.isCustomTypeExport(data)) {
      switch (nodeType) {
        case 1:
          city = data.customs_declarations[0].port_of_clearance_description;
          country = data.customs_declarations[0].export_country_code;
          break;
        case 2:
          city = data.lading_port?.portName;
          state = data.lading_port?.stateCode;
          country = data.lading_port?.country_name;
          break;
        case 3:
          city = data.arrival_port?.portName;
          state = data.arrival_port?.stateCode;
          country = data.arrival_port?.country_name;
          break;
      }
    } else {
      switch (nodeType) {
        case 1:
          city = data.lading_port?.portName;
          state = data.lading_port?.stateCode;
          country = data.lading_port?.country_name;
          break;
        case 2:
          city = data.arrival_port?.portName;
          state = data.arrival_port?.stateCode;
          country = data.arrival_port?.country_name;
          break;
        case 3:
          city = data.customs_declarations[0].port_of_clearance_description;
          country = data.customs_declarations[0].import_country_code;
          break;
      }
    }

    let message = this.convertCityStateAsUpperCase(city, state);
    message += CommonUtils.getUSCanadaStateText(country, state);

    if (country) {
      message += country + ', ';
    }
    return CommonUtils.removeLastComma(message);
  }

  /**

   * If city has the state value then convert city as title case and state as upper case as per

   * Bug 92046: UAT 260: State name should be in capital.

   * @param city

   * @param state

   * @returns converted city as string

   */

  static convertCityStateAsUpperCase(city: string, state: string): string {
    let message: string = '';
    if (city) {
      let cityArr = city.split(',');
      if (cityArr.length > 1 && state == '') {
        city = cityArr
          .map((word, i) =>
            i == cityArr.length - 1 && word.trim().length == 2
              ? word.toUpperCase()
              : CommonUtils.toTitleCase(word)
          )
          .toString();
      } else {
        city = CommonUtils.toTitleCase(city);
      }
      message += city + ', ';
    }
    return message;
  }

  static convertCityStateAsUpperCaseForLoc(
    city: string,
    state: string
  ): string {
    let message: string = '';
    if (city) {
      let cityArr = city.split(',');
      if (cityArr.length > 1 && state == '') {
        city = cityArr
          .map((word, i) =>
            i == cityArr.length - 1 && word.trim().length == 2
              ? word.toUpperCase()
              : CommonUtils.toTitleCase(word)
          )
          .toString();
      } else {
        city = CommonUtils.toTitleCase(city);
      }
      message += city;
    }
    return CommonUtils.removeLastComma(message);
  }

  static getInlineDateForNode(data: any, nodeType: number): string {
    if (!this.hasValidCustomDeclarionData(data)) {
      return '';
    }

    let message = nodeType == 1 ? 'Dep : ' : 'Arr : ';
    let displayDate: any = this.getCustomProgressBarDateForNode(data, nodeType);
    if (displayDate) {
      displayDate = displayDate.substring(0, 10);
    }
    message += displayDate ? CommonUtils.formatDateMMMDD(displayDate) : '---';
    return message;
  }

  static getCustomProgressBarDateForNode(data: any, nodeType: number): string {
    if (!this.hasValidCustomDeclarionData(data)) {
      return '';
    }

    let displayDate: any;
    if (this.isCustomTypeExport(data)) {
      switch (nodeType) {
        case 1:
          displayDate = data.customs_declarations[0].export_date;
          break;
        case 2:
          displayDate = data.loading_actual;
          break;
        case 3:
          displayDate = data.customs_declarations[0].import_date;
          break;
      }
    } else {
      switch (nodeType) {
        case 1:
          displayDate = data.loading_actual;
          break;
        case 2:
          displayDate = data.customs_declarations[0].import_date;
          break;
        case 3:
          displayDate = data.customs_declarations[0].declaration_date;
          break;
      }
    }
    return displayDate;
  }

  static getToolTipForNode(data: any, nodeType: number): string {
    if (!this.hasValidCustomDeclarionData(data)) {
      return '';
    }

    let message = '';
    if (this.isCustomTypeExport(data)) {
      switch (nodeType) {
        case 1:
          message += 'Port of Export';
          break;
        case 2:
          message += 'Loading Port';
          break;
        case 3:
          message += 'Discharge Port';
          break;
      }
    } else {
      switch (nodeType) {
        case 1:
          message += 'Loading Port';
          break;
        case 2:
          message += 'Discharge Port';
          break;
        case 3:
          message += 'Entry Port';
          break;
      }
    }
    return message;
  }

  static getProgressBarFillClassForNode(data: any, nodeType: number): number {
    if (!this.hasValidCustomDeclarionData(data)) {
      return 0;
    }

    let cssClass = 0;
    let displayDate: any = this.getCustomProgressBarDateForNode(data, nodeType);
    if (displayDate) {
      let isFutureDate = CommonUtils.isFutureDate(displayDate);
      cssClass = isFutureDate ? 80 : 100;
    }
    return cssClass;
  }

  static isCustomTypeExport(data: any): boolean {
    return (
      data &&
      data.customs_declarations[0] &&
      data.customs_declarations[0].customs_type &&
      data.customs_declarations[0].customs_type.toString().toUpperCase() ===
        'EXP'
    );
  }

  static hasValidDeclarationDate(data: any): boolean {
    return data &&
      data.customs_declarations &&
      data.customs_declarations[0] &&
      (data.customs_declarations[0].declaration_date ||
        data.customs_declarations[0].port_of_clearance_description ||
        data.customs_declarations[0].import_country_code)
      ? true
      : false;
  }

  static hasValidExportDate(data: any): boolean {
    return data &&
      data.customs_declarations &&
      data.customs_declarations[0] &&
      (data.customs_declarations[0].export_date ||
        data.customs_declarations[0].port_of_clearance_description ||
        data.customs_declarations[0].import_country_code)
      ? true
      : false;
  }

  static hasValidCustomDeclarionData(data: any) {
    return (
      data && data.customs_declarations && data.customs_declarations.length > 0
    );
  }
}
