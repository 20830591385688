import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChangeLogService {
  constructor(private http: HttpClient) {}

  getChangeLogs(
    shipmentId: any,
    category: any,
    sort: any,
    url: any,
    email: any
  ): Observable<any> {
    let request = {
      sort_options: sort,
    };
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url +
          url +
          shipmentId +
          '&changeLogCategory=' +
          category +
          '&userEmail=' +
          email,
        request
      )
      .pipe(catchError(this.handelError));
  }

  getActionFieldChangeLogs(url: any, param: any): Observable<any> {
    return this.http
      .post<any[]>(environment.base_api_desktop_url + url, param)
      .pipe(catchError(this.handelError));
  }

  handelError(error: any) {
    return throwError(
      {
        status: error.status,
        statusText: error.statusText,
      } || 'Server Error'
    );
  }
}
