<div class="logs">
    <button *ngIf="selectedCustom?.commercial_invoices?.length >0"
        (click)="prepareInvocieExcelDownload(selectedCustom.forwarder_reference)" class="download-invoice-btn">
        <img class="dwnld-icon" alt="upload icon" src="../../../assets/img/download_icon.svg" /> Download
        Invoice Summary</button>
    <div id="invoice1" class="card" *ngFor="let invoice of selectedCustom?.commercial_invoices; let index = index"
        id="header-invoice-{{selectedCustom.forwarder_reference}}-{{index}}"
        [ngClass]="index == 0 ? 'change-icon add-border' : ''">
        <div class="card-header"
            (click)="addClassFor('#header-invoice-' + selectedCustom.forwarder_reference+'-'+index)">
            <h5 class="mb-0"><button id="shipment-btn-pr" data-toggle="collapse"
                    [attr.data-target]="'#body-invoice-' + selectedCustom.forwarder_reference+'-'+index"
                    aria-expanded="true" aria-controls="collapseOne" class="btn btn-link"><a
                        class="btn-header-link">{{index +1}}.
                        Invoice No. {{invoice.invoice_number}}
                        <strong>{{invoice.commercial_invoice_lines?.length}}</strong></a></button></h5>
        </div>
        <div id="body-invoice-{{selectedCustom.forwarder_reference}}-{{index}}" aria-labelledby="invoice1"
            class="collapse" [attr.data-parent]="'#header-' + selectedCustom.forwarder_reference+'-'+index"
            [ngClass]="index == 0 ? 'collapse show' : 'collapse'">
            <div class="card-body container-color-bg">
                <h3 class="pl-0">Details</h3>
                <div class="mobile-view row">
                    <div class="col-sm-6 col-md-2 mt-2 mb-3 line-set col-6 mt-2">
                        <h5>Invoice Total</h5>
                        <p>{{invoice.total_due}}</p>
                    </div>
                    <div class="col-sm-6 mt-2 col-md-2 mb-3 line-set col-6">
                        <h5>Currency</h5>
                        <p>{{invoice.currency}}</p>
                    </div>
                    <div class="col-sm-6 mt-2 col-md-2 mb-3 line-set col-6">
                        <h5>INCO Term</h5>
                        <p>{{invoice.inco_terms}}</p>
                    </div>
                    <div class="col-sm-6 mt-2 col-md-2 mb-3 line-set col-6">
                        <h5>Origin</h5>
                        <p>{{invoice.country_of_origin}}</p>
                    </div>
                </div>
            </div>
            <div class="invoice-tble">
                <h3 class="pl-0">Invoice Lines <strong>{{invoice.commercial_invoice_lines?.length}}</strong></h3>
                <table aria-labelledby="document-table" id="invoice-table"
                    class="table log-table-border table-sortable document-sec">
                    <caption class="d-none"></caption>
                    <thead>
                        <tr>
                            <th id="invoiceLineNo-{{index}}" [appSort]="invoice.commercial_invoice_lines"
                                data-parent="#invoice-table" data-order="asc" data-name="declaration_line_numbers"
                                class="desc factive sort"> Line No </th>
                            <th id="invoiceProduct-{{index}}" data-order="asc" data-name="part_number"
                                [appSort]="invoice.commercial_invoice_lines" data-parent="#invoice-table"
                                class="asc sort">
                                Product </th>
                            <th id="invoiceTariff-{{index}}" data-order="asc" data-name="tariff_number"
                                [appSort]="invoice.commercial_invoice_lines" data-parent="#invoice-table"
                                class="asc sort">
                                <span class="mble-dt-fix">Tariff</span>
                            </th>
                            <th id="invoiceQuantity-{{index}}" data-order="asc" data-name="customs_quantity"
                                [appSort]="invoice.commercial_invoice_lines" data-parent="#invoice-table"
                                class="asc sort">
                                <span class="mble-dt-fix">Quantity</span>
                            </th>
                            <th id="invoiceUnit-{{index}}" data-order="asc" data-name="customs_quantity_uom"
                                [appSort]="invoice.commercial_invoice_lines" data-parent="#invoice-table"
                                class="asc sort">
                                <span class="mble-dt-fix">Unit</span>
                            </th>
                            <th id="invoicePrice-{{index}}" data-order="asc" data-name="extended_price"
                                [appSort]="invoice.commercial_invoice_lines" data-parent="#invoice-table"
                                class="asc sort">
                                <span class="mble-dt-fix">Price</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoiceLine of invoice.commercial_invoice_lines">
                            <td *ngIf="invoiceLine.declaration_number || invoiceLine.declaration_line_numbers">
                                {{invoiceLine.declaration_number}}<ng-container
                                    *ngIf="invoiceLine.declaration_number && invoiceLine.declaration_line_numbers">/
                                </ng-container>{{invoiceLine.declaration_line_numbers}} </td>
                            <td *ngIf="!invoiceLine.declaration_number && !invoiceLine.declaration_line_numbers">---
                            </td>
                            <td> {{invoiceLine.part_number ? invoiceLine.part_number : '---'}} </td>
                            <td> {{invoiceLine.tariff_number ? invoiceLine.tariff_number : '---'}} </td>
                            <td> {{invoiceLine.customs_quantity ? invoiceLine.customs_quantity: '---'}} </td>
                            <td> {{invoiceLine.customs_quantity_uom ? invoiceLine.customs_quantity_uom: '---'}} </td>
                            <td> {{invoiceLine.extended_price ? invoiceLine.extended_price: '---'}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>