import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonUserService {
  constructor(private http: HttpClient) {}

  getUserViewPreference(accountId: any, isGroup: boolean) {
    let url =
      environment.base_api_desktop_url +
      'user/getviewpreference?accountId=' +
      accountId +
      '&isGroup=' +
      isGroup;
    return this.http.get<any[]>(url).pipe(catchError(this.handelError));
  }

  updateUserViewPreference(params: any): Observable<any> {
    let url = environment.base_api_desktop_url + 'user/viewpreference';
    return this.http.put<any[]>(url, params).pipe(catchError(this.handelError));
  }

  handelError(error: any) {
    return throwError(
      {
        status: error.status,
        statusText: error.statusText,
      } || 'Server Error'
    );
  }
}
