import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customHbl',
})
export class CustomHblPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (value.forwarder_reference.charAt(0).toLowerCase() === 's') {
      if (value.customs_declarations[0]?.house_bill) {
        return 'HBL: ' + value.customs_declarations[0]?.house_bill;
      } else {
        return 'HBL: ---';
      }
    } else if (value.forwarder_reference.charAt(0).toLowerCase() === 'b') {
      if (value.house_bill) {
        return 'HBL: ' + value.house_bill;
      } else {
        return 'HBL: ---';
      }
    } else {
      return 'HBL: ---';
    }
  }
}
