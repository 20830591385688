import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrentSessionDataService } from '../../services/current-session-data.service';
import { DataSharingService } from '../../services/data-sharing.service';

import { FileUploadService } from '../../services/file-upload.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit, OnDestroy {
  fileList: any = [];
  myFiles: any[] = [];
  fileName = '';
  fileNameArray: any[] = [];
  showErrorMsg: any;
  hideUploadSec = false;
  percentageCompleted: any = [];
  edocTypeAutoComplete: any[] = [];
  keyword = 'codeWithDesc';
  autoCompleteInProgress = false;
  autoCompleteInProgressRef = false;
  searchFilter = '';
  cancelledDocs: any[] = [];
  selectedFilter: any = '';
  @Input() type: any;
  @Input() shipmentId: any;
  currentAccountData: any;
  currentUserData: any;
  retryMsgIndex = -1;
  connectivityStatus: OnlineStatusType = 1;
  testEmitter = new BehaviorSubject<any>(this.percentageCompleted);
  hideUploadButton = new BehaviorSubject<any>([]);

  constructor(
    private fileUploadService: FileUploadService,
    private dataSharing: DataSharingService,
    private currentSessionUserData: CurrentSessionDataService,
    private onlineStatusService: OnlineStatusService,
    private appInsightsService: AppInsightsService
  ) {
    this.fileUploadService.isFileUploadCancelled = false;
    this.currentAccountData =
      this.currentSessionUserData.getCurrentAccountData();
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // use status
      this.connectivityStatus = status;
    });
  }

  @HostListener('window:beforeunload')
  showMessage(event: any) {
    let fileUploadPercentage: any[] = [];
    let cancelledDocs: any[] = [];
    fileUploadPercentage = JSON.parse(
      localStorage.getItem('fileUploadStatus') || '[]'
    );
    fileUploadPercentage.some((el: any) => {
      if (el.Percentage < 100 && !el.Error) {
        cancelledDocs.push({ fileId: el.fileId, fileName: el.filename });
      }
    });

    if (cancelledDocs.length > 0) {
      this.dataSharing.eDocUploadCancelTrigger.next(cancelledDocs);
      localStorage.removeItem('fileUploadStatus');
    }
  }

  ngOnDestroy(): void {
    this.percentageCompleted = [];
    this.fileUploadService.destroyUploadComponent();
    localStorage.removeItem('fileUploadStatus');
    this.hideUploadButton.next([]);
  }

  ngOnInit(): void {
    this.fileUploadService.fileUploadPercentage.subscribe((res: any) => {
      if (res) {
        setTimeout(() => {
          this.percentageCompleted = [];
          this.percentageCompleted = res.sort((a: any, b: any) => {
            if (a.fileOrder > b.fileOrder) {
              return -1;
            } else if (b.fileOrder > a.fileOrder) {
              return 1;
            } else {
              return 0;
            }
          });
          this.testEmitter.next(this.percentageCompleted);
        }, 0);
      }
    });

    this.initData();
  }

  emptyProperties() {
    this.myFiles = [];
    this.fileNameArray = [];
    this.searchFilter = '';
    this.selectedFilter = '';
  }

  openDocUpload() {
    this.hideUploadSec = true;
  }

  fileBrowseHandler(event: any) {
    [...event.target.files].forEach((file) => {
      this.myFiles.push(file);
      this.fileNameArray.push(file.name);
    });
  }

  autoCompleteOnKeyUp(val: any) {
    this.selectedFilter = '';
    this.autoCompleteInProgress = true;

    this.fileUploadService
      .getEdocTypes(val, this.buildEdocParams())
      .subscribe((res) => {
        if (res) {
          this.edocTypeAutoComplete = res.result;
          this.autoCompleteInProgress = false;
        }
      });
  }

  initData() {
    this.selectedFilter = '';
    this.autoCompleteInProgress = true;

    this.fileUploadService
      .getEdocTypes('', this.buildEdocParams())
      .subscribe((res) => {
        if (res) {
          this.edocTypeAutoComplete = res.result;
          this.hideUploadButton.next(res.result);
          this.autoCompleteInProgress = false;
        }
      });
  }

  edocDocsRefreshApi() {
    this.autoCompleteInProgressRef = true;
    this.fileUploadService
      .getEdocTypes('', this.buildEdocParams())
      .subscribe((res) => {
        if (res) {
          this.edocTypeAutoComplete = res.result;
          this.hideUploadButton.next(res.result);
          this.fileUploadService.showToastEdocRefresh.next({
            msgShow: true,
            type: this.type,
          });
          this.autoCompleteInProgressRef = false;
        }
      });
  }

  buildEdocParams() {
    let isType = '';
    if (this.type == 'bookings') {
      isType = 'booking';
    } else if (this.type == 'shipment') {
      isType = 'shipment';
    } else if (this.type == 'customs') {
      isType = 'custom';
    }
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;

    return (
      environment.base_api_desktop_url +
      'edocAccount/EdocTypes?accountId=' +
      accountId +
      '&userEmail=' +
      emailId +
      '&isGroup=' +
      isGroup +
      '&type=' +
      isType
    );
  }

  uploadDocuments() {
    if (this.selectedFilter == '') {
      this.showErrorMsg = 'Please select a category to upload document.';
    } else if (this.myFiles.length == 0) {
      this.showErrorMsg = 'Please select a file to upload';
    } else {
      if (this.myFiles.length != 0) {
        this.trackAIFileUpload(this.myFiles);
        $('#profile').scrollTop(0);
        this.hideUploadSec = false;
        this.fileUploadService
          .fileUpload(
            this.myFiles,
            this.type,
            this.shipmentId,
            this.selectedFilter,
            ''
          )
          .then()
          .catch();
        this.emptyProperties();
      }
    }
    setTimeout(() => {
      this.showErrorMsg = '';
    }, 2000);
  }

  selectEvent(event: any) {
    this.selectedFilter = event;
  }

  closeList(index: any) {
    this.fileNameArray.splice(index, 1);
    this.myFiles.splice(index, 1);
  }

  retryFileUpload(fileId: any, index: any) {
    if (this.connectivityStatus != 0) {
      this.retryMsgIndex = fileId;
      this.fileUploadService.retryUpload(fileId);
    }
  }

  closeFileUploadSec() {
    $('#profile').scrollTop(0);
    $('#profile1').scrollTop(0);
    this.hideUploadSec = false;
    this.emptyProperties();
  }

  refershEdocList() {
    this.edocDocsRefreshApi();
  }

  trackAIFileUpload(files: any) {
    let docs: any[] = [];
    files.forEach((file: any) => {
      docs.push({
        'File Name': file.name,
        'File Type': file.type,
        'File Size': file.size,
      });
    });
    this.appInsightsService.logEvent('Document uploaded', {
      'Module Name': this.type,
      'Document Detail': JSON.stringify(docs),
    });
  }
}
