import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'statusHints',
})
export class StatusHintsPipe implements PipeTransform {
  transform(data: any): unknown {
    return CommonUtils.getStatusHint(data);
  }
}
