<div class="shipment-detail ob-5 mb-5" id="bookingDetailTab">
  <h3 class="update-sec">BOOKING SCHEDULE</h3>
  <div class="full-rap-set">
    <div class="full-src">
      <span>
        <ul class="timeline">
          <li class="progtrckr-done blue end-point">
            <img src="/assets/img/shipments/truck_progress.svg" alt="" />
            <div class="rap">
              <h6 class="title">Pickup From</h6>
              <p class="country" [innerHTML]="selectedBooking?.ship_from | cityStateCountry"
                *ngIf="selectedBooking?.isFullViewAccess"></p>
              <ng-container *ngIf="selectedBooking?.isFullViewAccess">
                <p [innerHTML]="selectedBooking | shipFromAddress"></p>
              </ng-container>
              <div class="update-change">
                <span class="display-sec">
                  <h6 class="d-inline-block pr-1">Estimated Pickup:</h6>
                  {{ selectedBooking?.origin_pickup_estimated | yearFormat }}
                </span>
              </div>
              <div class="update-change">
                <span class="display-sec">
                  <h6 class="d-inline-block pr-1">Warehouse Receipt Date:</h6>
                  {{ selectedBooking?.cfs_receipt_date | yearFormat }}
                </span>
              </div>
            </div>
            <div class="dynamic-progress progtrckr-done blue end-point"></div>
          </li>
          <li class="progtrckr-done blue end-point">
            <img src="/assets/img/shipments/ship_progress.svg" alt="" />
            <div class="rap">
              <h6 class="title">Origin</h6>
              <span class="country" [innerHtml]="selectedBooking?.origin_port | portDetails">
              </span>
              <div class="" [ngClass]="
                  selectedBooking?.carrier?.name &&
                  (selectedBooking?.voyage_number ||
                    selectedBooking?.vessel_name)
                    ? 'new-set-group'
                    : 'new-set-group one'
                ">
                <p class="rap-legs" *ngIf="selectedBooking?.carrier?.name">
                  <span class="font-bold">Carrier: </span><span>
                    {{
                    selectedBooking?.carrier?.name
                    ? selectedBooking?.carrier?.name
                    : "---"
                    }}</span>
                </p>
                <p class="rap-legs" *ngIf="
                    selectedBooking?.voyage_number ||
                    selectedBooking?.vessel_name
                  ">
                  <span class="font-bold">Vessel/Voyage: </span><span>{{
                    selectedBooking?.voyage_number
                    | vesselVoyageText : selectedBooking?.vessel_name
                    }}</span>
                </p>
              </div>

              <div class="estimate-ctr">
                <p class="display-sec">
                  <span class="font-bold">Estimated Departure: </span><span>
                    {{
                    selectedBooking?.departure_estimated | yearFormat
                    }}</span>
                </p>
              </div>
            </div>
            <div class="dynamic-progress progtrckr-done blue end-point"></div>
          </li>
          <li class="progtrckr-done blue end-point">
            <img src="/assets/img/shipments/truck_progress.svg" alt="" />
            <div class="rap">
              <h6 class="title">Destination</h6>
              <span class="country" [innerHtml]="
                  selectedBooking?.final_destination_port | portDetails
                ">
              </span>
              <div class="estimate-ctr">
                <p class="display-sec">
                  <span class="font-bold">Estimated Arrival: </span><span>
                    {{
                    selectedBooking?.arrival_port_estimated | yearFormat
                    }}</span>
                </p>
              </div>
            </div>
            <div class="dynamic-progress progtrckr-done blue end-point"></div>
          </li>

          <li class="progtrckr-done blue end-point">
            <img src="/assets/img/shipments/truck_progress.svg" alt="" />
            <div class="rap">
              <h6 class="title">Deliver To</h6>
              <p class="country" [innerHTML]="selectedBooking?.ship_to | cityStateCountry"></p>
              <ng-container *ngIf="selectedBooking?.isFullViewAccess">
                <p [innerHTML]="selectedBooking | shipToDetails"></p>
              </ng-container>

              <div class="update-change">
                <span class="display-sec">
                  <h6 class="d-inline-block pr-1">Estimated Delivery:</h6>
                  {{ selectedBooking?.delivery_estimated | yearFormat }}
                </span>
              </div>
            </div>
            <div class="dynamic-progress progtrckr-done blue end-point"></div>
          </li>
        </ul>
      </span>
    </div>
    <div class="full-src setup-top" *ngIf="selectedBooking?.isFullViewAccess">
      <h3 class="update-sec">ADDITIONAL DETAILS</h3>
      <div class="logs">
        <div id="sectionmilestones" class="card change-icon add-border" *ngIf="selectedBooking?.milestones?.length > 0">
          <div class="card-header" (click)="addClassFor('#sectionmilestones')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne" class="btn btn-link">
                <a class="btn-header-link">Milestones</a>
              </button>
            </h5>
          </div>
          <div id="collapseOne" aria-labelledby="sectionmilestones" class="collapse show">
            <div class="card-body container-color-bg">
              <div class="mobile-view row">
                <table class="table table-sortable document-sec mb-0" aria-labelledby="document-table"
                  id="account-table">
                  <thead>
                    <tr style="height: 46px">
                      <th [appSort]="selectedBooking?.milestones" id="event_code" class="desc sort fix-w"
                        data-order="asc" data-name="event_code" data-parent="#account-table">
                        Event Code
                      </th>
                      <th [appSort]="selectedBooking?.milestones" id="event_name" class="asc sort" data-order="asc"
                        data-name="event_name" data-parent="#account-table">
                        Event Description
                      </th>
                      <th [appSort]="selectedBooking?.milestones" id="estimated_date" class="asc sort fix-w"
                        data-order="asc" data-name="estimated_date" data-parent="#account-table">
                        Estimated Date
                      </th>
                      <th [appSort]="selectedBooking?.milestones" id="actual_date" class="desc factive sort fix-w"
                        data-order="asc" data-name="actual_date" data-parent="#account-table">
                        Actual Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="height: 46px" *ngFor="
                        let file of selectedBooking?.milestones;
                        let i = index
                      ">
                      <td [title]="file?.event_code">
                        <span class="add-middle-dots">{{
                          file.event_code
                          }}</span>
                      </td>
                      <td [title]="file?.event_name">
                        <span class="trim-set-mile"><span>{{ file.event_name }}</span></span>
                      </td>
                      <td>{{ file.estimated_date | dateFormatPipe : "MMMDDYYYY" }}</td>
                      <td>{{ file.actual_date | dateFormatPipe : "MMMDDYYYY" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="sectionbookingdetails" class="card change-icon add-border">
          <div class="card-header" (click)="addClassFor('#sectionbookingdetails')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#sectionbookingOne" aria-expanded="true"
                aria-controls="sectionbookingOne" class="btn btn-link">
                <a class="btn-header-link">Booking Details</a>
              </button>
            </h5>
          </div>
          <div id="sectionbookingOne" aria-labelledby="sectionbookingdetails" class="collapse show">
            <div class="col-md-12 container-color-bg booking-dls-ctr">
              <div class="mobile-view row">
                <div class="col-12 mb-2 mt-4">
                  <h5>Goods Description</h5>
                  <p>
                    {{
                    selectedBooking?.description_of_goods
                    ? selectedBooking?.description_of_goods.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-12 mob-top" style="padding-bottom: 20px">
                  <h5 class="mt-1">Marks & Numbers</h5>

                  <p class="inline-set" *ngIf="selectedBooking?.marks_and_numbers">
                    <span [innerText]="
                        readMoreMarksNumbers
                          ? (selectedBooking?.marks_and_numbers
                            | slice : 0 : 60)
                          : selectedBooking?.marks_and_numbers
                      "></span>
                  </p>
                  <p class="displayset set-top" *ngIf="selectedBooking?.marks_and_numbers">
                    <span *ngIf="readMoreMarksNumbers"> ...</span>
                    <a href="javascript:;" *ngIf="readMoreMarksNumbers" (click)="readMoreMarksNumbers = false">More</a>
                  </p>
                  <p *ngIf="!selectedBooking?.marks_and_numbers">---</p>
                </div>
                <div class="col-sm-6 col-md-3 mt-2 mb-3 line-set col-6 mt-2">
                  <h5>Gross Weight</h5>
                  <p *ngIf="
                      selectedBooking?.gross_weight_kgs &&
                      selectedBooking?.gross_weight_kgs > 0
                    ">
                    {{ selectedBooking?.gross_weight_kgs }}&nbsp;KGS
                  </p>
                  <p *ngIf="
                      (!selectedBooking?.gross_weight_kgs ||
                        selectedBooking?.gross_weight_kgs <= 0) &&
                      selectedBooking?.gross_weight_lbs &&
                      selectedBooking?.gross_weight_lbs > 0
                    ">
                    {{ selectedBooking?.gross_weight_lbs }}&nbsp;LBS
                  </p>
                  <p *ngIf="
                      ((!selectedBooking?.gross_weight_kgs ||
                        selectedBooking?.gross_weight_kgs <= 0) &&
                        !selectedBooking?.gross_weight_lbs) ||
                      selectedBooking?.gross_weight_lbs <= 0
                    ">
                    ---
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 mb-3 line-set col-6">
                  <h5>Chargeable Weight</h5>
                  <p>
                    {{
                    selectedBooking?.chargeable_weight
                    ? selectedBooking?.chargeable_weight
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 mb-3 line-set col-6">
                  <h5>Volume</h5>
                  <p *ngIf="
                      selectedBooking?.volume_cbms &&
                      selectedBooking?.volume_cbms >= 0
                    ">
                    {{ selectedBooking?.volume_cbms }}&nbsp;M<sup>3</sup>
                  </p>
                  <p *ngIf="
                      (!selectedBooking?.volume_cbms ||
                        selectedBooking?.volume_cbms < 0) &&
                      selectedBooking?.volume_cf &&
                      selectedBooking?.volume_cf >= 0
                    ">
                    {{ selectedBooking?.volume_cf }}&nbsp;CF
                  </p>
                  <p *ngIf="
                      (!selectedBooking?.volume_cbms ||
                        selectedBooking?.volume_cbms < 0) &&
                      (!selectedBooking?.volume_cf ||
                        selectedBooking?.volume_cf < 0)
                    ">
                    ---
                  </p>
                </div>
                <div class="col-sm-6 mt-2 col-md-3 mb-3 line-set col-6">
                  <h5>Packs</h5>
                  <p>
                    {{
                    selectedBooking?.number_of_outer_packages
                    ? selectedBooking?.number_of_outer_packages?.toUpperCase() +
                    " "
                    : "--- "
                    }}
                    {{ selectedBooking?.outer_pack_package_type_code }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                  <h5>Incoterm</h5>
                  <p>
                    {{
                    selectedBooking?.inco_terms_description
                    ? selectedBooking?.inco_terms_description?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                  <h5>Release Type</h5>
                  <p>
                    {{
                    selectedBooking?.release_type_description
                    ? selectedBooking?.release_type_description?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                  <h5>House Bill of Lading</h5>
                  <p>
                    {{
                    selectedBooking?.house_bill
                    ? selectedBooking?.house_bill?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top">
                  <h5>Carrier</h5>
                  <p>
                    {{
                    selectedBooking?.carrier?.name
                    ? selectedBooking?.carrier?.name?.toUpperCase()
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top" *ngIf="selectedBooking?.must_pickup_by_date">
                  <h5>Pickup Required By</h5>
                  <p>
                    {{
                    selectedBooking?.must_pickup_by_date
                    ? getDateForatMMMDDYYYY(
                    selectedBooking?.must_pickup_by_date
                    )
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top" *ngIf="selectedBooking?.must_deliver_by_date">
                  <h5>Delivery Required By</h5>
                  <p>
                    {{
                    selectedBooking?.must_deliver_by_date
                    ? getDateForatMMMDDYYYY(
                    selectedBooking?.must_deliver_by_date
                    )
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6 mob-top"
                  *ngIf="selectedBooking?.requested_final_destination_date">
                  <h5>Client Requested ETA</h5>
                  <p>
                    {{
                    selectedBooking?.requested_final_destination_date
                    ? getDateForatMMMDDYYYY(
                    selectedBooking?.requested_final_destination_date
                    )
                    : "---"
                    }}
                  </p>
                </div>
                <div class="col-sm-6 col-md-3 line-set col-6"></div>
                <div class="col-12 mb-2 new-pipe-design" *ngIf="showCustomField">
                  <span [innerHTML]="
                      customFieldList | customFieldRenderPipe : 'Shipment'
                    "></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sectionnotes" class="card change-icon add-border"
          *ngIf="displayNotes && selectedBooking.isFullViewAccess">
          <div class="card-header" (click)="addClassFor('#sectionnotes')">
            <h5 class="mb-0">
              <button id="shipment-btn-pr" data-toggle="collapse" data-target="#sectionnotescollapse"
                aria-expanded="true" aria-controls="sectionnotescollapse" class="btn btn-link">
                <a class="btn-header-link">Notes</a>
              </button>
            </h5>
          </div>
          <div id="sectionnotescollapse" aria-labelledby="sectionnotes" class="collapse show">
            <div class="col-md-12 container-color-bg booking-dls-ctr card-body">
              <div class="row">
                <div class="col-md-12">
                  <p class="shipNoteFontNormal" [innerText]="
                      readMoreNotes
                        ? (displayNotes | slice : 0 : 180)
                        : displayNotes
                    "></p>
                  <P class="displayset">
                    <span *ngIf="readMoreNotes"> ...</span>
                    <a href="javascript:;" *ngIf="readMoreNotes" (click)="readMoreNotes = false">More</a>
                  </P>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="
            selectedBooking?.purchase_orders?.length > 0 ||
            selectedBooking?.shipper_reference?.length > 0 ||
            doAdditionalReferencesExist(selectedBooking?.native_reference_numbers)
          ">
          <div id="bookingRefSec" class="card change-icon add-border">
            <div class="card-header" (click)="addClassFor('#bookingRefSec')">
              <h5 class="mb-0">
                <button id="shipment-btn-pr" data-toggle="collapse" data-target="#bookingRefSeccollapse"
                  aria-expanded="true" aria-controls="bookingRefSeccollapse" class="btn btn-link">
                  <a class="btn-header-link">References</a>
                </button>
              </h5>
            </div>
            <div id="bookingRefSeccollapse" aria-labelledby="bookingRefSec" class="collapse show">
              <div class="card-body container-color-bg">
                <div class="row">
                  <div class="col-md-12">
                    <ng-container *ngIf="
                        selectedBooking?.purchase_orders &&
                        selectedBooking?.purchase_orders?.length > 0
                      ">
                      <h5>Job References</h5>
                      <p class="displayset"
                        [innerHTML]="selectedBooking?.purchase_orders | shipmentReferenceNumberPipe : 0">
                      </p>
                    </ng-container>
                    <ng-container *ngIf="
                        selectedBooking?.shipper_reference &&
                        selectedBooking?.shipper_reference?.length > 0
                      ">
                      <h5 class="displayset">Shipper References</h5>
                      <p class="displayset">
                        {{
                        selectedBooking?.shipper_reference
                        ? selectedBooking?.shipper_reference
                        : "---"
                        }}
                      </p>
                    </ng-container>
                  </div>
                  <div class="col-md-12" *ngIf="
                      selectedBooking?.native_reference_numbers &&
                      selectedBooking?.native_reference_numbers?.length > 0 &&
                      selectedBooking?.isFullViewAccess
                    ">
                    <span [innerHTML]="
                        selectedBooking?.native_reference_numbers
                          | additionalReferenceRenderPipe
                      "></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>