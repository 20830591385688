import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AI_CommonEvents,
  AI_CustomProps,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss'],
})
export class BroadcastMessageComponent implements OnChanges {
  @Input() broadCastData: any;
  closeButtonEnable = false;
  linkText = '';
  linkTextEnable = false;
  textSize = '';
  messageColor = '';
  message = '';
  isShow = false;
  @Output()
  closeBroadCastMessage = new EventEmitter();

  constructor(
    private appInsightsService: AppInsightsService,
    public dataSharingService: DataSharingService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.broadCastData &&
      sessionStorage.getItem('isBroadCastClose') != 'true'
    ) {
      this.isShow = true;
      this.closeButtonEnable = this.broadCastData.displayCloseButton;
      this.linkTextEnable = this.broadCastData.linkTopBarStatus;
      this.linkText = this.broadCastData.linkText;
      this.textSize = this.broadCastData.messageFontSize;
      this.message = this.broadCastData.message;
      this.messageColor = this.broadCastData.messageColor;
      this.document.body.classList.add('toasterMsg');
    }
  }

  openLink() {
    this.tractAIBroadcastLinkViewed(this.broadCastData.linkUrl);

    if (this.broadCastData.openLinkInNewTab) {
      if (
        this.broadCastData.linkUrl.includes('http') ||
        this.broadCastData.linkUrl.includes('https')
      ) {
        window.open(this.broadCastData.linkUrl, '_blank');
      } else {
        window.open('//' + this.broadCastData.linkUrl, '_blank');
      }
    } else {
      if (
        this.broadCastData.linkUrl.includes('http') ||
        this.broadCastData.linkUrl.includes('https')
      ) {
        window.open(this.broadCastData.linkUrl, '_self');
      } else {
        window.open('//' + this.broadCastData.linkUrl, '_self');
      }
    }
  }

  close() {
    sessionStorage.setItem('isBroadCastClose', 'true');
    this.isShow = false;
    this.closeBroadCastMessage.emit(true);
    this.document.body.classList.remove('toasterMsg');
  }

  tractAIBroadcastLinkViewed(fileURL: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.BroadcastLinkClicked, {
      [AI_CustomProps.BroadcastMessageLinkURL]: fileURL,
    });
  }
}
