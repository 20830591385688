import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChangeLogsComponent } from 'src/app/shared/component/change-logs/change-logs.component';

import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { EdocUploadStatusService } from 'src/app/shared/services/eDoc-upload-status.service';
import { CustomsDetailsHeaderComponent } from '../customs-details-header/customs-details-header.component';
import { CustomsService } from '../../services/customs.service';
import { ActionDetailTabComponent } from '../action-detail-tab/action-detail-tab.component';
@Component({
  selector: 'app-customs-details',
  templateUrl: './customs-details.component.html',
  styleUrls: ['./customs-details.component.scss'],
})
export class CustomsDetailsComponent {
  @Input() selectedCustom: any = [];
  @Input() popupToggle: any;
  @Input() urlCustomsId: any = '';
  @Input() urlShare: any = '';
  @Input() publicShareEnrcyptedData: any = '';
  @Output() onRefreshDetailsCustoms: any = new EventEmitter<any>();
  @Output() detailPopClose = new EventEmitter<any>();
  @Output() onDetailPopupCloseHandler = new EventEmitter<any>();
  @Output() prepareUrlShare = new EventEmitter<any>();

  @ViewChild(CustomsDetailsHeaderComponent)
  customHeaderComponent!: CustomsDetailsHeaderComponent;
  @ViewChild(ChangeLogsComponent)
  ChangeLogsComponent!: ChangeLogsComponent;
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  hasActionField = this.currentAccountData.isCustomActionField;

  constructor(
    private edocUploadStatusService: EdocUploadStatusService,
    private dataSharing: DataSharingService,
    private currentSessionUserData: CurrentSessionDataService,
    private customService: CustomsService
  ) { }

  @ViewChild(ActionDetailTabComponent)
  actionFieldtabComponent: ActionDetailTabComponent;
  featureHide() {
    if (
      this.edocUploadStatusService.fileUploadStatus_LocalStorage().length == 0
    ) {
      if (this.hasActionField && this.customService.isActionFieldChanged) {
        this.actionFieldtabComponent.closeActionField();
      }
      else {
        this.popUpClose();
        this.popCloseForUpload();
      }
    } else {
      this.dataSharing.eDocUploadTrigger.next({ popup: true, reload: false });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.urlCustomsId && this.urlCustomsId !== '') {
      setTimeout(() => {
        this.addFullScreen(this.urlCustomsId);
        setTimeout(() => {
          this.urlCustomsId = '';
        }, 2000);
      }, 0);
    }
    this.loadDocuments();
  }

  loadDocuments() {
    this.selectedCustom?.attachments?.forEach((ele: any, index: any) => {
      let file_exe = ele.file_name.split('.').pop().toUpperCase();
      this.selectedCustom.attachments[index].file_type = file_exe;
      let regex = /[+-]?\d+(\.\d+)?/g;
      let floats = ele.file_size.match(regex).map(function (v: any) {
        return parseFloat(v);
      });
      let fileSizeSplit = ele.file_size.toLowerCase();
      if (fileSizeSplit.includes('kb')) {
        this.selectedCustom.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 1);
      } else if (fileSizeSplit.includes('mb')) {
        this.selectedCustom.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 2);
      } else if (fileSizeSplit.includes('gb')) {
        this.selectedCustom.attachments[index].file_size_bytes =
          floats[0] * Math.pow(1024, 3);
      } else if (fileSizeSplit.includes('bytes')) {
        this.selectedCustom.attachments[index].file_size_bytes = floats[0];
      }
    });
  }

  popUpClose() {
    this.detailPopClose.emit(false);
    this.customService.isActionFieldChanged = false;
  }

  popCloseForUpload() {
    this.popupToggle = false;
    $('#custom-sec-id > div').removeClass('active');
  }

  addFullScreen(value: any) {
    if (value) {
      $('#navCenter').addClass('justify-content-center');
      $('#shipmentFull').addClass('shipmet-fullwidth shimpent-removeicon');
      $('.dimScreen').addClass('shipment-fullwidth');
    }
  }

  reloadWatchListCustom(event: any) {
    this.onDetailPopupCloseHandler.emit({
      value: 'LOAD CUSTOMS',
      customsId: event,
    });
  }

  checkSFile(id: any) {
    if (id.charAt(0).toLowerCase() === 's') {
      return true;
    } else {
      return false;
    }
  }

  loadFullReferenceNum(event: any) {
    if (event) {
      this.customHeaderComponent.gotoRefSection();
    }
  }

  share(event: any) {
    this.prepareUrlShare.emit(event);
  }

  onReloadDetails(event: any) {
    if (this.currentUserData && event.access) {
      this.ChangeLogsComponent.getLogsCount();

      if (this.ChangeLogsComponent.categoryTypes.length > 0) {
        let sort = [
          {
            column_name: 'createDateTime',
            direction: 'desc',
          },
        ];
        this.ChangeLogsComponent.categoryTypes.forEach((element: any) => {
          this.ChangeLogsComponent.getChangeLogs(
            element.changeLogCategory.toString(),
            sort
          );
        });
      }
    }

    this.onRefreshDetailsCustoms.emit({
      value: 'LOAD DETAILS',
      shipmentId: event.shipmentId,
      access: event.access,
    });
  }

  @Output() loadCustomList = new EventEmitter();

  loadCustomListById(id: any) {
    this.loadCustomList.emit(id);
  }
}
