<div *ngIf="isShowMessage" class="newuser-toast-bg full-ship-toast booking-login">
  <div class="text-center contain-bg">
    <span (click)="redirectToLogin()" class="newuser_link">{{
      showMessage
      }}</span>

    <span class="close-button" (click)="closeToasterMsg()">
      <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="10.231" viewBox="0 0 10.232 10.231">
        <g data-name="Group 2379">
          <path data-name="Path 6681"
            d="m5.382 4.847 4.2-4.2a.379.379 0 0 0-.535-.535l-4.2 4.2L.646.112a.379.379 0 0 0-.535.535l4.2 4.2-4.2 4.2a.379.379 0 0 0 .536.535l4.2-4.2 4.2 4.2a.379.379 0 1 0 .536-.535z"
            transform="translate(.288 .287)" style="fill: #01457c; stroke: #01457c; stroke-width: 0.5px" />
        </g>
      </svg>
    </span>
  </div>
</div>
<div class="header-fix fixed-fullwidth" id="bookHeader" [ngClass]="[
    !(selectedBooking?.purchase_orders | bookingReferenceNumber : 0)
      ? 'my_class'
      : 'add-job-ref',
    selectedBooking.status != 'Converted' ? 'add-heig' : '',
    selectedBooking.booking_reference ? 'ref-added' : '',
    (selectedBooking?.purchase_orders | bookingReferenceNumber : 0).length > 39
      ? 'add-sm-bad'
      : 'remove-sm-bad'
  ]">
  <div *ngIf="showToastMessage" class="newuser-toast-bg remove-added-toast full-ship-toast">
    <div class="text-center contain-bg">
      <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
        {{ message }}
      </span>
      <span class="close-button" (click)="closeToasterMsg()"><img src="../../assets/img/close_whitebg_remove.svg"
          alt="" /></span>
    </div>
  </div>
  <div *ngIf="bookingRefreshToast" class="newuser-toast-bg add-remove-toast toast-limited-acess page-refreshed">
    <div class="text-center contain-bg pge-rfresh">
      <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
        Page is Refreshed
      </span>
      <span class="close-button" (click)="closeRefreshToast()"><img src="../../assets/img/close_whitebg_remove.svg"
          alt="" /></span>
    </div>
  </div>
  <div *ngIf="isShowMessageFileuploadSec" class="newuser-toast-bg newuser-toast-bg-top">
    <div class="text-center contain-bg">
      <span class="newuser-toast-txt"><img src="../../assets/img/okay.png" alt="" />
        Document Categories Successfully Updated
      </span>
      <span class="close-button" (click)="closeToasterFromFileUpload()">X6</span>
    </div>
  </div>
  <div class="head-sec set-one">
    <div class="left-sec"></div>
    <div class="col-lg-6 col-md-12 text-right">
      <button class="btn btn-primary shipment-btn mr-2 {{
          selectedBooking?.status | exceptionClass : selectedBooking?.exception : 'bookings'
        }} {{ selectedBooking?.status | titlecase }}">
        {{
        selectedBooking?.status === null ? "NULL" : selectedBooking?.status
        }}
      </button>
      <div class="image-container">
        <div class="dropdown added-set">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img src="../../assets/img/actionmenu-header.png" alt="action-menu" />
          </button>
          <div class="dropdown-menu no-close" aria-labelledby="dropdownMenuButton">
            <a *ngIf="!watchListToggle && selectedBooking?.isFullViewAccess" href="javascript:void(0)"
              class="dropdown-item" (click)="
                addWatchList('Add', selectedBooking?.forwarder_reference)
              ">
              <span>Watchlist</span></a>
            <a *ngIf="watchListToggle && selectedBooking?.isFullViewAccess" href="javascript:void(0)"
              class="dropdown-item checkedClass" (click)="
                addWatchList('Remove', selectedBooking?.forwarder_reference)
              "><img class="image-okay" src="../../assets/img/active-data.svg" alt="" /><span>Watchlist</span></a>
            <a class="dropdown-item"
              (click)="share(selectedBooking.forwarder_reference, 'Detail')"><span>Share</span></a>
            <a (click)="refreshBookingDetails()" class="dropdown-item">
              <div *ngIf="shipmentRefreshIcon" class="refresh-icon"></div>
              <span [ngClass]="{ 'refreshing-set': shipmentRefreshIcon }">{{
                shipmentRefreshIcon ? "Refreshing" : "Refresh"
                }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="head-sec">
    <div class="left-sec">
      <div class="detail-shipment">
        <div class="r-1">
          <div class="trans-status">
            <!-- Status icon render with exception color start-->
            <app-status-icon [transport_mode]="selectedBooking?.transport_mode" [status]="selectedBooking?.status"
              [exception]="selectedBooking?.exception" [module]="'bookings'"></app-status-icon>
            <!-- Status icon render with exception color end-->
          </div>
        </div>
        <div class="r-2">
          <h3 class="id-number pb-0 pr-0">
            {{ selectedBooking?.forwarder_reference }}
          </h3>
          <span class="container-sec">{{ selectedBooking?.containerization_type }}
          </span>
          <a (click)="
              openBookingsFullScreen(
                $event,
                selectedBooking.forwarder_reference,
                'list'
              )
            " class="pointer first-icon">
            <img src="../../../../../assets/img/shipments/open-new.svg" alt="" class="mr-1" />
          </a>
          <div class="container-sec p-0">
            <p>
              <span *ngIf="
                  selectedBooking?.booking_reference &&
                  selectedBooking?.isFullViewAccess
                ">
                Ref:&nbsp;{{ selectedBooking?.booking_reference }}
              </span>
            </p>
          </div>
        </div>
        <span class="con-level" *ngIf="selectedBooking?.isFullViewAccess">
          <span *ngIf="selectedBooking?.shipper?.name" class="shipper-sec">
            <strong class="pr-2">Shipper:</strong>{{
            selectedBooking?.shipper?.name
            ? selectedBooking?.shipper?.name
            : "---"
            }}
          </span>
          <p class="fixed-w mobile-left m-top set-top">
            <strong>Consignee:</strong>
            <span class="pl-2 consignee-name">{{
              selectedBooking?.consignee?.name
              ? selectedBooking?.consignee?.name
              : "---"
              }}</span>
          </p>
          <p *ngIf="
              selectedBooking?.purchase_orders | bookingReferenceNumber : 0
            " class="ref-width mobile-left p-0 linspace set-move-one">
            <span class="data-ref"
              [innerHtml]="selectedBooking?.purchase_orders | bookingReferenceNumber : refLengthCheck"></span>
            <a href="javascript:void(0)" class="show-more" *ngIf="
                (selectedBooking?.purchase_orders | bookingReferenceNumber : 0)
                  .length > refLengthCheck
              " (click)="gotoRefSection()">More</a>
          </p>
        </span>
      </div>
    </div>
    <div class="right-sec">
      <div class="detail-shipment update-top-space mobile-left m-top">
        <h3 class="location pr-0 pb-0" *ngIf="selectedBooking | pickupDeliveryPortCountry">
          <strong>
            {{ selectedBooking | pickupDeliveryPortCountry }}
          </strong>
        </h3>
        <p>
          <strong>Booked Date:</strong>
          <span class="pl-2">{{ selectedBooking | bookedDate }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 text-right view-set" *ngIf="selectedBooking.status == 'Converted'">
    <button class="view-shipment" (click)="viewShipment(selectedBooking?.forwarder_reference)">
      View shipment
    </button>
  </div>
  <div class="row">
    <ul class="nav nav-tabs tab-nav" id="myTab" role="tablist" id="navCenter" #navCenter>
      <li class="nav-item" role="presentation" (click)="detailTabScrollTop()">
        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home1" type="button"
          role="tab" aria-controls="home" aria-selected="true" (click)="setCurrentTab(1)">
          Details
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="selectedBooking?.isFullViewAccess">
        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile1" type="button"
          role="tab" aria-controls="profile" aria-selected="false" (click)="setCurrentTab(2)">
          Documents
          <strong>{{ selectedBooking?.attachments?.length || 0 }}</strong>
        </button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="hasActionField">
        <button class="nav-link" id="action-tab" data-bs-toggle="tab" data-bs-target="#action-tab-bookings"
          type="button" role="tab" aria-controls="profile" aria-selected="false" (click)="setCurrentTab(3)">
          Actions
        </button>
      </li>
      <li *ngIf="selectedBooking.isFullViewAccess && logsTabCount > 0" class="nav-item" role="presentation">
        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#logs1" type="button" role="tab"
          aria-controls="profile" aria-selected="false" (click)="setCurrentTab(6)">
          Logs
        </button>
      </li>
    </ul>
  </div>
</div>