import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtils } from './utils';

@Pipe({
  name: 'convertToTitlecase'
})
export class ConvertToTitlecasePipe implements PipeTransform {

  transform(str: string, refNum: any): unknown {
    let text = '';
    if (!str && !refNum) {
      return text;
    }
    if (str && refNum) {
      text = CommonUtils.toTitleCase(str) + ' - Ref: ' + refNum;
    } else if (str) {
      text = CommonUtils.toTitleCase(str);
    } else {
      text = 'Ref: ' + refNum;
    }
    return text;
  }

}
