export class CommonValidation {
  isDecimal(value: any) {
    return /^-?(\d*\.\d+)$/.test(value);
  }

  isNumber(value: any) {
    return /^-?(\d+)$/.test(value);
  }

  static canAccessNP(emailAddress: string): boolean {
    if (!emailAddress) return false;

    let userType = emailAddress.split('@').pop();
    if (!userType) return false;
    //console.log('Email = ', emailAddress);

    switch (userType) {
      case 'oiaglobal.com':
        if (
          [
            'steven.bui@oiaglobal.com',
            'adam.jones@oiaglobal.com',
            'mike.mostachetti@oiaglobal.com',
            'jacob.hills@oiaglobal.com',
            'samuel.lewis@oiaglobal.com',
            'pragya.manandhar@oiaglobal.com',
            'oc3-test2@oiaglobal.com',
            'oc3-test8@oiaglobal.com',
            'oc3-test12@oiaglobal.com',
            'oc3-test15@oiaglobal.com',
            'oc3-test17@oiaglobal.com',
          ].includes(emailAddress?.trim().toLowerCase())
        ) {
          //console.log('NP visibile oiaglobal.com');
          return true;
        }
        return false;
      case 'outlook.com':
        return false;
      case 'gmail.com':
        if (
          ['systemrole.testqa@gmail.com', 'userrole.testqa@gmail.com'].includes(
            emailAddress?.trim()
          )
        ) {
          //console.log('NP visibile gmail');
          return true;
        }
        return false;
      case 'photon.in':
      case 'photon.com':
        //console.log('NP visibile Photon account');
        return true;
    }
    return false;
  }
}
