import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtils } from 'src/app/common/utils';
import {
  AppInsightsService,
  AI_PageName,
  AI_CustomProps,
  AI_CommonEvents,
} from 'src/app/services/appinsights.service';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit {
  faqs: Faq[] = [];
  searchedText = '';
  resultNotFound = false;
  linkCopied = false;
  publicPage = false;
  resultCount: any = '';
  textoneForId23 =
    '1. Default filters, available in the left side of the shipment’s list';
  texttwoForId23 = '2. Additional filter options next to the search box';
  textoneBForId23 =
    '1. Default filters, available in the left side of the bookings list';
  shipmentDefaultFilterImg = 'assets/img/faq/shipment-default-filter.png';
  shipmentCustomFilterImg = 'assets/img/faq/shipment-custom-filter.png';
  bookingDefaultFilterImg = 'assets/img/faq/booking-default-filter.png';
  bookingCustomFilterImg = 'assets/img/faq/booking-custom-filter.png';
  currentUserData: any;
  placeholderText: any = 'Search by keyword';
  faqUrl = false;
  questionAnswerArray: any = [];
  scrHeight: any;
  scrWidth: any;
  item = 0;
  nextBtnClickCounter = 0;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private titleService: Title,
    private appInsightsService: AppInsightsService,
    private activatedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private currentSessionUserData: CurrentSessionDataService,
    private router: Router
  ) {
    this.currentUserData = this.currentSessionUserData.getCurrentUserData();
  }

  ngOnInit(): void {
    this.loadInitialData();
  }

  loadInitialData() {
    this.trackAIPageView();
    this.getScreenSize();
    this.showPageTitle();
    this.faqs = this.loadDefaultFaqs();
    this.loadActivatedRouteParams();
    this.checkLogin();
  }

  checkLogin() {
    if (!this.currentUserData) {
      this.publicPage = true;
      this.dataSharingService.notLoggedIn.next(true);
    }
  }

  loadActivatedRouteParams() {
    let faqKeyword = this.activatedRoute.snapshot.params['question'];
    if (faqKeyword) {
      setTimeout(() => {
        let sharedFaq: any = this.faqs.filter((x) => x.keyword == faqKeyword);
        if (sharedFaq.length > 0) {
          let faqurl = window.location.href;
          this.trackAIFaqLinkVisited(faqurl, sharedFaq[0].id, faqKeyword);
          this.faqUrl = true;
          //$('#faqId' + sharedFaq[0].id).click(); this code has an issue of getting aligned to top
          this.scrollToSelectedFaq(sharedFaq[0].id);
        }
      }, 10);
    }
  }

  scrollToSelectedFaq(id: any) {
    $('#card' + id + '> .add-padding-set').addClass('show');
    $('.scroll-update').scrollTop($('#card' + id).position().top);
  }

  redirectToLogin() {
    this.router.navigate(['/login/log-in']);
  }

  closeCopyText() {
    this.linkCopied = false;
  }

  closeToast() {
    this.publicPage = false;
  }

  showPageTitle() {
    CommonUtils.setPageTitle(this.titleService, 'FAQs');
  }

  loadDefaultFaqs() {
    let faqs: Faq[] = [
      {
        id: 1,
        question: 'How to register to OIA Connect?',
        answer: [
          {
            pointNo: 1,
            point:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/h_macjVsO-k?si=ura9-T2-NekASgAH&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><p style="margin-top: 20px;">OIA Connect requires a corporate email address for security and authentication purposes. To request access to an OIA Connect account, please follow these registration steps:',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 2,
            point:
              '<p><br>1. Visit <a href="https://www.oiaconnect.com/login/register" style="color: #14caeb;" target="_blank" >https://www.oiaconnect.com/login/register</a>.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 3,
            point:
              '<p>2. Click on <i>Register with Microsoft.</i>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 4,
            point:
              '<p>3. Enter your corporate email address.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 5,
            point: '<p>4. Click on ‘Next’.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 6,
            point:
              '<p>5. Enter your password for the email account.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 7,
            point:
              '<p>6. Click on ‘Accept’.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 8,
            point:
              '<p><br>The support team’s administrators will review your OIA Connect registration request. You will receive an email notification when/if your request is approved. You may start using OIA Connect by logging in at <a href="https://www.oiaconnect.com" style="color: #14caeb;" target="_blank" >www.oiaconnect.com</a>.',
            image: '',
            classImg: '',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/How_to_Register',
        keyword: 'How_to_Register',
      },
      {
        id: 2,
        question: 'How can I contact support?',
        answer: [
          {
            pointNo: 1,
            point:
              "<p><ul><li>For technical support, please contact <a style='color: #14caeb;' href='mailto: connect.support@oiaglobal.com'>connect.support@oiaglobal.com </a>.</p></li><li><p>For general assistance, please contact your OIA Global Account Manager and/or the Operations Team.</li></p>",
            image: '',
            classImg: '',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/OIA_support',
        keyword: 'OIA_support',
      },
      {
        id: 3,
        question: 'How can I invite someone to join OIA Connect?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>1. Go to the ‘My Profile’ section via the top right navigation menu.</p><p>2. Select ‘Invite User’.</p><p>3. Type in the appropriate email address.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 2,
            point:
              '<br><p>The potential user will receive a link via email and must use it to complete the registration process. The new user will only gain access to OIA Connect after administrator approval.',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 3,
            point: '',
            image: 'assets/img/faq/qa-3.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Invite_users',
        keyword: 'Invite_users',
      },
      {
        id: 4,
        question: 'How can I edit my personal details?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>1. Select the ‘My Profile’ section via the top right navigation menu.</p><p>2. Select ‘edit details’ </p><p>3. Update your information.</p>',
            image: 'assets/img/faq/qa-4.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Edit_personal_details',
        keyword: 'Edit_personal_details',
      },
      {
        id: 5,
        question: 'How do I search for a shipment?',
        answer: [
          {
            pointNo: 1,
            point:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/HdHqPEAsmQE?si=X5d1fJO77aWTPgmo&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><div style="margin-top: 20px;" class="added-bold">Quick Search Tool</div><p>The shipment dashboard’s quick search tool allows you to search for a shipment using a shipment number, reference number or container number.</p><br>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 2,
            point:
              '<span class="added-bold">Global Search Tool</span><p>You may also use the Global Search Tool, which is available via the top navigation menu.</p><ul><li>Enter <span style="font-style:italic">any</span> keyword to begin searching!</li><li>You can search using full or partial shipment numbers, reference numbers, or container numbers.</li><li>Search for multiple shipments by entering multiple shipment numbers separated by a comma and without a space, for example: S01098070,S01098071</li></ul>',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Search_shipments',
        keyword: 'Search_shipments',
      },
      {
        id: 6,
        question: 'How do I search for a booking?',
        answer: [
          {
            pointNo: 1,
            point:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/EiPbJNPI9Ak?si=KpI56zDbQPC57Rwf&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><div style="margin-top: 20px;">You can search for a booking using the Booking screen’s quick search tool. Simply input a booking number or reference number into the search bar. You can also use the global search tool, which is available via the top navigation menu. Enter <span style="font-style:italic">any</span> keyword into the search bar to begin.</div>',
            image: '',
            classImg: '',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Search_bookings',
        keyword: 'Search_bookings',
      },
      {
        id: 7,
        question: 'How can I track the progress of a shipment?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>1. Select the ‘Shipments’ tab from the main navigation menu.</p><p>2. Select ‘Details’ for an individual shipment to show more information about the shipment’s in-transit progress.</p>',
            image: '',
            classImg: '',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Track_shipment_progress',
        keyword: 'Track_shipment_progress',
      },
      {
        id: 8,
        question: 'How can I find the status of a shipment?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>The shipment’s status is located directly next to the shipment number, and clicking on the shipment number will also display more information.</p>',
            image: '',
            classImg: '',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Shipment_status',
        keyword: 'Shipment_status',
      },
      {
        id: 9,
        question: 'Can I search for shipments associated with other accounts?',
        answer: [
          {
            pointNo: 1,
            point: 'No, OIA Connect only shows the shipments associated with the account for which you are logged in. If you need to see additional shipments within the same screen, please use the Account Groups feature, accessed via the top right navigation menu.',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Search_shipment_for_other_accounts',
        keyword: 'Search_shipment_for_other_accounts',
      },
      {
        id: 10,
        question:
          'How can I create a group with multiple accounts?',
        answer: [
          {
            pointNo: 1,
            point: '<span class="added-bold">You can only create a group if you have multiple accounts to combine.</span> The new group will be displayed in the ‘Shipments’ tab along with all other available options associated with the account. All of your preexisting groups will be listed in the OIA Connect account’s dropdown menu. You will be able to add and/or remove the available options within each group.<br>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 2,
            point: '<br><span class="added-bold">How to Create a Group with Multiple Accounts</span> <p>1. Go to ‘Profile’ section via the top right navigation menu.</p><p>2. Select ‘My Groups’ > ‘Create New Group’.</p><p>3. Create a unique group name.</p><p>4. Select account(s) to add to the new group.</p><p>5. Save the new group.</p>',
            image: 'assets/img/faq/qa-10.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path:
          environment.localUrl + '/faqs/Create_group',
        keyword: 'Create_group',
      },
      {
        id: 11,
        question: 'How can I switch to another account?',
        answer: [
          {
            pointNo: 1,
            point:
              'Use the navigation menu in the top right corner to view and/or select other accounts associated with your profile.',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Switch_accounts',
        keyword: 'Switch_accounts',
      },
      {
        id: 12,
        question: 'How to add a shipment and/or booking into watchlist?',
        answer: [
          {
            pointNo: 1,
            point:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/hRiJ0WKY1Cc?si=4mK-VlxWgZ-YQiVh&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><p style="margin-top: 20px;" class="added-bold">Shipment</p><ul style="margin-bottom:0"><li>In the shipment details screen, locate the 3-dot navigation menu in the upper right corner and select ‘watchlist’ from the drop-down menu.</li></ul><p style="margin-left: 30px">OR</p><ul style="margin-bottom:0"><li>In the shipments list screen, select the eye icon next to the shipment number. The icon’s color will change from light to dark once it has been added to the watchlist.</li></ul>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 2,
            point:
              '<br><p class="added-bold">Bookings</p><ul style="margin-bottom:0"><li>In the Booking details screen, locate the 3-dot navigation menu in the upper right corner and select ‘watchlist’ from the drop-down menu.</li></ul>',
            image: '',
            classImg: '',
            classPo: '',
          },
          {
            pointNo: 3,
            point:
              '<ul style="margin-bottom:0"><li>In the booking list screen, select the eye icon next to the booking number to add it to the watchlist. The icon’s color changes from light to dark once it has been added to the watchlist.</li></ul><br>',
            image: 'assets/img/faq/qa-12.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Add_shipment_Booking_to_watchlist',
        keyword: 'Add_shipment_Booking_to_watchlist',
      },
      {
        id: 13,
        question: 'How can I remove a shipment from my watchlist?',
        answer: [
          {
            pointNo: 1,
            point:
              '<ul style="margin-bottom:0"><li>In the shipment list screen, click on the eye icon next to the shipment number to remove it from the Watchlist. Once removed the icon’s color should change from dark to light blue.</ul></li><p style="margin-left: 30px">OR</p><ul style="margin-bottom:0"><li>In the shipments details screen, locate the 3-dot navigation menu in the upper right corner and uncheck ‘watchlist’ from the drop-down menu.</ul></li>',
            image: 'assets/img/faq/qa-13.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Remove_Shipment_from_watchlist',
        keyword: 'Remove_Shipment_from_watchlist',
      },
      {
        id: 14,
        question:
          'How do I know whether a shipment or booking is on my watchlist?',
        answer: [
          {
            pointNo: 1,
            point:
              'Once a shipment/booking has been added to the watchlist, the eye icon next to the shipment number should change colors to become dark navy blue.<br>',
            image: 'assets/img/faq/qa-14.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Watchlist_added',
        keyword: 'Watchlist_added',
      },
      {
        id: 15,
        question:
          'How many shipments or bookings can be added to my watchlist?',
        answer: [
          {
            pointNo: 1,
            point:
              'There are no restrictions on the number of Watchlist items. The homepage’s watchlist widget automatically displays the first six shipments or bookings.',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Watchlist_limit',
        keyword: 'Watchlist_limit',
      },
      {
        id: 16,
        question: 'How do I use the shipment/booking filter options?',
        answer: [
          {
            pointNo: 1,
            point: '<iframe width="560" height="315" src="https://www.youtube.com/embed/hqXAw-X6Vsk?si=WUhref7Nbmj4OpIQ&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><div style="margin-top: 10px">There are two filtering options:<br><span class="added-bold">1. Default Filters</span><br>Filter different statuses via the left side of the shipment screen.<p>Status definitions:</p><ul><li>Pickup Scheduled – Pickup has been scheduled with an Estimated Pickup date.</li><li>Picked Up – Pickup has been made and has an Actual Pickup date.</li><li>Scheduled – Origin leg has been scheduled with Estimated Departure date (ETD).</li><li>In Transit – Departed from Origin and has an Actual Departure date (ATD).</li><li>Delivery Scheduled – Delivery has been scheduled with an Estimated Delivery date.</li><li>Delivered – Delivered to the Final destination and has an Actual Delivery date.</li></div>',
            image: 'assets/img/faq/qa-16-1.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large parent-ol',
            classPo: 'parent-ol',
          },
          {
            pointNo: 2,
            point:
              '<span class="added-bold">2. Additional Filters</span><br><p>These options are available next to the search box. You can filter the shipments based on transport type, status, consignee, location, date range, exception type, and watchlist.</p>',
            image: 'assets/img/faq/qa-16-2.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large parent-ol',
            classPo: 'parent-ol',
          }
        ],
        path: environment.localUrl + '/faqs/Shipment_filters',
        keyword: 'Shipment_filters',
      },
      {
        id: 17,
        question: 'Can I create and save my own filters?',
        answer: [
          {
            pointNo: 1,
            point:
              'Yes, when you search via filters, select the ‘Save As’ button underneath ‘My Saved filters’ and then select ‘Create New Filter.’',
            image: 'assets/img/faq/qa-17-1.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
          {
            pointNo: 2,
            point: '',
            image: 'assets/img/faq/qa-17-2.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
          {
            pointNo: 3,
            point: '',
            image: 'assets/img/faq/qa-17-3.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/My_Filters',
        keyword: 'My_Filters',
      },
      {
        id: 18,
        question: 'Is there a limit to my saved filters?',
        answer: [
          {
            pointNo: 1,
            point: 'No, you can create as many filters as you like.',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/My_Filters_Limit',
        keyword: 'My_Filters_Limit',
      },
      {
        id: 19,
        question: 'Can I modify the order of my saved filters?',
        answer: [
          {
            pointNo: 1,
            point:
              'No, filters are automatically displayed in alphabetical order.',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/My_filters_order',
        keyword: 'My_filters_order',
      },
      {
        id: 20,
        question: 'Can I share my saved filters with others?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>1. Locate the ‘Share’ button in the top right navigation menu of your filtered list.</p> <p>2. Copy the URL link that’s displayed.</p> <p>3. Paste the URL link into an email and/or digital asset to share it.</p>',
            image: '',
            classImg: '',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Share_My_Filters',
        keyword: 'Share_My_Filters',
      },
      {
        id: 21,
        question: 'How can I sort shipments/bookings?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>You can sort shipments and bookings in ascending or descending order by selecting any of these conditions: shipment/booking number, status, estimated pickup date, actual pickup date, ETD (departure), ETA (arrival), ATD (departure), ATA (arrival), estimated delivery date, and actual delivery date.</p>',
            image: 'assets/img/faq/qa-21.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Sort_Shipments_Bookings',
        keyword: 'Sort_Shipments_Bookings',
      },
      {
        id: 22,
        question: 'How can I see detailed shipment information from the shipment list?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>Select any individual shipment from the list to view detailed information in a new window on the right side of the screen.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/View_Shipment_details',
        keyword: 'View_Shipment_details',
      },
      {
        id: 23,
        question:
          'How can I see the detailed booking information from the booking list?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>Select any individual booking from the list to view the detailed information in a new window on the right side of the screen.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/View_Booking_details',
        keyword: 'View_Booking_details',
      },
      {
        id: 24,
        question: 'Where can I find a shipment’s documents?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>1. Click on the ‘details’ for any individual shipment.<br>2. Select the ‘Documents’ tab from the top navigation menu.</p>',
            image: 'assets/img/faq/qa-24.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Shipment_documents',
        keyword: 'Shipment_documents',
      },
      {
        id: 25,
        question: 'Can I upload documents to a shipment?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>Yes, you can upload documents to an individual shipment, but you must request it via the OIA Connect administrators. To request this, please contact your Account Manager or the Operations Team.</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Upload_document',
        keyword: 'Upload_document',
      },
      {
        id: 26,
        question: 'How can I view shipment exceptions?',
        answer: [
          {
            pointNo: 1,
            point: '<p>Filter > Exception type</p>',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Shipment_exceptions',
        keyword: 'Shipment_exceptions',
      },
      {
        id: 27,
        question: 'Can I share the individual shipment information with anyone?',
        answer: [
          {
            pointNo: 1,
            point: '<p>Yes, you can share the individual shipment information with anyone, but if the receiver doesn’t already have an OIA Connect login then the displayed information will be limited.<p>1. Choose a specific shipment/booking.</p><p>2. Locate the 3-dot navigation menu in the top right corner.</p><p>3. Select the ‘share URL’ option.</p></p>',
            image: '',
            classImg: '',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Share_shipment',
        keyword: 'Share_shipment',
      },
      {
        id: 28,
        question:
          'Can I download the shipment/booking data to an Excel spreadsheet?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>Yes, you can download the shipment/booking list into a Microsoft Excel file on your local system.<p>1. Choose the shipments/bookings records that you want to download.</p><p>2. Locate the download button.</p><p>3. Select ‘Download’.</p><p>4. Select the ‘download as excel’ option.</p></p>',
            image: 'assets/img/faq/qa-28.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/Excel_Download',
        keyword: 'Excel_Download',
      },
      {
        id: 29,
        question:
          'How to export reports data into Excel?',
        answer: [
          {
            pointNo: 1,
            point:
              '<p>1. Select the ‘Reports’ tab from the top main navigation menu.</p><p>2. Select a specific report from the left side navigation menu.</p><p>3. Go to data Table under Pages.</p><p>4. Hover in the upper right corner over the 3-dot icon, then click on the 3-dot icon.</p><p>5. From the new dropdown menu, Select ‘Export Data’.</p><p>6. In the popup window, choose which type of data you would like to export.</p><p>7. Click ‘export’.</p><br><p>The file will automatically start downloading into your browser’s default download folder and a confirmation message should display once the download is complete.</p><br>',
            image: 'assets/img/faq/qa-29-1.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
          {
            pointNo: 2,
            point: '',
            image: 'assets/img/faq/qa-29-2.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          }

        ],
        path: environment.localUrl + '/faqs/Export_report',
        keyword: 'Export_report',
      },
      {
        id: 30,
        question: 'How do I view Customs records?',
        answer: [
          {
            pointNo: 1,
            point:
              '<iframe style="margin-top: 20px" width="560" height="315" src="https://www.youtube.com/embed/Esa-Za8yYRk?si=7cMBg49N1xRPSTT&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><p style="margin-top: 20px;">1. Select the ‘Customs’ tab from the top main navigation menu.</p>',
            image: 'assets/img/faq/qa-30.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          },
        ],
        path: environment.localUrl + '/faqs/Customs_records',
        keyword: 'Customs_records',
      },
      {
        id: 31,
        question: 'How do I view reports within Connect?',
        answer: [
          {
            pointNo: 1,
            point:
              '<iframe style="margin-top: 20px;padding-right: 20px;" width="560" height="315" src="https://www.youtube.com/embed/NXLjV7XJdo0?si=cMgzb9_DufwuRLam&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><iframe style="margin-top: 20px; padding-right: 20px" width="560" height="315" src="https://www.youtube.com/embed/cSGPABQ2zaY?si=ik1MQShdPiFCVwvE&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><iframe style="margin-top: 20px;padding-right: 20px;" width="560" height="315" src="https://www.youtube.com/embed/dq69DglS2ik?si=f3_LdTzkUEh161xw&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><p style="margin-top: 20px;">1. Select the ‘Reports’ tab from the top main navigation menu.</p><p>2. Select the relevant report in the left-hand panel.</p></p>',
            image: 'assets/img/faq/qa-31.png',
            classImg: 'faq_img_ctrl faq_img_large img_extra_large',
            classPo: '',
          }
        ],
        path: environment.localUrl + '/faqs/View_reports',
        keyword: 'View_reports',
      },
    ];

    return faqs;
  }

  copyFaqUrl(text: any) {
    this.linkCopied = true;
    let dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    setTimeout(() => {
      this.linkCopied = false;
    }, 5000);
  }

  addClassFor(event: any) {
    if ($(event).hasClass('change-icon add-border')) {
      $(event).removeClass('change-icon add-border');
    } else {
      $(event).addClass('change-icon add-border');
    }
  }
  /* istanbul ignore next */
  expandAll() {
    $('#faq .collapse').collapse('show');
    $('#faq > div').addClass('change-icon add-border');
  }
  /* istanbul ignore next */
  collapseAll() {
    $('#faq .collapse').collapse('hide');
    $('#faq > div').removeClass('change-icon add-border');
  }

  scrollBackToDefaultPosition() {
    $('#faqHigh').scrollTop(0);
  }

  toggleScrollUpdateHeight(flag: boolean) {
    if (flag) {
      $('.scroll-update').css('height', '100%')
    }
    else {
      $('.scroll-update').css('height', '100vh')
    }
  }

  removeSet() {
    this.searchedText = '';
    this.loadDefaultFaqs();
    this.arraySearch('');
    this.collapseAll();
    this.setFocus();
    this.toggleScrollUpdateHeight(true);
    this.scrollBackToDefaultPosition();
    this.toggleScrollUpdateHeight(false);
  }

  arraySearch(value: any) {
    this.item = 0;
    this.nextBtnClickCounter = 0;
    this.resultCount = 0;
    $('.match').removeClass('match highlighted mark-highlight');
    let span = $('#faqHigh span');
    span.replaceWith(span.html());
    if (value?.length > 0) {
      this.markFirstWord();
      if (this.resultCount < 1) {
        this.resultNotFound = true;
      } else {
        this.resultNotFound = false;
        this.next();
      }
    } else {
      this.resultCount = 0;
      this.resultNotFound = false;
    }
  }

  fnScrollTop(cardId: any) {
    if (this.faqUrl) {
      const scrollDiv = window.document.getElementById(cardId);
      if (scrollDiv != null) {
        setTimeout(() => {
          scrollDiv.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 0);
      }
    }
    setTimeout(() => {
      this.faqUrl = false;
    }, 500);
  }

  trackAIFaqLinkVisited(faqUrl: any, questionNumber: any, uniqueKey: any) {
    this.appInsightsService.logEvent(AI_CommonEvents.FaqLinkViewed, {
      [AI_CustomProps.FaqUrlVisted]: faqUrl,
      [AI_CustomProps.FaqQuestionNumber]: questionNumber,
      [AI_CustomProps.FaqUniqueKey]: uniqueKey,
    });
  }

  escapeRegExp(string: string) {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  markFirstWord() {
    if (this.searchedText) {
      let selector = '#faqHigh'; //use body as selector if none provided
      let searchTermRegEx = new RegExp(this.escapeRegExp(this.searchedText), 'ig');
      let matches = $(selector).text().match(searchTermRegEx);
      this.resultCount = matches ? matches.length : 0;
      if (matches != null && matches.length > 0) {
        $('.highlighted').removeClass('highlighted'); //Remove old search highlights

        let span = $('#faqHigh span');
        span.replaceWith(span.html());

        if (this.searchedText === '&') {
          this.searchedText = '&amp;';
          searchTermRegEx = new RegExp(this.escapeRegExp(this.searchedText), 'ig');
        }

        this.replaceTextInHtmlBlock(
          '#faqHigh',
          searchTermRegEx,
          "<span class='match mark-highlight'>" + this.searchedText + '</span>'
        );
      }
    }
  }

  previous() {
    if (this.searchedText) {
      this.expandAll();
      if (this.item < 0) {
        this.item = 0;
      }
      this.item--;
      if (this.item < 0) this.item = $('.match').length - 1;
      $('.match').removeClass('highlighted');
      $('.match').eq(this.item).addClass('highlighted');
      let container = $('.scroll-update');
      let scrollTo = $('.match').eq(this.item);
      container.animate(
        {
          scrollTop:
            scrollTo.offset()?.top -
            container.offset()?.top +
            container.scrollTop(),
          scrollLeft: 0,
        },
        0
      );
      if ($('.highlighted:first').length) {
        //if match found,page scroll to where the first one appears
        $('#faqHigh').scrollTop($('.highlighted:first').position());
      }
    }
  }

  next() {
    if (this.searchedText) {
      this.expandAll();
      if (this.nextBtnClickCounter > 0) {
        this.item++;
      }
      if (this.item >= $('.match').length) this.item = 0;
      $('.match').removeClass('highlighted');
      $('.match').eq(this.item).addClass('highlighted');
      let container = $('.scroll-update');
      let scrollTo = $('.match').eq(this.item);
      container.animate(
        {
          scrollTop:
            scrollTo.offset()?.top -
            container.offset()?.top +
            container.scrollTop(),
          scrollLeft: 0,
        },
        0
      );
      this.nextBtnClickCounter++;
      if ($('.highlighted:first').length) {
        //if match found, scroll to where the first one appears
        $('#faqHigh').scrollTop($('.highlighted:first').position());
      }
    }
  }

  replaceTextInHtmlBlock(element: any, replaceText: any, replaceWith: any) {
    let $children = $(element).children().detach();
    let root = this;
    //Now that there should only be text nodes left do your replacement
    $(element).html($(element).text().replace(replaceText, replaceWith));
    //Run this function for each child element
    $children.each(function (index: any, me: any) {
      root.replaceTextInHtmlBlock(me, replaceText, replaceWith);
    });
    $(element).append($children);
  }

  reloadPage() {
    if (!this.searchedText) {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      this.setFocus();
    }
  }

  setFocus() {
    setTimeout(() => {
      let element: any = document.getElementById('faq-inp');
      if (element) {
        element.focus();
      }
    }, 100)
  }

  trackAIPageView() {
    this.appInsightsService.logPageView(AI_PageName.Faq, window.location.href);
  }
}

export class Faq {
  id: number;
  question: string;
  answer: any;
  path: string;
  keyword: string;
}
